import i18n from "src/producers/languages/i18n";
import { FieldInputType } from "src/templates/types/template";
import { InsideCard } from "../../ClauseCard/InsideCard";
import { PlainTextFieldGeneral } from "../../fields/PlainTextFieldGeneral";
import { CommonCost } from "./CommonCost";
import { PaymentProvision } from "./PaymentProvision";
import { VATExempted } from "./VATExempted";

export const RentalCostReadOnly: view = () => (
  <div>
    <InsideCard key="3.1" title={"3.1 " + i18n.t("parking_contract.rental_cost_clause_first_subclause")}>
      <div className="w-full mb-6">
        <PlainTextFieldGeneral varName="rentalCostDescription" inputType={FieldInputType.FreeText} />
      </div>
      <PlainTextFieldGeneral
        varName="rentPerParkingSpace"
        extraClasses="pr-8 w-1/2 self-end"
        label={i18n.t("parking_contract.parking_space_l")}
      />
      <PlainTextFieldGeneral
        varName="rentPerCharger"
        extraClasses="pr-8 w-1/2 self-end"
        label={i18n.t("parking_contract.rentPerCharger")}
      />
    </InsideCard>
    <CommonCost />
    <InsideCard key="3.3" title={"3.3 " + i18n.t("parking_contract.rental_cost_clause_intex")}>
      <div className="w-full">
        <p className="mb-4 w-full">
          {
            "Leien reguleres hver 1. januar, i samsvar med eventuelle endringer i Statistisk Sentralbyrås konsumprisindeks, eller, hvis denne blir opphevet, annen tilsvarende offentlig indeks."
          }
        </p>
        <div className="flex">
          <div className="flex flex-col w-64 mr-3">
            <span>{i18n.t("clause_rental_costs_index_adjustment_first_time_regulation")}</span>
            <PlainTextFieldGeneral varName="firstTimeRegulation" extraClasses="pr-8" inputType={FieldInputType.Date} />
          </div>
          <div className="flex flex-col w-64">
            <span>{i18n.t("clause_rental_costs_index_adjustment_basic_index")}</span>
            <PlainTextFieldGeneral
              varName="indexAdjustmentBasicIndex"
              inputType={FieldInputType.Date}
              extraClasses="pr-8"
            />
          </div>
        </div>
      </div>
    </InsideCard>

    <PaymentProvision />
    <VATExempted />
  </div>
);
