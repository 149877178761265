import { Button } from "@c11/components";
import "./PDFDownloadButton.css";

import { DownloadIcon } from "src/assets/svg";
import i18n from "src/producers/languages/i18n";
import { functions } from "src/producers/firebase";

export const PDFDownloadButton: view = ({
  pdfServerLoading = observe.contract.flags.loadingpdf,
  contractId = get.contract.original.id,
}) => {
  const triggerCreatePdfFunction = async () => {
    const viewPdf = functions.httpsCallable("viewPdf");
    const ref = window.open();
    ref?.document.write("loading");
    const resp = await viewPdf({ contractId: contractId.value() });
    ref.location = resp.data;
  };
  return (
    <Button
      variant="secondary"
      className="download-contract-btn flex uppercase gap-2 mr-3"
      onClick={() => {
        triggerCreatePdfFunction();
      }}
      isDisabled={false}
      isLoading={pdfServerLoading}>
      <DownloadIcon />
      <span>{i18n.t("contract_navbar.button_preview_pdf_contract")}</span>
    </Button>
  );
};
