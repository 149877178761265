import React from "react";
import i18n from "src/producers/languages/i18n";
import { RentalProperty } from "./Clauses/RentalProperty";
import { FreeTextGeneralClause } from "../FreeTextGeneralClause";
import { RentalPeriod } from "./Clauses/RentalPeriod";
import { RentalCost } from "./Clauses/RentalCosts";
import { Vat } from "./Clauses/Vat";

export const StorageAddendum: view = ({}) => (
  <div>
    <FreeTextGeneralClause varName="backgroundAndPurpose" title={`1. ${i18n.t("clause_background_reason_title")}`} />

    <RentalProperty />
    <RentalPeriod />
    <RentalCost />
    <Vat />
    <FreeTextGeneralClause varName="compulsoryDerogation" title={`6. ${i18n.t("clause_adoption_derogation_title")}`} />
    <FreeTextGeneralClause varName="specialProvisions" title={`7. ${i18n.t("clause_other_provisions_title")}`} />
  </div>
);
