import { v4 } from 'uuid'
import NumberFormat from 'react-number-format'
import { InputFieldLabel, InputFieldLabelReadOnly } from './InputFieldLabel'
import { InputFieldParams, InputFieldReadOnlyParams } from './InputFieldTypes'
import { ComposeHtmlClassName } from './compose-html-class-name'
import { InvalidMessage } from 'src/pages/ContractV2/components/InvalidMessage'

import { TEXT_FIELD_RO_CLASS_NAMES } from './TextField'
import { Switch } from '@c11/components'
import { Checkbox } from '@c11/components'
import { VisibleIf } from './visible-if'
import { ReactNumberInput } from './ReactNumberInput'
import i18n from 'src/producers/languages/i18n'

type NumberFieldReadOnlyParams = InputFieldReadOnlyParams<number>

type NumberFieldParameters = InputFieldParams<number> & {
  isFloat?: boolean
}

export const NumberField = ({
  name, label, value, required, placeholder, isFloat, extraClassNames,
  onChange
}: NumberFieldParameters) => {

  return <div className={ComposeHtmlClassName('flex flex-col flex-grow', extraClassNames)} style={{ flexBasis: 0 }}>
    <InputFieldLabel htmlFor={name} label={label} required={required}/>
    <input type="number" name={name} id={name}
           className="mt-auto shadow-sm appearance-none border focus:outline-none klp-border1 w-full p-3 text-gray-800 leading-tight focus:bg-green-300 focus:border-transparent"
           autoComplete="off"
           placeholder={`${placeholder || ''}`}
           defaultValue={value}
           onChange={e => onChange(isFloat ? parseFloat(e.target.value) : parseInt(e.target.value, 10))}
    />
    <InvalidMessage className="invalid-message" name={name}/>
  </div>
}

export const NumberFieldReadOnly = ({
  label, value, required, extraClassNames, inputExtraClassNames
}: NumberFieldReadOnlyParams) => {

  return <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)} style={{ flexBasis: 0 }}>
    <InputFieldLabelReadOnly label={label} required={required}/>
    <div className="input-readonly">
      {value}
    </div>
  </div>
}

type AutocalculatedFieldWithOverrideParams = InputFieldParams<number> & {
  isCustom: boolean
  onSetCustomChanged: (state: boolean) => void
}

export const AutocalculatedFieldWithOverride = ({
  name, label, value, required, placeholder, extraClassNames, inputExtraClassNames, isCustom,
  onChange, onSetCustomChanged
}: AutocalculatedFieldWithOverrideParams) => {
  return <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)}>
    <InputFieldLabelReadOnly label={label} required={required}/>
    <div className="checkbox-input">
      <VisibleIf predicate={isCustom}>
        <ReactNumberInput
          name={name}
          label={label}
          value={value}
          required={required}
          placeholder={placeholder}
          extraClassNames={extraClassNames}
          inputExtraClassNames={inputExtraClassNames}
          onChange={(value: any) => onChange(value)}
        />
      </VisibleIf>
      <VisibleIf predicate={!isCustom}>
        <input type="text"
          className={ComposeHtmlClassName(TEXT_FIELD_RO_CLASS_NAMES.replace('w-full', 'flex-grow'), inputExtraClassNames)}
          autoComplete="off"
          value={value}
          disabled={true}
          readOnly={true}
        />
      </VisibleIf>
      <div className="custom-checkbox">
        <VisibleIf predicate={!isCustom}>
          <div className="text-sm text-gray-500">{i18n.t('components.custom.set-custom')}</div>
        </VisibleIf>
        <VisibleIf predicate={isCustom}>
          <div className="text-sm text-gray-500">{i18n.t('components.custom.is-custom')}</div>
        </VisibleIf>
        <Checkbox
          key={`${name}-isCustomValue`}
          className={`secondary-switch-${isCustom ? 'checked' : 'unchecked'}`}
          isChecked={isCustom}
          onClick={_ => {
            onSetCustomChanged(!isCustom)
          }}
        />
      </div>
    </div>
  </div>
}

type AutocalculatedFieldWithOverrideReadOnlyParams = InputFieldReadOnlyParams<string> & {
  isCustom: boolean
}

export const AutocalculatedFieldWithOverrideReadOnly = ({
  label, value, required, extraClassNames, inputExtraClassNames, isCustom
}: AutocalculatedFieldWithOverrideReadOnlyParams) => {
  return <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)}>
    <InputFieldLabelReadOnly label={label} required={required}/>

    <div className="flex flex-row flex-grow w-full items-center justify-center">

      <VisibleIf predicate={!isCustom}>
        <input type="text"
               className={ComposeHtmlClassName(TEXT_FIELD_RO_CLASS_NAMES.replace('w-full', 'flex-grow'), inputExtraClassNames)}
               autoComplete="off"
               value={value}
               disabled={true}
               readOnly={true}
        />
      </VisibleIf>

      <VisibleIf predicate={isCustom}>
        <ReactNumberInput
          name={v4()}
          label={label}
          value={parseFloat(value || '0')}
          required={required}
          extraClassNames={extraClassNames}
          inputExtraClassNames={inputExtraClassNames}
          onChange={_ => {
          }}
          isReadOnly={true}
        />
      </VisibleIf>

      <div className="ml-4 flex flex-col items-center justify-center">
        <VisibleIf predicate={!isCustom}>
          <div className="text-sm font-bold text-gray-500">Automatically calculated</div>
        </VisibleIf>
        <VisibleIf predicate={isCustom}>
          <div className="text-sm font-bold text-green-500">Manually set</div>
        </VisibleIf>
      </div>
    </div>
  </div>
}
