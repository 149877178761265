import { db, fieldValue } from "src/producers/firebase"
import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from "src/types"

export const persistPartyDelete: producer = async ({
  contractId = get.contract.id,
  isLocked = get.contract.isLocked,

  signers = get.contract.originalv2.signers,
  updateContractSigners = update.contract.originalv2.signers,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  removedParty = observe.contract.changes.parties.removed,
  updateRemovedParty = update.contract.changes.parties.removed,
}) => {
  if (!removedParty) return
  if (!removedParty.id) return

  isLocked = isLocked.value()
  if (isLocked) return

  contractId = contractId.value()
  if (!contractId) return

  signers = signers.value()
  if (!signers) return
  if (Object.keys(signers).length < 1) return

  try {
    isSaving.set(true)

    const updatedAt = new Date().toISOString()

    await db
      .collection("contracts")
      .doc(contractId)
      .set(
        {
          signers: {
            [removedParty.id]: fieldValue.delete(),
          },
          updatedAt,
        },
        {
          merge: true,
        }
      )

    delete signers[removedParty.id]

    updateContractSigners.set(signers)
    successfulSave.set(true)
  } catch (e) {
    console.error("persist-party-delete", e)
    failedSave.set(true)
    error.set(
      {
        message: "saving-party-delete",
        level: LogLevels.WARN,
      } as ApplicationError,
      { id: "persist-party-delete" }
    )
  } finally {
    updateRemovedParty.remove()

    isSaving.set(false)
  }
}
