import styles from '../styles.module.css'
import i18n from 'src/producers/languages/i18n'
import { ContractPDFClause } from './ContractPDFClause'
import { OtherAddendums } from './otherAddendums'
import { RentalPremise } from '../DigitalContract/ContractTab/clauses/RentalPremise'
import { ContractDates } from './ContractDates'
import { PartiesClause } from '../DigitalContract/ContractTab/clauses/Parties'

export const PaperContractComponent: view = () => (
  <div className={`${styles.contractsContainer}`}>
    <div>
      <ContractPDFClause />
      <OtherAddendums />
      <ContractDates />
      <RentalPremise />
      <PartiesClause />
    </div>
  </div>
);
