import { InvalidMessage } from 'src/pages/ContractV2/components/InvalidMessage'
import { ComposeHtmlClassName } from './compose-html-class-name'
import { InputFieldLabel } from './InputFieldLabel'
import { InputFieldReadOnlyParams, InputFieldParams } from './InputFieldTypes'
import { NumberFormatterFactory } from './number-formatter'
// import { default as Input } from 'react-input-auto-format';
import { NumericFormat, PatternFormat } from 'react-number-format'

type ReactNumberInputReadOnlyParameters = InputFieldReadOnlyParams<number>

type ReactNumberInputParameters = InputFieldParams<number | null> & {
  isReadOnly?: boolean
  thousandSeparator?: string
  decimalSeparator?: string
  decimalScale?: number
  zeroAllowed?: boolean

  onValueCleared?: () => void
}

const TEXT_FIELD_CLASS_NAMES =
  'w-full p-3 appearance-none shadow-sm border klp-border1 text-gray-800 leading-tight focus:bg-green-300 focus:border-transparent focus:outline-none'

export const ReactNumberInput = ({
  name,
  label,
  value,
  required,
  placeholder,
  extraClassNames,
  inputExtraClassNames,
  isReadOnly,
  decimalSeparator,
  decimalScale,
  thousandSeparator,
  onChange,
  onValueCleared,
}: ReactNumberInputParameters) => {
  return (
    <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)} style={{ flexBasis: 0 }}>
      <InputFieldLabel htmlFor={name} label={label} required={required} />
      <NumericFormat
        thousandSeparator={thousandSeparator ?? '.'}
        decimalSeparator={decimalSeparator ?? ','}
        decimalScale={decimalScale ?? 2}
        name={name}
        id={name}
        autoComplete="off"
        disabled={isReadOnly}
        className={ComposeHtmlClassName(TEXT_FIELD_CLASS_NAMES, inputExtraClassNames)}
        placeholder={placeholder?.toString()}
        defaultValue={value}
        onValueChange={(e) => {
          // console.log(e.floatValue)
          // if (e.floatValue !== null && e.floatValue !== undefined)
          
          onChange && onChange(e.floatValue || 0)

          // else
          //   onValueCleared && onValueCleared()
        }}
      />
      <InvalidMessage name={name} />
    </div>
  )
}

type ReactPatternFormatParameters = InputFieldParams<number | string | null> & {
  isReadOnly?: boolean
  format?: string
}

export const ReactPatternInput = ({
  name,
  label,
  value,
  required,
  placeholder,
  extraClassNames,
  inputExtraClassNames,
  isReadOnly,
  format,
  onChange,
}: ReactPatternFormatParameters) => {
  return (
    <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)} style={{ flexBasis: 0 }}>
      <InputFieldLabel htmlFor={name} label={label} required={required} />
      <PatternFormat
        format={format ?? '##########'}
        name={name}
        id={name}
        autoComplete="off"
        disabled={isReadOnly}
        placeholder={placeholder?.toString()}
        className={ComposeHtmlClassName(TEXT_FIELD_CLASS_NAMES, inputExtraClassNames)}
        defaultValue={value}
        onValueChange={(e) => {
          if (e.floatValue !== null && e.floatValue !== undefined) onChange(e.floatValue)
        }}
      />
      <InvalidMessage name={name} />
    </div>
  )
}
