import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const deleteAddendum: producer = async ({
  trigger = observe.contract.triggers.deleteAddendum,
  error = update.errors[param.id],
}) => {
  if (!trigger) return;

  try {
    const contractDb = db.collection("contracts").doc(trigger);
    await contractDb.delete();
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "deleteAddendum-addendum" }
    );
  }
};
