import algoliasearch from 'algoliasearch';


export const AlgoliaIndexProducer: producer = ({
  updateAlgoliaIndex = update.algoliaIndex,
  configuration = observe.config,
}) => {
  const isEmptyObject = Object.keys(configuration).length === 0 && configuration.constructor === Object
  if(isEmptyObject) return
  try {
    console.log('configuration', configuration)
    const {appId, searchKey, indexName} = configuration.algolia
    const client = algoliasearch(appId, searchKey);
    const algoliaIndex = client.initIndex(indexName);
    updateAlgoliaIndex.set(algoliaIndex);
  } catch (err: any) {
    console.log('err', err)
  }
}
