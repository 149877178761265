import React from "react";

import { ContractStatus, UserRole } from "src/types";

export const Card: view = ({
  title,
  children,
  actionText,
  actionFunction,
  editableByTenant,
  required,
  userRole = get.user.data.role,
  status = observe.legacyContract.original.status,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const hasWhiteBackground = (editableByTenant && status == ContractStatus.NEGOTIATION) || isManager;
  return (
    <div key="card" className={`mb-8 p-6 border klp-border1 ${hasWhiteBackground && "bg-white"}`}>
      <div key="header" className="flex justify-between items-baseline">
        <h2 key="title" className="font-bold text-2xl mb-6 font-robotoSlab">
          {required && (
            <span className="text-pink-600 mr-1" key="0">
              *
            </span>
          )}
          <span key="1">{title}</span>
        </h2>
        {actionText && (
          <span className="cursor-pointer text-pink-600" onClick={actionFunction}>
            {actionText}
          </span>
        )}
      </div>
      {children}
    </div>
  );
};
