import i18n from 'src/producers/languages/i18n'
import styles from '../../styles.module.css'
import { SigningSummaryCard } from './SigningSummaryCard/SigningSummaryCard'
import { ContractStatus } from 'src/types'
import { HeaderSection } from 'src/pages/ContractV2/components/HeaderSection/HeaderSection'
import { Progress } from 'src/pages/ContractV2/DigitalContract/SummaryTab/Progress/Progress'
import { ContractCard } from 'src/pages/ContractV2/DigitalContract/SummaryTab/ContractCard/ContractCard'
import { AttachmentsCard } from 'src/pages/ContractV2/DigitalContract/SummaryTab/AttachmentsCard/AttachmentsCard'
import { PartiesCard } from 'src/pages/ContractV2/DigitalContract/SummaryTab/PartiesCard/PartiesCard'

export const Summary: view = ({
  status = observe.contract.originalv2.status,
}) => {
  const isSigning = status === ContractStatus.SIGNING
  const isActive = status === ContractStatus.ACTIVE
  const showSigningSummaryCard = isSigning || isActive
  return (
    <div className="pb-20">
      <HeaderSection title={i18n.t('summary_page.contract_timeline')}>
        <Progress/>
      </HeaderSection>
      <div className={`${styles.summarySection}`}>
        <div>
          <div className={`${styles.summaryCardsRow}`}>{showSigningSummaryCard && <SigningSummaryCard/>}</div>
          <div className={`${styles.summaryCardsRow}`}>
            <ContractCard/>
            <AttachmentsCard/>
            <PartiesCard/>
          </div>
        </div>
      </div>
      {/* <Notifications /> */}
    </div>
  )
}
