export const syncCustom: producer = ({
  id = observe.legacyContract.id,
  versions = observe.legacyContract.original.versions,
  sessions = observe.legacyContract.original.sessions,
  setCustom = update.legacyContract.custom,
  custom = get.legacyContract.custom[param.varName],
  role = get.user.data.role,
  resetCustoms = update.legacyContract.custom,
}) => {
  if (!id && (!versions || !sessions)) {
    return;
  }

  try {
    resetCustoms.remove();
    if (sessions) {
      const newCustom: any = {};
      Object.keys(sessions).forEach((sessionId) => {
        if (role.value() !== sessionId) return;
        const session = sessions[sessionId];

        if (!session || !session.changes) return;
        Object.keys(session.changes).forEach((varName) => {
          const variable = session.changes[varName];
          newCustom[varName] = !!variable.custom;
          // setCustom.set(!!variable.custom, { varName: varName });
        });
      });
      setCustom.merge(newCustom);
    }
    if (versions) {
      const newCustom: any = {};
      const sortedVersions = Object.keys(versions).sort((v1: string, v2: string): any => {
        if (parseInt(versions[v1].createdAt) > parseInt(versions[v2].createdAt)) {
          return -1;
        }
        return 1;
      });
      sortedVersions.forEach((versionId) => {
        const version = versions[versionId];
        Object.keys(version.variables).forEach((varName) => {
          const variable = version.variables[varName];
          const isCustom = custom.value({ varName: varName });
          if (isCustom !== undefined) return;
          // setCustom.set(!!variable.custom, { varName: varName });
          newCustom[varName] = !!variable.custom;
        });
      });
      setCustom.merge(newCustom);
    }
  } catch (e) {
    console.error("syncCustom", e);
  }
};
