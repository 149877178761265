import { ClauseUIElement, Field, FieldInputType, ValidationType } from "src/templates/types/template";

export const fields: { [k: string]: Field } = {
  contractName: {
    varName: "contractName",
    placeholder: "clause_heading_placeholder",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
  },
  introText: {
    varName: "introText",
    extraClasses: "w-full",
    inputType: FieldInputType.FreeText,
    type: ClauseUIElement.Field,
  },
  landlordName: {
    varName: "landlordName",
    label: "clause_parties_landlord_landlordName",
    placeholder: "clause_parties_landlord_landlordName_p",
    inputType: FieldInputType.Text,
    extraClasses: "pr-8",
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  landlordOrgNo: {
    varName: "landlordOrgNo",
    label: "clause_parties_landlord_organisation_no",
    placeholder: "clause_parties_landlord_placeholder_organisation_no",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  landlordAddress: {
    varName: "landlordAddress",
    label: "clause_parties_landlord_address",
    placeholder: "clause_parties_landlord_placeholder_address",
    inputType: FieldInputType.Text,
    extraClasses: "pr-8",
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  landlordPostalAddress: {
    varName: "landlordPostalAddress",
    label: "clause_parties_landlord_postal_address",
    placeholder: "clause_parties_landlord__placeholder_postal_address",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  landlordInvoiceAddress: {
    varName: "landlordInvoiceAddress",
    label: "clause_parties_landlord_invoice_address",
    placeholder: "clause_parties_landlord_invoice_address_p",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  landlordPropertyManager: {
    varName: "landlordPropertyManager",
    label: "clause_parties_landlord_landlord_property_manager",
    placeholder: "clause_parties_landlord_landlord_property_manager_p",
    extraClasses: "w-full",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  landlordEmail: {
    varName: "landlordEmail",
    label: "clause_parties_landlord_email",
    placeholder: "clause_parties_landlord_placeholder_email",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    validationType: ValidationType.Email,
    requiredForManager: true,
  },
  landlordContactPersonFirstName: {
    varName: "landlordContactPersonFirstName",
    label: "clause_parties_landlord_contact_person_first_name",
    placeholder: "clause_parties_landlord_contact_person_first_name_p",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  landlordContactPersonLastName: {
    varName: "landlordContactPersonLastName",
    label: "clause_parties_landlord_contact_person_last_name",
    placeholder: "clause_parties_landlord_contact_person_last_name_p",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  landlordPhone: {
    varName: "landlordPhone",
    label: "clause_parties_landlord_phone",
    placeholder: "clause_parties_landlord_placeholder_phone",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    readOnlyForTenant: false,
    type: ClauseUIElement.Field,
    requiredForManager: false,
  },
  landlordAccountNo: {
    varName: "landlordAccountNo",
    label: "clause_parties_landlord_accountNo",
    placeholder: "placeholders.landlord_accountNo_p",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },

  tenantName: {
    varName: "tenantName",
    label: "clause_parties_tenant_name",
    placeholder: "clause_parties_tenant_placeholder_name",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    requiredForTenant: true,
  },
  tenantOrgNo: {
    varName: "tenantOrgNo",
    label: "clause_parties_tenant_organisation_no",
    placeholder: "clause_parties_tenant_placeholder_organisation_no",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    requiredForTenant: true,
  },
  tenantAddress: {
    varName: "tenantAddress",
    label: "clause_parties_tenant_address",
    placeholder: "clause_parties_tenant_placeholder_address",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    requiredForTenant: true,
  },
  tenantPostalAddress: {
    varName: "tenantPostalAddress",
    label: "clause_parties_tenant_postal_address",
    placeholder: "clause_parties_tenant__placeholder_postal_address",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    requiredForTenant: true,
  },
  tenantInvoiceAddress: {
    varName: "tenantInvoiceAddress",
    label: "clause_parties_tenant_invoice_address",
    placeholder: "clause_parties_tenant_invoice_address_p",
    extraClasses: "w-full",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    requiredForTenant: true,
  },

  tenantContactPersonFirstName: {
    varName: "tenantContactPersonFirstName",
    label: "clause_parties_tenant_contact_person_first_name",
    placeholder: "clause_parties_tenant_placeholder_contact_person_first_name",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    requiredForTenant: true,
  },
  tenantContactPersonLastName: {
    varName: "tenantContactPersonLastName",
    label: "clause_parties_tenant_contact_person_last_name",
    placeholder: "clause_parties_tenant_placeholder_contact_person_last_name",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    requiredForTenant: true,
  },
  tenantPhone: {
    varName: "tenantPhone",
    label: "clause_parties_tenant_phone",
    placeholder: "clause_parties_tenant_placeholder_phone",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    requiredForTenant: true,
  },
  tenantEmail: {
    varName: "tenantEmail",
    label: "clause_parties_tenant_email",
    placeholder: "clause_parties_tenant_placeholder_email",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    validationType: ValidationType.Email,
    requiredForTenant: true,
  },
  // Rental costs
  turnoverPercent: {
    varName: "turnoverPercent",
    label: "clause_rental_costs_turnover_rent",
    placeholder: "clause_rental_costs_turnover_rent_p",
    extraClasses: "pr-8 w-full",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  minimumRent: {
    varName: "minimumRent",
    label: "clause_rental_costs_minimum_rent",
    placeholder: "clause_rental_costs_placeholder_minimum_rent",
    extraClasses: "w-full",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  MTO: {
    varName: "MTO",
    label: "clause_rental_costs_minimum_turnover",
    inputType: FieldInputType.CalculationCustom,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  marketingCosts: {
    varName: "marketingCosts",
    label: "clause_rental_costs_marketing_cost",
    placeholder: "clause_rental_costs_placeholder_marketing_cost",
    extraClasses: "w-full",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  minimumMarketingCost: {
    varName: "minimumMarketingCost",
    label: "clause_rental_costs_minimum_marketing_cost",
    inputType: FieldInputType.CalculationCustom,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  sharedCosts: {
    varName: "sharedCosts",
    label: "clause_rental_costs_shared_cost",
    placeholder: "placeholders.add_amount",
    extraClasses: "pr-8",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  sharedCostsStorage: {
    varName: "sharedCostsStorage",
    label: "clause_rental_costs_shared_cost_storage",
    placeholder: "placeholders.add_amount",
    extraClasses: "pr-8",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    hiddenFromTenantIfEmpty: true,
  },
  rentAdjustment: {
    varName: "rentAdjustment",
    label: "clause_rental_costs_index_adjustment_rent_adjustment",
    placeholder: "clause_rental_costs_index_adjustment_rent_adjustment_p",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  firstTimeRegulation: {
    varName: "firstTimeRegulation",
    label: "clause_rental_costs_index_adjustment_first_time_regulation",
    placeholder: "placeholders.select_date",
    extraClasses: "pr-8",
    inputType: FieldInputType.Date,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  indexAdjustmentBasicIndex: {
    varName: "indexAdjustmentBasicIndex",
    label: "clause_rental_costs_index_adjustment_basic_index",
    placeholder: "placeholders.select_date",
    inputType: FieldInputType.Date,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  paymentProvisionDueDate: {
    varName: "paymentProvisionDueDate",
    label: "clause_rental_costs_index_payment_provision_payment_due",
    placeholder: "clause_rental_costs_index_payment_provision_payment_due_p",
    extraClasses: "pr-8",
    inputType: FieldInputType.Date,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  paymentProvisionFirstPaySum: {
    varName: "paymentProvisionFirstPaySum",
    label: "clause_rental_costs_index_payment_provision_first_pay_sum",
    placeholder: "clause_rental_costs_index_payment_provision_first_pay_sum_p",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  // periods

  leaseDate: {
    varName: "leaseDate",
    label: "clause_lease_date",
    placeholder: "clause_rental_period_placeholder_lease_date",
    extraClasses: "w-1/3 pr-8",
    inputType: FieldInputType.Date,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    validationType: ValidationType.LeaseDate,
    requiredForManager: true,
  },
  rentalStart: {
    varName: "rentalStart",
    label: "clause_rental_period_rental_start",
    placeholder: "placeholders.select_date",
    extraClasses: "w-1/3 pr-8",
    inputType: FieldInputType.Date,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  rentalEnd: {
    varName: "rentalEnd",
    label: "clause_rental_period_rental_end",
    placeholder: "placeholders.select_date",
    extraClasses: "w-1/3 pr-8",
    inputType: FieldInputType.Date,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    validationType: ValidationType.RentalEnd,
    requiredForManager: true,
  },
  storeOpening: {
    varName: "storeOpening",
    label: "clause_rental_period_opening_date",
    placeholder: "placeholders.select_date",
    extraClasses: "w-1/3",
    inputType: FieldInputType.Date,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  firstRentDate: {
    varName: "firstRentDate",
    label: "clause_rental_period_date_of_the_first_rent",
    placeholder: "placeholders.select_date",
    extraClasses: "w-1/4",
    inputType: FieldInputType.Date,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  rentalPeriodOptionsPeriod: {
    varName: "rentalPeriodOptionsPeriod",
    label: "clause_rental_period_potential_options_period",
    placeholder: "clause_rental_period_potential_options_period_p",
    extraClasses: "w-full",
    inputType: FieldInputType.Dropdown,
    dropdownChoices: [
      "rentalPeriodOptionsPeriod_o1",
      "rentalPeriodOptionsPeriod_o2",
      "rentalPeriodOptionsPeriod_o3",
      "rentalPeriodOptionsPeriod_o4",
    ],
    readOnlyForTenant: true,
    hiddenFromTenantIfEmpty: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },

  // property
  shoppingMall: {
    varName: "shoppingMall",
    label: "clause_rental_property_property_shopping_mall",
    placeholder: "clause_rental_property_property_placeholder_shopping_mall",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  propertyAddress: {
    varName: "propertyAddress",
    label: "clause_rental_property_property_address",
    placeholder: "clause_rental_property_property_placeholder_address",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  municipality: {
    varName: "municipality",
    label: "clause_rental_property_property_municipality",
    placeholder: "clause_rental_property_property_placeholder_municipality",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  parcelNumber: {
    varName: "parcelNumber",
    label: "clause_rental_property_property_parcel_number",
    placeholder: "clause_rental_property_property_parcel_number_p",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  propertyLocalNo: {
    varName: "propertyLocalNo",
    label: "clause_rental_property_property_local_no",
    placeholder: "clause_rental_property_property_placeholder_local_no",
    extraClasses: "pr-8 w-1/4 self-end",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  propertyLevel: {
    varName: "propertyLevel",
    label: "clause_rental_property_property_level",
    placeholder: "clause_rental_property_property_placeholder_level",
    extraClasses: "w-1/4 pr-8 self-end",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  propertyExclusiveArea: {
    varName: "propertyExclusiveArea",
    label: "clause_rental_property_property_property_exclusive_area",
    placeholder: "clause_rental_property_property_placeholder_property_exclusive_area",
    extraClasses: "pr-8 w-1/4 self-end",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  propertyCommonArea: {
    varName: "propertyCommonArea",
    label: "clause_rental_property_property_propery_common_area",
    placeholder: "clause_rental_property_property_placeholder_propery_common_area",
    extraClasses: "w-1/4 self-end",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  storagePropertyLocalNo: {
    varName: "storagePropertyLocalNo",
    label: "clause_rental_property_property_local_no",
    placeholder: "clause_rental_property_property_placeholder_local_no",
    extraClasses: "pr-8 w-1/4",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  storagePropertyLevel: {
    varName: "storagePropertyLevel",
    label: "clause_rental_property_property_level",
    placeholder: "clause_rental_property_property_placeholder_level",
    extraClasses: "w-1/4 pr-8",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  storagePropertyExclusiveArea: {
    varName: "storagePropertyExclusiveArea",
    label: "clause_rental_property_property_property_exclusive_area",
    placeholder: "clause_rental_property_property_placeholder_property_exclusive_area",
    extraClasses: "pr-8 w-1/4",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  storagePropertyCommonArea: {
    varName: "storagePropertyCommonArea",
    label: "clause_rental_property_property_propery_common_area",
    placeholder: "clause_rental_property_property_placeholder_propery_common_area",
    extraClasses: "w-1/4",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  storageRent: {
    varName: "storageRent",
    label: "clause_rental_property_store_concept_storage_rent",
    placeholder: "clause_rental_property_store_concept_storage_rent_p",
    inputType: FieldInputType.Number,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  storeName: {
    varName: "storeName",
    label: "clause_rental_property_store_concept_store_name",
    placeholder: "clause_rental_property_store_concept_placeholder_store_name",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  storeChain: {
    varName: "storeChain",
    label: "clause_rental_property_store_concept_chain",
    placeholder: "clause_rental_property_store_concept_placeholder_chain",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  storeGroup: {
    varName: "storeGroup",
    label: "clause_rental_property_store_concept_group_name",
    placeholder: "clause_rental_property_store_concept_placeholder_group_name",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  storeIndustryCode: {
    varName: "storeIndustryCode",
    label: "clause_rental_property_store_concept_industry_code",
    placeholder: "clause_rental_property_store_concept_placeholder_industry_code",
    inputType: FieldInputType.Dropdown,
    dropdownChoices: [
      "storeIndustryCode_o1",
      "storeIndustryCode_o2",
      "storeIndustryCode_o3",
      "storeIndustryCode_o4",
      "storeIndustryCode_o5",
      "storeIndustryCode_o6",
      "storeIndustryCode_o7",
      "storeIndustryCode_o8",
    ],
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  storeConcept: {
    varName: "storeConcept",
    label: "clause_rental_property_store_concept_concept_of_the_store",
    placeholder: "clause_rental_property_store_concept_placeholder_concept_of_the_store",
    extraClasses: "w-full",
    inputType: FieldInputType.FreeText,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },
  propertyDescription: {
    varName: "propertyDescription",
    extraClasses: "w-full",
    inputType: FieldInputType.FreeText,
    type: ClauseUIElement.Field,
    requiredForManager: true,
  },

  //security
  securityAlternativeAditionalInfo: {
    varName: "securityAlternativeAditionalInfo",
    placeholder: "clause_security_alternative_1_info_p",
    extraClasses: "w-full",
    inputType: FieldInputType.Dropdown,
    dropdownChoices: [
      "securityAlternativeAditionalInfo_o1",
      "securityAlternativeAditionalInfo_o2",
      "securityAlternativeAditionalInfo_o3",
      "securityAlternativeAditionalInfo_o4",
      "securityAlternativeAditionalInfo_o5",
    ],
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  securityAlternativeAditionalInfoOther: {
    varName: "securityAlternativeAditionalInfoOther",
    placeholder: "clause_security_alternative_1_info_other_p",
    inputType: FieldInputType.Text,
    extraClasses: "w-full",
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  securityAmount: {
    varName: "securityAmount",
    inputType: FieldInputType.CalculationCustom,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  securityDeadline: {
    varName: "securityDeadline",
    label: "",
    placeholder: "placeholders.select_date",
    extraClasses: "w-full",
    inputType: FieldInputType.Date,
    readOnlyForTenant: true,
    type: ClauseUIElement.Field,
  },
  //vat
  vatPercent: {
    varName: "vatPercent",
    extraClasses: "w-1/6 inline-block",
    inputType: FieldInputType.Number,
    type: ClauseUIElement.Field,
  },

  //signatory
  signatoryLandlordFirstName: {
    label: "legacy.signatory_first_name",
    placeholder: "legacy.signatory_first_name_p",
    extraClasses: "pr-8",
    readOnlyForTenant: true,
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
  },
  signatoryLandlordLastName: {
    label: "legacy.signatory_last_name",
    placeholder: "legacy.signatory_last_name_p",
    readOnlyForTenant: true,
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
  },
  signatoryLandlordPosition: {
    label: "legacy.signatory_position",
    placeholder: "legacy.signatory_position_p",
    extraClasses: "pr-8",
    readOnlyForTenant: true,
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
  },
  signatoryLandlordPhone: {
    label: "legacy.signatory_phone",
    placeholder: "legacy.signatory_phone_p",
    readOnlyForTenant: true,
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
  },
  signatoryLandlordEmail: {
    label: "legacy.signatory_email",
    placeholder: "legacy.signatory_email_p",
    extraClasses: "pr-8",
    readOnlyForTenant: true,
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    validationType: ValidationType.Email,
  },
  signatoryTenantFirstName: {
    label: "legacy.signatory_first_name",
    placeholder: "legacy.signatory_first_name_p",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
  },
  signatoryTenantLastName: {
    label: "legacy.signatory_last_name",
    placeholder: "legacy.signatory_last_name_p",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
  },
  signatoryTenantPosition: {
    label: "legacy.signatory_position",
    placeholder: "legacy.signatory_position_p",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
  },
  signatoryTenantPhone: {
    label: "legacy.signatory_phone",
    placeholder: "legacy.signatory_phone_p",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
  },
  signatoryTenantEmail: {
    label: "legacy.signatory_email",
    placeholder: "legacy.signatory_email_p",
    extraClasses: "pr-8",
    inputType: FieldInputType.Text,
    type: ClauseUIElement.Field,
    validationType: ValidationType.Email,
  },

  //derogation
  compulsoryDerogation: {
    varName: "compulsoryDerogation",
    extraClasses: "w-full",
    inputType: FieldInputType.FreeText,
    type: ClauseUIElement.Field,
  },
  specialProvisions: {
    varName: "specialProvisions",
    extraClasses: "w-full",
    inputType: FieldInputType.FreeText,
    type: ClauseUIElement.Field,
  },
};
