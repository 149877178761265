import React from "react";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { VersionBubble } from "./VersionBubble";
import { ContractSentBubble } from "./ContractSentBubble";
import { MessageBubble } from "./MessageBubble";
import { ContractSignBubble } from "./ContractSignBubble";
import { UserRole } from "src/types";
import { HelpCircle } from "src/components";

export const UpdatesFeed: view = ({
  updatesFeed = observe.legacyContract.updatesFeed,
  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;

  const feedList = updatesFeed?.map((feedEvent: any, index: any) => {
    const isCommment = feedEvent.type === "comment";
    const isVersion = feedEvent.type === "version";
    const isSign = feedEvent.type === "sign";
    const contractSent = feedEvent.type === "contractSent";

    if (isCommment) {
      return <MessageBubble key={index} comment={feedEvent} />;
    }
    if (contractSent) {
      return (
        <div key={index + "csent"}>
          <ContractSentBubble key={index} feed={feedEvent} />
        </div>
      );
    }
    if (isVersion) {
      return (
        <div key={index + "version"}>
          <VersionBubble key={index} version={feedEvent} index={index} />
        </div>
      );
    }
    if (isSign) {
      return <ContractSignBubble key={index} feed={feedEvent} />;
    }
    return null;
  });

  return (
    <div style={{ flexGrow: 1 }} className="w-full p-4 flex flex-col">
      <div className="justify-between flex items-start">
        <h3 className="text-lg mb-4 flex items-center">
          <SVGs.Message className="mr-2 mt-1" />
          {i18n.t("legacy.sidebar_feed_title")}
        </h3>
        <div>
          <HelpCircle text={i18n.t("sidebar.tooltip_messages")} />
        </div>
      </div>
      <div style={{ paddingBottom: isTenant ? 205 : 440 }}>{feedList}</div>
    </div>
  );
};
