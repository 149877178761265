import { Modal } from "src/components";
import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";

export const ResetContractModal: view = ({
  show = observe.modal.resetContract,
  deleteAllChanges = update.contract.triggers.deleteAllChanges,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!show || !isManager) return null;
  return (
    <Modal
      title={i18n.t("modals_reset_contract_title")}
      okButtonText={i18n.t("modals_reset_contract_button")}
      image={() => <SVGs.ModalRemoveChanges />}
      onOkClick={() => deleteAllChanges.set(Date.now())}>
      <p>{i18n.t("modals_reset_contract_p1")}</p>
    </Modal>
  );
};
