import i18n from "src/producers/languages/i18n";
import { BuildingIcon } from "src/assets/svg";
import { ShoppingMallsDropdown } from "src/pages/Contract/subviews/ContractForm/RentalPropertyClause/ShoppingMallsDropdown";
import { UserRole } from "src/types";
import { ReadOnlyField } from "../ReadOnlyField/ReadOnlyField";
import { ShoppingMallsDropdownRow } from "../RentalPropertyClause/ShoppingMallsDropdownRow";
import styles from "../../../styles.module.css";

export const RentalPremiseClause: view = ({
  shoppingMalls = observe.domain.shoppingMalls,
  shoppingMallId = observe.contract.original.state.building.id,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const selectedSM = shoppingMalls?.[shoppingMallId];
  return (
    <div className={`${styles.contractRentalPremiseClause}`}>
      <div className="flex">
        <div className="flex flex-row gap-4 items-center">
          <BuildingIcon />
          <span>{i18n.t("contract_page.contract_premise_clause_p")}</span>
        </div>
      </div>
      <div className="w-full mt-3">
        {isManager && !isReadOnlyContract ? (
          <ShoppingMallsDropdown />
        ) : (
          <>
            <ShoppingMallsDropdownRow SM={selectedSM} />
            <div className="mt-4">
              <ReadOnlyField label={i18n.t("landlordName")} varName="landlordName" className="w-1/2 mb-5" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
