import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { Initialization, Dashboard, Login, ContractV2, ContractLegacy, Addendum, Contract } from './pages'
import { ContractWrapper } from './pages/contractWrapper'
export const routes = [
  {
    path: '/contract/:id',
    element: <ContractWrapper />,
    name: 'contract',
  },
  // {
  //   path: '/contract_v1/:id',
  //   element: <Contract />,
  // },
  {
    path: '/addendum/:id',
    element: <Addendum />,
  },
  // {
  //   path: '/contract_legacy/:id',
  //   element: <ContractLegacy />,
  // },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/init',
    element: <Initialization />,
  },
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '',
    element: <Initialization />,
  },
]
export const router = createBrowserRouter(routes)
