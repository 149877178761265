import React from "react";
import i18n from "src/producers/languages/i18n";
import { CheckboxType2 } from "../../fields/formFields/CheckboxType2";
import { InfoWarning } from "./InfoWarning";
import { InfoGreen } from "./InfoGreen";

export const SignatureCheckbox: view = ({
  getsignatureOptOut = observe.legacyContract.values.signatureOptOut.displayedValue,
  setSignatureOptOut = update.legacyContract.currentSession.changes.signatureOptOut,
  getsignatureOptOutFromChanges = observe.legacyContract.currentSession.changes.signatureOptOut.value,
}) => {
  const value = getsignatureOptOutFromChanges || getsignatureOptOut;
  const checked = value === "true";
  function handleClick() {
    if (value === "true") {
      setSignatureOptOut.set({ value: "false", createdAt: Date.now() });
    } else {
      setSignatureOptOut.set({ value: "true", createdAt: Date.now() });
    }
  }

  return (
    <CheckboxType2
      key={"2"}
      label={i18n.t("clause_signature_alternative_2_text")}
      checked={checked}
      onClick={() => handleClick()}>
      {checked ? <InfoGreen /> : <InfoWarning />}
    </CheckboxType2>
  );
};
