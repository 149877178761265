import i18n from "src/producers/languages/i18n";

import { UserRole, ContractStatus } from "src/types";
import { HeaderSection } from "src/pages/Contract/HeaderSection/HeaderSection";
import { UploadGuaranteeButton } from "src/pages/Contract/Navbar/UploadGuaranteeButton";
import { DeleteAttachmentModal } from "src/pages/Contract/subviews/Attachments/DeleteAttachmentModal/DeleteAttachmentModal";
import { AttachmentPreviewModal } from "src/pages/Contract/subviews/Attachments/AttachmentPreviewModal/AttachmentPreviewModal";
import { AttachmentSection } from "src/pages/Contract/subviews/Attachments/AttachmentSection/AttachmentSection";
import { AddAttachmentButton } from "src/pages/Contract/subviews/Attachments/AddAttachmentButton/AddAttachmentButton";
import { createAttachmentsViewModel } from "src/pages/Contract/subviews/Attachments/Attachments.createAttachmentsViewModel";
import { handleDownload } from "src/pages/Contract/subviews/Attachments/Attachments.handleDownload";
import { UpdateFoundBar } from "../ContractForm/UpdateFoundBar/UpdateFoundBar";

import styles from "../../styles.module.css"

export const Attachments: view = ({
  userRole = get.user.data.role,
  status = observe.contract.original.state.status,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  const isManager = userRole.value() === UserRole.MANAGER;
  const isDraft = status === ContractStatus.DRAFT;
  const isNegotiation = status === ContractStatus.NEGOTIATION;
  const isActive = status === ContractStatus.ACTIVE;
  const canAddAppendix = isDraft || isNegotiation;

  return (
    <>
      <div className={`${styles.attachmentPage}`}>
        <HeaderSection title={i18n.t("contract_navbar.attachments")}>
          {!isTenant && canAddAppendix && <AddAttachmentButton />}
          {isActive && <UploadGuaranteeButton />}
        </HeaderSection>
        {isManager && <UpdateFoundBar />}
        <AttachmentSection />
      </div>
      <AttachmentPreviewModal />
      <DeleteAttachmentModal />
    </>
  );
};

Attachments.producers([createAttachmentsViewModel, handleDownload]);
