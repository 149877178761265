import { db } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'

export const syncTenant: producer = async ({
  tenantId = observe.contract.original.tenantId,
  updateTenant = update.contract.tenant,
  error = update.errors[param.id],
}) => {
  if (!tenantId) return
  try {
    const snap = await db.collection('tenants').where('id', '==', tenantId).get()
    for (const doc of snap.docs) {
      const tenant = doc.data()
      if (!tenant) return
      if (tenant.mainContacts)
        tenant.mainContacts = Object.keys(tenant.mainContacts).map((x) => tenant.mainContacts[x])
      updateTenant.set(tenant)
    }
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.LOW,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'syncTenant-contract' }
    )
  }
}
