import moment from 'moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

import { ContractStatusSigning, ContractStatusDraft, ContractStatusNegotiation } from 'src/assets/svg'
import i18n from 'src/producers/languages/i18n'
import { ContractFilterOption, ContractStatus, AgreementType, ContractType } from 'src/types'
import { SVGs } from 'src/assets'
import { ContractMenu } from 'src/pages/Dashboard/ContractMenu/ContractMenu'
import { ListItemCard, ListItemCardBody, ListItemCardFooter, ListItemCardHeader } from 'src/components'

import styles from '../styles.module.css'
import { ContractFlow, ContractTemplate } from 'src/pages/ContractV2/contract.types'

export const ContractListItem: view = ({ contract, onClick }) => {
  if (!contract) {
    return null
  }
  const agreementType =
    contract.template === ContractTemplate.SHOPPING_MALL || contract.contractType === ContractType.PAPER
      ? i18n.t('dashboard.rental_contract')
      : i18n.t('dashboard.parking_contract')

  const flow = i18n.t(`dashboard.flow.${contract?.flow}`)

  const isLegacy = contract?.isLegacy ? `${i18n.t('dashboard.legacy')}` : ''

  // this logic is added for leggacy support
  let rentalStart = contract?.summary?.rentalStart
  // if (typeof rentalStart === "number") {

  // if (rentalStart) rentalStart = moment(contract.summary.rentalStart).utc().format('DD.MM.YYYY')

  if (rentalStart) rentalStart = dayjs(contract.summary.rentalStart).tz('Europe/Oslo').format('DD.MM.YYYY')

  // if(rentalStart) rentalStart = dayjs(new Date(Date.parse(rentalStart.toISOString())))
  // }

  let rentalEnd = contract?.summary?.rentalEnd
  // if (typeof rentalEnd === "number") {
  // if (rentalEnd) rentalEnd = moment(contract.summary.rentalEnd).utc().format('DD.MM.YYYY')

  if (rentalEnd) rentalEnd = dayjs(contract.summary.rentalEnd).tz('Europe/Oslo').format('DD.MM.YYYY')

  // }

  const rentalPeriod = `${rentalStart ? rentalStart + ' - ' : ''}${rentalEnd || ''}`

  const renderStatus = () => {
    const status = contract.state?.status || contract.status
    let statusIcon
    let statusText

    if (status === ContractStatus.DRAFT) {
      statusIcon = <ContractStatusDraft />
      statusText = i18n.t('dashboard.card_status_draft')
    }
    if (status === ContractStatus.NEGOTIATION) {
      statusIcon = <ContractStatusNegotiation />
      statusText = i18n.t('dashboard.card_status_negotiation')
    }
    if (status === ContractStatus.SIGNING) {
      statusIcon = <ContractStatusSigning />
      statusText = i18n.t('dashboard.card_status_signing')
    }
    if (status === ContractStatus.ACTIVE) {
      statusIcon = <SVGs.ContractStatusActive />
      statusText = i18n.t('dashboard.card_status_active')
    }
    if (contract.filterTag === ContractFilterOption.ARCHIVED) {
      statusIcon = <SVGs.ContractStatusArchived />
      statusText = i18n.t('dashboard.card_status_archived')
    }
    if (contract.filterTag === ContractFilterOption.LOST) {
      statusIcon = <SVGs.ContractStatusLost />
      statusText = i18n.t('dashboard.card_status_lost')
    }
    if (contract.filterTag === ContractFilterOption.TRASHED) {
      statusIcon = <SVGs.ContractStatusTrashed />
      statusText = i18n.t('dashboard.card_status_trashed')
    }

    return (
      <div className={`${styles.statusContainer} `}>
        <div className={`${styles.isLegacy}`}>{isLegacy}</div>
        <div className={`${styles.status} `}>
          {statusIcon && statusIcon}
          <span>{statusText}</span>
        </div>
      </div>
    )
  }

  return (
    <ListItemCard
      key={`li-${contract.id}`}
      className="transition duration-500 ease-in-out shadow hover:shadow-lg py-3 my-2"
      onClick={onClick}>
      <ListItemCardHeader
        key={`lih-${contract.id}`}
        title={
          (contract.template === ContractTemplate.PARKING ? contract?.summary?.title : contract?.summary?.tenantName) ||
          contract.id
        }
        className={`${styles.contractName} `}>
        <div className={`${styles.statusInfo}`}>
          {/* <div>{renderStatus()}</div> */}
          <span>
            {agreementType} - {flow}{' '}
          </span>
          {/* <span>{contract?.summary?.propertyLocalNo ? `#${contract?.summary?.propertyLocalNo}` : ""}</span> */}
        </div>
      </ListItemCardHeader>
      <ListItemCardBody key={`lib-${contract.id}`} className="justify-between ml-10">
        <div className="w-3/12 flex justify-start">{contract?.summary?.shoppingMall || ''}</div>
        <div className="w-2/12 flex justify-start">{contract?.summary?.propertyLocalNo || ''}</div>
        <div className="w-2/12 flex justify-start">{rentalStart || ''}</div>
        <div className="w-2/12 flex justify-start">{rentalEnd || ''}</div>
        <div className="flex justify-end" style={{ width: '20%' }}>
          {renderStatus()}
        </div>
      </ListItemCardBody>
      <ListItemCardFooter key={`lif-${contract.id}`}>
        <ContractMenu contractId={contract.id} contractFilterFlag={contract.filterTag} />
      </ListItemCardFooter>
    </ListItemCard>
  )
}
