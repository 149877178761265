import React from "react";

import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";

export const ContractSentBubble: view = ({ feed }) => {
  const { createdAt } = feed;

  return (
    <div className="p-4 bg-gray-200 w-full rounded mb-3 ">
      <div className="flex justify-between">
        <span className="flex">
          <SVGs.Page />
          <span className="ml-2 font-medium">{i18n.t("legacy.sidebar_feed_contract_sent")}</span>
        </span>
        <span className="font-12 text-gray-600 ">{createdAt}</span>
      </div>
    </div>
  );
};
