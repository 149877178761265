import { DatePicker } from "antd";

import i18n from "src/producers/languages/i18n";
import { Modal } from "src/components";
import { SVGs } from "src/assets";
import { UserRole } from "src/types";
import { TextField } from "@c11/components";
import { moveToSigning } from "src/pages/Contract/MoveToSigningModal/MoveToSigningModal.moveToSigning";
import { deleteAddendum } from "./DeleteAdeendumModal.deleteAddendum";

export const DeleteAdeendumModal: view = ({
  trigger = observe.modal.deleteAdeendum,
  deleteAddendum = update.contract.triggers.deleteAddendum,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!trigger || !isManager) return null;
  return (
    <Modal
      title={`${i18n.t("addendum.delete_addendum")}?`}
      okButtonText={i18n.t("addendum.delete_addendum")}
      onOkClick={() => {
        deleteAddendum.set(trigger);
      }}>
      <p key="1" className="mb-1">
        {`${i18n.t("addendum.delete_addendum_p1")}`}
      </p>
    </Modal>
  );
};

DeleteAdeendumModal.producers([deleteAddendum]);
