import { Switch } from "@c11/components";
import { Trans } from "react-i18next";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { ContractSigningMethod } from "src/types";
import { TooltipWrapper } from "src/components";

export const SigningMethodSection: view = ({ disabled, loading, selectedMethod, onClick }) => {
  const digitalSigning = selectedMethod === ContractSigningMethod.DIGITAL;
  return (
    <TooltipWrapper disabled={!disabled} text={i18n.t("parties_page.signing_method_tooltip")}>
      <div className="flex justify-center w-full">
        <div
          className={`flex justify-between w-11/12 border border-gray-300 mb-10 px-4 py-3 ${
            disabled ? "bg-gray-200 text-gray-600" : ""
          }`}>
          <div className="flex justify-start items-center font-bold w-3/12">
            <SVGs.DocumentChecked />
            <span className="ml-1">{i18n.t("parties_page.digital_signing_label")}</span>
            <div
              className="bg-gray-300"
              style={{
                width: "2px",
                height: "20px",
                margin: "0px 15px",
              }}
            />
          </div>
          <div className="flex-column w-6/12 justify-center">
            {digitalSigning ? (
              <Trans i18nKey="parties_page.digital_signing_p1">
                Recipients will sign the contract
                <span className="font-bold underline">digitally</span>
                with Verified.
              </Trans>
            ) : (
              <Trans i18nKey="parties_page.paper_signing_p1">
                Recipients will sign the contract
                <span className="font-bold underline">in person</span>.
                <span className="text-red-600 inline-block">
                  Usually this process takes longer and will require the contract's upload.
                </span>
              </Trans>
            )}
          </div>
          <div className="flex w-3/12 justify-end">
            <Switch
              className={`secondary-switch-${digitalSigning ? "checked" : "unchecked"}`}
              isDisabled={disabled}
              isLoading={loading}
              isChecked={digitalSigning}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </TooltipWrapper>
  );
};
