import { Spinner } from "@c11/components";

import i18n from "src/producers/languages/i18n";
import { ContractType, UserRole } from "src/types";
import { HeaderSection } from "src/pages/Contract/HeaderSection/HeaderSection";
import { PDFDownloadButton } from "src/pages/Contract/subviews/ContractForm/PDFDownloadButton/PDFDownloadButton";
import { VersionsDropdown } from "src/pages/Contract/subviews/ContractForm/VersionsDropdown/VersionsDropdown";
import { UpdateFoundBar } from "src/pages/Contract/subviews/ContractForm/UpdateFoundBar/UpdateFoundBar";
import { DigitalContract } from "src/pages/Contract/subviews/ContractForm/DigitalContract/DigitalContract";
import { PaperContract } from "src/pages/Contract/subviews/ContractForm/PaperContract/PaperContract";
import { valueValidation } from "src/pages/Contract/subviews/ContractForm/ContractForm.valueValidation";
import { mergeDataForValues } from "src/pages/Contract/subviews/ContractForm/ContractForm.mergeDataForValues";
import { resetDefault } from "src/pages/Contract/subviews/ContractForm/ContractForm.resetDefault";

import styles from "../../styles.module.css";
import { ParkingContract } from "./ParkingContract/ParkingContract";
import {
  SendContractDataButton
} from "src/pages/Contract/subviews/ContractForm/SendContractDataButton/SendContractDataButton";

export const ContractForm: view = ({
  contract = observe.contract.original,
  userRole = get.user.data.role
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!contract) return <Spinner className={`${styles.contractSpinner}`} />;

  const { contractType } = contract;

  switch (contractType) {
    case ContractType.PAPER:
      return (
        <div className="overflow-auto pb-20 h-screen">
          <PaperContract />
        </div>
      );
    case ContractType.PARKING:
      return (
        <div className="overflow-auto pb-20 h-screen">
          <HeaderSection title={i18n.t("contract_editor")}>
            {isManager && <VersionsDropdown />}
            <SendContractDataButton/>
            <PDFDownloadButton />
          </HeaderSection>
          {isManager && <UpdateFoundBar />}
          <ParkingContract />
        </div>
      );
    default:
      // ContractType.DIITAL
      return (
        <div className="overflow-auto pb-20 h-screen">
          <HeaderSection title={i18n.t("contract_editor")}>
            {isManager && <VersionsDropdown />}
            <SendContractDataButton/>
            <PDFDownloadButton />
          </HeaderSection>
          {isManager && <UpdateFoundBar />}
          <DigitalContract />
        </div>
      );
  }
};
ContractForm.producers([valueValidation, mergeDataForValues, resetDefault]);
