import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const syncAllLocations: producer = async ({
  sessionReady = observe.session.firebase.isReady,
  userReady = observe.user.isReady,
  setLocations = update.domain.locations,
  error = update.errors[param.id],
}) => {
  if (!sessionReady || !userReady) {
    return;
  }
  try {
    console.log("Syncing locations...");
    const buildingsDb = db.collection("buildings");
    const locations = (await buildingsDb.get()).docs;
    const locationObj: any = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const location of locations) {
      const data = location.data();
      if (!data) {
        continue;
      }

      locationObj[location.id] = data;
    }

    setLocations.set(locationObj);

    console.log("Locations syncing complete.");
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncAllLocations" }
    );
  }
};
