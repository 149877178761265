import { db } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel, ErrorType, UserRole } from 'src/types'

export const syncContract: producer = async ({
  id = observe.contract.id,
  setContract = update.contract.original,
  setListener = update.listeners[arg.id].contract,
  role = get.user.data.role,
  error = update.errors[param.id],
}) => {
  if (!id) {
    return
  }
  role = role.value()

  try {
    const listener = db
      .collection('contracts')
      .doc(id)
      .onSnapshot((doc) => {
        const result = doc.data()
        if (!result) return
        delete result.versionLock
        if (role === UserRole.TENANT) result.state = result.clientState
        else result.state = result.managerState
        delete result.clientState
        delete result.managerState
        setContract.set(result)
        console.log('agreement synced')
      })

    setListener.set(listener)
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'syncContract' }
    )
  }
}
