import { Divider } from "antd";
import { GridIcon, ListIcon } from "src/assets/svg";
import { DashboardViews } from "src/types";
import styles from "../styles.module.css";

interface ViewToggleModel {
  selected: DashboardViews;
  onClick: () => void;
}

export const ViewToggle: view<ViewToggleModel> = ({
  selectedView = observe.pages.dashboard.selectedView,
  selectView = update.pages.dashboard.selectedView,
}) => {
  const changeSelectedView = () => {
    if (selectedView === DashboardViews.GRID) {
      selectView.set(DashboardViews.LIST);
    } else {
      selectView.set(DashboardViews.GRID);
    }
  };

  return (
    <div className={`${styles.viewToggle}`}>
      <div>
        <button
          type="button"
          onClick={changeSelectedView}
          className={`${
            selectedView === DashboardViews.LIST
              ? "text-pink-500 border-focus"
              : "text-gray-500 border-focus hover:text-pink-300"
          }`}>
          <ListIcon />
        </button>
        <button
          type="button"
          onClick={changeSelectedView}
          className={`${
            selectedView === DashboardViews.GRID
              ? "text-pink-500 border-focus"
              : "text-gray-500 border-focus hover:text-pink-300"
          }`}>
          <GridIcon />
        </button>
      </div>
      {/* <Divider className={`${styles.tabsDivider}`} /> */}
    </div>
  );
};
