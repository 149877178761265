export const defaultValues: producer = ({
  contractId = observe.legacyContract.id,
  defaultValues = observe.legacyContract.original.defaultValues,
  values = update.legacyContract.syncQueue.values.defaultValues,
}) => {
  if (!contractId || defaultValues == undefined) {
    return;
  }
  if (!defaultValues) {
    values.set({});
    return;
  }
  try {
    const toSave: any = {};

    Object.keys(defaultValues).forEach((varName) => {
      const variable = defaultValues[varName];
      if (toSave[varName]) {
        toSave[varName].valueTimeline.default = {
          value: variable.value,
          createdAt: variable.createdAt,
          type: "default",
        };
      } else {
        toSave[varName] = {
          valueTimeline: {
            default: {
              value: variable.value,
              createdAt: variable.createdAt,
              type: "default",
            },
          },
        };
      }
    });
    values.set(toSave);
  } catch (e) {
    console.error("valuesVersions", e);
  }
};
