export const synchronizeUIDataAddendums: producer = async ({
  addendums = observe.contract.originalv2.addendums,
  updateAddendumsUIData = update.contract.ui.addendums,
}) => {
  if (!addendums) return

  if (Object.keys(addendums).length === 0) {
    updateAddendumsUIData.set({
      asArray: [],
      count: 0,
    })
    return
  }

  const asArray = Object.values(addendums)
  console.log(`Updated`)
  updateAddendumsUIData.set({
    asArray,
    count: asArray.length,
  })
}

type OrderedObject = {
  order: number
  [key: string]: any
}
const OrderSorter = <T extends OrderedObject>(a: T, b: T) => {
  if (a.order > b.order) return 1
  if (a.order < b.order) return -1
  return 0
}
