import { v4 } from 'uuid'
import NumberFormat from 'react-number-format'
import DOMPurify from 'dompurify'
import { SVGs } from 'src/assets'

import { InputFieldLabel, InputFieldLabelReadOnly } from './InputFieldLabel'
import { InputFieldParams, InputFieldReadOnlyParams } from './InputFieldTypes'
import { ComposeHtmlClassName } from './compose-html-class-name'

type DropdownFieldParams = InputFieldReadOnlyParams<string>

const DROPDOWN_CLASS_NAMES =
  'w-full p-3 appearance-none shadow-sm border klp-border1 text-gray-800 leading-tight cursor-pointer border-gray-300 text-gray-500'

export const DropdownField = ({
  name,
  label,
  value,
  required,
  extraClassNames,
  inputExtraClassNames,
  autofocus,
}: DropdownFieldParams) => {
  return (
    <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)} style={{ flexBasis: 0 }}>
      <InputFieldLabelReadOnly label={label} required={required} />
      <div className="relative">
        <input
          type="text"
          id={name}
          className={ComposeHtmlClassName(DROPDOWN_CLASS_NAMES, inputExtraClassNames)}
          autoComplete="off"
          value={value}
          autoFocus={autofocus}
          // disabled={true}
          // onClick={e => }
        />
        <div className={'dropdownArrow'} style={{ position: 'absolute', right: '10px', top: '18px' }}>
          <SVGs.DropdownArrow />
        </div>
      </div>
    </div>
  )
}
