import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'

export const saveContract: producer = async ({
  trigger = observe.pages.contract.triggers.savePaperContract,
  removeTrigger = update.pages.contract.triggers.savePaperContract,

  contractId = get.contract.id,
  session = get.contract.sessions[param.userType],
  currentSession = get.contract.currentSession,
  resetCurrentSession = update.contract.currentSession,
  clearSessions = update.contract.triggers.clearSessions,
  error = update.errors[param.id],
  stateTransition = update.contract.loading.stateTransition,
  rawUser = get.rawUser,
  localFunctions = get.localFunctions
}) => {
  if (!trigger) return
  stateTransition.set(true)

  contractId = contractId.value()
  session = session.value()
  currentSession = currentSession.value()

  try {
    const command = {
      action: CommandActions.SAVE_PAPER_CONTRACT,
      contractId,
      session,
      currentSession,
    }
    console.log('saveContract command', command)
    await axios.post(
      getFunctionsUrl('commandPublisher', localFunctions.value()),
      command,
      {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type': 'application/json'
        }
      }
    )

    clearSessions.set(Date.now())
    resetCurrentSession.remove()
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'saveContract-contract' }
    )
  } finally {
    removeTrigger.remove()
  }
}
