import { Menu, Dropdown } from "antd";
import { PeopleSilhouettesIcon, DropdownArrowOutline } from "src/assets/svg";
import { SVGs } from "src/assets";
import { syncSelectedTenantId } from "./TenantIdsDropdown.syncSelectedTenantId";

export const TenantIdsDropdown: view = ({
  tenant = observe.tenant,
  selectedTenantId = observe.pages.dashboard.selectedTenantId,
  savedSelectedTenantId = observe.user.data.selectedTenantId,
  setSelectedTenantId = update.pages.dashboard.selectedTenantId,
  triggerGetContracts = update.pages.dashboard.triggers.getContracts,
}) => {
  if (!tenant) return null;
  const tenants: any[] = Object.values(tenant);

  if (savedSelectedTenantId && !selectedTenantId) {
    selectedTenantId = savedSelectedTenantId;
    triggerGetContracts.set({ trigger: Date.now(), selectedTenantId });
  }

  if (tenants.length === 1 || !selectedTenantId) {
    setSelectedTenantId.set(tenants[0].id);
  }

  const dropdownMenu = (
    <Menu>
      {tenants.map((t) => (
        <Menu.Item
          key={t.id}
          className="mx-2 my-2"
          onClick={() => {
            setSelectedTenantId.set(t.id);
            triggerGetContracts.set({ trigger: Date.now(), selectedTenantId: t.id });
          }}>
          {t.name || t.number}
        </Menu.Item>
      ))}
    </Menu>
  );

  return tenants.length > 1 ? (
    <Dropdown overlay={dropdownMenu} trigger={["click"]}>
      <button type="button" className="flex items-center border-focus">
        <PeopleSilhouettesIcon width="20" height="20" />
        <span className="ml-2 mr-2">
          {selectedTenantId && (tenant[selectedTenantId]?.name || tenant[selectedTenantId]?.number)}
        </span>
        <DropdownArrowOutline />
      </button>
    </Dropdown>
  ) : (
    <>
      <PeopleSilhouettesIcon width="20" height="20" className="mr-2" />
      <span>{tenants[0].name || tenants[0].number}</span>
    </>
  );
};

TenantIdsDropdown.producers([syncSelectedTenantId]);
