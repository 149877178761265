import { db } from "src/producers/firebase";

export const addRecipient: producer = async ({
  _viewId,
  contractId = get.contract.id,
  recipients = observe.contract.original.recipients,
  trigger = observe.contract.triggers.addRecipient,
  viewState = observe.views[prop._viewId],

  removeTrigger = update.contract.triggers.addRecipient,
  updateViewState = update.views[prop._viewId][param.type],
}) => {
  if (!trigger) {
    return;
  }

  removeTrigger.remove();

  const landlords = recipients?.landlords ? [...recipients?.landlords] : [];
  const tenants = recipients?.tenants ? [...recipients?.tenants] : [];
  const replytoInput = viewState?.replyTo?.input;
  const sendtoInput = viewState?.sendTo?.input;
  let recipientsData = {};

  switch (trigger) {
    case "replyTo":
      recipientsData = {
        landlords: [replytoInput],
        tenants: tenants,
      };
      break;
    case "sendTo":
      recipientsData = {
        landlords: landlords,
        tenants: tenants ? [...tenants, sendtoInput] : [sendtoInput],
      };
      break;
  }

  const contractDb = db.collection("contracts").doc(contractId.value());
  await contractDb.update({ recipients: recipientsData }).catch((err: any) => console.error(err));

  switch (trigger) {
    case "replyTo":
      updateViewState.set({ newInputIsOpen: false }, { type: "replyTo" });
      break;
    case "sendTo":
      updateViewState.set({ newInputIsOpen: false }, { type: "sendTo" });
      break;
  }
};
