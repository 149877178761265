import { db } from "src/producers/firebase";

export const deleteRecipient: producer = async ({
  contractId = get.contract.id,

  recipients = observe.contract.original.recipients,
  trigger = observe.contract.triggers.deleteRecipient,

  removeTrigger = update.contract.triggers.deleteRecipient,
}) => {
  if (!trigger) {
    return;
  }

  removeTrigger.remove();

  let recipientsData = {};

  const landlords = recipients?.landlords ? [...recipients?.landlords] : [];
  const tenants = recipients?.tenants ? [...recipients?.tenants] : [];

  switch (trigger.case) {
    case "replyTo":
      recipientsData = {
        landlords: [],
        tenants: tenants,
      };
      break;

    case "sendTo":
      const newTenants = recipients?.tenants?.filter((mail: string) => mail != trigger.mail);
      recipientsData = {
        landlords: landlords,
        tenants: [...newTenants],
      };
      break;
  }

  const contractDb = db.collection("contracts").doc(contractId.value());
  await contractDb.update({ recipients: recipientsData }).catch((err: any) => console.error(err));
};
