import { Spinner } from "@c11/components";
import { TextEditor, TextEditorReadOnly } from "src/components";
import { VisibleIf } from "src/components_generic/visible-if";
import i18n from "src/producers/languages/i18n";
import styles from "../../../../../styles.module.css"

enum DerogationInputFields {
  DEROGATION_TEXT = "compulsoryDerogation"
}

export const Derogation: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === "active";
  const displayReadOnlyView = isActiveContract || isLocked;

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <DerogationComponent />
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <DerogationComponentReadOnly />
    </VisibleIf>
  </>
};

const DerogationComponent: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name]
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const GetDerogationText = () => {
    return fields[DerogationInputFields.DEROGATION_TEXT]?.value
  }

  return <div>
    <h2>7. {i18n.t('contract.general.derogation')}</h2>

    <div className={`${styles.cardSection}`}>
      <div className="flex flex-row items-center">
        <div className="w-full flex-grow">
          <TextEditor
            readOnly={false}
            value={GetDerogationText()}
            onChange={(e: any) => {
              setChanges.set({
                value: e
              }, {
                name: DerogationInputFields.DEROGATION_TEXT
              })
            }}
          />
        </div>
      </div>
    </div>
  </div>
}

const DerogationComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields
}) => {
  const GetDerogationText = () => {
    return fields[DerogationInputFields.DEROGATION_TEXT]?.values
        || i18n.t(`contract.placeholders.derogationText`);
  }

  return <div>
    <h2>7. {i18n.t('contract.general.derogation')}</h2>

    <div className={`${styles.cardSection}`}>
      <div className="flex flex-row items-center">
        <div className="w-full flex-grow">
          <TextEditorReadOnly
            readOnly={true}
            value={GetDerogationText()}
          />
        </div>
      </div>
    </div>
  </div>
}
