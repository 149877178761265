import { Button } from '@c11/components'
import './SendToSigningButton.css'

import i18n from 'src/producers/languages/i18n'
import { DocumentCheckedIcon, PenIcon } from 'src/assets/svg'
import { TooltipWrapper } from 'src/components'
import { ContractSigningMethod, PartyType } from 'src/types'
import { validateData } from 'src/validations/validateData'

export const SendToSigningButton: view = ({
  hasInvalidFields = observe.contract.hasInvalidFields,
  hasChanges = observe.contract.hasChanges,
  isLoading = observe.contract.loading.sendToSigning,
  showModal = update.modal.moveToSigning,
  // displayedVersion = get.contract.displayedVersion,
  state = get.contract.original.state,
  values = get.contract.values,
  updateHasInvalidFields = update.contract.hasInvalidFields,
  updateInvalidFields = update.contract.invalidFields[param.varName],
  removeAllInvalidFields = update.contract.invalidFields
}) => {
  // displayedVersion = displayedVersion.value()
  state = state.value()
  values = values.value()
  const parties = state.parties ? Object.values(state.parties) : []
  const valid = () => validateData(values, state, updateInvalidFields, removeAllInvalidFields)
  // const valid = validateData(values, state, updateInvalidFields)
  // if (valid) hasInvalidFields = false
  // const hasInvalidFields = !!invalidFields && Object.keys(invalidFields).length != 0;
  const hasEnoughParties = !!parties.find((p: any) => p.partyType == PartyType.TENANT) && !!parties.find((p: any) => p.partyType == PartyType.LANDLORD)
  // const isDisabled = displayedVersion /*|| hasInvalidFields */ || !hasEnoughParties || hasChanges
  const isDisabled = !hasEnoughParties || hasChanges
  const isDigitalSigning = state.signing === ContractSigningMethod.DIGITAL

  let tooltipText
  // if (displayedVersion) tooltipText = i18n.t('button_generate_contract_oldVersion_tooltip')
  if (hasInvalidFields) tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_invalid_fields')
  if (!hasEnoughParties) tooltipText = i18n.t('parties_page.no_parties_warning')
  if (hasChanges) tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_unpublished_changes')
  return (
    <TooltipWrapper
      open={hasInvalidFields}
      disabled={!isDisabled && !hasInvalidFields}
      text={tooltipText}
    >
      <Button
        className="send-signing-btn uppercase"
        variant="primary"
        isLoading={isLoading}
        isDisabled={isDisabled}
        onClick={() => {
          if (valid()) {
            updateHasInvalidFields.set(false)
            showModal.set(true)
          } else {
            updateHasInvalidFields.set(true)
          }
        }}
      >
        {i18n.t('contract_navbar.send_to_signing')}
        {isDigitalSigning ? (
          <DocumentCheckedIcon
            className="ml-2"
            fill={`${isDisabled ? '#C9CACA' : '#000'}`}
          />
        ) : (
          <PenIcon
            className="ml-2"
            fill={`${isDisabled ? '#C9CACA' : '#000'}`}
          />
        )}
      </Button>
    </TooltipWrapper>
  )
}
