import { PartyType } from 'src/types'

export const synchronizeUIDataParties: producer = async ({
  signers = observe.contract.originalv2.signers,
  updateSignersUIData = update.contract.ui.signers,
}) => {
  if (!signers) return

  if (Object.keys(signers).length === 0) {
    updateSignersUIData.set({
      asArray: [],
      count: 0,
      orderOfFirstElement: 0,
      orderOfLastElement: 0,
      orderSortedArray: [],
    })
    return
  }

  const asArray = Object.values(signers)

  const managers = asArray.filter((signer) => signer.partyType === PartyType.LANDLORD).length
  const tenants = asArray.filter((signer) => signer.partyType === PartyType.TENANT).length

  updateSignersUIData.set({
    asArray,
    count: asArray.length,
    orderOfFirstElement: Math.min(...asArray.map((s) => s.order).filter((s) => !!s)),
    orderOfLastElement: Math.max(...asArray.map((s) => s.order).filter((s) => !!s)),
    orderSortedArray: asArray.sort(OrderSorter),
    hasEnoughPartiesForSigning: managers >= 1 && tenants >= 1,
  })
}

type OrderedObject = {
  order: number
  [key: string]: any
}
const OrderSorter = <T extends OrderedObject>(a: T, b: T) => {
  if (a.order > b.order) return 1
  if (a.order < b.order) return -1
  return 0
}
