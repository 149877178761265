import { db } from "src/producers/firebase";

export const syncUsers: producer = async ({ id = observe.legacyContract.id, setUsers = update.users }) => {
  if (!id) {
    return;
  }
  try {
    const usersSnap = await db.collection("users").get();
    const users: any = {};
    usersSnap.forEach((doc) => {
      const user = doc.data();
      if (!user) return;
      users[user.uid] = user.data;
    });
    setUsers.set(users);
  } catch (e) {
    console.error("syncUsers", e);
  }
};
