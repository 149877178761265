import { ContractClauseFactory } from "src/pages/Contract/subviews/ContractForm/ContractClauseFactory/ContractClauseFactory";
import styles from "../../../styles.module.css"


export const DigitalContract: view = ({
  sortedClauses = observe.contract.template.sections.mainContract.clauseIds,
}) => {
  if (!sortedClauses) return null;

  const clausesList = sortedClauses.map((clauseID: any) => {
    return <ContractClauseFactory key={clauseID} clauseID={clauseID} />;
  });
  return <div className={`${styles.contractsContainer }`}><div>{clausesList}</div></div>;
};
