import { AddendumType, UserRole } from "src/types";

import { HeaderSection } from "src/pages/Contract/HeaderSection/HeaderSection";
import { VersionsDropdown } from "src/pages/Contract/subviews/ContractForm/VersionsDropdown/VersionsDropdown";
import { UpdateFoundBar } from "src/pages/Contract/subviews/ContractForm/UpdateFoundBar/UpdateFoundBar";
import { mergeDataForValues } from "src/pages/Contract/subviews/ContractForm/ContractForm.mergeDataForValues";
import { resetDefault } from "src/pages/Contract/subviews/ContractForm/ContractForm.resetDefault";
import { PDFDownloadButton } from "src/pages/Contract/subviews/Addendums/PDFDownloadButton/PDFDownloadButton";
import { CancelationAddendum } from "./CancelationAddendum";
import { DownpaymentAddendum } from "./DownpaymentAddendum";
import { RentalPeriodAndPriceAddendum } from "./RentalPeriodAndPriceAddendum";
import { StorageAddendum } from "./StorageAddendum/StorageAddendum";
import { TransportAddendum } from "./TransportAddendum";
import styles from "../../styles.module.css";
import { ContractPersonSection } from "./ContractPersonSection";
import { ContractTitle } from "./ContractTitle";
import { PartiesCard } from "./PartiesCard";

export const AddendumForm: view = ({
  userRole = get.user.data.role,
  addendumType = observe.contract.original.addendumType,
  id = observe.contract.id,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!addendumType) return null;
  return (
    <div className="overflow-auto pb-20 h-screen">
      <HeaderSection title={""}>
        {isManager && <VersionsDropdown />}
        <PDFDownloadButton />
      </HeaderSection>

      <div className={`${styles.contractsContainer}`}>
        <div>
          {isManager && <UpdateFoundBar />}
          <div>
            {addendumType !== AddendumType.TRANSPORT && <ContractPersonSection />}
            {<ContractTitle />}
            {<PartiesCard />}
            {addendumType === AddendumType.CANCELATION && <CancelationAddendum />}
            {addendumType === AddendumType.DOWNPAYMENT && <DownpaymentAddendum />}
            {addendumType === AddendumType.RENTAL_PERIOD_PRICE && <RentalPeriodAndPriceAddendum />}
            {addendumType === AddendumType.STORAGE && <StorageAddendum />}
            {addendumType === AddendumType.TRANSPORT && <TransportAddendum />}
          </div>
        </div>
      </div>
    </div>
  );
};

AddendumForm.producers([mergeDataForValues, resetDefault]);
