import { storageRef } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const handleDownload: producer = async ({
  _now,
  trigger = observe.contract.triggers.handleDownload,
  updatePendingDownload = update.contract.flags.attachments.pendingDownload[param.id],
  updateTrigger = update.contract.triggers.handleDownload,
  getAttachment = get.contract.original.state.attachments,
  getTempAttachment = get.contract.tempAttachments[arg.trigger],
  error = update.errors[param.id],
}) => {
  updateTrigger.remove();
  if (!trigger) {
    return;
  }

  try {
    const attachments = getAttachment.value();
    const attachment = Object.values(attachments).find((a) => a.id === trigger);
    const tempAttachment = getTempAttachment.value();
    updatePendingDownload.set(_now(), { id: trigger });
    if (attachment) {
      const appendixRef = storageRef.child(attachment.path);
      const URL = await appendixRef.getDownloadURL();
      const response = await fetch(URL);
      const fileBlob = await response.blob();
      saveAs(fileBlob, attachment.fileName);
      updatePendingDownload.remove({ id: trigger });
    } else if (tempAttachment) {
      setTimeout(() => {
        updateTrigger.set(trigger);
      }, 1000);
    }
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "handleDownload-contract" }
    );
    updatePendingDownload.remove({ id: trigger });
  }
};
