import React from "react";

import i18n from "src/producers/languages/i18n";
import { storageRef } from "src/producers/firebase";
import { SVGs } from "src/assets";
import { RouteName, UserRole } from "src/types";

export const Sections: view = ({
  contractId = observe.legacyContract.id,
  mountedAppendix = observe.routes.list[RouteName.LEGACY_CONTRACT].data.appendix.fileName,
  setMountedAppendix = update.routes.list[RouteName.LEGACY_CONTRACT].data.appendix,
  appendices = observe.legacyContract.original.appendices,
  setAddAppendixModal = update.modal.addAppendixModal,
  setDeleteAppendixModal = update.modal.deleteAppendixModal,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isMainContract = !mountedAppendix;

  function handleContractClick() {
    setMountedAppendix.set({});
  }

  function handleAppendixClick(fileName: any, fileId: any, fileType: any) {
    const appendixRef = storageRef.child(`${contractId}/${fileId}`);
    appendixRef.getDownloadURL().then((URL: any) => {
      setMountedAppendix.set({ fileName, fileId, URL, fileType });
    });
  }

  function handleDeleteSection(section: any) {
    setDeleteAppendixModal.set(section);
  }

  const appendicesList = appendices
    ? appendices.map((a: any, index: any) => (
        <div
          key={a.fileName}
          className={`flex items-center px-4 py-3 border-r border-green-300
    ${mountedAppendix !== a.fileName && "bg-white"}
          `}>
          <button onClick={() => handleAppendixClick(a.fileName, a.fileId, a.fileType)} className=" flex items-center">
            <SVGs.AppendixPage className="mr-2" />
            {a.fileName}
          </button>

          {isManager && (
            <>
              <VerticalLine />
              <SVGs.RecycleBin className="cursor-pointer" onClick={() => handleDeleteSection(index)} />
            </>
          )}
        </div>
      ))
    : null;

  return (
    <div className="flex justify-center">
      <div className="border border-green-300 rounded-lg overflow-hidden flex bg-green-300">
        <div className={`px-4 py-3 border-r border-green-300 ${!isMainContract && "bg-white"}`}>
          <button onClick={handleContractClick} className={" flex items-center"}>
            <SVGs.AppendixPage className="mr-2" />
            {i18n.t("general.contract")}
          </button>
        </div>
        {appendicesList}
        {isManager && (
          <button onClick={() => setAddAppendixModal.set(true)} className={"p-3 bg-white "}>
            <SVGs.AppendixPlus />
          </button>
        )}
      </div>
    </div>
  );
};

const VerticalLine: view = () => {
  return (
    <div
      className="bg-gray-200 my-0 mx-2"
      style={{
        width: "2px",
        height: "22px",
      }}></div>
  );
};
