import Fuse from 'fuse.js'

import { SelectMenu, SelectMenuItem, TextField } from '@c11/components'
import { useEffect, useRef } from 'react'

export const SearchSelect: view = ({
  _viewId,
  items,
  searchOptions, // Fuse.js options
  // below are the props that are passed to the TextField component used for search input
  id,
  isDisabled,
  isInvalid,
  isLoading,
  isRequired,
  error,
  showAsterix,
  info,
  label,
  name,
  placeholder,
  type,
  value,
  className,
  // onFocus, // removed prop
  // onBlur, // removed prop
  path,
  // this is a new prop used to handle the selection of an item
  onSelect,
  // replaces isAutoFocus of the TextField component to have the menu expanded by general
  isExpanded,
  // replaces onChange of the TextField component to raise the onSearch Event
  onSearch,
  // setters to state to be used by search results menu
  setIsExpanded = update.views[prop._viewId].isExpanded,
  setFilteredItems = update.views[prop._viewId].filteredItems
}) => {
  if (!items) return null

  setFilteredItems.set(items)
  setIsExpanded.set(isExpanded)

  const ref = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      collapseDropdown && collapseDropdown()
    }
  }

  const expandDropdown = () => {
    setIsExpanded.set(true)
  }

  const collapseDropdown = () => {
    setIsExpanded.set(false)
  }

  const onSelectWithColapse = (item) => {
    onSelect && onSelect(item)
    collapseDropdown()
  }

  const searchItems = (ev) => {
    if (ev?.target?.value?.length > 0) {
      let filteredItems: any[] = []
      const searchTerm = ev.target.value
      const searchEngine = new Fuse([...items], searchOptions)
      const searchResults = searchEngine.search(searchTerm)
      filteredItems = searchResults.map((result) => result.item)
      setFilteredItems.set(filteredItems)
      return ev.target.value
    }
  }

  return (
    <div ref={ref} className="flex-col">
      <TextField
        {...{
          id,
          isDisabled,
          isInvalid,
          isLoading,
          isRequired,
          error,
          showAsterix,
          info,
          label,
          name,
          placeholder,
          type,
          value,
          className,
          path,
          // isAutoFocus, // unused prop
          // onBlur, // unused prop
          onChange: searchItems,
          onFocus: expandDropdown
        }}
      />
      <SearchResultsMenu searchId={_viewId} onClick={onSelectWithColapse}/>
    </div>
  )
}

// Idea for this component is to be as dumb as possible, ideally only minimum logic should be passed to it as props or through the engine state
const SearchResultsMenu: view = ({
  searchId,
  onClick,
  items = observe.views[arg.searchId].filteredItems,
  isExpanded = observe.views[arg.searchId].isExpanded
  // updateIsExpanded = update.views[arg.searchId].isExpanded,
}) => {
  if (!isExpanded) return null
  const selectItemOnClick = (item) => {
    onClick({ target: { value: item } })
    // collapsing is happening through the onClick method passed as prop
    // updateIsExpanded.set(false);
  }
  return (
    <SelectMenu>
      {items.map((item: any) => (
        <SelectMenuItem key={item.name} isDisabled={!onClick} onClick={() => selectItemOnClick(item)}>
          {item.name || 'specify key'}
        </SelectMenuItem>
      ))}
    </SelectMenu>
  )
}
