import { storageRef } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'

const RandExp = require('randexp')

const randomId = new RandExp(/[A-Za-z0-9_]{9}/)

export const addGuaranteeDocument: producer = async ({
  trigger = observe.contract.triggers.addGuaranteeDocument,
  removeTrigger = update.contract.triggers.addGuaranteeDocument,
  updateTempAttachments = update.contract.tempAttachments[param.id],
  error = update.errors[param.id],
  getAttachment = get.contract.original.state.attachments,
  contractId = get.contract.id,
  rawUser = get.rawUser,
  localFunctions = get.localFunctions,
}) => {
  //TODO simmilar to addAttachment
  contractId = contractId.value()

  removeTrigger.remove()
  if (!contractId || !trigger?.data?.name) {
    return
  }
  const fileId = randomId.gen()

  const waitForAttachment = (id, cb) => {
    const attachments = getAttachment.value()
    const existingAttachments = attachments && Object.values(attachments)?.find((x: any) => x.fileId === fileId)
    if (!existingAttachments) {
      setTimeout(() => waitForAttachment(id, cb), 1000)
      return
    }
    cb()
  }
  const reset = () => {
    updateTempAttachments.remove({ id: fileId })
  }

  //TODO move storage logic server side
  const data = {
    fileName: trigger.data.name,
    fileType: trigger.data.type,
    fileId,
    path: `${contractId}/${fileId}`,
    domain: 'guarantee',
  }
  updateTempAttachments.set({ ...data, tempLinkId: fileId }, { id: fileId })
  const appendixRef = storageRef.child(`${contractId}/${fileId}`)
  try {
    await appendixRef.put(trigger.data, { contentType: trigger.data.type })

    const command = {
      action: CommandActions.UPLOAD_GUARANTEE,
      contractId,
      ...data,
    }
    console.log('add guarantee comm', command)
    await axios.post(
      getFunctionsUrl('commandPublisher', localFunctions.value()),
      command,
      {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type': 'application/json'
        }
      }
    )
    waitForAttachment(data.fileId, reset)
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'addGuaranteeDocument-contract' }
    )
  }
}
