'use strict'
// module.exports = validate20
// module.exports.default = validate20
export default validate20
const schema22 = {
  '$id': 'SHOPPING_MALL',
  'type': 'object',
  'definitions': {
    'email': { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' },
    'phone': { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
  },
  'properties': {
    'otherAddendums': { 'type': 'string' },
    'minimumTurnOver': { 'type': 'number' },
    'landlordAccountNo': { 'type': 'number' },
    'landlordAddress': { 'type': 'string' },
    'landlordContactPersonFirstName': { 'type': 'string' },
    'landlordContactPersonLastName': { 'type': 'string' },
    'landlordEmail': { '$ref': '#/definitions/email' },
    'landlordInvoiceAddress': { 'type': 'string' },
    'landlordName': { 'type': 'string' },
    'landlordOrgNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'landlordPhone': { '$ref': '#/definitions/phone' },
    'landlordPostalAddress': { 'type': 'string' },
    'landlordPropertyManager': { 'type': 'string' },
    'minimumMarketingCost': { 'type': 'number' },
    'municipality': { 'type': 'string' },
    'parcelNumber': { 'type': 'string' },
    'propertyAddress': { 'type': 'string' },
    'propertyTotalArea': { 'type': 'number' },
    'leaseDate': { 'type': 'string' },
    'endDate': { 'type': 'string' },
    'startDate': { 'type': 'string' },
    'shoppingMall': { 'type': 'string' },
    'storagePropertyTotalArea': { 'type': 'number' },
    'tenantAddress': { 'type': 'string' },
    'tenantContactPersonFirstName': { 'type': 'string' },
    'tenantContactPersonLastName': { 'type': 'string' },
    'tenantEmail': { '$ref': '#/definitions/email' },
    'tenantInvoiceAddress': { 'type': 'string' },
    'tenantName': { 'type': 'string' },
    'tenantOrgNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'tenantPhone': { '$ref': '#/definitions/phone' },
    'tenantPostalAddress': { 'type': 'string' },
  },
  "required": [
    "landlordAccountNo",
    "landlordAddress",
    "landlordContactPersonFirstName",
    "landlordContactPersonLastName",
    "landlordEmail",
    "landlordInvoiceAddress",
    "landlordName",
    "landlordOrgNo",
    "landlordPostalAddress",
    "landlordPropertyManager",
    "minimumMarketingCost",
    "municipality",
    "parcelNumber",
    "propertyAddress",
    "propertyTotalArea",
    "leaseDate",
    "endDate",
    "startDate",
    "shoppingMall",
    "tenantAddress",
    "tenantContactPersonFirstName",
    "tenantContactPersonLastName",
    "tenantEmail",
    "tenantInvoiceAddress",
    "tenantName",
    "tenantOrgNo",
    "tenantPostalAddress"
  ]
}
const schema23 = { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' }
const schema24 = { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
const pattern0 = new RegExp('^\\S+@\\S+\\.\\S+$', 'u')
const pattern1 = new RegExp('^[+]?[\\d\\s()-]{7,}$', 'u')
const pattern2 = new RegExp('^(E|R)$', 'u')

function validate20 (data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {/*# sourceURL="SHOPPING_MALL" */
  let vErrors = null
  let errors = 0
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.landlordAccountNo === undefined) {
      const err4 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordAccountNo' },
        message: 'must have required property \'' + 'landlordAccountNo' + '\''
      }
      if (vErrors === null) {vErrors = [err4]} else {vErrors.push(err4)}
      errors++
    }
    if (data.landlordAddress === undefined) {
      const err5 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordAddress' },
        message: 'must have required property \'' + 'landlordAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err5]} else {vErrors.push(err5)}
      errors++
    }
    if (data.landlordContactPersonFirstName === undefined) {
      const err6 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordContactPersonFirstName' },
        message: 'must have required property \'' + 'landlordContactPersonFirstName' + '\''
      }
      if (vErrors === null) {vErrors = [err6]} else {vErrors.push(err6)}
      errors++
    }
    if (data.landlordContactPersonLastName === undefined) {
      const err7 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordContactPersonLastName' },
        message: 'must have required property \'' + 'landlordContactPersonLastName' + '\''
      }
      if (vErrors === null) {vErrors = [err7]} else {vErrors.push(err7)}
      errors++
    }
    if (data.landlordEmail === undefined) {
      const err8 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordEmail' },
        message: 'must have required property \'' + 'landlordEmail' + '\''
      }
      if (vErrors === null) {vErrors = [err8]} else {vErrors.push(err8)}
      errors++
    }
    if (data.landlordInvoiceAddress === undefined) {
      const err9 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordInvoiceAddress' },
        message: 'must have required property \'' + 'landlordInvoiceAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err9]} else {vErrors.push(err9)}
      errors++
    }
    if (data.landlordName === undefined) {
      const err10 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordName' },
        message: 'must have required property \'' + 'landlordName' + '\''
      }
      if (vErrors === null) {vErrors = [err10]} else {vErrors.push(err10)}
      errors++
    }
    if (data.landlordOrgNo === undefined) {
      const err11 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordOrgNo' },
        message: 'must have required property \'' + 'landlordOrgNo' + '\''
      }
      if (vErrors === null) {vErrors = [err11]} else {vErrors.push(err11)}
      errors++
    }
    if (data.landlordPostalAddress === undefined) {
      const err12 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordPostalAddress' },
        message: 'must have required property \'' + 'landlordPostalAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err12]} else {vErrors.push(err12)}
      errors++
    }
    if (data.landlordPropertyManager === undefined) {
      const err13 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordPropertyManager' },
        message: 'must have required property \'' + 'landlordPropertyManager' + '\''
      }
      if (vErrors === null) {vErrors = [err13]} else {vErrors.push(err13)}
      errors++
    }
    if (data.minimumMarketingCost === undefined) {
      const err15 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'minimumMarketingCost' },
        message: 'must have required property \'' + 'minimumMarketingCost' + '\''
      }
      if (vErrors === null) {vErrors = [err15]} else {vErrors.push(err15)}
      errors++
    }
    if (data.municipality === undefined) {
      const err17 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'municipality' },
        message: 'must have required property \'' + 'municipality' + '\''
      }
      if (vErrors === null) {vErrors = [err17]} else {vErrors.push(err17)}
      errors++
    }
    if (data.parcelNumber === undefined) {
      const err18 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'parcelNumber' },
        message: 'must have required property \'' + 'parcelNumber' + '\''
      }
      if (vErrors === null) {vErrors = [err18]} else {vErrors.push(err18)}
      errors++
    }
    if (data.propertyAddress === undefined) {
      const err20 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyAddress' },
        message: 'must have required property \'' + 'propertyAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err20]} else {vErrors.push(err20)}
      errors++
    }
    if (data.propertyTotalArea === undefined) {
      const err26 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyTotalArea' },
        message: 'must have required property \'' + 'propertyTotalArea' + '\''
      }
      if (vErrors === null) {vErrors = [err26]} else {vErrors.push(err26)}
      errors++
    }
    if (data.leaseDate === undefined) {
      const err27 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'leaseDate' },
        message: 'must have required property \'' + 'leaseDate' + '\''
      }
      if (vErrors === null) {vErrors = [err27]} else {vErrors.push(err27)}
      errors++
    }
    if (data.endDate === undefined) {
      const err28 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'endDate' },
        message: 'must have required property \'' + 'endDate' + '\''
      }
      if (vErrors === null) {vErrors = [err28]} else {vErrors.push(err28)}
      errors++
    }
    if (data.startDate === undefined) {
      const err29 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'startDate' },
        message: 'must have required property \'' + 'startDate' + '\''
      }
      if (vErrors === null) {vErrors = [err29]} else {vErrors.push(err29)}
      errors++
    }
    if (data.shoppingMall === undefined) {
      const err30 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'shoppingMall' },
        message: 'must have required property \'' + 'shoppingMall' + '\''
      }
      if (vErrors === null) {vErrors = [err30]} else {vErrors.push(err30)}
      errors++
    }
    if (data.minimumTurnOver !== undefined) {
      let data0 = data.minimumTurnOver
      if (!((typeof data0 == 'number') && (isFinite(data0)))) {
        const err37 = {
          instancePath: instancePath + '/minimumTurnOver',
          schemaPath: '#/properties/minimumTurnOver/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err37]} else {vErrors.push(err37)}
        errors++
      }
    }
    if (data.otherAddendums !== undefined) {
      if (typeof data.introText !== 'string') {
        const err42 = {
          instancePath: instancePath + '/introText',
          schemaPath: '#/properties/introText/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err42]} else {vErrors.push(err42)}
        errors++
      }
    }
    if (data.landlordAccountNo !== undefined) {
      let data6 = data.landlordAccountNo
      if (!((typeof data6 == 'number') && (isFinite(data6)))) {
        const err43 = {
          instancePath: instancePath + '/landlordAccountNo',
          schemaPath: '#/properties/landlordAccountNo/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err43]} else {vErrors.push(err43)}
        errors++
      }
    }
    if (data.landlordAddress !== undefined) {
      if (typeof data.landlordAddress !== 'string') {
        const err44 = {
          instancePath: instancePath + '/landlordAddress',
          schemaPath: '#/properties/landlordAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err44]} else {vErrors.push(err44)}
        errors++
      }
    }
    if (data.landlordContactPersonFirstName !== undefined) {
      if (typeof data.landlordContactPersonFirstName !== 'string') {
        const err45 = {
          instancePath: instancePath + '/landlordContactPersonFirstName',
          schemaPath: '#/properties/landlordContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err45]} else {vErrors.push(err45)}
        errors++
      }
    }
    if (data.landlordContactPersonLastName !== undefined) {
      if (typeof data.landlordContactPersonLastName !== 'string') {
        const err46 = {
          instancePath: instancePath + '/landlordContactPersonLastName',
          schemaPath: '#/properties/landlordContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err46]} else {vErrors.push(err46)}
        errors++
      }
    }
    if (data.landlordEmail !== undefined) {
      let data10 = data.landlordEmail
      if (typeof data10 === 'string') {
        if (!pattern0.test(data10)) {
          const err47 = {
            instancePath: instancePath + '/landlordEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {vErrors = [err47]} else {vErrors.push(err47)}
          errors++
        }
      } else {
        const err48 = {
          instancePath: instancePath + '/landlordEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err48]} else {vErrors.push(err48)}
        errors++
      }
    }
    if (data.landlordInvoiceAddress !== undefined) {
      if (typeof data.landlordInvoiceAddress !== 'string') {
        const err49 = {
          instancePath: instancePath + '/landlordInvoiceAddress',
          schemaPath: '#/properties/landlordInvoiceAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err49]} else {vErrors.push(err49)}
        errors++
      }
    }
    if (data.landlordName !== undefined) {
      if (typeof data.landlordName !== 'string') {
        const err50 = {
          instancePath: instancePath + '/landlordName',
          schemaPath: '#/properties/landlordName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err50]} else {vErrors.push(err50)}
        errors++
      }
    }
    if (data.landlordOrgNo !== undefined) {
      let data13 = data.landlordOrgNo
      const _errs29 = errors
      let valid2 = false
      const _errs30 = errors
      if (!((typeof data13 == 'number') && (isFinite(data13)))) {
        const err51 = {
          instancePath: instancePath + '/landlordOrgNo',
          schemaPath: '#/properties/landlordOrgNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err51]} else {vErrors.push(err51)}
        errors++
      }
      var _valid0 = _errs30 === errors
      valid2 = valid2 || _valid0
      if (!valid2) {
        const _errs32 = errors
        if (typeof data13 !== 'string') {
          const err52 = {
            instancePath: instancePath + '/landlordOrgNo',
            schemaPath: '#/properties/landlordOrgNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {vErrors = [err52]} else {vErrors.push(err52)}
          errors++
        }
        var _valid0 = _errs32 === errors
        valid2 = valid2 || _valid0
      }
      if (!valid2) {
        const err53 = {
          instancePath: instancePath + '/landlordOrgNo',
          schemaPath: '#/properties/landlordOrgNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {vErrors = [err53]} else {vErrors.push(err53)}
        errors++
      } else {
        errors = _errs29
        if (vErrors !== null) {if (_errs29) {vErrors.length = _errs29} else {vErrors = null}}
      }
    }
    if (data.landlordPhone !== undefined) {
      let data14 = data.landlordPhone
      if (typeof data14 === 'string') {
        if (!pattern1.test(data14)) {
          const err54 = {
            instancePath: instancePath + '/landlordPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {vErrors = [err54]} else {vErrors.push(err54)}
          errors++
        }
      } else {
        const err55 = {
          instancePath: instancePath + '/landlordPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err55]} else {vErrors.push(err55)}
        errors++
      }
    }
    if (data.landlordPostalAddress !== undefined) {
      if (typeof data.landlordPostalAddress !== 'string') {
        const err56 = {
          instancePath: instancePath + '/landlordPostalAddress',
          schemaPath: '#/properties/landlordPostalAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err56]} else {vErrors.push(err56)}
        errors++
      }
    }
    if (data.landlordPropertyManager !== undefined) {
      if (typeof data.landlordPropertyManager !== 'string') {
        const err57 = {
          instancePath: instancePath + '/landlordPropertyManager',
          schemaPath: '#/properties/landlordPropertyManager/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err57]} else {vErrors.push(err57)}
        errors++
      }
    }
    if (data.minimumMarketingCost !== undefined) {
      let data18 = data.minimumMarketingCost
      if (!((typeof data18 == 'number') && (isFinite(data18)))) {
        const err59 = {
          instancePath: instancePath + '/minimumMarketingCost',
          schemaPath: '#/properties/minimumMarketingCost/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err59]} else {vErrors.push(err59)}
        errors++
      }
    }
    if (data.municipality !== undefined) {
      if (typeof data.municipality !== 'string') {
        const err61 = {
          instancePath: instancePath + '/municipality',
          schemaPath: '#/properties/municipality/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err61]} else {vErrors.push(err61)}
        errors++
      }
    }
    if (data.parcelNumber !== undefined) {
      if (typeof data.parcelNumber !== 'string') {
        const err62 = {
          instancePath: instancePath + '/parcelNumber',
          schemaPath: '#/properties/parcelNumber/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err62]} else {vErrors.push(err62)}
        errors++
      }
    }
    if (data.propertyAddress !== undefined) {
      if (typeof data.propertyAddress !== 'string') {
        const err64 = {
          instancePath: instancePath + '/propertyAddress',
          schemaPath: '#/properties/propertyAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err64]} else {vErrors.push(err64)}
        errors++
      }
    }
    if (data.propertyTotalArea !== undefined) {
      let data29 = data.propertyTotalArea
      if (!((typeof data29 == 'number') && (isFinite(data29)))) {
        const err70 = {
          instancePath: instancePath + '/propertyTotalArea',
          schemaPath: '#/properties/propertyTotalArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err70]} else {vErrors.push(err70)}
        errors++
      }
    }
    if (data.leaseDate !== undefined) {
      if (typeof data.rentalEnd !== 'string') {
        const err71 = {
          instancePath: instancePath + '/leaseDate',
          schemaPath: '#/properties/leaseDate/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err71]} else {vErrors.push(err71)}
        errors++
      }
    }
    if (data.endDate !== undefined) {
      if (typeof data.rentalEnd !== 'string') {
        const err71 = {
          instancePath: instancePath + '/endDate',
          schemaPath: '#/properties/endDate/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err71]} else {vErrors.push(err71)}
        errors++
      }
    }
    if (data.startDate !== undefined) {
      if (typeof data.rentalEnd !== 'string') {
        const err71 = {
          instancePath: instancePath + '/startDate',
          schemaPath: '#/properties/startDate/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err71]} else {vErrors.push(err71)}
        errors++
      }
    }
    if (data.shoppingMall !== undefined) {
      if (typeof data.shoppingMall !== 'string') {
        const err85 = {
          instancePath: instancePath + '/shoppingMall',
          schemaPath: '#/properties/shoppingMall/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err85]} else {vErrors.push(err85)}
        errors++
      }
    }
    if (data.storagePropertyTotalArea !== undefined) {
      let data47 = data.storagePropertyTotalArea
      if (!((typeof data47 == 'number') && (isFinite(data47)))) {
        const err91 = {
          instancePath: instancePath + '/storagePropertyTotalArea',
          schemaPath: '#/properties/storagePropertyTotalArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err91]} else {vErrors.push(err91)}
        errors++
      }
    }
    if (data.tenantAddress !== undefined) {
      if (typeof data.tenantAddress !== 'string') {
        const err99 = {
          instancePath: instancePath + '/tenantAddress',
          schemaPath: '#/properties/tenantAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err99]} else {vErrors.push(err99)}
        errors++
      }
    }
    if (data.tenantContactPersonFirstName !== undefined) {
      if (typeof data.tenantContactPersonFirstName !== 'string') {
        const err100 = {
          instancePath: instancePath + '/tenantContactPersonFirstName',
          schemaPath: '#/properties/tenantContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err100]} else {vErrors.push(err100)}
        errors++
      }
    }
    if (data.tenantContactPersonLastName !== undefined) {
      if (typeof data.tenantContactPersonLastName !== 'string') {
        const err101 = {
          instancePath: instancePath + '/tenantContactPersonLastName',
          schemaPath: '#/properties/tenantContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err101]} else {vErrors.push(err101)}
        errors++
      }
    }
    if (data.tenantEmail !== undefined) {
      let data58 = data.tenantEmail
      if (typeof data58 === 'string') {
        if (!pattern0.test(data58)) {
          const err102 = {
            instancePath: instancePath + '/tenantEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {vErrors = [err102]} else {vErrors.push(err102)}
          errors++
        }
      } else {
        const err103 = {
          instancePath: instancePath + '/tenantEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err103]} else {vErrors.push(err103)}
        errors++
      }
    }
    if (data.tenantInvoiceAddress !== undefined) {
      if (typeof data.tenantInvoiceAddress !== 'string') {
        const err104 = {
          instancePath: instancePath + '/tenantInvoiceAddress',
          schemaPath: '#/properties/tenantInvoiceAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err104]} else {vErrors.push(err104)}
        errors++
      }
    }
    if (data.tenantName !== undefined) {
      if (typeof data.tenantName !== 'string') {
        const err105 = {
          instancePath: instancePath + '/tenantName',
          schemaPath: '#/properties/tenantName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err105]} else {vErrors.push(err105)}
        errors++
      }
    }
    if (data.tenantOrgNo !== undefined) {
      let data61 = data.tenantOrgNo
      const _errs135 = errors
      let valid6 = false
      const _errs136 = errors
      if (!((typeof data61 == 'number') && (isFinite(data61)))) {
        const err106 = {
          instancePath: instancePath + '/tenantOrgNo',
          schemaPath: '#/properties/tenantOrgNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err106]} else {vErrors.push(err106)}
        errors++
      }
      var _valid2 = _errs136 === errors
      valid6 = valid6 || _valid2
      if (!valid6) {
        const _errs138 = errors
        if (typeof data61 !== 'string') {
          const err107 = {
            instancePath: instancePath + '/tenantOrgNo',
            schemaPath: '#/properties/tenantOrgNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {vErrors = [err107]} else {vErrors.push(err107)}
          errors++
        }
        var _valid2 = _errs138 === errors
        valid6 = valid6 || _valid2
      }
      if (!valid6) {
        const err108 = {
          instancePath: instancePath + '/tenantOrgNo',
          schemaPath: '#/properties/tenantOrgNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {vErrors = [err108]} else {vErrors.push(err108)}
        errors++
      } else {
        errors = _errs135
        if (vErrors !== null) {if (_errs135) {vErrors.length = _errs135} else {vErrors = null}}
      }
    }
    if (data.tenantPhone !== undefined) {
      let data62 = data.tenantPhone
      if (typeof data62 === 'string') {
        if (!pattern1.test(data62)) {
          const err109 = {
            instancePath: instancePath + '/tenantPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {vErrors = [err109]} else {vErrors.push(err109)}
          errors++
        }
      } else {
        const err110 = {
          instancePath: instancePath + '/tenantPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err110]} else {vErrors.push(err110)}
        errors++
      }
    }
    if (data.tenantPostalAddress !== undefined) {
      if (typeof data.tenantPostalAddress !== 'string') {
        const err111 = {
          instancePath: instancePath + '/tenantPostalAddress',
          schemaPath: '#/properties/tenantPostalAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err111]} else {vErrors.push(err111)}
        errors++
      }
    }
  } else {
    const err118 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object'
    }
    if (vErrors === null) {vErrors = [err118]} else {vErrors.push(err118)}
    errors++
  }
  validate20.errors = vErrors
  return errors === 0
}
