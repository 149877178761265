import { fields } from "src/templates/KLP-digital-v2/fields";
import i18n from "src/producers/languages/i18n";

export const syncFields: producer = ({
  id = observe.legacyContract.id,
  setTemplate = update.legacyContract.fields,
}) => {
  if (!id) {
    return;
  }
  try {
    const f = JSON.parse(JSON.stringify(fields));
    Object.keys(f).forEach((fieldID) => {
      const field = f[fieldID];
      if (field.label) f[fieldID].label = i18n.t(field.label);
      if (field.placeholder) f[fieldID].placeholder = i18n.t(field.placeholder);
    });
    setTemplate.set(f);
  } catch (e) {
    console.error("syncFields", e);
  }
};
