import { DownloadIconWhite, InfoIcon, SigningSummaryPdfActive, SigningSummaryPdfInactive } from 'src/assets/svg'
import i18n from 'src/producers/languages/i18n'
import { Button } from '@c11/components.button'
import { Divider } from './Divider'

export const InactivePDFListItem: view = ({ isRejected }) => (
  <div className="p-4  flex gap-4 items-center w-full bg-gray-200 h-16" style={{ marginTop: 1 }}>
    {isRejected ? <SigningSummaryPdfInactive /> : <SigningSummaryPdfActive />}
    <div className="w-6/12">
      <div className="flex items-center">
        <span className="mr-2">{isRejected ? i18n.t('summary_page.document_rejected') : i18n.t('summary_page.finalized_pdf')}</span>
      </div>
    </div>
  </div>
)

export const ActivePDFListItem: view = ({
  loadingContractDownload = observe.contract.flags.loadingContractDownload,
  downloadSignedContract = update.contract.triggers.downloadSignedContract,
}) => {
  return (
    <>
      <Divider />
      <div className="p-4  flex gap-4 items-center w-full border border-gray-600 h-16 justify-between">
        <div className="flex w-6/12 items-center">
          <SigningSummaryPdfActive />
          <div className="ml-4">
            <div className="text-green-500 flex items-center ">
              <span className="mr-2">{i18n.t('summary_page.finalized_pdf')}</span>

              {/* <InfoIcon /> */}
            </div>
            <div>{i18n.t('summary_page.contract_pdf_info')}</div>
          </div>
        </div>

        <div>
          <Button
            className="uppercase green-button"
            onClick={() => downloadSignedContract.set(Date.now())}
            isLoading={loadingContractDownload}>
            <span key="2" className="mr-2">
              {i18n.t('summary_page.button_download_contract')}
            </span>
            {<DownloadIconWhite key="1" />}
          </Button>
        </div>
      </div>
    </>
  )
}
