import { UserRole, ContractStatus, ApplicationError, ErrorLevel, ErrorType } from "src/types";

// the producer is long because engine does not support yet spread syntax in DragItem
export const createAttachmentsViewModel: producer = ({
  attachments = observe.contract.original.state.attachments,
  tempAttachments = observe.contract.tempAttachments,
  deletedAppendix = observe.contract.deletedAppendix,
  status = observe.contract.original.state.status,
  pendingDownload = observe.contract.flags.attachments.pendingDownload,
  uppdateAttachmentsViewModel = update.contract.attachmentsViewModel,
  setDeleteAppendixModal = update.modal.deleteAppendixModal,
  updateCurrentSession = update.contract.currentSession.attachments[param.id].selected,
  updateTriggerDownload = update.contract.triggers.handleDownload,
  updateTriggerPreview = update.modal.attachmentPreview,
  userRole = get.user.data.role,
  error = update.errors[param.id],
}) => {
  if (!attachments) return;
  try {
    attachments = JSON.parse(JSON.stringify(attachments));
    if (tempAttachments) {
      Object.values(tempAttachments).forEach((t) => {
        const existingAttachments = Object.values(attachments)?.find((x: any) => x.fileId === t.fileId);
        if (!existingAttachments) attachments = { ...tempAttachments, ...attachments };
      });
    }
    if (deletedAppendix) {
      attachments = Object.entries(attachments)
        .filter((x) => !deletedAppendix[x[1].fileId])
        .reduce((acc, x) => {
          acc[x[0]] = x[1];
          return acc;
        }, {});
    }

    const isTenant = userRole.value() === UserRole.TENANT;
    const isManager = userRole.value() === UserRole.MANAGER;
    const isDraft = status === ContractStatus.DRAFT;
    const isNegotiation = status === ContractStatus.NEGOTIATION;
    const attachmentsViewModel: any = [];

    Object.values(attachments).forEach((attachment: any) => {
      const { fileId, id } = attachment;

      attachment.canModifyAttachment = isManager && (isDraft || isNegotiation) && id;
      let canDeleteAttachments = false;

      if (
        !attachment.tempLinkId && // when the attachment is in temp you cannot delete it
        ((isTenant && attachment.domain === "guarantee") ||
          (isManager && (isDraft || isNegotiation) && attachment.domain !== "building"))
      )
        canDeleteAttachments = true;

      attachment.canDeleteAttachments = canDeleteAttachments;
      attachment.pendingDownload = pendingDownload?.[fileId];
      attachment.hasButtomControlls = !isTenant;
      attachment.setDeleteAppendixModal = () => setDeleteAppendixModal.set(id);
      attachment.includeInSigningToggle = () => updateCurrentSession.set(!attachment.selected, { id });
      attachment.updateTriggerDownload = () => updateTriggerDownload.set(id);
      attachment.updateTriggerPreview = () => updateTriggerPreview.set(id);

      attachmentsViewModel.push(attachment);
    });

    attachmentsViewModel.sort((a, b) => a.order - b.order);
    uppdateAttachmentsViewModel.set(attachmentsViewModel);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.APPLICATION,
      } as ApplicationError,
      { id: "createAttachmentsViewModel-contract" }
    );
  }
};
