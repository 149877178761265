import React from "react";
import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { DownloadPDFButton } from "./DownloadPDFButton";
import { VersionsDropdown } from "./versionsDropdown/VersionsDropdown";
import { ActionButtons } from "./ActionButtons";
import moment from "moment";
import { HelpCircle } from "src/components";

export const ContractVersion: view = ({
  lastSeenByTenant = observe.legacyContract.original.lastSeenByTenant,
  lastSeenFeature = observe.featureFlags.lastSeenFeature.main,
}) => {
  return (
    <div style={{ flexGrow: 1 }} className="border-b w-full py-8 px-5 klp-border1 relative">
      <div className="flex items-start mb-4 justify-between">
        <div className="flex items-center">
          <h3 className="text-lg  flex items-center">
            <SVGs.Rollback className="mr-2 mt-1" />
            {i18n.t("contract_sidebar_contract_version_title")}
          </h3>
          {lastSeenByTenant && lastSeenFeature && (
            <p className="ml-3">{`${i18n.t("legacy.contract_sidebar_last_seen")} ${moment(
              lastSeenByTenant.toDate()
            ).format("DD.MM.YY H:mm")}`}</p>
          )}
        </div>
        <div>
          <HelpCircle text={i18n.t("legacy.tooltip_contract_version")} />
        </div>
      </div>

      <div className="flex mb-5">
        <VersionsDropdown />
        <div
          style={{ height: 60, width: 60, marginLeft: 12 }}
          className="border klp-border1 rounded-md bg-white flex items-center justify-center">
          <DownloadPDFButton />
        </div>
      </div>
      <ActionButtons />
    </div>
  );
};
