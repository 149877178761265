import i18n from "src/producers/languages/i18n";

import { DateFieldGeneral, DropdownInputGeneral } from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import styles from "../../../../styles.module.css";

export const RentalPeriodManager: view = () => (
  <div className={`${styles.rentalPeriodTenant}`}>
    <div className={`${styles.datesInputRow}`}>
      <div className="flex flex-col w-64">
        <DateFieldGeneral
          varName="rentalStart"
          required
          placeholder={i18n.t("placeholders.select_date")}
          label={i18n.t("clause_rental_period_rental_start")}
        />
      </div>
      <div className="flex flex-col w-64">
        <DateFieldGeneral
          varName="rentalEnd"
          placeholder={i18n.t("placeholders.select_date")}
          label={i18n.t("clause_rental_period_rental_end")}
        />
      </div>
      <DropdownInputGeneral
        choises={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
        varName="noticePeriod"
        required
        placeholder={`${i18n.t("placeholders.add_amount")}`}
      />
    </div>
  </div>
);
