import { AddendumFields } from '../../DigitalContract/ContractTab/templates/addendums/fields'

export const updateIndices: producer = ({
  fields = observe.contract.originalv2.fields,
  setIndices = update.contract.originalv2.indices,
}) => {
  if (!fields) return

  const fieldsOrder = [
    AddendumFields.BACKGROUND_AND_PURPOSE,

    AddendumFields.RENTAL_PERIOD_DESCRIPTION,

    AddendumFields.RENTAL_PRICE_DESCRIPTION,

    AddendumFields.MARKETING_COST_DESCRIPTION,

    AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS,

    AddendumFields.SECURITY,

    AddendumFields.DEROGATION,

    AddendumFields.OTHER_PROVISIONS,
  ]

  const changeOfRentalPeriod = fields?.[AddendumFields.CHANGE_OF_RENTAL_PERIOD]?.value
  const hasPotentialOptions = fields?.[AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS]?.value
  const hasMarketingCost = fields?.[AddendumFields.HAS_MARKETING_COST]?.value

  const fieldsWithToggle = {
    [AddendumFields.RENTAL_PRICE_DESCRIPTION]: changeOfRentalPeriod,
    [AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS]: hasPotentialOptions,
    [AddendumFields.MARKETING_COST_DESCRIPTION] : hasMarketingCost
  }

  const firstNum = 1
  const tempIndices = {
    [fieldsOrder[0]]: firstNum,
  }

  for (let i = 1; i < fieldsOrder.length; i++) {
    const field = fieldsOrder[i]
    const prevField = fieldsOrder[i - 1]
    const fieldType = field as keyof typeof fieldsWithToggle
    const prevFieldType = prevField as keyof typeof fieldsWithToggle

    const hasToggle = Object.keys(fieldsWithToggle).includes(field)

    if (!hasToggle || (hasToggle && fieldsWithToggle[fieldType])) tempIndices[fieldType] = tempIndices[prevFieldType] + 1
    else tempIndices[fieldType] = tempIndices[prevFieldType]
  }

  setIndices.set(tempIndices)
}
