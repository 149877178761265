// import { Button } from "@c11/view";
// import "./PDFDownloadButton.css";
//
// import { DownloadIcon } from "src/assets/svg";
// import i18n from "src/producers/languages/i18n";
//
// export const PDFDownloadButton: view = ({
//   selectedShoppingMall = observe.contract.original.state.building.id,
//   pdfServerLoading = observe.contract.flags.loadingpdf,
//   generatePDF = update.contract.triggers.generatePDF,
//   saveStatus = observe.contract.loading.saveStatus,
// }) => {
//   const shouldDisable = !!!selectedShoppingMall || saveStatus;
//
//   return (
//     <Button
//       variant="secondary"
//       className="download-contract-btn flex uppercase gap-2 "
//       onClick={() => generatePDF.set(Date.now())}
//       isDisabled={shouldDisable}
//       isLoading={pdfServerLoading}>
//       <DownloadIcon />
//       <span>{i18n.t("contract_navbar.button_preview_pdf_contract")}</span>
//     </Button>
//   );
// };
//TODO add this back once we fix issue with popup and service account
import { Button } from "@c11/components";
import "./PDFDownloadButton.css";

import { DownloadIcon } from "src/assets/svg";
import i18n from "src/producers/languages/i18n";
import { functions } from "src/producers/firebase";

export const PDFDownloadButton: view = ({
  selectedShoppingMall = observe.contract.original.state.building.id,
  pdfServerLoading = observe.contract.flags.loadingpdf,
  contractId = get.contract.original.id,
}) => {
  const shouldDisable = !!!selectedShoppingMall;

  const triggerCreatePdfFunction = async () => {
    const viewPdf = functions.httpsCallable("viewPdf");
    const ref = window.open();
    ref?.document.write("loading");
    console.log("View pdf");
    const resp = await viewPdf({ contractId: contractId.value() });
    console.log("PDF URL", resp.data);
    ref.location = resp.data;
  };

  return (
    <div>
      <Button
        variant="secondary"
        className="preview-contract-btn flex uppercase gap-2 "
        onClick={() => {
          triggerCreatePdfFunction();
        }}
        isDisabled={shouldDisable}
        isLoading={pdfServerLoading}>
        <DownloadIcon />
        <span>{i18n.t("contract_navbar.button_preview_pdf_contract")}</span>
      </Button>
    </div>
  );
};
