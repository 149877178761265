import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import i18n from "src/producers/languages/i18n";
import { populateTenant } from "src/pages/Contract/subviews/ContractForm/PartiesClause/PartiesClause.populateTenant";
import { UserRole } from "src/types";
import { PartiesClauseManager } from "./PartiesClauseManager";
import { PartiesClauseTenant } from "./PartiesClauseTenant";
import { PartiesClauseReadOnly } from "./PartiesClauseReadOnly";

export const PartiesClause: view = ({
  userRole = observe.user.data.role,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
}) => {
  const isManager = userRole === UserRole.MANAGER;
  return (
    <ClauseCard title={i18n.t("contract_page.contract_tenant_clause_h")}>
      {isReadOnlyContract ? <PartiesClauseReadOnly /> : isManager ? <PartiesClauseManager /> : <PartiesClauseTenant />}
    </ClauseCard>
  );
};

PartiesClause.producers([populateTenant]);
