import React from "react";

import { Modal } from "src/components";
import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";

export const GenerateContractModal: view = ({
  show = observe.modal.generateContract,
  triggerVersion = update.legacyContract.triggers.triggerVersion,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!show || !isManager) return null;
  return (
    <Modal
      title={i18n.t("modals.generate_contract_title")}
      okButtonText={i18n.t("button_generate_contract")}
      image={() => <SVGs.ModalGenerateContract />}
      onOkClick={() => triggerVersion.set({ callback: "generateContract" })}>
      <p key="1" className="mb-1">
        {i18n.t("modals.generate_contract_p1")}
      </p>
      <p key="2">{i18n.t("modals.generate_contract_p2")}</p>
    </Modal>
  );
};
