// import {algoliaIndex} from "src/producers/algolia";

export const FetchTenants: producer = async ({
  searchQuery = observe.contract.originalv2View.tenantsSearchQuery,
  algoliaIndex = observe.algoliaIndex,
  updateSearchResults = update.contract.originalv2View.searchResults,
}) => {
  console.log('searchQuery', searchQuery)
  if(!searchQuery) return
  if (searchQuery?.length < 3)
    updateSearchResults.set([])
  else
    algoliaIndex.search(searchQuery).then((results: any) => {
      console.log('results', results)
      updateSearchResults.set(results.hits)
    })
}
