import { ContractStatus } from "src/types"


const lockedStatuses = [
    ContractStatus.ACTIVE,
    ContractStatus.SIGNING,
]

export const lockContract: producer = ({
    isEditedBy = observe.contract.originalv2.isEditedBy,
    status = observe.contract.originalv2.status,

    uid = get.user.uid.value(),

    isLocked = update.contract.isLocked,
}) => {
    console.log('lockContract', isEditedBy, status)
    if((isEditedBy && isEditedBy !== uid) || lockedStatuses.includes(status)) 
        isLocked.set(true)
    else 
        isLocked.set(false)
}