import React from "react";

import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";

export const ContractSignBubble: view = ({ feed }) => {
  const { createdAt } = feed;
  return (
    <div style={{ backgroundColor: "#FDCECF" }} className="p-4 w-full rounded mb-3 ">
      <div className="flex justify-between mb-2">
        <span className="flex">
          <SVGs.ContractLockedPage />
          <span className="ml-2 font-medium">{i18n.t("sidebar.contract_generated")}</span>
        </span>
        <span className="font-12 ">{createdAt}</span>
      </div>
      <p>{i18n.t("sidebar.contract_generated_p1")}</p>
    </div>
  );
};
