import { functions } from "src/producers/firebase";
import { CommandActions } from "src/types/commands/command";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const setShoppingMall: producer = async ({
  trigger = observe.contract.triggers.setShoppingMall,
  contractId = get.contract.id,
  shoppingMalls = get.domain.shoppingMalls,
  removeTrigger = update.contract.triggers.setShoppingMall,
  setShoppingMallId = update.contract.original.state.building.id,
  writeValue = update.contract.toSave.changes[param.varName],
  error = update.errors[param.id],
}) => {
  if (trigger == undefined) {
    return;
  }

  removeTrigger.remove();
  const shoppingMall = shoppingMalls.value()[trigger];
  contractId = contractId.value();

  // optimistic update the id
  setShoppingMallId.set(shoppingMall?.id);
  try {
    const commandPublisher = functions.httpsCallable("eventlessCommandHandler");
    const command = {
      action: CommandActions.SELECT_BUILDING,
      contractId,
      building: shoppingMall,
    };
    await commandPublisher(command);

    const mapDataToFields = {
      landlordName: shoppingMall?.companyName,
      landlordOrgNo: shoppingMall?.organisationalNumber,
      landlordPostalAddress: shoppingMall?.postalAddress,
      landlordInvoiceAddress: shoppingMall?.invoiceAddress,
      landlordAccountNo: shoppingMall?.accountNumber,
      landlordContactPersonFirstName: shoppingMall?.propertyManagerUserInfo?.firstName,
      landlordContactPersonLastName: shoppingMall?.propertyManagerUserInfo?.lastName,
      landlordPhone: shoppingMall?.propertyManagerUserInfo?.phone,
      landlordEmail: shoppingMall?.propertyManagerUserInfo?.email,
      landlordPropertyManager: shoppingMall?.companyInfo?.name || "KLP EIENDOM OSLO AS",
      shoppingMall: shoppingMall?.name,
      propertyAddress: shoppingMall?.address,
      landlordAddress: shoppingMall?.address,
      municipality: shoppingMall?.municipality,
      parcelNumber: shoppingMall?.parcelNumber,
    };

    Object.entries(mapDataToFields).forEach(([varName, value]) => {
      writeValue.set(
        {
          value: value || "",
          createdAt: Date.now(),
        },
        { varName }
      );
    });
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.APPLICATION,
      } as ApplicationError,
      { id: "setShoppingMall-contract" }
    );
  }
};
