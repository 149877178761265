import i18n from "src/producers/languages/i18n";
import {
  InsideCard
} from "src/pages/Contract/subviews/ContractForm/ClauseCard/InsideCard";
import {
  DateFieldGeneral
} from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import { RadioButton, RadioButtonGroup } from "@c11/components";
import {
  PlainTextFieldGeneral
} from "src/pages/Contract/subviews/ContractForm/fields/PlainTextFieldGeneral";
import { FieldInputType } from "src/templates/types/template";
import { AddendumType, AgreementType } from "src/types";

export const RentalCostTenant: view = ({
  getPaymentProvisionFirstPayDueDate = observe.contract.values.paymentProvisionFirstPayDueDate.displayedValue,
  getPaymentProvisionFirstPayDueDateFromChanges = observe.contract.currentSession.changes.paymentProvisionFirstPayDueDate.value,
  contractType = get.contract.original.addendumType,
}) => {
  const i = getPaymentProvisionFirstPayDueDateFromChanges || getPaymentProvisionFirstPayDueDate;
  contractType = contractType.value()
  const storageLabelTranslation = contractType.value() === AddendumType.STORAGE ? 'clause_rental_costs_shared_cost_storage_addendum' : 'clause_rental_costs_shared_cost'
  return (
    <>
      <InsideCard key="Property" title={"4.1 Lagerleie"}>
        <p className="mb-2 w-full">{i18n.t("storage_addendum.4_1p")}</p>
        <PlainTextFieldGeneral
          varName="storageRent"
          placeholder={i18n.t("clause_rental_property_store_concept_storage_rent_p")}
          label={i18n.t("clause_rental_property_store_concept_storage_rent")}
          inputType={FieldInputType.Number}
        />
      </InsideCard>
      <InsideCard key="Property"
                  title={`4.2 ${i18n.t("storage_addendum.rental_costs_shared_cost_title")}`}>
        <p className="mb-2 w-full">{i18n.t("storage_addendum.4_2p")}</p>

        <PlainTextFieldGeneral
          varName="sharedCosts"
          placeholder={i18n.t("placeholders.add_amount")}
          label={i18n.t(storageLabelTranslation)}
          inputType={FieldInputType.Number}
        />
      </InsideCard>
      <InsideCard key="Property"
                  title={`4.3 ${i18n.t("clause_rental_costs_index_adjustment_title")}`}>
        <p className="mb-2 w-full">{i18n.t("storage_addendum.4_3p")}</p>
        <div className="flex flex-col w-64 mr-3">
          <span>
            <span className="text-pink-600">*</span>
            {i18n.t("clause_rental_costs_index_adjustment_first_time_regulation")}
          </span>
          <DateFieldGeneral
            varName="firstTimeRegulation"
            placeholder={i18n.t("placeholders.select_date")}
            extraClasses="pr-8"
            readOnly="true"
          />
        </div>
        <div className="flex flex-col w-64">
          <span>
            <span className="text-pink-600">*</span>
            {i18n.t("clause_rental_costs_index_adjustment_basic_index")}
          </span>
          <DateFieldGeneral
            varName="indexAdjustmentBasicIndex"
            placeholder={i18n.t("placeholders.select_date")}
            extraClasses="pr-8"
            readOnly="true"
          />
        </div>
      </InsideCard>
      <InsideCard key="provision"
                  title={`4.4 ${i18n.t("storage_addendum.security_payment")}`}>
        <RadioButtonGroup selectedValue={String(i)}>
          <RadioButton key={`11`} className="mb-5 mr-4" value={"1"}
                       isDisabled={true}>
            {i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_1")}
          </RadioButton>
          <RadioButton key={`12-`} className="mb-5" value={"2"}
                       isDisabled={true}>
            {i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_2")}
          </RadioButton>
        </RadioButtonGroup>
        <p className="w-full mt-2">{i18n.t("storage_addendum.4_4p")}</p>
      </InsideCard>
    </>
  );
};
