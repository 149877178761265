import { SVGs } from 'src/assets'
import i18n from 'src/producers/languages/i18n'
import { Button } from '@c11/components.button'
import { DownloadIcon, PenIcon } from 'src/assets/svg'
import { VisibleIf } from 'src/components_generic/visible-if'

export const WarningBar: view = (
  props
) => {
  return (
    <div className=" bg-pink-100 flex px-6 py-4 mt-2">
      <div className="flex flex-auto items-center ">
        <SVGs.ExclamationCircleUnfilled className="mr-2"/>
        <div className="ml-3">
          <div className="">{props.message}</div>
        </div>
        <VisibleIf predicate={props.hasButton}>
          <Button
            variant="secondary"
            className="preview-contract-btn flex uppercase gap-2 "
            onClick={async () => {
              props.buttonOnClick()
            }}
            isLoading={false}
          >
            <PenIcon/>
            <span>{i18n.t(props.buttonText)}</span>
          </Button>
        </VisibleIf>
      </div>
    </div>
  )
}
