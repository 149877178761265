import { db } from 'src/producers/firebase'
import {ApplicationError, ErrorLevel, ErrorType, LogLevels, UserRole} from 'src/types'
import { UpdateInputField } from 'src/pages/ContractV2/producers/synchronize/updateInputField'

export const persistResetDefault: producer = async ({
  fieldsToReset = observe.contract.changes.resetToDefault,
  isLocked = get.contract.isLocked.value(),
  contractId = get.contract.id.value(),
  originalFields = get.contract.originalv2.fields.value(),
  defaultValue = get.contract.originalv2.defaultValues[param.field],

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,

  updateChanges = update.contract.changes.resetToDefault,
  updateContractStateFields = update.contract.originalv2.fields,
  error = update.errors[param.id],
}) => {
  console.log('persistResetDefault', fieldsToReset)
  if (!fieldsToReset || Object.keys(fieldsToReset).length < 1) return
  if (isLocked) return

  try {
    let fieldChanges: any = {}
    for (let field in fieldsToReset) {
      const value = defaultValue.value({ field }).value
      fieldChanges[field] = { value }
      UpdateInputField(field, value)
    }
    console.log('fieldChanges', fieldChanges)
    isSaving.set(true)
    const updatedAt = new Date().toISOString()
    await db
      .collection('contracts')
      .doc(contractId)
      .set({ fields: fieldChanges, updatedAt }, { merge: true })
    updateContractStateFields.merge(fieldChanges)
    updateChanges.remove()
    successfulSave.set(true)
  } catch (e) {
    console.error('persistFieldChanges', e)
    failedSave.set(true)
    updateContractStateFields.set(originalFields)
    error.set(
      {
        message: 'saving-changes-to-database',
        level: LogLevels.ERROR,
      } as ApplicationError,
      { id: 'persistFieldChanges' }
    )
  }
  isSaving.set(false)
}
