import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { DragItem } from "src/pages/Contract/subviews/Attachments/AttachmentSection/DragItem";
import { AttachmentCard } from "src/pages/Contract/subviews/Attachments/AttachmentCard/AttachmentCard";
import { moveElement } from "src/pages/Contract/subviews/Attachments/AttachmentSection/AttachmentSection.moveElement";

import styles from "../../../styles.module.css"

export const AttachmentSection: view = ({
  attachmentsViewModel = observe.contract.attachmentsViewModel,
  move = update.contract.triggers.dragAttachment,
}) => {
  if (!attachmentsViewModel) return null;
  const moveItem = (sourceId, destinationId) => move.set({ sourceId, destinationId });

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`${styles.attachmentCardsRow}`}>
        <div>
          {attachmentsViewModel.map((a: any, index: any) => (
            <DragItem key={a.id} id={a.id} onMoveItem={moveItem}>
              <AttachmentCard index={index} attachment={a} />
            </DragItem>
          ))}
        </div>
      </div>
    </DndProvider>
  );
};

AttachmentSection.producers(moveElement);
