import _, { get, hasIn } from 'lodash'
import { UpdateInputField } from 'src/pages/ContractV2/producers/synchronize/updateInputField'
import {ContractTemplate} from "src/pages/ContractV2/contract.types";

const fieldsToUpdate: {
  [key: string]: string | Array<string>
} = {
  accountNumber: 'landlordAccountNo',
  address: ['landlordAddress', 'propertyAddress'],
  'propertyManagerUserInfo.firstName': 'landlordContactPersonFirstName',
  'propertyManagerUserInfo.lastName': 'landlordContactPersonLastName',
  'propertyManagerUserInfo.email': 'landlordEmail',
  invoiceAddress: 'landlordInvoiceAddress',
  name: ['landlordName', 'shoppingMall'],
  organisationalNumber: 'landlordOrgNo',
  'propertyManagerUserInfo.phone': 'landlordPhone',
  postalAddress: 'landlordPostalAddress',
  companyName: 'landlordPropertyManager',
  municipality: 'municipality',
  parcelNumber: 'parcelNumber'
}

const formatAttachments = (attachments: any, building: any, template: string) => {
  let files: any = {}
  let group = template === ContractTemplate.SHOPPING_MALL ? 'mall': template === ContractTemplate.PARKING ? 'parking' : 'other'
  console.log('tlpl', template, group)
  let index = 1
  for (const key of Object.keys(building.files)) {
    let buildingFile = building.files[key]
    if(buildingFile.group !== group) continue
    files[buildingFile.id] = {
      name: buildingFile.name,
      type: buildingFile.type === 'pdf' ? 'application/pdf' : buildingFile.type,
      id: buildingFile.id,
      path: `defaultFiles/${buildingFile.path}`,
      createdAt: buildingFile.created,
      domain: 'building',
      order: index++
    }
  }
  return files
}

const previousBuildingFiles = (buildingFiles: any, attachments: any) => {
  if (!attachments) return []
  let filesToRemove: any = []
  for (const key of Object.keys(attachments)) {
    const file = attachments[key]
    if (file.domain !== 'building') continue
    const buildingFileIds = Object.keys(buildingFiles)
    if (buildingFileIds.includes(String(file.id))) continue
    filesToRemove.push(file.id)
  }
  return filesToRemove
}

export const syncBuildingToContract = async (
  building: any,
  attachments: any,
  contractField: any,
  template: string,
  updateFieldChanges: any,
  updateAttachmentsToRemove: any,
  updateAttachmentsChanges: any
) => {
  for (const key of Object.keys(fieldsToUpdate)) {
    if (!hasIn(building, key)) continue
    const value = get(building, key)

    if (Array.isArray(fieldsToUpdate[key])) {
      for (const field of fieldsToUpdate[key]) {
        const originalField = contractField.value({ field })
        if (originalField?.value === value) continue
        updateFieldChanges.set({ value }, { field })
        UpdateInputField(field, value)
      }
    } else {
      const originalField = contractField.value({ field: fieldsToUpdate[key] })
      if (originalField?.value === value) continue
      updateFieldChanges.set({ value }, { field: fieldsToUpdate[key] })
      UpdateInputField(fieldsToUpdate[key] as string, value)
    }
  }
  const attachmentsToRemove = previousBuildingFiles(building.files, attachments)
  console.log('building.files', attachmentsToRemove)
  if (attachmentsToRemove.length > 0) updateAttachmentsToRemove.set(attachmentsToRemove)
  console.log('building.files', building.files)
  if (building.files) {
    const files = formatAttachments(building.files, building, template)
    if (_.isEqual(files, attachments)) return
    updateAttachmentsChanges.set(files)
  }
}

// export const syncBuildingToContract: producer = async ({
//   building = observe.contract.originalv2.building,
//
//   contractField = get.contract.originalv2.fields[param.field],
//   attachments = get.contract.originalv2.attachments,
//
//   updateAttachmentsToRemove = update.contract.changes.attachmentsToRemove,
//   updateFieldChanges = update.contract.changes.fields[param.field],
//
//   updateAttachmentsChanges = update.contract.changes.attachments.building,
// }) => {
//   if (!building) return
//   for (const key of Object.keys(fieldsToUpdate)) {
//     if (!hasIn(building, key)) continue
//     const value = get(building, key)
//
//     if (Array.isArray(fieldsToUpdate[key])) {
//       for (const field of fieldsToUpdate[key]) {
//         const originalField = contractField.value({ field })
//         if (originalField?.value === value) continue
//         updateFieldChanges.set({ value }, { field })
//         UpdateInputField(field, value)
//       }
//     } else {
//       const originalField = contractField.value({ field: fieldsToUpdate[key] })
//       if (originalField?.value === value) continue
//       updateFieldChanges.set({ value }, { field: fieldsToUpdate[key] })
//       UpdateInputField(fieldsToUpdate[key] as string, value)
//     }
//   }
//   const attachmentsToRemove = previousBuildingFiles(building.files, attachments.value())
//   if (attachmentsToRemove.length > 0) updateAttachmentsToRemove.set(attachmentsToRemove)
//   if (building.files) {
//     const files = formatAttachments(building.files, building)
//     if (_.isEqual(files, attachments.value())) return
//     updateAttachmentsChanges.set(files)
//   }
// }
