import { ApplicationError, ErrorLevel, ErrorType, UserRole } from 'src/types'

export const userOnboarding: producer = async ({
  isReady = observe.user.isReady,
  user = get.user,
  triggerSetUserDeptModal = update.modal.newUserLogin,
  error = update.errors[param.id],
}) => {
  user = user.value()
  if (!isReady) {
    return
  }
  try {
    if (!user.data.departmentId && user?.data?.role === UserRole.MANAGER) {
      triggerSetUserDeptModal.set(true)
    }
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'userOnboarding' }
    )
  }
}
