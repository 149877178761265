export const propertyTotalArea: producer = ({
  fields = observe.contract.originalv2.fields,

  propertyExclusiveArea = get.contract.originalv2.fields.propertyExclusiveArea.value,
  propertyCommonArea = get.contract.originalv2.fields.propertyCommonArea.value,
  originalTotalArea = get.contract.originalv2.fields.propertyTotalArea.value,

  propertyTotalArea = update.contract.changes.fields.propertyTotalArea.value,
}) => {
  if (!fields) return
  propertyExclusiveArea = propertyExclusiveArea.value()
  propertyCommonArea = propertyCommonArea.value()
  if (!propertyExclusiveArea || !propertyCommonArea) {
    propertyTotalArea.set(null)
    return
  }

  const value = propertyCommonArea + propertyExclusiveArea
  if (originalTotalArea.value() === value) return
  propertyTotalArea.set(value)
}
