'use strict'
// module.exports = validate20
// module.exports.default = validate20
export default validate20
const schema22 = {
  '$id': 'TRANSPORT',
  'type': 'object',
  'definitions': {
    'email': { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' },
    'phone': { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
  },
  'properties': {
    'tenantContactPersonFirstName': { 'type': 'string' },
    'tenantContactPersonLastName': { 'type': 'string' },
    'tenantPhone': { '$ref': '#/definitions/phone' },
    'tenantEmail': { '$ref': '#/definitions/email' },
    'title': { 'type': 'string' },
    'backgroundAndPurpose': { 'type': 'string' },
    'otherProvisions': { 'type': 'string' },
    'tenantName': { 'type': 'string' },
    'tenantOrgNo': { 'type': 'string' },
    'tenantAddress': { 'type': 'string' },
    'tenantPostalAddress': { 'type': 'string' },
    'tenantInvoiceAddress': { 'type': 'string' },
    'conditionsOfTransport': { 'type': 'string' },
    'compulsoryDerogation': { 'type': 'string' }
  },
  'required': ['tenantEmail', 'title', 'backgroundAndPurpose', 'tenancyCancellation', 'tenantName', 'tenantOrgNo', 'tenantAddress', 'tenantPostalAddress', 'tenantInvoiceAddress', 'conditionsOfTransport']
}
const schema23 = { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
const schema24 = { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' }
const pattern0 = new RegExp('^[+]?[\\d\\s()-]{7,}$', 'u')
const pattern1 = new RegExp('^\\S+@\\S+\\.\\S+$', 'u')

function validate20 (data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {/*# sourceURL="TRANSPORT" */
  let vErrors = null
  let errors = 0
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.tenantEmail === undefined) {
      const err0 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenantEmail' },
        message: 'must have required property \'' + 'tenantEmail' + '\''
      }
      if (vErrors === null) {vErrors = [err0]} else {vErrors.push(err0)}
      errors++
    }
    if (data.title === undefined) {
      const err1 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'title' },
        message: 'must have required property \'' + 'title' + '\''
      }
      if (vErrors === null) {vErrors = [err1]} else {vErrors.push(err1)}
      errors++
    }
    if (data.backgroundAndPurpose === undefined) {
      const err2 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'backgroundAndPurpose' },
        message: 'must have required property \'' + 'backgroundAndPurpose' + '\''
      }
      if (vErrors === null) {vErrors = [err2]} else {vErrors.push(err2)}
      errors++
    }
    // if (data.tenancyCancellation === undefined) {
    //   const err3 = {
    //     instancePath,
    //     schemaPath: '#/required',
    //     keyword: 'required',
    //     params: { missingProperty: 'tenancyCancellation' },
    //     message: 'must have required property \'' + 'tenancyCancellation' + '\''
    //   }
    //   if (vErrors === null) {vErrors = [err3]} else {vErrors.push(err3)}
    //   errors++
    // }
    if (data.tenantName === undefined) {
      const err4 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenantName' },
        message: 'must have required property \'' + 'tenantName' + '\''
      }
      if (vErrors === null) {vErrors = [err4]} else {vErrors.push(err4)}
      errors++
    }
    if (data.tenantOrgNo === undefined) {
      const err5 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenantOrgNo' },
        message: 'must have required property \'' + 'tenantOrgNo' + '\''
      }
      if (vErrors === null) {vErrors = [err5]} else {vErrors.push(err5)}
      errors++
    }
    if (data.tenantAddress === undefined) {
      const err6 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenantAddress' },
        message: 'must have required property \'' + 'tenantAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err6]} else {vErrors.push(err6)}
      errors++
    }
    if (data.tenantPostalAddress === undefined) {
      const err7 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenantPostalAddress' },
        message: 'must have required property \'' + 'tenantPostalAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err7]} else {vErrors.push(err7)}
      errors++
    }
    if (data.tenantInvoiceAddress === undefined) {
      const err8 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenantInvoiceAddress' },
        message: 'must have required property \'' + 'tenantInvoiceAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err8]} else {vErrors.push(err8)}
      errors++
    }
    if (data.conditionsOfTransport === undefined) {
      const err9 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'conditionsOfTransport' },
        message: 'must have required property \'' + 'conditionsOfTransport' + '\''
      }
      if (vErrors === null) {vErrors = [err9]} else {vErrors.push(err9)}
      errors++
    }
    if (data.tenantContactPersonFirstName !== undefined) {
      if (typeof data.tenantContactPersonFirstName !== 'string') {
        const err10 = {
          instancePath: instancePath + '/tenantContactPersonFirstName',
          schemaPath: '#/properties/tenantContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err10]} else {vErrors.push(err10)}
        errors++
      }
    }
    if (data.tenantContactPersonLastName !== undefined) {
      if (typeof data.tenantContactPersonLastName !== 'string') {
        const err11 = {
          instancePath: instancePath + '/tenantContactPersonLastName',
          schemaPath: '#/properties/tenantContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err11]} else {vErrors.push(err11)}
        errors++
      }
    }
    if (data.tenantPhone !== undefined) {
      let data2 = data.tenantPhone
      if (typeof data2 === 'string') {
        if (!pattern0.test(data2)) {
          const err12 = {
            instancePath: instancePath + '/tenantPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {vErrors = [err12]} else {vErrors.push(err12)}
          errors++
        }
      } else {
        const err13 = {
          instancePath: instancePath + '/tenantPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err13]} else {vErrors.push(err13)}
        errors++
      }
    }
    if (data.tenantEmail !== undefined) {
      let data3 = data.tenantEmail
      if (typeof data3 === 'string') {
        if (!pattern1.test(data3)) {
          const err14 = {
            instancePath: instancePath + '/tenantEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {vErrors = [err14]} else {vErrors.push(err14)}
          errors++
        }
      } else {
        const err15 = {
          instancePath: instancePath + '/tenantEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err15]} else {vErrors.push(err15)}
        errors++
      }
    }
    if (data.title !== undefined) {
      if (typeof data.title !== 'string') {
        const err16 = {
          instancePath: instancePath + '/title',
          schemaPath: '#/properties/title/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err16]} else {vErrors.push(err16)}
        errors++
      }
    }
    if (data.backgroundAndPurpose !== undefined) {
      if (typeof data.backgroundAndPurpose !== 'string') {
        const err17 = {
          instancePath: instancePath + '/backgroundAndPurpose',
          schemaPath: '#/properties/backgroundAndPurpose/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err17]} else {vErrors.push(err17)}
        errors++
      }
    }
    if (data.otherProvisions !== undefined) {
      if (typeof data.otherProvisions !== 'string') {
        const err18 = {
          instancePath: instancePath + '/otherProvisions',
          schemaPath: '#/properties/otherProvisions/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err18]} else {vErrors.push(err18)}
        errors++
      }
    }
    if (data.tenantName !== undefined) {
      if (typeof data.tenantName !== 'string') {
        const err19 = {
          instancePath: instancePath + '/tenantName',
          schemaPath: '#/properties/tenantName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err19]} else {vErrors.push(err19)}
        errors++
      }
    }
    if (data.tenantOrgNo !== undefined) {
      if (typeof data.tenantOrgNo !== 'string') {
        const err20 = {
          instancePath: instancePath + '/tenantOrgNo',
          schemaPath: '#/properties/tenantOrgNo/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err20]} else {vErrors.push(err20)}
        errors++
      }
    }
    if (data.tenantAddress !== undefined) {
      if (typeof data.tenantAddress !== 'string') {
        const err21 = {
          instancePath: instancePath + '/tenantAddress',
          schemaPath: '#/properties/tenantAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err21]} else {vErrors.push(err21)}
        errors++
      }
    }
    if (data.tenantPostalAddress !== undefined) {
      if (typeof data.tenantPostalAddress !== 'string') {
        const err22 = {
          instancePath: instancePath + '/tenantPostalAddress',
          schemaPath: '#/properties/tenantPostalAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err22]} else {vErrors.push(err22)}
        errors++
      }
    }
    if (data.tenantInvoiceAddress !== undefined) {
      if (typeof data.tenantInvoiceAddress !== 'string') {
        const err23 = {
          instancePath: instancePath + '/tenantInvoiceAddress',
          schemaPath: '#/properties/tenantInvoiceAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err23]} else {vErrors.push(err23)}
        errors++
      }
    }
    if (data.conditionsOfTransport !== undefined) {
      if (typeof data.conditionsOfTransport !== 'string') {
        const err24 = {
          instancePath: instancePath + '/conditionsOfTransport',
          schemaPath: '#/properties/conditionsOfTransport/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err24]} else {vErrors.push(err24)}
        errors++
      }
    }
    if (data.compulsoryDerogation !== undefined) {
      if (typeof data.compulsoryDerogation !== 'string') {
        const err25 = {
          instancePath: instancePath + '/compulsoryDerogation',
          schemaPath: '#/properties/compulsoryDerogation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err25]} else {vErrors.push(err25)}
        errors++
      }
    }
  } else {
    const err26 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object'
    }
    if (vErrors === null) {vErrors = [err26]} else {vErrors.push(err26)}
    errors++
  }
  validate20.errors = vErrors
  return errors === 0
}
