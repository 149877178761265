import i18n from "src/producers/languages/i18n";

import { FieldInputType } from "src/templates/types/template";
import { PlainTextFieldGeneral } from "../../fields/PlainTextFieldGeneral";
import styles from "../../../../styles.module.css";

export const RentalPeriodTenant: view = () => (
  <div>
    <div className={`${styles.datesRow}`}>
      <div className="flex flex-col w-64">
        <PlainTextFieldGeneral
          varName="rentalStart"
          placeholder={i18n.t("placeholders.select_date")}
          label={i18n.t("clause_rental_period_rental_start")}
        />
      </div>
      <div className="flex flex-col w-64">
        <PlainTextFieldGeneral
          varName="rentalEnd"
          placeholder={i18n.t("placeholders.select_date")}
          label={i18n.t("clause_rental_period_rental_end")}
        />
      </div>
      <PlainTextFieldGeneral
        label={i18n.t("noticePeriod")}
        inputType={FieldInputType.Dropdown}
        varName="noticePeriod"
        placeholder={`${i18n.t("placeholders.add_amount")}`}
      />
    </div>
  </div>
);
