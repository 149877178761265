import React from "react";

import { ContractVersion, UpdatesFeed, SendMessageCard, WelcomeContractCard } from "./index";

import { ContractStatus, UserRole } from "src/types";

export const Sidebar: view = ({
  status = observe.legacyContract.original.status,
  userRole = get.user.data.role,
  displayedVersion = observe.legacyContract.displayedVersion,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isntDraft = status !== ContractStatus.DRAFT;
  return (
    <aside className="aside-class fixed right-0 bottom-0 top-0 pt-20 pb-10 flex flex-col">
      {/* col-reverse is there for scroll to bottom */}
      {isManager && <ContractVersion />}
      <div className="flex-grow overflow-y-auto">
        <div id="here" className=" pt-2 relative flex flex-col-reverse">
          {isntDraft && <UpdatesFeed />}

          <WelcomeContractCard />
        </div>
      </div>
      {isntDraft && !!!displayedVersion && <SendMessageCard />}
    </aside>
  );
};
