import {
  ApplicationError,
  ContractEventType,
  ErrorLevel,
  ErrorType
} from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'

export const submitComment: producer = async ({
  contractId = observe.contract.id,
  user = get.user,
  rawUser = get.rawUser,
  localFunctions = get.localFunctions,
  comment = get.comment.message,
  isSubmitted = get.comment.submit,
  setIsSubmitted = update.comment.submit,

  getActivityOriginal = get.contract.activity,
  updateActivity = update.contract.tempActivity,

  removeComment = update.comment.message,
  resetCommentComponent = update.comment.reset,
  latestVersion = get.contract.original.state.latestVersion,
  trigger = observe.contract.triggers.submitComment,
  removeTrigger = update.contract.triggers.submitComment,
  error = update.errors[param.id],
}) => {
  if (!comment.value() || !trigger || !contractId || isSubmitted.value()) {
    return
  }

  const reset = () => {
    updateActivity.set([])
  }
  try {
    setIsSubmitted.set(true)
    removeTrigger.remove()
    user = user.value()
    const message = comment.value()
    const version = latestVersion.value()
    const data = {
      versionId: version,
      userId: user.uid,
      message: message,
      createdBy: user.data.role,
      createdAt: Date.now(),
    }

    removeComment.remove()
    resetCommentComponent.set(Date.now())
    setIsSubmitted.remove()

    updateActivity.push({
      type: ContractEventType.COMMENT_ADDED,
      tempFor: getActivityOriginal.length() + 1,
      message: data.message,
      createdAt: data.createdAt,
      version: data.versionId,
      user: {
        email: user.data.email,
        fullName: user.data.displayName,
        role: user.data.role,
        userId: user.uid,
      },
    })

    const command = {
      action: CommandActions.ADD_COMMENT,
      contractId: contractId,
      message: message,
    }
    await axios.post(
      getFunctionsUrl('commandPublisher', localFunctions.value()),
      command,
      {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type': 'application/json'
        }
      }
    )
  } catch (e) {
    reset()
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'submitComment-contract' }
    )
  }
}
