import i18n from "src/producers/languages/i18n";
import { DownArrowOutlineIcon, RecycleBinIcon, UpArrowOutlineIcon } from "src/assets/svg";
import { VisibleIf } from "src/components_generic/visible-if";
import { Button } from "@c11/components.button";
import { Divider } from "antd";
import { SVGs } from "src/assets";
import { Switch } from "@c11/components.switch";
import styles from "../../styles.module.css"

export type AttachmentsListElement = {
  index: number,
  name: string,
  type: string,
  user: string,

  isLocked?: boolean,

  isUserAttachment?: boolean,
  includeInSigning?: boolean,

  isFirstElement?: boolean,
  isLastElement?: boolean,

  onDelete?: () => void,
  onPreview?: () => void,
  onReorderUpClick?: () => void,
  onReorderDownClick?: () => void,
  onIncludeInSigningToggled?: () => void
};

export const AttachmentsListElement = ({
  index, name, type, includeInSigning, user,
  isFirstElement, isLastElement, isUserAttachment, isLocked,
  onDelete, onPreview, onReorderUpClick, onReorderDownClick, onIncludeInSigningToggled
}: AttachmentsListElement) => {
  return <div className={styles["cards-list"]}>
    <div className={styles["cards-tab-list-item"]}>
      <div className={styles["cards-tab-list-item-index"]}>
        <div className="font-semibold text-center">{index}.</div>
        <div className="ant-divider ant-divider-vertical border h-8 mx-4"></div>
      </div>

      <div className="flex-grow flex flex-row items-center">
        <div className="flex-grow">
          <div className={styles["list-name-element"]}>{name}</div>
        </div>

        <div className={styles["cards-info-row"]}>
          <div className={styles["cards-info"]}>
            <div className="text-black-500 font-medium">{type}</div>
          </div>
          <div className={styles["cards-info"]}>
            <Switch
              className={`secondary-switch-${includeInSigning ? "checked" : "unchecked"}`}
              isChecked={includeInSigning}
              isDisabled={isLocked}
              onClick={() => {
                if (!onIncludeInSigningToggled) return;
                onIncludeInSigningToggled();
              }}
            />
          </div>
          <div className={styles["cards-actions-row"]}>
            <div className="flex flex-row items-center justify-end gap-1">
              {/* <h5 className="attachments-tab-list-item-subtitle">Remove Attachment</h5> */}
              <Button
                variant="ghost"
                className={styles["list-element-button"] + " " + styles["eye"]}
                onClick={() => {
                  if (!onPreview) return;
                  onPreview();
                }}>
                <SVGs.Eye className="text-gray-600 w-5"/>
              </Button>

              <VisibleIf predicate={!!isUserAttachment && !isLocked}>
                <Button
                  variant="ghost"
                  className={styles["list-element-button"]}
                  isDisabled={!isUserAttachment}
                  onClick={() => {
                    if (!onDelete) return;
                    onDelete();
                  }}>
                  <RecycleBinIcon className="text-gray-600"/>
                </Button>
              </VisibleIf>
            </div>
            <VisibleIf predicate={!(isFirstElement && isLastElement) && !isLocked}>
              <div className="flex flex-row">
                <Divider type="vertical" className="h-9 border mr-4 ml-2"/>
                <div className="flex flex-col">
                  <VisibleIf predicate={!isFirstElement}>
                    <Button className={styles["list-element-button"] + " " + styles["reorder"]} variant="ghost" onClick={() => {
                      if (!onReorderUpClick) return;
                      onReorderUpClick();
                    }}>
                      <UpArrowOutlineIcon className="text-gray-400"/>
                    </Button>
                  </VisibleIf>
                  <VisibleIf predicate={!isLastElement}>
                    <Button className={styles["list-element-button"] + " " + styles["reorder"]} variant="ghost" onClick={() => {
                      if (!onReorderDownClick) return;
                      onReorderDownClick();
                    }}>
                      <DownArrowOutlineIcon className="text-gray-400"/>
                    </Button>
                  </VisibleIf>
                </div>
              </div>
            </VisibleIf>
          </div>
        </div>
      </div>
    </div>
  </div>
}
