import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from 'src/types'
import { db } from 'src/producers/firebase'

export const persistAttachmentIncludeInSigning: producer = async ({
  user = get.user,
  isLocked = get.contract.isLocked,
  contractId = get.contract.id,
  attachments = get.contract.originalv2.attachments,
  updateContractAttachments = update.contract.originalv2.attachments,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  attachmentToIncludeInSigning = observe.contract.changes.attachments.includeInSigning,
  updateAttachmentToIncludeInSigning = update.contract.changes.attachments.includeInSigning,
}) => {
  if (!attachmentToIncludeInSigning) return
  if (!attachmentToIncludeInSigning.id) return

  isLocked = isLocked.value()
  if (isLocked) return

  user = user.value()
  contractId = contractId.value()
  attachments = attachments.value()

  if (!user) return
  if (!contractId) return
  if (!attachments) return

  try {
    isSaving.set(true)
    const updatedAt = new Date().toISOString()

    await db.collection('contracts').doc(contractId)
      .set({
          attachments: {
            [attachmentToIncludeInSigning.id]: { selected: attachmentToIncludeInSigning.selected },
          },
          updatedAt,
        },
        { merge: true })

    updateContractAttachments.merge({
      [attachmentToIncludeInSigning.id]: { selected: attachmentToIncludeInSigning.selected },
    })
    successfulSave.set(true)
  } catch (e) {
    console.error('persist-attachment-include-in-signing', e)
    failedSave.set(true)
    error.set(
      {
        message: 'saving-attachments-to-database',
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: 'persist-attachment-include-in-signing' }
    )
  } finally {
    updateAttachmentToIncludeInSigning.remove()

    isSaving.set(false)
  }
}
