import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";

export const TenantContractNavbarButton: view = ({
  pdfServerLoading = observe.legacyContract.flags.loadingpdf,
  generatePDF = update.legacyContract.triggers.generatePDF,
}) => {
  return (
    <Button
      className="uppercase"
      variant="secondary"
      onClick={() => generatePDF.set(Date.now())}
      isLoading={pdfServerLoading}>
      {!pdfServerLoading && <SVGs.DownloadIconBlack key="1" className="mt-1" />}
      <span key="2" className="ml-2">
        {i18n.t("contract_navbar.button_download_contract")}
      </span>
    </Button>
  );
};
