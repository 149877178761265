export const contractSync: producer = ({
  trigger = observe.legacyContract.currentSession.changes,
  processData = update.legacyContract.triggers.processData,
  setTimer = update.legacyContract.timeoutIdForProcessData,
  timerRef = get.legacyContract.timeoutIdForProcessData,
  saveStatus = update.legacyContract.loading.saveStatus,
}) => {
  if (!trigger) {
    return;
  }
  saveStatus.set("saving");
  if (timerRef.value()) {
    clearTimeout(timerRef.value());
  }
  const timerId = setTimeout(() => processData.set(Date.now()), 1000);

  setTimer.set(timerId);
};
