import React from "react";

import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";

export const MessageBubble: view = ({ comment, userRole = get.user.data.role, users = observe.users }) => {
  const { message, createdBy, createdAt, userId } = comment;
  const isMadeByYourTeam = userRole.value() === createdBy;

  let senderName = users[userId]?.displayName;
  if (!senderName) {
    if (isMadeByYourTeam) senderName = i18n.t("legacy.sidebar_feed_message_you");
    else
      senderName =
        createdBy == UserRole.TENANT
          ? i18n.t("legacy.sidebar_feed_message_tenant")
          : i18n.t("legacy.sidebar_feed_message_cm");
  }
  senderName = `${i18n.t("sidebar.message_by")} ${senderName}`;
  if (isMadeByYourTeam)
    return (
      <div className="justify-end flex">
        <div className="px-4 py-2 bg-green-300 rounded-lg w-11/12 mb-3">
          <p>{message}</p>
          <p className="font-12">{`${createdAt} ${senderName}`} </p>
        </div>
      </div>
    );
  else
    return (
      <div className="px-4 py-2 border border-gray-200 bg-white w-11/12 rounded-lg mb-3">
        <p>{message}</p>
        <p className="font-12 text-gray-600 ">{`${createdAt} ${senderName}`}</p>
      </div>
    );
};
