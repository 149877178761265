import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from "src/types"
import { CommandActions } from "src/types/commands/command"
import { functions, db, fieldValue } from "src/producers/firebase"
import { v4 } from "uuid"
import { ContractAddendum } from "../../contract.types"
import { get, isNull, isUndefined, omit, omitBy } from "lodash"

export const persistAddendumDelete: producer = async ({
  contractId = get.contract.id,

  addendums = get.contract.originalv2.addendums,
  updateAddendums = update.contract.originalv2.addendums,
  // setCurrentPath = update.routes.currentPath,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  trigger = observe.contract.triggers.deleteAddendum,
  removeTrigger = update.contract.triggers.deleteAddendum,
}) => {
  if (!trigger) return
  if (!trigger?.id) return

  contractId = contractId.value()
  if (!contractId) return

  addendums = addendums.value()
  if (!addendums) return
  if (Object.keys(addendums).length < 1) return

  try {
    isSaving.set(true)

    await db
      .collection("contracts")
      .doc(contractId)
      .set(
        {
          addendums: {
            [trigger.id]: fieldValue.delete(),
          },
        },
        {
          merge: true,
        }
      )

    await db.collection("contracts").doc(trigger.id).delete()

    updateAddendums.set(omitBy(addendums, (value, key) => key.localeCompare(trigger.id) === 0))
    successfulSave.set(true)

    // setCurrentPath.set(`/contract/${addendum.id}`)
  } catch (e) {
    console.error("ERROR: ", e)
    error.set(
      {
        message: e.message || 'error-delete-addendum',
        level: LogLevels.FATAL
      } as ApplicationError,
      { id: "persist-addendum-delete" }
    )
  } finally {
    removeTrigger.remove()

    isSaving.set(false)
  }
}
