import { UserRole } from 'src/types'
import { db } from 'src/producers/firebase'
export const setEditorDetails: producer = async ({
  editorUid = observe.contract.originalv2.isEditedBy,
  editor = get.contract.editor.value(),
  role = get.user.data.role.value(),

  updateEditor = update.contract.editor
}) => {
  if (!editorUid) return

  if (editor && editorUid === editor.id) return
  if (role === UserRole.MANAGER) {
    const userSnap = await db.collection('users').doc(editorUid).get()
    const user = userSnap.data()
    if (!user) return
    const { firstName, lastName, email, displayName } = user.data
    updateEditor.set({ firstName, lastName, email, displayName, id: user.uid })
  }
}
