import React from "react";

import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";

export const VersionBubbleTenant: view = ({ createdAt }) => {
  return (
    <>
      <div className="flex justify-between">
        <span className="flex">
          <SVGs.Page />
          <span className="ml-2 font-medium">{i18n.t("legacy.sidebar_feed_contract_updated")}</span>
        </span>
        <span className="font-12 text-gray-600 ">{createdAt}</span>
      </div>
    </>
  );
};
