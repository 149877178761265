import i18n from "src/producers/languages/i18n";
import { saveDefaultEmails } from "./SendContractModal.saveDefaultEmails";
import { addRecipient } from "./RecipientsForm.addRecipient";
import { deleteRecipient } from "./RecipientsForm.deleteRecipient";
import { editRecipient } from "./RecipientsForm.editRecipient";
import { TextField } from "@c11/components";
import { AddIcon, CloseIcon, ApproveIcon, EditIcon } from "src/assets/svg";
import validator from "validator";
import styles from "../styles.module.css"

export const RecipientsForm: view = ({
  _viewId,
  recipients = observe.contract.original.recipients,
  viewState = observe.views[prop._viewId],

  addRecipient = update.contract.triggers.addRecipient,
  deleteRecipient = update.contract.triggers.deleteRecipient,
  editRecipient = update.contract.triggers.editRecipient,
  updateViewState = update.views[prop._viewId][param.type],
}) => {
  if (!viewState) {
    updateViewState.set({ newInputIsOpen: false }, { type: "replyTo" });
    updateViewState.set({ newInputIsOpen: false }, { type: "sendTo" });
  }

  const recipientLandlord = recipients?.landlords;
  const hasRecipientLandlord = recipientLandlord && recipientLandlord.length > 0;
  const replayToInputIsOpen = viewState?.replyTo?.newInputIsOpen || false;
  const firstLandloardEmail = recipients?.landlords?.[0];
  const newReplayInputIsValid = validator.isEmail(viewState?.replyTo?.input || "");

  const recipientTenants = recipients?.tenants;
  const sendToInputIsOpen = viewState?.sendTo?.newInputIsOpen;

  const newSendInputIsValid = validator.isEmail(viewState?.sendTo?.input || "");
  const editSendInputIsValid = validator.isEmail(viewState?.sendTo?.input || "");

  return (
    <div className={`${styles.sendContractForm}`}>
      <ul>
        <label>{i18n.t("modals.reply_to")}</label>
        <div className={`${styles.inputContainer}`}>
            {hasRecipientLandlord && !replayToInputIsOpen && (
              <li>
                <span>{firstLandloardEmail}</span>
                <button
                  type="button"
                  onClick={() =>
                    updateViewState.set({ input: firstLandloardEmail, newInputIsOpen: true }, { type: "replyTo" })
                  }>
                  <EditIcon/>
                </button>
                <button type="button" onClick={() => deleteRecipient.set({ case: "replyTo", mail: firstLandloardEmail })}>
                  <CloseIcon/>
                </button>
              </li>
            )}
            {!hasRecipientLandlord && !replayToInputIsOpen && (
              <button type="button" onClick={() => updateViewState.set({ newInputIsOpen: true }, { type: "replyTo" })}>
                <AddIcon/>
              </button>
            )}
            {replayToInputIsOpen && (
              <>
                <TextField className={`${styles.customInput}`}
                  isRequired
                  isInvalid={newReplayInputIsValid}
                  value={viewState?.replyTo?.input}
                  onChange={(e) => updateViewState.merge({ input: e.target.value }, { type: "replyTo" })}
                />
                {newReplayInputIsValid && (
                  <button type="button" onClick={() => addRecipient.set("replyTo")}>
                    <ApproveIcon/>
                  </button>
                )}
                <button type="button" onClick={() => updateViewState.set({ isOpen: false }, { type: "replyTo" })}>
                  <CloseIcon/>
                </button>
              </>
            )}
          </div>
        </ul>
        {/* SEND TO */}
        <div>
          <ul>
            <label>{i18n.t("modals.send_to")} </label>
            <div className={`${styles.inputContainer}`}>
            {recipientTenants?.map((mail: string) => (
              <li key={mail}>
                {viewState?.sendTo?.editEmail != mail ? (
                  <>
                    <span>{mail}</span>
                    <button
                      type="button"
                      onClick={() => updateViewState.merge({ input: mail, editEmail: mail }, { type: "sendTo" })}>
                      <EditIcon/>
                    </button>
                    <button
                      type="button"
                      onClick={() => deleteRecipient.set({ case: "sendTo", mail: mail })}>
                        <CloseIcon/>
                    </button>
                  </>
                ) : (
                  <>
                    <TextField className={`${styles.customInput}`}
                      isRequired
                      isInvalid={editSendInputIsValid}
                      value={viewState?.sendTo?.input}
                      onChange={(e) => updateViewState.merge({ input: e.target.value }, { type: "sendTo" })}
                    />

                    <button
                      type="button"
                      onClick={() => editRecipient.set({ case: "sendTo", oldEmail: mail })}>
                      <ApproveIcon/>
                    </button>
                    <button type="button" onClick={() => updateViewState.merge({ editEmail: "" }, { type: "sendTo" })}>
                      <CloseIcon/>
                    </button>
                  </>
                )}
              </li>
            ))}

          {!sendToInputIsOpen && !viewState?.sendTo?.editEmail && (
            <button type="button" onClick={() => updateViewState.set({ newInputIsOpen: true }, { type: "sendTo" })}>
              <AddIcon/>
            </button>
          )}

          {sendToInputIsOpen && (
          <>
              <TextField className={`${styles.customInput}`}
                isRequired
                isInvalid={newSendInputIsValid}
                value={viewState?.sendTo?.input}
                onChange={(e) => updateViewState.merge({ input: e.target.value }, { type: "sendTo" })}
              />

              {newSendInputIsValid && (
                <button type="button" onClick={() => addRecipient.set("sendTo")}>
                  <ApproveIcon/>
                </button>
              )}
              <button type="button" onClick={() => updateViewState.set({ isOpen: false }, { type: "sendTo" })}>
                <CloseIcon/>
              </button>
            </>
          )}
          </div>
        </ul>
      </div>
    </div>
  );
};

RecipientsForm.producers([saveDefaultEmails, addRecipient, deleteRecipient, editRecipient]);
