import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { InputFieldLabel } from './InputFieldLabel'

export const PhoneField = ({ placeholder, value, onChange, defaultCountry, label, required }: any) => {
  return (
    <div>
      <div className="flex">{label && <InputFieldLabel label={label} required={required} />}</div>
      <PhoneInput
        international
        placeholder={placeholder}
        defaultCountry={defaultCountry}
        value={value}
        onChange={(value: any) => onChange && onChange(value)}
      />
    </div>
  )
}
