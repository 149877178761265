import { ValidationType } from "src/templates/types/template";
import i18n from "src/producers/languages/i18n";
import validator from "validator";
import moment from "moment";

export const valueValidation: producer = ({
  values = observe.legacyContract.values,
  templateField = observe.legacyContract.fields,
  updateInvalidFields = update.legacyContract.invalidFields[param.varName],
  removeAllInvalidFields = update.legacyContract.invalidFields,
}) => {
  if (!values || !templateField) {
    return;
  }
  //This is here for the case when you want to remove a value from a field
  removeAllInvalidFields.remove();
  Object.keys(values).forEach((v) => {
    const value = values[v]?.displayedValue;
    let validationType = templateField[v]?.validationType;
    let validationResult = true;
    if (validationType == ValidationType.Email) {
      validationResult = validator.isEmail(value);
    }
    if (validationType == ValidationType.RentalEnd) {
      const rentalStart = values.rentalStart?.displayedValue;
      if (rentalStart && !moment(rentalStart).isBefore(moment(value))) validationResult = false;
    }
    if (validationType == ValidationType.OrgNo) {
      validationResult = validator.isInt(value, { min: 100000000, max: 999999999 });
    }
    // TODO This is a hack because the signatories are dynamic
    if (v.search("Email") != -1) {
      validationResult = validator.isEmail(value);
      validationType = ValidationType.Email;
    }

    if (validationResult) updateInvalidFields.remove({ varName: v });
    else updateInvalidFields.set(i18n.t(`validation_message_${validationType}`), { varName: v });
  });
  try {
    //
  } catch (e) {
    console.error("submitComment", e);
  }
};
