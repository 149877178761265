import React from "react";

import { VersionDropdownRow } from "./VersionDropdownRow";
import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { Menu, Dropdown } from "antd";
import moment from "moment";

export const VersionsDropdown: view = ({
  versions = observe.legacyContract.original.versions,
  setDisplayedVersion = update.legacyContract.displayedVersion,
  displayedVersion = observe.legacyContract.displayedVersion,
}) => {
  if (!versions || Object.keys(versions).length === 0) {
    return (
      <div className="kpy-10 kpx-14 font-medium  bg-white inline-block relative w-full border klp-border1 rounded-md">
        {i18n.t("legacy.sidebar_versions_not_published")}
      </div>
    );
  }

  let versionIdsArray = Object.keys(versions);
  versionIdsArray.sort((a, b) => (versions[a]["createdAt"] < versions[b]["createdAt"] ? 1 : -1));

  const hasMoreThenOneVersion = versionIdsArray.length > 1;

  const versionsListDisplay = (
    <Menu>
      {versionIdsArray.map((version: any, index: any) => {
        const isLatest = index === 0;
        return (
          <Menu.Item key={index}>
            <VersionDropdownRow
              version={version}
              createdAt={versions[version]["createdAt"]}
              onClick={() => (isLatest ? setDisplayedVersion.remove() : setDisplayedVersion.set(version))}
              latest={isLatest}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const currentVersion = displayedVersion || versionIdsArray[0];

  return (
    <div className=" inline-block relative w-full" id="versionsDropdown">
      <Dropdown
        overlay={versionsListDisplay}
        trigger={["click"]}
        overlayClassName={"versionsDropdown"}
        getPopupContainer={() => document.getElementById("versionsDropdown") as HTMLElement}
        disabled={!hasMoreThenOneVersion}>
        <button className="bg-white kpy-10 kpx-14 flex w-full justify-between items-center border klp-border1 rounded-md">
          <VersionDropdownRow
            className="mr-1"
            version={currentVersion}
            latest={currentVersion && currentVersion === versionIdsArray[0]}
            header
          />
          <div className="flex items-center">{hasMoreThenOneVersion && <SVGs.DropdownArrow />}</div>
        </button>
      </Dropdown>
    </div>
  );
};
