export const deleteAllChanges: producer = ({
  trigger = observe.contract.triggers.deleteAllChanges,
  removeTrigger = update.contract.triggers.deleteAllChanges,
  removeChanges = update.contract.currentSession.changes,
  deleteSessionFromDB = update.contract.triggers.deleteSessionFromDB,
  rerendersAllFields = update.contract.rerendersAllFields,
}) => {
  if (!trigger) {
    return;
  }
  removeTrigger.remove();
  removeChanges.remove();
  deleteSessionFromDB.set(Date.now());
  rerendersAllFields.set(Date.now());
};
