import i18n from "src/producers/languages/i18n";
import { ContractEventType } from "src/types";
import { Changes } from "./Changes";
import { EventBubble } from "./EventBubble";

export const VersionBubbleManager: view = ({ versionNumber, timestamp, changes }) => {
  let changesList: any = [];
  if (changes.fields) {
    changesList = Object.keys(changes.fields).map((varName) => {
      const { current, previous } = changes.fields[varName];

      return <Changes key={varName} newValue={current?.value} oldValue={previous?.value} currentVariable={varName} />;
    });
  }
  if (changes?.updates?.attachments) {
    const attachments = changes?.updates?.attachments;
    Object.keys(attachments).forEach((a) => {
      if (attachments[a].toDelete)
        changesList.push(
          <p key={"toDelete " + a}>
            {i18n.t("activity_log.attachment_removed")}: {attachments[a].fileName}
          </p>
        );
      else if (attachments[a].fileType)
        changesList.push(
          <p key={"fileType " + a}>
            {i18n.t("activity_log.attachment_added")}: {attachments[a].fileName}
          </p>
        );
      else if (attachments[a].selected !== undefined)
        changesList.push(
          <p key={"selected " + a}>
            {attachments[a].selected
              ? i18n.t("activity_log.attachment_included")
              : i18n.t("activity_log.attachment_excluded")}
            : {a}
          </p>
        );
    });
  }
  if (changes?.updates?.parties) {
    const party = changes?.updates?.parties;
    Object.keys(party).forEach((a) => {
      if (party[a].toDelete)
        changesList.push(
          <p key={"delete " + a}>
            {i18n.t("activity_log.party_removed", {
              name: `${party[a]?.firstName || ""} ${party[a]?.lastName || ""}`,
            })}
          </p>
        );
      else if (party[a]?.partyId)
        changesList.push(
          <p key={party[a]?.partyId}>
            {i18n.t("activity_log.party_added", {
              name: `${party[a]?.firstName || ""} ${party[a]?.lastName || ""}`,
              role: i18n.t(`general.${party[a].partyType}`),
            })}
          </p>
        );
      else
        changesList.push(
          <p key={"name" + a}>
            {i18n.t("activity_log.party_updated", {
              name: a,
            })}
          </p>
        );
    });
  }
  const title = i18n.t("sidebar.version_created", { n: versionNumber });
  return (
    <EventBubble title={title} timestamp={timestamp} event={ContractEventType.VERSION_CREATED}>
      <div>{changesList}</div>
    </EventBubble>
  );
};
