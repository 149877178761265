import { db } from 'src/producers/firebase'
import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from 'src/types'
export const changeEditor: producer = async ({
  newEditor = observe.contract.changes.isEditedBy,
  currentEditor = get.contract.originalv2.isEditedBy.value(),
  contractId = get.contract.id.value(),

  updateEditor = update.contract.originalv2.isEditedBy,
  updateEditorChange = update.contract.changes.isEditedBy,
  error = update.errors[param.id]
}) => {
  if (!newEditor) return
  if (newEditor === currentEditor) {
    updateEditorChange.remove()
    return
  }
  try {
    await db.collection('contracts').doc(contractId).set({ isEditedBy: newEditor }, { merge: true })
    updateEditor.set(newEditor)
  } catch (e) {
    console.error('ERROR!! changeEditor', e)
    error.set(
      {
        message: 'error-changing-editor',
        level: LogLevels.ERROR,
      } as ApplicationError,
      { id: 'changeEditor' }
    )
  }
}
