import React from "react";
import { Images } from "src/assets";
import { setShoppingMall } from "src/pages/Contract/subviews/ContractForm/RentalPropertyClause/ShoppingMallsDropdownRow.setShoppingMall";

export const ShoppingMallsDropdownRow: view = ({
  SM,
  selectedShoppingMallId = observe.contract.original.state.building.id,
  setShoppingMall = update.contract.triggers.setShoppingMall,
}) => {
  if (!SM) return null;
  const mallId = SM.id;
  return (
    <div
      onClick={() => {
        if (mallId && selectedShoppingMallId != mallId) {
          console.log("set", mallId, selectedShoppingMallId);

          setShoppingMall.set(mallId);
        }
      }}
      className="flex justify-between items-center mr-2 my-1">
      <div className="flex">
        <img
          src={SM.buildingImageUrl || Images.logo}
          alt="shopping mall image"
          style={{ objectFit: "contain", maxWidth: 70, height: 40, marginRight: 15 }}
        />
        <div>
          <p key="1" className=" text-left font-medium">
            {SM.name && <span>{SM.name} </span>}
          </p>
          <p key="2" className="font-12 text-left text-gray-600">
            {SM.address && <span>{SM.address} </span>}
          </p>
        </div>
      </div>
    </div>
  );
};

ShoppingMallsDropdownRow.producers([setShoppingMall]);
