import React from "react";

// eslint-disable-next-line import/no-cycle
import { Checkbox } from "../../index";
import i18n from "src/producers/languages/i18n";
import {NumericFormat} from "react-number-format";

export const CalculationFieldManager: view = ({
  varName,
  fieldName,
  readOnly,
  isCustom = observe.legacyContract.custom[arg.varName],
  setCustom = update.legacyContract.custom[arg.varName],
  value = observe.legacyContract.values[arg.varName].displayedValue,
  writeValue = update.legacyContract.currentSession.changes[arg.varName],
  templateField = observe.legacyContract.fields[arg.fieldName],
}) => {
  if (!templateField) return null;
  const { label } = templateField;
  function handleClick() {
    if (isCustom) {
      setCustom.set(false);
    } else {
      setCustom.set(true);
    }
  }
  return (
    <div className={"w-full flex my-2 items-center"}>
      {label && <div className={`w-1/2 text-right font-bold pr-8`}>{`${label}:`}</div>}
      <div className={` relative ${label ? "w-1/2" : "w-full"} `}>
        <NumericFormat
          key={"1"}
          className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 leading-tight ${
            isCustom ? "focus:bg-green-300 focus:border-transparent" : "bg-gray-200"
          }`}
          value={value}
          readOnly={!isCustom}
          thousandSeparator={"."}
          decimalSeparator={","}
          onValueChange={(values) => {
            const { value } = values;
            if (isCustom)
              writeValue.set({
                value: value,
                createdAt: Date.now(),
                custom: isCustom,
              });
          }}
        />
        <CustomButton isCustom={isCustom} handleClick={handleClick} />
      </div>
    </div>
  );
};

const CustomButton: view = ({ isCustom, handleClick }) => {
  return (
    <div className="flex justify-end items-center absolute top-0 bottom-0 right-0">
      <VerticalLine key="2" />
      <p key="4" className="font-normal text-gray-700">
        {i18n.t("custom_checkbox")}
      </p>
      <div key="5" style={{ margin: "5px 0px 0px 10px" }}>
        <Checkbox small checked={isCustom} onClick={handleClick} />
      </div>
    </div>
  );
};

const VerticalLine: view = () => {
  return (
    <div
      className="bg-gray-500"
      style={{
        width: "2px",
        height: "20px",
        margin: "0px 15px",
      }}></div>
  );
};
