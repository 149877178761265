import { Space, Switch } from 'antd'
import { TenantInputSwitch } from './TenantInputSwitch'
import { TenantInputDropdown } from './TenantInputDropdown'
import { TenantInputText } from './TenantInputText'
import { ContractStatus, UserRole } from 'src/types'

export const TenantInput: view = ({
  flag = observe.featureFlags.tenantsDropdown,
  switchButton = observe.contract.triggers.tenantSwitchButton,
  updateSwitchButton = update.contract.triggers.tenantSwitchButton,

  status = observe.contract.original.state.status,
  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  if (status !== ContractStatus.DRAFT || isTenant) return null;

  if (!flag?.main) return <TenantInputText/>
  const handleClick = (value: boolean) => {
    updateSwitchButton.set(value)
  }
  return (
    <>
      <div className="w-1/2 pr-8 flex"><TenantInputText/></div>
      <div className="w-1/2 self-center">
        <Space>
          <Switch checkedChildren="Search" unCheckedChildren="Text"
                  defaultChecked onClick={handleClick}/>
        </Space>
      </div>
    </>
  )
}

TenantInput.producers([TenantInputSwitch])
