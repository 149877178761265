import {
  SigningSummaryBellActive,
  SigningSummaryBellDone,
  SigningSummaryCurrent,
  SigningSummaryRejected,
  SigningSummarySigned,
} from 'src/assets/svg'
import { Divider } from './Divider'
import i18n from 'src/producers/languages/i18n'
import { Button } from '@c11/components.button'
import moment from 'moment'
import { SVGs } from 'src/assets'
import styles from '../../../styles.module.css'

export const ActiveSignerListItem: view = ({
  index,
  recipient,
  date,
  showSendSigningNotificationModal = update.modal.sendSigningNotification,
  recipientstNotificationSent = observe.contract.recipientstNotificationSent,
  trigger = update.contract.triggers.refreshEnvelope,
}) => {
  const { givenName, familyName, email, role, partyType, signingMethod } = recipient || {}
  let recipientType = ' '
  if (partyType === 'tenant') recipientType = i18n.t('summary_page.signing_card_client')
  if (partyType === 'landlord') recipientType = i18n.t('summary_page.signing_card_owner')

  const fullName = `${givenName || ''} ${familyName || ''}`
  const roleType = role.name && i18n.t(`summary_page.signing_card_${role.name}`)
  const signingMethodString = signingMethod && i18n.t(`summary_page.signing_card_${signingMethod}`)
  return (
    <>
      {index > 0 && <Divider />}
      <div className="p-4 border border-gray-600 flex gap-4 items-center w-full h-16">
        <SigningSummaryCurrent />
        <div className="w-6/12">
          <div>{fullName}</div>
          <div className="text-gray-600">{`${email || ''}`}</div>
        </div>
        <div className="w-3/12">
          <div>{recipientType}</div>
          <div className="text-gray-600">
            {roleType || ''} | {signingMethodString || ''}
          </div>
        </div>
        <div className="w-3/12 flex justify-between items-center">
          <div>
            <div className="text-pink-600">{i18n.t('summary_page.signing_card_pending_signing')}</div>
            <div className="text-gray-600">{date && moment(date).format('DD.MM.YY HH:mm')}</div>
          </div>
          <div className="flex items-center gap-2">
            <div
              onClick={() => {
                showSendSigningNotificationModal.set({ fullName, recipientId: recipient.id })
              }}>
              {recipientstNotificationSent === recipient.id ? <SigningSummaryBellDone /> : <SigningSummaryBellActive />}
            </div>
            <div
              className={`${styles.reloadButton}`}
              onClick={() => {
                trigger.set(Date.now())
                console.log('triggered')
              }}>
              <SVGs.ReloadIcon />
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </>
  )
}

export const InactiveSignerListItemSigned: view = ({ recipient }) => {
  const { givenName, familyName, email, role, partyType, signature, signingMethod } = recipient || {}
  const { date, status } = signature
  const accepted = ['approved', 'signed'].includes(status)

  let recipientType = ' '
  if (partyType === 'tenant') recipientType = i18n.t('summary_page.signing_card_client')
  if (partyType === 'landlord') recipientType = i18n.t('summary_page.signing_card_owner')

  const fullName = `${givenName || ''} ${familyName || ''}`
  const roleType = role.name && i18n.t(`summary_page.signing_card_${role.name}`)
  const signingMethodString = signingMethod && i18n.t(`summary_page.signing_card_${signingMethod}`)
  return (
    <div className="p-4  flex gap-4 items-center w-full bg-gray-200 h-16" style={{ marginBottom: 1 }}>
      {accepted ? <SigningSummarySigned /> : <SigningSummaryRejected />}
      <div className="w-6/12">
        <div>{fullName}</div>
        <div className="text-gray-600">{`${email || ' '}`}</div>
      </div>
      <div className="w-3/12">
        <div>{recipientType}</div>
        <div className="text-gray-600">
          {roleType || ''} | {signingMethodString || ''}
        </div>
      </div>
      <div className="w-3/12">
        {accepted ? (
          <div className="text-green-500">{i18n.t('summary_page.signing_card_signed')}</div>
        ) : (
          <div className="text-red-600">{i18n.t('summary_page.signing_card_rejected')}</div>
        )}
        {date && <div className="text-gray-600">{moment(date).format('DD.MM.YY HH:mm')}</div>}
      </div>
    </div>
  )
}

export const InactiveSignerListItemUnsigned: view = ({ index, recipient }) => {
  const { givenName, familyName, email, role, partyType, signingMethod } = recipient || {}

  let recipientType = ' '
  if (partyType === 'tenant') recipientType = i18n.t('summary_page.signing_card_client')
  if (partyType === 'landlord') recipientType = i18n.t('summary_page.signing_card_owner')

  const fullName = `${givenName || ''} ${familyName || ''}`
  const roleType = role.name && i18n.t(`summary_page.signing_card_${role.name}`)
  const signingMethodString = signingMethod && i18n.t(`summary_page.signing_card_${signingMethod}`)
  return (
    <div className="p-4  flex gap-4 items-center w-full bg-gray-200 h-16" style={{ marginBottom: 1 }}>
      <div style={{ borderRadius: 100 }} className="flex justify-center items-center h-6 w-6 bg-white">
        {index + 1}
      </div>
      <div className="w-6/12">
        <div>{fullName}</div>
        <div className="text-gray-600">{`${email || ' '}`}</div>
      </div>
      <div className="w-3/12">
        <div>{recipientType}</div>
        <div className="text-gray-600">
          {roleType || ''} | {signingMethodString || ''}
        </div>
      </div>
    </div>
  )
}
