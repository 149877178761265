import { ApplicationError, ErrorLevel, ErrorType } from "src/types";
import Fuse from "fuse.js";

export const serchShoppingMall: producer = async ({
  fuseOptions,
  items = observe[prop.itemsPath],
  trigger = observe.views[prop._viewId].triggerSearch,
  setSearchResults = update.views[prop._viewId].searchResults,
  error = update.errors[param.id],
}) => {
  if (trigger == undefined) {
    return;
  }
  const fuse = new Fuse(Object.values(items), fuseOptions);
  const pattern = trigger;
  const fuseResults = fuse.search(pattern);
  const results = fuseResults.map((r) => r.item);
  setSearchResults.set(results);

  try {
    //
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.APPLICATION,
      } as ApplicationError,
      { id: "setShoppingMall-contract" }
    );
  }
};
