import { UserRole } from "src/types";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import i18n from "src/producers/languages/i18n";
import { FactoryFormField } from "src/pages/Contract/subviews/ContractForm/fields/FactoryFormField";

export const IntroClause: view = ({
  title,
  reset = update.contract.triggers.resetIntro,
  triggerReset = observe.contract.triggers.resetIntro,
  resetIntroTextDefault = update.contract.triggers.resetDefault,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  return (
    <ClauseCard
      key={triggerReset}
      title={title}
      actionText={isManager && i18n.t("clause_intro_reset")}
      actionFunction={
        isManager &&
        function () {
          reset.set(Date.now());
          resetIntroTextDefault.set({ varName: "introText" });
        }
      }>
      <FactoryFormField key="0" fieldName="introText" varName="introText" />
    </ClauseCard>
  );
};
