import React from "react";

import * as producers from "src/pages/producers/agreement/addendum";
import { AddendumPageSubview } from "src/types";
import { RouteName } from "src/types";
import { LoadingOverlay } from "src/components/LoadingOverlay";
import { Navbar } from "src/pages/Contract/Navbar/Navbar";
import { Sidebar } from "src/pages/Contract/Sidebar/Sidebar";
import { Attachments } from "src/pages/Contract/subviews/Attachments/Attachments";
import { Parties } from "src/pages/Contract/subviews/Parties/Parties";
import { ActivityLogModal } from "src/pages/Contract/ActivityLogModal/ActivityLogModal";
import { CreateVersionModal } from "src/pages/Contract/CreateVersionModal/CreateVersionModal";
import { MoveToSigningModal } from "src/pages/Contract/MoveToSigningModal/MoveToSigningModal";
import { ReEditContractModal } from "src/pages/Contract/ReEditContractModal/ReEditContractModal";
import { ResetContractModal } from "src/pages/Contract/ResetContractModal/ResetContractModal";
import { SendContractModal } from "src/pages/Contract/SendContractModal/SendContractModal";
import { StopSigningModal } from "src/pages/Contract/StopSigningModal/StopSigningModal";
import { manageLoadingState } from "src/pages/Contract/Contract.manageLoadingState";
import { AddendumForm } from "./subviews/AddendumForm";
import { SummaryAddendum } from 'src/pages/Addendum/subviews/Summary'

const Addendum: view = ({
  subview = observe.routes.list[RouteName.ADDENDUM].data.subview,
  stateTransition = observe.contract.loading.stateTransition,
  id = observe.contract.id,
}) => {
  if (!subview) return null;
  let selectedView = null;
  switch (subview) {
    case AddendumPageSubview.ADDENDUM:
      selectedView = <AddendumForm />;
      break;
    case AddendumPageSubview.ATTACHMENTS:
      selectedView = <Attachments />;
      break;
    case AddendumPageSubview.PARTIES:
      selectedView = <Parties />;
      break;
    case AddendumPageSubview.SUMMARY:
      selectedView = <SummaryAddendum />;
      break;

    default:
      break;
  }

  return (
    <>
      {stateTransition && <LoadingOverlay />}
      <div key="page" className="bg-gray-100 min-h-screen font-sans text-gray-800">
        <Navbar key="navbar" />
        <div className="flex flex-row justify-between">
          <main className={`fixed w-2/3 bg-gray-210`}>{selectedView}</main>
          <Sidebar />
        </div>

        <ActivityLogModal />
        <CreateVersionModal />
        <MoveToSigningModal />
        <ReEditContractModal />
        <ResetContractModal />
        <SendContractModal />
        <StopSigningModal />
      </div>
    </>
  );
};

Addendum.producers([...Object.values(producers), manageLoadingState]);

export { Addendum };
