import { db } from 'src/producers/firebase'
import { ContractEventType } from 'src/types'
export const syncErrors: producer = async ({
  userReady = observe.user.isReady,
  errors = update.errors[param.id],
  uid = get.user.uid
}) => {
  if (!userReady) return
  db.collection('errors')
    // .where('acknowledged', '!=', true)
    .where('uid', '==', uid.value())
    .onSnapshot((snapshot: any) => {
      snapshot.forEach((doc: any) => {
        const data = doc.data()
        if (!data || data.acknowledged) return
        data.fromDb = true
        errors.set(data, { id: doc.id })
      })
    })

}
