import i18n from "src/producers/languages/i18n";
import { FreeTextGeneralClause } from "./FreeTextGeneralClause";

export const CancelationAddendum: view = ({}) => (
  <div>
    <FreeTextGeneralClause varName="backgroundAndPurpose" title={`1. ${i18n.t("clause_background_reason_title")}`} />
    <FreeTextGeneralClause varName="tenancyCancelation" title={`2. ${i18n.t("clause_termination_tenancy_title")}`} />
    <FreeTextGeneralClause varName="otherProvisions" title={`3. ${i18n.t("clause_other_provisions_title")}`} />
  </div>
);
