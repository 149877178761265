import i18n from "src/producers/languages/i18n";

import { FieldInputType } from "src/templates/types/template";
import { PlainTextFieldGeneral } from "../../fields/PlainTextFieldGeneral";
import { ParkingType } from "./ParkingType";
import styles from "../../../../styles.module.css";

export const RentalObjectReadOnly: view = () => (
  <div>
    <div className={`${styles.partyFormRow}`}>
      <PlainTextFieldGeneral
        varName="numberOfParkingSpaces"
        inputType={FieldInputType.Number}
        extraClasses="pr-8 w-1/2 self-end"
        label={i18n.t("parking_contract.number_of_parking_spaces")}
      />
    </div>
    <div className={`${styles.partyFormRow}`}>
      <PlainTextFieldGeneral
        varName="numberOfChargers"
        inputType={FieldInputType.Number}
        extraClasses="pr-8 w-1/2 self-end"
        label={i18n.t("parking_contract.numberOfChargers")}
      />
    </div>
    <div className="w-1/2 my-2 self-end">
      <ParkingType />
    </div>
    <div className="w-full mb-6">
      <PlainTextFieldGeneral varName="rentalObject" inputType={FieldInputType.FreeText} />
    </div>
  </div>
);
