import { storageRef } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'
const RandExp = require('randexp')
const randomId = new RandExp(/[A-Za-z0-9_]{9}/)

export const addSignedContract: producer = async ({
  trigger = observe.contract.triggers.addSignedContract,
  removeTrigger = update.contract.triggers.addSignedContract,
  error = update.errors[param.id],
  contractId = get.contract.id,
  rawUser = get.rawUser,
  localFunctions = get.localFunctions,
}) => {
  //TODO simmilar to addAttachment
  contractId = contractId.value()

  if (!contractId || !trigger?.data?.name) {
    return
  }
  const fileId = randomId.gen()

  //TODO move storage logic server side
  const data = {
    fileName: trigger.data.name,
    fileType: trigger.data.type,
    fileId: fileId,
    path: `${contractId}/signed/${fileId}`,
    domain: 'user',
  }
  const fileRef = storageRef.child(`${contractId}/signed/${fileId}`)
  try {
    await fileRef.put(trigger.data, { contentType: trigger.data.type })

    const command = {
      action: CommandActions.EXECUTE_CONTRACT,
      contractId: contractId,
      ...data,
    }
    console.log('addSignedContract command', command)
    await axios.post(
      getFunctionsUrl('commandPublisher', localFunctions.value()),
      command,
      {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type': 'application/json'
        }
      }
    )
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'addSignedContract-contract' }
    )
  } finally {
    removeTrigger.remove()
  }
}
