import { UserRole, ApplicationError, ErrorLevel, ErrorType } from "src/types";
import { db } from "src/producers/firebase";
import { functions } from 'src/producers/firebase'

const handleClickSyncFilesToS3 = async () => {
    const syncFiles = functions.httpsCallable('queueTasks_syncFilesToS3Trigger')
    const filesToS3 = await syncFiles()
    console.log('>>>fils to s3: ', filesToS3)
  }
  
export const syncFilesToS3: producer = async ({
    isReady = observe.user.isReady,
    user = get.user,
    syncToS3Trigger = observe.syncToS3Trigger,
    updateSyncToS3Trigger = update.syncToS3Trigger,
    error = update.errors[param.id],
}) => {
    
  user = user.value();
  if (!isReady || user.data?.role !== UserRole.TENANT || !syncToS3Trigger) {
    return;
  }
  updateSyncToS3Trigger.remove();
  try {
    await handleClickSyncFilesToS3();
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncTenant" }
    );
  }
};
