import React from "react";

import { UserRole } from "src/types";
import { VersionBubbleTenant } from "./VersionBubbleTenant";
import { VersionBubbleManager } from "./VersionBubbleManager";

export const VersionBubble: view = ({ version, index, userRole = get.user.data.role }) => {
  const { versionId: versionNumber, createdAt, changes } = version;
  const isTenant = userRole.value() === UserRole.TENANT;

  return (
    <div className="p-4 bg-gray-200 w-full rounded mb-3 ">
      {isTenant ? (
        <VersionBubbleTenant createdAt={createdAt} />
      ) : (
        <VersionBubbleManager versionNumber={versionNumber} createdAt={createdAt} changes={changes} index={index} />
      )}
    </div>
  );
};
