import { Button } from "@c11/components.button";

import "./NewAgreementButton.css";
import i18n from "src/producers/languages/i18n";
import { PlusCircleIcon } from "src/assets/svg";

export const NewAgreementButton: view = ({
  loadingCreateAgreement = observe.dashboard.triggers.createPaperContract,
  openModal = update.modal.newAgreement,
}) => (
  <Button className="newContractBtn"
    variant="primary"
    isLoading={loadingCreateAgreement}
    onClick={() => {
      openModal.set(Date.now());
    }}>
    {!loadingCreateAgreement && <PlusCircleIcon />}
    <span>{i18n.t("dashboard.new_agreement_button")}</span>
  </Button>
);
