import Big from "big.js";

export const minimumMarketingCost: producer = ({
  minimumTurnOver = observe.legacyContract.values.MTO.displayedValue,
  marketingCosts = observe.legacyContract.values.marketingCosts.displayedValue,
  trigger = observe.legacyContract.custom.minimumMarketingCost,

  oldValue = get.legacyContract.values.minimumMarketingCost.displayedValue,

  minimumMarketingCost = update.legacyContract.currentSession.changes.minimumMarketingCost,
}) => {
  if (trigger || !minimumTurnOver || !marketingCosts) {
    return;
  }
  oldValue = oldValue.value();
  const value = new Big(minimumTurnOver).times(marketingCosts).div(100).round().toNumber();
  if (oldValue == value) {
    return;
  }
  minimumMarketingCost.set({
    value: value,
    createdAt: Date.now(),
    custom: false,
  });
};
