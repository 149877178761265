import React from "react";

import { UserRole } from "src/types";
import { Card } from "../index";
import i18n from "src/producers/languages/i18n";
import { FactoryFormField } from "../fields/formFields/FactoryFormField";

export const CompulsoryDerogation: view = ({
  title,
  reset = update.legacyContract.triggers.resetIntro,
  triggerReset = observe.legacyContract.triggers.resetIntro,
  resetIntroTextDefault = update.legacyContract.triggers.resetDefault,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;

  return (
    <Card
      key={triggerReset}
      title={title}
      actionText={isManager && i18n.t("clause_intro_reset")}
      actionFunction={
        isManager &&
        function () {
          reset.set(Date.now());
          resetIntroTextDefault.set({ varName: "compulsoryDerogation" });
        }
      }>
      <FactoryFormField key="0" fieldName="compulsoryDerogation" varName="compulsoryDerogation" />
    </Card>
  );
};
