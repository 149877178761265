import moment from "moment";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import i18n from "src/producers/languages/i18n";
import {
  AgreementType,
  ContractFilterOption,
  ContractStatus,
  UserRole
} from "src/types";
import { SVGs } from "src/assets";
import { ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from "src/components";
import { ContractMenu } from "src/pages/Dashboard/ContractMenu/ContractMenu";

import styles from "../styles.module.css";

export const ContractCard: view = ({ contract, onClick }) => {
  if (!contract) {
    return null;
  }
  // this logic is added for leggacy support
  let rentalStart = contract?.summary?.rentalStart;
  if (rentalStart) {
    rentalStart = dayjs(contract.summary.rentalStart).tz('Europe/Oslo').format('DD.MM.YYYY')
  }

  let rentalEnd = contract?.summary?.rentalEnd;
  if (rentalEnd) {
    rentalEnd = dayjs(contract.summary.rentalEnd).tz('Europe/Oslo').format('DD.MM.YYYY')
  }

  const rentalPeriod = `${rentalStart ? rentalStart + " - " : ""}${rentalEnd || ""}`;

  const renderStatus = () => {
    const status = contract.state?.status || contract.status;
    let statusIcon;
    let statusText;

    if (status === ContractStatus.DRAFT) {
      statusIcon = <SVGs.ContractStatusDraft />;
      statusText = i18n.t("dashboard.card_status_draft");
    }
    if (status === ContractStatus.NEGOTIATION) {
      statusIcon = <SVGs.ContractStatusNegotiation />;
      statusText = i18n.t("dashboard.card_status_negotiation");
    }
    if (status === ContractStatus.SIGNING) {
      statusIcon = <SVGs.ContractStatusSigning />;
      statusText = i18n.t("dashboard.card_status_signing");
    }
    if (status === ContractStatus.ACTIVE) {
      statusIcon = <SVGs.ContractStatusActive />;
      statusText = i18n.t("dashboard.card_status_active");
    }
    if (contract.filterTag === ContractFilterOption.ARCHIVED) {
      statusIcon = <SVGs.ContractStatusArchived />;
      statusText = i18n.t("dashboard.card_status_archived");
    }
    if (contract.filterTag === ContractFilterOption.LOST) {
      statusIcon = <SVGs.ContractStatusLost />;
      statusText = i18n.t("dashboard.card_status_lost");
    }
    if (contract.filterTag === ContractFilterOption.TRASHED) {
      statusIcon = <SVGs.ContractStatusTrashed />;
      statusText = i18n.t("dashboard.card_status_trashed");
    }

    return (
      <>
        <span>{statusText}</span>
        <span>{statusIcon && statusIcon}</span>
      </>
    );
  };

  return (
    <ThumbnailCard className={`${styles.thumbnailCard} `} key={`tc-${contract.id}`} onClick={onClick}>
      <ThumbnailCardHeader key={`tch-${contract.id}`} title={contract?.summary?.shoppingMall || contract.id}>
        <ContractMenu contractId={contract.id} contractFilterFlag={contract.filterTag} />
      </ThumbnailCardHeader>
      <ThumbnailCardBody key={`tcb-${contract.id}`} className="text-gray-600">
        <div className="mb-1">{(contract.agreementType === AgreementType.PARKING ? contract?.summary?.title : contract?.summary?.tenantName) || ""}</div>
        <div className="mb-1">{rentalPeriod}</div>
        <div className="mb-1">{contract?.summary?.propertyLocalNo ? `#${contract?.summary?.propertyLocalNo}` : ""}</div>
      </ThumbnailCardBody>
      <ThumbnailCardFooter key={`tcf-${contract.id}`} className="justify-between">
        {renderStatus()}
      </ThumbnailCardFooter>
    </ThumbnailCard>
  );
};
