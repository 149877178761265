import i18n from "src/producers/languages/i18n";
import { Modal } from "src/components";
import { SVGs } from "src/assets";
import { deleteAppendix } from "src/pages/Contract/subviews/Attachments/DeleteAttachmentModal/DeleteAttachmentModal.deleteAppendix";

export const DeleteAttachmentModal: view = ({
  appendixToDelete = observe.modal.deleteAppendixModal,
  triggerDeleteAppendix = update.contract.triggers.deleteAppendix,
  attachments = get.contract.original.state.attachments,
}) => {
  if (appendixToDelete === undefined) {
    return null;
  }

  attachments = attachments.value();
  if (!attachments) {
    return null;
  }

  return (
    <Modal
      title={i18n.t("modals_appendix_delete_title")}
      okButtonText={i18n.t("modals_appendix_delete_button")}
      image={() => <SVGs.ModalRemoveChanges />}
      onOkClick={() => triggerDeleteAppendix.set(appendixToDelete)}>
      <p>
        {i18n.t("modals_appendix_delete_p1")}{" "}
        <span key="1" className="font-medium">
          {attachments[appendixToDelete]?.fileName}
        </span>
        <span key="2"> ?</span>
      </p>
    </Modal>
  );
};

DeleteAttachmentModal.producers([deleteAppendix]);
