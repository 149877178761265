import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import { FactoryFormField } from "src/pages/Contract/subviews/ContractForm/fields/FactoryFormField";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { ManagerPotentialOptions } from "./ManagerPotentialOptions";
import styles from "../../../styles.module.css"

export const RentalPeriodClause: view = ({
  title,
  rentalPeriodOptionsPeriod = observe.contract.values.rentalPeriodOptionsPeriod.displayedValue,
  rentalPeriodHasOptionsDV = observe.contract.values.rentalPeriodHasOptions.displayedValue,
  rentalPeriodHasOptionsFromChanges = observe.contract.currentSession.changes.rentalPeriodHasOptions.value,
  rentalPeriodEorRDV = observe.contract.values.rentalPeriodEorR.displayedValue,
  rentalPeriodEorRFromChanges = observe.contract.currentSession.changes.rentalPeriodEorR.value,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  userRole = get.user.data.role,
}) => {
  const rentalPeriodHasOptions = rentalPeriodHasOptionsFromChanges || rentalPeriodHasOptionsDV;
  const rentalPeriodEorR = rentalPeriodEorRFromChanges || rentalPeriodEorRDV;

  const isManager = userRole.value() === UserRole.MANAGER;
  return (
    <ClauseCard title={title}>
      <div className={`${styles.contractCard}`}>
        <div className="flex flex-wrap">
          <FactoryFormField key="0" fieldName="rentalStart" varName="rentalStart" />
          <FactoryFormField key="1" fieldName="rentalEnd" varName="rentalEnd" />
          <FactoryFormField key="2" fieldName="storeOpening" varName="storeOpening" />

          {isManager && (
            <ManagerPotentialOptions
              key="03"
              rentalPeriodHasOptions={rentalPeriodHasOptions}
              rentalPeriodEorR={rentalPeriodEorR}
              isReadOnlyContract={isReadOnlyContract}
            />
          )}
          {/* TODO Make posible to save a boolean */}
          {rentalPeriodHasOptions === "true" && (
            <p key="p3" className="pb-2 pt-1">
              <span key="0">
                {i18n.t("rentalPeriodOptionsPeriod_p1_1", {
                  ER:
                    rentalPeriodEorR === "R"
                      ? i18n.t("clause_rental_period_potential_options_renewal")
                      : i18n.t("clause_rental_period_potential_options_extension"),
                })}
              </span>
              <strong key="1">
                {rentalPeriodOptionsPeriod &&
                  i18n.t(rentalPeriodOptionsPeriod) + i18n.t("rentalPeriodOptionsPeriod_p1_2")}
              </strong>
              <span key="2">
                {i18n.t("rentalPeriodOptionsPeriod_p1_3", {
                  ER:
                    rentalPeriodEorR === "R"
                      ? i18n.t("clause_rental_period_potential_options_renewal")
                      : i18n.t("clause_rental_period_potential_options_extension"),
                  T:
                    rentalPeriodEorR === "R"
                      ? i18n.t("clause_rental_period_potential_options_renegotiation")
                      : i18n.t("clause_rental_period_potential_options_same"),
                })}
              </span>
            </p>
          )}
          </div>
      </div>
    </ClauseCard>
  );
};
