
export const triggerVersion: producer = ({
  trigger = observe.legacyContract.triggers.triggerVersion,
  removeTrigger = update.legacyContract.triggers.triggerVersion,
  saveVersion = update.legacyContract.triggers.saveVersion,
  changes = observe.legacyContract.currentSession.changes,
  callbackTrigger = update.legacyContract.triggers[param.trigger],
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  if (!!changes && Object.keys(changes).length > 0) {
    saveVersion.set({ newContract: trigger.newContract || false, callback: trigger.callback });
  } else {
    callbackTrigger.set(Date.now(), { trigger: trigger.callback });
  }
};
//@ts-ignore
triggerVersion.meta = { name: "triggerVersion" };
