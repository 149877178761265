import { Contract } from "src/types/contract";

export interface ContractViewModel {
  contract: Contract;
  subview: ContractPageSubview;
  triggers: {
    [key: string]: any;
  };
}

export enum ContractPageSubview {
  SUMMARY = "SUMMARY",
  CONTRACT = "CONTRACT",
  ATTACHMENTS = "ATTACHMENTS",
  PARTIES = "PARTIES",
  ADDENDUMS = "ADDENDUMS",
}

export enum AddendumPageSubview {
  SUMMARY = "SUMMARY",
  ATTACHMENTS = "ATTACHMENTS",
  PARTIES = "PARTIES",
  ADDENDUM = "ADDENDUM",
}
