import { db } from "src/producers/firebase";
import { Contract, UserRole, ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const removeTag: producer = async ({
  trigger = observe.pages.dashboard.triggers.removeFilterTag,
  updateTrigger = update.pages.dashboard.triggers.removeFilterTag,
  updateDashboardContracts = update.pages.dashboard.contracts.data,
  error = update.errors[param.id],
  dashboardContracts = get.pages.dashboard.contracts.data,
  user = get.user,
}) => {
  user = user.value();
  if (!trigger || !trigger.contractId || user?.data?.role != UserRole.MANAGER) {
    return;
  }
  dashboardContracts = dashboardContracts.value();
  try {
    const filterContracts = dashboardContracts.filter((contract: Contract) => trigger.contractId !== contract.id);
    updateDashboardContracts.set(filterContracts);
    await db.collection("contracts").doc(String(trigger.contractId)).set(
      {
        filterTag: null,
        filterTagSetBy: {},
      },
      { merge: true }
    );
  } catch (e) {
    updateDashboardContracts.set(dashboardContracts);
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "removeTag" }
    );
  } finally {
    updateTrigger.remove();
  }
};
