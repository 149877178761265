import { ApplicationError, ContractStatus, ErrorLevel, ErrorType } from 'src/types'
import { db } from "src/producers/firebase";

export const saveContract: producer = async ({
  trigger = observe.pages.contract.triggers.savePaperContract_v2,
  removeTrigger = update.pages.contract.triggers.savePaperContract_v2,
  updateStatus = update.contract.originalv2.status,
  contractId = get.contract.id,
  error = update.errors[param.id],
  stateTransition = update.contract.loading.stateTransition,
}) => {
  if (!trigger) return
  stateTransition.set(true)

  contractId = contractId.value()

  try {
    // const command = {
    //   action: CommandActions.SAVE_PAPER_CONTRACT,
    //   contractId,
    //   session,
    //   currentSession,
    // }
    // console.log('saveContract command', command)
    // await axios.post(
    //   getFunctionsUrl('commandPublisher', localFunctions.value()),
    //   command,
    //   {
    //     headers: {
    //       'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
    //       'Content-Type': 'application/json'
    //     }
    //   }
    // )

    const contractDb = db.collection("contracts").doc(contractId);
    await contractDb.update({ status: ContractStatus.ACTIVE });
    updateStatus.set(ContractStatus.ACTIVE)

    
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'saveContract-contract' }
    )
  } finally {
    removeTrigger.remove()
  }
}
