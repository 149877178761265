import { notification } from 'antd'
import { UpdateInputField } from 'src/pages/ContractV2/producers/synchronize/updateInputField'
import { db } from 'src/producers/firebase'
export const contactFieldsToUpdate: {
  [key: string]: string
} = {
  firstName: 'tenantContactPersonFirstName',
  lastName: 'tenantContactPersonLastName',
  phone: 'tenantPhone',
  email: 'tenantEmail',
}

export const syncTenantContact: producer = async ({
  contactId = observe.contract.originalv2View.selectedTenantContact,
  mainContacts = get.contract.originalv2.tenant.mainContacts,
  contractId = get.contract.id,

  updateFieldChanges = update.contract.changes.fields[param.field],
  updateSelectedContact = update.contract.originalv2.tenant.selectedContact,
}) => {
  mainContacts = mainContacts.value()
  if (!contactId) return
  if (!mainContacts) return

  const mainContact = mainContacts.find((contact: any) => contact.id === contactId)
  if (!mainContact) return

  await db.collection('contracts').doc(contractId.value()).update({ 'tenant.selectedContact': mainContact })
  updateSelectedContact.set(mainContact)

  for (const key of Object.keys(mainContact)) {
    if (!contactFieldsToUpdate[key]) continue
    const value = mainContact[key]
    if (!value) continue
    updateFieldChanges.set({ value }, { field: contactFieldsToUpdate[key] })
    UpdateInputField(contactFieldsToUpdate[key] as string, value)
  }
}
