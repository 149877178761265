import i18n from "src/producers/languages/i18n";
import styles from "../../../Contract/styles.module.css";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";

export const PartiesCard: view = ({ inheritedData = observe.contract.original.inheritedData }) => {
  return (
    <ClauseCard>
      <div className={`${styles.contractCard}`}>
        <p className="mb-2">{`${i18n.t("addendum.between")}`}</p>
        <p>{`${i18n.t("general.landlord")}: ${inheritedData.landlordName}`}</p>
        <p>{`${i18n.t("general.tenant")}: ${inheritedData.tenantName}`}</p>
      </div>
    </ClauseCard>
  );
};
