import React from 'react'

import * as producers from 'src/pages/producers/agreement/contract'
import {ContractPageSubview, RouteName} from 'src/types'
import {LoadingOverlay} from 'src/components/LoadingOverlay'
import {Navbar} from 'src/pages/Contract/Navbar/Navbar'
import {Sidebar} from 'src/pages/Contract/Sidebar/Sidebar'
import {
  Attachments
} from 'src/pages/Contract/subviews/Attachments/Attachments'
import {
  ContractForm
} from 'src/pages/Contract/subviews/ContractForm/ContractForm'
import {Parties} from 'src/pages/Contract/subviews/Parties/Parties'
import {Summary} from 'src/pages/Contract/subviews/Summary/Summary'
import {
  ActivityLogModal
} from 'src/pages/Contract/ActivityLogModal/ActivityLogModal'
import {
  CreateVersionModal
} from 'src/pages/Contract/CreateVersionModal/CreateVersionModal'
import {
  MoveToSigningModal
} from 'src/pages/Contract/MoveToSigningModal/MoveToSigningModal'
import {
  ReEditContractModal
} from 'src/pages/Contract/ReEditContractModal/ReEditContractModal'
import {
  ResetContractModal
} from 'src/pages/Contract/ResetContractModal/ResetContractModal'
import {
  SendContractModal
} from 'src/pages/Contract/SendContractModal/SendContractModal'
import {
  StopSigningModal
} from 'src/pages/Contract/StopSigningModal/StopSigningModal'
import {
  manageLoadingState
} from 'src/pages/Contract/Contract.manageLoadingState'
import {syncTenant} from 'src/pages/Contract/Contract.syncTenant'
import {NewAddendumModal} from './NewAddendumModal/NewAddendumModal'
import {Addendums} from './subviews/Addendums'

const Contract: view = ({
  subview = observe.routes.list[RouteName.CONTRACT].data.subview,
  stateTransition = observe.contract.loading.stateTransition,
}) => {
  if (!subview) return null;
  let selectedView = null;
  switch (subview) {
    case ContractPageSubview.SUMMARY:
      selectedView = <Summary/>;
      break;
    case ContractPageSubview.CONTRACT:
      selectedView = <ContractForm/>;
      break;
    case ContractPageSubview.ATTACHMENTS:
      selectedView = <Attachments/>;
      break;
    case ContractPageSubview.PARTIES:
      selectedView = <Parties/>;
      break;
    case ContractPageSubview.ADDENDUMS:
      selectedView = <Addendums/>;
      break;
    default:
      break;
  }

  return (
    <>
      {stateTransition && <LoadingOverlay/>}
      <div key="page" className="bg-gray-100 min-h-screen font-sans text-gray-800">
        <Navbar key="navbar"/>
        <div className="flex flex-row justify-between">
          <main className={`fixed w-2/3 bg-gray-210`}>{selectedView}</main>
          <Sidebar/>
        </div>
        <NewAddendumModal/>
        <ActivityLogModal/>
        <CreateVersionModal/>
        <MoveToSigningModal/>
        <ReEditContractModal/>
        <ResetContractModal/>
        <SendContractModal/>
        <StopSigningModal/>
      </div>
    </>
  );
};

Contract.producers([...Object.values(producers), manageLoadingState, syncTenant]);

export {Contract};
