import { TenantInputFields } from 'src/pages/ContractV2/DigitalContract/ContractTab/clauses/Parties'
export const validateDataSelections = (tenant: any, building: any, errorNotification: any, updateInvalidFields: any) => {
  console.log('tenant', tenant, 'building', building)
  if (!tenant || !tenant.id) {
    // errorNotification.set({ message: 'tenant-not-selected' }, { id: 'tenant-selection' })
    updateInvalidFields.set('validation_message_missing', { varName: TenantInputFields.NUMBER })
    return false
  }
  if (!building || !building.id) {
    // errorNotification.set({ message: 'building-not-selected' }, { id: 'building-selection' })
    updateInvalidFields.set('validation_message_missing', { varName: 'buildingSearch' })
    return false
  }
  return true
}
