import { ApplicationError, ErrorLevel, ContractEventType } from "src/types";
import { db } from "src/producers/firebase";

export const syncActivity: producer = async ({
  contractId = observe.legacyContract.id,
  setActivityLog = update.legacyContract.activity,
  error = update.errors[param.type],
}) => {
  if (!contractId) {
    return;
  }
  try {
    let activityLog: any = [];
    const contractDb = db.collection("contracts").doc(contractId);
    contractDb.collection("activity").onSnapshot((snapshot) => {
      snapshot.forEach((doc) => {
        const data = doc.data();
        if (!data) return;
        if (data.type === ContractEventType.VERSION_CREATED) {
          //
        }
        activityLog.push(data);
      });
      activityLog.sort((a: any, b: any): any => (parseInt(a.createdAt) < parseInt(b.createdAt) ? -1 : 1));

      setActivityLog.set(activityLog);
    });
  } catch (e) {
    console.error("Error syncing activity, ", e);
    error.set({ message: "error-sync-activity", level: ErrorLevel.LOW } as ApplicationError, { type: "auth" });
  }
};
