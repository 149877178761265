import i18n from "src/producers/languages/i18n";
import {
  FreeTextFieldGeneral,
  NumberInputGeneral,
  RegularInputGeneral
} from '../../fields/FormField'
import { ParkingType } from "./ParkingType";
import styles from "../../../../styles.module.css";

export const RentalObjectManager: view = () => (
  <div>
    <div className={`${styles.partyFormRow}`}>
      <NumberInputGeneral
        varName="numberOfParkingSpaces"
        placeholder={i18n.t("placeholders.add_amount")}
        extraClasses="self-end"
        label={i18n.t("parking_contract.number_of_parking_spaces")}
      />
      <div className="w-1/2 my-2 self-end">
        <ParkingType />
      </div>
      <RegularInputGeneral
        key="numberOfChargers"
        placeholder={i18n.t("placeholders.add_amount")}
        label={i18n.t("parking_contract.numberOfChargers")}
        varName="numberOfChargers"
      />
    </div>
    <div className="w-full mb-6 mt-2">
      <FreeTextFieldGeneral varName="rentalObject" />
    </div>
  </div>
);
