import { SVGs } from "src/assets";
import { ContractStatus, PartyType } from "src/types";
import i18n from "src/producers/languages/i18n";
import { NavbarMenu } from 'src/pages/ContractV2/components/Navbar/NavbarMenu'
import { ContractName } from 'src/pages/ContractV2/components/Navbar/ContractName'
import { StatusPill } from 'src/pages/ContractV2/components/Navbar/StatusPill'
import { ContractNavbarButton } from 'src/pages/ContractV2/components/Navbar/ContractNavbarButton'
// import { ContractNavbarButton } from "src/pages/Contract/Navbar/ContractNavbarButton";
// import { ContractName } from "src/pages/Contract/Navbar/ContractName";
// import { StatusPill } from "src/pages/Contract/Navbar/StatusPill";
// import { NavbarMenu } from "src/pages/Contract/Navbar/NavbarMenu";
import { BackArrow } from "src/assets/svg";
import styles from "../../styles.module.css"
import { db, fieldValue } from "src/producers/firebase";
import { VisibleIf } from "src/components_generic/visible-if";
import { router } from "src/router";

export const Navbar: view = ({
  hasInvalidFields = observe.contract.hasInvalidFields,
  signers = observe.contract.originalv2.signers,
}) => {
  signers = signers ? Object.values(signers) : []
  const areSignersValid = !!signers.find((p: any) => p.partyType == PartyType.TENANT) && !!signers.find((p: any) => p.partyType == PartyType.LANDLORD)

  let tooltipText
  // if (displayedVersion) tooltipText = i18n.t('button_generate_contract_oldVersion_tooltip')
  if (hasInvalidFields) tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_invalid_fields')
  if (!areSignersValid) tooltipText = i18n.t('parties_page.no_parties_warning')
  // if (hasChanges) tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_unpublished_changes')

  return <nav className={`${styles.navHeaderContract}`}>
    <div className={`${styles.navHeaderContractInfo}`}>
      <div key="left">
        <BackButton />
        <ContractName />
        <StatusPill />

      </div>
      <div key="right">
        <VisibleIf predicate={hasInvalidFields}>
          <div className='text-center text-pink-600 text-sm mr-8'>
            {tooltipText}
          </div>
        </VisibleIf>

        <ContractNavbarButton />
      </div>
    </div>
    <div className={`${styles.navHeaderContractTabs}`}>
      <NavbarMenu />
    </div>
  </nav>
};

const BackButton: view = ({
  parentContractId = get.contract.originalv2.parentContractId,
  contractId = get.contract.originalv2.id,
  // setCurrentPath = update.routes.currentPath,
  currentQuery = observe.routes.currentQuery,
  updateReroute = update.routes.triggers.reroute,
  clearAll = update.contract,
  getContracts = update.pages.dashboard.triggers.getContracts,
}) => (
  <button
    className="mr-3 relative focus:outline-none border-focus"
    onClick={() => {
      // setCurrentPath.set(parentContractId.value() ? `/contract/${parentContractId.value()}` : "/");
      router.navigate(
        (parentContractId.value() ? `/contract/${parentContractId.value()}` : "/") +
        (currentQuery || ""));
      updateReroute.set(Date.now());
      db.collection('contracts').doc(contractId.value()).update({ isEditedBy: fieldValue.delete() })
      getContracts.set(Date.now());
      clearAll.remove();
    }}>
    <BackArrow />
  </button>
);

const ActivityLogButton: view = ({
  showModal = update.modal.activityLog,
  id = observe.contract.id,
  status = observe.contract.originalv2.state.status,
}) => {
  const isDraft = status === ContractStatus.DRAFT;

  if (isDraft) return null;
  return (
    <div onClick={() => showModal.set(Date.now())} className="flex items-center cursor-pointer">
      {i18n.t("contract_navbar.activity_log")}
      <SVGs.ActivityLog className="ml-2" />
    </div>
  );
};
