import moment from "moment";

import i18n from "src/producers/languages/i18n";
import { CalendarIcon } from "src/assets/svg";
import { DateFieldGeneral } from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import { ContractStatus, UserRole } from "src/types";

import styles from "../../../styles.module.css";

export const ContractDatesClause: view = ({
  leaseDate = observe.contract.values.leaseDate.displayedValue,
  rentalStart = observe.contract.values.rentalStart.displayedValue,
  rentalEnd = observe.contract.values.rentalEnd.displayedValue,
  status = observe.contract.original.status,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isActive = status === ContractStatus.ACTIVE;

  return (
    <div className={`${styles.contractDatesClause}`}>
      <div className={`${styles.contractDatesClauseHeader}`}>
        <CalendarIcon />
        <span>{i18n.t("contract_page.contract_dates_clause_p")}</span>
      </div>
      <div className={`${styles.datesInputRow}`}>
        <div className="flex flex-col">
          {isManager && !isActive ? (
            <>
              <span>
                <span className="text-pink-600">*</span>
                {i18n.t("contract_page.contract_dates_clause_lease_label")}
              </span>
              <DateFieldGeneral varName="leaseDate" placeholder={i18n.t("placeholders.select_date")} />
            </>
          ) : (
            <span>{moment(leaseDate).utc().format("DD.MM.YYYY")}</span>
          )}
        </div>
        <div className="flex flex-col">
          {isManager && !isActive ? (
            <>
              <span>
                <span className="text-pink-600">*</span>
                {i18n.t("contract_page.contract_dates_clause_start_label")}
              </span>
              <DateFieldGeneral varName="rentalStart" placeholder={i18n.t("placeholders.select_date")} />
            </>
          ) : (
            <span>{moment(rentalStart).utc().format("DD.MM.YYYY")}</span>
          )}
        </div>
        <div className="flex flex-col">
          {isManager && !isActive ? (
            <>
              <span>
                <span className="text-pink-600">*</span>
                {i18n.t("contract_page.contract_dates_clause_end_label")}
              </span>
              <DateFieldGeneral varName="rentalEnd" placeholder={i18n.t("placeholders.select_date")} />
            </>
          ) : (
            <span>{moment(rentalEnd).utc().format("DD.MM.YYYY")}</span>
          )}
        </div>
      </div>
    </div>
  );
};
