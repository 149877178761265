import mallValidate from 'src/assets/validations/SHOPPING_MALL.js'
import parkingValidate from 'src/assets/validations/PARKING.js'
import paperValidate from 'src/assets/validations/PAPER.js'
import cancellationValidate from 'src/assets/validations/CANCELLATION.js'
import downpaymentValidate from 'src/assets/validations/DOWNPAYMENT.js'
import rentalPeriodPriceValidate from 'src/assets/validations/RENTAL_PERIOD_PRICE.js'
import storageValidate from 'src/assets/validations/STORAGE.js'
import transportValidate from 'src/assets/validations/TRANSPORT.js'
import { ContractTemplate } from 'src/pages/ContractV2/contract.types'

export const validations: any = {
  [ContractTemplate.SHOPPING_MALL]: mallValidate,
  [ContractTemplate.PARKING]: parkingValidate,
  [ContractTemplate.PAPER]: paperValidate,
  [ContractTemplate.CANCELLATION]: cancellationValidate,
  [ContractTemplate.DOWNPAYMENT]: downpaymentValidate,
  [ContractTemplate.RENTAL_PERIOD_PRICE]: rentalPeriodPriceValidate,
  [ContractTemplate.STORAGE]: storageValidate,
  [ContractTemplate.TRANSPORT]: transportValidate
}
