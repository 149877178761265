import "./NewAddendumModal.css";

import i18n from "src/producers/languages/i18n";
import { AgreementDigitalContractIcon } from "src/assets/svg";
import { Modal, ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from "src/components";
import { Button } from "@c11/components.button";
import { SVGs } from "src/assets";
import { createAddendum } from "./NewAddendumModal.createAddendum";
import { ContractTemplate } from "src/pages/ContractV2/contract.types";
import styles from "../styles.module.css";

export const NewAddendumModal: view = ({
  trigger = observe.modal.newAddendum,
  modal = update.modal,
  triggerCreateAddendum = update.contract.triggers.createAddendum,
  rentalPeriodPriceAddendumFlag = observe.featureFlags.rentalPeriodPriceAddendum.main,
  storageAddendumFlag = observe.featureFlags.storageAddendum.main,
  transportAddendumFlag = observe.featureFlags.transportAddendum.main,
}) => {
  if (!trigger) return null;

  return (
    <Modal
      title={i18n.t("addendum.select_new_addendum")}
      width="auto"
      className={`${styles.multipleOptionsModal}`}
      cancelButtonHidden="true">
      <section className={`${styles.cardOptionsModal}`}>
        <div className={`${styles.modalOptionsRow}`}>
          <ThumbnailCard className={`${styles.option}`}>
            <ThumbnailCardHeader
              key="tch-1"
              className="font-robotoSlab flex-col-reverse"
              title={i18n.t("addendum.cancelation_addendum")}>
              <AgreementDigitalContractIcon width={70} height={70} />
            </ThumbnailCardHeader>
            <ThumbnailCardBody key="tcb-1">
              <p className="text-gray-600">{i18n.t("addendum.new_cancelation_description")}</p>
            </ThumbnailCardBody>
            <ThumbnailCardFooter key="tcf-1">
              <Button
                className={`${styles.modalSecondaryButton}`}
                onClick={() => {
                  triggerCreateAddendum.set({ template: ContractTemplate.CANCELLATION });
                  modal.remove();
                }}>
                <SVGs.RightArrowIcon className="mr-2" />
                {i18n.t("dashboard.agreement_option_button")}
              </Button>
            </ThumbnailCardFooter>
          </ThumbnailCard>
          <ThumbnailCard className={`${styles.option}`}>
            <ThumbnailCardHeader
              key="tch-1"
              className="font-robotoSlab flex-col-reverse"
              title={i18n.t("addendum.downpayment_addendum")}>
              <AgreementDigitalContractIcon width={70} height={70} />
            </ThumbnailCardHeader>
            <ThumbnailCardBody key="tcb-1">
              <p className="text-gray-600">{i18n.t("addendum.new_downpayment_description")}</p>
            </ThumbnailCardBody>
            <ThumbnailCardFooter key="tcf-1">
              <Button
                className={`${styles.modalSecondaryButton}`}
                onClick={() => {
                  triggerCreateAddendum.set({ template: ContractTemplate.DOWNPAYMENT });
                  modal.remove();
                }}>
                <SVGs.RightArrowIcon className="mr-2" />
                {i18n.t("dashboard.agreement_option_button")}
              </Button>
            </ThumbnailCardFooter>
          </ThumbnailCard>
          {rentalPeriodPriceAddendumFlag && (
            <ThumbnailCard className={`${styles.option}`}>
              <ThumbnailCardHeader
                key="tch-1"
                className="font-robotoSlab flex-col-reverse"
                title={i18n.t("addendum.change_price_period_addendum")}>
                <AgreementDigitalContractIcon width={70} height={70} />
              </ThumbnailCardHeader>
              <ThumbnailCardBody key="tcb-1">
                <p className="text-gray-600">{i18n.t("addendum.new_rental_period_price_description")}</p>
              </ThumbnailCardBody>
              <ThumbnailCardFooter key="tcf-1">
                <Button
                  className={`${styles.modalSecondaryButton}`}
                  onClick={() => {
                    triggerCreateAddendum.set({ template: ContractTemplate.RENTAL_PERIOD_PRICE });
                    modal.remove();
                  }}>
                  <SVGs.RightArrowIcon className="mr-2" />
                  {i18n.t("dashboard.agreement_option_button")}
                </Button>
              </ThumbnailCardFooter>
            </ThumbnailCard>
          )}
          {storageAddendumFlag && (
            <ThumbnailCard className={`${styles.option}`}>
              <ThumbnailCardHeader
                key="tch-1"
                className="font-robotoSlab flex-col-reverse"
                title={i18n.t("addendum.storage_addendum")}>
                <AgreementDigitalContractIcon width={70} height={70} />
              </ThumbnailCardHeader>
              <ThumbnailCardBody key="tcb-1">
                <p className="text-gray-600">{i18n.t("addendum.new_storage_description")}</p>
              </ThumbnailCardBody>
              <ThumbnailCardFooter key="tcf-1">
                <Button
                  className={`${styles.modalSecondaryButton}`}
                  onClick={() => {
                    triggerCreateAddendum.set({ template: ContractTemplate.STORAGE });
                    modal.remove();
                  }}>
                  <SVGs.RightArrowIcon className="mr-2" />
                  {i18n.t("dashboard.agreement_option_button")}
                </Button>
              </ThumbnailCardFooter>
            </ThumbnailCard>
          )}
          {transportAddendumFlag && (
            <ThumbnailCard className={`${styles.option}`}>
              <ThumbnailCardHeader
                key="tch-1"
                className="font-robotoSlab flex-col-reverse"
                title={i18n.t("addendum.transport_addendum")}>
                <AgreementDigitalContractIcon width={70} height={70} />
              </ThumbnailCardHeader>
              <ThumbnailCardBody key="tcb-1">
                <p className="text-gray-600">{i18n.t("addendum.new_transport_description")}</p>
              </ThumbnailCardBody>
              <ThumbnailCardFooter key="tcf-1">
                <Button
                  className={`${styles.modalSecondaryButton}`}
                  onClick={() => {
                    triggerCreateAddendum.set({ template: ContractTemplate.TRANSPORT });
                    modal.remove();
                  }}>
                  <SVGs.RightArrowIcon className="mr-2" />
                  {i18n.t("dashboard.agreement_option_button")}
                </Button>
              </ThumbnailCardFooter>
            </ThumbnailCard>
          )}
        </div>
      </section>
    </Modal>
  );
};

NewAddendumModal.producers([createAddendum]);
