import {ApplicationError, ContractSigningMethod, ContractType, ErrorLevel, ErrorType, LogLevels} from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import { db } from 'src/producers/firebase'
import { ContractFlow, ContractStatus, ContractTemplate } from '../../contract.types'
import { get, isNull, isUndefined, omitBy } from 'lodash'
import moment from 'moment'
import i18n from 'src/producers/languages/i18n'
import { notification } from 'antd'
import { router } from 'src/router'

const INHERITED_FIELDS = [
  'landlordAccountNo',
  'landlordAddress',
  'landlordContactPersonFirstName',
  'landlordContactPersonLastName',
  'landlordEmail',
  'landlordInvoiceAddress',
  'landlordName',
  'landlordOrgNo',
  'landlordPhone',
  'landlordPostalAddress',
  'landlordPropertyManager',
  'tenantName',
  'tenantOrgNo',
  'tenantContactPersonFirstName',
  'tenantContactPersonLastName',
  'tenantPhone',
  'tenantEmail',
  'tenantOrgNo',
  'leaseDate',
  'municipality',
  'parcelNumber',
  'propertyAddress',
  'propertyLocalNo',
  'propertyLevel',
  'propertyExclusiveArea',
  'propertyCommonArea',
  'shoppingMall'
] as const

const getAddendumTitle = (template: string) => {
  switch (template) {
    case ContractTemplate.STORAGE:
      return i18n.t('contract.addendums.storage_addendum')
    case ContractTemplate.CANCELLATION:
      return i18n.t('contract.addendums.cancellation_addendum')
    case ContractTemplate.DOWNPAYMENT:
      return i18n.t('contract.addendums.downpayment_addendum')
    case ContractTemplate.RENTAL_PERIOD_PRICE:
      return i18n.t('contract.addendums.change_price_period_addendum')
    case ContractTemplate.TRANSPORT:
      return i18n.t('contract.addendums.transport_addendum')
  }
}

const parseDefaultValues = (values: any, fields: any) => {
  for (const field of Object.keys(values)) {
    const value = values[field].value
    values[field].value = value.replace(/{{(.*?)}}/g, (match: any, p1: any) => fields[p1.trim()]?.value || '')
  }
  return values
}

const INHERITED_PROPERTIES = ['building', 'tenant'] as const

export const createAddendum: producer = async ({
  user = get.user.value(),
  contractId = get.contract.id.value(),

  contract = get.contract.originalv2.value(),
  addendums = get.contract.originalv2.addendums.value(),
  fields = get.contract.originalv2.fields.value(),

  updateAddendums = update.contract.originalv2.addendums,
  // setCurrentPath = update.routes.currentPath,
  currentQuery = observe.routes.currentQuery,
  updateReroute = update.routes.triggers.reroute,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  defaultValues = get.defaultValues[param.template],

  trigger = observe.contract.triggers.createAddendum,
  removeTrigger = update.contract.triggers.createAddendum
}) => {
  if (!trigger) return
  if (!trigger?.template) return
  if (!contract) return
  if (!contractId) return

  if (!fields) return
  if (Object.keys(fields).length < 1) return

  if (!user) return

  let inheritedFields: any = INHERITED_FIELDS.reduce((acc, curr) => {
    acc[curr] = get(fields, `${curr}`)
    return acc
  }, {} as { [key in (typeof INHERITED_FIELDS)[number]]: any })

  let inheritedProperties: any = INHERITED_PROPERTIES.reduce((acc, curr) => {
    acc[curr] = get(contract, curr)
    return acc
  }, {} as { [key in (typeof INHERITED_PROPERTIES)[number]]: any })
  inheritedFields.originalTenantName = { value: inheritedFields.tenantName?.value }
  inheritedFields = omitBy(inheritedFields, isNull)
  inheritedFields = omitBy(inheritedFields, isUndefined)

  inheritedProperties = omitBy(inheritedProperties, isNull)
  inheritedProperties = omitBy(inheritedProperties, isUndefined)

  const inheritedData = {
    ...inheritedFields,
    ...inheritedProperties
  }

  const defaultValuesForTemplate = parseDefaultValues(defaultValues.value({ template: trigger.template }) || {}, inheritedData)
  const contractTitle = getAddendumTitle(trigger.template)

  let addendum = {
    id: `Addendum-${moment().format('DD-MM-YY_HH-mm-SSS')}`,
    parentContractId: contractId,
    template: trigger.template,
    flow: ContractFlow.DIGITAL,
    title: contractTitle,

    handler: user.uid,
    status: ContractStatus.DRAFT,
    signing: ContractSigningMethod.DIGITAL,
    contractType: ContractType.DIGITAL,
    createdAt: new Date().toISOString(),

    inheritedData: {
      ...inheritedFields,
      ...inheritedProperties
    },

    fields: {
      ...defaultValuesForTemplate,
      ...inheritedFields,
      title: { value: contractTitle }
    },
    defaultValues: defaultValuesForTemplate,
    building: inheritedProperties.building,
    tenant: inheritedProperties.tenant,
    revision: 4
  }

  addendum = omitBy(addendum, isNull) as typeof addendum
  addendum = omitBy(addendum, isUndefined) as typeof addendum

  try {
    isSaving.set(true)
    await db
      .collection('contracts')
      .doc(contractId)
      .set(
        {
          addendums: {
            [addendum.id]: addendum
          }
        },
        {
          merge: true
        }
      )

    await db
      .collection('contracts')
      .doc(addendum.id)
      .set({
        ...addendum
      })

    updateAddendums.merge({
      [addendum.id]: addendum
    })
    successfulSave.set(true)

    // setCurrentPath.set(`/contract/${addendum.id}`)
    router.navigate(`/contract/${addendum.id}` + (currentQuery || ''))
    updateReroute.set(Date.now())
    notification.info({
      message: `New addendum created succesfully`
    })
  } catch (e) {
    console.error('ERROR: ', e)
    error.set(
      {
        message: 'error-creating-addendum',
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: 'persist-new-addendum' }
    )
  } finally {
    removeTrigger.remove()
    isSaving.set(false)
  }
}
