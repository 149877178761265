import { notification } from 'antd'
import { db } from 'src/producers/firebase'
import i18n from 'src/producers/languages/i18n'
import {ApplicationError, ErrorLevel, ErrorType, LogLevels, UserRole} from 'src/types'
import { syncBuildingToContract } from 'src/pages/ContractV2/producers/synchronize/syncBuildingToContract'

export const persistBuilding: producer = async ({
  building = observe.contract.changes.building,
  isLocked = get.contract.isLocked,
  contractId = get.contract.id,
  template = get.contract.originalv2.template.value(),

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,

  updateBuildingChange = update.contract.changes.building,
  updateContractBuilding = update.contract.originalv2.building,
  error = update.errors[param.id],

  contractField = get.contract.originalv2.fields[param.field],
  attachments = get.contract.originalv2.attachments.value(),

  updateAttachmentsToRemove = update.contract.changes.attachmentsToRemove,
  updateFieldChanges = update.contract.changes.fields[param.field],

  updateAttachmentsChanges = update.contract.changes.attachments.building,
}) => {
  if (!building || Object.keys(building).length < 1) return
  if (isLocked.value()) return

  contractId = contractId.value()
  try {
    isSaving.set(true)
    const updatedAt = new Date().toISOString()
    await db.collection('contracts').doc(contractId).update({ building, updatedAt })
    updateBuildingChange.remove()
    updateContractBuilding.set(building)
    await syncBuildingToContract(
      building,
      attachments,
      contractField,
      template,
      updateFieldChanges,
      updateAttachmentsToRemove,
      updateAttachmentsChanges
    )
    successfulSave.set(true)

    notification.info({
      message: i18n.t('contract.notifications.building_selected', { name: building?.name }),
    })
  } catch (e) {
    console.error('persistBuildingChanges', e)
    failedSave.set(true)
    error.set(
      {
        message: 'saving-building-to-database',
        level: LogLevels.ERROR,
      } as ApplicationError,
      { id: 'persistBuildingChanges' }
    )
  }
  isSaving.set(false)
}
