import { validations } from './schemas'

export const validateForm = (values: any, contractType: string) => {
  if (!validations[contractType]) return { valid: true, errors: {} }
  const validate = validations[contractType]
  let valid = validate(values)

  console.log('valid', valid, validate.errors)
  const errors: any = {}
  if (!valid) {
    for (const error of validate.errors) {
      let field = error.instancePath.substring(1)
      let translationKey

      switch (error.keyword) {
        case 'required':
          translationKey = 'validation_message_missing'
          field = error.params.missingProperty
          break
        case 'format':
          translationKey = `validation_message_${error.params.format}`
          break
        case 'pattern':
          translationKey = 'validation_message_error'
          if (field === 'landlordEmail' || field === 'tenantEmail') translationKey = 'validation_message_email'
          if (field === 'landlordPhone' || field === 'tenantPhone') translationKey = 'validation_message_phone'
          break
        case 'minimum':
          translationKey = 'validation_message_minimum'
          if (field === 'rentalEnd') translationKey = 'validation_message_rentalEnd'
          break
        default:
          translationKey = 'validation_message_error'
      }

      errors[field] = translationKey
    }
  }
  if (values.rentalStart && values.rentalEnd < values.rentalStart) {
    errors['rentalEnd'] = 'validation_message_rentalEnd'
    valid = false
  }
  return { valid, errors }
  // return { valid: true, errors: {} }
}
