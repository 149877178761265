type VisibleIfProperties = {
  predicate: boolean
};

export const VisibleIf: React.FC<VisibleIfProperties> = ({
  predicate, children
}) => {
  if (!predicate) return <></>;
  return <>
    {children}
  </>
}
