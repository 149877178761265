import React from "react";

import { UserRole } from "src/types";
import i18n from "src/producers/languages/i18n";

export const Label: view = ({
  fieldName,
  templateField = observe.legacyContract.fields[arg.fieldName],
  userRole = get.user.data.role,
}) => {
  if (!templateField?.label) return null;
  const { label, requiredForManager, requiredForTenant } = templateField;
  const isTenant = userRole.value() === UserRole.TENANT;
  const isManager = userRole.value() === UserRole.MANAGER;
  const hasAsterisk = (isManager && requiredForManager) || (requiredForTenant && isTenant);

  return (
    <label className="block text-gray-700 text-xs">
      {hasAsterisk && <span className="text-pink-600">*</span>}
      <span>{`${label} `}</span>
    </label>
  );
};
