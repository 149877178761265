import React from "react";

import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { Changes } from "./Changes";

export const VersionBubbleManager: view = ({
  versionNumber,
  createdAt,
  changes,
  index,
  isExpanded = observe.legacyContract.updatesFeed[arg.index].details,
  setUpdatesFeedDetails = update.legacyContract.updatesFeed[arg.index].details,
}) => {
  versionNumber = parseInt(versionNumber);
  let changesList: any;
  if (changes) {
    changesList = Object.keys(changes).map((varName) => {
      const { value, antecedent } = changes[varName];
      return <Changes key={varName} newValue={value} oldValue={antecedent} currentVariable={varName} />;
    });
  }
  const noOfChanges = changesList?.length;
  const title = i18n.t("sidebar.version_created", { n: versionNumber });
  return (
    <>
      <div className="flex justify-between">
        <span className="flex">
          <SVGs.Page />
          <span className="ml-2 font-medium">{title}</span>
        </span>
        <span className="font-12 text-gray-600 ">{createdAt}</span>
      </div>
      <div className="bg-white mt-2 rounded">
        <div style={{ padding: "6px 10px" }} className="flex justify-between">
          <span>{i18n.t("sidebar.no_updates", { count: noOfChanges })}</span>
          <span className="text-pink-600 cursor-pointer" onClick={() => setUpdatesFeedDetails.set(!isExpanded)}>
            {isExpanded ? i18n.t("sidebar.colapse") : i18n.t("sidebar.details")}
          </span>
        </div>

        {isExpanded && <div>{changesList}</div>}
      </div>
    </>
  );
};
