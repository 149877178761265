import { Switch } from '@c11/components'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import { HeaderSection } from '../../components/HeaderSection/HeaderSection'
import { PDFDownloadButton } from '../PDFDownloadButton/PDFDownloadButton'
import { ParkingContract } from './ParkingContract'
import { ShoppingMallContract } from './ShoppingMallContract'
import { AddendumCancellation } from './templates/addendums/cancellation'
import { AddendumChangeInRentalPriceDate } from './templates/addendums/change-in-rental-price-date'
import { AddendumDownpayment } from './templates/addendums/downpayment'
import { AddendumRentalAgreementStorageUnit } from './templates/addendums/rental-agreement-for-storage-unit'
import { AddendumTransport } from './templates/addendums/transport'
import { TransferContractData } from 'src/pages/ContractV2/DigitalContract/TransferContractData/transferContractData'
import { WarningBar } from 'src/components_generic/WarningBar'
import { ContractStatus } from 'src/types'
import { ContractEditedByWarning } from 'src/pages/ContractV2/components/ContractEditedByWarning'

export const DigitalContract: view = ({
  // originalContract = observe.contract.originalv2,
  // isReadOnlyView = observe.random.isReadOnlyView,
  isLocked = observe.contract.isLocked,
  user = get.contract.editor.value(),
  status = get.contract.originalv2.status.value(),
  // updateIsReadOnlyView = update.random.isReadOnlyView
}) => {
  return (<>
    <HeaderSection title={i18n.t('contract_editor')}>
      <TransferContractData/>
      {/*<div className="mx-4 mt-4 flex flex-col items-center justify-center" style={{ width: '10em' }}>*/}
      {/*  <Switch*/}
      {/*    key={`blabla`}*/}
      {/*    className={`secondary-switch-${isReadOnlyView ? 'checked' : 'unchecked'}`}*/}
      {/*    isChecked={isReadOnlyView}*/}
      {/*    isDisabled={false}*/}
      {/*    onClick={_ => {*/}
      {/*      updateIsReadOnlyView.set(!isReadOnlyView)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <VisibleIf predicate={isReadOnlyView}>*/}
      {/*    <div className="font-12 font-semibold text-gray-500">Read-Only view</div>*/}
      {/*  </VisibleIf>*/}
      {/*  <VisibleIf predicate={!isReadOnlyView}>*/}
      {/*    <div className="font-12 font-semibold text-pink-500">Edit view</div>*/}
      {/*  </VisibleIf>*/}
      {/*</div>*/}
      <PDFDownloadButton/>
    </HeaderSection>
    <VisibleIf predicate={isLocked && status === ContractStatus.DRAFT}>
      <ContractEditedByWarning/>
    </VisibleIf>
    <ParkingContract/>
    <ShoppingMallContract/>

    <AddendumCancellation/>
    <AddendumChangeInRentalPriceDate/>
    <AddendumDownpayment/>
    <AddendumRentalAgreementStorageUnit/>
    <AddendumTransport/>
  </>)
}
