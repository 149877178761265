import Big from "big.js";

export const storageTotalArea: producer = ({
  storagePropertyExclusiveArea = observe.contract.values.storagePropertyExclusiveArea.displayedValue,
  storagePropertyCommonArea = observe.contract.values.storagePropertyCommonArea.displayedValue,
  oldValue = get.contract.values.storagePropertyTotalArea.displayedValue,

  storagePropertyTotalArea = update.contract.toSave.changes.storagePropertyTotalArea,
}) => {
  if (storagePropertyExclusiveArea === undefined || storagePropertyCommonArea === undefined) {
    return;
  }
  if (storagePropertyExclusiveArea === "" || storagePropertyCommonArea === "") {
    storagePropertyTotalArea.set({
      value: 0,
      custom: false,
    });
  } else {
    oldValue = oldValue.value();
    const value = new Big(storagePropertyCommonArea).plus(new Big(storagePropertyExclusiveArea)).toNumber();
    if (oldValue == value) {
      return;
    }

    storagePropertyTotalArea.set({
      value,
      custom: false,
    });
  }
};
