import { RadioButton, RadioButtonGroup, Spinner } from "@c11/components";
import { NumberField } from "src/components_generic/NumberField";
import { TextField } from "src/components_generic/TextField";
import i18n from "src/producers/languages/i18n";
import { v4 } from "uuid";

export const LeaseConsistsOf: view = ({
  attachments = observe.contract.originalv2.attachments,
  setChanges = update.contract.changes.fields[param.name]
}) => {
  const GetAttachmentsArray = () => {
    if (!attachments) return [];
    if (Object.keys(attachments).length === 0) return [];

    return Object.keys(attachments)
      .map(id => ({
        id,
        ...attachments[id]
      }))
      .filter(attachment => attachment.selected)
      .sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      })
  }

  return <div>
    <h2>5. {i18n.t('contract.general.leaseConsistsOf')}</h2>

    <div className="flex flex-col gap-4 sub-clause-card">
      <div className="flex-grow w-full flex flex-col items-left justify-center">
      {
        GetAttachmentsArray().map(a => <div>
          {a.name}
        </div>)
      }
      </div>
    </div>
  </div>
};
