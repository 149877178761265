import React from 'react'

export const InvalidMessage: view = ({
  varName,
  invalidField = observe.contract.invalidFields[arg.varName]
}) => {
  if (!invalidField) return null

  return (
    <div key="invalidFields" className="text-pink-600 text-xs">
      {invalidField}
    </div>
  )
}
