export const hasChanges: producer = ({
  userId = observe.user.uid,
  sessions = observe.contract.sessions,
  updateHasChanges = update.contract.hasChanges,
}) => {
  if (!sessions) return;
  try {
    let hasAnyChanges = false;
    for (const sessionId in sessions) {
      const session = sessions[sessionId];
      const hasFieldChanges = session.changes && Object.values(session.changes).length > 0;
      const hasAttachmentsChanges = session.attachments && Object.keys(session.attachments).length > 0;
      const hasPartyChanges = session.parties && Object.keys(session.parties).length > 0;
      hasAnyChanges = hasFieldChanges || hasAttachmentsChanges || hasPartyChanges;
      if (hasAnyChanges) break;
    }

    updateHasChanges.set(hasAnyChanges);
  } catch (e) {
    console.error("hasChanges", e);
  }
};
