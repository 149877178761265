import copy from "copy-to-clipboard";
import { Menu, Dropdown, notification } from "antd";

import "src/pages/Dashboard/ContractMenu/ContractMenu.css";
import i18n from "src/producers/languages/i18n";
import { ContractFilterOption, UserRole } from "src/types";
import { SVGs } from "src/assets";

import styles from "../../styles.module.css";

export const AddendumMenu: view = ({
  contractId,
  canBeDeleted,
  filterTag,
  userRole = observe.user.data.role,
  deteleAddendum = update.modal.deleteAdeendum,
  setFilterFlag = update.triggers.setFilterFlag,
  removeFilterTag = update.triggers.removeFilterTag,
}) => {
  const isManager = userRole === UserRole.MANAGER;
  const menu = (
    <Menu>
      {isManager && canBeDeleted && (
        <Menu.Item
          className="mx-2 my-1"
          key="6"
          onClick={(param) => {
            param.domEvent.stopPropagation();
            deteleAddendum.set(contractId);
          }}>
          {i18n.t("dashboard.menu_li_trash")}
        </Menu.Item>
      )}
      {isManager &&
        (filterTag === ContractFilterOption.LOST ? (
          <Menu.Item
            className="mx-2 my-1"
            key="3"
            onClick={(param) => {
              param.domEvent.stopPropagation();
              removeFilterTag.set({ contractId });
            }}>
            {i18n.t("dashboard.menu_li_unlost")}
          </Menu.Item>
        ) : (
          <Menu.Item
            className="mx-2 my-1"
            key="4"
            onClick={(param) => {
              param.domEvent.stopPropagation();
              setFilterFlag.set({ contractId, tag: ContractFilterOption.LOST });
            }}>
            {i18n.t("dashboard.menu_li_lost")}
          </Menu.Item>
        ))}
      <Menu.Item
        className="mx-2 my-1"
        key="8"
        onClick={(param) => {
          param.domEvent.stopPropagation();
          copy(`${window.location.host}/contract/${contractId}?autologin=true`);
          notification.success({
            message: i18n.t("dashboard.notification_contract_link_copy"),
          });
        }}>
        {i18n.t("dashboard.menu_li_copy")}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
      <div className={`${styles.contractMenu}`} onClick={(e: any) => e.stopPropagation()}>
        <SVGs.ThreeDots />
      </div>
    </Dropdown>
  );
};
