import React from "react";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { ContractStatus, UserRole } from "src/types";
import { TooltipWrapper } from "src/components";

export const ContractName: view = ({
  shoppingMall = observe.legacyContract.values.shoppingMall.displayedValue,
  tenantName = observe.legacyContract.values.tenantName.displayedValue,
  status = observe.legacyContract.original.status,
  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  const isSigning = status === ContractStatus.SIGNING;

  return (
    <>
      <p className="text-xl">{`${i18n.t("general.contract")} ${shoppingMall || ""} ${tenantName || ""}`}</p>

      {isTenant && isSigning && (
        <TooltipWrapper text={i18n.t("tenant_contract_locked_icon_tooltip")}>
          <SVGs.ContractLockedPage className="ml-2" />
        </TooltipWrapper>
      )}
    </>
  );
};
