'use strict'
// module.exports = validate20
// module.exports.default = validate20
export default validate20
const schema22 = {
  '$id': 'SHOPPING_MALL',
  'type': 'object',
  'definitions': {
    'email': { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' },
    'phone': { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
  },
  'properties': {
    'minimumTurnOver': { 'type': 'number' },
    'compulsoryDerogation': { 'type': 'string' },
    'firstTimeRegulation': { 'type': 'string' },
    'hasStorageUnit': { 'type': 'boolean' },
    'indexAdjustmentBasicIndex': { 'type': 'string' },
    'introText': { 'type': 'string' },
    'landlordAccountNo': { 'type': 'number' },
    'landlordAddress': { 'type': 'string' },
    'landlordContactPersonFirstName': { 'type': 'string' },
    'landlordContactPersonLastName': { 'type': 'string' },
    'landlordEmail': { '$ref': '#/definitions/email' },
    'landlordInvoiceAddress': { 'type': 'string' },
    'landlordName': { 'type': 'string' },
    'landlordOrgNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'landlordPhone': { '$ref': '#/definitions/phone' },
    'landlordPostalAddress': { 'type': 'string' },
    'landlordPropertyManager': { 'type': 'string' },
    'marketingCosts': { 'type': 'number' },
    'minimumMarketingCost': { 'type': 'number' },
    'minimumRent': { 'type': 'number' },
    'municipality': { 'type': 'string' },
    'parcelNumber': { 'type': 'string' },
    'paymentProvisionType': { 'type': 'string' },
    'propertyAddress': { 'type': 'string' },
    'propertyCommonArea': { 'type': 'number' },
    'propertyDescription': { 'type': 'string' },
    'propertyExclusiveArea': { 'type': 'number' },
    'propertyLevel': { 'type': 'string' },
    'propertyLocalNo': { 'type': 'string' },
    'propertyTotalArea': { 'type': 'number' },
    'rentalEnd': { 'type': 'string' },
    'rentalPeriodEorR': { 'type': 'string', 'pattern': '^(E|R)$' },
    'rentalPeriodHasOptions': { 'type': 'boolean' },
    'rentalPeriodOptionsPeriod': { 'type': 'string' },
    'rentalStart': { 'type': 'string' },
    'securityIsProvided': { 'type': 'boolean' },
    'securityType': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'securityAmount': { 'type': 'number' },
    'securityDeadline': { 'type': 'string' },
    'sharedCosts': { 'type': 'number' },
    'sharedCostsStorage': { 'type': 'number' },
    'shoppingMall': { 'type': 'string' },
    'specialProvisions': { 'type': 'string' },
    'storagePropertyCommonArea': { 'type': 'number' },
    'storagePropertyExclusiveArea': { 'type': 'number' },
    'storagePropertyLevel': { 'type': 'string' },
    'storagePropertyLocalNo': { 'type': 'string' },
    'storagePropertyTotalArea': { 'type': 'number' },
    'storageRent': { 'type': 'number' },
    'storeChain': { 'type': 'string' },
    'storeConcept': { 'type': 'string' },
    'storeGroup': { 'type': 'string' },
    'storeIndustryCode': { 'type': 'string' },
    'storeName': { 'type': 'string' },
    'storeOpening': { 'type': 'string' },
    'tenantAddress': { 'type': 'string' },
    'tenantContactPersonFirstName': { 'type': 'string' },
    'tenantContactPersonLastName': { 'type': 'string' },
    'tenantEmail': { '$ref': '#/definitions/email' },
    'tenantInvoiceAddress': { 'type': 'string' },
    'tenantName': { 'type': 'string' },
    'tenantOrgNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'tenantPhone': { '$ref': '#/definitions/phone' },
    'tenantPostalAddress': { 'type': 'string' },
    'turnoverPercent': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'businessVATStatus': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] }
  },
  'required': ['compulsoryDerogation', 'firstTimeRegulation', 'indexAdjustmentBasicIndex', 'introText', 'landlordAccountNo', 'landlordAddress', 'landlordContactPersonFirstName', 'landlordContactPersonLastName', 'landlordEmail', 'landlordInvoiceAddress', 'landlordName', 'landlordOrgNo', 'landlordPostalAddress', 'landlordPropertyManager', 'marketingCosts', 'minimumMarketingCost', 'minimumRent', 'municipality', 'parcelNumber', 'paymentProvisionType', 'propertyAddress', 'propertyCommonArea', 'propertyDescription', 'propertyExclusiveArea', 'propertyLevel', 'propertyLocalNo', 'propertyTotalArea', 'rentalEnd', 'rentalStart', 'securityIsProvided', 'shoppingMall', 'storeConcept', 'storeIndustryCode', 'storeName', 'storeOpening', 'turnoverPercent', 'businessVATStatus']
}
const schema23 = { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' }
const schema24 = { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
const pattern0 = new RegExp('^\\S+@\\S+\\.\\S+$', 'u')
const pattern1 = new RegExp('^[+]?[\\d\\s()-]{7,}$', 'u')
const pattern2 = new RegExp('^(E|R)$', 'u')

function validate20 (data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {/*# sourceURL="SHOPPING_MALL" */
  let vErrors = null
  let errors = 0
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.compulsoryDerogation === undefined) {
      const err0 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'compulsoryDerogation' },
        message: 'must have required property \'' + 'compulsoryDerogation' + '\''
      }
      if (vErrors === null) {vErrors = [err0]} else {vErrors.push(err0)}
      errors++
    }
    if (data.firstTimeRegulation === undefined) {
      const err1 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'firstTimeRegulation' },
        message: 'must have required property \'' + 'firstTimeRegulation' + '\''
      }
      if (vErrors === null) {vErrors = [err1]} else {vErrors.push(err1)}
      errors++
    }
    if (data.indexAdjustmentBasicIndex === undefined) {
      const err2 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'indexAdjustmentBasicIndex' },
        message: 'must have required property \'' + 'indexAdjustmentBasicIndex' + '\''
      }
      if (vErrors === null) {vErrors = [err2]} else {vErrors.push(err2)}
      errors++
    }
    if (data.introText === undefined) {
      const err3 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'introText' },
        message: 'must have required property \'' + 'introText' + '\''
      }
      if (vErrors === null) {vErrors = [err3]} else {vErrors.push(err3)}
      errors++
    }
    if (data.landlordAccountNo === undefined) {
      const err4 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordAccountNo' },
        message: 'must have required property \'' + 'landlordAccountNo' + '\''
      }
      if (vErrors === null) {vErrors = [err4]} else {vErrors.push(err4)}
      errors++
    }
    if (data.landlordAddress === undefined) {
      const err5 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordAddress' },
        message: 'must have required property \'' + 'landlordAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err5]} else {vErrors.push(err5)}
      errors++
    }
    if (data.landlordContactPersonFirstName === undefined) {
      const err6 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordContactPersonFirstName' },
        message: 'must have required property \'' + 'landlordContactPersonFirstName' + '\''
      }
      if (vErrors === null) {vErrors = [err6]} else {vErrors.push(err6)}
      errors++
    }
    if (data.landlordContactPersonLastName === undefined) {
      const err7 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordContactPersonLastName' },
        message: 'must have required property \'' + 'landlordContactPersonLastName' + '\''
      }
      if (vErrors === null) {vErrors = [err7]} else {vErrors.push(err7)}
      errors++
    }
    if (data.landlordEmail === undefined) {
      const err8 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordEmail' },
        message: 'must have required property \'' + 'landlordEmail' + '\''
      }
      if (vErrors === null) {vErrors = [err8]} else {vErrors.push(err8)}
      errors++
    }
    if (data.landlordInvoiceAddress === undefined) {
      const err9 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordInvoiceAddress' },
        message: 'must have required property \'' + 'landlordInvoiceAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err9]} else {vErrors.push(err9)}
      errors++
    }
    if (data.landlordName === undefined) {
      const err10 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordName' },
        message: 'must have required property \'' + 'landlordName' + '\''
      }
      if (vErrors === null) {vErrors = [err10]} else {vErrors.push(err10)}
      errors++
    }
    if (data.landlordOrgNo === undefined) {
      const err11 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordOrgNo' },
        message: 'must have required property \'' + 'landlordOrgNo' + '\''
      }
      if (vErrors === null) {vErrors = [err11]} else {vErrors.push(err11)}
      errors++
    }
    if (data.landlordPostalAddress === undefined) {
      const err12 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordPostalAddress' },
        message: 'must have required property \'' + 'landlordPostalAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err12]} else {vErrors.push(err12)}
      errors++
    }
    if (data.landlordPropertyManager === undefined) {
      const err13 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordPropertyManager' },
        message: 'must have required property \'' + 'landlordPropertyManager' + '\''
      }
      if (vErrors === null) {vErrors = [err13]} else {vErrors.push(err13)}
      errors++
    }
    if (data.marketingCosts === undefined) {
      const err14 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'marketingCosts' },
        message: 'must have required property \'' + 'marketingCosts' + '\''
      }
      if (vErrors === null) {vErrors = [err14]} else {vErrors.push(err14)}
      errors++
    }
    if (data.minimumMarketingCost === undefined) {
      const err15 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'minimumMarketingCost' },
        message: 'must have required property \'' + 'minimumMarketingCost' + '\''
      }
      if (vErrors === null) {vErrors = [err15]} else {vErrors.push(err15)}
      errors++
    }
    if (data.minimumRent === undefined) {
      const err16 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'minimumRent' },
        message: 'must have required property \'' + 'minimumRent' + '\''
      }
      if (vErrors === null) {vErrors = [err16]} else {vErrors.push(err16)}
      errors++
    }
    if (data.municipality === undefined) {
      const err17 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'municipality' },
        message: 'must have required property \'' + 'municipality' + '\''
      }
      if (vErrors === null) {vErrors = [err17]} else {vErrors.push(err17)}
      errors++
    }
    if (data.parcelNumber === undefined) {
      const err18 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'parcelNumber' },
        message: 'must have required property \'' + 'parcelNumber' + '\''
      }
      if (vErrors === null) {vErrors = [err18]} else {vErrors.push(err18)}
      errors++
    }
    if (data.paymentProvisionType === undefined) {
      const err19 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'paymentProvisionType' },
        message: 'must have required property \'' + 'paymentProvisionType' + '\''
      }
      if (vErrors === null) {vErrors = [err19]} else {vErrors.push(err19)}
      errors++
    }
    if (data.propertyAddress === undefined) {
      const err20 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyAddress' },
        message: 'must have required property \'' + 'propertyAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err20]} else {vErrors.push(err20)}
      errors++
    }
    if (data.propertyCommonArea === undefined) {
      const err21 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyCommonArea' },
        message: 'must have required property \'' + 'propertyCommonArea' + '\''
      }
      if (vErrors === null) {vErrors = [err21]} else {vErrors.push(err21)}
      errors++
    }
    if (data.propertyDescription === undefined) {
      const err22 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyDescription' },
        message: 'must have required property \'' + 'propertyDescription' + '\''
      }
      if (vErrors === null) {vErrors = [err22]} else {vErrors.push(err22)}
      errors++
    }
    if (data.propertyExclusiveArea === undefined) {
      const err23 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyExclusiveArea' },
        message: 'must have required property \'' + 'propertyExclusiveArea' + '\''
      }
      if (vErrors === null) {vErrors = [err23]} else {vErrors.push(err23)}
      errors++
    }
    if (data.propertyLevel === undefined) {
      const err24 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyLevel' },
        message: 'must have required property \'' + 'propertyLevel' + '\''
      }
      if (vErrors === null) {vErrors = [err24]} else {vErrors.push(err24)}
      errors++
    }
    if (data.propertyLocalNo === undefined) {
      const err25 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyLocalNo' },
        message: 'must have required property \'' + 'propertyLocalNo' + '\''
      }
      if (vErrors === null) {vErrors = [err25]} else {vErrors.push(err25)}
      errors++
    }
    if (data.propertyTotalArea === undefined) {
      const err26 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyTotalArea' },
        message: 'must have required property \'' + 'propertyTotalArea' + '\''
      }
      if (vErrors === null) {vErrors = [err26]} else {vErrors.push(err26)}
      errors++
    }
    if (data.rentalEnd === undefined) {
      const err27 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentalEnd' },
        message: 'must have required property \'' + 'rentalEnd' + '\''
      }
      if (vErrors === null) {vErrors = [err27]} else {vErrors.push(err27)}
      errors++
    }
    if (data.rentalStart === undefined) {
      const err28 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentalStart' },
        message: 'must have required property \'' + 'rentalStart' + '\''
      }
      if (vErrors === null) {vErrors = [err28]} else {vErrors.push(err28)}
      errors++
    }
    if (data.securityIsProvided === undefined) {
      const err29 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'securityIsProvided' },
        message: 'must have required property \'' + 'securityIsProvided' + '\''
      }
      if (vErrors === null) {vErrors = [err29]} else {vErrors.push(err29)}
      errors++
    }
    if (data.shoppingMall === undefined) {
      const err30 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'shoppingMall' },
        message: 'must have required property \'' + 'shoppingMall' + '\''
      }
      if (vErrors === null) {vErrors = [err30]} else {vErrors.push(err30)}
      errors++
    }
    if (data.storeConcept === undefined) {
      const err31 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'storeConcept' },
        message: 'must have required property \'' + 'storeConcept' + '\''
      }
      if (vErrors === null) {vErrors = [err31]} else {vErrors.push(err31)}
      errors++
    }
    if (data.storeIndustryCode === undefined) {
      const err32 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'storeIndustryCode' },
        message: 'must have required property \'' + 'storeIndustryCode' + '\''
      }
      if (vErrors === null) {vErrors = [err32]} else {vErrors.push(err32)}
      errors++
    }
    if (data.storeName === undefined) {
      const err33 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'storeName' },
        message: 'must have required property \'' + 'storeName' + '\''
      }
      if (vErrors === null) {vErrors = [err33]} else {vErrors.push(err33)}
      errors++
    }
    if (data.storeOpening === undefined) {
      const err34 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'storeOpening' },
        message: 'must have required property \'' + 'storeOpening' + '\''
      }
      if (vErrors === null) {vErrors = [err34]} else {vErrors.push(err34)}
      errors++
    }
    if (data.turnoverPercent === undefined) {
      const err35 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'turnoverPercent' },
        message: 'must have required property \'' + 'turnoverPercent' + '\''
      }
      if (vErrors === null) {vErrors = [err35]} else {vErrors.push(err35)}
      errors++
    }
    if (data.businessVATStatus === undefined) {
      const err36 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'businessVATStatus' },
        message: 'must have required property \'' + 'businessVATStatus' + '\''
      }
      if (vErrors === null) {vErrors = [err36]} else {vErrors.push(err36)}
      errors++
    }
    if (data.minimumTurnOver !== undefined) {
      let data0 = data.minimumTurnOver
      if (!((typeof data0 == 'number') && (isFinite(data0)))) {
        const err37 = {
          instancePath: instancePath + '/minimumTurnOver',
          schemaPath: '#/properties/minimumTurnOver/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err37]} else {vErrors.push(err37)}
        errors++
      }
    }
    if (data.compulsoryDerogation !== undefined) {
      if (typeof data.compulsoryDerogation !== 'string') {
        const err38 = {
          instancePath: instancePath + '/compulsoryDerogation',
          schemaPath: '#/properties/compulsoryDerogation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err38]} else {vErrors.push(err38)}
        errors++
      }
    }
    if (data.firstTimeRegulation !== undefined) {
      if (typeof data.firstTimeRegulation !== 'string') {
        const err39 = {
          instancePath: instancePath + '/firstTimeRegulation',
          schemaPath: '#/properties/firstTimeRegulation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err39]} else {vErrors.push(err39)}
        errors++
      }
    }
    if (data.hasStorageUnit !== undefined) {
      if (typeof data.hasStorageUnit !== 'boolean') {
        const err40 = {
          instancePath: instancePath + '/hasStorageUnit',
          schemaPath: '#/properties/hasStorageUnit/type',
          keyword: 'type',
          params: { type: 'boolean' },
          message: 'must be boolean'
        }
        if (vErrors === null) {vErrors = [err40]} else {vErrors.push(err40)}
        errors++
      }
    }
    if (data.indexAdjustmentBasicIndex !== undefined) {
      if (typeof data.indexAdjustmentBasicIndex !== 'string') {
        const err41 = {
          instancePath: instancePath + '/indexAdjustmentBasicIndex',
          schemaPath: '#/properties/indexAdjustmentBasicIndex/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err41]} else {vErrors.push(err41)}
        errors++
      }
    }
    if (data.introText !== undefined) {
      if (typeof data.introText !== 'string') {
        const err42 = {
          instancePath: instancePath + '/introText',
          schemaPath: '#/properties/introText/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err42]} else {vErrors.push(err42)}
        errors++
      }
    }
    if (data.landlordAccountNo !== undefined) {
      let data6 = data.landlordAccountNo
      if (!((typeof data6 == 'number') && (isFinite(data6)))) {
        const err43 = {
          instancePath: instancePath + '/landlordAccountNo',
          schemaPath: '#/properties/landlordAccountNo/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err43]} else {vErrors.push(err43)}
        errors++
      }
    }
    if (data.landlordAddress !== undefined) {
      if (typeof data.landlordAddress !== 'string') {
        const err44 = {
          instancePath: instancePath + '/landlordAddress',
          schemaPath: '#/properties/landlordAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err44]} else {vErrors.push(err44)}
        errors++
      }
    }
    if (data.landlordContactPersonFirstName !== undefined) {
      if (typeof data.landlordContactPersonFirstName !== 'string') {
        const err45 = {
          instancePath: instancePath + '/landlordContactPersonFirstName',
          schemaPath: '#/properties/landlordContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err45]} else {vErrors.push(err45)}
        errors++
      }
    }
    if (data.landlordContactPersonLastName !== undefined) {
      if (typeof data.landlordContactPersonLastName !== 'string') {
        const err46 = {
          instancePath: instancePath + '/landlordContactPersonLastName',
          schemaPath: '#/properties/landlordContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err46]} else {vErrors.push(err46)}
        errors++
      }
    }
    if (data.landlordEmail !== undefined) {
      let data10 = data.landlordEmail
      if (typeof data10 === 'string') {
        if (!pattern0.test(data10)) {
          const err47 = {
            instancePath: instancePath + '/landlordEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {vErrors = [err47]} else {vErrors.push(err47)}
          errors++
        }
      } else {
        const err48 = {
          instancePath: instancePath + '/landlordEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err48]} else {vErrors.push(err48)}
        errors++
      }
    }
    if (data.landlordInvoiceAddress !== undefined) {
      if (typeof data.landlordInvoiceAddress !== 'string') {
        const err49 = {
          instancePath: instancePath + '/landlordInvoiceAddress',
          schemaPath: '#/properties/landlordInvoiceAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err49]} else {vErrors.push(err49)}
        errors++
      }
    }
    if (data.landlordName !== undefined) {
      if (typeof data.landlordName !== 'string') {
        const err50 = {
          instancePath: instancePath + '/landlordName',
          schemaPath: '#/properties/landlordName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err50]} else {vErrors.push(err50)}
        errors++
      }
    }
    if (data.landlordOrgNo !== undefined) {
      let data13 = data.landlordOrgNo
      const _errs29 = errors
      let valid2 = false
      const _errs30 = errors
      if (!((typeof data13 == 'number') && (isFinite(data13)))) {
        const err51 = {
          instancePath: instancePath + '/landlordOrgNo',
          schemaPath: '#/properties/landlordOrgNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err51]} else {vErrors.push(err51)}
        errors++
      }
      var _valid0 = _errs30 === errors
      valid2 = valid2 || _valid0
      if (!valid2) {
        const _errs32 = errors
        if (typeof data13 !== 'string') {
          const err52 = {
            instancePath: instancePath + '/landlordOrgNo',
            schemaPath: '#/properties/landlordOrgNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {vErrors = [err52]} else {vErrors.push(err52)}
          errors++
        }
        var _valid0 = _errs32 === errors
        valid2 = valid2 || _valid0
      }
      if (!valid2) {
        const err53 = {
          instancePath: instancePath + '/landlordOrgNo',
          schemaPath: '#/properties/landlordOrgNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {vErrors = [err53]} else {vErrors.push(err53)}
        errors++
      } else {
        errors = _errs29
        if (vErrors !== null) {if (_errs29) {vErrors.length = _errs29} else {vErrors = null}}
      }
    }
    if (data.landlordPhone !== undefined) {
      let data14 = data.landlordPhone
      if (typeof data14 === 'string') {
        if (!pattern1.test(data14)) {
          const err54 = {
            instancePath: instancePath + '/landlordPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {vErrors = [err54]} else {vErrors.push(err54)}
          errors++
        }
      } else {
        const err55 = {
          instancePath: instancePath + '/landlordPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err55]} else {vErrors.push(err55)}
        errors++
      }
    }
    if (data.landlordPostalAddress !== undefined) {
      if (typeof data.landlordPostalAddress !== 'string') {
        const err56 = {
          instancePath: instancePath + '/landlordPostalAddress',
          schemaPath: '#/properties/landlordPostalAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err56]} else {vErrors.push(err56)}
        errors++
      }
    }
    if (data.landlordPropertyManager !== undefined) {
      if (typeof data.landlordPropertyManager !== 'string') {
        const err57 = {
          instancePath: instancePath + '/landlordPropertyManager',
          schemaPath: '#/properties/landlordPropertyManager/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err57]} else {vErrors.push(err57)}
        errors++
      }
    }
    if (data.marketingCosts !== undefined) {
      let data17 = data.marketingCosts
      if (!((typeof data17 == 'number') && (isFinite(data17)))) {
        const err58 = {
          instancePath: instancePath + '/marketingCosts',
          schemaPath: '#/properties/marketingCosts/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err58]} else {vErrors.push(err58)}
        errors++
      }
    }
    if (data.minimumMarketingCost !== undefined) {
      let data18 = data.minimumMarketingCost
      if (!((typeof data18 == 'number') && (isFinite(data18)))) {
        const err59 = {
          instancePath: instancePath + '/minimumMarketingCost',
          schemaPath: '#/properties/minimumMarketingCost/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err59]} else {vErrors.push(err59)}
        errors++
      }
    }
    if (data.minimumRent !== undefined) {
      let data19 = data.minimumRent
      if (!((typeof data19 == 'number') && (isFinite(data19)))) {
        const err60 = {
          instancePath: instancePath + '/minimumRent',
          schemaPath: '#/properties/minimumRent/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err60]} else {vErrors.push(err60)}
        errors++
      }
    }
    if (data.municipality !== undefined) {
      if (typeof data.municipality !== 'string') {
        const err61 = {
          instancePath: instancePath + '/municipality',
          schemaPath: '#/properties/municipality/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err61]} else {vErrors.push(err61)}
        errors++
      }
    }
    if (data.parcelNumber !== undefined) {
      if (typeof data.parcelNumber !== 'string') {
        const err62 = {
          instancePath: instancePath + '/parcelNumber',
          schemaPath: '#/properties/parcelNumber/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err62]} else {vErrors.push(err62)}
        errors++
      }
    }
    if (data.paymentProvisionType !== undefined) {
      if (typeof data.paymentProvisionType !== 'string') {
        const err63 = {
          instancePath: instancePath + '/paymentProvisionType',
          schemaPath: '#/properties/paymentProvisionType/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err63]} else {vErrors.push(err63)}
        errors++
      }
    }
    if (data.propertyAddress !== undefined) {
      if (typeof data.propertyAddress !== 'string') {
        const err64 = {
          instancePath: instancePath + '/propertyAddress',
          schemaPath: '#/properties/propertyAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err64]} else {vErrors.push(err64)}
        errors++
      }
    }
    if (data.propertyCommonArea !== undefined) {
      let data24 = data.propertyCommonArea
      if (!((typeof data24 == 'number') && (isFinite(data24)))) {
        const err65 = {
          instancePath: instancePath + '/propertyCommonArea',
          schemaPath: '#/properties/propertyCommonArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err65]} else {vErrors.push(err65)}
        errors++
      }
    }
    if (data.propertyDescription !== undefined) {
      if (typeof data.propertyDescription !== 'string') {
        const err66 = {
          instancePath: instancePath + '/propertyDescription',
          schemaPath: '#/properties/propertyDescription/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err66]} else {vErrors.push(err66)}
        errors++
      }
    }
    if (data.propertyExclusiveArea !== undefined) {
      let data26 = data.propertyExclusiveArea
      if (!((typeof data26 == 'number') && (isFinite(data26)))) {
        const err67 = {
          instancePath: instancePath + '/propertyExclusiveArea',
          schemaPath: '#/properties/propertyExclusiveArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err67]} else {vErrors.push(err67)}
        errors++
      }
    }
    if (data.propertyLevel !== undefined) {
      if (typeof data.propertyLevel !== 'string') {
        const err68 = {
          instancePath: instancePath + '/propertyLevel',
          schemaPath: '#/properties/propertyLevel/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err68]} else {vErrors.push(err68)}
        errors++
      }
    }
    if (data.propertyLocalNo !== undefined) {
      if (typeof data.propertyLocalNo !== 'string') {
        const err69 = {
          instancePath: instancePath + '/propertyLocalNo',
          schemaPath: '#/properties/propertyLocalNo/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err69]} else {vErrors.push(err69)}
        errors++
      }
    }
    if (data.propertyTotalArea !== undefined) {
      let data29 = data.propertyTotalArea
      if (!((typeof data29 == 'number') && (isFinite(data29)))) {
        const err70 = {
          instancePath: instancePath + '/propertyTotalArea',
          schemaPath: '#/properties/propertyTotalArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err70]} else {vErrors.push(err70)}
        errors++
      }
    }
    if (data.rentalEnd !== undefined) {
      if (typeof data.rentalEnd !== 'string') {
        const err71 = {
          instancePath: instancePath + '/rentalEnd',
          schemaPath: '#/properties/rentalEnd/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err71]} else {vErrors.push(err71)}
        errors++
      }
    }
    if (data.rentalPeriodEorR !== undefined) {
      let data31 = data.rentalPeriodEorR
      if (typeof data31 === 'string') {
        if (!pattern2.test(data31)) {
          const err72 = {
            instancePath: instancePath + '/rentalPeriodEorR',
            schemaPath: '#/properties/rentalPeriodEorR/pattern',
            keyword: 'pattern',
            params: { pattern: '^(E|R)$' },
            message: 'must match pattern "' + '^(E|R)$' + '"'
          }
          if (vErrors === null) {vErrors = [err72]} else {vErrors.push(err72)}
          errors++
        }
      } else {
        const err73 = {
          instancePath: instancePath + '/rentalPeriodEorR',
          schemaPath: '#/properties/rentalPeriodEorR/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err73]} else {vErrors.push(err73)}
        errors++
      }
    }
    if (data.rentalPeriodHasOptions !== undefined) {
      if (typeof data.rentalPeriodHasOptions !== 'boolean') {
        const err74 = {
          instancePath: instancePath + '/rentalPeriodHasOptions',
          schemaPath: '#/properties/rentalPeriodHasOptions/type',
          keyword: 'type',
          params: { type: 'boolean' },
          message: 'must be boolean'
        }
        if (vErrors === null) {vErrors = [err74]} else {vErrors.push(err74)}
        errors++
      }
    }
    if (data.rentalPeriodOptionsPeriod !== undefined) {
      if (typeof data.rentalPeriodOptionsPeriod !== 'string') {
        const err75 = {
          instancePath: instancePath + '/rentalPeriodOptionsPeriod',
          schemaPath: '#/properties/rentalPeriodOptionsPeriod/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err75]} else {vErrors.push(err75)}
        errors++
      }
    }
    if (data.rentalStart !== undefined) {
      if (typeof data.rentalStart !== 'string') {
        const err76 = {
          instancePath: instancePath + '/rentalStart',
          schemaPath: '#/properties/rentalStart/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err76]} else {vErrors.push(err76)}
        errors++
      }
    }
    if (data.securityIsProvided !== undefined) {
      if (typeof data.securityIsProvided !== 'boolean') {
        const err77 = {
          instancePath: instancePath + '/securityIsProvided',
          schemaPath: '#/properties/securityIsProvided/type',
          keyword: 'type',
          params: { type: 'boolean' },
          message: 'must be boolean'
        }
        if (vErrors === null) {vErrors = [err77]} else {vErrors.push(err77)}
        errors++
      }
    }
    if (data.securityType !== undefined) {
      let data36 = data.securityType
      const _errs80 = errors
      let valid4 = false
      const _errs81 = errors
      if (!((typeof data36 == 'number') && (isFinite(data36)))) {
        const err78 = {
          instancePath: instancePath + '/securityType',
          schemaPath: '#/properties/securityType/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err78]} else {vErrors.push(err78)}
        errors++
      }
      var _valid1 = _errs81 === errors
      valid4 = valid4 || _valid1
      if (!valid4) {
        const _errs83 = errors
        if (typeof data36 !== 'string') {
          const err79 = {
            instancePath: instancePath + '/securityType',
            schemaPath: '#/properties/securityType/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {vErrors = [err79]} else {vErrors.push(err79)}
          errors++
        }
        var _valid1 = _errs83 === errors
        valid4 = valid4 || _valid1
      }
      if (!valid4) {
        const err80 = {
          instancePath: instancePath + '/securityType',
          schemaPath: '#/properties/securityType/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {vErrors = [err80]} else {vErrors.push(err80)}
        errors++
      } else {
        errors = _errs80
        if (vErrors !== null) {if (_errs80) {vErrors.length = _errs80} else {vErrors = null}}
      }
    }
    if (data.securityAmount !== undefined) {
      let data37 = data.securityAmount
      if (!((typeof data37 == 'number') && (isFinite(data37)))) {
        const err81 = {
          instancePath: instancePath + '/securityAmount',
          schemaPath: '#/properties/securityAmount/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err81]} else {vErrors.push(err81)}
        errors++
      }
    }
    if (data.securityDeadline !== undefined) {
      if (typeof data.securityDeadline !== 'string') {
        const err82 = {
          instancePath: instancePath + '/securityDeadline',
          schemaPath: '#/properties/securityDeadline/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err82]} else {vErrors.push(err82)}
        errors++
      }
    }
    if (data.sharedCosts !== undefined) {
      let data39 = data.sharedCosts
      if (!((typeof data39 == 'number') && (isFinite(data39)))) {
        const err83 = {
          instancePath: instancePath + '/sharedCosts',
          schemaPath: '#/properties/sharedCosts/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err83]} else {vErrors.push(err83)}
        errors++
      }
    }
    if (data.sharedCostsStorage !== undefined) {
      let data40 = data.sharedCostsStorage
      if (!((typeof data40 == 'number') && (isFinite(data40)))) {
        const err84 = {
          instancePath: instancePath + '/sharedCostsStorage',
          schemaPath: '#/properties/sharedCostsStorage/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err84]} else {vErrors.push(err84)}
        errors++
      }
    }
    if (data.shoppingMall !== undefined) {
      if (typeof data.shoppingMall !== 'string') {
        const err85 = {
          instancePath: instancePath + '/shoppingMall',
          schemaPath: '#/properties/shoppingMall/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err85]} else {vErrors.push(err85)}
        errors++
      }
    }
    if (data.specialProvisions !== undefined) {
      if (typeof data.specialProvisions !== 'string') {
        const err86 = {
          instancePath: instancePath + '/specialProvisions',
          schemaPath: '#/properties/specialProvisions/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err86]} else {vErrors.push(err86)}
        errors++
      }
    }
    if (data.storagePropertyCommonArea !== undefined) {
      let data43 = data.storagePropertyCommonArea
      if (!((typeof data43 == 'number') && (isFinite(data43)))) {
        const err87 = {
          instancePath: instancePath + '/storagePropertyCommonArea',
          schemaPath: '#/properties/storagePropertyCommonArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err87]} else {vErrors.push(err87)}
        errors++
      }
    }
    if (data.storagePropertyExclusiveArea !== undefined) {
      let data44 = data.storagePropertyExclusiveArea
      if (!((typeof data44 == 'number') && (isFinite(data44)))) {
        const err88 = {
          instancePath: instancePath + '/storagePropertyExclusiveArea',
          schemaPath: '#/properties/storagePropertyExclusiveArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err88]} else {vErrors.push(err88)}
        errors++
      }
    }
    if (data.storagePropertyLevel !== undefined) {
      if (typeof data.storagePropertyLevel !== 'string') {
        const err89 = {
          instancePath: instancePath + '/storagePropertyLevel',
          schemaPath: '#/properties/storagePropertyLevel/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err89]} else {vErrors.push(err89)}
        errors++
      }
    }
    if (data.storagePropertyLocalNo !== undefined) {
      if (typeof data.storagePropertyLocalNo !== 'string') {
        const err90 = {
          instancePath: instancePath + '/storagePropertyLocalNo',
          schemaPath: '#/properties/storagePropertyLocalNo/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err90]} else {vErrors.push(err90)}
        errors++
      }
    }
    if (data.storagePropertyTotalArea !== undefined) {
      let data47 = data.storagePropertyTotalArea
      if (!((typeof data47 == 'number') && (isFinite(data47)))) {
        const err91 = {
          instancePath: instancePath + '/storagePropertyTotalArea',
          schemaPath: '#/properties/storagePropertyTotalArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err91]} else {vErrors.push(err91)}
        errors++
      }
    }
    if (data.storageRent !== undefined) {
      let data48 = data.storageRent
      if (!((typeof data48 == 'number') && (isFinite(data48)))) {
        const err92 = {
          instancePath: instancePath + '/storageRent',
          schemaPath: '#/properties/storageRent/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err92]} else {vErrors.push(err92)}
        errors++
      }
    }
    if (data.storeChain !== undefined) {
      if (typeof data.storeChain !== 'string') {
        const err93 = {
          instancePath: instancePath + '/storeChain',
          schemaPath: '#/properties/storeChain/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err93]} else {vErrors.push(err93)}
        errors++
      }
    }
    if (data.storeConcept !== undefined) {
      if (typeof data.storeConcept !== 'string') {
        const err94 = {
          instancePath: instancePath + '/storeConcept',
          schemaPath: '#/properties/storeConcept/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err94]} else {vErrors.push(err94)}
        errors++
      }
    }
    if (data.storeGroup !== undefined) {
      if (typeof data.storeGroup !== 'string') {
        const err95 = {
          instancePath: instancePath + '/storeGroup',
          schemaPath: '#/properties/storeGroup/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err95]} else {vErrors.push(err95)}
        errors++
      }
    }
    if (data.storeIndustryCode !== undefined) {
      if (typeof data.storeIndustryCode !== 'string') {
        const err96 = {
          instancePath: instancePath + '/storeIndustryCode',
          schemaPath: '#/properties/storeIndustryCode/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err96]} else {vErrors.push(err96)}
        errors++
      }
    }
    if (data.storeName !== undefined) {
      if (typeof data.storeName !== 'string') {
        const err97 = {
          instancePath: instancePath + '/storeName',
          schemaPath: '#/properties/storeName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err97]} else {vErrors.push(err97)}
        errors++
      }
    }
    if (data.storeOpening !== undefined) {
      if (typeof data.storeOpening !== 'string') {
        const err98 = {
          instancePath: instancePath + '/storeOpening',
          schemaPath: '#/properties/storeOpening/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err98]} else {vErrors.push(err98)}
        errors++
      }
    }
    if (data.tenantAddress !== undefined) {
      if (typeof data.tenantAddress !== 'string') {
        const err99 = {
          instancePath: instancePath + '/tenantAddress',
          schemaPath: '#/properties/tenantAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err99]} else {vErrors.push(err99)}
        errors++
      }
    }
    if (data.tenantContactPersonFirstName !== undefined) {
      if (typeof data.tenantContactPersonFirstName !== 'string') {
        const err100 = {
          instancePath: instancePath + '/tenantContactPersonFirstName',
          schemaPath: '#/properties/tenantContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err100]} else {vErrors.push(err100)}
        errors++
      }
    }
    if (data.tenantContactPersonLastName !== undefined) {
      if (typeof data.tenantContactPersonLastName !== 'string') {
        const err101 = {
          instancePath: instancePath + '/tenantContactPersonLastName',
          schemaPath: '#/properties/tenantContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err101]} else {vErrors.push(err101)}
        errors++
      }
    }
    if (data.tenantEmail !== undefined) {
      let data58 = data.tenantEmail
      if (typeof data58 === 'string') {
        if (!pattern0.test(data58)) {
          const err102 = {
            instancePath: instancePath + '/tenantEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {vErrors = [err102]} else {vErrors.push(err102)}
          errors++
        }
      } else {
        const err103 = {
          instancePath: instancePath + '/tenantEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err103]} else {vErrors.push(err103)}
        errors++
      }
    }
    if (data.tenantInvoiceAddress !== undefined) {
      if (typeof data.tenantInvoiceAddress !== 'string') {
        const err104 = {
          instancePath: instancePath + '/tenantInvoiceAddress',
          schemaPath: '#/properties/tenantInvoiceAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err104]} else {vErrors.push(err104)}
        errors++
      }
    }
    if (data.tenantName !== undefined) {
      if (typeof data.tenantName !== 'string') {
        const err105 = {
          instancePath: instancePath + '/tenantName',
          schemaPath: '#/properties/tenantName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err105]} else {vErrors.push(err105)}
        errors++
      }
    }
    if (data.tenantOrgNo !== undefined) {
      let data61 = data.tenantOrgNo
      const _errs135 = errors
      let valid6 = false
      const _errs136 = errors
      if (!((typeof data61 == 'number') && (isFinite(data61)))) {
        const err106 = {
          instancePath: instancePath + '/tenantOrgNo',
          schemaPath: '#/properties/tenantOrgNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err106]} else {vErrors.push(err106)}
        errors++
      }
      var _valid2 = _errs136 === errors
      valid6 = valid6 || _valid2
      if (!valid6) {
        const _errs138 = errors
        if (typeof data61 !== 'string') {
          const err107 = {
            instancePath: instancePath + '/tenantOrgNo',
            schemaPath: '#/properties/tenantOrgNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {vErrors = [err107]} else {vErrors.push(err107)}
          errors++
        }
        var _valid2 = _errs138 === errors
        valid6 = valid6 || _valid2
      }
      if (!valid6) {
        const err108 = {
          instancePath: instancePath + '/tenantOrgNo',
          schemaPath: '#/properties/tenantOrgNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {vErrors = [err108]} else {vErrors.push(err108)}
        errors++
      } else {
        errors = _errs135
        if (vErrors !== null) {if (_errs135) {vErrors.length = _errs135} else {vErrors = null}}
      }
    }
    if (data.tenantPhone !== undefined) {
      let data62 = data.tenantPhone
      if (typeof data62 === 'string') {
        if (!pattern1.test(data62)) {
          const err109 = {
            instancePath: instancePath + '/tenantPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {vErrors = [err109]} else {vErrors.push(err109)}
          errors++
        }
      } else {
        const err110 = {
          instancePath: instancePath + '/tenantPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err110]} else {vErrors.push(err110)}
        errors++
      }
    }
    if (data.tenantPostalAddress !== undefined) {
      if (typeof data.tenantPostalAddress !== 'string') {
        const err111 = {
          instancePath: instancePath + '/tenantPostalAddress',
          schemaPath: '#/properties/tenantPostalAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err111]} else {vErrors.push(err111)}
        errors++
      }
    }
    if (data.turnoverPercent !== undefined) {
      let data64 = data.turnoverPercent
      const _errs146 = errors
      let valid8 = false
      const _errs147 = errors
      if (!((typeof data64 == 'number') && (isFinite(data64)))) {
        const err112 = {
          instancePath: instancePath + '/turnoverPercent',
          schemaPath: '#/properties/turnoverPercent/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err112]} else {vErrors.push(err112)}
        errors++
      }
      var _valid3 = _errs147 === errors
      valid8 = valid8 || _valid3
      if (!valid8) {
        const _errs149 = errors
        if (typeof data64 !== 'string') {
          const err113 = {
            instancePath: instancePath + '/turnoverPercent',
            schemaPath: '#/properties/turnoverPercent/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {vErrors = [err113]} else {vErrors.push(err113)}
          errors++
        }
        var _valid3 = _errs149 === errors
        valid8 = valid8 || _valid3
      }
      if (!valid8) {
        const err114 = {
          instancePath: instancePath + '/turnoverPercent',
          schemaPath: '#/properties/turnoverPercent/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {vErrors = [err114]} else {vErrors.push(err114)}
        errors++
      } else {
        errors = _errs146
        if (vErrors !== null) {if (_errs146) {vErrors.length = _errs146} else {vErrors = null}}
      }
    }
    if (data.businessVATStatus !== undefined) {
      let data65 = data.businessVATStatus
      const _errs152 = errors
      let valid9 = false
      const _errs153 = errors
      if (!((typeof data65 == 'number') && (isFinite(data65)))) {
        const err115 = {
          instancePath: instancePath + '/businessVATStatus',
          schemaPath: '#/properties/businessVATStatus/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err115]} else {vErrors.push(err115)}
        errors++
      }
      var _valid4 = _errs153 === errors
      valid9 = valid9 || _valid4
      if (!valid9) {
        const _errs155 = errors
        if (typeof data65 !== 'string') {
          const err116 = {
            instancePath: instancePath + '/businessVATStatus',
            schemaPath: '#/properties/businessVATStatus/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {vErrors = [err116]} else {vErrors.push(err116)}
          errors++
        }
        var _valid4 = _errs155 === errors
        valid9 = valid9 || _valid4
      }
      if (!valid9) {
        const err117 = {
          instancePath: instancePath + '/businessVATStatus',
          schemaPath: '#/properties/businessVATStatus/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {vErrors = [err117]} else {vErrors.push(err117)}
        errors++
      } else {
        errors = _errs152
        if (vErrors !== null) {if (_errs152) {vErrors.length = _errs152} else {vErrors = null}}
      }
    }
  } else {
    const err118 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object'
    }
    if (vErrors === null) {vErrors = [err118]} else {vErrors.push(err118)}
    errors++
  }
  validate20.errors = vErrors
  return errors === 0
}
