export enum FieldInputType {
  String = "String",
  Text = "text",
  Number = "number",
  Date = "date",
  FreeText = "freeText",
  Dropdown = "dropdown",
  CalculationCustom = "calculationCustom",
  Calculation = "calculation",
}

export enum ClauseUIElement {
  Paragraph = "Paragraph",
  Field = "Field",
  Subclause = "Subclause",
}

export enum ValidationType {
  Email = "email",
  OrgNo = "orgno",
  RentalEnd = "rentalend",
  LeaseDate = "leasedate",
}
export interface Field {
  varName?: string;
  inputType: FieldInputType;
  label?: string;
  placeholder?: string;
  extraClasses?: string;
  readOnlyForTenant?: boolean;
  hiddenFromTenantIfEmpty?: boolean;
  requiredForManager?: boolean;
  requiredForTenant?: boolean;
  dropdownChoices?: string[];
  validationType?: ValidationType;
  type: ClauseUIElement.Field;
}

export interface Section {
  name: string;
  clauses: {
    [k: string]: Clause;
  };
  clauseIds: string[];
}

export enum ClauseType {
  Custom = "custom",
}

export interface Clause {
  name: string;
  title: string;
  elements: {
    [k: string]: Field | SubClause | Paragraph;
  };
  sortedElementsIds: string[];
  type: ClauseType;
}
export interface SubClause {
  name: string;
  label: string;
  elements: {
    [k: string]: Field | Paragraph;
  };
  type: ClauseUIElement.Subclause;
}

export interface Paragraph {
  text: string;
  extraClasses?: string;
  type: ClauseUIElement.Paragraph;
}

export interface Template {
  createdBy: string;
  createdAt: number;
  isAvailable: boolean;
  name: string;
  sectionIds: string[];
  sections: {
    [k: string]: Section;
  };
}
