import { MenuProps } from "antd";
import { VisibleIf } from "src/components_generic/visible-if";

export enum AddendumListElementExtraOption {
  LOST = "LOST",
  COPY_LINK = "COPY_LINK"
}

type AddendumListElementExtraOptions = {
  isLostToggled?: boolean,
  onClick: (key: AddendumListElementExtraOption) => void
};

export function AddendumListElementExtraOptionsFactory(params: AddendumListElementExtraOptions): MenuProps['items'] {
  return [{
    key: AddendumListElementExtraOption.LOST,
    label: (<div className="flex-grow w-full" onClick={_ => params.onClick(AddendumListElementExtraOption.LOST)}>
      <VisibleIf predicate={!!params.isLostToggled}>Take out from lost</VisibleIf>
      <VisibleIf predicate={!params.isLostToggled}>Mark as lost</VisibleIf>
    </div>)
  }, {
    key: AddendumListElementExtraOption.COPY_LINK,
    label: (<div className="flex-grow w-full" onClick={_ => params.onClick(AddendumListElementExtraOption.COPY_LINK)}>Copy Link</div>)
  }];
}
