import {Dropdown, Menu, notification, Select} from 'antd'
import {TextField} from 'src/components_generic/TextField'
import {VisibleIf} from 'src/components_generic/visible-if'

import './RentalPremise.css'
import i18n from 'src/producers/languages/i18n'
import {TenantDropdownMenuFactory} from '../templates/shoppingMall/dropdowns/TenantDropdown'

let timer: any = null

export const SearchTenant: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status,
  syncIsReady = observe.tenants.syncIsReady,
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked

  return (
    <>
      <VisibleIf predicate={!displayReadOnlyView}>
        {/*{syncIsReady ? <TenantSearchComponent /> : <Spinner className={`${styles.contractSpinner}`} />}*/}
        <TenantSearchComponent/>
      </VisibleIf>
    </>
  )
}

const TenantSearchComponent: view = ({
  // tenantsSearchQuery = observe.contract.originalv2View.tenantsSearchQuery,
  setTenantsSearchQuery = update.contract.originalv2View.tenantsSearchQuery,
  searchResults = observe.contract.originalv2View.searchResults,
  updateSearchResults = update.contract.originalv2View.searchResults,
  setTenant = update.contract.changes.tenant,
}) => {
  return (
    <div>
      <div>
        <Dropdown
          menu={{
            items: TenantDropdownMenuFactory({
              items: searchResults || [],
              onClick: (id: any) => {
                setTenantsSearchQuery.set(null)
                const chosenTenant = searchResults.find((tenant: any) => tenant.id === id)
                if (chosenTenant.mainContacts) {
                  chosenTenant.mainContacts = Object.keys(chosenTenant.mainContacts || {}).map((contactId: string) => {
                    return chosenTenant.mainContacts[contactId]
                  })
                }
                setTenant.set(chosenTenant)
                const element = document.getElementById('tenantSearch') as HTMLInputElement
                if (element) {
                  element.value = ''
                }
                updateSearchResults.set(null)
              },
            }),
          }}
          open={!!searchResults}
          overlayStyle={{borderRadius: 0}}
          trigger={['click']}>
          <TextField
            name={'tenantSearch'}
            placeholder={'Search for tenant'}
            onChange={(e) => {
              if (timer) clearTimeout(timer)
              timer = setTimeout(() => setTenantsSearchQuery.set(e), 500)
            }}
          />
        </Dropdown>
      </div>
    </div>
  )
}
