import { db } from "src/producers/firebase";

export const syncContract: producer = ({
  id = observe.legacyContract.id,
  setContract = update.legacyContract.original,
  setAppendices = update.legacyContract.original.appendices,
  setListener = update.listenersOld[arg.id].contract,
}) => {
  if (!id) {
    return;
  }
  try {
    const listener = db
      .collection("contracts")
      .doc(id)
      .onSnapshot((doc) => {
        setContract.merge(doc.data());
        setAppendices.set(doc.data()?.appendices);
      });
    setListener.set(listener);
  } catch (e) {
    console.error("syncContract", e);
  }
};
