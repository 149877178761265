import React from "react";
import { Switch } from "@c11/components";

import { ContractStatus, UserRole } from "src/types";
import styles from "../../../styles.module.css";

export const ClauseCard: view = ({
  title,
  children,
  actionText,
  actionFunction,
  editableByTenant,
  required,
  hasSwitch,
  switchValue,
  onToggleSwitch,
  userRole = get.user.data.role,
  status = observe.contract.original.state.status,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const hasWhiteBackground = (editableByTenant && status == ContractStatus.NEGOTIATION) || isManager;

  return (
    <div key="card" className={`${hasWhiteBackground && "bg-white"}`}>
      <div key="header" className="flex justify-between items-baseline">
        {title && (
          <h2 key="title">
            {required && (
              <span className="text-pink-600 mr-1" key="0">
                *
              </span>
            )}
            <span key="1">{title}</span>
          </h2>
        )}
        {actionText && (
          <span className="cursor-pointer text-pink-600" onClick={actionFunction}>
            {actionText}
          </span>
        )}
        {hasSwitch && (
          <Switch
            isChecked={switchValue}
            onClick={onToggleSwitch}
            className={`${styles.headerSwitch} secondary-switch-${switchValue === "true" ? "checked" : "unchecked"}`}
          />
        )}
      </div>
      {children}
    </div>
  );
};
