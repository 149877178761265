import React from "react";

import i18n from "src/producers/languages/i18n";
import { InsideCard } from "../..";
import { UserRole } from "src/types";
import { FactoryFormField } from "../../fields/formFields/FactoryFormField";

export const LandlordSignatoryCard: view = ({
  i,
  onPlusOneClick,
  onRemoveClick,
  isLast,
  userRole = get.user.data.role,
  setSignatory = update.legacyContract.currentSession.changes[param.field],
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;

  return (
    <InsideCard key={"Landlord" + i} title={`${i18n.t("legacy.signatory_landlord_title")} #${i + 1}`}>
      <FactoryFormField
        key="0"
        varName={`signatory${i + 1}LandlordFirstName`}
        fieldName={"signatoryLandlordFirstName"}
      />
      <FactoryFormField key="1" varName={`signatory${i + 1}LandlordLastName`} fieldName={`signatoryLandlordLastName`} />
      <FactoryFormField key="2" varName={`signatory${i + 1}LandlordPosition`} fieldName={`signatoryLandlordPosition`} />
      <FactoryFormField key="3" varName={`signatory${i + 1}LandlordPhone`} fieldName={`signatoryLandlordPhone`} />
      <FactoryFormField key="4" varName={`signatory${i + 1}LandlordEmail`} fieldName={`signatoryLandlordEmail`} />
      <div key="b1" className="flex justify-between w-full">
        {isLast && isManager && (
          <button
            key="button1"
            className="my-4 border-b font-14 uppercase font-medium border-pink-600"
            onClick={onPlusOneClick}>
            {i18n.t("legacy.signatory_add_landlord")}
          </button>
        )}
        {/* don't show if is he first card */}
        {isLast && isManager && i !== 0 && (
          <button
            key="button2"
            className="my-4 border-b font-14 uppercase font-medium border-pink-600"
            onClick={() => {
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}LandlordFirstName` });
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}LandlordLastName` });
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}LandlordPosition` });
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}LandlordPhone` });
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}LandlordEmail` });
              onRemoveClick();
            }}>
            {i18n.t("legacy.signatory_remove")}
          </button>
        )}
      </div>
    </InsideCard>
  );
};
