import Big from 'big.js'

export const MinimumTurnOver: producer = ({
  fields = observe.contract.originalv2.fields,

  minimumRent = get.contract.originalv2.fields.minimumRent.value.value(),
  turnoverPercent = get.contract.originalv2.fields.turnoverPercent.value.value(),
  originalMinimumTurnOver = get.contract.originalv2.fields.minimumTurnOver.value.value(),
  isCustom = get.contract.originalv2.fields.minimumTurnOver.isCustom.value(),

  minimumTurnOver = update.contract.changes.fields.minimumTurnOver.value,
}) => {
  if (!fields || isCustom) return
  if (!minimumRent || !turnoverPercent) {
    minimumTurnOver.set(null)
    return
  }

  const value = new Big(minimumRent).div(turnoverPercent).times(100).round().toNumber()
  if (originalMinimumTurnOver === value) return
  minimumTurnOver.set(value)
  console.log('minimum turnover let s go', value, minimumRent, turnoverPercent)
}
