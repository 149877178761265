import { functions } from "src/producers/firebase";
import { formatContractName } from "src/utils/formatContractName";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";
import { b64toBlob } from "src/utils/b64ToBlob";
import { AgreementType } from "src/types/commands/command";

export const generatePDF: producer = async ({
  generatePDFTrigger = observe.contract.triggers.generatePDF,
  agreementType = observe.contract.original.agreementType,
  title = observe.contract.values.title.displayedValue,
  inheritedData = observe.contract.original.inheritedData,
  removeTrigger = update.contract.triggers.generatePDF,
  PDFLoading = update.contract.flags.loadingpdf,
  contractId = get.contract.id,
  allValuesRef = get.contract.values,
  error = update.errors[param.id],
}) => {
  if (!generatePDFTrigger) return;

  removeTrigger.remove();
  PDFLoading.set(performance.now());

  try {
    const values = allValuesRef.value();
    const generatePDFv3 = functions.httpsCallable("generatePDFv3");
    const res = await generatePDFv3({ contractId: contractId.value() });
    let nameOfFile = formatContractName(values);
    if (agreementType === AgreementType.ADDENDUM) {
      const shoppingMall = inheritedData.shoppingMall ? `, ${inheritedData.shoppingMall}` : "";
      const tenantName = inheritedData.tenantName ? `${inheritedData.tenantName}` : "";

      nameOfFile = `${title} - ${tenantName} ${shoppingMall}`;
    }
    const pdfBlob = b64toBlob(res.data, "application/pdf");
    const fileName = `${nameOfFile}.pdf`;
    saveAs(pdfBlob, fileName);

    PDFLoading.remove();
  } catch (e) {
    PDFLoading.remove();
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "generatePDF" }
    );
  }
};
