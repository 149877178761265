'use strict'
// module.exports = validate20
// module.exports.general = validate20
export default validate20
const schema22 = {
  '$id': 'mall',
  'type': 'object',
  'definitions': {
    'email': {
      'type': 'string',
      'pattern': '^\\S+@\\S+\\.\\S+$'
    }, 'phone': { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
  },
  'properties': {
    'MTO': { 'type': 'number' },
    'compulsoryDerogation': { 'type': 'string' },
    'firstTimeRegulation': { 'type': 'number' },
    'hasStorageUnit': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }, { 'type': 'boolean' }] },
    'indexAdjustmentBasicIndex': { 'type': 'number' },
    'introText': { 'type': 'string' },
    'landlordAccountNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'landlordAddress': { 'type': 'string' },
    'landlordContactPersonFirstName': { 'type': 'string' },
    'landlordContactPersonLastName': { 'type': 'string' },
    'landlordEmail': { '$ref': '#/definitions/email' },
    'landlordInvoiceAddress': { 'type': 'string' },
    'landlordName': { 'type': 'string' },
    'landlordOrgNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'landlordPhone': { '$ref': '#/definitions/phone' },
    'landlordPostalAddress': { 'type': 'string' },
    'landlordPropertyManager': { 'type': 'string' },
    'marketingCosts': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'minimumMarketingCost': { 'type': 'number' },
    'minimumRent': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'municipality': { 'type': 'string' },
    'parcelNumber': { 'type': 'string' },
    'paymentProvisionFirstPayDueDate': { 'type': 'number' },
    'propertyAddress': { 'type': 'string' },
    'propertyCommonArea': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'propertyDescription': { 'type': 'string' },
    'propertyExclusiveArea': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'propertyLevel': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'propertyLocalNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'propertyTotalArea': { 'type': 'number' },
    'rentalEnd': { 'type': 'number' },
    'rentalPeriodEorR': { 'type': 'string', 'pattern': '^(E|R)$' },
    'rentalPeriodHasOptions': { 'anyOf': [{ 'type': 'boolean' }, { 'type': 'string' }] },
    'rentalPeriodOptionsPeriod': { 'type': 'string' },
    'rentalStart': { 'type': 'number' },
    'securityAlternative': { 'type': 'number' },
    'securityAlternativeAditionalInfo': { 'type': 'string' },
    'securityAmount': { 'type': 'number' },
    'securityDeadline': { 'type': 'number' },
    'sharedCosts': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'sharedCostsStorage': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'shoppingMall': { 'type': 'string' },
    'specialProvisions': { 'type': 'string' },
    'storagePropertyCommonArea': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'storagePropertyExclusiveArea': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'storagePropertyLevel': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'storagePropertyLocalNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'storagePropertyTotalArea': { 'type': 'number' },
    'storageRent': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'storeChain': { 'type': 'string' },
    'storeConcept': { 'type': 'string' },
    'storeGroup': { 'type': 'string' },
    'storeIndustryCode': { 'type': 'string' },
    'storeName': { 'type': 'string' },
    'storeOpening': { 'type': 'number' },
    'tenantAddress': { 'type': 'string' },
    'tenantContactPersonFirstName': { 'type': 'string' },
    'tenantContactPersonLastName': { 'type': 'string' },
    'tenantEmail': { '$ref': '#/definitions/email' },
    'tenantInvoiceAddress': { 'type': 'string' },
    'tenantName': { 'type': 'string' },
    'tenantOrgNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'tenantPhone': { '$ref': '#/definitions/phone' },
    'tenantPostalAddress': { 'type': 'string' },
    'turnoverPercent': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'vatAlternative': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] }
  },
  'required': ['MTO', 'compulsoryDerogation', 'firstTimeRegulation', 'indexAdjustmentBasicIndex', 'introText', 'landlordAccountNo', 'landlordAddress', 'landlordContactPersonFirstName', 'landlordContactPersonLastName', 'landlordEmail', 'landlordInvoiceAddress', 'landlordName', 'landlordOrgNo', 'landlordPostalAddress', 'landlordPropertyManager', 'marketingCosts', 'minimumMarketingCost', 'minimumRent', 'municipality', 'parcelNumber', 'paymentProvisionFirstPayDueDate', 'propertyAddress', 'propertyCommonArea', 'propertyDescription', 'propertyExclusiveArea', 'propertyLevel', 'propertyLocalNo', 'propertyTotalArea', 'rentalEnd', 'rentalStart', 'securityAlternative', 'shoppingMall', 'storeConcept', 'storeIndustryCode', 'storeName', 'storeOpening', 'turnoverPercent', 'vatAlternative']
}
const schema23 = { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' }
const schema24 = { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
const pattern0 = new RegExp('^\\S+@\\S+\\.\\S+$', 'u')
const pattern1 = new RegExp('^[+]?[\\d\\s()-]{7,}$', 'u')
const pattern2 = new RegExp('^(E|R)$', 'u')

function validate20(data, {
  instancePath = '',
  parentData,
  parentDataProperty,
  rootData = data
} = {}) {/*# sourceURL="mall" */
  let vErrors = null
  let errors = 0
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.MTO === undefined) {
      const err0 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'MTO' },
        message: 'must have required property \'' + 'MTO' + '\''
      }
      if (vErrors === null) {
        vErrors = [err0]
      } else {
        vErrors.push(err0)
      }
      errors++
    }
    if (data.compulsoryDerogation === undefined) {
      const err1 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'compulsoryDerogation' },
        message: 'must have required property \'' + 'compulsoryDerogation' + '\''
      }
      if (vErrors === null) {
        vErrors = [err1]
      } else {
        vErrors.push(err1)
      }
      errors++
    }
    if (data.firstTimeRegulation === undefined) {
      const err2 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'firstTimeRegulation' },
        message: 'must have required property \'' + 'firstTimeRegulation' + '\''
      }
      if (vErrors === null) {
        vErrors = [err2]
      } else {
        vErrors.push(err2)
      }
      errors++
    }
    if (data.indexAdjustmentBasicIndex === undefined) {
      const err3 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'indexAdjustmentBasicIndex' },
        message: 'must have required property \'' + 'indexAdjustmentBasicIndex' + '\''
      }
      if (vErrors === null) {
        vErrors = [err3]
      } else {
        vErrors.push(err3)
      }
      errors++
    }
    if (data.introText === undefined) {
      const err4 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'introText' },
        message: 'must have required property \'' + 'introText' + '\''
      }
      if (vErrors === null) {
        vErrors = [err4]
      } else {
        vErrors.push(err4)
      }
      errors++
    }
    if (data.landlordAccountNo === undefined) {
      const err5 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordAccountNo' },
        message: 'must have required property \'' + 'landlordAccountNo' + '\''
      }
      if (vErrors === null) {
        vErrors = [err5]
      } else {
        vErrors.push(err5)
      }
      errors++
    }
    if (data.landlordAddress === undefined) {
      const err6 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordAddress' },
        message: 'must have required property \'' + 'landlordAddress' + '\''
      }
      if (vErrors === null) {
        vErrors = [err6]
      } else {
        vErrors.push(err6)
      }
      errors++
    }
    if (data.landlordContactPersonFirstName === undefined) {
      const err7 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordContactPersonFirstName' },
        message: 'must have required property \'' + 'landlordContactPersonFirstName' + '\''
      }
      if (vErrors === null) {
        vErrors = [err7]
      } else {
        vErrors.push(err7)
      }
      errors++
    }
    if (data.landlordContactPersonLastName === undefined) {
      const err8 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordContactPersonLastName' },
        message: 'must have required property \'' + 'landlordContactPersonLastName' + '\''
      }
      if (vErrors === null) {
        vErrors = [err8]
      } else {
        vErrors.push(err8)
      }
      errors++
    }
    if (data.landlordEmail === undefined) {
      const err9 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordEmail' },
        message: 'must have required property \'' + 'landlordEmail' + '\''
      }
      if (vErrors === null) {
        vErrors = [err9]
      } else {
        vErrors.push(err9)
      }
      errors++
    }
    if (data.landlordInvoiceAddress === undefined) {
      const err10 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordInvoiceAddress' },
        message: 'must have required property \'' + 'landlordInvoiceAddress' + '\''
      }
      if (vErrors === null) {
        vErrors = [err10]
      } else {
        vErrors.push(err10)
      }
      errors++
    }
    if (data.landlordName === undefined) {
      const err11 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordName' },
        message: 'must have required property \'' + 'landlordName' + '\''
      }
      if (vErrors === null) {
        vErrors = [err11]
      } else {
        vErrors.push(err11)
      }
      errors++
    }
    if (data.landlordOrgNo === undefined) {
      const err12 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordOrgNo' },
        message: 'must have required property \'' + 'landlordOrgNo' + '\''
      }
      if (vErrors === null) {
        vErrors = [err12]
      } else {
        vErrors.push(err12)
      }
      errors++
    }
    if (data.landlordPostalAddress === undefined) {
      const err13 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordPostalAddress' },
        message: 'must have required property \'' + 'landlordPostalAddress' + '\''
      }
      if (vErrors === null) {
        vErrors = [err13]
      } else {
        vErrors.push(err13)
      }
      errors++
    }
    if (data.landlordPropertyManager === undefined) {
      const err14 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordPropertyManager' },
        message: 'must have required property \'' + 'landlordPropertyManager' + '\''
      }
      if (vErrors === null) {
        vErrors = [err14]
      } else {
        vErrors.push(err14)
      }
      errors++
    }
    if (data.marketingCosts === undefined) {
      const err15 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'marketingCosts' },
        message: 'must have required property \'' + 'marketingCosts' + '\''
      }
      if (vErrors === null) {
        vErrors = [err15]
      } else {
        vErrors.push(err15)
      }
      errors++
    }
    if (data.minimumMarketingCost === undefined) {
      const err16 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'minimumMarketingCost' },
        message: 'must have required property \'' + 'minimumMarketingCost' + '\''
      }
      if (vErrors === null) {
        vErrors = [err16]
      } else {
        vErrors.push(err16)
      }
      errors++
    }
    if (data.minimumRent === undefined) {
      const err17 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'minimumRent' },
        message: 'must have required property \'' + 'minimumRent' + '\''
      }
      if (vErrors === null) {
        vErrors = [err17]
      } else {
        vErrors.push(err17)
      }
      errors++
    }
    if (data.municipality === undefined) {
      const err18 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'municipality' },
        message: 'must have required property \'' + 'municipality' + '\''
      }
      if (vErrors === null) {
        vErrors = [err18]
      } else {
        vErrors.push(err18)
      }
      errors++
    }
    if (data.parcelNumber === undefined) {
      const err19 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'parcelNumber' },
        message: 'must have required property \'' + 'parcelNumber' + '\''
      }
      if (vErrors === null) {
        vErrors = [err19]
      } else {
        vErrors.push(err19)
      }
      errors++
    }
    if (data.paymentProvisionFirstPayDueDate === undefined) {
      const err20 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'paymentProvisionFirstPayDueDate' },
        message: 'must have required property \'' + 'paymentProvisionFirstPayDueDate' + '\''
      }
      if (vErrors === null) {
        vErrors = [err20]
      } else {
        vErrors.push(err20)
      }
      errors++
    }
    if (data.propertyAddress === undefined) {
      const err21 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyAddress' },
        message: 'must have required property \'' + 'propertyAddress' + '\''
      }
      if (vErrors === null) {
        vErrors = [err21]
      } else {
        vErrors.push(err21)
      }
      errors++
    }
    if (data.propertyCommonArea === undefined) {
      const err22 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyCommonArea' },
        message: 'must have required property \'' + 'propertyCommonArea' + '\''
      }
      if (vErrors === null) {
        vErrors = [err22]
      } else {
        vErrors.push(err22)
      }
      errors++
    }
    if (data.propertyDescription === undefined) {
      const err23 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyDescription' },
        message: 'must have required property \'' + 'propertyDescription' + '\''
      }
      if (vErrors === null) {
        vErrors = [err23]
      } else {
        vErrors.push(err23)
      }
      errors++
    }
    if (data.propertyExclusiveArea === undefined) {
      const err24 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyExclusiveArea' },
        message: 'must have required property \'' + 'propertyExclusiveArea' + '\''
      }
      if (vErrors === null) {
        vErrors = [err24]
      } else {
        vErrors.push(err24)
      }
      errors++
    }
    if (data.propertyLevel === undefined) {
      const err25 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyLevel' },
        message: 'must have required property \'' + 'propertyLevel' + '\''
      }
      if (vErrors === null) {
        vErrors = [err25]
      } else {
        vErrors.push(err25)
      }
      errors++
    }
    if (data.propertyLocalNo === undefined) {
      const err26 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyLocalNo' },
        message: 'must have required property \'' + 'propertyLocalNo' + '\''
      }
      if (vErrors === null) {
        vErrors = [err26]
      } else {
        vErrors.push(err26)
      }
      errors++
    }
    if (data.propertyTotalArea === undefined) {
      const err27 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyTotalArea' },
        message: 'must have required property \'' + 'propertyTotalArea' + '\''
      }
      if (vErrors === null) {
        vErrors = [err27]
      } else {
        vErrors.push(err27)
      }
      errors++
    }
    if (data.rentalEnd === undefined) {
      const err28 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentalEnd' },
        message: 'must have required property \'' + 'rentalEnd' + '\''
      }
      if (vErrors === null) {
        vErrors = [err28]
      } else {
        vErrors.push(err28)
      }
      errors++
    }
    if (data.rentalStart === undefined) {
      const err29 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentalStart' },
        message: 'must have required property \'' + 'rentalStart' + '\''
      }
      if (vErrors === null) {
        vErrors = [err29]
      } else {
        vErrors.push(err29)
      }
      errors++
    }
    if (data.securityAlternative === undefined) {
      const err30 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'securityAlternative' },
        message: 'must have required property \'' + 'securityAlternative' + '\''
      }
      if (vErrors === null) {
        vErrors = [err30]
      } else {
        vErrors.push(err30)
      }
      errors++
    }
    if (data.shoppingMall === undefined) {
      const err31 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'shoppingMall' },
        message: 'must have required property \'' + 'shoppingMall' + '\''
      }
      if (vErrors === null) {
        vErrors = [err31]
      } else {
        vErrors.push(err31)
      }
      errors++
    }
    if (data.storeConcept === undefined) {
      const err32 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'storeConcept' },
        message: 'must have required property \'' + 'storeConcept' + '\''
      }
      if (vErrors === null) {
        vErrors = [err32]
      } else {
        vErrors.push(err32)
      }
      errors++
    }
    if (data.storeIndustryCode === undefined) {
      const err33 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'storeIndustryCode' },
        message: 'must have required property \'' + 'storeIndustryCode' + '\''
      }
      if (vErrors === null) {
        vErrors = [err33]
      } else {
        vErrors.push(err33)
      }
      errors++
    }
    if (data.storeName === undefined) {
      const err34 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'storeName' },
        message: 'must have required property \'' + 'storeName' + '\''
      }
      if (vErrors === null) {
        vErrors = [err34]
      } else {
        vErrors.push(err34)
      }
      errors++
    }
    if (data.storeOpening === undefined) {
      const err35 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'storeOpening' },
        message: 'must have required property \'' + 'storeOpening' + '\''
      }
      if (vErrors === null) {
        vErrors = [err35]
      } else {
        vErrors.push(err35)
      }
      errors++
    }
    if (data.turnoverPercent === undefined) {
      const err36 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'turnoverPercent' },
        message: 'must have required property \'' + 'turnoverPercent' + '\''
      }
      if (vErrors === null) {
        vErrors = [err36]
      } else {
        vErrors.push(err36)
      }
      errors++
    }
    if (data.vatAlternative === undefined) {
      const err37 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'vatAlternative' },
        message: 'must have required property \'' + 'vatAlternative' + '\''
      }
      if (vErrors === null) {
        vErrors = [err37]
      } else {
        vErrors.push(err37)
      }
      errors++
    }
    if (data.MTO !== undefined) {
      let data0 = data.MTO
      if (!((typeof data0 == 'number') && (isFinite(data0)))) {
        const err38 = {
          instancePath: instancePath + '/MTO',
          schemaPath: '#/properties/MTO/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err38]
        } else {
          vErrors.push(err38)
        }
        errors++
      }
    }
    if (data.compulsoryDerogation !== undefined) {
      if (typeof data.compulsoryDerogation !== 'string') {
        const err39 = {
          instancePath: instancePath + '/compulsoryDerogation',
          schemaPath: '#/properties/compulsoryDerogation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err39]
        } else {
          vErrors.push(err39)
        }
        errors++
      }
    }
    if (data.firstTimeRegulation !== undefined) {
      let data2 = data.firstTimeRegulation
      if (!((typeof data2 == 'number') && (isFinite(data2)))) {
        const err40 = {
          instancePath: instancePath + '/firstTimeRegulation',
          schemaPath: '#/properties/firstTimeRegulation/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err40]
        } else {
          vErrors.push(err40)
        }
        errors++
      }
    }
    if (data.hasStorageUnit !== undefined) {
      let data3 = data.hasStorageUnit
      const _errs8 = errors
      let valid1 = false
      const _errs9 = errors
      if (!((typeof data3 == 'number') && (isFinite(data3)))) {
        const err41 = {
          instancePath: instancePath + '/hasStorageUnit',
          schemaPath: '#/properties/hasStorageUnit/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err41]
        } else {
          vErrors.push(err41)
        }
        errors++
      }
      var _valid0 = _errs9 === errors
      valid1 = valid1 || _valid0
      if (!valid1) {
        const _errs11 = errors
        if (typeof data3 !== 'string') {
          const err42 = {
            instancePath: instancePath + '/hasStorageUnit',
            schemaPath: '#/properties/hasStorageUnit/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err42]
          } else {
            vErrors.push(err42)
          }
          errors++
        }
        var _valid0 = _errs11 === errors
        valid1 = valid1 || _valid0
        if (!valid1) {
          const _errs13 = errors
          if (typeof data3 !== 'boolean') {
            const err43 = {
              instancePath: instancePath + '/hasStorageUnit',
              schemaPath: '#/properties/hasStorageUnit/anyOf/2/type',
              keyword: 'type',
              params: { type: 'boolean' },
              message: 'must be boolean'
            }
            if (vErrors === null) {
              vErrors = [err43]
            } else {
              vErrors.push(err43)
            }
            errors++
          }
          var _valid0 = _errs13 === errors
          valid1 = valid1 || _valid0
        }
      }
      if (!valid1) {
        const err44 = {
          instancePath: instancePath + '/hasStorageUnit',
          schemaPath: '#/properties/hasStorageUnit/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err44]
        } else {
          vErrors.push(err44)
        }
        errors++
      } else {
        errors = _errs8
        if (vErrors !== null) {
          if (_errs8) {
            vErrors.length = _errs8
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.indexAdjustmentBasicIndex !== undefined) {
      let data4 = data.indexAdjustmentBasicIndex
      if (!((typeof data4 == 'number') && (isFinite(data4)))) {
        const err45 = {
          instancePath: instancePath + '/indexAdjustmentBasicIndex',
          schemaPath: '#/properties/indexAdjustmentBasicIndex/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err45]
        } else {
          vErrors.push(err45)
        }
        errors++
      }
    }
    if (data.introText !== undefined) {
      if (typeof data.introText !== 'string') {
        const err46 = {
          instancePath: instancePath + '/introText',
          schemaPath: '#/properties/introText/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err46]
        } else {
          vErrors.push(err46)
        }
        errors++
      }
    }
    if (data.landlordAccountNo !== undefined) {
      let data6 = data.landlordAccountNo
      const _errs20 = errors
      let valid2 = false
      const _errs21 = errors
      if (!((typeof data6 == 'number') && (isFinite(data6)))) {
        const err47 = {
          instancePath: instancePath + '/landlordAccountNo',
          schemaPath: '#/properties/landlordAccountNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err47]
        } else {
          vErrors.push(err47)
        }
        errors++
      }
      var _valid1 = _errs21 === errors
      valid2 = valid2 || _valid1
      if (!valid2) {
        const _errs23 = errors
        if (typeof data6 !== 'string') {
          const err48 = {
            instancePath: instancePath + '/landlordAccountNo',
            schemaPath: '#/properties/landlordAccountNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err48]
          } else {
            vErrors.push(err48)
          }
          errors++
        }
        var _valid1 = _errs23 === errors
        valid2 = valid2 || _valid1
      }
      if (!valid2) {
        const err49 = {
          instancePath: instancePath + '/landlordAccountNo',
          schemaPath: '#/properties/landlordAccountNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err49]
        } else {
          vErrors.push(err49)
        }
        errors++
      } else {
        errors = _errs20
        if (vErrors !== null) {
          if (_errs20) {
            vErrors.length = _errs20
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.landlordAddress !== undefined) {
      if (typeof data.landlordAddress !== 'string') {
        const err50 = {
          instancePath: instancePath + '/landlordAddress',
          schemaPath: '#/properties/landlordAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err50]
        } else {
          vErrors.push(err50)
        }
        errors++
      }
    }
    if (data.landlordContactPersonFirstName !== undefined) {
      if (typeof data.landlordContactPersonFirstName !== 'string') {
        const err51 = {
          instancePath: instancePath + '/landlordContactPersonFirstName',
          schemaPath: '#/properties/landlordContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err51]
        } else {
          vErrors.push(err51)
        }
        errors++
      }
    }
    if (data.landlordContactPersonLastName !== undefined) {
      if (typeof data.landlordContactPersonLastName !== 'string') {
        const err52 = {
          instancePath: instancePath + '/landlordContactPersonLastName',
          schemaPath: '#/properties/landlordContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err52]
        } else {
          vErrors.push(err52)
        }
        errors++
      }
    }
    if (data.landlordEmail !== undefined) {
      let data10 = data.landlordEmail
      if (typeof data10 === 'string') {
        if (!pattern0.test(data10)) {
          const err53 = {
            instancePath: instancePath + '/landlordEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {
            vErrors = [err53]
          } else {
            vErrors.push(err53)
          }
          errors++
        }
      } else {
        const err54 = {
          instancePath: instancePath + '/landlordEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err54]
        } else {
          vErrors.push(err54)
        }
        errors++
      }
    }
    if (data.landlordInvoiceAddress !== undefined) {
      if (typeof data.landlordInvoiceAddress !== 'string') {
        const err55 = {
          instancePath: instancePath + '/landlordInvoiceAddress',
          schemaPath: '#/properties/landlordInvoiceAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err55]
        } else {
          vErrors.push(err55)
        }
        errors++
      }
    }
    if (data.landlordName !== undefined) {
      if (typeof data.landlordName !== 'string') {
        const err56 = {
          instancePath: instancePath + '/landlordName',
          schemaPath: '#/properties/landlordName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err56]
        } else {
          vErrors.push(err56)
        }
        errors++
      }
    }
    if (data.landlordOrgNo !== undefined) {
      let data13 = data.landlordOrgNo
      const _errs39 = errors
      let valid4 = false
      const _errs40 = errors
      if (!((typeof data13 == 'number') && (isFinite(data13)))) {
        const err57 = {
          instancePath: instancePath + '/landlordOrgNo',
          schemaPath: '#/properties/landlordOrgNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err57]
        } else {
          vErrors.push(err57)
        }
        errors++
      }
      var _valid2 = _errs40 === errors
      valid4 = valid4 || _valid2
      if (!valid4) {
        const _errs42 = errors
        if (typeof data13 !== 'string') {
          const err58 = {
            instancePath: instancePath + '/landlordOrgNo',
            schemaPath: '#/properties/landlordOrgNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err58]
          } else {
            vErrors.push(err58)
          }
          errors++
        }
        var _valid2 = _errs42 === errors
        valid4 = valid4 || _valid2
      }
      if (!valid4) {
        const err59 = {
          instancePath: instancePath + '/landlordOrgNo',
          schemaPath: '#/properties/landlordOrgNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err59]
        } else {
          vErrors.push(err59)
        }
        errors++
      } else {
        errors = _errs39
        if (vErrors !== null) {
          if (_errs39) {
            vErrors.length = _errs39
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.landlordPhone !== undefined) {
      let data14 = data.landlordPhone
      if (typeof data14 === 'string') {
        if (!pattern1.test(data14)) {
          const err60 = {
            instancePath: instancePath + '/landlordPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {
            vErrors = [err60]
          } else {
            vErrors.push(err60)
          }
          errors++
        }
      } else {
        const err61 = {
          instancePath: instancePath + '/landlordPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err61]
        } else {
          vErrors.push(err61)
        }
        errors++
      }
    }
    if (data.landlordPostalAddress !== undefined) {
      if (typeof data.landlordPostalAddress !== 'string') {
        const err62 = {
          instancePath: instancePath + '/landlordPostalAddress',
          schemaPath: '#/properties/landlordPostalAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err62]
        } else {
          vErrors.push(err62)
        }
        errors++
      }
    }
    if (data.landlordPropertyManager !== undefined) {
      if (typeof data.landlordPropertyManager !== 'string') {
        const err63 = {
          instancePath: instancePath + '/landlordPropertyManager',
          schemaPath: '#/properties/landlordPropertyManager/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err63]
        } else {
          vErrors.push(err63)
        }
        errors++
      }
    }
    if (data.marketingCosts !== undefined) {
      let data17 = data.marketingCosts
      const _errs52 = errors
      let valid6 = false
      const _errs53 = errors
      if (!((typeof data17 == 'number') && (isFinite(data17)))) {
        const err64 = {
          instancePath: instancePath + '/marketingCosts',
          schemaPath: '#/properties/marketingCosts/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err64]
        } else {
          vErrors.push(err64)
        }
        errors++
      }
      var _valid3 = _errs53 === errors
      valid6 = valid6 || _valid3
      if (!valid6) {
        const _errs55 = errors
        if (typeof data17 !== 'string') {
          const err65 = {
            instancePath: instancePath + '/marketingCosts',
            schemaPath: '#/properties/marketingCosts/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err65]
          } else {
            vErrors.push(err65)
          }
          errors++
        }
        var _valid3 = _errs55 === errors
        valid6 = valid6 || _valid3
      }
      if (!valid6) {
        const err66 = {
          instancePath: instancePath + '/marketingCosts',
          schemaPath: '#/properties/marketingCosts/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err66]
        } else {
          vErrors.push(err66)
        }
        errors++
      } else {
        errors = _errs52
        if (vErrors !== null) {
          if (_errs52) {
            vErrors.length = _errs52
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.minimumMarketingCost !== undefined) {
      let data18 = data.minimumMarketingCost
      if (!((typeof data18 == 'number') && (isFinite(data18)))) {
        const err67 = {
          instancePath: instancePath + '/minimumMarketingCost',
          schemaPath: '#/properties/minimumMarketingCost/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err67]
        } else {
          vErrors.push(err67)
        }
        errors++
      }
    }
    if (data.minimumRent !== undefined) {
      let data19 = data.minimumRent
      const _errs60 = errors
      let valid7 = false
      const _errs61 = errors
      if (!((typeof data19 == 'number') && (isFinite(data19)))) {
        const err68 = {
          instancePath: instancePath + '/minimumRent',
          schemaPath: '#/properties/minimumRent/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err68]
        } else {
          vErrors.push(err68)
        }
        errors++
      }
      var _valid4 = _errs61 === errors
      valid7 = valid7 || _valid4
      if (!valid7) {
        const _errs63 = errors
        if (typeof data19 !== 'string') {
          const err69 = {
            instancePath: instancePath + '/minimumRent',
            schemaPath: '#/properties/minimumRent/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err69]
          } else {
            vErrors.push(err69)
          }
          errors++
        }
        var _valid4 = _errs63 === errors
        valid7 = valid7 || _valid4
      }
      if (!valid7) {
        const err70 = {
          instancePath: instancePath + '/minimumRent',
          schemaPath: '#/properties/minimumRent/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err70]
        } else {
          vErrors.push(err70)
        }
        errors++
      } else {
        errors = _errs60
        if (vErrors !== null) {
          if (_errs60) {
            vErrors.length = _errs60
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.municipality !== undefined) {
      if (typeof data.municipality !== 'string') {
        const err71 = {
          instancePath: instancePath + '/municipality',
          schemaPath: '#/properties/municipality/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err71]
        } else {
          vErrors.push(err71)
        }
        errors++
      }
    }
    if (data.parcelNumber !== undefined) {
      if (typeof data.parcelNumber !== 'string') {
        const err72 = {
          instancePath: instancePath + '/parcelNumber',
          schemaPath: '#/properties/parcelNumber/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err72]
        } else {
          vErrors.push(err72)
        }
        errors++
      }
    }
    if (data.paymentProvisionFirstPayDueDate !== undefined) {
      let data22 = data.paymentProvisionFirstPayDueDate
      if (!((typeof data22 == 'number') && (isFinite(data22)))) {
        const err73 = {
          instancePath: instancePath + '/paymentProvisionFirstPayDueDate',
          schemaPath: '#/properties/paymentProvisionFirstPayDueDate/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err73]
        } else {
          vErrors.push(err73)
        }
        errors++
      }
    }
    if (data.propertyAddress !== undefined) {
      if (typeof data.propertyAddress !== 'string') {
        const err74 = {
          instancePath: instancePath + '/propertyAddress',
          schemaPath: '#/properties/propertyAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err74]
        } else {
          vErrors.push(err74)
        }
        errors++
      }
    }
    if (data.propertyCommonArea !== undefined) {
      let data24 = data.propertyCommonArea
      const _errs74 = errors
      let valid8 = false
      const _errs75 = errors
      if (!((typeof data24 == 'number') && (isFinite(data24)))) {
        const err75 = {
          instancePath: instancePath + '/propertyCommonArea',
          schemaPath: '#/properties/propertyCommonArea/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err75]
        } else {
          vErrors.push(err75)
        }
        errors++
      }
      var _valid5 = _errs75 === errors
      valid8 = valid8 || _valid5
      if (!valid8) {
        const _errs77 = errors
        if (typeof data24 !== 'string') {
          const err76 = {
            instancePath: instancePath + '/propertyCommonArea',
            schemaPath: '#/properties/propertyCommonArea/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err76]
          } else {
            vErrors.push(err76)
          }
          errors++
        }
        var _valid5 = _errs77 === errors
        valid8 = valid8 || _valid5
      }
      if (!valid8) {
        const err77 = {
          instancePath: instancePath + '/propertyCommonArea',
          schemaPath: '#/properties/propertyCommonArea/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err77]
        } else {
          vErrors.push(err77)
        }
        errors++
      } else {
        errors = _errs74
        if (vErrors !== null) {
          if (_errs74) {
            vErrors.length = _errs74
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.propertyDescription !== undefined) {
      if (typeof data.propertyDescription !== 'string') {
        const err78 = {
          instancePath: instancePath + '/propertyDescription',
          schemaPath: '#/properties/propertyDescription/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err78]
        } else {
          vErrors.push(err78)
        }
        errors++
      }
    }
    if (data.propertyExclusiveArea !== undefined) {
      let data26 = data.propertyExclusiveArea
      const _errs82 = errors
      let valid9 = false
      const _errs83 = errors
      if (!((typeof data26 == 'number') && (isFinite(data26)))) {
        const err79 = {
          instancePath: instancePath + '/propertyExclusiveArea',
          schemaPath: '#/properties/propertyExclusiveArea/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err79]
        } else {
          vErrors.push(err79)
        }
        errors++
      }
      var _valid6 = _errs83 === errors
      valid9 = valid9 || _valid6
      if (!valid9) {
        const _errs85 = errors
        if (typeof data26 !== 'string') {
          const err80 = {
            instancePath: instancePath + '/propertyExclusiveArea',
            schemaPath: '#/properties/propertyExclusiveArea/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err80]
          } else {
            vErrors.push(err80)
          }
          errors++
        }
        var _valid6 = _errs85 === errors
        valid9 = valid9 || _valid6
      }
      if (!valid9) {
        const err81 = {
          instancePath: instancePath + '/propertyExclusiveArea',
          schemaPath: '#/properties/propertyExclusiveArea/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err81]
        } else {
          vErrors.push(err81)
        }
        errors++
      } else {
        errors = _errs82
        if (vErrors !== null) {
          if (_errs82) {
            vErrors.length = _errs82
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.propertyLevel !== undefined) {
      let data27 = data.propertyLevel
      const _errs88 = errors
      let valid10 = false
      const _errs89 = errors
      if (!((typeof data27 == 'number') && (isFinite(data27)))) {
        const err82 = {
          instancePath: instancePath + '/propertyLevel',
          schemaPath: '#/properties/propertyLevel/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err82]
        } else {
          vErrors.push(err82)
        }
        errors++
      }
      var _valid7 = _errs89 === errors
      valid10 = valid10 || _valid7
      if (!valid10) {
        const _errs91 = errors
        if (typeof data27 !== 'string') {
          const err83 = {
            instancePath: instancePath + '/propertyLevel',
            schemaPath: '#/properties/propertyLevel/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err83]
          } else {
            vErrors.push(err83)
          }
          errors++
        }
        var _valid7 = _errs91 === errors
        valid10 = valid10 || _valid7
      }
      if (!valid10) {
        const err84 = {
          instancePath: instancePath + '/propertyLevel',
          schemaPath: '#/properties/propertyLevel/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err84]
        } else {
          vErrors.push(err84)
        }
        errors++
      } else {
        errors = _errs88
        if (vErrors !== null) {
          if (_errs88) {
            vErrors.length = _errs88
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.propertyLocalNo !== undefined) {
      let data28 = data.propertyLocalNo
      const _errs94 = errors
      let valid11 = false
      const _errs95 = errors
      if (!((typeof data28 == 'number') && (isFinite(data28)))) {
        const err85 = {
          instancePath: instancePath + '/propertyLocalNo',
          schemaPath: '#/properties/propertyLocalNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err85]
        } else {
          vErrors.push(err85)
        }
        errors++
      }
      var _valid8 = _errs95 === errors
      valid11 = valid11 || _valid8
      if (!valid11) {
        const _errs97 = errors
        if (typeof data28 !== 'string') {
          const err86 = {
            instancePath: instancePath + '/propertyLocalNo',
            schemaPath: '#/properties/propertyLocalNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err86]
          } else {
            vErrors.push(err86)
          }
          errors++
        }
        var _valid8 = _errs97 === errors
        valid11 = valid11 || _valid8
      }
      if (!valid11) {
        const err87 = {
          instancePath: instancePath + '/propertyLocalNo',
          schemaPath: '#/properties/propertyLocalNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err87]
        } else {
          vErrors.push(err87)
        }
        errors++
      } else {
        errors = _errs94
        if (vErrors !== null) {
          if (_errs94) {
            vErrors.length = _errs94
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.propertyTotalArea !== undefined) {
      let data29 = data.propertyTotalArea
      if (!((typeof data29 == 'number') && (isFinite(data29)))) {
        const err88 = {
          instancePath: instancePath + '/propertyTotalArea',
          schemaPath: '#/properties/propertyTotalArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err88]
        } else {
          vErrors.push(err88)
        }
        errors++
      }
    }
    if (data.rentalEnd !== undefined) {
      let data30 = data.rentalEnd
      if (!((typeof data30 == 'number') && (isFinite(data30)))) {
        const err89 = {
          instancePath: instancePath + '/rentalEnd',
          schemaPath: '#/properties/rentalEnd/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err89]
        } else {
          vErrors.push(err89)
        }
        errors++
      }
    }
    if (data.rentalPeriodEorR !== undefined) {
      let data31 = data.rentalPeriodEorR
      if (typeof data31 === 'string') {
        if (!pattern2.test(data31)) {
          const err90 = {
            instancePath: instancePath + '/rentalPeriodEorR',
            schemaPath: '#/properties/rentalPeriodEorR/pattern',
            keyword: 'pattern',
            params: { pattern: '^(E|R)$' },
            message: 'must match pattern "' + '^(E|R)$' + '"'
          }
          if (vErrors === null) {
            vErrors = [err90]
          } else {
            vErrors.push(err90)
          }
          errors++
        }
      } else {
        const err91 = {
          instancePath: instancePath + '/rentalPeriodEorR',
          schemaPath: '#/properties/rentalPeriodEorR/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err91]
        } else {
          vErrors.push(err91)
        }
        errors++
      }
    }
    if (data.rentalPeriodHasOptions !== undefined) {
      let data32 = data.rentalPeriodHasOptions
      const _errs106 = errors
      let valid12 = false
      const _errs107 = errors
      if (typeof data32 !== 'boolean') {
        const err92 = {
          instancePath: instancePath + '/rentalPeriodHasOptions',
          schemaPath: '#/properties/rentalPeriodHasOptions/anyOf/0/type',
          keyword: 'type',
          params: { type: 'boolean' },
          message: 'must be boolean'
        }
        if (vErrors === null) {
          vErrors = [err92]
        } else {
          vErrors.push(err92)
        }
        errors++
      }
      var _valid9 = _errs107 === errors
      valid12 = valid12 || _valid9
      if (!valid12) {
        const _errs109 = errors
        if (typeof data32 !== 'string') {
          const err93 = {
            instancePath: instancePath + '/rentalPeriodHasOptions',
            schemaPath: '#/properties/rentalPeriodHasOptions/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err93]
          } else {
            vErrors.push(err93)
          }
          errors++
        }
        var _valid9 = _errs109 === errors
        valid12 = valid12 || _valid9
      }
      if (!valid12) {
        const err94 = {
          instancePath: instancePath + '/rentalPeriodHasOptions',
          schemaPath: '#/properties/rentalPeriodHasOptions/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err94]
        } else {
          vErrors.push(err94)
        }
        errors++
      } else {
        errors = _errs106
        if (vErrors !== null) {
          if (_errs106) {
            vErrors.length = _errs106
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.rentalPeriodOptionsPeriod !== undefined) {
      if (typeof data.rentalPeriodOptionsPeriod !== 'string') {
        const err95 = {
          instancePath: instancePath + '/rentalPeriodOptionsPeriod',
          schemaPath: '#/properties/rentalPeriodOptionsPeriod/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err95]
        } else {
          vErrors.push(err95)
        }
        errors++
      }
    }
    if (data.rentalStart !== undefined) {
      let data34 = data.rentalStart
      if (!((typeof data34 == 'number') && (isFinite(data34)))) {
        const err96 = {
          instancePath: instancePath + '/rentalStart',
          schemaPath: '#/properties/rentalStart/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err96]
        } else {
          vErrors.push(err96)
        }
        errors++
      }
    }
    if (data.securityAlternative !== undefined) {
      let data35 = data.securityAlternative
      if (!((typeof data35 == 'number') && (isFinite(data35)))) {
        const err97 = {
          instancePath: instancePath + '/securityAlternative',
          schemaPath: '#/properties/securityAlternative/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err97]
        } else {
          vErrors.push(err97)
        }
        errors++
      }
    }
    if (data.securityAlternativeAditionalInfo !== undefined) {
      if (typeof data.securityAlternativeAditionalInfo !== 'string') {
        const err98 = {
          instancePath: instancePath + '/securityAlternativeAditionalInfo',
          schemaPath: '#/properties/securityAlternativeAditionalInfo/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err98]
        } else {
          vErrors.push(err98)
        }
        errors++
      }
    }
    if (data.securityAmount !== undefined) {
      let data37 = data.securityAmount
      if (!((typeof data37 == 'number') && (isFinite(data37)))) {
        const err99 = {
          instancePath: instancePath + '/securityAmount',
          schemaPath: '#/properties/securityAmount/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err99]
        } else {
          vErrors.push(err99)
        }
        errors++
      }
    }
    if (data.securityDeadline !== undefined) {
      let data38 = data.securityDeadline
      if (!((typeof data38 == 'number') && (isFinite(data38)))) {
        const err100 = {
          instancePath: instancePath + '/securityDeadline',
          schemaPath: '#/properties/securityDeadline/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err100]
        } else {
          vErrors.push(err100)
        }
        errors++
      }
    }
    if (data.sharedCosts !== undefined) {
      let data39 = data.sharedCosts
      const _errs124 = errors
      let valid13 = false
      const _errs125 = errors
      if (!((typeof data39 == 'number') && (isFinite(data39)))) {
        const err101 = {
          instancePath: instancePath + '/sharedCosts',
          schemaPath: '#/properties/sharedCosts/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err101]
        } else {
          vErrors.push(err101)
        }
        errors++
      }
      var _valid10 = _errs125 === errors
      valid13 = valid13 || _valid10
      if (!valid13) {
        const _errs127 = errors
        if (typeof data39 !== 'string') {
          const err102 = {
            instancePath: instancePath + '/sharedCosts',
            schemaPath: '#/properties/sharedCosts/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err102]
          } else {
            vErrors.push(err102)
          }
          errors++
        }
        var _valid10 = _errs127 === errors
        valid13 = valid13 || _valid10
      }
      if (!valid13) {
        const err103 = {
          instancePath: instancePath + '/sharedCosts',
          schemaPath: '#/properties/sharedCosts/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err103]
        } else {
          vErrors.push(err103)
        }
        errors++
      } else {
        errors = _errs124
        if (vErrors !== null) {
          if (_errs124) {
            vErrors.length = _errs124
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.sharedCostsStorage !== undefined) {
      let data40 = data.sharedCostsStorage
      const _errs130 = errors
      let valid14 = false
      const _errs131 = errors
      if (!((typeof data40 == 'number') && (isFinite(data40)))) {
        const err104 = {
          instancePath: instancePath + '/sharedCostsStorage',
          schemaPath: '#/properties/sharedCostsStorage/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err104]
        } else {
          vErrors.push(err104)
        }
        errors++
      }
      var _valid11 = _errs131 === errors
      valid14 = valid14 || _valid11
      if (!valid14) {
        const _errs133 = errors
        if (typeof data40 !== 'string') {
          const err105 = {
            instancePath: instancePath + '/sharedCostsStorage',
            schemaPath: '#/properties/sharedCostsStorage/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err105]
          } else {
            vErrors.push(err105)
          }
          errors++
        }
        var _valid11 = _errs133 === errors
        valid14 = valid14 || _valid11
      }
      if (!valid14) {
        const err106 = {
          instancePath: instancePath + '/sharedCostsStorage',
          schemaPath: '#/properties/sharedCostsStorage/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err106]
        } else {
          vErrors.push(err106)
        }
        errors++
      } else {
        errors = _errs130
        if (vErrors !== null) {
          if (_errs130) {
            vErrors.length = _errs130
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.shoppingMall !== undefined) {
      if (typeof data.shoppingMall !== 'string') {
        const err107 = {
          instancePath: instancePath + '/shoppingMall',
          schemaPath: '#/properties/shoppingMall/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err107]
        } else {
          vErrors.push(err107)
        }
        errors++
      }
    }
    if (data.specialProvisions !== undefined) {
      if (typeof data.specialProvisions !== 'string') {
        const err108 = {
          instancePath: instancePath + '/specialProvisions',
          schemaPath: '#/properties/specialProvisions/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err108]
        } else {
          vErrors.push(err108)
        }
        errors++
      }
    }
    if (data.storagePropertyCommonArea !== undefined) {
      let data43 = data.storagePropertyCommonArea
      const _errs140 = errors
      let valid15 = false
      const _errs141 = errors
      if (!((typeof data43 == 'number') && (isFinite(data43)))) {
        const err109 = {
          instancePath: instancePath + '/storagePropertyCommonArea',
          schemaPath: '#/properties/storagePropertyCommonArea/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err109]
        } else {
          vErrors.push(err109)
        }
        errors++
      }
      var _valid12 = _errs141 === errors
      valid15 = valid15 || _valid12
      if (!valid15) {
        const _errs143 = errors
        if (typeof data43 !== 'string') {
          const err110 = {
            instancePath: instancePath + '/storagePropertyCommonArea',
            schemaPath: '#/properties/storagePropertyCommonArea/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err110]
          } else {
            vErrors.push(err110)
          }
          errors++
        }
        var _valid12 = _errs143 === errors
        valid15 = valid15 || _valid12
      }
      if (!valid15) {
        const err111 = {
          instancePath: instancePath + '/storagePropertyCommonArea',
          schemaPath: '#/properties/storagePropertyCommonArea/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err111]
        } else {
          vErrors.push(err111)
        }
        errors++
      } else {
        errors = _errs140
        if (vErrors !== null) {
          if (_errs140) {
            vErrors.length = _errs140
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.storagePropertyExclusiveArea !== undefined) {
      let data44 = data.storagePropertyExclusiveArea
      const _errs146 = errors
      let valid16 = false
      const _errs147 = errors
      if (!((typeof data44 == 'number') && (isFinite(data44)))) {
        const err112 = {
          instancePath: instancePath + '/storagePropertyExclusiveArea',
          schemaPath: '#/properties/storagePropertyExclusiveArea/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err112]
        } else {
          vErrors.push(err112)
        }
        errors++
      }
      var _valid13 = _errs147 === errors
      valid16 = valid16 || _valid13
      if (!valid16) {
        const _errs149 = errors
        if (typeof data44 !== 'string') {
          const err113 = {
            instancePath: instancePath + '/storagePropertyExclusiveArea',
            schemaPath: '#/properties/storagePropertyExclusiveArea/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err113]
          } else {
            vErrors.push(err113)
          }
          errors++
        }
        var _valid13 = _errs149 === errors
        valid16 = valid16 || _valid13
      }
      if (!valid16) {
        const err114 = {
          instancePath: instancePath + '/storagePropertyExclusiveArea',
          schemaPath: '#/properties/storagePropertyExclusiveArea/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err114]
        } else {
          vErrors.push(err114)
        }
        errors++
      } else {
        errors = _errs146
        if (vErrors !== null) {
          if (_errs146) {
            vErrors.length = _errs146
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.storagePropertyLevel !== undefined) {
      let data45 = data.storagePropertyLevel
      const _errs152 = errors
      let valid17 = false
      const _errs153 = errors
      if (!((typeof data45 == 'number') && (isFinite(data45)))) {
        const err115 = {
          instancePath: instancePath + '/storagePropertyLevel',
          schemaPath: '#/properties/storagePropertyLevel/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err115]
        } else {
          vErrors.push(err115)
        }
        errors++
      }
      var _valid14 = _errs153 === errors
      valid17 = valid17 || _valid14
      if (!valid17) {
        const _errs155 = errors
        if (typeof data45 !== 'string') {
          const err116 = {
            instancePath: instancePath + '/storagePropertyLevel',
            schemaPath: '#/properties/storagePropertyLevel/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err116]
          } else {
            vErrors.push(err116)
          }
          errors++
        }
        var _valid14 = _errs155 === errors
        valid17 = valid17 || _valid14
      }
      if (!valid17) {
        const err117 = {
          instancePath: instancePath + '/storagePropertyLevel',
          schemaPath: '#/properties/storagePropertyLevel/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err117]
        } else {
          vErrors.push(err117)
        }
        errors++
      } else {
        errors = _errs152
        if (vErrors !== null) {
          if (_errs152) {
            vErrors.length = _errs152
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.storagePropertyLocalNo !== undefined) {
      let data46 = data.storagePropertyLocalNo
      const _errs158 = errors
      let valid18 = false
      const _errs159 = errors
      if (!((typeof data46 == 'number') && (isFinite(data46)))) {
        const err118 = {
          instancePath: instancePath + '/storagePropertyLocalNo',
          schemaPath: '#/properties/storagePropertyLocalNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err118]
        } else {
          vErrors.push(err118)
        }
        errors++
      }
      var _valid15 = _errs159 === errors
      valid18 = valid18 || _valid15
      if (!valid18) {
        const _errs161 = errors
        if (typeof data46 !== 'string') {
          const err119 = {
            instancePath: instancePath + '/storagePropertyLocalNo',
            schemaPath: '#/properties/storagePropertyLocalNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err119]
          } else {
            vErrors.push(err119)
          }
          errors++
        }
        var _valid15 = _errs161 === errors
        valid18 = valid18 || _valid15
      }
      if (!valid18) {
        const err120 = {
          instancePath: instancePath + '/storagePropertyLocalNo',
          schemaPath: '#/properties/storagePropertyLocalNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err120]
        } else {
          vErrors.push(err120)
        }
        errors++
      } else {
        errors = _errs158
        if (vErrors !== null) {
          if (_errs158) {
            vErrors.length = _errs158
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.storagePropertyTotalArea !== undefined) {
      let data47 = data.storagePropertyTotalArea
      if (!((typeof data47 == 'number') && (isFinite(data47)))) {
        const err121 = {
          instancePath: instancePath + '/storagePropertyTotalArea',
          schemaPath: '#/properties/storagePropertyTotalArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err121]
        } else {
          vErrors.push(err121)
        }
        errors++
      }
    }
    if (data.storageRent !== undefined) {
      let data48 = data.storageRent
      const _errs166 = errors
      let valid19 = false
      const _errs167 = errors
      if (!((typeof data48 == 'number') && (isFinite(data48)))) {
        const err122 = {
          instancePath: instancePath + '/storageRent',
          schemaPath: '#/properties/storageRent/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err122]
        } else {
          vErrors.push(err122)
        }
        errors++
      }
      var _valid16 = _errs167 === errors
      valid19 = valid19 || _valid16
      if (!valid19) {
        const _errs169 = errors
        if (typeof data48 !== 'string') {
          const err123 = {
            instancePath: instancePath + '/storageRent',
            schemaPath: '#/properties/storageRent/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err123]
          } else {
            vErrors.push(err123)
          }
          errors++
        }
        var _valid16 = _errs169 === errors
        valid19 = valid19 || _valid16
      }
      if (!valid19) {
        const err124 = {
          instancePath: instancePath + '/storageRent',
          schemaPath: '#/properties/storageRent/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err124]
        } else {
          vErrors.push(err124)
        }
        errors++
      } else {
        errors = _errs166
        if (vErrors !== null) {
          if (_errs166) {
            vErrors.length = _errs166
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.storeChain !== undefined) {
      if (typeof data.storeChain !== 'string') {
        const err125 = {
          instancePath: instancePath + '/storeChain',
          schemaPath: '#/properties/storeChain/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err125]
        } else {
          vErrors.push(err125)
        }
        errors++
      }
    }
    if (data.storeConcept !== undefined) {
      if (typeof data.storeConcept !== 'string') {
        const err126 = {
          instancePath: instancePath + '/storeConcept',
          schemaPath: '#/properties/storeConcept/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err126]
        } else {
          vErrors.push(err126)
        }
        errors++
      }
    }
    if (data.storeGroup !== undefined) {
      if (typeof data.storeGroup !== 'string') {
        const err127 = {
          instancePath: instancePath + '/storeGroup',
          schemaPath: '#/properties/storeGroup/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err127]
        } else {
          vErrors.push(err127)
        }
        errors++
      }
    }
    if (data.storeIndustryCode !== undefined) {
      if (typeof data.storeIndustryCode !== 'string') {
        const err128 = {
          instancePath: instancePath + '/storeIndustryCode',
          schemaPath: '#/properties/storeIndustryCode/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err128]
        } else {
          vErrors.push(err128)
        }
        errors++
      }
    }
    if (data.storeName !== undefined) {
      if (typeof data.storeName !== 'string') {
        const err129 = {
          instancePath: instancePath + '/storeName',
          schemaPath: '#/properties/storeName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err129]
        } else {
          vErrors.push(err129)
        }
        errors++
      }
    }
    if (data.storeOpening !== undefined) {
      let data54 = data.storeOpening
      if (!((typeof data54 == 'number') && (isFinite(data54)))) {
        const err130 = {
          instancePath: instancePath + '/storeOpening',
          schemaPath: '#/properties/storeOpening/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err130]
        } else {
          vErrors.push(err130)
        }
        errors++
      }
    }
    if (data.tenantAddress !== undefined) {
      if (typeof data.tenantAddress !== 'string') {
        const err131 = {
          instancePath: instancePath + '/tenantAddress',
          schemaPath: '#/properties/tenantAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err131]
        } else {
          vErrors.push(err131)
        }
        errors++
      }
    }
    if (data.tenantContactPersonFirstName !== undefined) {
      if (typeof data.tenantContactPersonFirstName !== 'string') {
        const err132 = {
          instancePath: instancePath + '/tenantContactPersonFirstName',
          schemaPath: '#/properties/tenantContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err132]
        } else {
          vErrors.push(err132)
        }
        errors++
      }
    }
    if (data.tenantContactPersonLastName !== undefined) {
      if (typeof data.tenantContactPersonLastName !== 'string') {
        const err133 = {
          instancePath: instancePath + '/tenantContactPersonLastName',
          schemaPath: '#/properties/tenantContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err133]
        } else {
          vErrors.push(err133)
        }
        errors++
      }
    }
    if (data.tenantEmail !== undefined) {
      let data58 = data.tenantEmail
      if (typeof data58 === 'string') {
        if (!pattern0.test(data58)) {
          const err134 = {
            instancePath: instancePath + '/tenantEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {
            vErrors = [err134]
          } else {
            vErrors.push(err134)
          }
          errors++
        }
      } else {
        const err135 = {
          instancePath: instancePath + '/tenantEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err135]
        } else {
          vErrors.push(err135)
        }
        errors++
      }
    }
    if (data.tenantInvoiceAddress !== undefined) {
      if (typeof data.tenantInvoiceAddress !== 'string') {
        const err136 = {
          instancePath: instancePath + '/tenantInvoiceAddress',
          schemaPath: '#/properties/tenantInvoiceAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err136]
        } else {
          vErrors.push(err136)
        }
        errors++
      }
    }
    if (data.tenantName !== undefined) {
      if (typeof data.tenantName !== 'string') {
        const err137 = {
          instancePath: instancePath + '/tenantName',
          schemaPath: '#/properties/tenantName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err137]
        } else {
          vErrors.push(err137)
        }
        errors++
      }
    }
    if (data.tenantOrgNo !== undefined) {
      let data61 = data.tenantOrgNo
      const _errs197 = errors
      let valid21 = false
      const _errs198 = errors
      if (!((typeof data61 == 'number') && (isFinite(data61)))) {
        const err138 = {
          instancePath: instancePath + '/tenantOrgNo',
          schemaPath: '#/properties/tenantOrgNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err138]
        } else {
          vErrors.push(err138)
        }
        errors++
      }
      var _valid17 = _errs198 === errors
      valid21 = valid21 || _valid17
      if (!valid21) {
        const _errs200 = errors
        if (typeof data61 !== 'string') {
          const err139 = {
            instancePath: instancePath + '/tenantOrgNo',
            schemaPath: '#/properties/tenantOrgNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err139]
          } else {
            vErrors.push(err139)
          }
          errors++
        }
        var _valid17 = _errs200 === errors
        valid21 = valid21 || _valid17
      }
      if (!valid21) {
        const err140 = {
          instancePath: instancePath + '/tenantOrgNo',
          schemaPath: '#/properties/tenantOrgNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err140]
        } else {
          vErrors.push(err140)
        }
        errors++
      } else {
        errors = _errs197
        if (vErrors !== null) {
          if (_errs197) {
            vErrors.length = _errs197
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.tenantPhone !== undefined) {
      let data62 = data.tenantPhone
      if (typeof data62 === 'string') {
        if (!pattern1.test(data62)) {
          const err141 = {
            instancePath: instancePath + '/tenantPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {
            vErrors = [err141]
          } else {
            vErrors.push(err141)
          }
          errors++
        }
      } else {
        const err142 = {
          instancePath: instancePath + '/tenantPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err142]
        } else {
          vErrors.push(err142)
        }
        errors++
      }
    }
    if (data.tenantPostalAddress !== undefined) {
      if (typeof data.tenantPostalAddress !== 'string') {
        const err143 = {
          instancePath: instancePath + '/tenantPostalAddress',
          schemaPath: '#/properties/tenantPostalAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err143]
        } else {
          vErrors.push(err143)
        }
        errors++
      }
    }
    if (data.turnoverPercent !== undefined) {
      let data64 = data.turnoverPercent
      const _errs208 = errors
      let valid23 = false
      const _errs209 = errors
      if (!((typeof data64 == 'number') && (isFinite(data64)))) {
        const err144 = {
          instancePath: instancePath + '/turnoverPercent',
          schemaPath: '#/properties/turnoverPercent/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err144]
        } else {
          vErrors.push(err144)
        }
        errors++
      }
      var _valid18 = _errs209 === errors
      valid23 = valid23 || _valid18
      if (!valid23) {
        const _errs211 = errors
        if (typeof data64 !== 'string') {
          const err145 = {
            instancePath: instancePath + '/turnoverPercent',
            schemaPath: '#/properties/turnoverPercent/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err145]
          } else {
            vErrors.push(err145)
          }
          errors++
        }
        var _valid18 = _errs211 === errors
        valid23 = valid23 || _valid18
      }
      if (!valid23) {
        const err146 = {
          instancePath: instancePath + '/turnoverPercent',
          schemaPath: '#/properties/turnoverPercent/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err146]
        } else {
          vErrors.push(err146)
        }
        errors++
      } else {
        errors = _errs208
        if (vErrors !== null) {
          if (_errs208) {
            vErrors.length = _errs208
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.vatAlternative !== undefined) {
      let data65 = data.vatAlternative
      const _errs214 = errors
      let valid24 = false
      const _errs215 = errors
      if (!((typeof data65 == 'number') && (isFinite(data65)))) {
        const err147 = {
          instancePath: instancePath + '/vatAlternative',
          schemaPath: '#/properties/vatAlternative/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err147]
        } else {
          vErrors.push(err147)
        }
        errors++
      }
      var _valid19 = _errs215 === errors
      valid24 = valid24 || _valid19
      if (!valid24) {
        const _errs217 = errors
        if (typeof data65 !== 'string') {
          const err148 = {
            instancePath: instancePath + '/vatAlternative',
            schemaPath: '#/properties/vatAlternative/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err148]
          } else {
            vErrors.push(err148)
          }
          errors++
        }
        var _valid19 = _errs217 === errors
        valid24 = valid24 || _valid19
      }
      if (!valid24) {
        const err149 = {
          instancePath: instancePath + '/vatAlternative',
          schemaPath: '#/properties/vatAlternative/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err149]
        } else {
          vErrors.push(err149)
        }
        errors++
      } else {
        errors = _errs214
        if (vErrors !== null) {
          if (_errs214) {
            vErrors.length = _errs214
          } else {
            vErrors = null
          }
        }
      }
    }
  } else {
    const err150 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object'
    }
    if (vErrors === null) {
      vErrors = [err150]
    } else {
      vErrors.push(err150)
    }
    errors++
  }
  validate20.errors = vErrors
  return errors === 0
}
