import { ApplicationError, ErrorLevel, UserRole } from "src/types";
import { db } from "src/producers/firebase";

export const setAntecedents: producer = async ({
  contractId = observe.legacyContract.id,
  versions = observe.legacyContract.original.versions,
  sessions = observe.legacyContract.original.sessions,
  trigger = observe.legacyContract.triggers.setAntecedents,

  allValues = get.legacyContract.values,
  removeTrigger = update.legacyContract.triggers.setAntecedents,
  role = get.user.data.role,
  syncFeed = update.legacyContract.triggers.syncFeed,
  error = update.errors[param.type],
}) => {
  allValues = allValues.value();
  if (!trigger || !contractId || !allValues || !versions || !sessions) {
    return;
  }
  removeTrigger.remove();
  const batch = db.batch();
  const contractDb = db.collection("contracts").doc(contractId);
  const calculateAntecedent = (timelineValue: any, timeline: any, currentValue: string): any => {
    if (timelineValue?.antecedent) {
      let antecedentId = timelineValue.antecedent;
      const antecedentValue = timeline[String(antecedentId)].value;
      if (currentValue === antecedentValue) {
        return calculateAntecedent(timeline[String(antecedentId)], timeline, antecedentValue);
      } else {
        return antecedentValue;
      }
    } else {
      return;
    }
  };

  try {
    const userRole = role.value();
    if (versions) {
      Object.keys(versions).forEach((versionId) => {
        const versionDb = contractDb.collection("versions").doc(String(versionId));
        const version = versions[versionId];
        Object.keys(version.variables).forEach((varName) => {
          const variable = version.variables[varName];
          let antecedent: string = "";
          if (String(versionId) !== "1") {
            const valueId = Object.keys(allValues[varName].valueTimeline).find((id) => {
              return (
                allValues[varName].valueTimeline[id].versionId === versionId &&
                allValues[varName].valueTimeline[id].type === "version"
              );
            });
            antecedent = calculateAntecedent(
              allValues[varName].valueTimeline[String(valueId)],
              allValues[varName].valueTimeline,
              variable.value
            );
          }
          if (antecedent && (!variable.antecedent || variable.antecedent !== antecedent)) {
            const antecedentVar: any = {};
            antecedentVar[`variables.${varName}.antecedent`] = antecedent;
            batch.update(versionDb, antecedentVar);
          }
        });
      });
    }
    if (sessions) {
      Object.keys(sessions).forEach((sessionId) => {
        const sessionDb = contractDb.collection("sessions").doc(String(sessionId));
        if (userRole !== UserRole.MANAGER && sessionId !== userRole) {
          return;
        }
        const session = sessions[sessionId];
        if (!session.changes) {
          return;
        }
        Object.keys(session.changes).forEach((varName) => {
          if (!session.changes) {
            return;
          }
          const variable = session.changes[varName];
          const antecedent = calculateAntecedent(
            allValues[varName].valueTimeline[sessionId],
            allValues[varName].valueTimeline,
            variable.value
          );
          if (antecedent && (!variable.antecedent || variable.antecedent !== antecedent)) {
            const antecedentVar: any = {};
            antecedentVar[`changes.${varName}.antecedent`] = antecedent;
            batch.update(sessionDb, antecedentVar);
          }
        });
      });
    }
    await batch.commit();
    console.log("updated antecedents");
    syncFeed.set(Date.now());
  } catch (e) {
    console.error("setAtecedents", e);
    error.set({ message: "error-setting-antecedents", level: ErrorLevel.LOW, details: e } as ApplicationError, {
      type: "values",
    });
  }
};
