import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import { FactoryFormField } from "src/pages/Contract/subviews/ContractForm/fields/FactoryFormField";
import styles from "../../../styles.module.css"

export const SpecialProvisionsClause: view = ({ title }) => {
  return (
    <ClauseCard title={title}>
      <div className={`${styles.contractCard}`}>
        <FactoryFormField key="0" fieldName="specialProvisions" varName="specialProvisions" />
      </div>
    </ClauseCard>
  );
};
