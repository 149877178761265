import i18n from "src/producers/languages/i18n";
import { Modal } from "src/components";
import { SVGs } from "src/assets";
import { UserRole } from "src/types";
import { saveVersion } from "src/pages/Contract/CreateVersionModal/CreateVersionModal.saveVersion";
import { TextField } from "@c11/components";
import { RecipientsForm } from "../RecipientsForm/RecipientsForm";

export const CreateVersionModal: view = ({
  show = observe.modal.createNewVersion,
  saveVersion = update.contract.triggers.saveVersion,
  userRole = get.user.data.role,
  _viewId,
  setMessage = update.views[prop._viewId].message,
  message = observe.views[prop._viewId].message,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!show || !isManager) return null;
  return (
    <Modal
      title={i18n.t("modals.create_version_title")}
      okButtonText={i18n.t("modals.create_version_button")}
      image={() => <SVGs.ModalGenerateContract />}
      onOkClick={() => saveVersion.set({ now: Date.now(), payload: { message } })}
      onCancelClick={() => {
        setMessage.remove();
      }}>
      <p className="mb-1">{i18n.t("modals.create_version_p1")}</p>
      <RecipientsForm />
      <div translate={"no"} className="notranslate my-2">
        <TextField
          value={message}
          label={i18n.t("modals.send_message_to_tenant")}
          onChange={(e: any) => {
            if (e) {
              setMessage.set(e.target.value);
            }
          }}
        />
      </div>
    </Modal>
  );
};

CreateVersionModal.producers([saveVersion]);
