import { db } from "src/producers/firebase";
import { ContractStatus, ContractEventType, ErrorLevel, ApplicationError, NotificationTypes } from "src/types";
import moment from "moment";

export const generateContract: producer = async ({
  trigger = observe.legacyContract.triggers.generateContract,
  removeTrigger = update.legacyContract.triggers.generateContract,
  contractId = get.legacyContract.id,
  sendEmail = update.legacyContract.triggers.sendEmail,
  userID = get.user.uid,
  values = get.legacyContract.values,
  error = update.errors[param.type],
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  contractId = contractId.value();

  try {
    const batch = db.batch();

    // saving generateContract event to DB
    // const eventsRef = db
    //   .collection("contracts")
    //   .doc(contractId)
    //   .collection("events")
    //   .doc(`movedToSigning-${moment().format("DD-MM-YY_HH-mm-ss")}`);

    // const generateContractEvent: any = {
    //   createdAt: Date.now(),
    //   type: ContractEventType.MOVED_TO_SIGNING,
    //   user: userID.value(),
    // };
    // batch.set(eventsRef, generateContractEvent);

    batch.update(db.collection("contracts").doc(contractId), {
      status: ContractStatus.SIGNING,
      movedToSigningAt: Date.now(),
    });
    await batch.commit();
    sendEmail.set({
      type: NotificationTypes.TENANT_SIGN_LEGACY,
    });
  } catch (e) {
    console.error("generateContract", e);
    error.set({ message: "generateContract error", level: ErrorLevel.BREAKING } as ApplicationError, {
      type: "version",
    });
  }
};
