/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import styles from "../styles.module.css";
import { Divider, Tag } from "antd";
import { Checkbox } from "@c11/components";
import { SearchIcon } from "src/assets/svg";
import "src/pages/Dashboard/LocationFilter/LocationFilter.css";
import i18n from "src/producers/languages/i18n";
import { DashboardFilters } from "src/types";
import { SearchSelect } from "src/components/SearchSelect/SearchSelect";

export const LocationFilter: view = ({
  locations = observe.domain.locations,
  currentValue = observe.pages.dashboard.filters[DashboardFilters.LOCATION],
  updateCurrentValue = update.pages.dashboard.filters[DashboardFilters.LOCATION],
  triggerGetContracts = update.pages.dashboard.triggers.getContracts,
  searchDropdownsFf = observe.featureFlags.dashboardSearchDropdowns.main,
  dashboardFiltersFlag = observe.featureFlags.dashboardFilters.main,
}) => {
  let shoppingMalls: any[] = [];
  if (locations) {
    shoppingMalls = Object.values(locations);
  }

  return (
    <>
      {searchDropdownsFf && (
        <div>
          <label>{i18n.t("dashboard.filter_location_default")}</label>
          <div className={`${styles.filterContainer}`}>
            <SearchSelect
              items={shoppingMalls}
              placeholder={i18n.t("dashboard.filter_location_default")}
              searchOptions={{ keys: ["name"] }}
              onSearch={(ev) => console.log(`searchDropdown.onSearch: event.target = ${JSON.stringify(ev.target)}`)}
              onSelect={(ev) => {
                console.log(`searchDropdown.onSelect: event.target = ${JSON.stringify(ev.target)}`);
                const selectedLocation = ev.target.value;
                if (currentValue?.id !== selectedLocation?.id) {
                  updateCurrentValue.set(selectedLocation);
                } else {
                  updateCurrentValue.remove();
                }
                // TODO: maybe find a better way to trigger get contracts
                triggerGetContracts.set(Date.now());
              }}
            />
            <SearchIcon />
          </div>
          {currentValue && (
            <div>
              <Tag
                // Tag's color prop looks strange, used style and custom close icon instead
                className={`${styles.selectOption}`}
                closable
                onClose={() => {
                  updateCurrentValue.remove();
                  triggerGetContracts.set(Date.now());
                }}>
                {currentValue.name}
              </Tag>
            </div>
          )}
        </div>
      )}
      {dashboardFiltersFlag && (
        <div className={`${styles.filter}`}>
          <div className={`${styles.filterHeader}`}>
            <h3>{i18n.t("dashboard.filter_location_default")}</h3>
            <Divider className={`${styles.divider} `} />
          </div>
          <div className={`${styles.filterContent}`}>
            {shoppingMalls.map((sm: any, index: number) => (
              <div
                key={`sm-${index}`}
                className={`${styles.filterOption} `}
                onClick={() => {
                  if (currentValue?.id !== sm.id) {
                    updateCurrentValue.set(sm);
                  } else {
                    updateCurrentValue.remove();
                  }
                  // TODO: maybe find a better way to trigger get contracts
                  triggerGetContracts.set(Date.now());
                }}>
                <Checkbox key={`ck-${sm.id}-${currentValue?.id === sm.id}`} isChecked={currentValue?.id === sm.id} />
                <p className={currentValue?.id === sm.id ? "text-pink-600 font-semibold" : ""}>{sm.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
