import { db, storageRef } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel } from 'src/types'
import { appendixToSave, resetAppendixToSave } from '../components'

const RandExp = require('randexp')

const randomId = new RandExp(/[A-Za-z0-9_]{9}/)

export const addAttachment: producer = async ({
  contractId = observe.legacyContract.id,
  trigger = observe.legacyContract.triggers.saveAppendix.save,
  removeTrigger = update.legacyContract.triggers.saveAppendix,
  appendices = get.legacyContract.original.appendices,
  appendixDisplayName = observe.legacyContract.triggers.saveAppendix.appendixDisplayName,
  error = update.errors[param.type],
  userId = get.user.uid,
}) => {
  if (!trigger || !contractId || !appendixDisplayName) {
    return
  }
  const fileId = randomId.gen()
  const appendixRef = storageRef.child(`${contractId}/${fileId}`)
  try {
    await appendixRef.put(appendixToSave)
    appendices = appendices.value()
    if (!appendices) appendices = []
    appendices.push({
      fileName: appendixDisplayName,
      fileId: fileId,
      fileType: appendixToSave.type,
    })
    const contractDb = db.collection('contracts').doc(contractId)
    await contractDb.set({ appendices }, { merge: true })
    userId = userId.value();
    ``
    console.log('Document successfully written!')
    removeTrigger.remove()
    resetAppendixToSave()
  } catch (e) {
    console.error('addAttachment', e)
    error.set(
      {
        message: 'error-saving-appendix',
        level: ErrorLevel.WARNING,
      } as ApplicationError,
      {
        type: 'defaultFiles',
      }
    )
  }
}
