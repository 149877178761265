import React from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./textEditor.css";

export const TextEditor: view = ({ value, onChange, readOnly }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link"],
    ],
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"];
  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      defaultValue={value}
      onChange={onChange}
      readOnly={readOnly}
      className = { readOnly && "bg-gray-200" }
    />
  );
};
