import { db } from 'src/producers/firebase'
import {
  ApplicationError,
  ContractStatus,
  ErrorLevel,
  ErrorType
} from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'

export const goBackToNegotiation: producer = async ({
  trigger = observe.contract.triggers.goBackToNegociation,
  removeTrigger = update.contract.triggers.goBackToNegociation,
  contractId = get.contract.id,
  error = update.errors[param.id],
  rawUser = get.rawUser,
  localFunctions = get.localFunctions,
}) => {
  if (!trigger) return
  removeTrigger.remove()
  contractId = contractId.value()
  try {
    const contractDb = db.collection('contracts').doc(contractId)
    // TODO - deprecated
    contractDb.update({
      status: ContractStatus.NEGOTIATION,
    })
    const command = {
      action: CommandActions.MOVE_TO_NEGOTIATION,
      contractId,
    }
    await axios.post(
      getFunctionsUrl('commandPublisher', localFunctions.value()),
      command,
      {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type': 'application/json'
        }
      }
    )
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'goBackToNegotiation-contract' }
    )
  }
}
