import { db } from "src/producers/firebase";
import { ApplicationError, ContractType, ErrorLevel, ErrorType } from "src/types";

const specialCharactersToReplace = {
  "Æ": "AE",
  "æ": "ae",
  "Ø": "O",
  "ø": "o",
  "Å": "AA",
  "å": "aa",
  "é": "e",
  "è": "e",
  "ê": "e",
  "ë": "e",
  "É": "E",
  "È": "E",
  "Ê": "E",
  "Ë": "E",
  "á": "a",
  "à": "a",
  "â": "a",
  "ä": "a",
  "Á": "A",
  "À": "A",
  "Â": "A",
  "Ä": "A",
  "í": "i",
  "ì": "i",
  "î": "i",
  "ï": "i",
  "Í": "I",
  "Ì": "I",
  "Î": "I",
  "Ï": "I",
  "ó": "o",
  "ò": "o",
  "ô": "o",
  "ö": "o",
  "Ó": "O",
  "Ò": "O",
  "Ô": "O",
  "Ö": "O",
  "ú": "u",
  "ù": "u",
  "û": "u",
  "ü": "u",
  "Ú": "U",
  "Ù": "U",
  "Û": "U",
  "Ü": "U",
  "ý": "y",
  "ÿ": "y",
  "Ý": "Y"
}

export const syncBuildings: producer = async ({
  userReady = observe.user.isReady,
  setShoppingMalls = update.domain.buildings,
  error = update.errors[param.id],
}) => {
  if (!userReady) return

  try {
    console.log("Syncing buildings...");
    const buildingsDb = db.collection("buildings");
    const buildings = (await buildingsDb.get()).docs;
    let buildingsToSync: any = {};
    console.log("buildings", buildings)
    for (const doc of buildings) {
      const building = doc.data();
      if (!building) continue
      buildingsToSync[doc.id] = building
      const regex = new RegExp(Object.keys(specialCharactersToReplace).join("|"), "g");

      buildingsToSync[doc.id]['searchName'] = building.name?.replace(regex, function(m) {
        return specialCharactersToReplace[m];
      });
    }

    setShoppingMalls.set(buildingsToSync);

    console.log("building syncing complete.", buildingsToSync);
  } catch (e) {
    console.log("Error syncing buildings", e);
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncBuilding" }
    );
  }
};
