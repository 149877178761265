export const mergeDataForValues: producer = ({
  fields = observe.contract.original.state.fields,
  session = observe.contract.currentSession,
  tenantSession = observe.contract.sessions.tenant,
  updateValues = update.contract.values,
}) => {
  if (!fields) return;
  let values: any = Object.entries(fields).reduce((acc, [key, value]) => {
    if (value?.current?.value !== undefined) {
      acc[key] = {
        displayedValue: value.current?.value,
        ...(value.current.custom !== undefined && { custom: value.current.custom }),
      };
    } else {
      acc[key] = { displayedValue: "" };
    }
    return acc;
  }, {} as any);
  if (session && session.changes) {
    const changes = session.changes;
    Object.entries(changes).forEach(([varName, data]) => {
      if (values[varName] && data?.value === null) delete values[varName];
      else {
        values[varName] = {
          ...values[varName],
          ...(data?.value && { displayedValue: data.value }),
          ...(data?.custom !== undefined && { custom: data.custom }),
        };
      }
    });
  }
  if (tenantSession?.changes) {
    Object.entries(tenantSession.changes).forEach(([varName, data]) => {
      if (values[varName] && data?.value === null) delete values[varName];
      else {
        values[varName] = {
          ...values[varName],
          ...(data?.value && { displayedValue: data.value }),
          ...(data?.custom !== undefined && { custom: data.custom }),
        };
      }
    });
  }
  updateValues.set(values);
};
