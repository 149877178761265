import { db } from 'src/producers/firebase'
import {
  ApplicationError,
  ContractStatus,
  ErrorLevel,
  NotificationTypes,
  UserRole,
} from 'src/types'

export const saveVersion: producer = async ({
  user = observe.user,
  trigger = observe.legacyContract.triggers.saveVersion,
  removeTrigger = update.legacyContract.triggers.saveVersion,
  contractId = observe.legacyContract.id,
  currentSession = observe.legacyContract.currentSession,
  latestVersion = observe.legacyContract.original.latestVersion,
  status = get.legacyContract.original.status,
  session = get.legacyContract.original.sessions[param.userType],
  defaults = get.legacyContract.original.defaultValues,
  resetCurrentSession = update.legacyContract.currentSession,
  clearSessions = update.legacyContract.triggers.clearSessions,
  sendEmail = update.legacyContract.triggers.sendEmail,
  callbackTrigger = update.legacyContract.triggers[param.trigger],
  values = get.legacyContract.values,
  error = update.errors[param.type],
}) => {
  if (!trigger || user.data.role !== UserRole.MANAGER || !currentSession?.changes) {
    return
  }
  const cbTrigger = trigger.callback
  status = status.value()
  const isDraft = status === ContractStatus.DRAFT
  const isNegotiation = status === ContractStatus.NEGOTIATION
  try {
    removeTrigger.remove()
    let managerChanges: any = {}

    Object.keys(currentSession.changes).forEach((varName) => {
      managerChanges[varName] = {
        custom: currentSession.changes[varName].custom || false,
        value: currentSession.changes[varName].value,
        metadata: {
          createdAt: currentSession.changes[varName].createdAt,
          createdBy: UserRole.MANAGER,
        },
      }
    })

    // Merge sessions and defaults for draft
    defaults = defaults.value()
    let toSave: any = {}
    if (isDraft && defaults) {
      Object.keys(defaults).forEach((varName) => {
        toSave[varName] = {
          value: defaults[varName].value,
          metadata: {
            createdAt: defaults[varName].createdAt,
            createdBy: UserRole.MANAGER,
          },
        }
      })
    }
    let tenantSession = session.value({ userType: UserRole.TENANT })
    if (tenantSession) {
      Object.keys(tenantSession.changes).forEach((varName) => {
        if (
          managerChanges[varName]?.metadata.createdAt &&
          tenantSession.changes[varName].createdAt > managerChanges[varName].metadata.createdAt
        ) {
          delete managerChanges[varName]
        }
        toSave[varName] = {
          custom: tenantSession.changes[varName].custom || false,
          value: tenantSession.changes[varName].value,
          metadata: {
            createdAt: tenantSession.changes[varName].createdAt,
            createdBy: UserRole.TENANT,
          },
        }
      })
      toSave = Object.assign(toSave, managerChanges)
    } else {
      toSave = Object.assign(toSave, managerChanges)
    }

    //set latestVersion
    let nextVersionNumber = parseInt(latestVersion)
    if (isNegotiation) {
      nextVersionNumber = latestVersion + 1
    }
    values = values.value()

    // saving newVersion to DB
    const nextVersionRef = db
      .collection('contracts')
      .doc(contractId)
      .collection('versions')
      .doc(String(nextVersionNumber))

    const newVersion: any = {
      variables: toSave,
      user: user.uid,
      createdAt: Date.now(),
    }

    const batch = db.batch()
    batch.set(nextVersionRef, newVersion)

    // const command = {
    //   action: CommandActions.CREATE_VERSION,
    //   contractId: contractId,
    //   currentSession: currentSession,
    //   defaults: defaults,
    //   tenantSession: tenantSession
    // };
    // commandPublisher(command).catch((err: any) => console.error(err))

    // // saving newVersion event to DB
    // const eventsRef = db
    //   .collection("contracts")
    //   .doc(contractId)
    //   .collection("events")
    //   .doc(`newVersion-${nextVersionNumber}`);

    // const newVersionEvent = {
    //   fields: toSave,
    //   user: user.uid,
    //   createdAt: Date.now(),
    //   type: ContractEventType.VERSION_CREATED,
    //   version: nextVersionNumber,
    // };

    // batch.set(eventsRef, newVersionEvent);

    // saving dashboard info to db
    batch.update(db.collection('contracts').doc(contractId), {
      latestVersion: nextVersionNumber,
      dashboardInfo: {
        shoppingMall: values['shoppingMall']?.['displayedValue'] || '',
        tenantName: values['tenantName']?.['displayedValue'] || '',
        rentalStart: values['rentalStart']?.['displayedValue'] || '',
        rentalEnd: values['rentalEnd']?.['displayedValue'] || '',
        propertyLocalNo: values['propertyLocalNo']?.['displayedValue'] || '',
      },
    })
    await batch.commit()
    if (!isDraft)
      sendEmail.set({
        type: NotificationTypes.NEW_VERSION,
      })
    clearSessions.set(Date.now())
    resetCurrentSession.remove()
    if (cbTrigger) callbackTrigger.set(Date.now(), { trigger: cbTrigger })

    console.log('All done')
  } catch (e) {
    console.error('Save Versions Legacy', e)
    error.set({
      message: 'error-saving-version',
      level: ErrorLevel.BREAKING
    } as ApplicationError, { type: 'version' })
  }
}
