import { db, functions } from "src/producers/firebase";
import { ContractEventType, ContractStatus, NotificationTypes } from "src/types";
import { CommandActions } from "src/types/commands/command";

export const sendContract: producer = async ({
  trigger = observe.legacyContract.triggers.sendContract,
  session = get.contract.original.sessions[param.userType],
  defaults = get.contract.original.defaultValues,
  currentSession = get.contract.currentSession,
  removeTrigger = update.legacyContract.triggers.sendContract,
  contractId = get.legacyContract.id,
  sendEmail = update.legacyContract.triggers.sendEmail,
  user = get.user,
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  contractId = contractId.value();
  user = user.value();
  
  session = session.value();
  defaults = defaults.value();
  currentSession = currentSession.value();

  const contractDb = db.collection("contracts").doc(contractId);
  // const commandPublisher = functions.httpsCallable("commandPublisher");
  // const command = {
  //   action: CommandActions.SEND_OFFER,
  //   contractId: contractId,
  //   session,
  //   defaults,
  //   currentSession
  // };
  // commandPublisher(command).catch((err: any) => console.error(err))
  // contractDb.collection("events").add({
  //   createdAt: Date.now(),
  //   type: ContractEventType.NEGOTIATION_STARTED,
  //   user: user.uid,
  // });
  contractDb.update({ status: ContractStatus.NEGOTIATION });
  sendEmail.set({ type: NotificationTypes.TENANT_INVITATION });
};
