import { Spinner } from "@c11/components";
import i18n from "src/producers/languages/i18n";

import styles from "../../styles.module.css";
import { ContractTemplate, ContractV2 } from "../../contract.types";
import { PartiesClause } from "./clauses/Parties";
import { RentalPremise } from "./clauses/RentalPremise";
import { RentalProperty } from "./templates/shoppingMall/clauses/RentalProperty";
import { RentalPeriod } from "./templates/shoppingMall/clauses/RentalPeriod";
import { RentalCosts } from "./templates/shoppingMall/clauses/RentalCosts";
import { VAT } from "./templates/shoppingMall/clauses/VAT";
import { Security } from "./templates/shoppingMall/clauses/Security";
import { Derogation } from "./templates/shoppingMall/clauses/Derogation";
import { SpecialProvisions } from "./clauses/SpecialProvisions";
import { LeaseConsistsOf } from "./templates/shoppingMall/clauses/LeaseConsistsOf";


export const ShoppingMallContract: view = ({
  contract = observe.contract.originalv2,
}) => {
  if (!contract) return <Spinner className={`${styles.contractSpinner}`} />;
  if (contract.template !== ContractTemplate.SHOPPING_MALL) return <></>;

  return (<div className={`${styles.contract}`}>
            <div className={`${styles.innerContract} contract-centered`}>
              <RentalPremise />
              <LeaseConsistsOf />
              <PartiesClause />
              <RentalProperty />
              <RentalPeriod />
              <RentalCosts />
              <VAT />
              <Security />
              <Derogation />
              <SpecialProvisions />
            </div>
        </div>);
};
