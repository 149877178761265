import React from "react";
import { SelectMenu, SelectMenuItem, Select } from "@c11/components";
import i18n from "src/producers/languages/i18n";
import { DashboardTabs, UserRole } from "src/types";
import styles from "../styles.module.css";

export const StatusDropdown: view = ({
  userRole = observe.user.data.role,
  selectedTab = observe.pages.dashboard.selectedTab,
  selectTab = update.pages.dashboard.selectedTab,
  triggerGetContracts = update.pages.dashboard.triggers.getContracts,
}) => {
  const isManager = userRole === UserRole.MANAGER;

  const statusName = (status: DashboardTabs) => {
    switch (status) {
      case DashboardTabs.ALL_CONTRACTS:
        return i18n.t("dashboard.tab_all_contracts");
      case DashboardTabs.DRAFTS:
        return i18n.t("status.draft");

      case DashboardTabs.OFFERS:
        return i18n.t("status.negotiation");

      case DashboardTabs.SIGNING:
        return i18n.t("status.signing");
      case DashboardTabs.ACTIVE:
        return i18n.t("status.active");

      default:
        return i18n.t("dashboard.tab_all_contracts");
    }
  };

  const renderStatusItem = (status: DashboardTabs) => (
    <SelectMenuItem
      key={status}
      className={`${styles.languageDropdownItem}`}
      onClick={() => {
        triggerGetContracts.set(Date.now());
        selectTab.set(status);
      }}>
      {statusName(status)}
    </SelectMenuItem>
  );

  const currentStatus = statusName(selectedTab);

  return (
    <div>
      <label>{i18n.t("dashboard.status")}</label>
      <Select placeholder={currentStatus}>
        <SelectMenu>
          {renderStatusItem(DashboardTabs.ALL_CONTRACTS)}
          {isManager && renderStatusItem(DashboardTabs.DRAFTS)}
          {renderStatusItem(DashboardTabs.OFFERS)}
          {renderStatusItem(DashboardTabs.SIGNING)}
          {renderStatusItem(DashboardTabs.ACTIVE)}
        </SelectMenu>
      </Select>
    </div>
  );
};
