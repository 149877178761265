import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'

export const moveToSigning: producer = async ({
  trigger = observe.contract.triggers.moveToSigning,
  removeTrigger = update.contract.triggers.moveToSigning,
  contractId = get.contract.id,
  error = update.errors[param.id],
  // stateTransition = update.contract.loading.stateTransition,
  isLoading = update.contract.loading.sendToSigning,
  contractSigningMethod = observe.contract.original.state.signing,
  rawUser = get.rawUser,
  localFunctions = get.localFunctions,
}) => {
  if (!trigger) return
  const payload = trigger.payload
  Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key])
  contractId = contractId.value()
  // stateTransition.set(true)
  try {
    //TODO if changes in session, decline until resolved
    const command: any = {
      signing: contractSigningMethod || '',
      action: CommandActions.INITIALIZE_SIGNING,
      contractId,
      options: payload,
    }

    console.log(' initializeSigning.ts', command)
    isLoading.set(true)
    await axios.post(
      getFunctionsUrl('commandPublisher', localFunctions.value()),
      command,
      {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type': 'application/json'
        }
      }
    )
  } catch (e) {
    isLoading.set(false)
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'moveToSigning-contract' }
    )
  } finally {
    removeTrigger.remove()
  }
}
