import { db } from "src/producers/firebase";

export const syncVersions: producer = async ({
  id = observe.legacyContract.id,

  versions = get.legacyContract.original.versions,

  setVersions = update.legacyContract.original.versions[param.versionId],
  setEmptyVersions = update.legacyContract.original.versions,
  setListener = update.listenersOld[arg.id].versions,
}) => {
  if (!id) {
    return;
  }
  try {
    const listener = await db
      .collection("contracts")
      .doc(id)
      .collection("versions")
      .onSnapshot((versionSnapshot) => {
        versionSnapshot.forEach((version) => {
          const data = version.data();
          if (!data) {
            return;
          }
          setVersions.set(data, { id, versionId: version.id });
        });
        const version = versions.value();
        if (version == undefined || !version) {
          setEmptyVersions.set({});
        }
      });
    setListener.set(listener);
  } catch (e) {
    console.error(e);
  }
};
