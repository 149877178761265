import i18n from 'src/producers/languages/i18n'
import { FileIcon } from 'src/assets/svg'
import { UploadPaperContractButton } from './UploadPaperContractButton'
import { ContractStatus, UserRole } from 'src/types'
import styles from '../styles.module.css'

export const ContractPDFClause: view = ({
  leaseDocument = observe.contract.originalv2.rentalContract,
  status = observe.contract.originalv2.status,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER
  const isActive = status === ContractStatus.ACTIVE
  return (
    <div>
      <h2>{i18n.t('contract_page.contract_pdf_clause_h')}</h2>
      <div className="flex flex-col gap-4 sub-clause-card pb-10">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-4 items-center">
            <FileIcon />
            {leaseDocument ? (
              <span className="font-semibold italic">{leaseDocument.fileName}</span>
            ) : (
              <span>{i18n.t('contract_page.contract_pdf_clause_p')}</span>
            )}
            {isManager && !isActive && (
              <div className="mr-12">
                <UploadPaperContractButton />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
