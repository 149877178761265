import React from "react";

import { Modal } from "src/components";
import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";

export const ReEditContractModal: view = ({
  show = observe.modal.reEditContract,
  deleteAllChanges = update.legacyContract.triggers.deleteAllChanges,
  goBackToNegotiation = update.legacyContract.triggers.goBackToNegotiation,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!show || !isManager) return null;
  return (
    <Modal
      title={i18n.t("modals_edit_contract_title")}
      okButtonText={i18n.t("modals_edit_contract_button")}
      image={() => <SVGs.ModalEdit />}
      onOkClick={() => goBackToNegotiation.set(Date.now())}
      onCancelClick={() => deleteAllChanges.set(Date.now())}>
      <p>{i18n.t("modals_edit_contract_p1")}</p>
    </Modal>
  );
};
