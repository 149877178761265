import _numeral from "src/utils/_numeral";
import i18n from "src/producers/languages/i18n";
import { SigningSummaryVerified } from "src/assets/svg";
import { ThumbnailCard, ThumbnailCardBody, ThumbnailCardHeader, ThumbnailCardHeaderV2 } from "src/components";

import styles from "../../../styles.module.css";
import { ContractStatus } from "src/types";
import { SendSigningNotificationModal } from "src/pages/Contract/SendSigningNotificationModal/SendSigningNotificationModal";
import { ActiveSignerListItem, InactiveSignerListItem } from "./SignerListItem";
import { ActivePDFListItem, InactivePDFListItem } from "./PdfListItem";
import { ExpiryDate } from "./ExpiryDate";

export const SigningSummaryCard: view = ({
  document = observe.contract.original.envelope.documents[0],
  envelopeModifiedDate = get.contract.original.envelope.modified,
  contractType = get.contract.original.contractType,
  status = observe.contract.original.state.status,
}) => {
  const recipients = document?.recipients;
  if (!recipients) return null;
  let lastSignedRecipientIndex: any = null;
  const isActive = status === ContractStatus.ACTIVE;
  recipients.forEach((recipient, i) => {
    if (recipient.completed) lastSignedRecipientIndex = i;
  });
  contractType = contractType.value();
  return (
    <>
      <ThumbnailCard className={`${styles.summaryCardSigning}`}>
        <ThumbnailCardHeaderV2
          className={`${styles.signingCardsHeaderTitle}`}
          key="tch-1"
          title={i18n.t("summary_page.signing_card_title")}
          subtitle={i18n.t("summary_page.signing_card_subtitle")}>
          <div className="float-right inline-block">
            <div className="flex items-center">
              <ExpiryDate />
              <SigningSummaryVerified />
            </div>
          </div>
        </ThumbnailCardHeaderV2>
        <ThumbnailCardBody key="tcb-1" className={`${styles.summaryCardSigningBody}`}>
          {recipients.map((recipient, i) => {
            if (recipient.completed) {
              return <InactiveSignerListItem signed recipient={recipient} index={i} key={i} />;
            }
            if (lastSignedRecipientIndex === i - 1 || (i === 0 && lastSignedRecipientIndex === null)) {
              const lastSignDate = recipients?.[lastSignedRecipientIndex]?.signature?.date;
              const date = lastSignDate || envelopeModifiedDate.value();
              return <ActiveSignerListItem recipient={recipient} index={i} key={i} date={date} />;
            } else {
              return <InactiveSignerListItem recipient={recipient} index={i} key={i} />;
            }
          })}
          {isActive ? <ActivePDFListItem /> : <InactivePDFListItem />}
        </ThumbnailCardBody>
      </ThumbnailCard>
      <SendSigningNotificationModal />
    </>
  );
};
