import {ApplicationError, ErrorLevel, ErrorType, LogLevels, UserRole} from 'src/types'
import { db } from 'src/producers/firebase'

export const contractListener: producer = async ({
  contractId = observe.contract.id,
  uid = get.user.uid,
  role = get.user.data.role.value(),
  state = get.contract.originalv2,
  isEditedBy = get.contract.originalv2.isEditedBy.value(),
  editor = get.contract.editor.value(),

  updateContract = update.contract.originalv2,
  updateIsEditedBy = update.contract.originalv2.isEditedBy,
  updateEditor = update.contract.editor,

  error = update.errors[param.id]
}) => {
  if (!contractId) return
  uid = uid.value()
  try {
    db.collection('contracts').doc(contractId)
      .onSnapshot(async (doc: any) => {
        const contract = doc.data()
        if (!contract) return
        if (!state.value()) updateContract.set(contract)
        if (!contract.isEditedBy) {
          updateIsEditedBy.remove()
          updateEditor.remove()
        } else {
          updateIsEditedBy.set(contract.isEditedBy)
        }

      })

  } catch (e) {
    error.set(
      {
        message: e,
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: 'envelope' }
    )
  }
}
