import i18n from 'src/producers/languages/i18n'
import { getContractType } from 'src/utils/getContractType'
import { validateForm } from 'src/validations/validateForm'

export const validateData = (
  values: any,
  state: string,
  updateInvalidFields: any,
  removeAllInvalidFields: any,
) => {
  let contractType = getContractType(state)
  if (contractType.includes('parking')) contractType = 'parking'
  removeAllInvalidFields.remove()
  let valuesToValidate: any = {}
  for (const key in values) {
    if (values[key].displayedValue.length < 1) continue
    valuesToValidate[key] = values[key].displayedValue
  }
  const { valid, errors } = validateForm(valuesToValidate, contractType)
  if (!valid) {
    for (const varName in errors) {
      updateInvalidFields.set(i18n.t(errors[varName]), { varName: varName })
    }
  }
  return valid
}
