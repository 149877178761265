import React from "react";

import { Card } from "../../index";
import { UserRole } from "src/types";
import { SecurityManager } from "./SecurityManager";
import { SecurityTenant } from "./SecurityTenant";

export const Security: view = ({
  title,
  securityAlternative = observe.legacyContract.values.securityAlternative.displayedValue,
  securityAlternativeFromChanges = observe.legacyContract.currentSession.changes.securityAlternative.value,
  userRole = get.user.data.role,
}) => {
  const i = securityAlternativeFromChanges || securityAlternative;
  const checkedState = [i === 1, i === 2];
  const isTenant = userRole.value() === UserRole.TENANT;
  const isManager = userRole.value() === UserRole.MANAGER;

  return (
    <Card title={title}>
      {isManager && <SecurityManager key="1" checkedState={checkedState} />}
      {isTenant && <SecurityTenant key="2" checkedState={checkedState} />}
    </Card>
  );
};
