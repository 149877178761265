import React from "react";
import { SelectMenu, SelectMenuItem, Select } from "@c11/components";
import i18n from "src/producers/languages/i18n";
import { KLPDepartments, UserRole } from 'src/types'
import styles from "../styles.module.css";
import { Divider } from 'antd'
import { BuildingIcon } from 'src/assets/svg'

export const DepartmentDropdown: view = ({
  label,
  onSelect,
  currentDepartment = observe.user.data.departmentId,
  triggerChange = update.triggers.selectUserDepartment,
  getContracts = update.pages.dashboard.triggers.getContracts,
  role = get.user.data.role,
}) => {
  role = role.value()
  if(role !== UserRole.MANAGER) return
  const departmentName = (department: KLPDepartments) => {
    switch (department) {
      case KLPDepartments.SHOPPING_MALLS:
        return i18n.t("dashboard.department_shopping_malls");
      case KLPDepartments.OFFICE_BUILDINGS:
        return i18n.t("dashboard.department_office_buildings");
      default:
        return department;
    }
  };

  const displayedValue = currentDepartment
    ? departmentName(currentDepartment)
    : i18n.t("department_dropdown.placeholder");

  const departmentListItem = (department: KLPDepartments) => (
    <SelectMenuItem
      key={`dept-${department}`}
      onClick={() => {
        triggerChange.set(department);
        getContracts.set(Date.now())
        // onSelect(department);
      }}>
      {departmentName(department)}
    </SelectMenuItem>
  );

  return (
    <>
      <div className={`${styles.headerDivider}`}>
        <Divider type="vertical" />
      </div>

      <div className={`${styles.navButtons+" "+styles.department}`}>
        <BuildingIcon/>
        <Select label={label} placeholder={displayedValue}>
          <SelectMenu>{Object.values(KLPDepartments).map((department) => departmentListItem(department))}</SelectMenu>
        </Select>
        {/*<DepartmentDropdown className={`${styles.departamentDopdown}`}*/}
        {/*                    onSelect={() => {*/}
        {/*                      getContracts.set(Date.now());*/}
        {/*                    }}*/}
        {/*  // label={i18n.t("dashboard.department_dropdown_label")}*/}
        {/*/>*/}
      </div>
    </>
  );
};
