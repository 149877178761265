import { SVGs } from "src/assets";
import { ContractStatus } from "src/types";
import i18n from "src/producers/languages/i18n";
import { ContractName } from "src/pages/Contract/Navbar/ContractName";
import { ContractNavbarButton } from "src/pages/Contract/Navbar/ContractNavbarButton";
import { StatusPill } from "src/pages/Contract/Navbar/StatusPill";
import { NavbarMenu } from "src/pages/Contract/Navbar/NavbarMenu";
import { BackArrow } from "src/assets/svg";
import styles from "../styles.module.css"
import { router } from "src/router";

export const Navbar: view = ({}) => (
  <nav className={`${styles.navHeaderContract}`}>
    <div className={`${styles.navHeaderContractInfo}`}>
      <div key="left">
        <BackButton />
        <ContractName />
        <StatusPill />
      </div>
      <div key="right">
        <ContractNavbarButton />
      </div>
    </div>
    <div className={`${styles.navHeaderContractTabs}`}>
      <NavbarMenu />
      <ActivityLogButton />
    </div>
  </nav>
);

const BackButton: view = ({
  // setCurrentPath = update.routes.currentPath,
  currentQuery = observe.routes.currentQuery,
  updateReroute = update.routes.triggers.reroute,
  clearAll = update.contract,
  parentContractId = observe.contract.original.parentContractId,
  getContracts = update.pages.dashboard.triggers.getContracts,
}) => (
  <button
    className="mr-3 relative focus:outline-none border-focus"
    onClick={() => {
      // setCurrentPath.set(parentContractId ? `/contract/${parentContractId}` : "/");
      router.navigate(
        (parentContractId ? `/contract/${parentContractId}` : "/") +
        (currentQuery || ""));
      updateReroute.set(Date.now());
      getContracts.set(Date.now());
      clearAll.remove();
    }}>
    <BackArrow />
  </button>
);

const ActivityLogButton: view = ({
  showModal = update.modal.activityLog,
  id = observe.contract.id,
  status = observe.contract.original.state.status,
}) => {
  const isDraft = status === ContractStatus.DRAFT;

  if (isDraft) return null;
  return (
    <div onClick={() => showModal.set(Date.now())} className="flex items-center cursor-pointer">
      {i18n.t("contract_navbar.activity_log")}
      <SVGs.ActivityLog className="ml-2" />
    </div>
  );
};
