import i18n from "src/producers/languages/i18n";
import { ContractPDFClause } from "src/pages/Contract/subviews/ContractForm/ContractPDFClause/ContractPDFClause";
import { RentalPremiseClause } from "src/pages/Contract/subviews/ContractForm/RentalPremiseClause/RentalPremiseClause";
import { ContractDatesClause } from "src/pages/Contract/subviews/ContractForm/ContractDatesClause/ContractDatesClause";
import { PartiesClause } from "src/pages/Contract/subviews/ContractForm/PartiesClause/PartiesClause";

import { FreeTextGeneralClause } from "src/pages/Addendum/subviews/AddendumForm/FreeTextGeneralClause";
import styles from "../../../styles.module.css";
import { ClauseCard } from "../ClauseCard/ClauseCard";
import { InsideCard } from "../ClauseCard/InsideCard";

export const PaperContract: view = () => (
  <div className={`${styles.contractsContainer}`}>
    <div>
      <ClauseCard title={i18n.t("contract_page.contract_pdf_clause_h")}>
        <InsideCard>
          <ContractPDFClause />
        </InsideCard>
      </ClauseCard>
      <FreeTextGeneralClause varName="otherAddendums" title={i18n.t("contract_page.other_addendums_clause_h")} />
      <ClauseCard title={i18n.t("contract_page.contract_dates_clause_h")}>
        <InsideCard>
          <ContractDatesClause />
        </InsideCard>
      </ClauseCard>
      <ClauseCard title={i18n.t("contract_page.contract_premise_clause_h")}>
        <InsideCard>
          <RentalPremiseClause />
        </InsideCard>
      </ClauseCard>
      <PartiesClause title={i18n.t("contract_page.contract_tenant_clause_h")} />
    </div>
  </div>
);
