import { notification } from 'antd'
import { db } from 'src/producers/firebase'
import { Contract, UserRole, ApplicationError, ErrorLevel, ErrorType } from 'src/types'

export const setFilterTag: producer = async ({
  trigger = observe.pages.dashboard.triggers.setFilterFlag,
  updateTrigger = update.pages.dashboard.triggers.setFilterFlag,
  updateDashboardContracts = update.pages.dashboard.contracts.data,
  dashboardContracts = get.pages.dashboard.contracts.data,
  user = get.user,
  error = update.errors[param.id],
}) => {
  user = user.value()
  if (!trigger || !trigger.contractId || user.data.role != UserRole.MANAGER) {
    return
  }
  dashboardContracts = dashboardContracts.value()
  try {
    const filterContracts = dashboardContracts.filter((contract: Contract) => trigger.contractId !== contract.id)
    updateDashboardContracts.set(filterContracts)
    await db
      .collection('contracts')
      .doc(String(trigger.contractId))
      .set(
        {
          filterTag: trigger.tag,
          filterTagSetBy: {
            uid: user.uid,
            name: `${user.data.displayName || ''}`,
          },
        },
        { merge: true }
      )

    notification.info({
      message: `${trigger.contractId} marked as ${trigger.tag}`,
    })
  } catch (e) {
    updateDashboardContracts.set(dashboardContracts)

    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'setFilterTag' }
    )
  } finally {
    updateTrigger.remove()
  }
}
