import { User, Contract } from "src/types";

export interface DashboardViewModel {
  currentUser: User; // TODO: remove this and use the user from root state once thats cleaned up
  selectedTab: DashboardTabs;
  selectedView: DashboardViews;
  contracts: Contract[];
  filteredTenants: {
    filters: {
      [type: string]: any;
    };
    data: {
      [id: string]: any;
    };
  };
  triggers: {
    [key: string]: any;
  };
  filters: {
    [k in DashboardFilters]: any;
  };
  sort: DashboardSortDirection;
}

export enum DashboardTabs {
  ALL_CONTRACTS = "all_contracts",
  DRAFTS = "drafts",
  OFFERS = "offers",
  SIGNING = "signing",
  ACTIVE = "active",
  ARCHIVED = "archived",
  LOST = "lost",
  TRASHED = "trashed",
}

export enum DashboardViews {
  GRID = "grid",
  LIST = "list",
}

export enum DashboardFilters {
  LOCATION = "location",
  CLIENT = "client",
  RENTAL_END = "rental_end",
}

export enum DashboardSortDirection {
  ASC = "asc",
  DESC = "desc",
}
