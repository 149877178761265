import React from "react";

import { UserRole } from "src/types";

export const Input: view = ({
  label,
  placeholder,
  extraClasses,
  inputType,
  onChange,
  defaultValue,
  value,
  disabled,
  isReadOnlyFieldForTenent,
  hiddenFromTenantIfEmpty,
  isReadOnlyContract = observe.legacyContract.isReadOnlyContract,
  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  const isReadOnly = disabled || isReadOnlyContract || (isReadOnlyFieldForTenent && isTenant);
  const isHidden = hiddenFromTenantIfEmpty && isTenant && !defaultValue;
  if (isHidden) return null;
  return (
    <div className={`my-3 w-1/2 ${extraClasses}`}>
      {label && <label className="block text-gray-700 mb-2">{label}</label>}
      <input
        className={`shadow-sm appearance-none border klp-border1 rounded w-full p-2 text-gray-700 leading-tight ${
          isReadOnly ? "bg-gray-200" : ""
        }`}
        placeholder={placeholder}
        type={inputType}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        readOnly={isReadOnly}
      />
    </div>
  );
};
