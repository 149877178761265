import { Tooltip } from "antd";
import { Button, Switch } from "@c11/components";
import "./AttachmentCard.css";
import styles from "../../../styles.module.css";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { DownloadIcon, RecycleBinIcon } from "src/assets/svg";
import {
  ThumbnailCard,
  ThumbnailCardBody,
  ThumbnailCardFooter,
  ThumbnailCardHeader,
  TooltipWrapper,
} from "src/components";
import { DragAndDropHandle } from "src/pages/Contract/subviews/Attachments/DragAndDropHandle/DragAndDropHandle";

export const AttachmentCard = ({ connectDrag, connectDrop, connectDragPreview, isDragging, ...props }) => {
  const {
    fileName,
    fileType,

    selected,
    hasButtomControlls,
    canModifyAttachment,
    canDeleteAttachments,
    pendingDownload,
    pendingPreview,
    setDeleteAppendixModal,
    includeInSigningToggle,
    updateTriggerDownload,
    updateTriggerPreview,
  } = props.attachment || {};

  return (
    <div ref={connectDrop}>
      <div className="group" key={`prev-${fileName}`} ref={connectDragPreview}>
        {canModifyAttachment && (
          <div className="hidden group-hover:flex" ref={connectDrag}>
            <DragAndDropHandle />
          </div>
        )}
        <ThumbnailCard
          index={props.index + 1}
          className={`w-266px ${hasButtomControlls ? "h-64" : "h-56"} ${styles.attachmentCard}`}>
          <ThumbnailCardHeader className={`${styles.attachmentCardHeader}`}>
            <div>{fileType == "application/pdf" ? <SVGs.AppendixCardPDF2 /> : <SVGs.AppendixCardImage2 />}</div>
          </ThumbnailCardHeader>
          <ThumbnailCardBody className="px-2">
            <TooltipWrapper text={fileName} disabled={fileName?.length < 20}>
              <div className="w-52">
                <p className="font-semibold truncate">{fileName}</p>
              </div>
            </TooltipWrapper>

            <div className="flex mt-3 px-2 gap-2 items-center">
              {/* <Button
                variant="secondary"
                isLoading={pendingPreview}
                className={`${pendingPreview ? "" : "icon-btn border-secondary"} second-theme-color uppercase`}
                onClick={() => updateTriggerPreview()}>
                {!pendingPreview && <span className="ml-2">Preview</span>}
              </Button> */}
              <Button
                variant="secondary"
                isLoading={pendingDownload}
                className={`${pendingDownload ? "" : "icon-btn border-secondary"} second-theme-color ${
                  styles.downloadAttachment
                }`}
                onClick={() => updateTriggerDownload()}>
                {!pendingDownload && (
                  <>
                    {/* <DownloadIcon /> */}
                    <span className="ml-2">{i18n.t("attachments_page.button_download")}</span>
                  </>
                )}
              </Button>
              {canDeleteAttachments && (
                <Button
                  variant="secondary"
                  className={`icon-btn ${styles.removeAttachment}`}
                  onClick={() => setDeleteAppendixModal()}>
                  <RecycleBinIcon />
                </Button>
              )}
            </div>
          </ThumbnailCardBody>
          {hasButtomControlls && (
            <ThumbnailCardFooter className={`${styles.attachmentCardFooter}`}>
              <div className={`${styles.attachmentSwitchLabel}`}>
                <p>{i18n.t("attachments_page.card_toggle_text")}</p>
                <Tooltip placement={"bottom"} title={i18n.t("attachments_page.card_tooltip_text")}>
                  <SVGs.HelpCircleGray />
                </Tooltip>
              </div>
              <div className="ml-auto flex">
                <Switch
                  className={`secondary-switch-${selected ? "checked" : "unchecked"}`}
                  isChecked={selected}
                  isDisabled={!canModifyAttachment}
                  onClick={() => includeInSigningToggle()}
                />
              </div>
            </ThumbnailCardFooter>
          )}
        </ThumbnailCard>
      </div>
    </div>
  );
};
