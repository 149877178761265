import { functions } from "src/producers/firebase";
import { CommandActions } from "src/types/commands/command";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const updateParty: producer = async ({
  trigger = observe.contract.triggers.updateParty,
  parties = get.contract.original.state.parties,
  updateTrigger = update.contract.triggers.updateParty,
  contractId = get.contract.id,
  error = update.errors[param.id],
}) => {
  if (!trigger) return;
  console.log("trigger", trigger);

  const oldValues = parties.value()[trigger.partyId];
  let updatedValues = {} as any;
  Object.keys(trigger).forEach((key) => {
    if (trigger[key] !== oldValues[key]) updatedValues[key] = trigger[key];
  });
  delete updatedValues["user"];
  updatedValues["partyId"] = trigger.partyId;

  contractId = contractId.value();
  try {
    const commandPublisher = functions.httpsCallable("eventlessCommandHandler");

    const command = {
      ...updatedValues,
      action: CommandActions.UPDATE_PARTY,
      contractId,
    };
    await commandPublisher(command);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.LOW,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "updateParty-contract" }
    );
  } finally {
    updateTrigger.remove();
  }
};
