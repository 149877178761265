import Big from "big.js";

export const totalArea: producer = ({
  propertyExclusiveArea = observe.legacyContract.values.propertyExclusiveArea.displayedValue,
  propertyCommonArea = observe.legacyContract.values.propertyCommonArea.displayedValue,

  oldValue = get.legacyContract.values.propertyTotalArea.displayedValue,

  propertyTotalArea = update.legacyContract.currentSession.changes.propertyTotalArea,
}) => {
  if (!propertyExclusiveArea || !propertyCommonArea) {
    return;
  }
  oldValue = oldValue.value();
  const value = new Big(propertyCommonArea).plus(new Big(propertyExclusiveArea)).toNumber();
  if (oldValue == value) {
    return;
  }
  propertyTotalArea.set({
    value: value,
    createdAt: Date.now(),
    custom: false,
  });
};
