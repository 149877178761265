import React from 'react'
import { db } from 'src/producers/firebase'
import { router, routes } from 'src/router'
import { matchRoutes, useLocation } from 'react-router-dom'

export const getContractProd: producer = async ({
  updateRevision = update.routes.contractRevision,
  updatePathParam = update.routes.pathParam,
  firebaseIsReady = observe.session.firebase.isReady,
  currentRoute = observe.routes.current,
}) => {
  const pathParam = currentRoute?.matches?.[0]?.params?.id
  if (!pathParam || !firebaseIsReady) return
  const contract = (await db.collection('contracts').doc(pathParam).get()).data()
  const revision = contract?.revision || contract?.original?.revision || contract?.originalV2?.revision
  updateRevision.set(revision)
  updatePathParam.set(pathParam)
  return
}
