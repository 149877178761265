import React from "react";

export const TenantCPDropdownRow: view = ({
  CP,
  index,
  setSelectedCP = update.contract.temp.selectedCP,
  populateContactPerson = update.contract.triggers.populateContactPerson,
}) => {
  if (!CP) return null;

  return (
    <div
      onClick={() => {
        if (true) {
          setSelectedCP.set(index);
          populateContactPerson.set(Date.now());
        }
      }}
      className="flex justify-between items-center mx-2">
      <div>
        <p key="1" className=" text-left font-medium">{`${CP.firstName ? CP.firstName : " "} ${
          CP.lastName ? CP.lastName : ""
        }`}</p>
        <p key="2" className="font-12 text-left text-gray-600">
          {CP.email && <span>{CP.email} </span>}
        </p>
      </div>
    </div>
  );
};
