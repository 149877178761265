import { Button } from "@c11/components";
import "./SavePaperContractButton.css";

import i18n from "src/producers/languages/i18n";
import { FloppyIcon } from "src/assets/svg";
import { TooltipWrapper } from "src/components";
import { saveContract } from "./SavePaperContractButton.saveContract";

export const SavePaperContractButton: view = ({
  triggerSaveContract = update.pages.contract.triggers.savePaperContract,
  currentTenantId = observe.contract.original.state.tenant.id,
  invalidFields = observe.contract.invalidFields,
  tenantEmail = observe.contract.values.tenantEmail,
}) => {
  const hasInvalidFields = !!invalidFields && Object.keys(invalidFields).length != 0;
  const isDisabled = !currentTenantId || hasInvalidFields || !tenantEmail;
  const tooltipText =
    !currentTenantId || !tenantEmail
      ? i18n.t("contract_navbar.button_send_contract_tooltip_no_tenantid")
      : hasInvalidFields
      ? i18n.t("contract_navbar.button_send_contract_tooltip_invalid_fields")
      : null;

  return (
    <TooltipWrapper disabled={!isDisabled} text={tooltipText} placement="bottom">
      <Button
        className="save-contract-btn uppercase"
        variant="primary"
        isDisabled={isDisabled}
        onClick={() => triggerSaveContract.set(true)}>
        {i18n.t("contract_navbar.button_save_contract")}
        <FloppyIcon className="ml-2" />
      </Button>
    </TooltipWrapper>
  );
};

SavePaperContractButton.producers([saveContract]);
