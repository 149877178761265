import { ApplicationError, ContractSigningMethod, ContractType, ErrorLevel, ErrorType } from 'src/types'
import { db } from 'src/producers/firebase'
import { ContractFlow, ContractStatus, ContractTemplate } from 'src/pages/ContractV2/contract.types'
import { get, isNull, isUndefined, omitBy } from 'lodash'
import moment from 'moment'
import i18n from 'src/producers/languages/i18n'
import dayjs from 'dayjs'
import { router } from 'src/router'

const INHERITED_FIELDS = [
  'landlordName',
  'landlordOrgNo',
  'tenantName',
  'tenantOrgNo',
  'leaseDate',
  'shoppingMall',
  'tenantContactPersonFirstName',
  'tenantContactPersonLastName',
  'tenantPhone',
  'tenantEmail',
  'tenantOrgNo',
  'propertyAddress',
  'municipality',
  'parcelNumber',
  'propertyLocalNo',
  'propertyLevel',
  'propertyExclusiveArea',
  'propertyCommonArea',
] as const

const DATE_FIELDS = ['leaseDate'] as const

const getAddendumTitle = (template: string) => {
  switch (template) {
    case ContractTemplate.STORAGE:
      return i18n.t('contract.addendums.storage_addendum')
    case ContractTemplate.CANCELLATION:
      return i18n.t('contract.addendums.cancellation_addendum')
    case ContractTemplate.DOWNPAYMENT:
      return i18n.t('contract.addendums.downpayment_addendum')
    case ContractTemplate.RENTAL_PERIOD_PRICE:
      return i18n.t('contract.addendums.change_price_period_addendum')
    case ContractTemplate.TRANSPORT:
      return i18n.t('contract.addendums.transport_addendum')
  }
}

const parseDefaultValues = (values: any, fields: any) => {
  for (const field of Object.keys(values)) {
    const value = values[field].value
    values[field].value = value.replace(/{{(.*?)}}/g, (match: any, p1: any) => fields[p1.trim()]?.value || '')
  }
  return values
}

const INHERITED_PROPERTIES = ['building', 'tenant'] as const
const getInheritedData = (fields: any, contract: any) => {
  let inheritedFields: any = INHERITED_FIELDS.reduce((acc, curr) => {
    if (!fields[curr]?.current) return acc
    acc[curr] = { value: fields[curr]?.current.value }
    return acc
  }, {} as { [key in (typeof INHERITED_FIELDS)[number]]: any })

  let inheritedProperties: any = INHERITED_PROPERTIES.reduce((acc, curr) => {
    let value = contract[curr]
    if (curr === 'tenant' && value?.mainContacts) {
      if (!Array.isArray(value.mainContacts)) value.mainContacts = Object.values(value.mainContacts)
    }
    acc[curr] = value
    return acc
  }, {} as { [key in (typeof INHERITED_PROPERTIES)[number]]: any })

  inheritedFields.originalTenantName = { value: inheritedFields.tenantName?.value }
  inheritedFields = omitBy(inheritedFields, isNull)
  inheritedFields = omitBy(inheritedFields, isUndefined)

  inheritedProperties = omitBy(inheritedProperties, isNull)
  inheritedProperties = omitBy(inheritedProperties, isUndefined)
  return {
    inheritedFields,
    inheritedProperties,
  }
}

export const createAddendum: producer = async ({
  user = get.user.value(),
  contractId = get.contract.id.value(),
  contract = get.contract.original.state.value(),
  fields = get.contract.original.state.fields.value(),
  defaultValues = get.defaultValues[param.template],

  // setCurrentPath = update.routes.currentPath,
  currentQuery = observe.routes.currentQuery,
  updateReroute = update.routes.triggers.reroute,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  error = update.errors[param.id],

  trigger = observe.contract.triggers.createAddendum,
  removeTrigger = update.contract.triggers.createAddendum,
}) => {
  if (!trigger) return
  if (!trigger?.template) return
  if (!contract) return
  if (!contractId) return
  if (!fields) return
  if (!user) return
  if (Object.keys(fields).length < 1) return

  const { inheritedFields, inheritedProperties } = getInheritedData(fields, contract)
  const inheritedData = { ...inheritedFields, ...inheritedProperties }

  let reformattedInheritedFields = {...inheritedFields}; 
  if(trigger.template === ContractTemplate.CANCELLATION){
    for (const dateField of DATE_FIELDS) {
      const fieldValue = reformattedInheritedFields[dateField].value;
    
      if (fieldValue) {
        reformattedInheritedFields[dateField].value = dayjs(fieldValue).format('DD/MM/YYYY'); 
      }
    }
  }
  const defaultValuesForTemplate = parseDefaultValues(defaultValues.value({ template: trigger.template }) || {}, reformattedInheritedFields)
  const contractTitle = getAddendumTitle(trigger.template)

  let addendum = {
    id: `Addendum-${moment().format('DD-MM-YY_HH-mm-SSS')}`,
    parentContractId: contractId,
    template: trigger.template,
    flow: ContractFlow.DIGITAL,
    title: contractTitle,

    handler: user.uid,
    status: ContractStatus.DRAFT,
    signing: ContractSigningMethod.DIGITAL,
    contractType: ContractType.DIGITAL,
    createdAt: new Date().toISOString(),

    inheritedData,

    fields: {
      ...defaultValuesForTemplate,
      ...inheritedFields,
      title: { value: contractTitle },
    },
    defaultValues: defaultValuesForTemplate,
    tenant: inheritedProperties.tenant,
    revision: 4,
  }

  addendum = omitBy(addendum, isNull) as typeof addendum
  addendum = omitBy(addendum, isUndefined) as typeof addendum

  try {
    isSaving.set(true)

    await db
      .collection('contracts')
      .doc(addendum.id)
      .set({
        ...addendum,
      })

    successfulSave.set(true)
    // setCurrentPath.set(`/contract_v1/${addendum.id}`)
    router.navigate(`/contract/${addendum.id}` + (currentQuery || ''))
    updateReroute.set(Date.now())

  } catch (e) {
    console.error('ERROR: ', e)
    error.set(
      {
        message: 'error-creating-addendum',
      } as ApplicationError,
      { id: 'create-new-addendum' }
    )
  } finally {
    removeTrigger.remove()

    isSaving.set(false)
  }
}

// import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
// import { CommandActions } from 'src/types/commands/command'
// import { functions } from 'src/producers/firebase'

// export const createAddendum: producer = async ({
// trigger = observe.contract.triggers.createAddendum,
// removeTrigger = update.contract.triggers.createAddendum,
// setCurrentPath = update.routes.currentPath,
// contractId = get.contract.id,
// error = update.errors[param.id]
// }) => {
//   if (!trigger || !trigger?.addendumType || !contractId.value()) return
//   try {
//     const command = {
//       action: CommandActions.CREATE_ADDENDUM,
//       parentContractId: contractId.value(),
//       addendumType: trigger.addendumType,
//     }
//     console.log('command', command)
//     const commandPublisher = functions.httpsCallable('eventlessCommandHandler')
//     const state = await commandPublisher(command)
//     console.log('state', state)
//     // const resp = await axios.post(
//     //   getFunctionsUrl('commandPublisher', localFunctions.value()),
//     //   command,
//     //   {
//     //     headers: {
//     //       'Authorization': 'Bearer ' + token.value(),
//     //       'Content-Type': 'application/json'
//     //     }
//     //   }
//     // )
//     // const state = resp.data
//     setCurrentPath.set(`/addendum/${state.data.id}`)
//   } catch (e) {
//     console.error('ERROR: ', e)
//     error.set(
//       {
//         message: e.message,
//         level: ErrorLevel.BREAKING,
//         type: ErrorType.SERVER,
//       } as ApplicationError,
//       { id: 'createAddendum-contract' }
//     )
//   } finally {
//     removeTrigger.remove()
//   }
// }
