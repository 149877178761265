import { path } from "@c11/engine.runtime";

import i18n from "src/producers/languages/i18n";

import { CalculationFieldAllUsers } from "src/pages/Contract/subviews/ContractForm/RentalPropertyClause/CalculationFieldAllUsers";
import { InsideCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/InsideCard";
import {
  FreeTextFieldGeneral,
  NumberInputGeneral,
  RegularInputGeneral,
} from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import styles from "../../../../../../Contract/styles.module.css";

export const RentalPropertyManager: view = ({}) => {
  const _path = path.contract;
  return (
    <ClauseCard title={`2. ${i18n.t("clause_rental_property_title")}`}>
      <div className={`${styles.contractCard}`}>
        <InsideCard key="Property" title={i18n.t("clause_rental_property_property_title")}>
          <RegularInputGeneral
            varName="shoppingMall"
            placeholder={i18n.t("clause_rental_property_property_placeholder_shopping_mall")}
            extraClasses="pr-8"
            label={i18n.t("clause_rental_property_property_shopping_mall")}
          />
          <RegularInputGeneral
            varName="propertyAddress"
            placeholder={i18n.t("clause_rental_property_property_placeholder_address")}
            label={i18n.t("clause_rental_property_property_address")}
          />
          <RegularInputGeneral
            varName="municipality"
            placeholder={i18n.t("clause_rental_property_property_placeholder_municipality")}
            extraClasses="pr-8"
            label={i18n.t("clause_rental_property_property_municipality")}
          />
          <RegularInputGeneral
            varName="parcelNumber"
            placeholder={i18n.t("clause_rental_property_property_parcel_number_p")}
            label={i18n.t("clause_rental_property_property_parcel_number")}
          />
          <RegularInputGeneral
            varName="propertyLocalNo"
            placeholder={i18n.t("clause_rental_property_property_placeholder_local_no")}
            extraClasses="pr-8 w-1/4 self-end"
            label={i18n.t("clause_rental_property_property_local_no")}
          />
          <RegularInputGeneral
            varName="propertyLevel"
            placeholder={i18n.t("clause_rental_property_property_placeholder_level")}
            extraClasses="w-1/4 pr-8 self-end"
            label={i18n.t("clause_rental_property_property_level")}
          />
          <NumberInputGeneral
            varName="propertyExclusiveArea"
            placeholder={i18n.t("clause_rental_property_property_placeholder_property_exclusive_area")}
            extraClasses="pr-8 w-1/4 self-end"
            label={i18n.t("clause_rental_property_property_property_exclusive_area")}
          />
          <NumberInputGeneral
            varName="propertyCommonArea"
            placeholder={i18n.t("clause_rental_property_property_placeholder_propery_common_area")}
            extraClasses="w-1/4 self-end"
            label={i18n.t("clause_rental_property_property_propery_common_area")}
          />
          <CalculationFieldAllUsers
            key="8"
            label={i18n.t("clause_rental_property_total_areal")}
            readPath={_path.values.propertyTotalArea.displayedValue}
          />
        </InsideCard>
        <InsideCard key="storage rent" title={`2.2 ${i18n.t("storage_addendum.conditionAndUseStorage")}`}>
          <div className="w-full">
            <FreeTextFieldGeneral varName="conditionAndUseStorage" />
          </div>
        </InsideCard>
      </div>
    </ClauseCard>
  );
};
