import i18n from "src/producers/languages/i18n";

import { DateFieldGeneral } from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import styles from "../../../../../styles.module.css";

export const RentalPeriodTenant: view = ({
  rentalPeriodOptionsPeriod = observe.contract.values.rentalPeriodOptionsPeriod.displayedValue,
  rentalPeriodHasOptionsDV = observe.contract.values.rentalPeriodHasOptions.displayedValue,
  rentalPeriodHasOptionsFromChanges = observe.contract.currentSession.changes.rentalPeriodHasOptions.value,
  rentalPeriodEorRDV = observe.contract.values.rentalPeriodEorR.displayedValue,
  rentalPeriodEorRFromChanges = observe.contract.currentSession.changes.rentalPeriodEorR.value,
}) => {
  const rentalPeriodHasOptions = rentalPeriodHasOptionsFromChanges || rentalPeriodHasOptionsDV;
  const rentalPeriodEorR = rentalPeriodEorRFromChanges || rentalPeriodEorRDV;
  const hasPotencialOptions = rentalPeriodHasOptions === "true";
  return (
    <div>
      <div className={`${styles.datesRow}`}>
        <div className="flex flex-col w-64">
          <span>
            <span className="text-pink-600">*</span>
            {i18n.t("clause_rental_period_rental_start")}
          </span>
          <DateFieldGeneral varName="rentalStart" placeholder={i18n.t("placeholders.select_date")} readOnly />
        </div>
        <div className="flex flex-col w-64">
          <span>
            <span className="text-pink-600">*</span>
            {i18n.t("clause_rental_period_rental_end")}
          </span>
          <DateFieldGeneral varName="rentalEnd" placeholder={i18n.t("placeholders.select_date")} readOnly />
        </div>
      </div>
      {hasPotencialOptions && (
        <p key="p3" className="pb-2 pt-1">
          <span key="0">
            {i18n.t("rentalPeriodOptionsPeriod_p1_1", {
              ER:
                rentalPeriodEorR === "R"
                  ? i18n.t("clause_rental_period_potential_options_renewal")
                  : i18n.t("clause_rental_period_potential_options_extension"),
            })}
          </span>
          <strong key="1">
            {rentalPeriodOptionsPeriod && i18n.t(rentalPeriodOptionsPeriod) + i18n.t("rentalPeriodOptionsPeriod_p1_2")}
          </strong>
          <span key="2">
            {i18n.t("rentalPeriodOptionsPeriod_p1_3", {
              ER:
                rentalPeriodEorR === "R"
                  ? i18n.t("clause_rental_period_potential_options_renewal")
                  : i18n.t("clause_rental_period_potential_options_extension"),
              T:
                rentalPeriodEorR === "R"
                  ? i18n.t("clause_rental_period_potential_options_renegotiation")
                  : i18n.t("clause_rental_period_potential_options_same"),
            })}
          </span>
        </p>
      )}
    </div>
  );
};
