import React from "react";

import { CreateVersionButton } from "./CreateVersionButton";
import { ChangesNotification } from "./ChangesNotification";
import { ContractStatus } from "src/types";
import { ReturnToCurrentVersion } from "./ReturnToCurrentVersion";

export const ActionButtons: view = ({
  changes = observe.legacyContract.currentSession.changes,
  status = observe.legacyContract.original.status,
  displayedVersion = observe.legacyContract.displayedVersion,
}) => {
  const isntDraft = status !== ContractStatus.DRAFT;
  const hasChanges = !!changes && Object.keys(changes).length > 0;
  if (displayedVersion) return <ReturnToCurrentVersion />;
  if (isntDraft)
    return (
      <div className="flex flex-wrap">
        <CreateVersionButton hasChanges={hasChanges} />
        {hasChanges && <ChangesNotification />}
      </div>
    );
  return null;
};
