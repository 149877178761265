import i18n from "src/producers/languages/i18n";
import { DownArrowOutlineIcon, EditIcon, PersonSilhouetteIcon, RecycleBinIcon, UpArrowOutlineIcon } from "src/assets/svg";
import { VisibleIf } from "src/components_generic/visible-if";
import { Button } from "@c11/components.button";
import { Divider } from "antd";
import styles from "../../styles.module.css"

export type PartiesListElement = {
  index: number,
  name: string,
  email: string,

  isTenant?: boolean,
  isLandlord?: boolean,
  isSigner?: boolean,
  isReviewer?: boolean,
  isLocked?: boolean,

  isFirstElement?: boolean,
  isLastElement?: boolean,

  onEdit?: () => void
  onDelete?: () => void
  onReorderUpClick?: () => void
  onReorderDownClick?: () => void
};

export const PartiesListElement = ({
  index, name, email,
  isTenant, isLandlord, isSigner, isReviewer, isLocked,
  isFirstElement, isLastElement,
  onEdit, onDelete, onReorderUpClick, onReorderDownClick
}: PartiesListElement) => {
  return <div className={styles["cards-list"]}>
    <div className={styles["cards-tab-list-item"]}>
      <div className={styles["cards-tab-list-item-index"]}>
        <div className="font-semibold text-center">{index}.</div>
        <div className="ant-divider ant-divider-vertical border h-8 mx-4"></div>
      </div>
      <div className="flex-grow flex flex-row items-center">
        <div className="flex-grow w-1/4 flex flex-row items-center justify-center">
          <VisibleIf predicate={!!isTenant}>
            <PersonSilhouetteIcon width="25" height="25" className="text-green-500 mr-4" />
          </VisibleIf>
          <VisibleIf predicate={!!isLandlord}>
            <PersonSilhouetteIcon width="25" height="25" className="text-pink-500 mr-4" />
          </VisibleIf>
          <div className="flex-grow flex flex-col">
            <div className={styles["list-name-element"]}>{name}</div>
            <div className="parties-tab-person-email-text">{email}</div>
          </div>
        </div>
        <div className={styles["cards-info-row"]}>
          <div className={styles["cards-info"]}>
            <VisibleIf predicate={!!isTenant}>
              <div className="text-green-500 font-medium">{i18n.t("general.tenant")}</div>
            </VisibleIf>

            <VisibleIf predicate={!!isLandlord}>
              <div className="text-pink-500 font-medium">{i18n.t("general.landlord")}</div>
            </VisibleIf>

            <VisibleIf predicate={!isTenant && !isLandlord}>
              <div className="text-red-500 font-medium">No title selected</div>
            </VisibleIf>
          </div>

          <div className={styles["cards-info"]}>
              <VisibleIf predicate={!!isSigner}>
                <div className="text-green-500 font-medium">{i18n.t("parties_page.action_sign")}</div>
              </VisibleIf>
              <VisibleIf predicate={!!isReviewer}>
                <div className="text-pink-500 font-medium">{i18n.t("parties_page.action_approve")}</div>
              </VisibleIf>
              <VisibleIf predicate={!isSigner && !isReviewer}>
                <div className="text-red-500 font-medium">No role selected</div>
              </VisibleIf>
            </div>
            <div className={styles["cards-actions-row"]}>
                {/* <h5 className="parties-tab-list-item-subtitle">Actions</h5> */}
                <div className="flex-grow w-full flex flex-row items-center justify-end gap-1">
                  <VisibleIf predicate={!isLocked}>
                  <Button
                    variant="ghost"
                    className={styles["list-element-button"]}
                    onClick={() => {
                      if (!onEdit) return;
                      onEdit();
                    }}>
                    <EditIcon className="text-gray-600"/>
                  </Button>
                  </VisibleIf>

                  <VisibleIf predicate={!isLocked}>
                  <Button
                    variant="ghost"
                    className={styles["list-element-button"]}
                    onClick={() => {
                      if (!onDelete) return;
                      onDelete();
                    }}>
                    <RecycleBinIcon className="text-gray-600 " />
                  </Button>
                  </VisibleIf>
                </div>

                <VisibleIf predicate={!(isFirstElement && isLastElement) && !isLocked}>
                  <div className="flex flex-row">
                    <Divider type="vertical" className="h-9 border mr-4 ml-2" />
                      <div className="flex flex-col">
                        <VisibleIf predicate={!isFirstElement}>
                          <Button className={styles["list-element-button"] + " " + styles["reorder"]} variant="ghost" onClick={() => {
                            if (!onReorderUpClick) return;
                            onReorderUpClick();
                          }}>
                            <UpArrowOutlineIcon className="text-gray-400" />
                          </Button>
                        </VisibleIf>

                        <VisibleIf predicate={!isLastElement}>
                          <Button className={styles["list-element-button"] + " " + styles["reorder"]} variant="ghost" onClick={() => {
                            if (!onReorderDownClick) return;
                            onReorderDownClick();
                          }}>
                            <DownArrowOutlineIcon className="text-gray-400" />
                          </Button>
                        </VisibleIf>
                      </div>
                    </div>
                </VisibleIf>
            </div>
          </div>
        </div>
    </div>
  </div>
}
