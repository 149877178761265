import { ContractStatus, UserRole } from "src/types";

export const setReadOnly: producer = ({
  status = observe.legacyContract.original.status,
  isReadOnlyContract = update.legacyContract.isReadOnlyContract,
  displayedVersion = observe.legacyContract.displayedVersion,
  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  if ((status === ContractStatus.SIGNING && isTenant) || displayedVersion) {
    isReadOnlyContract.set(true);
  } else {
    isReadOnlyContract.remove();
  }
};
