import { Switch } from "@c11/components";
import { SVGs } from "src/assets";
import { VisibleIf } from "src/components_generic/visible-if";

import i18n from "src/producers/languages/i18n";
import { ContractSigningMethod } from "src/types";

export const DigitalSigningSwitch: view = ({
  isLocked = observe.contract.isLocked,
  signing = observe.contract.originalv2.signing,
  updateSingingMethodTrigger = update.contract.triggers.parties.updateSigningMethod,
}) => {
  const backgroundClassName = isLocked ? 'bg-gray-200' : '';

  return <div className={`flex flex-row items-center mx-14 px-8 py-3 digital-signing-switch-container ${backgroundClassName}`}>
    <div className="flex flex-row items-center font-bold gap-2">
      <SVGs.DocumentChecked />
      <div>{i18n.t("parties_page.digital_signing_label")}</div>
      <div className="bg-gray-300"
        style={{
          width: "2px",
          height: "20px",
          margin: "0px 15px",
        }}
      />
    </div>

    <div className="flex-grow flex flex-col">
      <VisibleIf predicate={signing === ContractSigningMethod.DIGITAL}>
      <div>
        Recipients will sign the contract <span className="font-bold underline">digitally</span> with Verified.
      </div>
      </VisibleIf>

      <VisibleIf predicate={signing === ContractSigningMethod.PAPER}>
        <div>
          Recipients will sign the contract <span className="font-bold underline">in person</span>
        </div>
        <div className="text-red-600 inline-block">
          Usually this process takes longer and will require the contract's upload
        </div>
      </VisibleIf>
    </div>

    <div className="flex items-center justify-center">
      <Switch
        className={`secondary-switch-${signing === ContractSigningMethod.DIGITAL ? "checked" : "unchecked"}`}
        isDisabled={!!isLocked}
        // isLoading={loading}
        isChecked={signing === ContractSigningMethod.DIGITAL}
        onClick={() => updateSingingMethodTrigger.set(true)}
      />
    </div>
  </div>
}
