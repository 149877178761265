import logo from "./img/KLP_logo.png";
import mall from "./img/mall.jpg";
import building from "./img/building.png";

import { ReactComponent as BackArrow } from "./icons/back_arrow.svg";
import { ReactComponent as WhiteGearIcon } from "./icons/gear.svg";
import { ReactComponent as GreyGearIcon } from "./icons/gear_grey.svg";
import { ReactComponent as WhiteTickIcon } from "./icons/white_tick.svg";
import { ReactComponent as DownloadIconGray } from "./icons/download_icon_gray.svg";
import { ReactComponent as DownloadIconLightGray } from "./icons/download_icon_light_gray.svg";
import { ReactComponent as DownloadIconBlack } from "./icons/download_icon_black.svg";
import { ReactComponent as DownloadIconPink } from "./icons/download_icon_pink.svg";
import { ReactComponent as DraftIcon } from "./icons/draft_icon.svg";
import { ReactComponent as NegotiationIcon } from "./icons/negotiation_icon.svg";
import { ReactComponent as PenOutlineWhite } from "./icons/pen_outline_white.svg";
import { ReactComponent as PenOutlineGray } from "./icons/pen_outline_gray.svg";
import { ReactComponent as CancelIcon } from "./icons/cancel_circle_active.svg";
import { ReactComponent as TickIconActive } from "./icons/tick_circle_active.svg";
import { ReactComponent as TickIconDisabled } from "./icons/tick_circle_disabled.svg";
import { ReactComponent as TickIconApproved } from "./icons/tick_circle_status.svg";
import { ReactComponent as CancelIconActive } from "./icons/cancel_circle_active.svg";
import { ReactComponent as CancelIconDisabled } from "./icons/cancel_circle_disabled.svg";
import { ReactComponent as CancelIconDeclined } from "./icons/cancel_circle_status.svg";
import { ReactComponent as InfoIcon } from "./icons/info_circle.svg";
import { ReactComponent as LockIcon } from "./icons/lock_icon.svg";
import { ReactComponent as GlobeIcon } from "./icons/globe.svg";
import { ReactComponent as FinalizedIcon } from "./icons/contract_finalized.svg";
import { ReactComponent as NewDocumentIcon } from "./icons/new_document.svg";
import { ReactComponent as Message } from "./icons/message.svg";
import { ReactComponent as Page } from "./icons/page.svg";
import { ReactComponent as AppendixPage } from "./icons/appendix_page.svg";
import { ReactComponent as AppendixPlus } from "./icons/appendix_plus.svg";
import { ReactComponent as PlusUser } from "./icons/plus_user.svg";
import { ReactComponent as Rollback } from "./icons/rollback.svg";
import { ReactComponent as DropdownArrow } from "./icons/dropdown_arrow_full.svg";
import { ReactComponent as VersionDropdownArrowLine } from "./icons/versionDropdownArrowLine.svg";
import { ReactComponent as Eye } from "./icons/eye.svg";
import { ReactComponent as PdfFile } from "./icons/pdfFile.svg";
import { ReactComponent as ExclamationCircleFilled } from "./icons/exclamation_circle_filled.svg";
import { ReactComponent as ExclamationCircleUnfilled } from "./icons/exclamation_circle_unfilled.svg";
import { ReactComponent as PaperAirplaneWhite } from "./icons/paper_airplane_white.svg";
import { ReactComponent as PaperAirplaneGray } from "./icons/paper_airplane_gray.svg";
import { ReactComponent as PaperAirplanePink } from "./icons/paper_airplane_pink.svg";
import { ReactComponent as DownloadContractPage } from "./icons/download_contract_page.svg";
import { ReactComponent as GenerateContractGearPage } from "./icons/generate_contract_gear_page.svg";
import { ReactComponent as ContractLockedPage } from "./icons/tenant_contract_locked_page.svg";
import { ReactComponent as ModalGenerateContract } from "./icons/modal_generate_contract.svg";
import { ReactComponent as ModalPlane } from "./icons/modal_plane.svg";
import { ReactComponent as ModalRemoveChanges } from "./icons/modal_remove_changes.svg";
import { ReactComponent as ModalEdit } from "./icons/modal_edit.svg";
import { ReactComponent as NavPinkPaper } from "./icons/nav_pink_paper.svg";
import { ReactComponent as Question } from "./icons/question.svg";
import { ReactComponent as AddApendix } from "./icons/add_appendix.svg";
import { ReactComponent as RecycleBin } from "./icons/recycle_bin.svg";
import { ReactComponent as RecycleBinUser } from "./icons/recycle_bin_user.svg";
import { ReactComponent as ModalCancel } from "./icons/modal_cancel.svg";
import { ReactComponent as WelcomeEditing } from "./icons/welcome_editing.svg";
import { ReactComponent as WelcomeMessages } from "./icons/welcome_messages.svg";
import { ReactComponent as WelcomeVersions } from "./icons/welcome_versions.svg";
import { ReactComponent as SigningTree } from "./icons/signing_tree.svg";
import { ReactComponent as SigningWarning } from "./icons/signing_warning.svg";
import { ReactComponent as ThreeDots } from "./icons/three_dots.svg";
import { ReactComponent as HelpCircle } from "./icons/help_circle.svg";
import { ReactComponent as HelpCircleLight } from "./icons/help_circle_light.svg";
import { ReactComponent as ActivityLog } from "./icons/change_log.svg";
import { ReactComponent as SignButton } from "./icons/sign_button.svg";
import { ReactComponent as HelpBigBlack } from "./icons/help_circle_big_black.svg";
import { ReactComponent as HelpBigGray } from "./icons/help_circle_big_gray.svg";
import { ReactComponent as HelpCircleGray } from "./icons/help_circle_gray.svg";
import { ReactComponent as UserBanner } from "./icons/user_banner.svg";
import { ReactComponent as NotificationsBell } from "./icons/notifications_bell.svg";
import { ReactComponent as OverviewAttachments } from "./icons/overview_attachments.svg";
import { ReactComponent as OverviewAttachmentsButton } from "./icons/overview_attachments_button.svg";
import { ReactComponent as OverviewContract } from "./icons/overview_contract.svg";
import { ReactComponent as OverviewParties } from "./icons/overview_parties.svg";
import { ReactComponent as OverviewPartiesButton } from "./icons/overview_parties_button.svg";
import { ReactComponent as UpploadAppendix } from "./icons/uppload_appendix.svg";
import { ReactComponent as AppendixCardImage } from "./icons/appendix_card_image.svg";
import { ReactComponent as AppendixCardImage2 } from "./icons/appendix_card_image2.svg";
import { ReactComponent as AppendixCardPDF } from "./icons/appendix_card_pdf.svg";
import { ReactComponent as AppendixCardPDF2 } from "./icons/appendix_card_pdf2.svg";
import { ReactComponent as PartiesCheckmark } from "./icons/parties_checkmark.svg";
import { ReactComponent as PartiesxX } from "./icons/parties_x.svg";
import { ReactComponent as PartiesEdit } from "./icons/parties_edit.svg";
import { ReactComponent as AddParty } from "./icons/add_party.svg";
import { ReactComponent as NoParty } from "./icons/no_party.svg";
import { ReactComponent as ManagerIcon } from "./icons/manager_icon.svg";
import { ReactComponent as TenantIcon } from "./icons/tenant_icon.svg";
import { ReactComponent as ContractAction } from "./icons/contract_action.svg";
import { ReactComponent as OverviewDocument } from "./icons/overview_document.svg";
import { ReactComponent as OverviewPartySign } from "./icons/overview_party_sign.svg";
import { ReactComponent as PlusCircleWhite } from "./icons/plus_circle_white.svg";
import { ReactComponent as DocumentGray } from "./icons/document_gray.svg";
import { ReactComponent as DocumentMulticolored } from "./icons/document_multicolored.svg";
import { ReactComponent as DropdownArrowOutline } from "./icons/dropdown_arrow_outline.svg";
import { ReactComponent as DragIcon } from "./icons/drag.svg";
import { ReactComponent as DocumentChecked } from "./icons/document_checked.svg";
import { ReactComponent as DocumentCheckedWhite } from "./icons/document_checked_white.svg";
import { ReactComponent as GridIconPink } from "./icons/grid_icon_pink.svg";
import { ReactComponent as GridIconGray } from "./icons/grid_icon_gray.svg";
import { ReactComponent as ListIconPink } from "./icons/list_icon_pink.svg";
import { ReactComponent as ListIconGray } from "./icons/list_icon_gray.svg";
import { ReactComponent as ContractStatusDraft } from "./icons/contract_status_draft.svg";
import { ReactComponent as ContractStatusNegotiation } from "./icons/contract_status_negotiation.svg";
import { ReactComponent as ContractStatusActive } from "./icons/contract_status_active.svg";
import { ReactComponent as ContractStatusSigning } from "./icons/contract_status_signing.svg";
import { ReactComponent as ContractStatusTrashed } from "./icons/contract_status_trashed.svg";
import { ReactComponent as ContractStatusLost } from "./icons/contract_status_lost.svg";
import { ReactComponent as ContractStatusArchived } from "./icons/contract_status_archived.svg";
import { ReactComponent as SortArrowDown } from "./icons/sort_arrow_down.svg";
import { ReactComponent as SortArrowUp } from "./icons/sort_arrow_up.svg";
import { ReactComponent as CheckmarkCircleFilled } from "./icons/checkmark_circle_filled.svg";
import { ReactComponent as RightArrowIcon } from "./icons/right_arrow.svg";
import { ReactComponent as ReloadIcon } from "./icons/reload.svg";

export const Images = { logo, mall, building };
export const SVGs = {
  AppendixPage,
  AppendixPlus,
  BackArrow,
  WhiteGearIcon,
  GreyGearIcon,
  WhiteTickIcon,
  DownloadIconGray,
  DownloadIconLightGray,
  DownloadIconBlack,
  DownloadIconPink,
  DraftIcon,
  NegotiationIcon,
  PenOutlineWhite,
  PenOutlineGray,
  CancelIcon,
  TenantIcon,
  ManagerIcon,
  TickIconActive,
  TickIconDisabled,
  TickIconApproved,
  CancelIconActive,
  CancelIconDisabled,
  CancelIconDeclined,
  InfoIcon,
  LockIcon,
  GlobeIcon,
  FinalizedIcon,
  NewDocumentIcon,
  Message,
  Page,
  Rollback,
  ActivityLog,
  DropdownArrow,
  VersionDropdownArrowLine,
  Eye,
  PdfFile,
  ExclamationCircleFilled,
  ExclamationCircleUnfilled,
  PaperAirplaneWhite,
  PaperAirplaneGray,
  PaperAirplanePink,
  DownloadContractPage,
  GenerateContractGearPage,
  ModalGenerateContract,
  ModalPlane,
  NavPinkPaper,
  Question,
  AddApendix,
  RecycleBin,
  RecycleBinUser,
  PlusUser,
  ModalCancel,
  ContractLockedPage,
  ModalRemoveChanges,
  ModalEdit,
  WelcomeEditing,
  WelcomeMessages,
  WelcomeVersions,
  SigningTree,
  SigningWarning,
  ThreeDots,
  HelpCircle,
  HelpCircleLight,
  HelpBigBlack,
  HelpBigGray,
  HelpCircleGray,
  UserBanner,
  SignButton,
  NotificationsBell,
  OverviewAttachments,
  OverviewAttachmentsButton,
  OverviewContract,
  OverviewParties,
  OverviewPartiesButton,
  UpploadAppendix,
  AppendixCardImage,
  AppendixCardImage2,
  AppendixCardPDF,
  AppendixCardPDF2,
  PartiesCheckmark,
  PartiesxX,
  PartiesEdit,
  AddParty,
  NoParty,
  ContractAction,
  OverviewDocument,
  OverviewPartySign,
  PlusCircleWhite,
  DocumentGray,
  DocumentMulticolored,
  DropdownArrowOutline,
  DragIcon,
  DocumentChecked,
  DocumentCheckedWhite,
  GridIconGray,
  GridIconPink,
  ListIconGray,
  ListIconPink,
  ContractStatusDraft,
  ContractStatusNegotiation,
  ContractStatusActive,
  ContractStatusSigning,
  ContractStatusTrashed,
  ContractStatusLost,
  ContractStatusArchived,
  SortArrowDown,
  SortArrowUp,
  CheckmarkCircleFilled,
  RightArrowIcon,
  ReloadIcon
};
