import { Button } from '@c11/components'
import './SavePaperContractButton.css'

import i18n from 'src/producers/languages/i18n'
import { FloppyIcon } from 'src/assets/svg'
import { TooltipWrapper } from 'src/components'
import { saveContract } from './SavePaperContractButton.saveContract'
import { validateData } from 'src/pages/ContractV2/producers'
import { validateDataSelections } from '../../producers/validations/validateDataSelections'
import { AddendumTemplates } from '../../contract.types'

export const SavePaperContractButton: view = ({
  triggerSaveContract = update.pages.contract.triggers.savePaperContract_v2,
  currentTenantId = observe.contract.originalv2.tenant.id,
  invalidFields = observe.contract.invalidFields,
  tenantEmail = observe.contract.originalv2.fields.tenantEmail,
  hasInvalidFields = observe.contract.hasInvalidFields,
  updateInvalidFields = update.contract.invalidFields[param.varName],
  removeAllInvalidFields = update.contract.invalidFields,
  state = get.contract.originalv2,
  errorNotification = update.errors[param.id],
}) => {
  // const hasInvalidFields = !!invalidFields && Object.keys(invalidFields).length != 0;
  const stateValue = state.value()
  if (!stateValue) return null
  const isAddendum = AddendumTemplates.includes(stateValue.template)
  const validate = (state: any) => {
    console.log('FIELDS: ', state.fields)
    return (
      validateData(state.fields, state.template, updateInvalidFields, removeAllInvalidFields) &&
      (isAddendum || validateDataSelections(state.tenant, state.building, errorNotification, updateInvalidFields))
    )
  }
  let tooltipText =
    !currentTenantId || !tenantEmail
      ? i18n.t('contract_navbar.button_send_contract_tooltip_no_tenantid')
      : hasInvalidFields
      ? i18n.t('contract_navbar.button_send_contract_tooltip_invalid_fields')
      : null

  if (hasInvalidFields) tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_invalid_fields')
  let isDisabled = !currentTenantId || hasInvalidFields || !tenantEmail
  if (!isDisabled) {
    if (stateValue?.fields?.endDate?.value < stateValue?.fields?.startDate?.value) {
      isDisabled = true
      tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_invalid_dates')
    }
    if(new Date(stateValue?.fields?.leaseDate?.value) > new Date()) {
      isDisabled = true
      tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_invalid_lease_date')
    }
  }
  // const isValid = validate(state.value())
  // console.log('isValid', isValid)
  return (
    <TooltipWrapper disabled={!isDisabled} text={tooltipText} placement="bottom">
      <Button
        className="save-contract-btn uppercase"
        variant="primary"
        isDisabled={isDisabled}
        onClick={() => {
          // const isValid = validate(state.value())
          // console.log('isValid', isValid)
          triggerSaveContract.set(true)
          // if (isValid) {
          //   updateHasInvalidFields.set(false)
          // } else {
          //   updateHasInvalidFields.set(true)
          //   updateTriggerNotifications.set(true)
          // }
        }}>
        {i18n.t('contract_navbar.button_save_contract')}
        <FloppyIcon className="ml-2" />
      </Button>
    </TooltipWrapper>
  )
}

SavePaperContractButton.producers([saveContract])
