import React from "react";

import { FactoryClause } from "./clauses";

export const MainContract: view = ({ sortedClauses = observe.legacyContract.template.sections.mainContract.clauseIds }) => {
  if (!sortedClauses) return null;
  const clausesList = sortedClauses.map((clauseID: any) => {
    return <FactoryClause key={clauseID} clauseID={clauseID} />;
  });
  return <>{clausesList}</>;
};
