import { UserRole } from "src/types";
import i18n from "src/producers/languages/i18n";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import styles from "../../../../styles.module.css";
import { RentalObjectManager } from "./RentalObjectManager";
import { RentalObjectReadOnly } from "./RentalObjectReadOnly";

export const RentalObject: view = ({
  userRole = observe.user.data.role,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
}) => {
  const isManager = userRole === UserRole.MANAGER;
  return (
    <ClauseCard title={`1. ${i18n.t("parking_contract.rental_object")}`}>
      <div className={`${styles.contractCard}`}>
        {isManager && !isReadOnlyContract ? <RentalObjectManager /> : <RentalObjectReadOnly />}
      </div>
    </ClauseCard>
  );
};
