import React from "react";
import { path } from "@c11/engine.runtime";

import { UserRole } from "src/types";
import { ShoppingMallsDropdownRow } from "./ShoppingMallsDropdownRow";

import { SearchDropdown } from "../../../../../components/SearchDropdown/SearchDropdown";
import styles from 'src/pages/Dashboard/styles.module.css'
import { SearchSelect } from 'src/components/SearchSelect/SearchSelect'
import i18n from 'src/producers/languages/i18n'
import { SearchIcon } from 'src/assets/svg'

export const ShoppingMallsDropdown: view = ({
  shoppingMalls = observe.domain.shoppingMalls,
  shoppingMallId = observe.contract.original.state.building.id,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  if (!shoppingMalls || isTenant) return null;

  const selectedSM = shoppingMalls?.[shoppingMallId];
  return (
    <div className="my-2">
      {isReadOnlyContract ? (
        <ShoppingMallsDropdownRow SM={selectedSM} />
      ) : (
        <div className="relative flex w-full" id="shoppingMallsDropdown">
          <div className="w-1/2">
            {selectedSM ? (
              <ShoppingMallsDropdownRow SM={selectedSM} />
            ) : (
              <div className=" flex justify-center items-center"></div>
            )}
          </div>
          <div className="w-1/2">
            <SearchDropdown
              id="shoppingMallsDropdown"
              menuItem={(item: any) => <ShoppingMallsDropdownRow SM={item} />}
              itemsPath={path.domain.shoppingMalls}
              fuseOptions={{
                keys: ["name"],
              }}
            />
          </div>

          {/*<div className={`${styles.filterContainer}`}>*/}
          {/*  <SearchSelect*/}
          {/*    items={shoppingMalls}*/}
          {/*    placeholder={i18n.t("dashboard.filter_location_default")}*/}
          {/*    searchOptions={{ keys: ["name"] }}*/}
          {/*    onSearch={(ev) => console.log(`searchDropdown.onSearch: event.target = ${JSON.stringify(ev.target)}`)}*/}
          {/*    onSelect={(ev) => {*/}
          {/*      console.log(`searchDropdown.onSelect: event.target = ${JSON.stringify(ev.target)}`);*/}
          {/*      const selectedLocation = ev.target.value;*/}
          {/*      if (currentValue?.id !== selectedLocation?.id) {*/}
          {/*        updateCurrentValue.set(selectedLocation);*/}
          {/*      } else {*/}
          {/*        updateCurrentValue.remove();*/}
          {/*      }*/}
          {/*      // TODO: maybe find a better way to trigger get contracts*/}
          {/*      triggerGetContracts.set(Date.now());*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <SearchIcon />*/}
          {/*</div>*/}
        </div>
      )}
    </div>
  );
};
