import { db } from "src/producers/firebase";

export const updateRecipients: producer = ({
  contractId = get.contract.id,
  recipients = observe.contract.recipients,
}) => {
  if (!recipients) recipients = {};

  const contractDb = db.collection("contracts").doc(contractId.value());
  contractDb.update({ recipients: recipients }).catch((err: any) => console.error(err));
};
