import { db } from 'src/producers/firebase'
import {
  ContractFilterOption,
  ContractStatus,
  ApplicationError,
  ErrorLevel,
  DashboardFilters,
  DashboardTabs,
  UserRole,
  ErrorType,
  KLPDepartments,
  AgreementType, Department,
} from 'src/types'

export const getContracts: producer = async ({
  trigger = observe.pages.dashboard.triggers.getContracts,
  updateTrigger = update.pages.dashboard.triggers.getContracts,
  updateContractsData = update.pages.dashboard.contracts[param.path],
  contractsData = get.pages.dashboard.contracts,
  dashboardTab = get.pages.dashboard.selectedTab,
  dashboardFilters = get.pages.dashboard.filters,
  user = get.user,
  error = update.errors[param.id],
}) => {
  if (!trigger) return

  user = user.value()
  dashboardTab = dashboardTab.value()
  contractsData = contractsData.value()
  dashboardFilters = dashboardFilters.value()

  updateContractsData.remove({ path: 'end' })

  const noOfFetchContracts = 20
  const mergeToExistingContract = trigger === 'scroll'

  let contractDb: any = db.collection('managerDashboard')
  let { selectedTenantId } = trigger

  // TODO: find a better way to filter for tenant
  if (user?.data?.role === UserRole.TENANT) {
    selectedTenantId = user?.data?.selectedTenantId
    contractDb = db.collection('clientDashboard')

    if (!selectedTenantId) {
      throw new Error('Cannot load data without a tenantId')
    }

    contractDb = contractDb.where('tenantId', '==', selectedTenantId)
  }

  // FILTERING
  // tab filter
  if (dashboardTab === DashboardTabs.ALL_CONTRACTS) {
    // FIXME: temporary fix for missing prop on prod contracts
  } else if (dashboardTab === DashboardTabs.DRAFTS) {
    contractDb = contractDb.where('status', '==', ContractStatus.DRAFT)
  } else if (dashboardTab === DashboardTabs.OFFERS) {
    contractDb = contractDb.where('status', '==', ContractStatus.NEGOTIATION)
  } else if (dashboardTab === DashboardTabs.SIGNING) {
    contractDb = contractDb.where('status', '==', ContractStatus.SIGNING)
  } else if (dashboardTab === DashboardTabs.ACTIVE) {
    contractDb = contractDb.where('status', '==', ContractStatus.ACTIVE)
  } else if (dashboardTab === DashboardTabs.ARCHIVED) {
    contractDb = contractDb.where('filterTag', '==', ContractFilterOption.ARCHIVED)
  } else if (dashboardTab === DashboardTabs.LOST) {
    contractDb = contractDb.where('filterTag', '==', ContractFilterOption.LOST)
  } else if (dashboardTab === DashboardTabs.TRASHED) {
    contractDb = contractDb.where('filterTag', '==', ContractFilterOption.TRASHED)
  }

  // location filter
  if (dashboardFilters?.[DashboardFilters.LOCATION]) {
    console.log('location', dashboardFilters?.[DashboardFilters.LOCATION])
    contractDb = contractDb.where('summary.shoppingMall', '==', dashboardFilters[DashboardFilters.LOCATION].name)
    console.log('location', dashboardFilters?.[DashboardFilters.LOCATION].name)
  }

  // client filter
  if (dashboardFilters?.[DashboardFilters.CLIENT] && user?.data?.role === UserRole.MANAGER) {
    const id = Number(dashboardFilters[DashboardFilters.CLIENT].id)
    console.log('client', id)
    contractDb = contractDb.where('tenantId', '==', id)
  }

  // department filter
  if (user?.data?.departmentId && user?.data?.role === UserRole.MANAGER) {
    console.log('departmentId', user?.data?.departmentId)
    switch (user?.data?.departmentId) {
      case KLPDepartments.OFFICE_BUILDINGS:
        // contractDb = contractDb.where('agreementType', '==', AgreementType.PARKING)
        contractDb = contractDb.where('department', '==', Department.OFFICE)
        break
      case KLPDepartments.SHOPPING_MALLS:
        // contractDb = contractDb.where('agreementType', '==', AgreementType.RENTAL)
        contractDb = contractDb.where('department', '==', Department.MALL)
        break
      default:
        break
    }
  }

  // ORDERING
  contractDb = contractDb.orderBy('createdAt', 'desc')

  // Add pagination
  if (contractsData?.lastDoc && mergeToExistingContract) {
    contractDb = contractDb.startAfter(contractsData.lastDoc)
  }
  contractDb = contractDb.limit(noOfFetchContracts)

  // Running the query
  try {
    const snaps = await contractDb.get()
    const contracts = snaps.docs.map((doc: any, index: any, array: any) => {
      const lastDocument = array.length - 1 === index

      if (lastDocument) {
        updateContractsData.set(doc, { path: 'lastDoc' })
      }
      return doc.data()
    })
    // console.log('contracts', contracts)
    if (mergeToExistingContract) {
      if (contracts?.length < noOfFetchContracts) updateContractsData.set(true, { path: 'end' })

      updateContractsData.set([...contractsData.data, ...contracts], { path: 'data' })
    } else {
      updateContractsData.set(contracts, { path: 'data' })
    }
  } catch (e) {
    console.error('Error getting contracts', e)
    updateContractsData.set([], { path: 'data' })
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'getContracts' }
    )
  }

  updateTrigger.remove()
}
