import styles from "../../styles.module.css"

export const HeaderSection: view = ({ title, subtitle, children }) => {
  return (
    <div className={`${styles.contractHeader}`}>
      <div>
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      <div className={`${styles.contractHeaderButtons}`}>{children}</div>
    </div>
  );
};
