// workaround for the fact that KLP doesnt have actually anything on departments on their systems
export enum KLPDepartments {
  SHOPPING_MALLS = '1',
  OFFICE_BUILDINGS = '2',
}

export enum Department {
  MALL = 'mall',
  OFFICE = 'office',
}
