import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const setUserDept: producer = async ({
  trigger = observe.pages.dashboard.triggers.setUsetDept,
  removeTrigger = update.pages.dashboard.triggers.setUsetDept,
  user = get.user,
  error = update.errors[param.id],
}) => {
  user = user.value();
  if (!trigger) {
    return;
  }

  try {
    console.log("setUserDept producer");
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "createAgreement" }
    );
  } finally {
    removeTrigger.remove();
  }
};
