import { ApplicationError, ErrorLevel, NotificationTypes } from "src/types";
import { db } from "src/producers/firebase";

interface Email {
  to?: string;
  from?: string;
  subject?: string;
  data?: any;
}

export const sendEmail: producer = async ({
  contractId = observe.legacyContract.id,
  trigger = observe.legacyContract.triggers.sendEmail,
  removeTrigger = update.legacyContract.triggers.sendEmail,
  contactFirstName = get.legacyContract.values.tenantContactPersonFirstName.displayedValue,
  contactLastName = get.legacyContract.values.tenantContactPersonLastName.displayedValue,
  contactEmail = get.legacyContract.values.tenantEmail.displayedValue,
  managerEmail = get.legacyContract.values.landlordEmail.displayedValue,
  managerFirstName = get.legacyContract.values.landlordContactPersonFirstName.displayedValue,
  managerLastName = get.legacyContract.values.landlordContactPersonLastName.displayedValue,
  shoppingMall = get.legacyContract.values.shoppingMall.displayedValue,
  error = update.errors[param.type],
}) => {
  if (!trigger) {
    return;
  }
  removeTrigger.remove();
  try {
    let name: string;
    let email: string;
    const tenantName = `${contactFirstName.value() || ""} ${contactLastName.value() || ""}`;
    const managerName = `${managerFirstName.value() || ""} ${managerLastName.value() || ""}`;
    switch (trigger.type) {
      case NotificationTypes.NEW_VERSION:
      case NotificationTypes.TENANT_INVITATION:
      case NotificationTypes.TENANT_SIGN_LEGACY:
        name = tenantName;
        email = contactEmail.value();
        break;
      default:
        return Promise.reject("Type not found!");
    }
    if (!name || !email) throw "Name or email not provided!";
    const sendEmailsEvent = {
      name: name,
      email: email,
      managerName: managerName,
      contractId: contractId,
      type: trigger.type,
      shoppingMall: shoppingMall.value() || "",
    };
    console.log("✉ sendEmailsEvent 2", sendEmailsEvent);
    await db.collection("sendEmails").add(sendEmailsEvent);
  } catch (err) {
    console.error("sendEmailsEvent error", err);
    error.set({ message: "sendEmailsEvent error", level: ErrorLevel.LOW, details: err } as ApplicationError, {
      type: "values",
    });
  }
};
