import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType, UserRole } from "src/types";

export const syncSessions: producer = ({
  id = observe.contract.id,
  user = get.user,

  setSessions = update.contract.sessions[param.sessionId],
  setListener = update.listeners[arg.id].sessions,
  error = update.errors[param.id],
}) => {
  if (!id) {
    return;
  }
  user = user.value();
  try {
    const listener = db
      .collection("contracts")
      .doc(id)
      .collection("sessions")
      .onSnapshot((sessionsSnap: any) => {
        for (const change of sessionsSnap.docChanges()) {
          const data = change.doc.data();
          if (change.type === "removed") {
            setSessions.remove({ sessionId: change.doc.id });
          } else {
            if (!data || user.data.role === UserRole.TENANT && data.role === UserRole.MANAGER) return;
            setSessions.set(data, { sessionId: change.doc.id });
          }
        }
      });

    setListener.set(listener);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncSessions" }
    );
  }
};
