import React from "react";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";

export const WelcomeContractCard: view = ({
  triggerDismiss = update.legacyContract.triggers.dismissWelcomeCard,
  onboarded = observe.user.onboarded,
}) => {
  if (onboarded) return null;
  return (
    <div className="p-4">
      <div style={{ flexGrow: 1 }} key="card" className="bg-white border klp-border1 p-6 rounded-sm">
        <div className="flex justify-between items-start">
          <h3 key="title" className="font-bold text-xl mb-4 mr-5">
            {i18n.t("sidebar.welcome_card_title")}
          </h3>
          <button onClick={() => triggerDismiss.set(Date.now())} className="text-pink-600 font-12 ">
            {i18n.t("sidebar.welcome_card_dismiss")}
          </button>
        </div>

        <p className="pb-4 mb-4 border-b border-gray-200">{i18n.t("sidebar.welcome_card_text_1")}</p>
        <h4 className="font-bold mt-4 mb-2 flex items-center">
          <SVGs.WelcomeEditing className="mr-2" />
          {i18n.t("sidebar.welcome_card_editing_title")}
        </h4>
        <p className="">{i18n.t("sidebar.welcome_card_editing_p1")}</p>
        <h4 className="font-bold mt-4 mb-2 flex items-center">
          <SVGs.WelcomeMessages className="mr-2" />
          {i18n.t("sidebar.welcome_card_messages_title")}
        </h4>
        <p className="">{i18n.t("sidebar.welcome_card_messages_p1")}</p>
      </div>
    </div>
  );
};
