import React from "react";

import { LandlordSignatoryCard } from "./LandlordSignatoryCard";
import { TenantSignatoryCard } from "./TenantSignatoryCard";

export const Signatories: view = ({
  numberOfLandlordSignatures = observe.legacyContract.values.numberOfLandlordSignatures.displayedValue,
  numberOfTenantSignatures = observe.legacyContract.values.numberOfTenantSignatures.displayedValue,
  setNumberOfLandlordSignatures = update.legacyContract.currentSession.changes.numberOfLandlordSignatures,
  setNumberOfTenantSignatures = update.legacyContract.currentSession.changes.numberOfTenantSignatures,
}) => {
  // if there is no signatories set them to one
  if (!numberOfLandlordSignatures) {
    numberOfLandlordSignatures = 1;
  }
  if (!numberOfTenantSignatures) {
    numberOfTenantSignatures = 1;
  }
  const landlordsArray = Array.from(Array(numberOfLandlordSignatures).keys());
  const landlordsList = landlordsArray.map((i) => {
    const isLast = landlordsArray.length === i + 1;
    return (
      <LandlordSignatoryCard
        key={i}
        i={i}
        onPlusOneClick={() =>
          setNumberOfLandlordSignatures.set({ value: numberOfLandlordSignatures + 1, createdAt: Date.now() })
        }
        onRemoveClick={() =>
          setNumberOfLandlordSignatures.set({ value: numberOfLandlordSignatures - 1, createdAt: Date.now() })
        }
        isLast={isLast}
      />
    );
  });

  const tenantsArray = Array.from(Array(numberOfTenantSignatures).keys());
  const tenantsList = tenantsArray.map((i) => {
    const isLast = tenantsArray.length === i + 1;
    return (
      <TenantSignatoryCard
        key={i}
        i={i}
        onPlusOneClick={() =>
          setNumberOfTenantSignatures.set({ value: numberOfTenantSignatures + 1, createdAt: Date.now() })
        }
        onRemoveClick={() =>
          setNumberOfTenantSignatures.set({ value: numberOfTenantSignatures - 1, createdAt: Date.now() })
        }
        isLast={isLast}
      />
    );
  });

  return (
    <>
      {landlordsList}
      {tenantsList}
    </>
  );
};
