import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";
import { ContractStatus, UserRole } from "src/types";

export const TenantInput: view = ({
  linkTenant = update.legacyContract.triggers.linkTenant,
  tenantIdTemp = observe.legacyContract.temp.tenantId,
  setTenantIdTemp = update.legacyContract.temp.tenantId,
  status = observe.legacyContract.original.status,
  loadingTenant = observe.legacyContract.flags.loadingTenant,

  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;

  if (status !== ContractStatus.DRAFT || isTenant) return null;

  return (
    <>
      <div className="w-1/2 pr-8 my-2" key="1">
        <label className="block text-gray-700 text-xs">
          <span>{i18n.t("clause_parties_tenant_id")}</span>
        </label>
        <input
          className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 text-gray-800 leading-tight focus:bg-green-300 focus:border-transparent`}
          key={8}
          placeholder={i18n.t("clause_parties_tenant_id_p")}
          type="text"
          onChange={(e) => setTenantIdTemp.set(e.target.value)}
          defaultValue={tenantIdTemp}
        />
      </div>

      <div className="self-end mb-2" key="2">
        <Button
          variant="primary"
          isDisabled={!!!tenantIdTemp}
          isLoading={loadingTenant}
          onClick={() => (tenantIdTemp ? linkTenant.set(tenantIdTemp) : null)}
          className="uppercase">
          {i18n.t("button_link_tenant")}
        </Button>
      </div>
    </>
  );
};
