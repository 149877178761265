import {
  PhoneField,
  RadioButton,
  RadioButtonGroup,
  TextField
} from '@c11/components'

import i18n from 'src/producers/languages/i18n'
import { PersonSilhouetteIcon, RightArrowCircleIcon } from 'src/assets/svg'
import { Modal } from 'src/components'
import { PartyType } from 'src/types'
import {
  TenantsDropdown
} from 'src/pages/Contract/subviews/Parties/TenandsDropdown/TenantsDropdown'
import {
  LandlordsDropdown
} from 'src/pages/Contract/subviews/Parties/LandlordsDropdown/LandlordsDropdown'
import {
  populatePartyForm
} from 'src/pages/Contract/subviews/Parties/PartyModal/PartyModal.populatePartyForm'
import {
  validatePartyForm
} from 'src/pages/Contract/subviews/Parties/PartyModal/PartyModal.validatePartyForm'
import {
  removeParty
} from 'src/pages/Contract/subviews/Parties/PartyModal/PartyModal.removeParty'
import {
  updateParty
} from 'src/pages/Contract/subviews/Parties/PartyModal/PartyModal.updateParty'
import {
  addParty
} from 'src/pages/Contract/subviews/Parties/PartyModal/PartyModal.addParty'

import styles from '../../../styles.module.css'
import { path } from '@c11/engine.runtime'

export const PartyModal: view = ({
  modalType = observe.modal.addEditPartyModal.type,
  selectedOption = observe.modal.addEditPartyModal.selectedPartyCP,
  modalData = observe.modal.addEditPartyModal.data,
  modalIsValid = observe.modal.addEditPartyModal.isValid,
  addPartyTrigger = update.contract.triggers.addParty,
  updatePartyTrigger = update.contract.triggers.updateParty,
  removePartyTrigger = update.contract.triggers.removeParty,
  setModalData = update.modal.addEditPartyModal.data[param.field],
  closeModal = update.modal.addEditPartyModal
}) => {
  if (!modalType) return null
  const modalTitle =
    modalType === 'edit' ? i18n.t('parties_page.modal_edit_participant') : i18n.t('parties_page.modal_add_participant')
  let { partyType, recipientAction, partyId } = modalData || {}

  function handleOKClick() {
    if (modalType === 'edit') {
      updatePartyTrigger.set(modalData)
    } else {
      addPartyTrigger.set(modalData)
    }
  }

  console.log('modalData position', modalData?.position)
  console.log('modalData', modalData)
  return (
    <Modal
      title={modalTitle}
      okButtonText={modalTitle}
      key={selectedOption}
      // eslint-disable-next-line react/jsx-no-bind
      onOkClick={handleOKClick}
      width={600}
      okButtonDisabled={modalIsValid !== true}
      leftButtonText={i18n.t('parties_page.modal_delete_participant')}
      leftButtonAction={() => {
        removePartyTrigger.set(partyId)
        closeModal.remove()
      }}>
      <div className={`${styles.partyModalCard}`}>
        <div className={`${styles.partyModalTitle}`}>
          <div role="row">
            <PersonSilhouetteIcon/>
            <h4 className="text-black">
              {i18n.t('parties_page.modal_participant_role')} <span
              className={`${styles.mendatoryField}`}>*</span>
            </h4>
          </div>
          <div role="row">
            <RadioButtonGroup className={`${styles.partyHeaderRadio}`}
                              selectedValue={String(partyType)}>
              <RadioButton
                className={`${styles.radioContainer}`}
                value={PartyType.TENANT}
                onClick={() => setModalData.set(PartyType.TENANT, { field: 'partyType' })}>
                <span className="ml-2">{i18n.t('general.tenant')}</span>
              </RadioButton>
              <RadioButton
                className={`${styles.radioContainer}`}
                value={PartyType.LANDLORD}
                onClick={() => setModalData.set(PartyType.LANDLORD, { field: 'partyType' })}>
                <span className="ml-2">{i18n.t('general.landlord')}</span>
              </RadioButton>
            </RadioButtonGroup>
          </div>
        </div>
        {partyType === PartyType.TENANT && <TenantsDropdown/>}
        {partyType === PartyType.LANDLORD && <LandlordsDropdown/>}
        <div className={`${styles.partyModalForm}`}>
          <div className="mb-5 w-1/2">
            <TextField
              isRequired
              // key={modalData?.firstName}
              path={path.modal.addEditPartyModal.data.firstName}
              label={`${i18n.t('parties_page.modal_first_name')} *`}
              placeholder={i18n.t('parties_page.modal_first_name')}
              // value={modalData?.firstName}
              // onChange={(ev) => {
              //   setModalData.set(ev.target.value, { field: "firstName" });
              // }}
            />
          </div>
          <div className="pl-4 mb-4 w-1/2">
            <TextField
              isRequired
              // key={modalData?.lastName}
              path={path.modal.addEditPartyModal.data.lastName}
              label={`${i18n.t('parties_page.modal_last_name')} *`}
              placeholder={i18n.t('parties_page.modal_last_name')}

            />
          </div>
          <div className="w-full mb-4">
            <TextField
              label={i18n.t('parties_page.modal_position')}
              placeholder={i18n.t('parties_page.modal_position')}
              path={path.modal.addEditPartyModal.data.position}
              // key={modalData?.position}
              // value={modalData?.position}
              // onChange={(ev) => {
              //   setModalData.set(ev.target.value, { field: "position" });
              // }}
            />
          </div>
          <div className="w-full mb-4">
            <TextField
              isRequired
              label={`${i18n.t('parties_page.modal_email')} *`}
              placeholder={i18n.t('parties_page.modal_email')}
              path={path.modal.addEditPartyModal.data.email}
              // key={modalData?.email}
              // value={modalData?.email}
              // onChange={(ev) => {
              //   setModalData.set(ev.target.value, { field: "email" });
              // }}
            />
          </div>
          <div className="w-1/2">
            <PhoneField
              label={i18n.t('parties_page.modal_phone')}
              placeholder={i18n.t('parties_page.modal_phone')}
              country="NO"
              //TODO - fix phone field
              // path={path.modal.addEditPartyModal.data.phone}
              // key={modalData?.phone}
              value={modalData?.phone}
              onChange={(ev) => {
                console.log('DEBUG PartyModal: PhoneField value:', ev?.target?.value)
                setModalData.set(`${ev.target?.countryInfo?.code}${ev.target?.value}`, { field: 'phone' })
              }}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.partyModalCard}`}>
        <div className="flex font-bold items-center justify-between">
          <div className="flex text-pink-600">
            <RightArrowCircleIcon/>
            <div className="mx-3">
              <span
                className="text-black">{i18n.t('parties_page.modal_required_action')}</span> *
            </div>
          </div>

          <RadioButtonGroup selectedValue={String(recipientAction)}
                            className="flex">
            <RadioButton
              value={'sign'}
              className={`${styles.radioContainer}`}
              onClick={() => setModalData.set('sign', { field: 'recipientAction' })}>
              <span className="ml-2">{i18n.t('parties_page.action_sign')}</span>
            </RadioButton>

            <RadioButton
              key={`ra-${'review'}`}
              className={`${styles.radioContainer}`}
              value={'review'}
              onClick={() => setModalData.set('review', { field: 'recipientAction' })}>
              <span
                className="ml-2">{i18n.t('parties_page.action_approve')}</span>
            </RadioButton>
          </RadioButtonGroup>
        </div>
      </div>
    </Modal>
  )
}

PartyModal.producers([populatePartyForm, validatePartyForm, removeParty, updateParty, addParty])
