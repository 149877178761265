import { ApplicationError, ContractSigningMethod, ErrorLevel, ErrorType } from 'src/types'
import { functions } from 'src/producers/firebase'
import { ContractStatus } from 'src/pages/ContractV2/contract.types'

export const moveToSigning: producer = async ({
  trigger = observe.contract.triggers.moveToSigning,
  removeTrigger = update.contract.triggers.moveToSigning,
  contractId = get.contract.id,
  error = update.errors[param.id],
  // stateTransition = update.contract.loading.stateTransition,
  isLoading = update.contract.loading.sendToSigning,
  contractSigningMethod = observe.contract.originalv2.signing,

  updateEnvelope = update.contract.originalv2.envelope,
  updateEnvelopeInfo = update.contract.originalv2.envelopeInfo,
  updateStatus = update.contract.originalv2.status
}) => {
  console.log('trigger', trigger)
  if (!trigger) return
  const options = trigger.payload
  Object.keys(options).forEach((key) => options[key] === undefined && delete options[key])
  contractId = contractId.value()
  // stateTransition.set(true)
  try {
    //TODO if changes in session, decline until resolved
    const payload: any = {
      signing: contractSigningMethod || '',
      contractId,
      options
    }
    isLoading.set(true)
    const moveToSigning = functions.httpsCallable('moveToSigning')
    const resp = await moveToSigning(payload)
    console.log('resp: ', resp)
    if (resp.data?.code && resp.data.code !== 200) throw (resp.data)
    if (contractSigningMethod === ContractSigningMethod.DIGITAL) {
      updateEnvelope.set(resp.data)
      updateEnvelopeInfo.set({ envelopeUid: resp.data.uid })
    }
    updateStatus.set(ContractStatus.SIGNING)
  } catch (e) {
    console.error('ERROR on moving to signing: ', e)
    error.set(
      {
        message: 'internal-server-error',
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER
      } as ApplicationError,
      { id: 'moveToSigning-contract' }
    )
  } finally {
    isLoading.set(false)
    removeTrigger.remove()
  }

}
