import { Button } from "@c11/components";
import { Divider } from "antd";
import "./ReorderPartyButton.css";

import { DownArrowOutlineIcon, UpArrowOutlineIcon } from "src/assets/svg";
import { reorderParties } from "src/pages/Contract/subviews/Parties/ReorderPartyButton/ReorderPartyButton.reorderParties";

export const ReorderPartyButton: view = ({
  party,
  index,
  parties = get.contract.original.state.parties,

  reorderParty = update.contract.triggers.reorderParty,
}) => {
  const totalParties = Object.values(parties.value())?.length || 0;
  const { order, partyId } = party;
  const isFirst = order === 0;
  const isLast = order === totalParties - 1;

  return (
    <div className="flex flex-col">
      <Button className={`icon-small-btn ${!isFirst && "second-theme-color"} btnGhostSimple`} variant="ghost" isDisabled={isFirst} onClick={() => reorderParty.set({ index, by: -1 })}>
        <UpArrowOutlineIcon />
      </Button>
      <Divider className="my-0 border w-3" />
      <Button className={`icon-small-btn ${!isLast && "second-theme-color"} btnGhostSimple`} variant="ghost" isDisabled={isLast} onClick={() => reorderParty.set({ index, by: 1 })}>
        <DownArrowOutlineIcon />
      </Button>
    </div>
  );
};

ReorderPartyButton.producers([reorderParties]);
