import { Button } from '@c11/components'
import './PDFDownloadButton.css'

import { DownloadIcon } from 'src/assets/svg'
import i18n from 'src/producers/languages/i18n'
import { functions } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'

export const PDFDownloadButton: view = ({
  pdfServerLoading = observe.contract.flags.loadingpdf,
  updatePdfServerLoading = update.contract.flags.loadingpdf,
  contractId = get.contract.originalv2.id,
  updateErrors = update.errors[param.id],
}) => {
  const triggerCreatePdfFunction = async () => {
    const ref = window.open()
    try {
      const viewPdf = functions.httpsCallable('previewContractPdf')
      updatePdfServerLoading.set(true)
      ref?.document.write('loading')
      const resp = await viewPdf({ contractId: contractId.value() })
      ref.location = resp.data
    } catch (error) {
      ref.close()
      console.error('Error on preview pdf: ', error)
      updateErrors.set(
        {
          message: 'preview-pdf-error',
          level: ErrorLevel.WARNING,
          type: ErrorType.SERVER,
        },
        { id: 'previewPDF' }
      )
    }
    updatePdfServerLoading.set(false)
  }
  return (
    <Button
      variant="secondary"
      className="download-contract-btn flex uppercase gap-2 mr-3"
      onClick={() => {
        triggerCreatePdfFunction()
      }}
      isDisabled={false}
      isLoading={pdfServerLoading}
    >
      <DownloadIcon/>
      <span>{i18n.t('contract_navbar.button_preview_pdf_contract')}</span>
    </Button>
  )
}
