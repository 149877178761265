import { storageRef, functions } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";
import { CommandActions } from "src/types/commands/command";

const RandExp = require("randexp");

const randomId = new RandExp(/[A-Za-z0-9_]{9}/);

export const uploadPaperContract: producer = async ({
  trigger = observe.contract.triggers.uploadPaperContract,
  removeTrigger = update.contract.triggers.uploadPaperContract,
  error = update.errors[param.id],
  contractId = get.contract.id,
}) => {
  //TODO simmilar to addAttachment
  contractId = contractId.value();

  if (!contractId || !trigger?.data?.name) {
    return;
  }
  const fileId = randomId.gen();

  //TODO move storage logic server side
  const data = {
    fileName: trigger.data.name,
    fileType: trigger.data.type,
    fileId,
    path: `${contractId}/signed/${fileId}`,
    domain: "user",
  };
  const fileRef = storageRef.child(`${contractId}/signed/${fileId}`);
  try {
    await fileRef.put(trigger.data, { contentType: trigger.data.type });
    const commandPublisher = functions.httpsCallable("eventlessCommandHandler");

    const command = {
      action: CommandActions.UPLOAD_PAPER_CONTRACT,
      contractId,
      ...data,
    };
    console.log("uploadPaperContract command", command);
    await commandPublisher(command);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "uploadPaperContract-contract" }
    );
  } finally {
    removeTrigger.remove();
  }
};
