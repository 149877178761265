import { Button } from '@c11/components.button'

import i18n from 'src/producers/languages/i18n'
import { linkTenantLegacy } from './TenantInput.linkTenantLegacy'

export const TenantInputText: view = ({
  tenantNumberTemp = observe.contract.temp.tenantNumber,
  status = observe.contract.original.state.status,
  tenantNumber = observe.contract.original.state.tenant.number,
  loadingTenant = observe.contract.flags.loadingTenant,
  setTenantNumber = update.contract.temp.tenantNumber,
  linkTenant = update.contract.triggers.linkTenant,

  userRole = get.user.data.role,
}) => {

  return (
    <>
      <div className="link-tenant-input w-1/2 my-2" key="1">
        <label
          className="block text-gray-700 text-xs">
          <span>{i18n.t("clause_parties_tenant_id")}</span>
        </label>
        <input
          className={`shadow-sm appearance-none border focus:outline-none klp-border1 w-full p-3 text-gray-800 leading-tight focus:bg-green-300 focus:border-transparent`}
          key={8}
          placeholder={i18n.t("clause_parties_tenant_id_p")}
          type="text"
          onChange={(e) => setTenantNumber.set(e.target.value)}
          defaultValue={tenantNumber}
        />
      </div>
      <div className="link-tenant-button self-end mb-2" key="2">
        <Button
          variant="primary"
          isDisabled={!!!tenantNumberTemp}
          isLoading={loadingTenant}
          onClick={() => (tenantNumberTemp ? linkTenant.set(tenantNumberTemp) : null)}
          className="uppercase">
          {i18n.t("button_link_tenant")}
        </Button>
      </div>
    </>
  );
};

TenantInputText.producers([linkTenantLegacy]);

