import { ContractStatus } from "src/types";

export const showReEditContractModal: producer = ({
  hasChanges = observe.contract.hasChanges,
  status = observe.contract.original.state.status,
  showModal = update.modal.reEditContract,
}) => {
  const isSigning = status === ContractStatus.SIGNING;
  if (hasChanges && isSigning) showModal.set(Date.now());
};
