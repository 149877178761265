import { Spinner } from "@c11/components";
import i18n from "src/producers/languages/i18n";

import styles from "../../styles.module.css";
import { ContractTemplate, ContractV2 } from "../../contract.types";
import { ContractName } from "./templates/parking/clauses/ContractName";
import { RentalPremise } from "./clauses/RentalPremise";
import { PartiesClause } from "./clauses/Parties";
import { RentalObject } from "./templates/parking/clauses/RentalObject";
import { RentalPeriod } from "./templates/parking/clauses/RentalPeriod";
import { RentalCosts } from "./templates/parking/clauses/RentalCosts";
import { LeaseConsistsOf } from "./templates/parking/clauses/LeaseConsistsOf";

export const ParkingContract: view = ({
  originalContract = observe.contract.originalv2,
}) => {
    if (!originalContract) return <Spinner className={`${styles.contractSpinner}`} />;
    if (originalContract.template !== ContractTemplate.PARKING) return <></>;

    return (<div className={`${styles.contract}`}>
          <div className={`${styles.innerContract} contract-centered`}>
          <ContractName />
          <RentalPremise />

          <PartiesClause />
          <RentalObject />
          <RentalPeriod />
          <RentalCosts />
          <LeaseConsistsOf />
        </div>

      </div>);
};
