import React from "react";

import { Card, InsideCard } from "../index";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { CheckboxType2 } from "../fields/formFields/CheckboxType2";
import { FactoryFormField } from "../fields/formFields/FactoryFormField";

export const RentalCosts: view = ({
  title,
  userRole = get.user.data.role,
  setPaymentProvisionFirstPayDueDate = update.legacyContract.currentSession.changes.paymentProvisionFirstPayDueDate,
  getPaymentProvisionFirstPayDueDate = observe.legacyContract.values.paymentProvisionFirstPayDueDate.displayedValue,
  getPaymentProvisionFirstPayDueDateFromChanges = observe.legacyContract.currentSession.changes
    .paymentProvisionFirstPayDueDate.value,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const i = getPaymentProvisionFirstPayDueDateFromChanges || getPaymentProvisionFirstPayDueDate;
  const checkedState = [i === 1, i === 2];

  function handleClick(c: any) {
    if (!checkedState[c] && isManager) setPaymentProvisionFirstPayDueDate.set({ value: c + 1, createdAt: Date.now() });
  }

  return (
    <Card title={title}>
      <InsideCard key="rent" title={i18n.t("clause_rental_costs_rent_title")}>
        <div className="w-1/2" key="0">
          <p key="p1" className="">
            {i18n.t("clause_rental_costs_rent_p1")}
          </p>
          <FactoryFormField key="0" fieldName="turnoverPercent" varName="turnoverPercent" />
        </div>
        <div className="w-1/2" key="1">
          <p key="p2" className="">
            {i18n.t("clause_rental_costs_rent_p2")}
          </p>
          <FactoryFormField key="1" fieldName="minimumRent" varName="minimumRent" />
        </div>
        <div className="w-full flex flex-wrap justify-end" key="2">
          <p className="w-1/2" key="0"></p>
          <p className="w-1/2" key="1">
            {i18n.t("clause_rental_costs_rent_p3")}
          </p>
          <FactoryFormField key="2" fieldName="MTO" varName="MTO" />
        </div>
      </InsideCard>
      <InsideCard key="marketing" title={i18n.t("clause_rental_costs_marketing_cost_title")}>
        <p key="p" className="pb-2">
          {i18n.t("clause_rental_costs_marketing_cost_p1")}
        </p>
        <FactoryFormField key="3" fieldName="marketingCosts" varName="marketingCosts" />
        <FactoryFormField key="4" fieldName="minimumMarketingCost" varName="minimumMarketingCost" />
      </InsideCard>
      <InsideCard key="shared" title={i18n.t("clause_rental_costs_shared_cost_title")}>
        <p key="p" className="pb-2">
          {i18n.t("clause_rental_costs_shared_cost_p1")}
        </p>
        <FactoryFormField key="5" fieldName="sharedCosts" varName="sharedCosts" />
        <FactoryFormField key="6" fieldName="sharedCostsStorage" varName="sharedCostsStorage" />
      </InsideCard>
      <InsideCard key="index" title={i18n.t("clause_rental_costs_index_adjustment_title")}>
        <p key="p" className="w-full pb-2">
          {i18n.t("clause_rental_costs_index_adjustment_p1")}
        </p>
        {/* <FactoryFormField key="6" fieldName="rentAdjustment" varName="rentAdjustment" /> */}
        <FactoryFormField key="7" fieldName="firstTimeRegulation" varName="firstTimeRegulation" />
        <FactoryFormField key="8" fieldName="indexAdjustmentBasicIndex" varName="indexAdjustmentBasicIndex" />
      </InsideCard>
      <InsideCard key="provision" title={i18n.t("clause_rental_costs_index_payment_provision_title")}>
        {(isManager || checkedState[0]) && (
          <CheckboxType2
            key="11"
            label={i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_1")}
            checked={checkedState[0]}
            onClick={() => handleClick(0)}
          />
        )}
        {(isManager || checkedState[1]) && (
          <CheckboxType2
            key="12"
            label={i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_2")}
            checked={checkedState[1]}
            onClick={() => handleClick(1)}
          />
        )}
      </InsideCard>
    </Card>
  );
};
