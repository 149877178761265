import { Language, RouteName } from 'src/types'
import { routes } from 'src/producers/routing/routes'
import { db, fieldValue } from 'src/producers/firebase'
import { router } from 'src/router'

export const onLoad: producer = ({
  // setCurrentPath = update.routes.currentPath,
  setCurrentQuery = update.routes.currentQuery,
  updateCurrentRoute = update.routes.current,
  updateReroute = update.routes.triggers.reroute,
  setAutologin = update.routes.queryParams.autologin,
  setHidenNavbar = update.routes.queryParams.hiddenNavbar,
  setGoogleAuth = update.routes.queryParams.googleAuth,
  setRainforest = update.routes.queryParams.rainforest,
  setTenantNumber = update.routes.queryParams.tenantNumber,
  changeLanguage = update.languages.triggers.change,
  logout = update.session.triggers.logout,
  redirectToPath = update.routes.list[RouteName.LOGIN].redirectToPath,
  redirectToSearch = update.routes.list[RouteName.LOGIN].redirectToSearch,
  getCurrentRoute = get.routes.current,
  updateIsEditedBy = update.contract.originalv2.isEditedBy,
  isEditedBy = get.contract.originalv2.isEditedBy,
  uid = get.user.uid,
  contractId = get.contract.id,
}) => {
  const currentRoute = getCurrentRoute.value()
  let pathname = currentRoute?.matches?.[0]?.pathname
  if (!pathname) pathname = window.location.pathname
  let query = window.location.search
  if (pathname == '/logout') {
    logout.set(Date.now())
    return
  }

  const routeUpdater = (route: any) => {
    updateCurrentRoute.set(route)
  }

  router.subscribe(routeUpdater)
  const redirectPath = window.sessionStorage.getItem('redirectToPath')
  const redirectSearch = window.sessionStorage.getItem('redirectToSearch')
  // if (redirectPath) {
  //   pathname = redirectPath
  //   window.sessionStorage.removeItem('redirectToPath')
  // }
  if (redirectSearch) {
    query = redirectSearch
    window.sessionStorage.removeItem('redirectToSearch')
  }

  const found = Object.keys(routes).find((routeName: string) => {
    const route = routes[routeName]
    const regex = new RegExp(route.path)
    return regex.test(pathname)
  })
  if (found) {
    redirectToPath.set(pathname)
    redirectToSearch.set(query)
  }
  // setCurrentPath.set(pathname);
  router.navigate(pathname + (query || ''))
  updateReroute.set(Date.now())
  if (query) {
    const queryObj = new URLSearchParams(query)
    const language = queryObj.get('language')
    const autologin = queryObj.get('autologin')
    const hiddenNavbar = queryObj.get('hiddenNavbar')
    const googleAuth = queryObj.get('googleAuth')
    const rainforest = queryObj.get('rainforest')
    const tenantNumber = queryObj.get('kundeNummer')
    if (language) changeLanguage.set(language == 'no' ? Language.NORWEGIAN : Language.ENGLISH)
    if (autologin == 'true') setAutologin.set(true)
    if (hiddenNavbar == 'true') setHidenNavbar.set(true)
    if (googleAuth == 'true') setGoogleAuth.set(true)
    if (rainforest == 'true') setRainforest.set(true)
    if (tenantNumber) setTenantNumber.set(tenantNumber)
  }
  setCurrentQuery.set(query)

  window.addEventListener('beforeunload', function (e) {
    contractId = contractId.value()
    if (!contractId) return
    if (isEditedBy.value() !== uid.value()) return
    updateIsEditedBy.remove()
    db.collection('contracts').doc(contractId).update({ isEditedBy: fieldValue.delete() })
  })
}
