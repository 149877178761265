export const minimumMarketingCost: producer = ({
  fields = observe.contract.originalv2.fields,

  // minimumTurnOver = fields.minimumTurnOver.value,
  minimumTurnOver = get.contract.originalv2.fields.minimumTurnOver.value.value(),
  marketingCosts = get.contract.originalv2.fields.marketingCosts.value.value(),
  originalMinimumMarketingCost = get.contract.originalv2.fields.minimumMarketingCost.value.value(),
  isCustom = get.contract.originalv2.fields.minimumMarketingCost.isCustom.value(),

  minimumMarketingCost = update.contract.changes.fields.minimumMarketingCost.value,
}) => {
  if (!fields || isCustom) return
  // const marketingCosts = fields.marketingCost.value
  // const minimumTurnOver = fields.minimumTurnOver.value
  if (!minimumTurnOver || !marketingCosts) {
    minimumMarketingCost.set(null)
    return
  }

  const value = (minimumTurnOver * marketingCosts) / 100

  if (originalMinimumMarketingCost === value) return
  minimumMarketingCost.set(value)
}
