import { Button } from "@c11/components";
import { Divider } from "antd";
import "./PartyListItem.css";

import i18n from "src/producers/languages/i18n";
import { UserRole, ContractStatus, PartyType } from "src/types";
import { ReorderPartyButton } from "src/pages/Contract/subviews/Parties/ReorderPartyButton/ReorderPartyButton";
import { ListItemCard, ListItemCardBody, ListItemCardFooter, ListItemCardHeader } from "src/components";
import { EditIcon, PersonSilhouetteIcon } from "src/assets/svg";

import styles from "../../../styles.module.css";

export const PartyListItem: view = ({
  party,
  index,
  userRole = get.user.data.role,
  openModal = update.modal.addEditPartyModal,
  status = observe.contract.original.state.status,
  partyLoading = observe.contract.triggers.updateParty,
}) => {
  const { email, firstName, lastName, partyType, recipientAction } = party;
  const isManager = userRole.value() === UserRole.MANAGER;
  const isDraft = status === ContractStatus.DRAFT;
  const isNegotiation = status === ContractStatus.NEGOTIATION;
  const canEditParty = isManager && (isDraft || isNegotiation);
  const thisPartyIsLoading = partyLoading?.id === party?.partyId;

  return (
    <ListItemCard
      key={`lic-${index}`}
      index={index + 1}
      isLoading={!!thisPartyIsLoading}
      className={`${styles.signersListItem}`}>
      <ListItemCardHeader key={`lich-${index}`} className="w-4/12">
        <div className={`${styles.userInfo}`}>
          <div className={`${partyType === PartyType.TENANT ? "text-green-500" : "text-pink-500"} "border-focus"`}>
            <PersonSilhouetteIcon width="25" height="25" className="mr-3" />
          </div>
          <div>
            <b>{`${firstName} ${lastName}`}</b>
            <p>{`${email}`}</p>
          </div>
        </div>
      </ListItemCardHeader>
      <ListItemCardBody key={`licb-${index}`} className={`${styles.signersListItemBody}`}>
        <div className="w-1/2 ml-auto">
          <h5>{i18n.t("parties_page.card_title")}</h5>
          <span className={`font-medium ${partyType === PartyType.TENANT ? "text-green-500" : "text-pink-500"}`}>
            {i18n.t(`general.${partyType}`)}
          </span>
        </div>
        <div className="w-1/2 ml-auto">
          <h5>{i18n.t("parties_page.action")}</h5>
          {recipientAction === "sign" && (
            <span className="text-green-500 font-medium">{i18n.t("parties_page.action_sign")}</span>
          )}
          {recipientAction === "review" && (
            <span className="text-pink-500 font-medium">{i18n.t("parties_page.action_approve")}</span>
          )}
        </div>
      </ListItemCardBody>
      <ListItemCardFooter key={`licf-${index}`} className={`${styles.signersListFooter}`}>
        {canEditParty && (
          <Button
            variant="ghost"
            className="party-icon-btn second-theme-color btnGhostSimple"
            onClick={() => openModal.set({ type: "edit", data: party })}>
            <EditIcon width="16" height="16" />
          </Button>
        )}
        {canEditParty && (
          <div className={`${styles.signersListFooterButtons}`}>
            <Divider type="vertical" className="h-9 border" />
            <ReorderPartyButton party={party} index={index} />
          </div>
        )}
      </ListItemCardFooter>
    </ListItemCard>
  );
};
