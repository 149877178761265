import { Button } from '@c11/components'
import { functions } from 'src/producers/firebase'

export const TestButton: view = ({
  rawUser = get.rawUser,
  user = observe.user,
  localFunctions = get.config.localFunctions,
  featureFlags = observe.featureFlags,
}) => {
  const handleClickRev1 = async () => {
    console.log('click 1')
    const migrateContracts = functions.httpsCallable('migrateContractsRev1_call')
    const contracts = await migrateContracts()

    console.log('contracts', contracts.data)
  }

  const handleClickRev3 = async () => {
    console.log('click 3')
    const migrateContracts = functions.httpsCallable('migrateContractsRev3_call')
    const contracts = await migrateContracts()

    console.log('contracts', contracts.data)
  }

  const handleClickMigrateAddendums = async () => {
    console.log('click migrate')
    const migrateAddendums = functions.httpsCallable('migrateAddendums')
    const addendums = await migrateAddendums()
    console.log('>>>Addendums: ', addendums.data)
  }
  const handleClickSyncBuildings = async () => {
    const syncBuilding = functions.httpsCallable('queueTasks_syncBuildingsTrigger')
    const buildings = await syncBuilding()
    console.log('>>>buildings: ', buildings.data)
  }
  const handleClickSyncFiles = async () => {
    const syncFiles = functions.httpsCallable('queueFileTasksTrigger')
    const files = await syncFiles()
    console.log('>>>files: ', files.data)
  }
  const handleClickSyncTenants = async () => {
    const syncTenants = functions.httpsCallable('queueTasks_syncKLPTenantsTrigger')
    const tenants = await syncTenants()
    console.log('>>>tenants: ', tenants.data)
  }
  const handleClickSyncUsers = async () => {
    const syncUsers = functions.httpsCallable('queueTasks_syncKLPUsersTrigger')
    const users = await syncUsers()
    console.log('>>>users: ', users.data)
  }

  const syncButtonsVisible =
    featureFlags?.syncDataTrigger?.main === true &&
    featureFlags?.syncDataTrigger?.emails &&
    featureFlags?.syncDataTrigger?.emails?.includes(user?.data?.email)

  return (
    <>
      <Button onClick={async () => await handleClickRev1()}> Migrate Rev 1 </Button>
      <Button onClick={async () => await handleClickRev3()}> Migrate Rev 3 </Button>
      <Button onClick={async () => await handleClickMigrateAddendums()}> Migrate Addendums </Button>
      {syncButtonsVisible && (
        <div style={{paddingTop: "20px"}}>
          <Button onClick={async () => await handleClickSyncBuildings()}> Sync Buildings </Button>
          <Button onClick={async () => await handleClickSyncFiles()}> Sync Files </Button>
          <Button onClick={async () => await handleClickSyncTenants()}> Sync Tenantns </Button>
          <Button onClick={async () => await handleClickSyncUsers()}> Sync Users </Button>
        </div>
      )}
      {/* <Button> Delete </Button> */}
    </>
  )
}
