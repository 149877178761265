export const resetDefault: producer = ({
  trigger = observe.legacyContract.triggers.resetDefault,
  removeTrigger = update.legacyContract.triggers.resetDefault,
  defaultValue = get.legacyContract.original.defaultValues[param.varName].value,
  setDefault = update.legacyContract.currentSession.changes[param.varName],
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  defaultValue = defaultValue.value({ varName: trigger.varName });
  setDefault.set({ value: defaultValue, createdAt: Date.now() }, { varName: trigger.varName });
};
