import React from "react";

import i18n from "src/producers/languages/i18n";

export const ReturnToCurrentVersion: view = ({ setDisplayedVersion = update.legacyContract.displayedVersion }) => {
  return (
    <button
      className={"appearance-none py-3 px-4 bg-gray-800 text-white rounded "}
      onClick={() => setDisplayedVersion.remove()}>
      <div className={`uppercase`}>{i18n.t("button_return_to_current")}</div>
    </button>
  );
};
