import { Button } from '@c11/components'
import './SendContractButton.css'

import i18n from 'src/producers/languages/i18n'
import { PaperAirplaneIcon } from 'src/assets/svg'
import { TooltipWrapper } from 'src/components'
import { validateData } from 'src/validations/validateData'

export const SendContractButton: view = ({
  hasInvalidFields = observe.contract.hasInvalidFields,
  showModal = update.modal.sendContract,
  // isLoading = observe.contract.loading.sendToSigning,
  state = get.contract.original.state,
  values = get.contract.values,
  updateHasInvalidFields = update.contract.hasInvalidFields,
  updateInvalidFields = update.contract.invalidFields[param.varName],
  // validateData = update.contract.triggers.validateData,
  removeAllInvalidFields = update.contract.invalidFields
}) => {

  const valid = () => validateData(values.value(), state.value(), updateInvalidFields, removeAllInvalidFields)
  // if (valid) hasInvalidFields = false
  let tooltipText
  if (hasInvalidFields) tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_invalid_fields')
  console.log('hasInvalidFields', hasInvalidFields)
  return (
    <TooltipWrapper
      // open={hasInvalidFields}
      // disabled={!isDisabled}
      text={tooltipText}
    >
      <Button
        className="send-contract-btn uppercase"
        variant="primary"
        // isDisabled={isDisabled}
        onClick={() => {
          // if (valid()) {
          //   updateHasInvalidFields.set(false)
            showModal.set(true)
          // } else {
          //   updateHasInvalidFields.set(true)
          // }
        }}
      >
        <>
          {i18n.t('contract_navbar.button_send_contract')}
          <PaperAirplaneIcon
            className="ml-2"
            fill={/*{`${isDisabled ? "#C9CACA" : "#000"}`}*/'#000'}
          />
        </>
      </Button>
    </TooltipWrapper>
  )
}
