import { AddendumPageSubview, ContractPageSubview, ContractRoutesMap, ContractType, RouteName } from 'src/types'
import { db } from '../firebase'
import { clearContractState } from 'src/pages/producers/agreement/clear/contract'
import posthog from 'posthog-js'
import { router } from 'src/router'

export const onSessionIsReady: producer = async ({
  // currentPath = observe.routes.currentPath,
  isUserReady = observe.user.isReady,
  isAuth = observe.user.isAuth,
  isReady = observe.session.isReady,
  currentQuery = observe.routes.currentQuery,
  reroute = observe.routes.triggers.reroute,
  newPaperContractState = get.routes.newPaperContractState.value(),
  removeNewPaperContractState = update.routes.newPaperContractState,
  changeRoute = update.routes.triggers.changeRoute,
  setContractInitialState = update.contract,
  setLegacyContractInitialState = update.legacyContract,
  updateLastUrl = update.routes.lastUrl,
  lastUrl = observe.routes.lastUrl,
  currentRoute = observe.routes.current,
  updateTriggerGetContract = update.routes.triggers.getContract,

  clearAll = update.contract,
  listeners = get.listeners,
  updateListeners = update.listeners,

  error = update.errors[param.id],
}) => {
  if (!isReady) return
  if (!reroute) return
  let currentPath = currentRoute?.matches?.[0]?.pathname
  if (!currentPath) currentPath = window.location.pathname
  if (isAuth && lastUrl && lastUrl !== currentPath) {
    router.navigate(lastUrl + currentQuery)
    updateLastUrl.set('')
  } else if (isAuth && currentPath === '/login') {
    router.navigate('/' + currentQuery)
  }

  if (!currentQuery) currentQuery = ''

  if (isAuth === false) {
    // window.history.replaceState('', '', '/login' + currentQuery)
    if (currentPath !== '/login') {
      updateLastUrl.set(currentPath)
      router.navigate('/login' + currentQuery)
      changeRoute.set({
        route: RouteName.LOGIN,
      })
    }
    return
  }

  if (isUserReady) {
    const pathSplited = currentPath.split('/')
    let route = pathSplited[1].toUpperCase()
    let pathParam = pathSplited[2]
    // newContractState = newContractState.value()
    // console.log('PAAAAATH', pathSplited, route, pathParam)
    // Special case to get the contract revision
    // TODO: should be removed once legacy contracts are removed
    console.log('route', route, pathParam)
    if (route === RouteName.CONTRACT || (route.includes(RouteName.CONTRACT) && pathParam)) {
      let contract = (await db.collection('contracts').doc(pathParam).get()).data()
      if (newPaperContractState && newPaperContractState.contractType === ContractType.PAPER) {
        contract = newPaperContractState
        removeNewPaperContractState.remove()
      }
      if (!contract) return
      route = ContractRoutesMap[ContractType.DIGITAL][contract.revision]
    }
    if (currentPath === '/' || currentPath === '/login') route = RouteName.DASHBOARD
    // clearContractState({ clearData: clearAll, clearListeners: updateListeners, listeners })
    console.log('route', route, pathParam)
    // posthog.capture('route', { route, pathParam })
    switch (route) {
      case RouteName.CONTRACT || 'CONTRACT_V1': // TODO: TEMPORARY, until contracts are mighrated to the new type
        changeRoute.set({
          route: route,
          data: {
            contractId: pathParam,
            templateId: 'template1',
            subview: ContractPageSubview.CONTRACT,
          },
        })
        setContractInitialState.set({
          id: pathParam,
          templateId: 'template1',
        })
        // window.history.pushState('', '', '/contract/' + pathParam + currentQuery)
        // router.navigate('/contract_v1/' + pathParam + currentQuery)
        break
      case RouteName.CONTRACT_V2:
        changeRoute.set({
          route: route,
          data: {
            contractId: pathParam,
            templateId: 'template1',
            subview: ContractPageSubview.CONTRACT,
          },
        })
        setContractInitialState.set({
          templateId: 'template1',
          id: pathParam,
          // originalv2: contract
        })
        // window.history.pushState('', '', '/contract/' + pathParam + currentQuery)
        // router.navigate('/contract/' + pathParam + currentQuery)
        break
      case RouteName.LEGACY_CONTRACT:
        changeRoute.set({
          route: route,
          data: {
            contractId: pathParam,
            templateId: 'template1',
            subview: ContractPageSubview.CONTRACT,
          },
        })
        setLegacyContractInitialState.set({
          id: pathParam,
          templateId: 'template1',
        })
        // window.history.pushState('', '', '/contract/' + pathParam + currentQuery)
        // router.navigate('/contract_legacy/' + pathParam + currentQuery)
        break

      case RouteName.ADDENDUM:
        changeRoute.set({
          route: route,
          data: {
            contractId: pathParam,
            subview: AddendumPageSubview.ADDENDUM,
          },
        })
        setContractInitialState.set({
          id: pathParam,
        })

        // window.history.pushState('', '', '/addendum/' + pathParam || 'new' + currentQuery)
        // router.navigate('/addendum/' + pathParam || 'new' + currentQuery)
        break
      case RouteName.DASHBOARD:
        changeRoute.set({ route: RouteName.DASHBOARD })
        // window.history.replaceState('', '', '/' + currentQuery)
        // router.navigate('/' + currentQuery)
        break
      default:
        error.set(
          {
            message: 'error-route-not-found',
            description: `route: ${route}`,
          },
          {
            id: 'valueValidation-contract',
          }
        )
    }
  }
}
