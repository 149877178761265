import React from "react";
import i18n from "src/producers/languages/i18n";
import { ContractStatus, UserRole } from "src/types";
import { Checkbox } from "../../fields/Checkbox";

export const AcceptCheckbox: view = ({
  getAcceptTerms = observe.legacyContract.values.acceptTerms.displayedValue,
  setAcceptTerms = update.legacyContract.currentSession.changes.acceptTerms,
  getAcceptTermsFromChanges = observe.legacyContract.currentSession.changes.acceptTerms.value,
  userRole = get.user.data.role,
  status = observe.legacyContract.original.status,
  termsUrl = observe.legacyContract.termsUrl,
}) => {
  const value = getAcceptTermsFromChanges || getAcceptTerms;
  const checked = value === "true";
  const isTenant = userRole.value() === UserRole.TENANT;
  const isManager = userRole.value() === UserRole.MANAGER;
  function handleClick() {
    if (status !== ContractStatus.NEGOTIATION) return;
    if (value === "true") {
      setAcceptTerms.set({ value: "false", createdAt: Date.now() });
    } else {
      setAcceptTerms.set({ value: "true", createdAt: Date.now() });
    }
  }

  return (
    <>
      <p>{i18n.t("legacy.accept_terms_p1")}</p>

      <div className="flex mb-2 mt-3" key="1">
        {isTenant && (
          <>
            <Checkbox key="1.0" checked={checked} onClick={() => handleClick()} />
            <p key={"1.1"} className="font-semibold text-sm">
              <span key="5">{i18n.t("legacy.accept_terms_tenant")} </span>
              <a href={termsUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                link
              </a>
            </p>
          </>
        )}
      </div>
      <div>
        {isManager && (
          <p>
            {checked ? i18n.t("legacy.accept_terms_manager_checked") : i18n.t("legacy.accept_terms_manager_unchecked")}
          </p>
        )}
      </div>
    </>
  );
};
