import { Spinner } from "@c11/components.spinner";

import { SVGs } from "src/assets";

export const DownloadPDFButton: view = ({
  pdfServerLoading = observe.legacyContract.flags.loadingpdf,
  generatePDF = update.legacyContract.triggers.generatePDF,
}) => {
  return (
    <>
      {pdfServerLoading ? (
        <div>
          <Spinner color="primary" size="medium" />
        </div>
      ) : (
        <div onClick={() => generatePDF.set(Date.now())}>
          <SVGs.PdfFile />
        </div>
      )}
    </>
  );
};
