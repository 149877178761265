export const resetDefault: producer = ({
  trigger = observe.contract.triggers.resetDefault,
  removeTrigger = update.contract.triggers.resetDefault,
  defaultValue = get.contract.original.defaultValues[param.varName].value,
  setDefault = update.contract.toSave.changes[param.varName],
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  defaultValue = defaultValue.value({ varName: trigger.varName });
  setDefault.set({
    value: defaultValue,
    createdAt: Date.now()
  }, { varName: trigger.varName });
};
