import React from "react";
import { path } from "@c11/engine.runtime";
import moment from "moment";
// eslint-disable-next-line import/no-cycle
import { UserRole } from "src/types";
import { FieldInputType } from "src/templates/types/template";
import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { Menu, Dropdown, DatePicker } from "antd";
import { CalculationFieldManager } from "./CalculationFieldManager";
import { HtmlRender } from "../HtmlRender";
import { TextEditor } from "../TextEditor";
import { NumberField, TextField } from "./TextFieldsEngine";

import { Label } from "./Label";
import { InvalidMessage } from "./InvalidMessage";

export const FormField: view = ({
  fieldName,
  varName,
  readOnly,
  templateField = observe.legacyContract.fields[arg.fieldName],
  userRole = get.user.data.role,
}) => {
  if (!templateField) return null;
  const { inputType, extraClasses, placeholder } = templateField;
  const isManager = userRole.value() === UserRole.MANAGER;

  if (inputType === FieldInputType.CalculationCustom && isManager)
    return <CalculationFieldManager {...{ fieldName, varName, readOnly }} />;

  let FieldToRender = RegularInput;
  if (inputType === FieldInputType.Date) FieldToRender = DateInput;
  if (inputType === FieldInputType.Number) FieldToRender = NumberInput;
  if (inputType === FieldInputType.FreeText) FieldToRender = FreeTextField;
  if (inputType === FieldInputType.Dropdown) FieldToRender = DropdownInput;

  return <FieldToRender {...{ fieldName, varName, readOnly, extraClasses, placeholder }} />;
};

const RegularInput: view = ({
  varName,
  fieldName,
  extraClasses,
  readOnly,
  placeholder,
  value = observe.legacyContract.values[arg.varName].displayedValue,
}) => (
  <div className={`w-1/2 my-2 ${extraClasses}`}>
    <Label key="00" fieldName={fieldName} />
    <TextField
      path={path.newFiedValues[varName]}
      value={value}
      outputPath={path.legacyContract.currentSession.changes[varName]}
      readOnly={readOnly}
      className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 text-gray-800 leading-tight ${
        readOnly ? "bg-gray-200" : "focus:bg-green-300 focus:border-transparent"
      }`}
      placeholder={placeholder}
    />
    <InvalidMessage varName={varName} />
  </div>
);
const NumberInput: view = ({
  varName,
  fieldName,
  extraClasses,
  readOnly,
  placeholder,
  value = observe.legacyContract.values[arg.varName].displayedValue,
}) => (
  <div className={`w-1/2 my-2 ${extraClasses}`}>
    <Label key="00" fieldName={fieldName} />
    <NumberField
      path={path.newFiedValues[varName]}
      type="number"
      value={value}
      outputPath={path.legacyContract.currentSession.changes[varName]}
      readOnly={readOnly}
      className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 text-gray-800 leading-tight ${
        readOnly ? "bg-gray-200" : "focus:bg-green-300 focus:border-transparent"
      }`}
      placeholder={placeholder}
    />
    <InvalidMessage varName={varName} />
  </div>
);

const FreeTextField: view = ({
  varName,
  fieldName,
  extraClasses,
  readOnly,
  value = observe.legacyContract.values[arg.varName].displayedValue,
  userRole = get.user.data.role,
  rerendersAllFields = observe.legacyContract.rerendersAllFields,
  writeValue = update.legacyContract.currentSession.changes[arg.varName],
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  const key = rerendersAllFields || !!value;
  return (
    <div className={`w-1/2 my-2 ${extraClasses}`}>
      <Label key="00" fieldName={fieldName} />
      {isTenant ? (
        <HtmlRender html={value} />
      ) : (
        <div translate={"no"} className="notranslate">
          <TextEditor
            key={key}
            value={value}
            readOnly={readOnly}
            onChange={(e: any) => {
              if (e) {
                writeValue.set({
                  value: e,
                  createdAt: Date.now(),
                });
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

const DateInput: view = ({
  varName,
  fieldName,
  extraClasses,
  readOnly,
  placeholder,
  value = observe.legacyContract.values[arg.varName].displayedValue,
  writeValue = update.legacyContract.currentSession.changes[arg.varName],
  rerendersAllFields = observe.legacyContract.rerendersAllFields,
}) => (
  <div className={`w-1/2 my-2 ${extraClasses}`}>
    <Label key="00" fieldName={fieldName} />
    <DatePicker
      key={rerendersAllFields}
      className="text-xl"
      placeholder={placeholder}
      style={{ width: "100%", height: "45px", background: readOnly ? "#F0F0F0" : "transparent" }}
      onChange={(date) =>
        writeValue.set({
          value: date?.valueOf(),
          createdAt: Date.now(),
        })
      }
      value={value ? moment(value) : null}
      disabled={readOnly}
      format={"DD.MM.YYYY"}
    />
    <InvalidMessage varName={varName} />
  </div>
);

const DropdownInput: view = ({
  varName,
  fieldName,
  extraClasses,
  placeholder,
  value = observe.legacyContract.values[arg.varName].displayedValue,
  // hack the displayValue to update instantly
  updateDisplayedValue = update.legacyContract.values[arg.varName].displayedValue,
  writeValue = update.legacyContract.currentSession.changes[arg.varName],
  templateField = observe.legacyContract.fields[arg.fieldName],
}) => {
  const choises = templateField?.dropdownChoices || [];
  const hasMoreThenOneChoise = choises.length > 1;

  const menu = (
    <Menu>
      {choises.map((choise: any, index: any) => (
        <Menu.Item key={index}>
          <DropdownRow
            choise={choise}
            onClick={() => {
              writeValue.set({
                value: choise,
                createdAt: Date.now(),
              });
              updateDisplayedValue.set(choise);
            }}
          />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={`w-1/2 my-2 ${extraClasses}`}>
      <Label key="00" fieldName={fieldName} />
      <div className=" inline-block relative w-full" id={`fieldDropdown-${fieldName}`}>
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          overlayClassName={"fieldDropdown"}
          getPopupContainer={() => document.getElementById(`fieldDropdown-${fieldName}`) as HTMLElement}
          disabled={!hasMoreThenOneChoise}>
          <button className="bg-white kpy-10 kpx-14 flex w-full justify-between items-center border klp-border1">
            {value ? <DropdownRow choise={value} /> : <span key="1">{placeholder} </span>}
            {hasMoreThenOneChoise && <SVGs.DropdownArrow />}
          </button>
        </Dropdown>
      </div>
    </div>
  );
};

const DropdownRow: view = ({ choise, onClick }) => (
  <div onClick={onClick} className="flex justify-between items-center mx-2 my-1">
    <div>
      <p key="1" className=" text-left font-medium">{`${i18n.t(choise)}`}</p>
    </div>
  </div>
);
