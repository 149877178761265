import {ApplicationError, LogLevels} from "src/types";

export let firebase = window.firebase as any
// import firebase from 'firebase/compat/app'
// import 'firebase/compat/auth'
// import 'firebase/compat/firestore'

export let db: firebase.default.firestore.Firestore
export let auth: firebase.default.auth.Auth
export let creationAuth: firebase.default.auth.Auth
export let GoogleAuthProvider = firebase.auth.GoogleAuthProvider as firebase.default.auth.GoogleAuthProvider
export let fieldValue = firebase.firestore.FieldValue as firebase.default.firestore.FieldValue
export let firebaseListeners: any = {}
export let storageRef: any
export let klpProvider: firebase.default.auth.OAuthProvider
export let functions: firebase.default.functions.Functions

export const firebaseProducer: producer = ({
  firebaseConfig = observe.config.firebase,
  isReady = get.session.firebase.isReady,
  oidc = get.config.oidcProvider,
  setIsReady = update.session.firebase.isReady,
  error = update.errors[param.id]
}) => {
  try {
    if (isReady.value() || !firebaseConfig) return

    const app = firebase.initializeApp(firebaseConfig)
    db = app.firestore()
    db.settings({ experimentalForceLongPolling: true })
    auth = firebase.auth()
    functions = app.functions('europe-west2')

    klpProvider = new firebase.auth.OAuthProvider(oidc.value())
    creationAuth = firebase.initializeApp(firebaseConfig, 'Secondary').auth()
    const storage = firebase.storage()
    storageRef = storage.ref()

    if (process.env.WITH_FUNCTIONS) {
      try {
        functions.useEmulator('localhost', 5001)
      } catch (err) {
        console.error('ERRRRRRRRRRRR', err)
      }
    }
    if (process.env.LOCAL) {
      try {
        functions.useEmulator('localhost', 5001)
        storage.useEmulator("localhost", 4000);
        db.useEmulator("localhost", 8084);
      } catch (err) {
        console.error('ERRRRRRRRRRRR', err)
      }
    }

    firebase.analytics()

    setIsReady.set(true)
  } catch (err) {
    console.error('Error in firebase.ts: ', err)
    error.set(
      {
        message: err,
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: "firebaseInit" }
    );
  }
}
