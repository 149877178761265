import { functions } from "src/producers/firebase";
import { CommandActions } from "src/types/commands/command";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const addParty: producer = async ({
  trigger = observe.contract.triggers.addParty,
  parties = get.contract.original.state.parties,
  contractId = get.contract.id,
  updateTrigger = update.contract.triggers.addParty,
  error = update.errors[param.id],
}) => {
  if (!trigger) return;
  parties = parties.value();
  const order = parties ? Object.keys(parties).length : 0;
  trigger.order = order;
  updateTrigger.remove();
  contractId = contractId.value();

  try {
    const commandPublisher = functions.httpsCallable("eventlessCommandHandler");
    const command = {
      ...trigger,
      action: CommandActions.ADD_PARTY,
      contractId,
    };
    await commandPublisher(command);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.LOW,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "addParty-contract" }
    );
  }
};
