export const handleNewFormData: producer = ({
  trigger = observe.contract.toSave.changes,
  userId = get.user.uid,
  updateTrigger = update.contract.toSave,
  timerRef = get.contract.timeoutIdForProcessData,
  fieldsInSession: session = get.contract.sessions,
  contractStateFields = get.contract.original.state.fields,
  changes = observe.contract.currentSession.changes,
  setChanges = update.contract.currentSession.changes,
  setTimer = update.contract.timeoutIdForProcessData,
  saveStatus = update.contract.loading.saveStatus,
}) => {
  if (!trigger) {
    return;
  }

  saveStatus.set("saving");
  if (timerRef.value()) {
    clearTimeout(timerRef.value());
  }
  const timerId = setTimeout(() => {
    contractStateFields = contractStateFields.value();
    session = session.value();
    userId = userId.value();
    const changesFromSession = session?.[userId]?.changes;
    let newChanges: any = {};
    Object.keys(trigger).forEach((key) => {
      const newValue = trigger[key].value;
      let previousValue;
      if (changesFromSession?.[key]) {
        previousValue = contractStateFields?.[key]?.previous?.value;
      } else {
        previousValue = contractStateFields?.[key]?.current?.value;
      }

      if (previousValue === newValue) {
        if (changes?.[key]) delete changes[key];
        else {
          newChanges[key] = {
            value: newValue,
            deleted: true,
            createdAt: Date.now(),
            // this is for calculation
            ...(trigger[key].custom !== undefined && { custom: trigger[key].custom }),
          };
        }
      } else {
        newChanges[key] = {
          value: newValue,
          createdAt: Date.now(),
          // this is for calculation
          ...(trigger[key].custom !== undefined && { custom: trigger[key].custom }),
        };
      }
    });
    setChanges.set({ ...changes, ...newChanges });
    updateTrigger.remove();
  }, 500);

  setTimer.set(timerId);
};
