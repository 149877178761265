import { db } from "src/producers/firebase";
import { ContractStatus } from "src/types";

export const goBackToNegotiation: producer = ({
  trigger = observe.legacyContract.triggers.goBackToNegociation,
  removeTrigger = update.legacyContract.triggers.goBackToNegociation,
  contractId = get.legacyContract.id,
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  db.collection("contracts").doc(contractId.value()).update({
    status: ContractStatus.NEGOTIATION,
  });
};
