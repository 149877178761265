export const manageLoadingState: producer = ({
  trigger1 = observe.contract.triggers.saveVersion,
  trigger2 = observe.contract.triggers.moveToSigning,
  trigger3 = observe.pages.contract.triggers.savePaperContract_v2,
  trigger4 = observe.contract.triggers.stopSigning,
  trigger5 = observe.contract.triggers.sendContract,
  stateTransition = update.contract.loading.stateTransition,
}) => {
  if (!trigger1 && !trigger2 && !trigger3 && !trigger4 && !trigger5) {
    stateTransition.remove();
  }
};
