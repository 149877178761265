import React from "react";

import { RouteName } from "src/types";
import * as producers from "./producers";
import {
  ContractNavbar,
  Sidebar,
  Appendix,
  Sections,
  MainContract,
  AddAppendixModal,
  DeleteAppendixModal,
  GenerateContractModal,
  ReEditContractModal,
  ResetContractModal,
  SendContractModal,
  ActivityLogModal,
} from "./components";

const ContractLegacy: view = ({
  shouldMountAppendix = observe.routes.list[RouteName.LEGACY_CONTRACT].data.appendix.fileName,
}) => {
  return (
    <div key="page" className="bg-gray-100 min-h-screen font-sans text-gray-800">
      <ContractNavbar key="navbar" />
      <>
        <main className={`fixed main-class`}>
          <div style={{ marginTop: 82 }} className="overflow-auto pt-8 h-screen pb-48 ">
            <div className="xl:max-w-screen-lg mx-auto pl-12 pr-8">
              <Sections />
              <div className="pt-12">{shouldMountAppendix ? <Appendix /> : <MainContract />}</div>
            </div>
          </div>
        </main>
        <Sidebar />
        <AddAppendixModal />
        <DeleteAppendixModal />
        <SendContractModal />
        <GenerateContractModal />
        <ActivityLogModal />
        <ReEditContractModal />
        <ResetContractModal />
      </>
    </div>
  );
};

ContractLegacy.producers(Object.values(producers));

export { ContractLegacy };
