import { Spinner } from "@c11/components.spinner";

import { RouteName } from "src/types";

const ErrorState: view = ({ isError = observe.session.isError }) => {
  if (!isError) {
    return null;
  }
  return <div>Error was ecountered</div>;
};

const LoadingState: view = ({
  isSessionReady = observe.session.isReady,
  isLanguageReady = observe.languages.isReady,
  isUserReady = observe.user.isReady,
}) => {
  if (!isSessionReady || !isLanguageReady || !isUserReady) {
    return null;
  }

  return (
    <div className={"w-full h-screen flex justify-center items-center "}>
      <Spinner color="primary" size="large" />
    </div>
  );
};

export const Initialization: view = ({ shouldMount = observe.routes.list[RouteName.INITIALIZATION].isMounted }) => {
  return (
    shouldMount && (
      <div>
        <ErrorState />
        <LoadingState />
      </div>
    )
  );
};
