import { path } from "@c11/engine.runtime";

import i18n from "src/producers/languages/i18n";

import { CalculationFieldAllUsers } from "src/pages/Contract/subviews/ContractForm/RentalPropertyClause/CalculationFieldAllUsers";
import { InsideCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/InsideCard";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import styles from "../../../../../../Contract/styles.module.css";
import { PlainTextFieldGeneral } from "src/pages/Contract/subviews/ContractForm/fields/PlainTextFieldGeneral";
import { FieldInputType } from "src/templates/types/template";
import { FreeTextFieldGeneral } from "src/pages/Contract/subviews/ContractForm/fields/FormField";

export const RentalPropertyTenant: view = ({}) => {
  const _path = path.contract;

  return (
    <ClauseCard title={`2. ${i18n.t("clause_rental_property_title")}`}>
      <div className={`${styles.contractCard}`}>
        <InsideCard key="Property" title={i18n.t("clause_rental_property_property_title")}>
          <PlainTextFieldGeneral
            varName="shoppingMall"
            extraClasses="pr-8"
            label={i18n.t("clause_rental_property_property_shopping_mall")}
          />
          <PlainTextFieldGeneral
            varName="propertyAddress"
            placeholder={i18n.t("clause_rental_property_property_placeholder_address")}
            label={i18n.t("clause_rental_property_property_address")}
          />
          <PlainTextFieldGeneral
            varName="municipality"
            placeholder={i18n.t("clause_rental_property_property_placeholder_municipality")}
            extraClasses="pr-8"
            label={i18n.t("clause_rental_property_property_municipality")}
          />
          <PlainTextFieldGeneral
            inputType={FieldInputType.Number}
            varName="parcelNumber"
            label={i18n.t("clause_rental_property_property_parcel_number")}
          />
          <PlainTextFieldGeneral
            inputType={FieldInputType.Number}
            varName="propertyLocalNo"
            extraClasses="pr-8 w-1/4 self-end"
            label={i18n.t("clause_rental_property_property_local_no")}
          />
          <PlainTextFieldGeneral
            inputType={FieldInputType.Number}
            varName="propertyLevel"
            extraClasses="w-1/4 pr-8 self-end"
            label={i18n.t("clause_rental_property_property_level")}
          />
          <PlainTextFieldGeneral
            inputType={FieldInputType.Number}
            varName="propertyExclusiveArea"
            extraClasses="pr-8 w-1/4 self-end"
            label={i18n.t("clause_rental_property_property_property_exclusive_area")}
          />
          <PlainTextFieldGeneral
            inputType={FieldInputType.Number}
            varName="propertyCommonArea"
            placeholder={i18n.t("clause_rental_property_property_placeholder_propery_common_area")}
            extraClasses="w-1/4 self-end"
            label={i18n.t("clause_rental_property_property_propery_common_area")}
          />
          <CalculationFieldAllUsers
            key="8"
            label={i18n.t("clause_rental_property_total_areal")}
            readPath={_path.values.propertyTotalArea.displayedValue}
          />
        </InsideCard>

        <InsideCard key="storage rent" title={`2.2 ${i18n.t("storage_addendum.conditionAndUseStorage")}`}>
          <div className="w-full">
            <PlainTextFieldGeneral varName="conditionAndUseStorage" inputType={FieldInputType.FreeText} />
          </div>
        </InsideCard>
      </div>
    </ClauseCard>
  );
};
