import { Button } from "@c11/components.button";
import "./SendContractButton.css";
import validator from "validator";

import i18n from "src/producers/languages/i18n";
import { PaperAirplaneIcon } from "src/assets/svg";
import { TooltipWrapper } from "src/components";

export const SendAddendumButton: view = ({
  showModal = update.modal.sendContract,
  addendumType = observe.contract.original.addendumType,
  tenantEmail = observe.contract.values.tenantEmail.displayedValue,
}) => {
  let isFormValid = true;
  if (!tenantEmail || !validator.isEmail(tenantEmail)) isFormValid = false;
  const isDisabled = !addendumType || !isFormValid;
  const tooltipText = i18n.t("contract_navbar.button_send_contract_tooltip_invalid_fields");
  return (
    <TooltipWrapper disabled={!isDisabled} text={tooltipText}>
      <Button
        className="send-contract-btn uppercase"
        variant="primary"
        isDisabled={isDisabled}
        onClick={() => showModal.set(true)}>
        <>
          {i18n.t("contract_navbar.button_send_addendum")}
          <PaperAirplaneIcon className="ml-2" fill={`${isDisabled ? "#C9CACA" : "#000"}`} />
        </>
      </Button>
    </TooltipWrapper>
  );
};
