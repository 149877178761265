import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const syncSelectedTenantId: producer = async ({
  selectedTenantId = observe.pages.dashboard.selectedTenantId,
  userUid = get.user.uid,
  error = update.errors[param.id],
}) => {
  if (!selectedTenantId) {
    return;
  }
  try {
    const userId = userUid.value();
    const usersCollection = db.collection("users");
    usersCollection.doc(userId).set(
      {
        data: {
          selectedTenantId,
        },
      },
      { merge: true }
    );
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncSelectedTenantId" }
    );
  }
};
