import { DashboardTabs } from "src/types";

export const setDefaultTab: producer = ({
  currentTab = observe.pages.dashboard.selectedTab,
  updateTab = update.pages.dashboard.selectedTab,
}) => {
  if (!currentTab) {
    updateTab.set(DashboardTabs.ALL_CONTRACTS);
  }
};
