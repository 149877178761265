import { db } from "src/producers/firebase";

export const syncShoppingMalls: producer = async ({
  id = observe.legacyContract.id,
  setShoppingMalls = update.legacyContract.shoppingMalls,
  shoppingMalls = get.legacyContract.shoppingMalls,
  setListener = update.listenersOld[arg.id].shoppingMalls,
}) => {
  if (!id) {
    return;
  }
  if (!shoppingMalls.value()) {
    setShoppingMalls.set([]);
  }
  try {
    const shoppingMallsDb = db.collection("buildings");
    const listener = await shoppingMallsDb.onSnapshot((shoppingMalls: any) => {
      shoppingMalls.forEach((doc: any) => {
        const data = doc.data();
        if (!data) {
          return;
        }
        setShoppingMalls.push(data);
      });
    });
    setListener.set(listener);
  } catch (e) {
    console.error("syncShoppingMalls", e);
  }
};
