import { UserRole } from "src/types";

export const sessions: producer = ({
  contractId = observe.legacyContract.id,
  sessions = observe.legacyContract.original.sessions,
  displayedVersion = observe.legacyContract.original.displayedVersion,

  userRole = get.user.data.role,
  latestVersion = get.legacyContract.original.latestVersion,
  values = update.legacyContract.syncQueue.values.sessions,
}) => {
  if (!contractId || sessions == undefined) {
    return;
  }
  if (!sessions) {
    values.set({});
    return;
  }
  try {
    userRole = userRole.value();
    latestVersion = latestVersion.value();
    const toSave: any = {};
    if (!displayedVersion) {
      Object.keys(sessions).forEach((sessionId) => {
        if (userRole === UserRole.TENANT && sessionId !== userRole) {
          return;
        }
        const changes = sessions[sessionId]?.changes;
        if (!changes) return;
        Object.keys(changes).forEach((varName) => {
          const variable = changes[varName];
          if (toSave[varName]) {
            toSave[varName].valueTimeline[sessionId] = {
              value: variable.value,
              createdAt: variable.createdAt,
              custom: variable.custom || false,
              type: "session",
              versionId: latestVersion,
            };
          } else {
            toSave[varName] = {
              valueTimeline: {
                [sessionId]: {
                  value: variable.value,
                  createdAt: variable.createdAt,
                  custom: variable.custom || false,
                  type: "session",
                  versionId: latestVersion,
                },
              },
            };
          }
        });
      });
    }
    values.set(toSave);
  } catch (e) {
    console.error("valuesVersions", e);
  }
};
