import validator from "validator";
import { UserRole } from "src/types";

import { RegularInputGeneral } from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import i18n from "src/producers/languages/i18n";
import { PlainTextFieldGeneral } from "src/pages/Contract/subviews/ContractForm/fields/PlainTextFieldGeneral";

export const ContractPersonSection: view = ({ userRole = observe.user.data.role }) => {
  const isManager = userRole === UserRole.MANAGER;
  let renderFields;
  if (isManager) {
    renderFields = (
      <>
        <RegularInputGeneral
          varName="tenantContactPersonFirstName"
          placeholder={i18n.t("clause_parties_tenant_placeholder_contact_person_first_name")}
          extraClasses="pr-8"
          label={i18n.t("clause_parties_tenant_contact_person_first_name")}
        />
        <RegularInputGeneral
          varName="tenantContactPersonLastName"
          placeholder={i18n.t("clause_parties_tenant_placeholder_contact_person_last_name")}
          label={i18n.t("clause_parties_tenant_contact_person_last_name")}
        />
        <RegularInputGeneral
          varName="tenantPhone"
          placeholder={i18n.t("clause_parties_tenant_placeholder_phone")}
          extraClasses="pr-8"
          label={i18n.t("clause_parties_tenant_phone")}
        />
        <RegularInputGeneral
          varName="tenantEmail"
          placeholder={i18n.t("clause_parties_tenant_placeholder_email")}
          required
          validationFunction={(e: any) => validator.isEmail(e)}
          label={i18n.t("clause_parties_tenant_email")}
          invalidMessage={i18n.t("validation_message_email")}
        />
      </>
    );
  } else {
    renderFields = (
      <>
        <PlainTextFieldGeneral
          varName="tenantContactPersonFirstName"
          extraClasses="pr-8"
          label={i18n.t("clause_parties_tenant_contact_person_first_name")}
        />
        <PlainTextFieldGeneral
          varName="tenantContactPersonLastName"
          label={i18n.t("clause_parties_tenant_contact_person_last_name")}
        />
        <PlainTextFieldGeneral
          varName="tenantPhone"
          extraClasses="pr-8"
          label={i18n.t("clause_parties_tenant_phone")}
        />
        <PlainTextFieldGeneral
          varName="tenantEmail"
          required
          validationFunction={(e: any) => validator.isEmail(e)}
          label={i18n.t("clause_parties_tenant_email")}
          invalidMessage={i18n.t("validation_message_email")}
        />
      </>
    );
  }
  return (
    <div className="flex flex-wrap mb-8">
      <h4 style={{ fontFamily: "Arial", fontSize: 14 }} className="font-bold my-4 uppercase w-full">
        {i18n.t("clause_parties_tenant_contact")}
      </h4>
      {renderFields}
    </div>
  );
};
