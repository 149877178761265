import { ApplicationError, ErrorLevel } from "src/types";

export const processChanges: producer = ({
  latestVersion = observe.legacyContract.original.latestVersion,
  trigger = observe.legacyContract.triggers.processData,
  removeTrigger = update.legacyContract.triggers.processData,
  user = get.user,
  currentSessionRef = get.legacyContract.currentSession,
  createSession = update.legacyContract.currentSession,
  removeChange = update.legacyContract.currentSession.changes[param.varName],
  saveChangesToDB = update.legacyContract.triggers.saveChangesToDB,
  error = update.errors[param.type],
}) => {
  if (!trigger) {
    return;
  }
  try {
    console.log("processSave");
    removeTrigger.remove();
    const currentSession = currentSessionRef.value();
    if (!currentSession?.changes) {
      return;
    }
    Object.keys(currentSession.changes).forEach((key) => {
      let change = currentSession.changes[key].value;
      if (String(change).length < 1) {
        removeChange.remove({ varName: key });
        delete currentSession.changes[key];
        return;
      }
    });
    // currentSession.createdAt = Date.now();
    currentSession.versionId = latestVersion;
    // currentSession.createdBy = user.value().data.role;
    createSession.set(currentSession);
    saveChangesToDB.set(Date.now());
  } catch (e) {
    console.error("processChanges", e);
    error.set({ message: "error-processing-changes", level: ErrorLevel.WARNING } as ApplicationError, {
      type: "changes",
    });
  }
};
