import { path } from "@c11/engine.runtime";

import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { CalculationFieldAllUsers } from "src/pages/Contract/subviews/ContractForm/RentalPropertyClause/CalculationFieldAllUsers";
import { InsideCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/InsideCard";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import { FactoryFormField } from "src/pages/Contract/subviews/ContractForm/fields/FactoryFormField";
import { CheckboxType2 } from "src/pages/Contract/subviews/ContractForm/fields/CheckboxType2";
import { PlainTextField } from "src/pages/Contract/subviews/ContractForm/fields/PlainTextField";

export const RentalPropertyClause: view = ({
  title,
  gethasStorageUnit = observe.contract.values.hasStorageUnit.displayedValue,
  sethasStorageUnit = update.contract.toSave.changes.hasStorageUnit,
  gethasStorageUnitFromChanges = observe.contract.currentSession.changes.hasStorageUnit.value,
  userRole = get.user.data.role,
}) => {
  const hasStorageUnit = gethasStorageUnitFromChanges || gethasStorageUnit;
  const checked = hasStorageUnit === "true";
  const isManager = userRole.value() === UserRole.MANAGER;
  function handleClick() {
    if (hasStorageUnit === "true") {
      sethasStorageUnit.set({ value: "false", createdAt: Date.now() });
    } else {
      sethasStorageUnit.set({ value: "true", createdAt: Date.now() });
    }
  }

  const _path = path.contract;
  return (
    <ClauseCard title={title}>
      <InsideCard key="Property" title={i18n.t("clause_rental_property_property_title")}>
        <FactoryFormField key="0" fieldName="shoppingMall" varName="shoppingMall" />
        <FactoryFormField key="1" fieldName="propertyAddress" varName="propertyAddress" />
        <FactoryFormField key="2" fieldName="municipality" varName="municipality" />
        <FactoryFormField key="5" fieldName="parcelNumber" varName="parcelNumber" />
        <FactoryFormField key="3" fieldName="propertyLocalNo" varName="propertyLocalNo" />
        <FactoryFormField key="4" fieldName="propertyLevel" varName="propertyLevel" />
        <FactoryFormField key="6" fieldName="propertyExclusiveArea" varName="propertyExclusiveArea" />
        <FactoryFormField key="7" fieldName="propertyCommonArea" varName="propertyCommonArea" />
        <CalculationFieldAllUsers
          key="8"
          label={i18n.t("clause_rental_property_total_areal")}
          readPath={_path.values.propertyTotalArea.displayedValue}
        />
        {isManager && (
          <CheckboxType2
            key="9"
            label={i18n.t("clause_rental_property_store_concept_storage")}
            checked={checked}
            onClick={() => handleClick()}
          />
        )}
      </InsideCard>
      {hasStorageUnit === "true" && (
        <>
          <InsideCard key="storage" title={i18n.t("clause_rental_property_store_concept_storage")}>
            <PlainTextField key="0" fieldName="shoppingMall" varName="shoppingMall" />
            <PlainTextField key="1" fieldName="propertyAddress" varName="propertyAddress" />
            <FactoryFormField key="3" fieldName="storagePropertyLocalNo" varName="storagePropertyLocalNo" />
            <FactoryFormField key="4" fieldName="storagePropertyLevel" varName="storagePropertyLevel" />
            <FactoryFormField key="6" fieldName="storagePropertyExclusiveArea" varName="storagePropertyExclusiveArea" />
            <FactoryFormField key="7" fieldName="storagePropertyCommonArea" varName="storagePropertyCommonArea" />
            <CalculationFieldAllUsers
              key="8"
              label={i18n.t("clause_rental_property_total_areal")}
              readPath={_path.values.storagePropertyTotalArea.displayedValue}
            />
          </InsideCard>
          <InsideCard key="storage rent" title={i18n.t("clause_rental_property_store_concept_storage_rent_title")}>
            <p key="p" className="pb-2 w-full">
              {i18n.t("clause_rental_property_store_concept_storage_rent_description")}
            </p>
            <FactoryFormField key="3" fieldName="storageRent" varName="storageRent" />
          </InsideCard>
        </>
      )}

      <InsideCard key="store" title={i18n.t("clause_rental_property_store_concept_store_title")}>
        <FactoryFormField key="9" fieldName="storeName" varName="storeName" />
        <FactoryFormField key="10" fieldName="storeChain" varName="storeChain" />
        <FactoryFormField key="11" fieldName="storeGroup" varName="storeGroup" />
        <FactoryFormField key="12" fieldName="storeIndustryCode" varName="storeIndustryCode" />
        <FactoryFormField key="13" fieldName="storeConcept" varName="storeConcept" />
      </InsideCard>
      <InsideCard key="store description" title={`2.3 ${i18n.t("clause_rental_property_description_title")}`}>
        <FactoryFormField key="13" fieldName="propertyDescription" varName="propertyDescription" />
      </InsideCard>
    </ClauseCard>
  );
};
