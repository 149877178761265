import {
  variablesOfTypeMultipleOption,
  VariablesSufix,
  variablesWithLongText,
  variablesOfTypeDate,
  variablesOfTypeDropdownWithKeys,
  variablesOfTypeNumber,
  variablesOfBoolean,
} from "src/utils/variablesInfo";
import i18n from "src/producers/languages/i18n";
import moment from "moment";
import _numeral from "src/utils/_numeral";

export const Changes: view = ({ oldValue, newValue, currentVariable }) => {
  let oldValueText;
  let newValueText;

  if (variablesOfTypeMultipleOption.includes(currentVariable)) {
    oldValueText = `${i18n.t("contract_sidebar_changes_vat_option")} ${oldValue}`;
    newValueText = `${i18n.t("contract_sidebar_changes_vat_option")} ${newValue}`;
  }

  if (variablesOfTypeDate.includes(currentVariable)) {
    oldValueText = oldValue && moment(oldValue).format("DD.MM.YY");
    newValueText = moment(newValue).format("DD.MM.YY");
  }
  if (variablesOfTypeNumber.includes(currentVariable)) {
    const valueSuffix = `${VariablesSufix[currentVariable] || ""}`;
    oldValueText = oldValue && `${_numeral(Number(newValue)).format("0,0.[00]")}${valueSuffix}`;
    newValueText = `${_numeral(Number(newValue)).format("0,0.[00]")}${valueSuffix}`;
  }

  if (variablesOfTypeDropdownWithKeys.includes(currentVariable)) {
    oldValueText = `${i18n.t(oldValue)}`;
    newValueText = `${i18n.t(newValue)}`;
  }

  if (variablesOfBoolean.includes(currentVariable)) {
    if (currentVariable === "rentalPeriodHasOptions") {
      newValueText =
        newValue === "true"
          ? i18n.t("activity_log.rentalPeriodHasOptions_true")
          : i18n.t("activity_log.rentalPeriodHasOptions_false");
      oldValueText =
        oldValue === "true"
          ? i18n.t("activity_log.rentalPeriodHasOptions_true")
          : i18n.t("activity_log.rentalPeriodHasOptions_false");
    }
    if (currentVariable === "hasStorageUnit") {
      newValueText =
        newValue === "true"
          ? i18n.t("activity_log.hasStorageUnit_true")
          : i18n.t("activity_log.hasStorageUnit_falsedf");
      oldValueText =
        oldValue === "true" ? i18n.t("activity_log.hasStorageUnit_true") : i18n.t("activity_log.hasStorageUnit_false");
    }
  }

  const regularValueChange = (
    <p>
      {`${i18n.t(currentVariable) || currentVariable}: `}
      {oldValue && (
        <>
          <span className="line-through text-gray-500">{`${oldValueText}`}</span>
          <span>{" -> "}</span>
        </>
      )}
      <span className="">{newValueText}</span>
    </p>
  );

  const longValueChange = (
    <p>
      {i18n.t(currentVariable) || currentVariable}
      {` ${i18n.t("contract_sidebar_changes_changed")} `}
    </p>
  );
  const regularOrLongVariable = variablesWithLongText.includes(currentVariable) ? longValueChange : regularValueChange;

  return (
    <div style={{ padding: "6px 10px" }} className=" klp-border1 flex w-full justify-between">
      {regularOrLongVariable}
    </div>
  );
};
