import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from 'src/types'
import { functions, db, fieldValue } from 'src/producers/firebase'
import { get, isNull, isUndefined, omit, omitBy } from 'lodash'

export const persistAddendumLost: producer = async ({
  contractId = get.contract.id,

  addendums = get.contract.originalv2.addendums,
  updateAddendums = update.contract.originalv2.addendums,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  trigger = observe.contract.triggers.toggleAddendumLost,
  removeTrigger = update.contract.triggers.toggleAddendumLost,
}) => {
  if (!trigger) return
  if (!trigger?.id) return

  contractId = contractId.value()
  if (!contractId) return

  addendums = addendums.value()
  if (!addendums) return
  if (Object.keys(addendums).length < 1) return

  const addendum = addendums[trigger.id]
  const isLost = addendum.isLost

  try {
    isSaving.set(true)

    await db
      .collection('contracts')
      .doc(contractId)
      .set(
        {
          addendums: {
            [trigger.id]: {
              ...addendum,
              isLost: !isLost,
            },
          },
        },
        {
          merge: true,
        }
      )

    await db.collection('contracts').doc(trigger.id).set(
      {
        isLost: !isLost,
      },
      {
        merge: true,
      }
    )

    updateAddendums.merge({
      [trigger.id]: {
        ...addendum,
        isLost: !isLost,
      },
    })
    successfulSave.set(true)

    // setCurrentPath.set(`/contract/${addendum.id}`)
  } catch (e) {
    console.error('ERROR: ', e)
    error.set(
      {
        message: e.message || 'internal-server-error',
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: 'persist-addendum-delete' }
    )
  } finally {
    removeTrigger.remove()

    isSaving.set(false)
  }
}
