import Big from "big.js";

export const securityAmount: producer = ({
  sharedCosts = observe.legacyContract.values.sharedCosts.displayedValue,
  totalArea = observe.legacyContract.values.propertyTotalArea.displayedValue,
  marketingCosts = observe.legacyContract.values.marketingCosts.displayedValue,
  minimumRent = observe.legacyContract.values.minimumRent.displayedValue,
  minimumTurnover = observe.legacyContract.values.MTO.displayedValue,
  securityAlternative = observe.legacyContract.values.securityAlternative.displayedValue,

  oldValue = get.legacyContract.values.securityAmount.displayedValue,

  securityAmount = update.legacyContract.currentSession.changes.securityAmount,
  trigger = observe.legacyContract.custom.securityAmount,
}) => {
  if (
    trigger ||
    !sharedCosts ||
    !totalArea ||
    !marketingCosts ||
    !minimumRent ||
    !minimumTurnover ||
    securityAlternative != 1
  ) {
    return;
  }
  oldValue = oldValue.value();
  minimumRent = new Big(minimumRent);
  sharedCosts = new Big(sharedCosts);
  totalArea = new Big(totalArea);
  marketingCosts = new Big(marketingCosts);
  minimumTurnover = new Big(minimumTurnover);
  const value = minimumRent
    .div(2)
    .plus(sharedCosts.times(totalArea).div(2))
    .plus(marketingCosts.div(100).times(minimumTurnover).div(2))
    .round()
    .toNumber();
  if (oldValue == value) {
    return;
  }
  securityAmount.set({
    value: value,
    createdAt: Date.now(),
    custom: false,
  });
};
