import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'

export const syncEnvelope: producer = ({
  contractId = observe.contract.original.state.id,
  envelopeUid = get.contract.original.state.envelopeInfo.envelopeUid,
  user = get.user,
  updateContracts = update.contract.triggers.updateContracts,
  error = update.errors[param.id],
}) => {
  envelopeUid = envelopeUid.value()
  if (!contractId || !envelopeUid) return
  user = user.value()

  try {
    const payload = {
      action: 'updateEnvelopeStatus',
      contractId,
      envelopeUid: envelopeUid,
      loadingFor: 'syncEnvelope',
    }
    console.log('payload, envelope.ts', payload)
    updateContracts.set(payload)
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'syncEnvelope' }
    )
  }
}
