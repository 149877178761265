import { db } from "src/producers/firebase";
import {ApplicationError, ErrorLevel, ErrorType, LogLevels, UserRole} from "src/types";

export const deleteSessionFromDB: producer = ({
  contractId = observe.contract.id,
  trigger = observe.contract.triggers.deleteSessionFromDB,
  removeTrigger = update.contract.triggers.deleteSessionFromDB,
  rerendersAllFields = update.contract.rerendersAllFields,
  error = update.errors[param.id],
  user = get.user,
}) => {
  user = user.value();
  const isTenant = user?.data?.role === UserRole.TENANT;

  if (!trigger || !user || !contractId) {
    return;
  }

  try {
    console.log("deleted all changes");
    const contractDb = db.collection("contracts").doc(contractId);
    contractDb
      .collection("sessions")
      .doc(isTenant ? UserRole.TENANT : user.uid)
      .delete()
      .then(() => {
        removeTrigger.remove();
        rerendersAllFields.set(Date.now());
      });
  } catch (e) {
    error.set(
      {
        message: e,
        level: LogLevels.WARN,
      } as ApplicationError,
      { id: "deleteSessionFromDB" }
    );
  }
};
