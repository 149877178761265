import { Modal } from "src/components";
import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { TextField } from "@c11/components";
import { sendContract } from "src/pages/Contract/SendContractModal/SendContractModal.sendContract";
import { AgreementType } from "src/types/commands/command";
import { RecipientsForm } from "../RecipientsForm/RecipientsForm";

export const SendContractModal: view = ({
  _viewId,
  message = observe.views[prop._viewId].message,
  agreementType = observe.contract.original.agreementType,
  show = observe.modal.sendContract,
  contractId = get.contract.id,
  userRole = get.user.data.role,
  setMessage = update.views[prop._viewId].message,
  sendContract = update.contract.triggers.sendContract,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!show || !isManager) return null;
  contractId = contractId.value();

  return (
    <Modal
      title={
        agreementType === AgreementType.ADDENDUM
          ? i18n.t("modals.send_addendum_title")
          : i18n.t("modals.send_contract_title")
      }
      okButtonText={
        agreementType === AgreementType.ADDENDUM
          ? i18n.t("contract_navbar.button_send_addendum")
          : i18n.t("contract_navbar.button_send_contract")
      }
      image={() => <SVGs.ModalPlane />}
      onOkClick={() => sendContract.set({ now: Date.now(), payload: { message } })}
      onCancelClick={() => {
        setMessage.remove();
      }}>
      <p className="mb-1">
        {agreementType === AgreementType.ADDENDUM
          ? i18n.t("modals.send_addendum_p1")
          : i18n.t("modals.send_contract_p1")}
      </p>

      <RecipientsForm />

      <div translate={"no"} className="notranslate my-2">
        <TextField
          isRequired
          label={`${i18n.t("modals.send_message_to_tenant")}`}
          value={message}
          onChange={(e: any) => {
            if (e) {
              setMessage.set(e.target.value);
            }
          }}
        />
      </div>
    </Modal>
  );
};

SendContractModal.producers([sendContract]);
