import { functions } from 'src/producers/firebase'
import { UserRole, ApplicationError, ErrorLevel, ErrorType } from 'src/types'

export const updateContracts: producer = async ({
  trigger = observe.contract.triggers.updateContracts,
  removeTrigger = update.contract.triggers.updateContracts,
  updateLoading = update.contract.flags[param.loadingFor],
  role = get.user.data.role,
  error = update.errors[param.id],
  pdfURL = update.contract.original.pdfURL,
}) => {
  if (!trigger || role.value() != UserRole.MANAGER) return
  const loadingFor = trigger.loadingFor || null
  removeTrigger.remove()
  try {
    const updateContracts = functions.httpsCallable('updateContracts', { timeout: 300000 })
    console.log('Updating contracts with action: ', trigger.action)
    const resp = await updateContracts(trigger)
    console.log('update contracts request sent', resp)
  } catch (e) {
    console.error('error updating contracts', e)
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'updateContracts' }
    )
  }
  if (loadingFor) updateLoading.set(false, { loadingFor: loadingFor })
}
