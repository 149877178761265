import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { ApplicationError, ErrorLevel, ErrorType, Language } from "src/types";

export default i18n;

export const i18nProducer: producer = async ({
  trigger = observe.user.isReady,
  user = get.user,
  updateCurrentLang = update.languages.current,
  langIsReady = update.languages.isReady,
  error = update.errors[param.id],
}) => {
  user = user.value();
  if (!user || !user.isReady) {
    return;
  }

  try {
    const defaulLanguage = user.data.language ? user.data.language : Language.NORWEGIAN;
    const path = "/locales/{{lng}}/{{ns}}.json";
    const i18nextOptions: any = {
      lng: defaulLanguage,
      ns: ["common"],
      defaultNS: "common",
      debug: true,
      backend: {
        loadPath: path,
      },
      fallbackLng: Language.ENGLISH,
    };

    await i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(i18nextOptions);
    updateCurrentLang.set(defaulLanguage);
    langIsReady.set(true);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.APPLICATION,
      } as ApplicationError,
      { id: "i18nProducer" }
    );
  }
};
