import { Button } from "@c11/components.button";

import "./LogOutButton.css";
import i18n from "src/producers/languages/i18n";
import { UnlockedIcon } from "src/assets/svg";
import { logOutUser } from "src/pages/Dashboard/LogOutButton/LogOutButton.logOutUser";

import styles from "../styles.module.css"

export const LogOutButton: view = ({ triggerLogout = update.pages.dashboard.triggers.logout }) => {
  return (
    <Button className={`${styles.logOutButton + " " + styles.button}`} variant="secondary" onClick={() => triggerLogout.set(Date.now())}>
      <UnlockedIcon />
      <span className="ml-2 uppercase">{i18n.t("dashboard.logout_button")}</span>
    </Button>
  );
};

LogOutButton.producers([logOutUser]);
