import { PDFDocument } from "pdf-lib";
import { storageRef } from "src/producers/firebase";
import { ApplicationError, ErrorLevel } from "src/types";

export const getAppendicesPDF: producer = async ({
  trigger = observe.legacyContract.triggers.getAllAppendicesPDF,
  removeTrigger = update.legacyContract.triggers.getAllAppendicesPDF,

  appendices = observe.legacyContract.original.appendices,
  saveAppendicesPDF = update.legacyContract.pdf.appendices,
  contractId = observe.legacyContract.id,
  removeLoading = update.legacyContract.flags.loadingpdf,
  error = update.errors[param.type],
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  if (!contractId) return;
  try {
    saveAppendicesPDF.set(null);
    if (appendices) {
      let filesBuffer: any = await Promise.all(
        appendices.map(async (appendix: any, index: any) => {
          const appendixRef = storageRef.child(`${contractId}/${appendix.fileId}`);
          const url = await appendixRef.getDownloadURL();
          const fetchResponse = await fetch(url);
          const responseBuffer = await fetchResponse.arrayBuffer();
          return responseBuffer;
        })
      );
      const appendixDoc = await PDFDocument.create();
      for (let index = 0; index < appendices.length; index++) {
        const appendix = appendices[index];
        if (["image/png", "image/jpg", "image/jpeg"].includes(appendix.fileType)) {
          const page = appendixDoc.addPage();
          const image =
            appendix.fileType === "image/png"
              ? await appendixDoc.embedPng(filesBuffer[index])
              : await appendixDoc.embedJpg(filesBuffer[index]);
          const newWidth =
            image.width > page.getWidth() - 20
              ? image.scaleToFit(page.getWidth() - 20, page.getHeight() - 20).width
              : image.width;
          const newHeight =
            image.height > page.getHeight() - 20
              ? image.scaleToFit(page.getWidth() - 20, page.getHeight() - 20).height
              : image.height;
          page.drawImage(image, {
            x: 10,
            y: page.getHeight() - 10 - newHeight,
            width: newWidth,
            height: newHeight,
          });
        } else if (appendix.fileType === "application/pdf") {
          const document = await PDFDocument.load(filesBuffer[index]);
          const copiedPages = await appendixDoc.copyPages(document, document.getPageIndices());
          copiedPages.forEach((page) => appendixDoc.addPage(page));
        }
      }
      const doc = await appendixDoc.save();
      saveAppendicesPDF.set(doc);
    }
  } catch (e) {
    removeLoading.remove();
    console.error("generatePDF", e);
    error.set({ message: "error-generating-pdf", level: ErrorLevel.LOW } as ApplicationError, { type: "pdf" });
  }
};
