import { RouteName } from "src/types";

export const contract: producer = ({
  isMounted = observe.routes.list[RouteName.LEGACY_CONTRACT].isMounted,
  clearAll = update.legacyContract,
  listeners = get.listenersOld,
  updateListeners = update.listenersOld,
}) => {
  if (isMounted) return;
  listeners = listeners.value();
  clearAll.remove();
  for (const contractId in listeners) {
    const lists = listeners[contractId];
    for (const listener in lists) {
      lists[listener]();
    }
  }
  updateListeners.remove();
};
