import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const populateTenant: producer = async ({
  trigger = observe.contract.triggers.populateTenant,
  removeTrigger = update.contract.triggers.populateTenant,
  writeValue = update.contract.toSave.changes[param.varName],
  selectedCP = update.contract.temp.selectedCP,
  populateContactPerson = update.contract.triggers.populateContactPerson,
  error = update.errors[param.id],
}) => {
  if (!trigger) {
    return;
  }

  removeTrigger.remove();
  const tenant = trigger;

  try {
    const numberOfContacts = Object.keys(tenant.mainContacts)?.length;
    if (numberOfContacts <= 1) {
      selectedCP.set(0);
      populateContactPerson.set(Date.now());
    } else {
      //reset ContactPerson
      writeValue.set(
        {
          value: "",
          createdAt: Date.now(),
        },
        { varName: "tenantContactPersonFirstName" }
      );
      writeValue.set(
        {
          value: "",
          createdAt: Date.now(),
        },
        { varName: "tenantContactPersonLastName" }
      );
      writeValue.set(
        {
          value: "",
          createdAt: Date.now(),
        },
        { varName: "tenantPhone" }
      );
      writeValue.set(
        {
          value: "",
          createdAt: Date.now(),
        },
        { varName: "tenantEmail" }
      );
    }

    writeValue.set(
      {
        value: tenant.name || "",
        createdAt: Date.now(),
      },
      { varName: "tenantName" }
    );

    writeValue.set(
      {
        value: tenant.address || "",
        createdAt: Date.now(),
      },
      { varName: "tenantAddress" }
    );

    writeValue.set(
      {
        value: tenant.invoiceAddress || "",
        createdAt: Date.now(),
      },
      { varName: "tenantInvoiceAddress" }
    );

    writeValue.set(
      {
        value: tenant.postalAddress || "",
        createdAt: Date.now(),
      },
      { varName: "tenantPostalAddress" }
    );
    if (tenant.orgNumber) {
      writeValue.set(
        {
          value: tenant.orgNumber?.replace(/\s+/g, "") || "",
          createdAt: Date.now(),
        },
        { varName: "tenantOrgNo" }
      );
    }
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.LOW,
        type: ErrorType.APPLICATION,
      } as ApplicationError,
      { id: "populateTenant-addendum" }
    );
  }
};
