export interface ApplicationError {
  message: string;
  level: ErrorLevel | LogLevels
  detailed?: any;
  type?: ErrorType;
}

export enum ErrorLevel {
  LOW = "LOW",
  BREAKING = "BREAKING",
  WARNING = "WARNING",
}

export enum LogLevels {
  TRACE = "TRACE",
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  FATAL = "FATAL",
}

export enum ErrorType {
  APPLICATION = "APPLICATION",
  SERVER = "SERVER",
}
