import { Spinner } from "@c11/components";
import { Divider } from "antd";

import styles from "./styles.module.css";

interface ThumbnailCardProps {
  index: number;
  onClick: () => void;
  children: any;
  className: string;
  isLoading: boolean;
}

interface ThumbnailCardHeaderProps {
  title: string;
  children: any;
  className: string;
  align: "start" | "center" | "end";
  separator: boolean;
  subtitle: string;
}

interface ThumbnailCardBodyProps {
  children: any;
  className: any;
  align: "start" | "center" | "end";
}

interface ThumbnailCardFooterProps {
  children: any;
  className: any;
  align: "start" | "center" | "end";
}

export const ThumbnailCard: view<ThumbnailCardProps> = ({
  index,
  onClick,
  children,
  className,
  isLoading,
}: ThumbnailCardProps) => {
  return (
    <div className={`${className} ${styles.thumbnailCard} ${!!onClick ? "cursor-pointer" : ""}`} onClick={onClick}>
      {index && (
        <div className="relative ml-auto mr-8">
          <div className={`${styles.thumbnailInfo}`}>
            <span className="font-medium text-gray-700">{`${index}. `}</span>
          </div>
        </div>
      )}
      {isLoading ? <Spinner color="primary" size="medium" /> : children}
    </div>
  );
};

export const ThumbnailCardHeader: view<ThumbnailCardHeaderProps> = ({
  title,
  children,
  separator,
  subtitle,
  className,
  align,
}: ThumbnailCardHeaderProps) => {
  if (!align) align = "start";

  return (
    <div className={`${styles.thumbnailCardHeader}`}>
      <div className={`place-items-${align} ${className}`}>
        {title && <h3>{title}</h3>}
        {children}
      </div>
      {subtitle && <div className="font-roboto text-sm text-gray-600">{subtitle}</div>}
      {separator && <Divider className="mt-1 mb-1" type="horizontal" />}
    </div>
  );
};
export const ThumbnailCardHeaderV2: view<ThumbnailCardHeaderProps> = ({
  title,
  children,
  subtitle,
  className,
  align,
}: ThumbnailCardHeaderProps) => {
  if (!align) align = "start";

  return (
    <div className={`${styles.thumbnailCardHeader}`}>
      <div className={`place-items-${align} ${className}`}>
        <div>
          {title && <h3>{title}</h3>}
          {subtitle && <div className="font-roboto text-sm text-gray-600">{subtitle}</div>}
        </div>
        {children}
      </div>
    </div>
  );
};

export const ThumbnailCardBody: view<ThumbnailCardBodyProps> = ({
  children,
  className,
  align,
}: ThumbnailCardBodyProps) => {
  if (!align) align = "start";

  return <div className={`place-content-${align} ${className} ${styles.thumbnailCardBody}`}>{children}</div>;
};

export const ThumbnailCardFooter: view<ThumbnailCardFooterProps> = ({
  children,
  className,
  align,
}: ThumbnailCardFooterProps) => {
  if (!align) align = "start";

  return <div className={` place-content-${align} ${className} ${styles.thumbnailCardFooter}`}>{children}</div>;
};
