import React from "react";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import { InsideCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/InsideCard";
import { RegularInputGeneral } from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import { TenantCPDropdown } from "src/pages/Contract/subviews/ContractForm/PartiesClause/TenantCPDropdown";
import { TenantInput } from "src/pages/Contract/subviews/ContractForm/PartiesClause/TenantInput";
import i18n from "src/producers/languages/i18n";
import validator from "validator";
import styles from "../../styles.module.css";
import { FreeTextGeneralClause } from "./FreeTextGeneralClause";
import { populateTenant } from "./TransportAddendum.populateTenant";

export const TransportAddendum: view = ({}) => (
  <div>
    <FreeTextGeneralClause varName="backgroundAndPurpose" title={`1. ${i18n.t("clause_background_reason_title")}`} />
    <ClauseCard title={`2. ${i18n.t("clause_new_tenant_title")}`}>
      <InsideCard>
        <div className={`${styles.partyFormRow}`}>
          <TenantInput />
          <TenantCPDropdown />
          <RegularInputGeneral
            varName="tenantName"
            placeholder={i18n.t("clause_parties_tenant_placeholder_name")}
            label={i18n.t("clause_parties_tenant_name")}
          />
          <RegularInputGeneral
            varName="tenantOrgNo"
            placeholder={i18n.t("clause_parties_tenant_placeholder_organisation_no")}
            label={i18n.t("clause_parties_tenant_organisation_no")}
          />
          <RegularInputGeneral
            varName="tenantAddress"
            placeholder={i18n.t("clause_parties_tenant_placeholder_address")}
            label={i18n.t("clause_parties_tenant_address")}
          />
          <RegularInputGeneral
            varName="tenantPostalAddress"
            placeholder={i18n.t("clause_parties_tenant__placeholder_postal_address")}
            label={i18n.t("clause_parties_tenant_postal_address")}
          />
          <RegularInputGeneral
            varName="tenantInvoiceAddress"
            placeholder={i18n.t("clause_parties_tenant_invoice_address_p")}
            label={i18n.t("clause_parties_tenant_invoice_address")}
          />
        </div>
        <h4 style={{ fontFamily: "Arial", fontSize: 14 }} className="font-bold my-4 uppercase w-full">
          {i18n.t("clause_parties_tenant_contact")}
        </h4>
        <div className={`${styles.partyFormRow}`}>
          <RegularInputGeneral
            varName="tenantContactPersonFirstName"
            placeholder={i18n.t("clause_parties_tenant_placeholder_contact_person_first_name")}
            label={i18n.t("clause_parties_tenant_contact_person_first_name")}
          />
          <RegularInputGeneral
            varName="tenantContactPersonLastName"
            placeholder={i18n.t("clause_parties_tenant_placeholder_contact_person_last_name")}
            label={i18n.t("clause_parties_tenant_contact_person_last_name")}
          />

          <RegularInputGeneral
            varName="tenantPhone"
            placeholder={i18n.t("clause_parties_tenant_placeholder_phone")}
            label={i18n.t("clause_parties_tenant_phone")}
          />
          <RegularInputGeneral
            varName="tenantEmail"
            placeholder={i18n.t("clause_parties_tenant_placeholder_email")}
            required
            validationFunction={(e: any) => validator.isEmail(e)}
            label={i18n.t("clause_parties_tenant_email")}
            invalidMessage={i18n.t("validation_message_email")}
          />
        </div>
      </InsideCard>
    </ClauseCard>
    <FreeTextGeneralClause varName="conditionsOfTansport" title={`3. ${i18n.t("clause_conditions_transport_title")}`} />
    <FreeTextGeneralClause varName="cumpulsoryDerogation" title={`4. ${i18n.t("clause_adoption_derogation_title")}`} />
    <FreeTextGeneralClause varName="otherProvisions" title={`5. ${i18n.t("clause_other_provisions_title")}`} />
  </div>
);

TransportAddendum.producers([populateTenant]);
