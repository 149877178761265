import { InsideCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/InsideCard";
import i18n from "src/producers/languages/i18n";
import { PlainTextFieldGeneral } from "src/pages/Contract/subviews/ContractForm/fields/PlainTextFieldGeneral";
import styles from "../../../../styles.module.css";
import { FieldInputType } from "src/templates/types/template";
import { RegularInputGeneral } from "../../fields/FormField";
import validator from "validator";

export const PartiesClauseTenant: view = () => (
  <>
    <InsideCard key="Landlord" title={i18n.t("clause_parties_landlord_title")}>
      <div className={`${styles.partyFormRow}`}>
        <PlainTextFieldGeneral
          key="0"
          label={i18n.t("clause_parties_landlord_landlordName")}
          inputType={FieldInputType.Text}
          varName="landlordName"
        />
        <PlainTextFieldGeneral
          key="1"
          label={i18n.t("clause_parties_landlord_organisation_no")}
          inputType={FieldInputType.Text}
          varName="landlordOrgNo"
        />
        <PlainTextFieldGeneral
          key="2"
          label={i18n.t("clause_parties_landlord_address")}
          inputType={FieldInputType.Text}
          varName="landlordAddress"
        />
        <PlainTextFieldGeneral
          key="3"
          label={i18n.t("clause_parties_landlord_postal_address")}
          inputType={FieldInputType.Text}
          varName="landlordPostalAddress"
        />
        <PlainTextFieldGeneral
          key="4"
          label={i18n.t("clause_parties_landlord_invoice_address")}
          inputType={FieldInputType.Text}
          varName="landlordInvoiceAddress"
        />
        <PlainTextFieldGeneral
          key="9"
          label={i18n.t("clause_parties_landlord_accountNo")}
          inputType={FieldInputType.Text}
          varName="landlordAccountNo"
        />
        <div className={styles.fullWidth}>
          <PlainTextFieldGeneral
            key="40"
            label={i18n.t("clause_parties_landlord_landlord_property_manager")}
            inputType={FieldInputType.Text}
            varName="landlordPropertyManager"
            extraClasses="w-full"
          />
        </div>

        <PlainTextFieldGeneral
          key="6"
          label={i18n.t("clause_parties_landlord_contact_person_first_name_p")}
          inputType={FieldInputType.Text}
          varName="landlordContactPersonFirstName"
        />
        <PlainTextFieldGeneral
          key="7"
          label={i18n.t("clause_parties_landlord_contact_person_last_name")}
          inputType={FieldInputType.Text}
          varName="landlordContactPersonLastName"
        />
        <PlainTextFieldGeneral
          key="8"
          label={i18n.t("clause_parties_landlord_phone")}
          inputType={FieldInputType.Text}
          varName="landlordPhone"
        />
        <PlainTextFieldGeneral
          key="5"
          label={i18n.t("clause_parties_landlord_email")}
          inputType={FieldInputType.Text}
          varName="landlordEmail"
        />
      </div>
    </InsideCard>
    <InsideCard title={i18n.t("clause_parties_tenant_title")} editableByTenant>
      <div className={`${styles.partyFormRow}`}>
        <RegularInputGeneral
          varName="tenantName"
          placeholder={i18n.t("clause_parties_tenant_placeholder_name")}
          label={i18n.t("clause_parties_tenant_name")}
        />
        <RegularInputGeneral
          varName="tenantOrgNo"
          placeholder={i18n.t("clause_parties_tenant_placeholder_organisation_no")}
          label={i18n.t("clause_parties_tenant_organisation_no")}
        />
        <RegularInputGeneral
          varName="tenantAddress"
          placeholder={i18n.t("clause_parties_tenant_placeholder_address")}
          label={i18n.t("clause_parties_tenant_address")}
        />
        <RegularInputGeneral
          varName="tenantPostalAddress"
          placeholder={i18n.t("clause_parties_tenant__placeholder_postal_address")}
          label={i18n.t("clause_parties_tenant_postal_address")}
        />
        <RegularInputGeneral
          varName="tenantInvoiceAddress"
          placeholder={i18n.t("clause_parties_tenant_invoice_address_p")}
          label={i18n.t("clause_parties_tenant_invoice_address")}
        />
      </div>
      <h4 style={{ fontFamily: "Arial", fontSize: 14 }} className="font-bold my-4 uppercase w-full">
        {i18n.t("clause_parties_tenant_contact")}
      </h4>
      <div className={`${styles.partyFormRow}`}>
        <RegularInputGeneral
          varName="tenantContactPersonFirstName"
          placeholder={i18n.t("clause_parties_tenant_placeholder_contact_person_first_name")}
          label={i18n.t("clause_parties_tenant_contact_person_first_name")}
        />
        <RegularInputGeneral
          varName="tenantContactPersonLastName"
          placeholder={i18n.t("clause_parties_tenant_placeholder_contact_person_last_name")}
          label={i18n.t("clause_parties_tenant_contact_person_last_name")}
        />

        <RegularInputGeneral
          varName="tenantPhone"
          placeholder={i18n.t("clause_parties_tenant_placeholder_phone")}
          label={i18n.t("clause_parties_tenant_phone")}
        />
        <RegularInputGeneral
          varName="tenantEmail"
          placeholder={i18n.t("clause_parties_tenant_placeholder_email")}
          required
          validationFunction={(e: any) => validator.isEmail(e)}
          label={i18n.t("clause_parties_tenant_email")}
          invalidMessage={i18n.t("validation_message_email")}
        />
      </div>
    </InsideCard>
  </>
);
