import React from "react";

import { ContractStatus, UserRole } from "src/types";

export const InsideCard: view = ({
  title,
  children,
  editableByTenant,
  userRole = get.user.data.role,
  status = observe.legacyContract.original.status,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const hasWhiteBackground = (editableByTenant && status == ContractStatus.NEGOTIATION) || isManager;
  return (
    <div className={`mb-8 p-5 border klp-border1 ${hasWhiteBackground && "bg-white"}`}>
      <h4 style={{ fontFamily: "Arial", fontSize: 14 }} className="font-bold mb-6 uppercase">
        {title}
      </h4>
      <div className="flex flex-wrap">{children}</div>
    </div>
  );
};
