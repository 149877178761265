import React from "react";

import { Tooltip } from "antd";

export const TooltipWrapper: view = ({ children, disabled, text, placement, open }) => {
  if (disabled) return <div className="px-2">{children}</div>;
  const localPlacement = placement || "bottom";
  return (
    <Tooltip open={open} placement={localPlacement} title={text}>
      <div className="px-2">{children}</div>
    </Tooltip>
  );
};
