'use strict'
// module.exports = validate20
// module.exports.default = validate20
export default validate20
const schema22 = {
  '$id': 'CANCELLATION',
  'type': 'object',
  'definitions': {
    'email': { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' },
    'phone': { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
  },
  'properties': {
    'tenantContactPersonFirstName': { 'type': 'string' },
    'tenantContactPersonLastName': { 'type': 'string' },
    'tenantPhone': { '$ref': '#/definitions/phone' },
    'tenantEmail': { '$ref': '#/definitions/email' },
    'title': { 'type': 'string' },
    'backgroundAndPurpose': { 'type': 'string' },
    'otherProvisions': { 'type': 'string' },
    'tenancyCancellation': { 'type': 'string' }
  },
  'required': ['tenantEmail', 'title', 'backgroundAndPurpose', 'tenancyCancellation']
}
const schema23 = { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
const schema24 = { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' }
const pattern0 = new RegExp('^[+]?[\\d\\s()-]{7,}$', 'u')
const pattern1 = new RegExp('^\\S+@\\S+\\.\\S+$', 'u')

function validate20 (data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {/*# sourceURL="CANCELLATION" */
  let vErrors = null
  let errors = 0
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.tenantEmail === undefined) {
      const err0 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenantEmail' },
        message: 'must have required property \'' + 'tenantEmail' + '\''
      }
      if (vErrors === null) {vErrors = [err0]} else {vErrors.push(err0)}
      errors++
    }
    if (data.title === undefined) {
      const err1 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'title' },
        message: 'must have required property \'' + 'title' + '\''
      }
      if (vErrors === null) {vErrors = [err1]} else {vErrors.push(err1)}
      errors++
    }
    if (data.backgroundAndPurpose === undefined) {
      const err2 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'backgroundAndPurpose' },
        message: 'must have required property \'' + 'backgroundAndPurpose' + '\''
      }
      if (vErrors === null) {vErrors = [err2]} else {vErrors.push(err2)}
      errors++
    }
    if (data.tenancyCancellation === undefined) {
      const err3 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenancyCancellation' },
        message: 'must have required property \'' + 'tenancyCancellation' + '\''
      }
      if (vErrors === null) {vErrors = [err3]} else {vErrors.push(err3)}
      errors++
    }
    if (data.tenantContactPersonFirstName !== undefined) {
      if (typeof data.tenantContactPersonFirstName !== 'string') {
        const err4 = {
          instancePath: instancePath + '/tenantContactPersonFirstName',
          schemaPath: '#/properties/tenantContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err4]} else {vErrors.push(err4)}
        errors++
      }
    }
    if (data.tenantContactPersonLastName !== undefined) {
      if (typeof data.tenantContactPersonLastName !== 'string') {
        const err5 = {
          instancePath: instancePath + '/tenantContactPersonLastName',
          schemaPath: '#/properties/tenantContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err5]} else {vErrors.push(err5)}
        errors++
      }
    }
    if (data.tenantPhone !== undefined) {
      let data2 = data.tenantPhone
      if (typeof data2 === 'string') {
        if (!pattern0.test(data2)) {
          const err6 = {
            instancePath: instancePath + '/tenantPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {vErrors = [err6]} else {vErrors.push(err6)}
          errors++
        }
      } else {
        const err7 = {
          instancePath: instancePath + '/tenantPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err7]} else {vErrors.push(err7)}
        errors++
      }
    }
    if (data.tenantEmail !== undefined) {
      let data3 = data.tenantEmail
      if (typeof data3 === 'string') {
        if (!pattern1.test(data3)) {
          const err8 = {
            instancePath: instancePath + '/tenantEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {vErrors = [err8]} else {vErrors.push(err8)}
          errors++
        }
      } else {
        const err9 = {
          instancePath: instancePath + '/tenantEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err9]} else {vErrors.push(err9)}
        errors++
      }
    }
    if (data.title !== undefined) {
      if (typeof data.title !== 'string') {
        const err10 = {
          instancePath: instancePath + '/title',
          schemaPath: '#/properties/title/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err10]} else {vErrors.push(err10)}
        errors++
      }
    }
    if (data.backgroundAndPurpose !== undefined) {
      if (typeof data.backgroundAndPurpose !== 'string') {
        const err11 = {
          instancePath: instancePath + '/backgroundAndPurpose',
          schemaPath: '#/properties/backgroundAndPurpose/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err11]} else {vErrors.push(err11)}
        errors++
      }
    }
    if (data.otherProvisions !== undefined) {
      if (typeof data.otherProvisions !== 'string') {
        const err12 = {
          instancePath: instancePath + '/otherProvisions',
          schemaPath: '#/properties/otherProvisions/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err12]} else {vErrors.push(err12)}
        errors++
      }
    }
    if (data.tenancyCancellation !== undefined) {
      if (typeof data.tenancyCancellation !== 'string') {
        const err13 = {
          instancePath: instancePath + '/tenancyCancellation',
          schemaPath: '#/properties/tenancyCancellation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err13]} else {vErrors.push(err13)}
        errors++
      }
    }
  } else {
    const err14 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object'
    }
    if (vErrors === null) {vErrors = [err14]} else {vErrors.push(err14)}
    errors++
  }
  validate20.errors = vErrors
  return errors === 0
}
