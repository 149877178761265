import { RadioButton, RadioButtonGroup, Spinner } from '@c11/components'
import { NumberField, NumberFieldReadOnly } from 'src/components_generic/NumberField'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import { v4 } from 'uuid'
import styles from '../../../../../styles.module.css'
import { ReactNumberInput } from 'src/components_generic/ReactNumberInput'
import { InvalidMessage } from 'src/pages/ContractV2/components/InvalidMessage'
import { ValidationResult } from 'src/pages/ContractV2/components/ValidationResult'
import { VisibleIfInvalidField } from 'src/pages/ContractV2/components/VisibleIfInvalidField'

enum VATInputFields {
  BUSINESS_VAT_STATUS = 'businessVATStatus',
  BUSINESS_VAT_PERCENTAGE = 'businessVATPercentage'
}

export enum VATType {
  EXCLUSIVELY_WITHIN_VAT = 'exclusively_within_vat',
  EXCLUSIVELY_OUTSIDE_VAT = 'exclusively_outside_vat',
  PARTIALLY_WITHIN_VAT = 'partially_within_vat'
}

export const VAT: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <VATComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <VATComponentReadOnly/>
    </VisibleIf>
  </>
}

const VATComponent: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  setMiscChanges = update.contract.changes.miscellaneous[param.name]
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const GetVATStatus = () => fields[VATInputFields.BUSINESS_VAT_STATUS]?.value
  const GetVATPercentage = () => fields[VATInputFields.BUSINESS_VAT_PERCENTAGE]?.value

  return <div>
    <h2>5. {i18n.t('contract.general.vat')}</h2>

    <div className={`${styles.cardSection}`}>
      <div className="flex flex-col justify-center">
        <VisibleIfInvalidField name={VATInputFields.BUSINESS_VAT_STATUS}>
          <div className="text-pink-600 text-xs mb-4 ml-8">
            {i18n.t('errors.vat_value_not_selected')}
          </div>
        </VisibleIfInvalidField>

        <RadioButtonGroup selectedValue={GetVATStatus()} className="flex flex-col gap-4">
          <RadioButton
            key={v4()}
            className='items-center'
            value={VATType.EXCLUSIVELY_WITHIN_VAT}
            isDisabled={false}
            onClick={() => {
              setMiscChanges.set({
                value: VATType.EXCLUSIVELY_WITHIN_VAT
              }, {
                name: VATInputFields.BUSINESS_VAT_STATUS
              })
            }}>
            {i18n.t(`contract.fields.${VATInputFields.BUSINESS_VAT_STATUS}.exclusively_within_vat`)}

          </RadioButton>
          <RadioButton
            key={v4()}
            className='items-center'
            value={VATType.EXCLUSIVELY_OUTSIDE_VAT}
            isDisabled={false}
            onClick={() => {
              setMiscChanges.set({
                value: VATType.EXCLUSIVELY_OUTSIDE_VAT
              }, {
                name: VATInputFields.BUSINESS_VAT_STATUS
              })
            }}>
            {i18n.t(`contract.fields.${VATInputFields.BUSINESS_VAT_STATUS}.exclusively_outside_vat`)}
          </RadioButton>
          <RadioButton
            key={v4()}
            className='items-center justify-center'
            value={VATType.PARTIALLY_WITHIN_VAT}
            isDisabled={false}
            onClick={() => {
              setMiscChanges.set({
                value: VATType.PARTIALLY_WITHIN_VAT
              }, {
                name: VATInputFields.BUSINESS_VAT_STATUS
              })
            }}>
            <div className="flex items-center gap-6">
              <VisibleIf predicate={GetVATStatus() === VATType.PARTIALLY_WITHIN_VAT}>
                <div className="w-1/2 mb-2">
                  <ReactNumberInput
                    // label={i18n.t(`contract.labels.${VATInputFields.BUSINESS_VAT_PERCENTAGE}`)}
                    name={VATInputFields.BUSINESS_VAT_PERCENTAGE}
                    value={GetVATPercentage()}
                    // required={true}
                    onChange={(e) => {
                      setChanges.set({
                        value: e
                      }, {
                        name: VATInputFields.BUSINESS_VAT_PERCENTAGE
                      })
                    }}
                  />
                </div>
              </VisibleIf>
              {i18n.t(`contract.fields.${VATInputFields.BUSINESS_VAT_STATUS}.partially_within_vat`)}
            </div>
          </RadioButton>
        </RadioButtonGroup>
      </div>
    </div>
  </div>
}

const VATComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields
}) => {
  const GetVATStatus = () => fields[VATInputFields.BUSINESS_VAT_STATUS]?.value
  const GetVATPercentage = () => fields[VATInputFields.BUSINESS_VAT_PERCENTAGE]?.value

  return <div>
    <h2>5. {i18n.t('contract.general.vat')}</h2>

    <div className={`${styles.cardSection}`}>
      <div className="flex flex-row items-center">
        <RadioButtonGroup selectedValue={GetVATStatus()} className="flex flex-col gap-4">
          <RadioButton
            key={v4()}
            value={VATType.EXCLUSIVELY_WITHIN_VAT}
            isDisabled={true}>
            {i18n.t(`contract.fields.${VATInputFields.BUSINESS_VAT_STATUS}.exclusively_within_vat`)}

          </RadioButton>
          <RadioButton
            key={v4()}
            value={VATType.EXCLUSIVELY_OUTSIDE_VAT}
            isDisabled={true}>
            {i18n.t(`contract.fields.${VATInputFields.BUSINESS_VAT_STATUS}.exclusively_outside_vat`)}
          </RadioButton>
          <RadioButton
            key={v4()}
            value={VATType.PARTIALLY_WITHIN_VAT}
            isDisabled={true}>
            <div className="flex items-center gap-6">
              <VisibleIf predicate={GetVATStatus() === VATType.PARTIALLY_WITHIN_VAT}>
                <div className="w-1/2">
                  <NumberFieldReadOnly
                    label={i18n.t(`contract.labels.${VATInputFields.BUSINESS_VAT_PERCENTAGE}`)}
                    // name={VATInputFields.BUSINESS_VAT_PERCENTAGE}
                    value={GetVATPercentage()}
                    required={true}
                  />
                </div>
              </VisibleIf>
              {i18n.t(`contract.fields.${VATInputFields.BUSINESS_VAT_STATUS}.partially_within_vat`)}
            </div>
          </RadioButton>
        </RadioButtonGroup>
      </div>
    </div>
  </div>
}
