import { Checkbox, Spinner } from '@c11/components'
import FormatNumber from 'format-number'
import { Dropdown, Menu, MenuProps } from 'antd'
import { TextEditor, TextEditorReadOnly } from 'src/components'
import { DropdownField } from 'src/components_generic/DropdownField'
import { InputFieldLabel } from 'src/components_generic/InputFieldLabel'
import { NumberField, NumberFieldReadOnly } from 'src/components_generic/NumberField'
import { TextField, TextFieldReadOnly } from 'src/components_generic/TextField'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'

import styles from '../../../../../styles.module.css'
import { ReactNumberInput } from 'src/components_generic/ReactNumberInput'
import { InvalidMessage } from 'src/pages/ContractV2/components/InvalidMessage'

export const RentalProperty: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <RentalPropertyComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <RentalPropertyComponentReadOnly/>
    </VisibleIf>
  </>
}

export enum RentalPropertyInputFields {
  SHOPPING_MALL = 'shoppingMall',
  ADDRESS = 'propertyAddress',
  MUNICIPALITY = 'municipality',
  PARCEL_NUMBER = 'parcelNumber',
  LOCAL_NUMBER = 'propertyLocalNo',
  LEVEL = 'propertyLevel',
  APPROX_EXCLUSIVE_AREA = 'propertyExclusiveArea',
  COMMON_AREA = 'propertyCommonArea',
  TOTAL_AREA = 'propertyTotalArea',

  HAS_STORAGE_UNIT = 'propertyHasStorageUnit',

  PROPERTY_DESCRIPTION = 'propertyDescription',
}

export enum StorageUnitInputFields {
  SHOPPING_MALL = 'shoppingMall',
  ADDRESS = 'propertyAddress',
  LOCAL_NUMBER = 'storagePropertyLocalNo',
  LEVEL = 'storagePropertyLevel',
  APPROX_EXCLUSIVE_AREA = 'storagePropertyExclusiveArea',
  COMMON_AREA = 'storagePropertyCommonArea',
  TOTAL_AREA = 'storagePropertyTotalArea'
}

enum StorageRentInputFields {
  RENT_VALUE = 'storageRent'
}

enum StoreInputFields {
  NAME = 'storeName',
  CHAIN = 'storeChain',
  GROUP_NAME = 'storeGroup',
  INDUSTRY_CODE = 'storeIndustryCode',
  STORE_CONCEPT = 'storeConcept'
}

enum IndustryCodes {
  FOOD_AND_BEVERAGE = 'food-and-beverage',
  CLOTHES_AND_SHOES = 'clothes-and-shoes',
  SPECIAL_STORES = 'special-stores',
  HOUSE_AND_HOME = 'house-and-home',
  SERVICE_AND_SERVICE_PROVIDERS = 'service-and-service-providers',
  SERVING = 'serving',
  OTHER_RETAIL = 'other-retail',
  OTHER_BUSINESS = 'other-business'
}

const AreaFormatter = FormatNumber({
  suffix: 'm²',
  integerSeparator: '.',
  decimal: ',',
  round: 2
})

const CurrencyFormatter = FormatNumber({
  integerSeparator: '.',
  decimal: ',',
  round: 2
})

const RentalPropertyComponent: view = ({
  fields = observe.contract.originalv2.fields,
  building = observe.contract.originalv2.building,
  setChanges = update.contract.changes.fields[param.name],
  setMiscChanges = update.contract.changes.miscellaneous[param.name],
  setTime = update.timestampNow
}) => {
  const HasStorageUnit = () => fields[RentalPropertyInputFields.HAS_STORAGE_UNIT]?.value

  return <div>
    <h2>2. {i18n.t(`contract.general.rental_property`)}</h2>
    <div className={`${styles.cardSectionColumn}`}>
      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">2.1 {i18n.t(`contract.general.property`)}</div>
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.SHOPPING_MALL}`)}
            name={RentalPropertyInputFields.SHOPPING_MALL}
            required={true}
            value={(fields[RentalPropertyInputFields.SHOPPING_MALL]?.value || null) as string}
            placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.SHOPPING_MALL}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalPropertyInputFields.SHOPPING_MALL
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.ADDRESS}`)}
            name={RentalPropertyInputFields.ADDRESS}
            required={true}
            value={(fields[RentalPropertyInputFields.ADDRESS]?.value || null) as string}
            placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.ADDRESS}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalPropertyInputFields.ADDRESS
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.MUNICIPALITY}`)}
            name={RentalPropertyInputFields.MUNICIPALITY}
            required={true}
            value={(fields[RentalPropertyInputFields.MUNICIPALITY]?.value || null) as string}
            placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.MUNICIPALITY}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalPropertyInputFields.MUNICIPALITY
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.PARCEL_NUMBER}`)}
            name={RentalPropertyInputFields.PARCEL_NUMBER}
            required={true}
            value={(fields[RentalPropertyInputFields.PARCEL_NUMBER]?.value || null) as string}
            placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.PARCEL_NUMBER}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalPropertyInputFields.PARCEL_NUMBER
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.LOCAL_NUMBER}`)}
            name={RentalPropertyInputFields.LOCAL_NUMBER}
            required={true}
            value={(fields[RentalPropertyInputFields.LOCAL_NUMBER]?.value || null) as string}
            placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.LOCAL_NUMBER}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalPropertyInputFields.LOCAL_NUMBER
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.LEVEL}`)}
            name={RentalPropertyInputFields.LEVEL}
            required={true}
            value={(fields[RentalPropertyInputFields.LEVEL]?.value || null) as string}
            placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.LEVEL}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalPropertyInputFields.LEVEL
            })}
          />

          <ReactNumberInput
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA}`)}
            name={RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA}
            required={true}
            value={(fields[RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA]?.value || null) as number}
            placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA
            })}
          />

          <ReactNumberInput
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.COMMON_AREA}`)}
            name={RentalPropertyInputFields.COMMON_AREA}
            required={true}
            value={(fields[RentalPropertyInputFields.COMMON_AREA]?.value || null) as number}
            placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.COMMON_AREA}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalPropertyInputFields.COMMON_AREA
            })}
          />
        </div>

        <VisibleIf predicate={!fields.propertyTotalArea?.value}>
          <div className="mt-3 font-bold text-right">
            {i18n.t('components.custom.fill-in-fields',
              { fields: `${i18n.t(`contract.labels.${RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA}`)}, ${i18n.t(`contract.labels.${RentalPropertyInputFields.COMMON_AREA}`)}` }
            )}
          </div>
        </VisibleIf>

        <VisibleIf predicate={!!fields.propertyTotalArea?.value}>
          <div className="flex flex-row flex-grow items-center justify-end mt-6">
            <div>
              <div className="text-right mr-6 mt-1">{i18n.t(`contract.labels.${RentalPropertyInputFields.TOTAL_AREA}`)}</div>
            </div>
            <TextFieldReadOnly
              extraClassNames={`${styles.totalAreaField}`}
              value={AreaFormatter(fields.propertyTotalArea?.value)}
              // value={fields.propertyTotalArea?.value}
            />
          </div>
        </VisibleIf>

        <div className="flex-grow w-1/2">
          <Checkbox
            className="mt-4" key="hasStorageUnit" id="hasStorageUnit"
            isChecked={HasStorageUnit()}
            onClick={_ => {
              setMiscChanges.set(
                { value: !HasStorageUnit() },
                { name: RentalPropertyInputFields.HAS_STORAGE_UNIT }
              )
            }}
          >
            <div
              className="no-select"
              style={{ marginTop: '-2px' }}
            >2.1.1 {i18n.t(`contract.labels.${RentalPropertyInputFields.HAS_STORAGE_UNIT}`)}</div>
          </Checkbox>
        </div>
      </div>

      <VisibleIf predicate={!!HasStorageUnit()}>
        <div className={`${styles.cardSection}`}>
          <div className="flex flex-row items-center">
            <div className="sub-clause-header">2.1.1 {i18n.t(`contract.general.storage_unit`)}</div>
          </div>

          <div className="flex flex-row items-center gap-8">
            {/* <TextField
              label="Shopping Mall"
              name={RentalPropertyInputFields.SHOPPING_MALL}
              required={true}
              value="This should be a readonly field. Working on it"
              onChange={value => setChanges.set({ value }, {
                name: RentalPropertyInputFields.SHOPPING_MALL
              })}
            /> */}
            <div className="flex flex-col flex-grow w-1/2">
              <InputFieldLabel label={i18n.t(`contract.labels.storageShoppingMallName`)}/>
              {building?.name}
            </div>
            <div className="flex flex-col flex-grow w-1/2">
              <InputFieldLabel label={i18n.t(`contract.labels.storageShoppingMallAddress`)}/>
              {building?.address}
            </div>

            {/* <TextField
              label="Address"
              name={RentalPropertyInputFields.ADDRESS}
              required={true}
              value="This should be a readonly field. Working on it"
              onChange={value => setChanges.set({ value }, {
                name: RentalPropertyInputFields.ADDRESS
              })}
            /> */}
          </div>

          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${StorageUnitInputFields.LOCAL_NUMBER}`)}
              name={StorageUnitInputFields.LOCAL_NUMBER}
              required={true}
              value={(fields[StorageUnitInputFields.LOCAL_NUMBER]?.value || null) as string}
              placeholder={i18n.t(`contract.placeholders.${StorageUnitInputFields.LOCAL_NUMBER}`)}
              onChange={value => setChanges.set({ value }, {
                name: StorageUnitInputFields.LOCAL_NUMBER
              })}
            />

            <TextField
              label={i18n.t(`contract.labels.${StorageUnitInputFields.LEVEL}`)}
              name={StorageUnitInputFields.LEVEL}
              required={true}
              value={(fields[StorageUnitInputFields.LEVEL]?.value || null) as string}
              placeholder={i18n.t(`contract.placeholders.${StorageUnitInputFields.LEVEL}`)}
              onChange={value => setChanges.set({ value }, {
                name: StorageUnitInputFields.LEVEL
              })}
            />

            <ReactNumberInput
              label={i18n.t(`contract.labels.${StorageUnitInputFields.APPROX_EXCLUSIVE_AREA}`)}
              name={StorageUnitInputFields.APPROX_EXCLUSIVE_AREA}
              required={true}
              value={(fields[StorageUnitInputFields.APPROX_EXCLUSIVE_AREA]?.value || null) as number}
              placeholder={i18n.t(`contract.placeholders.${StorageUnitInputFields.APPROX_EXCLUSIVE_AREA}`)}
              onChange={value => setChanges.set({ value }, {
                name: StorageUnitInputFields.APPROX_EXCLUSIVE_AREA
              })}
            />

            <ReactNumberInput
              label={i18n.t(`contract.labels.${StorageUnitInputFields.COMMON_AREA}`)}
              name={StorageUnitInputFields.COMMON_AREA}
              required={true}
              value={(fields[StorageUnitInputFields.COMMON_AREA]?.value || null) as number}
              placeholder={i18n.t(`contract.placeholders.${StorageUnitInputFields.COMMON_AREA}`)}
              onChange={value => setChanges.set({ value }, {
                name: StorageUnitInputFields.COMMON_AREA
              })}
            />
          </div>
          <VisibleIf predicate={!fields.storagePropertyTotalArea?.value}>
            <div className="mt-3 font-bold text-right">
              {i18n.t('components.custom.fill-in-fields',
                { fields: `${i18n.t(`contract.labels.${StorageUnitInputFields.APPROX_EXCLUSIVE_AREA}`)}, ${i18n.t(`contract.labels.${StorageUnitInputFields.COMMON_AREA}`)}` }
              )}
            </div>
          </VisibleIf>
          <VisibleIf predicate={!!fields.storagePropertyTotalArea?.value}>
            <div className="flex flex-row items-center justify-end mt-3">
              <div className="mr-6 mt-1 text-right">{i18n.t(`contract.labels.${StorageUnitInputFields.TOTAL_AREA}`)}</div>
              <TextFieldReadOnly
                extraClassNames={`${styles.totalAreaField}`}
                value={AreaFormatter(fields.storagePropertyTotalArea?.value)}
                // value={fields.storagePropertyTotalArea?.value}
              />
            </div>
          </VisibleIf>
        </div>


        <div className={`${styles.cardSection}`}>
          <div className="flex flex-row items-center">
            <div className="sub-clause-header">2.1.2{i18n.t('contract.general.storage_rent')}</div>
          </div>

          <div className="flex flex-row items-center justify-center gap-8">
            {/* <NumberField
              label={i18n.t(`contract.labels.${StorageRentInputFields.RENT_VALUE}`)}
              extraClassNames="w-1/2"
              name={StorageRentInputFields.RENT_VALUE}
              value={(fields[StorageRentInputFields.RENT_VALUE]?.value || null) as number}
              placeholder={i18n.t(`contract.placeholders.${StorageRentInputFields.RENT_VALUE}`)}
              onChange={value => setChanges.set({ value }, {
                name: StorageRentInputFields.RENT_VALUE
              })}
            /> */}
            <ReactNumberInput
              label={i18n.t(`contract.labels.${StorageRentInputFields.RENT_VALUE}`)}
              extraClassNames="w-1/2"
              name={StorageRentInputFields.RENT_VALUE}
              value={(fields[StorageRentInputFields.RENT_VALUE]?.value || null) as number}
              placeholder={i18n.t(`contract.placeholders.${StorageRentInputFields.RENT_VALUE}`)}
              onChange={value => setChanges.set({ value }, {
                name: StorageRentInputFields.RENT_VALUE
              })}
            />

            <div className="flex-grow w-1/2">
              <div className="w-full mt-3 font-bold">{i18n.t(`contract.general.storage_rent_description`)}</div>
            </div>
          </div>
        </div>
      </VisibleIf>


      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">2.2 {i18n.t(`contract.general.store`)}</div>
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${StoreInputFields.NAME}`)}
            name={StoreInputFields.NAME}
            required={true}
            value={(fields[StoreInputFields.NAME]?.value || null) as string}
            placeholder={i18n.t(`contract.placeholders.${StoreInputFields.NAME}`)}
            onChange={value => setChanges.set({ value }, {
              name: StoreInputFields.NAME
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${StoreInputFields.CHAIN}`)}
            name={StoreInputFields.CHAIN}
            // required={true}
            value={(fields[StoreInputFields.CHAIN]?.value || null) as string}
            placeholder={i18n.t(`contract.placeholders.${StoreInputFields.CHAIN}`)}
            onChange={value => setChanges.set({ value }, {
              name: StoreInputFields.CHAIN
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${StoreInputFields.GROUP_NAME}`)}
            name={StoreInputFields.GROUP_NAME}
            // required={true}
            value={(fields[StoreInputFields.GROUP_NAME]?.value || null) as string}
            placeholder={i18n.t(`contract.placeholders.${StoreInputFields.GROUP_NAME}`)}
            onChange={value => setChanges.set({ value }, {
              name: StoreInputFields.GROUP_NAME
            })}
          />
          <Dropdown placement="bottom" trigger={['click']}
            menu={{
              items: IndustryCodesDropdownItemsFactory({
                onClick: (key) => {
                  setMiscChanges.set({ value: key }, { name: StoreInputFields.INDUSTRY_CODE })
                }
              })
            }}
          >
            <div className={`${styles.dropdownField}`}>
              <DropdownField
                name={StoreInputFields.INDUSTRY_CODE}
                label={i18n.t(`contract.labels.${StoreInputFields.INDUSTRY_CODE}`)}
                required={true}
                value={fields[StoreInputFields.INDUSTRY_CODE]?.value ? i18n.t(`industryCode.${fields[StoreInputFields.INDUSTRY_CODE]?.value}`) : i18n.t(`industryCode.undefined`)}
              />
            </div>
          </Dropdown>
        </div>

        <div className="flex flex-col items-center">
          <div className="w-full flex-grow">
            <InputFieldLabel label={i18n.t(`contract.labels.${StoreInputFields.STORE_CONCEPT}`)} required={true}/>
          </div>
          <div className="w-full flex-grow">
            <TextEditor
              name={StoreInputFields.STORE_CONCEPT}
              readOnly={false}
              value={(fields[StoreInputFields.STORE_CONCEPT]?.value || null) as string}
              onChange={value => setChanges.set({ value }, {
                name: StoreInputFields.STORE_CONCEPT
              })}
            />
          </div>
        </div>
      </div>


      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">2.3 {i18n.t(`contract.general.description_of_rental_object`)}</div>
        </div>

        <div className="flex flex-row items-center justify-center gap-8">
          <div translate={'no'} className="notranslate w-full">
            <TextEditor
              value={(fields[RentalPropertyInputFields.PROPERTY_DESCRIPTION]?.value || null) as string}
              readOnly={false}
              onChange={value => setChanges.set({ value }, {
                name: RentalPropertyInputFields.PROPERTY_DESCRIPTION
              })}
            />
          </div>
        </div>
      </div>

    </div>
  </div>
}

const RentalPropertyComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields,
  building = observe.contract.originalv2.building
}) => {

  return <div>
    <h2>2. {i18n.t(`contract.general.rental_property`)}</h2>
    <div className={`${styles.cardSectionColumn}`}>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">2.1 {i18n.t(`contract.general.property`)}</div>
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.SHOPPING_MALL}`)}
            required={true}
            value={(fields[RentalPropertyInputFields.SHOPPING_MALL]?.value || null) as string}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.ADDRESS}`)}
            required={true}
            value={(fields[RentalPropertyInputFields.ADDRESS]?.value || null) as string}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.MUNICIPALITY}`)}
            required={true}
            value={(fields[RentalPropertyInputFields.MUNICIPALITY]?.value || null) as string}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.PARCEL_NUMBER}`)}
            required={true}
            value={(fields[RentalPropertyInputFields.PARCEL_NUMBER]?.value || null) as string}
          />
        </div>

        <div className={`${styles.row}`}>
          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.LOCAL_NUMBER}`)}
            required={true}
            value={(fields[RentalPropertyInputFields.LOCAL_NUMBER]?.value || null) as number}
          />

          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.LEVEL}`)}
            required={true}
            value={(fields[RentalPropertyInputFields.LEVEL]?.value || null) as number}
          />

          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA}`)}
            required={true}
            value={(fields[RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA]?.value || null) as number}
          />

          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${RentalPropertyInputFields.COMMON_AREA}`)}
            required={true}
            value={(fields[RentalPropertyInputFields.COMMON_AREA]?.value || null) as number}
          />
        </div>

        <VisibleIf predicate={!fields.propertyTotalArea?.value}>
          <div className="mt-3 font-bold text-right">
            {i18n.t('components.custom.fill-in-fields',
              { fields: `${i18n.t(`contract.labels.${RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA}`)}, ${i18n.t(`contract.labels.${RentalPropertyInputFields.COMMON_AREA}`)}` }
            )}
          </div>
        </VisibleIf>

        <VisibleIf predicate={!!fields.propertyTotalArea?.value}>
          <div className="flex flex-row flex-grow items-center justify-end mt-6">
            <div>
              <div className="text-right mt-1 mr-6">{i18n.t(`contract.labels.${RentalPropertyInputFields.TOTAL_AREA}`)}</div>
            </div>
            <TextFieldReadOnly
              extraClassNames={`${styles.totalAreaField}`}
              value={AreaFormatter(fields.propertyTotalArea?.value)}
            />
          </div>
        </VisibleIf>

        <div className="flex-grow w-1/2">
          <Checkbox
            className="mt-4" key="hasStorageUnit" id="hasStorageUnit"
            isChecked={fields[RentalPropertyInputFields.HAS_STORAGE_UNIT]?.value}
            isDisabled={true}
          >
            <div
              className="no-select"
              style={{ marginTop: '-2px' }}
            >2.1.1 {i18n.t(`contract.labels.${RentalPropertyInputFields.HAS_STORAGE_UNIT}`)}</div>
          </Checkbox>
        </div>
      </div>

      <VisibleIf predicate={!!(fields[RentalPropertyInputFields.HAS_STORAGE_UNIT]?.value)}>
        <div className={`${styles.cardSection}`}>
          <div className="flex flex-row items-center">
            <div className="sub-clause-header">2.1.1 {i18n.t(`contract.general.storage_unit`)}</div>
          </div>

          <div className="flex flex-row items-center gap-8">
            <div className="flex flex-col flex-grow w-1/2">
              <InputFieldLabel label={i18n.t(`contract.labels.storageShoppingMallName`)}/>
              {building?.name}
            </div>
            <div className="flex flex-col flex-grow w-1/2">
              <InputFieldLabel label={i18n.t(`contract.labels.storageShoppingMallAddress`)}/>
              {building?.address}
            </div>
          </div>

          <div className={`${styles.row}`}>
            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${StorageUnitInputFields.LOCAL_NUMBER}`)}
              required={true}
              value={(fields[StorageUnitInputFields.LOCAL_NUMBER]?.value || null) as number}
            />

            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${StorageUnitInputFields.LEVEL}`)}
              required={true}
              value={(fields[StorageUnitInputFields.LEVEL]?.value || null) as number}
            />

            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${StorageUnitInputFields.APPROX_EXCLUSIVE_AREA}`)}
              required={true}
              value={(fields[StorageUnitInputFields.APPROX_EXCLUSIVE_AREA]?.value || null) as number}
            />

            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${StorageUnitInputFields.COMMON_AREA}`)}
              required={true}
              value={(fields[StorageUnitInputFields.COMMON_AREA]?.value || null) as number}
            />
          </div>

          <VisibleIf predicate={!fields.storagePropertyTotalArea?.value}>
            <div className="mt-3 font-bold text-right">
              {i18n.t('components.custom.fill-in-fields',
                { fields: `${i18n.t(`contract.labels.${StorageUnitInputFields.APPROX_EXCLUSIVE_AREA}`)}, ${i18n.t(`contract.labels.${StorageUnitInputFields.COMMON_AREA}`)}` }
              )}
            </div>
          </VisibleIf>

          <VisibleIf predicate={!!fields.storagePropertyTotalArea?.value}>
            <div className="flex flex-row items-center justify-end mt-6">
              <div className="mr-6 mt-1">{i18n.t(`contract.labels.${StorageUnitInputFields.TOTAL_AREA}`)}</div>
              <TextFieldReadOnly
                extraClassNames={`${styles.totalAreaField}`}
                value={AreaFormatter(fields.storagePropertyTotalArea?.value)}
              />
            </div>
          </VisibleIf>
        </div>

        <div className={`${styles.cardSection}`}>
          <div className="flex flex-row items-center">
            <div className="sub-clause-header">{i18n.t('contract.general.storage_rent')}</div>
          </div>

          <div className="flex flex-row items-center justify-center gap-8">
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${StorageRentInputFields.RENT_VALUE}`)}
              extraClassNames="w-1/2"
              value={
                CurrencyFormatter(fields[StorageRentInputFields.RENT_VALUE]?.value || null)
              }
            />

            <div className="flex-grow w-1/2">
              <div className="w-full mt-3 font-bold">{i18n.t(`contract.general.storage_rent_description`)}</div>
            </div>
          </div>
        </div>
      </VisibleIf>


      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">2.2 {i18n.t(`contract.general.store`)}</div>
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${StoreInputFields.NAME}`)}
            required={true}
            value={(fields[StoreInputFields.NAME]?.value || null) as string}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${StoreInputFields.CHAIN}`)}
            required={true}
            value={(fields[StoreInputFields.CHAIN]?.value || null) as string}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${StoreInputFields.GROUP_NAME}`)}
            required={true}
            value={(fields[StoreInputFields.GROUP_NAME]?.value || null) as string}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${StoreInputFields.INDUSTRY_CODE}`)}
            required={true}
            value={
              fields[StoreInputFields.INDUSTRY_CODE]?.value ? i18n.t(`industryCode.${fields[StoreInputFields.INDUSTRY_CODE]?.value}`) : i18n.t(`industryCode.undefined`)
            }
          />
        </div>

        <div className="flex flex-col items-center">
          <div className="w-full flex-grow">
            <InputFieldLabel label={i18n.t(`contract.labels.${StoreInputFields.STORE_CONCEPT}`)} required={true}/>
          </div>
          <div className="w-full flex-grow">
            <TextEditorReadOnly
              value={(fields[StoreInputFields.STORE_CONCEPT]?.value || null) as string}
              readOnly={true}
            />
          </div>
        </div>
      </div>


      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">2.3 {i18n.t(`contract.general.description_of_rental_object`)}</div>
        </div>

        <div className="flex flex-row items-center justify-center gap-8">
          <div translate={'no'} className="notranslate w-full">
            <TextEditorReadOnly
              value={(fields[RentalPropertyInputFields.PROPERTY_DESCRIPTION]?.value || null) as string}
              readOnly={true}
            />
          </div>
        </div>
      </div>

    </div>
  </div>
}

type IndustryDropdownItemsParams = {
  onClick: (key: IndustryCodes) => void
};

function IndustryCodesDropdownItemsFactory(params: IndustryDropdownItemsParams): MenuProps['items'] {
  return Object.values(IndustryCodes).map((code: IndustryCodes) => {
    return {
      key: code,
      label: (<div className="flex-grow" onClick={_ => params.onClick(code)}>
        {i18n.t(`industryCode.${code}`)}
      </div>)
    }
  })
  // return [{
  //   key: IndustryCodes.FOOD_AND_BEVERAGE,
  //   label: (<div className="flex-grow" onClick={_ => params.onClick(IndustryCodes.FOOD_AND_BEVERAGE)}>
  //     {i18n.t(`industryCode.${IndustryCodes.FOOD_AND_BEVERAGE}`)}
  //   </div>)
  // }, {
  //   key: IndustryCodes.CLOTHES_AND_SHOES,
  //   label: (<div className="flex-grow" onClick={_ => params.onClick(IndustryCodes.CLOTHES_AND_SHOES)}>
  //     {i18n.t(`industryCode.${IndustryCodes.CLOTHES_AND_SHOES}`)}
  //   </div>)
  // }, {
  //   key: IndustryCodes.HOUSE_AND_HOME,
  //   label: (<div className="flex-grow" onClick={_ => params.onClick(IndustryCodes.HOUSE_AND_HOME)}>
  //     {i18n.t(`industryCode.${IndustryCodes.HOUSE_AND_HOME}`)}
  //   </div>)
  // }, {
  //   key: IndustryCodes.SPECIAL_STORES,
  //   label: (<div className="flex-grow" onClick={_ => params.onClick(IndustryCodes.SPECIAL_STORES)}>
  //     {i18n.t(`industryCode.${IndustryCodes.SPECIAL_STORES}`)}
  //   </div>)
  // }, {
  //   key: IndustryCodes.SERVICE_AND_SERVICE_PROVIDERS,
  //   label: (<div className="flex-grow" onClick={_ => params.onClick(IndustryCodes.SERVICE_AND_SERVICE_PROVIDERS)}>
  //     {i18n.t(`industryCode.${IndustryCodes.SERVICE_AND_SERVICE_PROVIDERS}`)}
  //   </div>)
  // }, {
  //   key: IndustryCodes.SERVING,
  //   label: (<div className="flex-grow" onClick={_ => params.onClick(IndustryCodes.SERVING)}>
  //     {i18n.t(`industryCode.${IndustryCodes.SERVING}`)}
  //   </div>)
  // }, {
  //   key: IndustryCodes.OTHER_RETAIL,
  //   label: (<div className="flex-grow" onClick={_ => params.onClick(IndustryCodes.OTHER_RETAIL)}>
  //     {i18n.t(`industryCode.${IndustryCodes.OTHER_RETAIL}`)}
  //   </div>)
  // }, {
  //   key: IndustryCodes.OTHER_BUSINESS,
  //   label: (<div className="flex-grow" onClick={_ => params.onClick(IndustryCodes.OTHER_BUSINESS)}>
  //     {i18n.t(`industryCode.${IndustryCodes.OTHER_BUSINESS}`)}
  //   </div>)
  // }]
}
