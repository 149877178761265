import { ErrorLevel, ApplicationError, ErrorType } from 'src/types'
import { functions } from "src/producers/firebase";
import { ContractStatus } from "src/pages/ContractV2/contract.types";

export const sendSigningNotification: producer = async ({
  trigger = observe.contract.triggers.sendSigningNotification,
  removeTrigger = update.contract.triggers.sendSigningNotification,

  contractId = get.contract.id,
  envelopeUid = get.contract.originalv2.envelopeInfo.envelopeUid,
  recipientsNotificationSent = update.contract.recipientstNotificationSent,
  error = update.errors[param.id],
}) => {
  if (!trigger) return
  contractId = contractId.value()
  envelopeUid = envelopeUid.value()
  removeTrigger.remove()

  try {
    const sendReminder = functions.httpsCallable('sendVEReminder')
    const payload = { envelopeUid, contractId, recipientId: trigger.recipientId, greeting: trigger.message }
    const resp = await sendReminder(payload)
    recipientsNotificationSent.set(trigger.recipientId)
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'moveToSigning-contract' }
    )
  }
}
