import { RadioButton, RadioButtonGroup } from "@c11/components";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { InsideCard } from "../../ClauseCard/InsideCard";

export const VATExempted: view = ({
  userRole = observe.user.data.role,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  getVATExempted = observe.contract.values.VATExempted.displayedValue,
  getVATExemptedFromChanges = observe.contract.currentSession.changes.VATExempted.value,
  setVATExempted = update.contract.toSave.changes.VATExempted,
}) => {
  const isTenant = userRole === UserRole.TENANT;

  let i: any;
  if (getVATExemptedFromChanges !== undefined) {
    i = getVATExemptedFromChanges;
  } else {
    i = getVATExempted;
  }

  function handleClick(v: any) {
    if (i !== v) setVATExempted.set({ value: v, createdAt: Date.now() });
  }

  return (
    <InsideCard key="provision"
                title={"3.5 " + i18n.t("parking_contract.rental_cost_VATExempted")}>
      <RadioButtonGroup selectedValue={String(i)}>
        <RadioButton
          key={`11`}
          className="mr-4"
          value={"true"}
          isDisabled={isReadOnlyContract || isTenant}
          onClick={() => handleClick(true)}>
          {i18n.t("parking_contract.rental_cost_VATExempted_a_true")}
        </RadioButton>
        <RadioButton
          key={`12`}
          value={"false"}
          isDisabled={isReadOnlyContract || isTenant}
          onClick={() => handleClick(false)}>
          {i18n.t("parking_contract.rental_cost_VATExempted_a_false")}
        </RadioButton>
      </RadioButtonGroup>
    </InsideCard>
  );
};
