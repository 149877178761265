import { MenuProps } from 'rc-menu'

export type SignerDropdownMenuProps = {
  onClick: (signer: SignerDropdownMenuProps['items'][number]) => void

  items: Array<{
    id: string
    firstName: string
    lastName: string
    email: string
    phone: string
    position: string
    region: Array<string>
  }>
}

export const SignerDropdownMenu = (props: SignerDropdownMenuProps): MenuProps['items'] => {
  if (!props || !props?.items || props.items.length === 0)
    return [
      {
        key: 'none',
        label: <div className="flex-grow">No results found</div>,
      },
    ]
  return props.items.map((item) => ({
    key: item.id,
    label: (
      <div className="flex flex-row gap-1 flex-grow" onClick={(_) => props.onClick(item)}>
        <div>{item.firstName}</div>
        <div>{item.lastName}</div>
      </div>
    ),
  }))
}
