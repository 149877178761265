import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel } from "src/types";

export const deleteSessionFromDB: producer = ({
  contractId = observe.legacyContract.id,
  trigger = observe.legacyContract.triggers.deleteSessionFromDB,
  removeTrigger = update.legacyContract.triggers.deleteSessionFromDB,
  removeChanges = update.legacyContract.currentSession.changes,
  userRole = get.user.data.role,
  rerendersAllFields = update.legacyContract.rerendersAllFields,
  error = update.errors[param.type],
}) => {
  const role = userRole.value();
  if (!trigger || !role || !contractId) {
    return;
  }

  try {
    console.log("deleted all changes");
    const contractDb = db.collection("contracts").doc(contractId);
    contractDb
      .collection("sessions")
      .doc(role)
      .delete()
      .then(() => {
        removeTrigger.remove();
        removeChanges.remove();
        rerendersAllFields.set(Date.now());
      });
  } catch (e) {
    console.error("deleteSessionFromDB", e);
    error.set({ message: "error-clear-session", level: ErrorLevel.LOW } as ApplicationError, { type: "session" });
  }
};
