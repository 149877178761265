import numeral from "numeral";
// load a locale

const _numeral = numeral;
_numeral.register("locale", "nb2", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal(number) {
    return number === 1 ? "første" : "";
  },
  currency: {
    symbol: "€",
  },
});

// switch between locales
_numeral.locale("nb2");

export default _numeral;
