import i18n from 'src/producers/languages/i18n'
import { SVGs } from 'src/assets'
import { VisibleIf } from 'src/components_generic/visible-if'
import { HeaderSection } from '../../components/HeaderSection/HeaderSection'
import { AddPartyButton } from './AddPartyButton'

import './PartiesTab.css'
import { PartiesListElement } from './PartiesListElement'
import { RemovePartyModalWindow } from './RemovePartyModalWindow'
import { DigitalSigningSwitch } from './DigitalSigningSwitch'
import { PartyModalWindow } from './PartyModalWindow'

export const Parties: view = ({
  signersUIData = observe.contract.ui.signers,
  isLocked = observe.contract.isLocked,

  updatePartyModal = update.modal.partyModal,
  updateRemovePartyModal = update.modal.removePartyModal,
  updateReorderedParty = update.contract.changes.parties.reordered,
}) => {
  return (
    <>
      <HeaderSection title={i18n.t('parties_page.parties_title')} subtitle={i18n.t('parties_page.parties_subtitle')}>
        <VisibleIf predicate={!isLocked}>
          <AddPartyButton />
        </VisibleIf>
      </HeaderSection>

      <VisibleIf predicate={!signersUIData?.count || !signersUIData?.hasEnoughPartiesForSigning}>
        <NoPartiesBar />
      </VisibleIf>

      <div className="flex-grow flex flex-col align-center mt-8">
        <VisibleIf predicate={signersUIData?.count > 0}>
          <div className="mb-4">
            <div className="signers-list-header">
              <h5 className="parties-tab-list-item-subtitle">{i18n.t('parties_page.card_title')}</h5>
              <h5 className="parties-tab-list-item-subtitle">{i18n.t('parties_page.action')}</h5>
            </div>
            {signersUIData?.orderSortedArray?.map((signer, index) => (
              <PartiesListElement
                key={signer.partyId}
                index={index + 1}
                name={`${signer.firstName} ${signer.lastName}`}
                email={signer.email}
                isLandlord={signer.partyType === 'landlord'}
                isTenant={signer.partyType === 'tenant'}
                isSigner={signer.recipientAction === 'sign'}
                isReviewer={signer.recipientAction === 'review'}
                isLocked={isLocked}
                isFirstElement={signersUIData?.count < 2 || signer.order === signersUIData?.orderOfFirstElement}
                isLastElement={signersUIData?.count < 2 || signer.order === signersUIData?.orderOfLastElement}
                onEdit={() => {
                  updatePartyModal.set({
                    isOpen: true,
                    modalType: 'edit',
                    ...signer,
                  })
                }}
                onDelete={() => {
                  updateRemovePartyModal.set({
                    isOpen: true,
                    partyId: signer.partyId,
                  })
                }}
                onReorderDownClick={() => {
                  updateReorderedParty.set({
                    partyId: signer.partyId,
                    direction: 'down',
                  })
                }}
                onReorderUpClick={() => {
                  updateReorderedParty.set({
                    partyId: signer.partyId,
                    direction: 'up',
                  })
                }}
              />
            ))}
          </div>
        </VisibleIf>
        <DigitalSigningSwitch />
      </div>

      <PartyModalWindow />
      <RemovePartyModalWindow />
    </>
  )
}

const NoPartiesBar: view = () => {
  return (
    <div className=" bg-pink-100 flex px-6 py-4 mt-2">
      <div className="flex flex-auto items-center ">
        <SVGs.ExclamationCircleUnfilled className="mr-2" />
        <div className="ml-3">
          <div className="">{i18n.t('parties_page.no_parties_warning')}.</div>
        </div>
      </div>
    </div>
  )
}
