import React from "react";

import { Modal } from "src/components";
import i18n from "src/producers/languages/i18n";
import { Table } from "antd";
import moment from "moment";
import { ContractEventType } from "src/types";
import { variablesOfTypeMultipleOption, VariablesSufix, variablesWithLongText } from "src/utils/variablesInfo";

export const ActivityLogModal: view = ({
  show = observe.modal.activityLog,
  activityLog = observe.legacyContract.activity,
}) => {
  if (!show || !activityLog) return null;
  const columns = [
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Timestamp", dataIndex: "timestamp", key: "timestamp" },
    { title: "By", dataIndex: "by", key: "by" },
  ];

  const data = activityLog.map((a: any) => {
    return {
      key: a.timestamp,
      type: a.type,
      timestamp: moment(a.createdAt).format("DD.MM.YY HH:mm:ss"),
      by: a.user?.fullName,
      ...a,
    };
  });

  return (
    <Modal title={i18n.t("modals_activity_log_title")} width={800}>
      <div>
        <Table
          columns={columns}
          expandable={{
            defaultExpandAllRows: true,
            expandedRowRender: (record) => {
              if (record.type === ContractEventType.COMMENT_ADDED)
                return <p className="m-0">Message: {record.message}</p>;
              if (record.type === ContractEventType.VERSION_CREATED) {
                let changesList: any;
                const changes = record.fields;
                if (changes) {
                  changesList = Object.keys(changes).map((varName) => {
                    const newValue = changes[varName].current.value;
                    const oldValue = changes[varName].previous.value;

                    const valueSuffix = `${VariablesSufix[varName] ? VariablesSufix[varName] : ""}`;
                    let oldValueText = oldValue + valueSuffix;
                    let newValueText = newValue + valueSuffix;

                    if (variablesOfTypeMultipleOption.includes(varName)) {
                      oldValueText = `${i18n.t("contract_sidebar_changes_vat_option")} ${oldValue}`;
                      newValueText = `${i18n.t("contract_sidebar_changes_vat_option")} ${newValue}`;
                    }

                    return oldValue ? (
                      <p key={varName}>
                        <span className="font-bold">{i18n.t(varName) || varName}</span>
                        {` ${i18n.t("contract_sidebar_changes_updated_from")} `}
                        <span className="font-bold">{oldValueText}</span>
                        {` ${i18n.t("contract_sidebar_changes_updated_to")} `}
                        <span className="text-pink-600">{newValueText}</span>
                      </p>
                    ) : (
                      <p key={varName}>
                        <span className="font-bold">{i18n.t(varName) || varName}</span>
                        {` ${i18n.t("contract_sidebar_changes_changed_to")} `}
                        <span className="text-pink-600">{newValueText}</span>
                      </p>
                    );
                  });
                  return (
                    <div
                      key={record.timestamp}
                      style={{ marginLeft: 50, marginRight: 10, marginTop: 5, marginBottom: 5 }}>
                      {changesList}
                    </div>
                  );
                }
              }
            },
            rowExpandable: (record) =>
              [ContractEventType.VERSION_CREATED, ContractEventType.COMMENT_ADDED].includes(record.type),
          }}
          dataSource={data}
          pagination={false}
          rowKey={(record) => record.timestamp + record.type}
        />
      </div>
    </Modal>
  );
};
