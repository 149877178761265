import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import { UserRole } from "src/types";
import { ManagerSecurityClause } from "./ManagerSecurityClause";
import { TenantSecurityClause } from "./TenantSecurityClause";

import styles from "../../../styles.module.css";

export const SecurityClause: view = ({
  title,
  securityAlternative = observe.contract.values.securityAlternative.displayedValue,
  securityAlternativeFromChanges = observe.contract.currentSession.changes.securityAlternative.value,
  userRole = get.user.data.role,
}) => {
  const i = securityAlternativeFromChanges || securityAlternative;
  const checkedState = [i === 1, i === 2];
  const isTenant = userRole.value() === UserRole.TENANT;
  const isManager = userRole.value() === UserRole.MANAGER;

  return (
    <ClauseCard title={title}>
      <div className={`${styles.contractCard}`}>
        {isManager && <ManagerSecurityClause key="1" value={i} />}
        {isTenant && <TenantSecurityClause key="2" value={i} />}
      </div>
    </ClauseCard>
  );
};
