import { ErrorLevel, ApplicationError, ErrorType } from 'src/types'
import { functions } from 'src/producers/firebase'

export const modifyExpiry: producer = async ({
  _viewId,
  newExpiry = observe.views[prop._viewId].trigger,
  removeTrigger = update.views[prop._viewId].trigger,
  contractId = get.contract.id.value(),
  envelopeInfo = get.contract.originalv2.envelopeInfo.value(),
  updateExpiry = update.contract.originalv2.envelope.expiration,

  error = update.errors[param.id],
  updateLoading = update.contract.flags.modifyExpiry
}) => {
  if (!newExpiry) return
  removeTrigger.remove()
  updateLoading.set(true)
  try {
    const modifyEnvelopeExpiry = functions.httpsCallable('modifyEnvelopeExpiry')
    const payload = {
      contractId,
      envelopeUid: envelopeInfo.envelopeUid,
      newExpiry: newExpiry.toISOString()
    }
    console.log('modifyExpiry', payload)
    await modifyEnvelopeExpiry(payload)
    updateExpiry.set(newExpiry.toISOString())
    // updateContracts.set({
    //   action: 'modifyExpiry',
    //   contractId,
    //   envelopeUid: envelopeInfo.envelopeUid,
    //   newExpiry: trigger.toISOString(),
    //   loadingFor: 'modifyExpiry',
    // })
  } catch (e) {
    console.error('ERROR on expiry', e)
    error.set(
      {
        message: typeof e === 'string' ? e : 'internal-server-error',
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'modify-expiry' }
    )
  }
  updateLoading.set(false)
}
