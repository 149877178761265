export const ReadOnlyField: view = ({
  varName,
  label,
  className,
  value = observe.contract.values[arg.varName].displayedValue,
}) => {
  if (!value) return null;
  return (
    <div className={`mb-2 ${className}`}>
      <label className="block text-gray-700 text-xs leading-none">
        <span>{label}</span>
      </label>
      <p>{value}</p>
    </div>
  );
};
