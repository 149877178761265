import { RadioButton, RadioButtonGroup } from '@c11/components'
import { v4 } from 'uuid'
import { DateField, DateFieldReadOnly } from 'src/components_generic/DateField'
import {
  AutocalculatedFieldWithOverride,
  AutocalculatedFieldWithOverrideReadOnly,
  NumberField,
  NumberFieldReadOnly
} from 'src/components_generic/NumberField'
import { TextFieldReadOnly } from 'src/components_generic/TextField'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'

import styles from 'src/pages/ContractV2/styles.module.css'
import { ReactNumberInput } from 'src/components_generic/ReactNumberInput'
import { NumberFormatterFactory, NumberFormatterNew } from 'src/components_generic/number-formatter'
import { VisibleIfInvalidField } from 'src/pages/ContractV2/components/VisibleIfInvalidField'

export const RentalCosts: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = get.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus.value() === 'active'
  const displayReadOnlyView = isActiveContract || isLocked

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <RentalCostsComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <RentalCostsComponentReadOnly/>
    </VisibleIf>
  </>
}

enum RentalCostsInputFields {
  TURNOVER_RENT = 'turnoverPercent',
  MINIMUM_RENT = 'minimumRent',
  MINIMUM_TURNOVER = 'minimumTurnOver'
}

enum MarketingCostsInputFields {
  COST_PERCENT = 'marketingCosts',
  MINIMUM_MARKETING_COST = 'minimumMarketingCost'
}

enum SharedCostsInputFields {
  STIPULATED_COMMON_COSTS_STORE = 'sharedCosts',
  STIPULATED_COMMON_COSTS_STORAGE = 'sharedCostsStorage'
}

enum IndexAdjustmentInputFields {
  FIRST_TIME_REGULATION = 'firstTimeRegulation',
  BASIC_INDEX = 'indexAdjustmentBasicIndex'
}

enum PaymentProvisionInputFields {
  PROVISION_TYPE = 'paymentProvisionType'
}

export enum PaymentProvisionType {
  QUARTERLY_IN_ADVANCE = 'quarterly',
  MONTHLY_IN_ADVANCE = 'monthly'
}

// const NumberFormatter = NumberFormatterFactory()
const NumberFormatter = NumberFormatterFactory()

const RentalCostsComponent: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  setMiscChanges = update.contract.changes.miscellaneous[param.name]
}) => {
  // if (!contract?.metadata?.readOnly) return null

  const GetPaymentProvisionType = () => fields[PaymentProvisionInputFields.PROVISION_TYPE]?.value

  const turnoverRent = fields?.[RentalCostsInputFields.TURNOVER_RENT]?.value || null

  return <div>
    <h2>4. {i18n.t(`contract.general.rental_costs`)}</h2>
    <div className={`${styles.cardSectionColumn}`}>
      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.1 {i18n.t(`contract.general.rent`)}</div>
        </div>

        <div className={`${styles.row}`}>
          <div className='flex-grow w-1/2 text-center'>

            {i18n.t(`contract.general.rent_turnover_description`)}
          </div>
          <div className='flex-grow w-1/2 text-center'>
            {i18n.t(`contract.general.minimum_rent_description`)}
          </div>
        </div>

        <div className={`${styles.row}`}>
          <ReactNumberInput
            label={i18n.t(`contract.labels.${RentalCostsInputFields.TURNOVER_RENT}`)}
            name={RentalCostsInputFields.TURNOVER_RENT}
            required={true}
            value={turnoverRent}
            placeholder={i18n.t(`contract.placeholders.${RentalCostsInputFields.TURNOVER_RENT}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalCostsInputFields.TURNOVER_RENT
            })}
          />
          <ReactNumberInput
            label={i18n.t(`contract.labels.${RentalCostsInputFields.MINIMUM_RENT}`)}
            name={RentalCostsInputFields.MINIMUM_RENT}
            required={true}
            value={(fields?.[RentalCostsInputFields.MINIMUM_RENT]?.value || null) as number}
            placeholder={i18n.t(`contract.placeholders.${RentalCostsInputFields.MINIMUM_RENT}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalCostsInputFields.MINIMUM_RENT
            })}
          />
        </div>

        <VisibleIf predicate={!fields.minimumTurnOver?.value && !fields.minimumTurnOver?.isCustom}>
          <div className="mt-3 font-bold text-right">
            {i18n.t('components.custom.fill-in-fields', { fields: `${i18n.t(`contract.labels.${RentalCostsInputFields.TURNOVER_RENT}`)}, ${i18n.t(`contract.labels.${RentalCostsInputFields.MINIMUM_RENT}`)}` })}
          </div>
        </VisibleIf>
        <VisibleIf predicate={!!fields.minimumTurnOver?.value || fields.minimumTurnOver?.isCustom}>
          <div className="flex flex-row items-center justify-center gap-8 mt-3">
            <div className="text-right font-bold w-1/2 ">
              <div>{i18n.t(`contract.labels.${RentalCostsInputFields.MINIMUM_TURNOVER}`)}</div>
            </div>
            <AutocalculatedFieldWithOverride
              name=""
              inputExtraClassNames="text-right font-bold"
              value={
                fields.minimumTurnOver?.isCustom ?
                  fields.minimumTurnOver?.value :
                  NumberFormatterNew(fields.minimumTurnOver?.value)
              }
              isCustom={fields.minimumTurnOver?.isCustom}
              onChange={value => {
                // if (!value) return
                setChanges.merge({
                  value: value
                }, {
                  name: RentalCostsInputFields.MINIMUM_TURNOVER
                })
              }}
              onSetCustomChanged={value => {
                setMiscChanges.merge({
                  isCustom: !fields.minimumTurnOver?.isCustom
                }, {
                  name: RentalCostsInputFields.MINIMUM_TURNOVER
                })
              }}
            />
          </div>
        </VisibleIf>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.2 {i18n.t(`contract.general.marketing_costs`)}</div>
        </div>

        <div>
          {i18n.t(`contract.general.marketing_costs_description`)}
        </div>

        <div className={`${styles.row}`}>
          <ReactNumberInput
            label={i18n.t(`contract.labels.${MarketingCostsInputFields.COST_PERCENT}`)}
            name={MarketingCostsInputFields.COST_PERCENT}
            required={true}
            value={(fields?.[MarketingCostsInputFields.COST_PERCENT]?.value || null) as number}
            placeholder={i18n.t(`contract.placeholders.${MarketingCostsInputFields.COST_PERCENT}`)}
            onChange={value => setChanges.set({ value }, {
              name: MarketingCostsInputFields.COST_PERCENT
            })}
          />
        </div>

        <VisibleIf predicate={!fields.minimumMarketingCost?.value}>
          <div className="font-bold mt-3 text-right">
            {i18n.t('components.custom.fill-in-fields',
              { fields: `${i18n.t(`contract.labels.${MarketingCostsInputFields.COST_PERCENT}`)}, ${i18n.t(`contract.labels.${RentalCostsInputFields.MINIMUM_TURNOVER}`)}` }
            )}
            {/*Please fill in the 'Marketing cost' and 'Minimum rent' fields in order to enable marketing cost calculation*/}
          </div>
        </VisibleIf>
        <VisibleIf predicate={!!fields.minimumMarketingCost?.value}>
          <div className="flex flex-row items-center justify-center gap-8 mt-3">
            <div
              className="w-1/2 text-right font-bold">{i18n.t(`contract.labels.${MarketingCostsInputFields.MINIMUM_MARKETING_COST}`)}</div>
            <div className="flex-grow">
              <AutocalculatedFieldWithOverride
                name={MarketingCostsInputFields.MINIMUM_MARKETING_COST}
                inputExtraClassNames="text-right font-bold"
                value={
                  fields.minimumMarketingCost?.isCustom ?
                    fields.minimumMarketingCost?.value :
                    NumberFormatterNew(fields.minimumMarketingCost?.value)
                }
                isCustom={fields.minimumMarketingCost?.isCustom}
                onChange={value => {
                  if (!value) return
                  setChanges.set({
                    value: value
                  }, {
                    name: MarketingCostsInputFields.MINIMUM_MARKETING_COST
                  })
                }}
                onSetCustomChanged={value => {
                  setMiscChanges.set({
                    isCustom: !fields.minimumMarketingCost?.isCustom
                  }, {
                    name: MarketingCostsInputFields.MINIMUM_MARKETING_COST
                  })
                }}
              />
            </div>
          </div>
        </VisibleIf>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.3 {i18n.t(`contract.general.shared_costs`)}</div>
        </div>

        <div>
          {i18n.t(`contract.general.shared_costs_description`)}
        </div>

        <div className={`${styles.row}`}>
          <ReactNumberInput
            label={i18n.t(`contract.labels.${SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORE}`)}
            name={SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORE}
            // required={true}
            value={(fields?.[SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORE]?.value || null) as number}
            placeholder={i18n.t(`contract.placeholders.${SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORE}`)}
            onChange={value => setChanges.set({ value }, {
              name: SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORE
            })}
          />
          <ReactNumberInput
            label={i18n.t(`contract.labels.${SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORAGE}`)}
            name={SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORAGE}
            // required={true}
            value={(fields?.[SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORAGE]?.value || null) as number}
            placeholder={i18n.t(`contract.placeholders.${SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORAGE}`)}
            onChange={value => setChanges.set({ value }, {
              name: SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORAGE
            })}
          />
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.4{i18n.t(`contract.general.index_adjustment`)}</div>
        </div>

        <div className={`${styles.row}`}>
          <DateField
            extraClassNames="flex-grow w-1/2"
            label={i18n.t(`contract.labels.${IndexAdjustmentInputFields.FIRST_TIME_REGULATION}`)}
            name={IndexAdjustmentInputFields.FIRST_TIME_REGULATION}
            value={fields[IndexAdjustmentInputFields.FIRST_TIME_REGULATION]?.value}
            onChange={value => {
              setChanges.set({
                value
              }, {
                name: IndexAdjustmentInputFields.FIRST_TIME_REGULATION
              })
            }}
          />
          <DateField
            extraClassNames="flex-grow w-1/2"
            label={i18n.t(`contract.labels.${IndexAdjustmentInputFields.BASIC_INDEX}`)}
            name={IndexAdjustmentInputFields.BASIC_INDEX}
            value={fields[IndexAdjustmentInputFields.BASIC_INDEX]?.value}
            onChange={value => {
              setChanges.set({
                value
              }, {
                name: IndexAdjustmentInputFields.BASIC_INDEX
              })
            }}
          />
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.5 {i18n.t(`contract.general.payment_provision`)}</div>
        </div>

        <RadioButtonGroup selectedValue={GetPaymentProvisionType()} className="flex flex-row gap-8">
          <RadioButton
            key={v4()}
            value={PaymentProvisionType.QUARTERLY_IN_ADVANCE}
            isDisabled={false}
            onClick={() => {
              setMiscChanges.set({
                value: PaymentProvisionType.QUARTERLY_IN_ADVANCE
              }, {
                name: PaymentProvisionInputFields.PROVISION_TYPE
              })
            }}
          >
            {i18n.t(`contract.fields.${PaymentProvisionInputFields.PROVISION_TYPE}.${PaymentProvisionType.QUARTERLY_IN_ADVANCE}`)}
          </RadioButton>
          <RadioButton
            key={v4()}
            value={PaymentProvisionType.MONTHLY_IN_ADVANCE}
            isDisabled={false}
            onClick={() => {
              setMiscChanges.set({
                value: PaymentProvisionType.MONTHLY_IN_ADVANCE
              }, {
                name: PaymentProvisionInputFields.PROVISION_TYPE
              })
            }}
          >
            {i18n.t(`contract.fields.${PaymentProvisionInputFields.PROVISION_TYPE}.${PaymentProvisionType.MONTHLY_IN_ADVANCE}`)}
          </RadioButton>

          <VisibleIfInvalidField name={PaymentProvisionInputFields.PROVISION_TYPE}>
            <div className="text-pink-600 text-xs mt-1">
              {i18n.t("errors.payment_provision_type_not_selected")}
            </div>
          </VisibleIfInvalidField>
        </RadioButtonGroup>
      </div>
    </div>
  </div>
}

const RentalCostsComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields
}) => {
  return <div>
    <h2>4. {i18n.t(`contract.general.rental_costs`)}</h2>
    <div className={`${styles.cardSectionColumn}`}>
      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.1 {i18n.t(`contract.general.rent`)}</div>
        </div>

        <div className={`${styles.row}`}>
          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${RentalCostsInputFields.TURNOVER_RENT}`)}
            required={true}
            value={(fields?.[RentalCostsInputFields.TURNOVER_RENT]?.value || null) as number}
          />
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${RentalCostsInputFields.MINIMUM_RENT}`)}
            required={true}
            value={(fields?.[RentalCostsInputFields.MINIMUM_RENT]?.value || null) as string}
          />
        </div>

        <VisibleIf predicate={!fields.minimumTurnOver?.value}>
          <div className="mt-3 font-bold text-right">
            <div>{i18n.t(`contract.labels.${RentalCostsInputFields.MINIMUM_TURNOVER}`)}</div>
          </div>
        </VisibleIf>
        <VisibleIf predicate={!!fields.minimumTurnOver?.value}>
          <div className="flex flex-row items-center justify-center gap-8 mt-3">
            <div className="flex-grow text-right font-bold w-1/2 ">
              <div className="w-full">{i18n.t(`contract.labels.${RentalCostsInputFields.MINIMUM_TURNOVER}`)}</div>
            </div>
            <AutocalculatedFieldWithOverrideReadOnly
              extraClassNames="flex-grow"
              inputExtraClassNames="text-right font-bold"
              value={
                fields.minimumTurnOver?.isCustom ?
                  fields.minimumTurnOver?.value :
                  NumberFormatter(fields.minimumTurnOver?.value)
              }
              isCustom={fields.minimumTurnOver?.isCustom}
            />
          </div>
        </VisibleIf>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.2 {i18n.t(`contract.general.marketing_costs`)}</div>
        </div>

        <div>
          {i18n.t(`contract.general.marketing_costs_description`)}
        </div>

        <div className="flex flex-row items-center justify-center gap-8">
          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${MarketingCostsInputFields.COST_PERCENT}`)}
            // name={MarketingCostsInputFields.COST_PERCENT}
            required={true}
            value={(fields?.[MarketingCostsInputFields.COST_PERCENT]?.value || null) as number}
          />
        </div>

        <VisibleIf predicate={!fields.minimumMarketingCost?.value}>
          <div className="font-bold mt-3 text-right">
            {i18n.t('components.custom.fill-in-fields',
              { fields: `${i18n.t(`contract.labels.${MarketingCostsInputFields.COST_PERCENT}`)}, ${i18n.t(`contract.labels.${RentalCostsInputFields.MINIMUM_TURNOVER}`)}` }
            )}
          </div>
        </VisibleIf>
        <VisibleIf predicate={!!fields.minimumMarketingCost?.value}>
          <div className={`${styles.row}`}>
            <div
              className="w-1/2 text-right font-bold">{i18n.t(`contract.labels.${MarketingCostsInputFields.MINIMUM_MARKETING_COST}`)}</div>
            <div className="flex-grow">
              <AutocalculatedFieldWithOverrideReadOnly
                inputExtraClassNames="text-right font-bold"
                value={
                  fields.minimumMarketingCost?.isCustom ?
                    fields.minimumMarketingCost?.value :
                    NumberFormatter(fields.minimumMarketingCost?.value)
                }
                isCustom={fields.minimumMarketingCost?.isCustom}
              />
            </div>
          </div>
        </VisibleIf>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.3 {i18n.t(`contract.general.marketing_costs`)}</div>
        </div>

        <div>
          {i18n.t(`contract.general.shared_costs_description`)}
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORE}`)}
            // name={SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORE}
            required={true}
            value={(fields?.[SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORE]?.value || null)}
          />
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORAGE}`)}
            // name={SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORAGE}
            required={true}
            value={(fields?.[SharedCostsInputFields.STIPULATED_COMMON_COSTS_STORAGE]?.value || null)}
          />
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.4 {i18n.t(`contract.general.index_adjustment`)}</div>
        </div>

        <div className={`${styles.row}`}>
          <DateFieldReadOnly
            extraClassNames="flex-grow w-1/2"
            label={i18n.t(`contract.labels.${IndexAdjustmentInputFields.FIRST_TIME_REGULATION}`)}
            value={fields[IndexAdjustmentInputFields.FIRST_TIME_REGULATION]?.value}
          />
          <DateFieldReadOnly
            extraClassNames="flex-grow w-1/2"
            label={i18n.t(`contract.labels.${IndexAdjustmentInputFields.BASIC_INDEX}`)}
            value={fields[IndexAdjustmentInputFields.BASIC_INDEX]?.value}
          />
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.5 {i18n.t(`contract.general.payment_provision`)}</div>
        </div>

        <TextFieldReadOnly
          value={i18n.t(`contract.fields.${PaymentProvisionInputFields.PROVISION_TYPE}.${fields[PaymentProvisionInputFields.PROVISION_TYPE]?.value}`)}
          // value={fields[PaymentProvisionInputFields.PROVISION_TYPE]?.value}
        />
      </div>
    </div>
  </div>
}
