import { db, fieldValue } from "src/producers/firebase";
import {ApplicationError, ErrorLevel, ErrorType, LogLevels, UserRole} from "src/types";

export const saveChangesToDB: producer = ({
  contractId = observe.contract.id,
  currentSession = observe.contract.currentSession,
  saveStatus = update.contract.loading.saveStatus,
  user = get.user,
  error = update.errors[param.id],
}) => {
  user = user.value();
  const isTenant = user?.data?.role === UserRole.TENANT;

  if (!currentSession || !user.data.role || !contractId) {
    return;
  }
  const changesToWrite: any = {};
  if (currentSession.changes) {
    Object.keys(currentSession.changes).forEach((c) => {
      if (!currentSession.changes?.[c]?.deleted !== true) {
        changesToWrite[c] = fieldValue.delete();
      } else {
        changesToWrite[c] = currentSession.changes?.[c];
      }
    });
    currentSession.changes = changesToWrite;
  }

  try {
    const contractDb = db.collection("contracts").doc(contractId);
    contractDb
      .collection("sessions")
      .doc(isTenant ? UserRole.TENANT : user.uid)
      .set(
        {
          ...currentSession,
          role: user.data.role,
          updatedAt: Date.now(),
        },
        { merge: true }
      )
      .then(() => {
        setTimeout(() => saveStatus.remove(), 5000);
        console.log("saved");
      });
  } catch (e) {
    saveStatus.set("error");
    error.set(
      {
        message: e,
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: "saveChangesToDB" }
    );
  }
};
