import { auth, GoogleAuthProvider, firebase } from '../firebase'
import {ApplicationError, ErrorLevel, ErrorType, LogLevels, RouteName} from 'src/types'

export const signInWithGoogle: producer = ({
  trigger = observe.session.triggers.signInWithGoogle,
  error = update.errors[param.id],
  // redirectToPath = get.routes.list[RouteName.DASHBOARD].redirectToPath,
  // redirectToSearch = get.routes.list[RouteName.LOGIN].redirectToSearch,
}) => {
  if (!trigger) {
    return
  }

  // window.sessionStorage.setItem('redirectToPath', redirectToPath.value())
  // window.sessionStorage.setItem('redirectToSearch', redirectToSearch.value())

  const provider = new GoogleAuthProvider()
  auth
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      auth.signInWithPopup(provider)
    })
    .catch((e: any) => {
      error.set(
        {
          message: e,
          level: LogLevels.ERROR,
        } as ApplicationError,
        { id: 'signInWithGoogle' }
      )
    })
}
