import React from "react";

import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import moment from "moment";
import { Language } from "src/types";

require("moment/locale/nb");

export const VersionDropdownRow: view = ({ version, createdAt, onClick, latest, header }) => {
  if (i18n.language === Language.NORWEGIAN) moment.locale("nb");
  else moment.locale("en");
  let versionName = `${i18n.t("sidebar.version")} ${parseInt(version)}`;
  if (latest) {
    versionName = `${i18n.t("contract_page.latest_version")} • ${i18n.t("sidebar.version")} ${parseInt(version)}`;
  }

  return (
    <div onClick={onClick} className="flex justify-between items-center mx-2 my-1">
      <div>
        <p key="1" className=" text-left font-medium">{`${versionName}`}</p>
        <p key="2" className="font-12 text-left">{`${i18n.t("contract_sidebar_contract_version_created")} ${moment(
          createdAt
        ).fromNow()}`}</p>
      </div>
      {!header && !latest && <SVGs.Eye />}
    </div>
  );
};
