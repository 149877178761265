export enum AddendumFields {
  FIRST_NAME = 'tenantContactPersonFirstName',
  LAST_NAME = 'tenantContactPersonLastName',
  PHONE = 'tenantPhone',
  EMAIL = 'tenantEmail',

  LANDLORD_NAME = 'landlordName',
  TENANT_NAME = 'tenantName',
  ORIGINAL_TENANT_NAME = 'originalTenantName',

  CONTRACT_TITLE = 'title',

  BACKGROUND_AND_PURPOSE = 'backgroundAndPurpose',
  OTHER_PROVISIONS = 'otherProvisions',
  DEROGATION = 'compulsoryDerogation',
  SECURITY = 'security',

  TERMINATION_OF_TENANCY = 'tenancyCancellation',
  REPAYMENT_OF_OUTSTANDING = 'pendingDownpayment',
  CONDITIONS_OF_TRANSPORT = 'conditionsOfTransport',

  CHANGE_OF_RENTAL_PERIOD = 'hasRentalPeriodChange',
  CHANGE_OF_RENTAL_PRICE = 'hasRentalPriceChange',
  RENTAL_HAS_EXTENSION_OPTIONS = 'rentalPeriodHasOptions',

  PROPERTY_TOTAL_AREA = 'propertyTotalArea',
  PROPERTY_SHOPPING_MALL = 'shoppingMall',
  PROPERTY_ADDRESS = 'propertyAddress',
  PROPERTY_MUNICIPALITY = 'municipality',
  PROPERTY_PARCEL_NUMBER = 'parcelNumber',
  PROPERTY_LOCAL_NO = 'propertyLocalNo',
  PROPERTY_LEVEL = 'propertyLevel',
  PROPERTY_APPROX_EXCL_AREA = 'propertyExclusiveArea',
  PROPERTY_COMMON_AREA = 'propertyCommonArea',
  PROPERTY_CONDITION_AND_USE = 'conditionAndUseStorage',

  RENTAL_PERIOD_DESCRIPTION = 'changeRentalPeriod',
  RENTAL_START = 'rentalStart',
  RENTAL_END = 'rentalEnd',
  RENTAL_PAYMENT_START = 'rentalPaymentStart',

  RENTAL_EXTENSION_TYPE = 'rentalPeriodExtensionType',
  RENTAL_EXTENSION_NUMBER_OF_YEARS = 'rentalPeriodExtensionYears',

  STORAGE_RENT = 'storageRent',
  SHARED_COSTS = 'sharedCosts',
  FIRST_TIME_REGULATION = 'firstTimeRegulation',
  BASIC_INDEX = 'indexAdjustmentBasicIndex',
  PROVISION_TYPE = 'paymentProvisionType',

  RENTAL_PRICE_DESCRIPTION = 'changeRentalPrice',
  TURNOVER_RENT = 'turnoverPercent',
  MINIMUM_RENT = 'minimumRent',
  MINIMUM_TURNOVER = 'minimumTurnOver',

  MARKETING_COST_DESCRIPTION = 'marketingCost',
  HAS_MARKETING_COST = "hasMarketingCost",
  MARKETING_CONTRIBUTIONS = 'marketingContributions',
  MINIMUM_CONTRIBUTION = 'minimumContribution',

  BUSINESS_VAT_STATUS = 'businessVATStatus',
  BUSINESS_VAT_PERCENTAGE = 'businessVATPercentage',

  TENANT_COMPANY_NAME = 'tenantName',
  TENANT_ORGANIZATION_NUMBER = 'tenantOrgNo',
  TENANT_ADDRESS = 'tenantAddress',
  TENANT_POSTAL_ADDRESS = 'tenantPostalAddress',
  TENANT_INVOICE_ADDRESS = 'tenantInvoiceAddress',
  TENANT_CONTACT_PERSON_FIRST_NAME = 'tenantContactPersonFirstName',
  TENANT_CONTACT_PERSON_LAST_NAME = 'tenantContactPersonLastName',
  TENANT_CONTACT_PERSON_PHONE_NUMBER = 'tenantPhone',
  TENANT_CONTACT_PERSON_EMAIL = 'tenantEmail',

  RENTAL_PERIOD_POTENTIAL_OPTIONS = 'rentalPeriodHasOptions',
}
