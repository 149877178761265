import { notification } from 'antd'
import { ErrorLevel } from 'src/types'
import { db } from 'src/producers/firebase'
import i18n from 'src/producers/languages/i18n'
import posthog from "posthog-js";

export const handleErrors: producer = ({
  errors = observe.errors,
  configuration = observe.config,
  acknowledged = update.errors[param.id].acknowledged,
}) => {

  if (!errors) return
  for (let errorId in errors) {
    const error = errors[errorId]
    console.error('ERROR', errorId, error)
    if (error.acknowledged || typeof error.message !== 'string' || !error.message) continue
    // console.log('ERROR to posthog: ', { errorId, message: error.message, description: error.description , error})
    if(configuration.posthog) posthog.capture('error', { errorId, message: error?.message, description: error?.description , error})
    let config = {
      duration: 0,
      message: i18n.t(`errors.${error.message}`),
      onClose: () => {
        acknowledged.set(true, { id: errorId })
        if (error.fromDb) db.collection('errors').doc(errorId).update({ acknowledged: true })
      }
    }
    config.description = `Error ID: "${errorId}"`
    if (error.description) config.description = i18n.t(`errors.${error.description}`)
    notification.error(config)
  }
  // Object.keys(errors).forEach((e) => {
  //   // if (!errors[e].showed && [ErrorLevel.WARNING, ErrorLevel.BREAKING].includes(errors[e].level)) {
  //   notification.error({
  //     message: e,
  //     description: errors[e].message,
  //   })
  //   setShow.set(true, { id: e })
  //   // }
  // })
}
