import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import React from "react";

export const InfoGreen = () => {
  return (
    <div className="bg-green-200 px-3 py-4 flex items-center rounded mt-4 mb-2 w-full">
      <SVGs.SigningTree className="mr-3 " />
      <p>{i18n.t("legacy.info_signing_green")}</p>
    </div>
  );
};
