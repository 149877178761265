import { SVGs } from 'src/assets'
import { ThumbnailCard, ThumbnailCardFooter, ThumbnailCardHeader } from 'src/components'
import i18n from 'src/producers/languages/i18n'
import { ContractFilterOption, ContractStatus } from 'src/types'
import { AddendumMenu } from './AddendumMenu'
import { router } from 'src/router'

export const ExistingAddendumCard: view = ({
  addendum,
  currentQuery = observe.routes.currentQuery,
  updateReroute = update.routes.triggers.reroute,
  // setCurrentPath = update.routes.currentPath, // TODO: update with new router
}) => {
  const status = addendum.state?.status || addendum.status
  const title =
    addendum.managerState?.fields?.title?.current?.value || addendum.clientState?.fields?.title?.current?.value

  const canBeDeleted = status === ContractStatus.DRAFT
  const renderStatus = () => {
    let statusIcon
    let statusText

    if (status === ContractStatus.DRAFT) {
      statusIcon = <SVGs.ContractStatusDraft/>
      statusText = i18n.t('dashboard.card_status_draft')
    }
    if (status === ContractStatus.NEGOTIATION) {
      statusIcon = <SVGs.ContractStatusNegotiation/>
      statusText = i18n.t('dashboard.card_status_negotiation')
    }
    if (status === ContractStatus.SIGNING) {
      statusIcon = <SVGs.ContractStatusSigning/>
      statusText = i18n.t('dashboard.card_status_signing')
    }
    if (status === ContractStatus.ACTIVE) {
      statusIcon = <SVGs.ContractStatusActive/>
      statusText = i18n.t('dashboard.card_status_active')
    }
    if (addendum.filterTag === ContractFilterOption.LOST) {
      statusIcon = <SVGs.ContractStatusLost/>
      statusText = i18n.t('dashboard.card_status_lost')
    }

    return (
      <>
        <span>{statusText}</span>
        <span>{statusIcon && statusIcon}</span>
      </>
    )
  }

  return (
    <ThumbnailCard
      className="transition duration-500 ease-in-out shadow-none hover:shadow-lg border klp-border1 w-56 h-64 m-4"
      onClick={() => {
        if (addendum.revision > 3) {
          router.navigate(`/contract/${addendum.id}` + (currentQuery || ''))
          updateReroute.set(Date.now())
          // setCurrentPath.set('/contract_v1/' + addendum.id)
        }
        else {
          router.navigate(`/addendum/${addendum.id}` + (currentQuery || ''))
          updateReroute.set(Date.now())
          // setCurrentPath.set('/addendum/' + addendum.id)
        }
      }}
    >
      <ThumbnailCardHeader key={`tch-${addendum.id}`} title={title || addendum.id}>
        <AddendumMenu contractId={addendum.id} canBeDeleted={canBeDeleted} filterTag={addendum.filterTag}/>
      </ThumbnailCardHeader>

      <ThumbnailCardFooter key={`tcf-${addendum.id}`} className="justify-between">
        {renderStatus()}
      </ThumbnailCardFooter>
    </ThumbnailCard>
  )
}
