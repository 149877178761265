import { path } from "@c11/engine.runtime";
import { DatePicker, Menu, Dropdown } from "antd";
import moment from "moment";

import { UserRole } from "src/types";
import { HtmlRender } from "src/pages/Contract/subviews/ContractForm/fields/HtmlRender";
import { TextEditor } from "src/components/TextEditor";
import { FieldInputType } from "src/templates/types/template";
import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { CalculationFieldManager } from "./CalculationFieldManager";
import { NumberField, TextField, FreeTextField } from "./TextFieldsEngine";

import { Label } from "./Label";
import { InvalidMessage } from "./InvalidMessage";
import dayjs from 'dayjs'

export const FormField: view = ({
  fieldName,
  varName,
  readOnly,
  templateField = observe.contract.fields[arg.fieldName],
  userRole = get.user.data.role,
}) => {
  if (!templateField) return null;
  const { inputType, extraClasses, placeholder } = templateField;
  const isManager = userRole.value() === UserRole.MANAGER;

  if (inputType === FieldInputType.CalculationCustom && isManager)
    return <CalculationFieldManager {...{ fieldName, varName, readOnly }} />;
  let FieldToRender = RegularInput;
  if (inputType === FieldInputType.Date) FieldToRender = DateInput;
  if (inputType === FieldInputType.Number) FieldToRender = NumberInput;
  if (inputType === FieldInputType.FreeText) FieldToRender = FreeTextInput;
  if (inputType === FieldInputType.Dropdown) FieldToRender = DropdownInput;

  return <FieldToRender {...{ fieldName, varName, readOnly, extraClasses, placeholder }} />;
};

export const RegularInput: view = ({
  varName,
  fieldName,
  extraClasses,
  readOnly,
  placeholder,
  value = observe.contract.values[arg.varName].displayedValue,
}) => (
  <div className={`w-1/2 my-2 ${extraClasses}`}>
    <Label key="00" fieldName={fieldName} />
    <TextField
      path={path.newFiedValues[varName]}
      value={value}
      outputPath={path.contract.toSave.changes[varName]}
      readOnly={readOnly}
      className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 text-gray-800 leading-tight ${
        readOnly ? "bg-gray-200" : "focus:bg-green-300 focus:border-transparent"
      }`}
      placeholder={placeholder}
    />
    <InvalidMessage varName={varName} />
  </div>
);
export const RegularInputGeneral: view = ({
  varName,
  label,
  required,
  extraClasses,
  readOnly,
  placeholder,
  invalidMessage,
  validationFunction,
  value = observe.contract.values[arg.varName].displayedValue,
}) => (
  <div className={`w-1/2 mt-1 mb-4 ${extraClasses}`}>
    {label && (
      <label className="block text-gray-700 text-xs">
        {required && <span className="text-pink-600">*</span>}
        <span>{`${label} `}</span>
      </label>
    )}
    <TextField
      path={path.newFiedValues[varName]}
      value={value}
      outputPath={path.contract.toSave.changes[varName]}
      readOnly={readOnly}
      className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 text-gray-800 leading-tight ${
        readOnly ? "bg-gray-200" : "focus:bg-green-300 focus:border-transparent"
      }`}
      placeholder={placeholder}
    />
    <InvalidMessage varName={varName} />
    {/*{value && validationFunction && !validationFunction(value || "") && (*/}
    {/*  <div key="invalidFields" className="text-pink-600 text-xs">*/}
    {/*    {invalidMessage}*/}
    {/*  </div>*/}
    {/*)}*/}
  </div>
);
const NumberInput: view = ({
  varName,
  fieldName,
  extraClasses,
  readOnly,
  placeholder,
  value = observe.contract.values[arg.varName].displayedValue,
}) => (
  <div className={`w-1/2 my-2 ${extraClasses}`}>
    <Label key="00" fieldName={fieldName} />
    <NumberField
      path={path.newFiedValues[varName]}
      type="number"
      value={value}
      outputPath={path.contract.toSave.changes[varName]}
      readOnly={readOnly}
      className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 text-gray-800 leading-tight ${
        readOnly ? "bg-gray-200" : "focus:bg-green-300 focus:border-transparent"
      }`}
      placeholder={placeholder}
    />
    <InvalidMessage varName={varName} />
  </div>
);

export const NumberInputGeneral: view = ({
  varName,
  label,
  required,
  extraClasses,
  readOnly,
  placeholder,
  invalidMessage,
  validationFunction,
  value = observe.contract.values[arg.varName].displayedValue,
}) => (
  <div className={`w-1/2 my-2 ${extraClasses}`}>
    {label && (
      <label
        style={{
          display: "block",
          color: "#6F6F6F",
          fontSize: "13px",
        }}>
        {required && <span className="text-pink-600">*</span>}
        <span>{`${label} `}</span>
      </label>
    )}
    <NumberField
      path={path.newFiedValues[varName]}
      type="number"
      value={value}
      outputPath={path.contract.toSave.changes[varName]}
      readOnly={readOnly}
      className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 text-gray-800 leading-tight ${
        readOnly ? "bg-gray-200" : "focus:bg-green-300 focus:border-transparent"
      }`}
      placeholder={placeholder}
    />
    {value && validationFunction && !validationFunction(value || "") && (
      <div key="invalidFields" className="text-pink-600 text-xs">
        {invalidMessage}
      </div>
    )}
  </div>
);

const FreeTextInput: view = ({
  varName,
  fieldName,
  extraClasses,
  readOnly,
  value = observe.contract.values[arg.varName].displayedValue,
  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  return (
    <div className={`w-1/2 my-2 ${extraClasses}`}>
      <Label key="00" fieldName={fieldName} />
      {isTenant ? (
        <HtmlRender html={value} />
      ) : (
        <div translate={"no"} className="notranslate">
          <FreeTextField
            path={path.newFiedValues[varName]}
            value={value}
            outputPath={path.contract.toSave.changes[varName]}
            readOnly={readOnly}
          />
        </div>
      )}
    </div>
  );
};

export const FreeTextFieldGeneral: view = ({
  varName,
  extraClasses,
  htmlOnly,
  readOnly,
  value = observe.contract.values[arg.varName].displayedValue,
  rerendersAllFields = observe.contract.rerendersAllFields,
  writeValue = update.contract.toSave.changes[arg.varName],
}) => {
  const key = rerendersAllFields || !!value;
  return (
    <div className={` my-2 mb-5 ${extraClasses}`}>
      {htmlOnly ? (
        <HtmlRender html={value} />
      ) : (
        <div translate={"no"} className="notranslate w-full">
          <TextEditor
            key={key}
            value={value}
            readOnly={readOnly}
            onChange={(e: any) => {
              if (e) {
                writeValue.set({
                  value: e,
                  createdAt: Date.now(),
                });
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

const DateInput: view = ({
  varName,
  fieldName,
  extraClasses,
  readOnly,
  placeholder,
  value = observe.contract.values[arg.varName].displayedValue,
  writeValue = update.contract.toSave.changes[arg.varName],
  rerendersAllFields = observe.contract.rerendersAllFields,
}) => (
  <div className={`w-1/2 my-2 ${extraClasses}`}>
    <Label key="00" fieldName={fieldName} />
    <DatePicker
      key={rerendersAllFields}
      className={`text-xl ${readOnly ? "bg-gray-200" : "bg-transparent"}`}
      placeholder={placeholder}
      style={{ width: "100%", height: "45px" }}
      onChange={(date) => {
        writeValue.set({
          value: date === null ? null : date?.valueOf(),
          createdAt: Date.now(),
        });
      }}
      value={value ? dayjs(value) : null}
      disabled={readOnly}
      format={"DD.MM.YYYY"}
    />
    <InvalidMessage varName={varName} />
  </div>
);
export const DateFieldGeneral: view = ({
  varName,
  readOnly,
  label,
  required,
  placeholder,
  value = observe.contract.values[arg.varName].displayedValue,
  writeValue = update.contract.toSave.changes[arg.varName],
  rerendersAllFields = observe.contract.rerendersAllFields,
}) => (
  <div>
    {label && (
      <label className="block text-gray-700 text-xs">
        {required && <span className="text-pink-600">*</span>}
        <span>{`${label} `}</span>
      </label>
    )}
    <DatePicker
      key={rerendersAllFields}
      className="text-xl"
      placeholder={placeholder}
      style={{ width: "100%", height: "45px", background: readOnly ? "#f0f0f0" : "transparent" }}
      onChange={(date) =>
        writeValue.set({
          value: date?.valueOf(),
          createdAt: Date.now(),
        })
      }
      value={value ? dayjs(value) : null}
      disabled={readOnly}
      format={"DD.MM.YYYY"}
    />
  </div>
);

export const DropdownInput: view = ({
  varName,
  fieldName,
  extraClasses,
  readOnly,
  placeholder,
  value = observe.contract.values[arg.varName].displayedValue,
  // hack the displayValue to update instantly
  updateDisplayedValue = update.contract.values[arg.varName].displayedValue,
  writeValue = update.contract.toSave.changes[arg.varName],
  templateField = observe.contract.fields[arg.fieldName],
}) => {
  const choises = templateField?.dropdownChoices || [];
  const hasMoreThenOneChoise = choises.length > 1;

  const menu = (
    <Menu>
      {choises.map((choise: any, index: any) => (
        <Menu.Item key={index}>
          <DropdownRow
            choise={choise}
            onClick={() => {
              writeValue.set({
                value: choise,
                createdAt: Date.now(),
              });
              updateDisplayedValue.set(choise);
            }}
          />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={`w-1/2 my-2 ${extraClasses}`}>
      <Label key="00" fieldName={fieldName} />
      <div className=" inline-block relative w-full" id={`fieldDropdown-${fieldName}`}>
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          overlayClassName={"fieldDropdown"}
          getPopupContainer={() => document.getElementById(`fieldDropdown-${fieldName}`) as HTMLElement}
          disabled={!hasMoreThenOneChoise || readOnly}>
          <button
            className={`${
              readOnly ? "bg-gray-200" : "bg-white"
            } p-3 leading-tight flex w-full justify-between items-center border klp-border1`}>
            {value ? <DropdownRow choise={value} /> : <span key="1">{placeholder} </span>}
            {hasMoreThenOneChoise && !readOnly && <SVGs.DropdownArrow />}
          </button>
        </Dropdown>
      </div>
    </div>
  );
};
export const DropdownInputGeneral: view = ({
  varName,
  required,
  extraClasses,
  readOnly,
  placeholder,
  choises,
  value = observe.contract.values[arg.varName].displayedValue,
  // hack the displayValue to update instantly
  updateDisplayedValue = update.contract.values[arg.varName].displayedValue,
  writeValue = update.contract.toSave.changes[arg.varName],
}) => {
  const hasMoreThenOneChoise = choises.length > 1;

  const menu = (
    <Menu>
      {choises.map((choise: any, index: any) => (
        <Menu.Item key={index}>
          <DropdownRow
            choise={choise}
            onClick={() => {
              writeValue.set({
                value: choise,
                createdAt: Date.now(),
              });
              updateDisplayedValue.set(choise);
            }}
          />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={`w-1/2 ${extraClasses}`}>
      <label className="block text-gray-700 text-xs">
        {required && <span className="text-pink-600">*</span>}
        <span>{`${i18n.t(varName)} `}</span>
      </label>
      <div className=" inline-block relative w-full" id={`fieldDropdown-${varName}`}>
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          overlayClassName={"fieldDropdown"}
          getPopupContainer={() => document.getElementById(`fieldDropdown-${varName}`) as HTMLElement}
          disabled={!hasMoreThenOneChoise || readOnly}>
          <button
            className={`${
              readOnly ? "bg-gray-200" : "bg-white"
            } p-3 leading-tight flex w-full justify-between items-center border klp-border1`}>
            {value ? (
              <DropdownRow choise={value} />
            ) : (
              <span className="text-gray-500" key="1">
                {placeholder}
              </span>
            )}
            {hasMoreThenOneChoise && !readOnly && <SVGs.DropdownArrow />}
          </button>
        </Dropdown>
      </div>
    </div>
  );
};

// FIXME: should take translation outside
const DropdownRow: view = ({ choise, onClick }) => {
  const value = typeof choise === "number" ? choise : i18n.t(choise);
  return (
    <div onClick={onClick} className="flex justify-between items-center">
      <div>
        <p key="1" className="ml-2 text-left font-medium">
          {value}
        </p>
      </div>
    </div>
  );
};
