import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'

export const sendContract: producer = async ({
  trigger = observe.contract.triggers.sendContract,

  removeTrigger = update.contract.triggers.sendContract,
  recipients = get.contract.original.recipients,
  contractId = get.contract.id,
  resetCurrentSession = update.contract.currentSession,
  clearSessions = update.contract.triggers.clearSessions,
  error = update.errors[param.id],
  stateTransition = update.contract.loading.stateTransition,
  rawUser = get.rawUser,
  localFunctions = get.localFunctions,
}) => {
  if (!trigger) return

  try {
    contractId = contractId.value()
    stateTransition.set(true)

    const payload = trigger.payload
    Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key])

    const command = {
      action: CommandActions.CREATE_DIGITAL_CONTRACT,
      contractId,
      ...payload,
      recipients: recipients.value(),
    }

    await axios.post(
      getFunctionsUrl('commandPublisher', localFunctions.value()),
      command,
      {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type': 'application/json'
        }
      }
    )

    // await commandHandler(command);

    clearSessions.set(Date.now())
    resetCurrentSession.remove()
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'sendContract-contract' }
    )
  } finally {
    removeTrigger.remove()
  }
}
