export const deleteAllChanges: producer = ({
  trigger = observe.legacyContract.triggers.deleteAllChanges,
  removeTrigger = update.legacyContract.triggers.deleteAllChanges,
  removeChanges = update.legacyContract.currentSession.changes,
  deleteSessionFromDB = update.legacyContract.triggers.deleteSessionFromDB,
  rerendersAllFields = update.legacyContract.rerendersAllFields,
}) => {
  if (!trigger) {
    return;
  }
  removeTrigger.remove();
  removeChanges.remove();
  deleteSessionFromDB.set(Date.now());
  rerendersAllFields.set(Date.now());
};
