import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType, UserRole } from "src/types";

export const syncAddendumsForContract: producer = async ({
  contractId = observe.contract.id,
  userData = get.user.data,
  setAddendums = update.contract.addendums[param.id],
  setListener = update.listeners[arg.id].addendums,
  error = update.errors[param.id],
}) => {
  if (!contractId) {
    return;
  }
  userData = userData.value();
  try {
    let cursor = db.collection("contracts").where("parentContractId", "==", contractId);
    if (userData.role === UserRole.TENANT) {
      cursor = cursor.where("tenantId", "in", userData.tenantId);
    }
    const listener = cursor.onSnapshot((snapshot) => {
      snapshot.forEach((snap) => {
        const data = snap.data();
        if (!data) return;
        setAddendums.set(data, { id: snap.id });
      });
      snapshot.docChanges().forEach((change: any) => {
        if (change.type === "removed") {
          const data = change.doc.data();
          setAddendums.remove({ id: data.id });
        }
      });
    });
    setListener.set(listener);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncAddendumsForContract" }
    );
  }
};
