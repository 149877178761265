import { Button } from '@c11/components'
import './transferContractData.css'

import { DownloadIcon } from 'src/assets/svg'
import i18n from 'src/producers/languages/i18n'
import { ContractStatus, UserRole } from 'src/types'
import { functions } from 'src/producers/firebase'

export const TransferContractData: view = ({
  isLoading = observe.contract.flags.transferContractDataLoading,
  status = get.contract.originalv2.status.value(),
  id = get.contract.id.value(),
  role = get.user.data.role.value(),
  updateLoading = update.contract.flags.transferContractDataLoading
}) => {
  // if (role !== UserRole.MANAGER || status !== ContractStatus.ACTIVE) return
  if (role !== UserRole.MANAGER) return

  async function sendContractData() {
    try {
      console.log('idddddd', id)
      updateLoading.set(true)
      const contractDataTransfer = functions.httpsCallable('contractDataTransferAction')
      console.log('id', id)
      await contractDataTransfer({ contractId: id })
      console.log('success')
    } catch (err) {
      console.error('Error on sending data: ', err)
    }
    updateLoading.set(false)
  }

  return (
    <div>
      <Button
        variant="secondary"
        className="preview-contract-btn flex uppercase gap-2 "
        onClick={async () => {
          await sendContractData()
        }}
        isLoading={isLoading}
      >
        <DownloadIcon/>
        <span>{i18n.t('contract_navbar.send_contract_data')}</span>
      </Button>
    </div>
  )
}
