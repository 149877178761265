import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const clearSessions: producer = async ({
  contractId = observe.contract.id,
  trigger = observe.contract.triggers.clearSessions,
  removeTrigger = update.contract.triggers.clearSessions,
  error = update.errors[param.id],
}) => {
  if (!contractId || !trigger) return;
  removeTrigger.remove();
  try {
    //todo maybe another version would be to have version on each session and never delete them
    const sessionsDb = db.collection("contracts").doc(contractId).collection("sessions");
    const sessionsSnap = await sessionsDb.get();
    console.log("ceva", sessionsSnap);
    const batch = db.batch();
    for (const doc of sessionsSnap.docs) {
      batch.delete(doc.ref);
    }
    await batch.commit();
    console.log("sessions cleared");
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "clearSessions" }
    );
  }
};
