import { ContractStatus } from "src/types";

export const setReadOnlyContract: producer = ({
  status = observe.contract.original.state.status,
  isReadOnlyContract = update.contract.isReadOnlyContract,
  displayedVersion = observe.contract.displayedVersion,
}) => {
  if (status === ContractStatus.SIGNING || status === ContractStatus.ACTIVE || displayedVersion) {
    isReadOnlyContract.set(true);
  } else {
    isReadOnlyContract.remove();
  }
};
