import React from "react";

import i18n from "src/producers/languages/i18n";
import { TooltipWrapper } from "src/components";

export const CreateVersionButton: view = ({ hasChanges, saveVersion = update.legacyContract.triggers.saveVersion }) => {
  if (hasChanges) {
    return (
      <button
        className={"appearance-none py-3 px-4 bg-gray-800 text-white rounded mr-4"}
        onClick={() => saveVersion.set(Date.now())}>
        <div className={`uppercase`}>{i18n.t("button_create_new_version")}</div>
      </button>
    );
  } else {
    return (
      <TooltipWrapper text={i18n.t("button_create_new_version_tooltip")}>
        <button className={"appearance-none py-3 px-4 bg-gray-200 hover:font-bold rounded mr-4 "}>
          <div className={`uppercase text-body-100`}>
            {i18n.t("button_create_new_version")}
          </div>
        </button>
      </TooltipWrapper>
    );
  }
};
