import React from "react";

import { FieldInputType } from "src/templates/types/template";
import {NumericFormat} from "react-number-format";
import i18n from "src/producers/languages/i18n";
import { Label } from "./Label";
import { HtmlRender } from "./HtmlRender";

export const PlainTextField: view = ({
  fieldName,
  varName,
  value = observe.contract.values[arg.varName].displayedValue,
  templateField = observe.contract.fields[arg.fieldName],
}) => {
  if (!templateField) return null;

  const { extraClasses, inputType } = templateField;

  return (
    <div className={`w-1/2 my-2 ${extraClasses}`}>
      <Label key="0" fieldName={fieldName} />
      <p className="font-medium" style={{ marginTop: 2 }}>
        {inputType === FieldInputType.Number || inputType === FieldInputType.CalculationCustom ? (
          <NumericFormat
            key={"1"}
            value={value}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            // isNumericString
          />
        ) : inputType === FieldInputType.Dropdown ? (
          i18n.t(value)
        ) : inputType === FieldInputType.FreeText ? (
          <HtmlRender html={value} />
        ) : (
          value
        )}
      </p>
    </div>
  );
};
