import i18n from "src/producers/languages/i18n";
import { FileIcon } from "src/assets/svg";
import { UploadPaperContractButton } from "src/pages/Contract/subviews/ContractForm/UploadPaperContractButton/UploadPaperContractButton";
import { ContractStatus, UserRole } from "src/types";
import styles from "../../../styles.module.css"

export const ContractPDFClause: view = ({
  leaseDocument = observe.contract.original.state.contractPdf,
  status = observe.contract.original.status,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isActive = status === ContractStatus.ACTIVE;
  return (
    <div className={`${styles.contractPdfClause}`}>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-4 items-center">
          <FileIcon />
          {leaseDocument ? (
            <span className="font-semibold italic">{leaseDocument.fileName}</span>
          ) : (
            <span>{i18n.t("contract_page.contract_pdf_clause_p")}</span>
          )}
          {isManager && !isActive && (
          <div className="mr-12">
            <UploadPaperContractButton />
          </div>
        )}
        </div>

      </div>
    </div>
  );
};
