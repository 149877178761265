import React from "react";

import i18n from "src/producers/languages/i18n";
import { Clause, ClauseType } from "src/templates/types/template";
import {
  Heading,
  Intro,
  Parties,
  RentalProperty,
  RentalPeriod,
  RentalCosts,
  Vat,
  SpecialProvisions,
  Security,
  CompulsoryDerogation,
  Signature,
} from ".";

export const FactoryClause: view = ({
  clauseID,
  clause = observe.legacyContract.template.sections.mainContract.clauses[arg.clauseID],
}: {
  clauseID: string;
  clause: Clause;
}) => {
  if (!clause) return null;

  //TODO
  if (clause.type === ClauseType.Custom) {
    switch (clause.name) {
      case "heading":
        return <Heading key={clauseID} clause={clause} />;
      case "intro":
        return <Intro key="intro" title={i18n.t("clause_intro_title")} />;
      case "parties":
        return <Parties key="parties" title={i18n.t("clause_parties_title")} />;
      case "rentalProperty":
        return <RentalProperty key="rentalProperty" title={i18n.t("clause_rental_property_title")} />;
      case "rentalPeriod":
        return <RentalPeriod key="rentalPeriod" title={i18n.t("clause_rental_period_title")} />;
      case "rentalCosts":
        return <RentalCosts key="rentalCosts" title={i18n.t("clause_rental_costs_title")} />;
      case "vat":
        return <Vat key="vat" title={i18n.t("clause_vat_title")} />;
      case "specialProvisions":
        return <SpecialProvisions key="specialProvisions" title={i18n.t("clause_special_provisions_title")} />;
      case "security":
        return <Security key="security" title={i18n.t("clause_security_title")} />;
      case "compulsoryDerogation":
        return <CompulsoryDerogation key="compulsoryDerogation" title={i18n.t("clause_compulsory_derogation_title")} />;
      case "signature":
        return <Signature key="signature" title={i18n.t("clause_signature_title")} />;
      default:
        return null;
    }
  }
  return null;
};
