import { template1 } from "src/templates/KLP-digital-v2/template";

export const syncTemplate: producer = ({
  id = observe.legacyContract.id,
  setTemplate = update.legacyContract.template,
}) => {
  if (!id) {
    return;
  }
  try {
    setTemplate.set(template1);
  } catch (e) {
    console.error("syncTemplate", e);
  }
};
