import i18n from 'src/producers/languages/i18n'
import styles from '../pages/ContractV2/styles.module.css'

import { VisibleIf } from './visible-if'
import { v4 } from 'uuid'
import { Switch } from '@c11/components'

type SubClause = {
  title?: string,
  index?: string,

  children?: any
}

type Clause = SubClause & {
  isClauseToggled?: boolean,
  onClauseToggled?: () => void,
}

export const SubClause: React.FC<SubClause> = ({
  title, index, children
}) => {
  return <div className={`${styles.cardSection}`}>
    <VisibleIf predicate={!!index || !!title}>
      <div className="sub-clause-header flex flex-row">
        <div className="flex flex-row gap-2 flex-grow">
          <VisibleIf predicate={!!index}>
            <div>{index}</div>
          </VisibleIf>
          <VisibleIf predicate={!!title}>
            <div>{title}</div>
          </VisibleIf>
        </div>
      </div>
    </VisibleIf>
    {children}
  </div>
}

export const Clause: React.FC<Clause> = ({
  title, index,
  onClauseToggled, isClauseToggled,
  isToggleDisabled,
  children
}) => {
  return <div className={`${styles.cardSectionColumn} ${styles.clause}`}>
    <div className="flex flex-row flex-grow">
      <h2 className="flex flex-row gap-2 flex-grow">
        <VisibleIf predicate={!!index}>
          <div>{index}</div>
        </VisibleIf>
        <VisibleIf predicate={!!title}>
          <div>{title || ''}</div>
        </VisibleIf>
      </h2>

      <VisibleIf predicate={!!onClauseToggled && !!onClauseToggled.bind}>
        <div className="flex-grow self-end text-right">
          <Switch
            key={v4()}
            className={`secondary-switch-${isClauseToggled ? 'checked' : 'unchecked'}`}
            isChecked={isClauseToggled}
            isDisabled={isToggleDisabled}
            onClick={_ => !isToggleDisabled && onClauseToggled && onClauseToggled()}
          />
        </div>
      </VisibleIf>
    </div>

    {children}
  </div>
}
