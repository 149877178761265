import React from "react";

import { FieldInputType } from "src/templates/types/template";
import {NumericFormat} from "react-number-format";
import i18n from "src/producers/languages/i18n";
import { HtmlRender } from "./HtmlRender";
import moment from "moment";

export const PlainTextFieldGeneral: view = ({
  varName,
  label,
  required,
  inputType,
  extraClasses,
  value = observe.contract.values[arg.varName].displayedValue,
}) => {
  return (
    <div className={`w-1/2 my-2 ${extraClasses}`}>
      {inputType !== FieldInputType.FreeText && label && (
        <label
          style={{
            display: "block",
            color: "#6F6F6F",
            fontSize: "13px",
          }}>
          {required && <span className="text-pink-600">*</span>}
          <span>{`${label} `}</span>
        </label>
      )}
      <div className="font-medium" style={{ marginTop: 2 }}>
        {inputType === FieldInputType.Number || inputType === FieldInputType.CalculationCustom ? (
          <NumericFormat
            key={"1"}
            value={value}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            // isNumericString
          />
        ) : inputType === FieldInputType.Dropdown ? (
          i18n.t(value)
        ) : inputType === FieldInputType.FreeText ? (
          <HtmlRender html={value} />
        ) : inputType === FieldInputType.Text ? (
          value
        ) : value ? (
          moment(value).utc().format("DD.MM.YYYY")
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
