import React from "react";

import i18n from "src/producers/languages/i18n";
import {NumericFormat} from "react-number-format";
import { path } from "@c11/engine.runtime";
import { NumberField } from "./TextFieldsEngine";
import { Checkbox } from "./Checkbox";

export const CalculationFieldManager: view = ({
  varName,
  fieldName,
  readOnly,
  isCustom = observe.contract.values[arg.varName].custom,
  value = observe.contract.values[arg.varName].displayedValue,
  templateField = observe.contract.fields[arg.fieldName],
}) => {
  const { label } = templateField || { label: i18n.t("clause_rental_costs_minimum_turnover") };
  return (
    <div className={"w-full flex my-2 items-center"}>
      {label && <div className={`w-1/2 text-right font-bold pr-8`}>{`${label}:`}</div>}
      <div className={` relative ${label ? "w-1/2" : "w-full"} `}>
        {readOnly || !isCustom ? (
          <NumericFormat
            key={"1"}
            className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 leading-tight ${
              isCustom ? "focus:bg-green-300 focus:border-transparent" : "bg-gray-200"
            }`}
            value={value}
            readOnly
            thousandSeparator={"."}
            decimalSeparator={","}
          />
        ) : (
          <NumberField
            path={path.newFiedValues[varName]}
            type="number"
            value={value}
            varName={varName}
            custom={isCustom}
            outputPath={path.contract.toSave.changes[varName]}
            readOnly={false}
            className={`shadow-sm appearance-none border focus:outline-none  klp-border1 w-full p-3 text-gray-800 leading-tight ${
              readOnly ? "bg-gray-200" : "focus:bg-green-300 focus:border-transparent"
            }`}
          />
        )}
        <CustomButton varName={varName} disabled={readOnly} />
      </div>
    </div>
  );
};

const CustomButton: view = ({
  varName,
  disabled,
  isCustom = observe.contract.values[arg.varName].custom,
  setChanges = update.contract.toSave.changes[arg.varName],
  value = observe.contract.values[arg.varName].displayedValue,
}) => {
  function handleClick() {
    if (disabled) return;
    if (isCustom) {
      setChanges.set({ value, createdAt: Date.now(), custom: false });
    } else {
      setChanges.set({ value, createdAt: Date.now(), custom: true });
    }
  }
  return (
    <div className="flex justify-end items-center absolute top-0 bottom-0 right-0">
      <VerticalLine key="2" />
      <p key="4" className="font-normal text-gray-700">
        {i18n.t("custom_checkbox")}
      </p>
      <div key="5" style={{ margin: "5px 0px 0px 10px" }}>
        <Checkbox small checked={isCustom} onClick={handleClick} />
      </div>
    </div>
  );
};

const VerticalLine: view = () => (
  <div
    className="bg-gray-500"
    style={{
      width: "2px",
      height: "20px",
      margin: "0px 15px",
    }}
  />
);
