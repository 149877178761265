import { RouteName } from 'src/types'

export const contract: producer = ({
  contractIsMounted = observe.routes.list[RouteName.CONTRACT].isMounted,
  addendumIsMounted = observe.routes.list[RouteName.ADDENDUM].isMounted,
  clearAll = update.contract,
  listeners = get.listeners,
  updateListeners = update.listeners,
}) => {
  if (contractIsMounted || addendumIsMounted) return
  // clearContractState({ clearData: clearAll, clearListeners: updateListeners, listeners })
  // listeners = listeners.value()
  // clearAll.remove()
  // for (const contractId in listeners) {
  //   const lists = listeners[contractId]
  //   for (const listener in lists) {
  //     lists[listener]()
  //   }
  // }
  // updateListeners.remove()
}

export const clearContractState = ({
  clearData, clearListeners, listeners
}) => {
  listeners = listeners.value()
  clearData.remove()
  for (const contractId in listeners) {
    const lists = listeners[contractId]
    for (const listener in lists) {
      lists[listener]()
    }
  }
  clearListeners.remove()
}
