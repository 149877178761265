import { SVGs } from 'src/assets'
import './NavbarMenu.css'
import i18n from 'src/producers/languages/i18n'
import { AddendumPageSubview, ContractPageSubview, ContractStatus, ContractType, RouteName } from 'src/types'
import { VisibleIf } from 'src/components_generic/visible-if'
import { ContractTemplate } from 'src/pages/ContractV2/contract.types'

export const NavbarMenu: view = ({
  contract = observe.contract.originalv2,
  attachments = observe.contract.attachmentsViewModel,
  hasChanges = observe.contract.hasChanges,
  // status = observe.contract.originalv2.state.status,
  status = observe.contract.originalv2.status,
  addendumIsMounted = observe.routes.list[RouteName.ADDENDUM].isMounted,
  addendumFeatureFlag = observe.featureFlags.addendum.main
}) => {
  if (!contract) return null
  const isActive = status === ContractStatus.ACTIVE
  const numberOfAppendices = attachments?.length
  const { contractType } = contract
  return (
    <div className="flex">
      {!addendumIsMounted ? (
        // ContractV2  Menu
        <>
          <MenuItem option={ContractPageSubview.CONTRACT} route={RouteName.CONTRACT_V2}>
            <div className="flex items-center px-5">
              {hasChanges && <SVGs.ExclamationCircleUnfilled className="mr-2"/>}
              {i18n.t('contract_navbar.contract')}
            </div>
          </MenuItem>
          {contractType !== ContractType.PAPER && (
            <MenuItem option={ContractPageSubview.ATTACHMENTS} route={RouteName.CONTRACT_V2}>
              <div className="flex items-center px-5">
                {i18n.t('contract_navbar.attachments')}
                {numberOfAppendices > 0 && ` (${numberOfAppendices})`}
              </div>
            </MenuItem>
          )}
          {contractType !== ContractType.PAPER && (
            <MenuItem option={ContractPageSubview.PARTIES} route={RouteName.CONTRACT_V2}>
              <div className="flex items-center px-5">
                {false && <SVGs.ExclamationCircleUnfilled className="mr-2"/>}
                {i18n.t('contract_navbar.signers')}
              </div>
            </MenuItem>
          )}
          {/* <VisibleIf predicate={addendumFeatureFlag && isActive}> */}
          <VisibleIf predicate={isActive && [ContractTemplate.SHOPPING_MALL, ContractTemplate.PAPER].includes(contract.template)}>
            <MenuItem option={ContractPageSubview.ADDENDUMS} route={RouteName.CONTRACT_V2}>
              <div className="flex items-center px-5">
                {false && <SVGs.ExclamationCircleUnfilled className="mr-2"/>}
                {i18n.t('general.addendums')}
              </div>
            </MenuItem>
          </VisibleIf>
          {contractType !== ContractType.PAPER && (
            <MenuItem option={ContractPageSubview.SUMMARY} route={RouteName.CONTRACT_V2}>
              <div className="flex items-center px-5">
                {i18n.t('contract_navbar.summary')}
              </div>
            </MenuItem>
          )}
        </>
      ) : (
        // Addendum Menu
        <>
          <MenuItem option={AddendumPageSubview.ADDENDUM} route={RouteName.ADDENDUM}>
            <div className="flex items-center px-5">
              {hasChanges && <SVGs.ExclamationCircleUnfilled className="mr-2"/>}
              {i18n.t('general.addendum')}
            </div>
          </MenuItem>

          <MenuItem option={AddendumPageSubview.ATTACHMENTS} route={RouteName.ADDENDUM}>
            <div className="flex items-center px-5">
              {i18n.t('contract_navbar.attachments')}
              {numberOfAppendices > 0 && ` (${numberOfAppendices})`}
            </div>
          </MenuItem>

          <MenuItem option={AddendumPageSubview.PARTIES} route={RouteName.ADDENDUM}>
            <div className="flex items-center px-5">
              {false && <SVGs.ExclamationCircleUnfilled className="mr-2"/>}
              {i18n.t('contract_navbar.signers')}
            </div>
          </MenuItem>
        </>
      )}
    </div>
  )
}

const MenuItem: view = ({
  children,
  option,
  route,
  selectedSubView = observe.routes.list[prop.route].data.subview,
  setSubview = update.routes.list[prop.route].data.subview
}) => {
  const isSelected = option === selectedSubView
  return (
    <div
      className={`cursor-pointer text-base relative ${isSelected ? 'selected-view' : ''}`}
      onClick={() => setSubview.set(option)}>
      <div>{children}</div>
    </div>
  )
}
