import React from "react";

import { Menu, Dropdown } from "antd";
import { TextField } from "@c11/components";
import { serchShoppingMall } from "./ShoppingMallsDropdown.search";
import i18n from "src/producers/languages/i18n";

export const SearchDropdown: view = ({
  id,
  _viewId,
  menuItem,
  items = observe[prop.itemsPath],
  searchResults = observe.views[prop._viewId].searchResults,
  resetField = observe.views[prop._viewId].resetField,
  triggerSearch = update.views[prop._viewId].triggerSearch,
  setResetField = update.views[prop._viewId].resetField,
  setSearchResults = update.views[prop._viewId].searchResults,
}) => {
  if (!items) return null;
  const itemsList =
    searchResults?.length > 0 ? (
      <Menu>
        {searchResults.map((item, index) => (
          <Menu.Item key={index} onClick={() => setResetField.set(Date.now())}>
            <div
              onClick={() => {
                setResetField.set(Date.now());
                setSearchResults.set("");
              }}>
              {menuItem(item)}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <></>
    );

  return (
    <Dropdown
      overlay={itemsList}
      trigger={["click"]}
      overlayClassName={"shoppingMallsDropdown"}
      getPopupContainer={() => document.getElementById(id) as HTMLElement}
      className="w-full"
      onVisibleChange={(isOpen: boolean) => {
        if (!isOpen) {
          setResetField.set(Date.now());
          setSearchResults.set("");
        }
      }}>
      <div className="flex">
        <TextField
          key={resetField}
          className="w-full"
          isRequired
          placeholder={`${i18n.t("contract_page.contract_premise_clause_search_p")}`}
          value={""}
          onChange={(ev) => {
            triggerSearch.set(ev.target.value);
          }}
        />
      </div>
    </Dropdown>
  );
};

SearchDropdown.producers(serchShoppingMall);
