import {
  ClauseCard
} from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import {
  FactoryFormField
} from "src/pages/Contract/subviews/ContractForm/fields/FactoryFormField";
import { RadioButton, RadioButtonGroup } from "@c11/components";

import styles from "../../../styles.module.css";

export const VatClause: view = ({
  _viewId,
  title,
  getVatAlternative = observe.contract.values.vatAlternative.displayedValue,
  selected = observe.views[prop._viewId].selected,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  userRole = get.user.data.role,
  setVariable = update.contract.toSave.changes.vatAlternative,
  setSelected = update.views[prop._viewId].selected,
}) => {
  const i = selected || getVatAlternative;
  const isTenant = userRole.value() === UserRole.TENANT;

  function handleClick(c: any) {
    setVariable.set({ value: c + 1, createdAt: Date.now() });
    setSelected.set(c + 1);
  }

  return (
    <ClauseCard title={title} required={isTenant} editableByTenant>
      <div className={`${styles.contractCard}`}>
        <RadioButtonGroup selectedValue={String(i)}>
          <RadioButton
            id="rb-1"
            key={`1`}
            className="mb-5"
            value={"1"}
            isDisabled={isReadOnlyContract}
            onClick={() => handleClick(0)}>
            {i18n.t("clause_vat_alternative_1_text")}
          </RadioButton>
          <br/>
          <RadioButton
            id="rb-2"
            key={`2`}
            className="mb-5"
            value={"2"}
            isDisabled={isReadOnlyContract}
            onClick={() => handleClick(1)}>
            {i18n.t("clause_vat_alternative_2_text")}
          </RadioButton>
          <br/>
          <RadioButton
            id="rb-3"
            key={`3`}
            className="mb-5"
            value={"3"}
            isDisabled={isReadOnlyContract}
            onClick={() => handleClick(2)}>
            <div className={`${styles.vatPercent}`}>
              <FactoryFormField key="1" disabled={i !== 2}
                                fieldName="vatPercent" varName="vatPercent"/>
            </div>
            <span key="2">{` ${i18n.t("clause_vat_alternative_3_text")}`}</span>
          </RadioButton>
        </RadioButtonGroup>
      </div>
    </ClauseCard>
  );
};
