import { RadioButton, RadioButtonGroup } from "@c11/components";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";

export const ParkingType: view = ({
  userRole = observe.user.data.role,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  getParkingType = observe.contract.values.parkingType.displayedValue,
  getParkingTypeFromChanges = observe.contract.currentSession.changes.parkingType.value,
  setParkingType = update.contract.toSave.changes.parkingType,
}) => {
  const isTenant = userRole === UserRole.TENANT;

  const parkingType = getParkingTypeFromChanges || getParkingType;

  function handleClick(type: any) {
    console.log('type', type)
    setParkingType.set({ value: type, createdAt: Date.now() });
  }

  return (
    <RadioButtonGroup selectedValue={String(parkingType)}>
      <RadioButton
        key={`11`}
        className="mr-4"
        value={"indoor"}
        isDisabled={isReadOnlyContract || isTenant}
        onClick={() => handleClick('indoor')}>
        {i18n.t("parking_contract.rental_object_parkingType_a_indoor")}
      </RadioButton>
      <RadioButton key={`12`} value={"outdoor"}
                   isDisabled={isReadOnlyContract || isTenant}
                   onClick={() => handleClick('outdoor')}>
        {i18n.t("parking_contract.rental_object_parkingType_a_outdoor")}
      </RadioButton>
    </RadioButtonGroup>
  );
};
