import i18n from "src/producers/languages/i18n";
import { HeaderSection } from "src/pages/Contract/HeaderSection/HeaderSection";
import { ContractCard } from "src/pages/Contract/subviews/Summary/ContractCard/ContractCard";
import { AttachmentsCard } from "src/pages/Contract/subviews/Summary/AttachmentsCard/AttachmentsCard";
import { PartiesCard } from "src/pages/Contract/subviews/Summary/PartiesCard/PartiesCard";
import { Progress } from "src/pages/Contract/subviews/Summary/Progress/Progress";

import styles from "../../styles.module.css";
import { SigningSummaryCard } from "./SigningSummaryCard/SigningSummaryCard";
import { ContractStatus } from "src/types";

export const Summary: view = ({
  signingSummaryCard = observe.featureFlags.signingSummaryCard.main,
  status = observe.contract.original.state.status,
}) => {
  const isSigning = status === ContractStatus.SIGNING;
  const isActive = status === ContractStatus.ACTIVE;
  const showSigningSummaryCard = signingSummaryCard && (isSigning || isActive);
  return (
    <div className="overflow-auto pb-20 h-screen">
      <HeaderSection title={i18n.t("summary_page.contract_timeline")}>
        <Progress />
      </HeaderSection>
      <div className={`${styles.summarySection}`}>
        <div>
          <div className={`${styles.summaryCardsRow}`}>{showSigningSummaryCard && <SigningSummaryCard />}</div>
          <div className={`${styles.summaryCardsRow}`}>
            <ContractCard />
            <AttachmentsCard />
            <PartiesCard />
          </div>
        </div>
      </div>
      {/* <Notifications /> */}
    </div>
  );
};
