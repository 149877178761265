// pentru salvat attachments in storage

import { storageRef, functions } from "src/producers/firebase"
import { ApplicationError, ErrorLevel, ErrorType } from "src/types"
import { CommandActions } from "src/types/commands/command"

const RandExp = require("randexp")

const randomId = new RandExp(/[A-Za-z0-9_]{9}/)

export const addAttachment: producer = async ({
  appendixToSave = observe.contract.triggers.saveAttachment,
  contractId = get.contract.id,
  getAttachment = get.contract.original.state.attachments,
  attachmentsViewModel = get.contract.attachmentsViewModel,
  updateTempAttachments = update.contract.tempAttachments[param.id],
  removeTrigger = update.contract.triggers.saveAttachment,
  error = update.errors[param.id],
}) => {
  contractId = contractId.value()

  removeTrigger.remove()
  if (!contractId || !appendixToSave?.data?.name) {
    return
  }
  const fileId = randomId.gen()
  const reset = () => {
    updateTempAttachments.remove({ id: fileId })
  }
  try {
    const waitForAttachment = (id, cb) => {
      const attachments = getAttachment.value()
      const existingAttachments = attachments && Object.values(attachments)?.find((x: any) => x.fileId === fileId)
      if (!existingAttachments) {
        setTimeout(() => waitForAttachment(id, cb), 1000)
        return
      }
      cb()
    }

    let highestOrder: any = attachmentsViewModel.value()?.reduce((acc, curr: any) => {
      return curr.order ? (curr.order > acc ? curr.order : acc) : acc
    }, -1)

    //TODO move storage logic server side
    const data = {
      fileName: appendixToSave.data.name,
      fileType: appendixToSave.data.type,
      fileId,
      path: `${contractId}/${fileId}`,
      selected: false,
      domain: "user",
      order: highestOrder ? highestOrder + 1 : 0,
    }
    updateTempAttachments.set({ ...data, tempLinkId: fileId }, { id: fileId })
    const appendixRef = storageRef.child(`${contractId}/${fileId}`)
    await appendixRef.put(appendixToSave.data, { contentType: appendixToSave.data.type })
    const commandPublisher = functions.httpsCallable("eventlessCommandHandler")

    const command = {
      action: CommandActions.ADD_ATTACHMENT,
      contractId,
      ...data,
    }
    console.log("add attachment comm", command)
    await commandPublisher(command)
    console.log("Document successfully written!")
    waitForAttachment(data.fileId, reset)
  } catch (e) {
    reset()
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "addAttachment-contract" }
    )
  }
}
