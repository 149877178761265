import { UserRole } from "src/types";
import { db } from "src/producers/firebase";

export const clearSessions: producer = ({
  contractId = observe.legacyContract.id,
  trigger = observe.legacyContract.triggers.clearSessions,
  removeTrigger = update.legacyContract.triggers.clearSessions,
}) => {
  if (!contractId || !trigger) {
    return;
  }
  const batch = db.batch();
  const sessionsDb = db.collection("contracts").doc(contractId).collection("sessions");
  batch.delete(sessionsDb.doc(UserRole.TENANT));
  batch.delete(sessionsDb.doc(UserRole.MANAGER));
  batch.commit().then(() => {
    removeTrigger.remove();
    console.log("sessions cleared");
  });
};
