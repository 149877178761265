import { functions } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";
import { b64toBlob } from "src/utils/b64ToBlob";

export const downloadContract: producer = async ({
  trigger = observe.contract.triggers.downloadSignedContract,
  removeTrigger = update.contract.triggers.downloadSignedContract,
  loadingContractDownload = update.contract.flags.loadingContractDownload,
  contractId = get.contract.id,
  error = update.errors[param.id],
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  loadingContractDownload.set(performance.now());
  try {
    const downloadSignedContract = functions.httpsCallable("downloadSignedContract");
    const res = await downloadSignedContract({ contractId: contractId.value() });
    if (res.data) { 
      const pdfBlob = b64toBlob(res.data.data, "application/pdf");
      saveAs(pdfBlob, `${res.data.name}`);
    }
    loadingContractDownload.remove();
  } catch (e) {
    loadingContractDownload.remove();
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "downloadContract-contract" }
    );
  }
};
