import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const dismissWelcomeCard: producer = ({
  trigger = observe.contract.triggers.dismissWelcomeCard,
  removeTrigger = update.contract.triggers.dismissWelcomeCard,
  userUid = get.user.uid,
  error = update.errors[param.id],
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  try {
    db.collection("users").doc(userUid.value()).update({ onboarded: true });
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "dismissWelcomeCard-contract" }
    );
  }
};
