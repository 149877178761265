import React, { CSSProperties } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { saveAs } from "file-saver";
import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";
import { RouteName } from "src/types";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //TODO find another way

export const Appendix: view = ({
  mountedAppendix = observe.routes.list[RouteName.LEGACY_CONTRACT].data.appendix,
  numberOfPages = observe.routes.list[RouteName.LEGACY_CONTRACT].data.appendix.numPages,
  setNumberOfPages = update.routes.list[RouteName.LEGACY_CONTRACT].data.appendix.numPages,
  pageNumber = observe.routes.list[RouteName.LEGACY_CONTRACT].data.appendix.pageNumber,
  setPageNumber = update.routes.list[RouteName.LEGACY_CONTRACT].data.appendix.pageNumber,
}) => {
  const { URL, fileType, fileName } = mountedAppendix;
  if (!URL) return null;

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumberOfPages.set(numPages);
    setPageNumber.set(1);
  }

  function changePage(offset: number) {
    const newPageNumber = pageNumber + offset;
    if (newPageNumber > 0 && newPageNumber <= numberOfPages) setPageNumber.set(pageNumber + offset);
  }

  function handleDownload() {
    console.log(123, fileType, URL);

    fetch(URL)
      .then((response) => response.blob())
      .then((blob: any) => {
        saveAs(blob, fileName);
      });
  }

  return (
    <div className="flex flex-col items-center">
      <div className="mb-2">
        <Button variant="primary" onClick={handleDownload}>
          {i18n.t("legacy.download_appendix")}
        </Button>
      </div>
      {fileType === "application/pdf" ? (
        <PdfViewer
          URL={URL}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          pageNumber={pageNumber}
          numberOfPages={numberOfPages}
          changePage={changePage}
        />
      ) : (
        <img src={URL} width={400} alt="appendix"></img>
      )}
    </div>
  );
};

const controlsStyles: CSSProperties = {
  top: "-50px",
  width: "90px",
};

const PdfViewer: view = ({ URL, onDocumentLoadSuccess, pageNumber, numberOfPages, changePage }) => {
  return (
    <>
      <Document file={URL} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber || 1} width={400} />
      </Document>
      {pageNumber ? (
        <div style={controlsStyles} className="relative shadow-lg rounded-lg flex justify-between">
          <button className="px-2" type="button" onClick={() => changePage(-1)}>
            ‹
          </button>
          <span>{`${pageNumber} ${i18n.t("legacy.appendix_page_of")} ${numberOfPages}`}</span>
          <button className="px-2" type="button" onClick={() => changePage(1)}>
            ›
          </button>
        </div>
      ) : null}
    </>
  );
};
