import { db } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'

export const syncFeatureFlags: producer = async ({
  user = observe.user,
  setFeatureFlags = update.featureFlags[param.id],
  featureFlagsReady = update.featureFlags.isReady,
  error = update.errors[param.id],
}) => {
  if (!user?.isAuth || !user?.isReady) return
  try {
    const featureFlagsSnap = await db.collection('featureFlags').get()
    for (const doc of featureFlagsSnap.docs) {
      const feature = doc.data()
      setFeatureFlags.set(feature, { id: doc.id })
    }
    featureFlagsReady.set(true)
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'syncFeatureFlags' }
    )
  }
}
