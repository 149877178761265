import { ErrorLevel, ApplicationError, ErrorType } from "src/types";

export const sendSigningNotification: producer = async ({
  trigger = observe.contract.triggers.sendSigningNotification,
  removeTrigger = update.contract.triggers.sendSigningNotification,

  contractId = get.contract.id,
  envelopeInfo = get.contract.original.state.envelopeInfo,
  error = update.errors[param.id],
  updateContracts = update.contract.triggers.updateContracts,
  recipientstNotificationSent = update.contract.recipientstNotificationSent,
}) => {
  if (!trigger) return;
  contractId = contractId.value();
  envelopeInfo = envelopeInfo.value();
  removeTrigger.remove();

  try {
    updateContracts.set({
      action: "sendSigningReminder",
      contractId,
      envelopeUid: envelopeInfo.envelopeUid,
      recipients: trigger.recipientId,
      greeting: trigger.message,
    });
    recipientstNotificationSent.set(trigger.recipientId);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "moveToSigning-contract" }
    );
  }
};
