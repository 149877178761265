import { ApplicationError, ErrorLevel, ErrorType } from "src/types";
import { db } from "src/producers/firebase";

export const syncActivity: producer = async ({
  contractId = observe.contract.id,
  setActivityLog = update.contract.activity,
  error = update.errors[param.id],
}) => {
  if (!contractId) {
    return;
  }

  try {
    const contractDb = db.collection("contracts").doc(contractId);
    contractDb.collection("activity").onSnapshot((snapshot: any) => {
      let activityLog: any = [];
      snapshot.forEach((doc: any) => {
        const data = doc.data();
        if (!data) return;
        activityLog.push(data);
      });
      activityLog.sort((a: any, b: any): any =>
        new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? -1 : 1
      );

      setActivityLog.set(activityLog);
    });
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncActivity" }
    );
  }
};
