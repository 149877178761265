import React from "react";

import styles from "./styles.module.css";

export const Card: view = ({ title, children, extraClasses }) => (
  <div className={`${extraClasses} ${styles.card}`}>
    <div>
      <h2>
        <span>{title}</span>
      </h2>
    </div>
    {children}
  </div>
);
