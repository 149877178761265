import { db } from "src/producers/firebase"
import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from "src/types"

export const persistBuildingAttachment: producer = async ({
  user = get.user,
  isLocked = get.contract.isLocked,
  contractId = get.contract.id,
  attachments = get.contract.originalv2.attachments,
  updateContractAttachments = update.contract.originalv2.attachments,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  buildingAttachments = observe.contract.changes.attachments.building,
  updateAttachmentsChanges = update.contract.changes.attachments,
}) => {
  if (!buildingAttachments) return
  if (Object.keys(buildingAttachments).length === 0) return

  isLocked = isLocked.value()
  if (isLocked) return

  user = user.value()
  contractId = contractId.value()
  attachments = attachments.value()

  if (!user) return
  if (!contractId) return

  try {
    isSaving.set(true)

    await db.collection("contracts").doc(contractId).set(
      {
        attachments: buildingAttachments,
        updatedAt: new Date().toISOString(),
      },
      { merge: true }
    )

    updateContractAttachments.merge(buildingAttachments)
    successfulSave.set(true)
  } catch (e) {
    console.error("persist-building-attachment", e)
    failedSave.set(true)
    error.set(
      {
        message: "saving-attachments-to-database",
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: "persist-building-attachment" }
    )
  } finally {
    updateAttachmentsChanges.remove()

    isSaving.set(false)
  }
}
