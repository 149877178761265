import moment from 'moment'
import { Button } from '@c11/components.button'

import _numeral from 'src/utils/_numeral'
import i18n from 'src/producers/languages/i18n'
import { SVGs } from 'src/assets'
import { ContractPageSubview, ContractStatus, ContractType, RouteName } from 'src/types'
import { ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from 'src/components'

import styles from '../../../styles.module.css'
import { ContractTemplate } from 'src/pages/ContractV2/contract.types'
import { VisibleIf } from 'src/components_generic/visible-if'

export const ContractCard: view = ({
  setSubview = update.routes.list[RouteName.CONTRACT_V2].data.subview,
  fields = observe.contract.originalv2.fields,
  status = observe.contract.originalv2.status,
  template = get.contract.originalv2.template
}) => {
  //TODO maybe change the hidden logic to something best practice
  template = template.value()
  const isSigning = status === ContractStatus.SIGNING
  const isActive = status === ContractStatus.ACTIVE
  const isSigningOrActive = isSigning || isActive
  return (
    <>
      <ThumbnailCard className={`${styles.contractSummaryCard} ${isSigningOrActive && styles.borderedCard}`}>
        <ThumbnailCardHeader
          className={`${styles.summaryCardsHeader}`}
          key="tch-1"
          title={i18n.t('summary_page.contract_card_title')}
          separator={true}>
          <div className="float-right inline-block">
            <SVGs.OverviewContract/>
          </div>
        </ThumbnailCardHeader>
        <ThumbnailCardBody key="tcb-1">
          <div className={`${styles.summaryList}`}>
            {/*<VisibleIf predicate={!fields?.tenantName?.value}>*/}
            {/*  <span className={`${styles.tenant}`}>No tenant specified</span>*/}
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!!fields?.tenantName?.value}>*/}
            <span>{`${i18n.t('general.tenant')}:`}</span>
            <span className={`${styles.tenant}`}>{fields?.tenantName?.value || '-'}</span>
            {/*</VisibleIf>*/}
          </div>
          <div className={`${styles.summaryList}`}>
            {/*<VisibleIf predicate={!fields?.shoppingMall?.value}>*/}
            {/*  <span className={`${styles.tenant}`}>No shopping mall specified</span>*/}
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!!fields?.shoppingMall?.value}>*/}
            <span>{`${i18n.t('building')}:`}</span>
            <span>{fields?.shoppingMall?.value || '-'}</span>
            {/*</VisibleIf>*/}
          </div>
          <div className={`${styles.summaryList}`} hidden={template === ContractTemplate.PARKING}>
            {/*<VisibleIf predicate={!fields?.propertyLocalNo?.value}>*/}
            {/*  <span>No property local number specified</span>*/}
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!!fields?.propertyLocalNo?.value}>*/}
            <span>{`${i18n.t('clause_rental_property_property_local_no')}:`}</span>
            <span>{fields?.propertyLocalNo?.value || '-'}</span>
            {/*</VisibleIf>*/}
          </div>
          <div className={`${styles.summaryList} flex-row justify-center`}>
            {/*<VisibleIf predicate={!fields?.rentalStart?.value && !fields?.rentalEnd?.value}>*/}
            {/*  <span>No date specified</span>*/}
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!!fields?.rentalStart?.value && !!fields?.rentalEnd?.value}>*/}
            <span>{`${i18n.t('summary_page.date')}:`}</span>
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!!fields?.rentalStart?.value && !fields?.rentalEnd?.value}>*/}
            {/*  <span>Start date:</span>*/}
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!fields?.rentalStart?.value && !!fields?.rentalEnd?.value}>*/}
            {/*  <span>End date:</span>*/}
            {/*</VisibleIf>*/}
            <div className="flex flex-row gap-2">
              <VisibleIf predicate={!fields?.rentalStart?.value && !fields?.rentalEnd?.value}>
                <span> - </span>
              </VisibleIf>
              <VisibleIf predicate={!!fields?.rentalStart?.value}>
                <span>{moment(fields?.rentalStart?.value).utc().format('DD.MM.YYYY')}</span>
              </VisibleIf>
              <VisibleIf predicate={!!fields?.rentalStart?.value && !!fields?.rentalEnd?.value}>
                <span> - </span>
              </VisibleIf>
              <VisibleIf predicate={!!fields?.rentalEnd?.value}>
                <span>{moment(fields?.rentalEnd?.value).utc().format('DD.MM.YYYY')}</span>
              </VisibleIf>
            </div>
          </div>
          <div className={`${styles.summaryList}`} hidden={template === ContractTemplate.PARKING}>
            {/*<VisibleIf predicate={!fields?.minimumRent?.value}>*/}
            {/*  <span>No minimum rent specified</span>*/}
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!!fields?.minimumRent?.value}>*/}
            <span>{`${i18n.t('minimumRent')}:`}</span>
            <span>
                {fields?.minimumRent?.value
                  ? _numeral(Number(fields?.minimumRent?.value)).format('0,0.[00]')
                  : '-'}
              </span>
            {/*</VisibleIf>*/}
          </div>
          <div className={`${styles.summaryList}`} hidden={template === ContractTemplate.PARKING}>
            {/*<VisibleIf predicate={!fields?.marketingCosts?.value}>*/}
            {/*  <span>No marketing costs specified</span>*/}
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!!fields?.marketingCosts?.value}>*/}
            <span>{`${i18n.t('marketingCosts')}:`}</span>
            <span>
                {fields?.marketingCosts?.value
                  ? _numeral(Number(fields?.marketingCosts?.value)).format('0,0.[00]')
                  : '-'}
              </span>
            {/*</VisibleIf>*/}
          </div>
          <div className={`${styles.summaryList}`}>
            {/*<VisibleIf predicate={!fields?.sharedCosts?.value}>*/}
            {/*  <span>No common costs specified</span>*/}
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!!fields?.sharedCosts?.value}>*/}
            <span>{`${i18n.t('sharedCosts')}:`}</span>
            <span>
                {fields?.sharedCosts?.value
                  ? _numeral(Number(fields?.sharedCosts?.value)).format('0,0.[00]')
                  : '-'}
              </span>
            {/*</VisibleIf>*/}
          </div>
          <div className={`${styles.summaryList}`} hidden={template === ContractTemplate.PARKING}>
            {/*<VisibleIf predicate={!fields?.securityAmount?.value}>*/}
            {/*  <span>No secutity amount specified</span>*/}
            {/*</VisibleIf>*/}
            {/*<VisibleIf predicate={!!fields?.securityAmount?.value}>*/}
            <span>{`${i18n.t('securityAmount')}:`}</span>
            <span>
                {fields?.securityAmount?.value
                  ? _numeral(Number(fields?.securityAmount?.value)).format('0,0.[00]')
                  : '-'}
              </span>
            {/*</VisibleIf>*/}
          </div>
        </ThumbnailCardBody>
        <ThumbnailCardFooter key="tcf-1">
          <Button
            className="border-secondary second-theme-color"
            variant="secondary"
            onClick={() => {
              setSubview.set(ContractPageSubview.CONTRACT)
            }}>
            <span className="uppercase">{i18n.t('summary_page.view_contract')}</span>
          </Button>
        </ThumbnailCardFooter>
      </ThumbnailCard>
    </>
  )
}
