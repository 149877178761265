import { Images } from "src/assets";
import { UserRole } from "src/types";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import i18n from "src/producers/languages/i18n";
import { ShoppingMallsDropdown } from "src/pages/Contract/subviews/ContractForm/RentalPropertyClause/ShoppingMallsDropdown";

export const HeadingClause: view = ({
  clause,
  userRole = get.user.data.role,
  shoppingMalls = observe.domain.shoppingMalls,
  shoppingMallId = observe.contract.original.state.building.id,
}) => {
  if (!clause || !shoppingMalls) return null;
  const isTenant = userRole.value() === UserRole.TENANT;
  const selectedMall = shoppingMalls[shoppingMallId];

  return (
    <ClauseCard title={i18n.t(clause.title)}>
      {isTenant ? (
        <div className="mb-10">
          {i18n.t("contract_page.contract_premise_clause_p")}
          <div className="flex">
            <img
              src={selectedMall?.buildingImageUrl || Images.logo}
              alt="shopping mall image"
              style={{ objectFit: "contain", width: 70, height: 40, marginRight: 15 }}
            />
            <div>
              <p key="1" className=" text-left font-medium">
                {selectedMall?.name && <span>{selectedMall.name} </span>}
              </p>
              <p key="2" className="font-12 text-left text-gray-600">
                {selectedMall?.address && <span>{selectedMall.address} </span>}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-10">
          <span key="text" className="mr-4">
            {i18n.t("contract_page.contract_premise_clause_p")}
          </span>
          <ShoppingMallsDropdown key="00" />
        </div>
      )}
    </ClauseCard>
  );
};
