import { db } from "src/producers/firebase";

export const syncDefaults: producer = async ({
  id = observe.legacyContract.id,
  createdAt = observe.legacyContract.original.createdAt,

  defaultValues = get.legacyContract.original.defaultValues,

  setDefaults = update.legacyContract.original.defaultValues[param.varName],
  setEmptyDefaults = update.legacyContract.original.defaultValues,
  setListener = update.listenersOld[arg.id].defaults,
}) => {
  if (!id || !createdAt) {
    return;
  }
  try {
    const listener = await db.collection("defaults").onSnapshot((snapshot) => {
      snapshot.forEach((doc) => {
        let data = doc.data();
        if (!data) {
          return;
        }
        data.createdAt = createdAt;
        setDefaults.merge(data, { varName: doc.id });
      });
      const values = defaultValues.value();
      if (values == undefined || !values) {
        setEmptyDefaults.set({});
      }
    });
    setListener.set(listener);
  } catch (e) {
    console.error("syncDefaults", e);
  }
};
