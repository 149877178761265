import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
import { functions } from 'src/producers/firebase'
import { ContractStatus } from 'src/pages/ContractV2/contract.types'

export const stopSigning: producer = async ({
  trigger = observe.contract.triggers.stopSigning,
  contractId = get.contract.id,
  envelopeInfo = get.contract.originalv2.envelopeInfo,

  updateEnvelopeInfo = update.contract.originalv2.envelopeInfo,
  updateEnvelope = update.contract.originalv2.envelope,
  updateStatus = update.contract.originalv2.status,

  updateLoading = update.contract.loading.stopSigning,
  error = update.errors[param.id],
  removeTrigger = update.contract.triggers.stopSigning
}) => {

  if (!trigger) return
  contractId = contractId.value()
  envelopeInfo = envelopeInfo.value()
  const envelopeUid = envelopeInfo?.envelopeUid

  try {
    const stopSigningProcess = functions.httpsCallable('stopSigning')
    const payload = { contractId, envelopeUid }
    console.log('stopSigning producer: ', payload)
    updateLoading.set(true)
    const resp = await stopSigningProcess(payload)
    updateEnvelope.remove()
    updateEnvelopeInfo.remove()
    updateStatus.set(ContractStatus.DRAFT)
  } catch (e) {
    console.error(e)
    error.set(
      {
        message: e.message || 'internal-server-error',
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'stopSigning-contract' }
    )
  } finally {
    removeTrigger.remove()
  }
  updateLoading.set(false)
}
