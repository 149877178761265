import React from "react";

import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { ShoppingMallsDropdownRow } from "./ShoppingMallsDropdownRow";
import { Menu, Dropdown } from "antd";

export const ShoppingMallsDropdown: view = ({
  shoppingMalls = observe.legacyContract.shoppingMalls,
  shoppingMallName = observe.legacyContract.values.shoppingMall.displayedValue,

  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  if (!shoppingMalls || isTenant) return null;
  const shoppingMallList = (
    <Menu>
      {shoppingMalls.map((SM: any, index: any) => (
        <Menu.Item key={index}>
          <ShoppingMallsDropdownRow SM={SM} index={index} />
        </Menu.Item>
      ))}
    </Menu>
  );

  const selectedSM = shoppingMalls.filter((sm: any) => sm.name == shoppingMallName)[0];
  return (
    <div className="my-2 relative" id="shoppingMallsDropdown">
      <Dropdown
        overlay={shoppingMallList}
        trigger={["click"]}
        overlayClassName={"shoppingMallsDropdown"}
        getPopupContainer={() => document.getElementById("shoppingMallsDropdown") as HTMLElement}>
        <button className="bg-white kpy-10 kpx-14 flex w-full justify-between items-center border klp-border1 rounded-md">
          {selectedSM ? (
            <ShoppingMallsDropdownRow SM={selectedSM} />
          ) : (
            <span key="1">{i18n.t("clause_rental_property_property_shopping_mall_choose")}</span>
          )}
          {<SVGs.DropdownArrow />}
        </button>
      </Dropdown>
    </div>
  );
};
