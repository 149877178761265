import {ApplicationError, ErrorLevel, ErrorType, LogLevels, RouteName} from "src/types";
import {auth, klpProvider, firebase} from "../firebase";

export const signInWithKLP: producer = ({
  trigger = observe.session.triggers.signInWithKLP,
  error = update.errors[param.id],
  redirectToPath = get.routes.list[RouteName.LOGIN].redirectToPath,
  redirectToSearch = get.routes.list[RouteName.LOGIN].redirectToSearch,
}) => {
  if (!trigger) return;
  window.sessionStorage.setItem("redirectToPath", redirectToPath.value());
  window.sessionStorage.setItem("redirectToSearch", redirectToSearch.value());
  console.log("Redirecting to KLP")

  // klpProvider.setCustomParameters({
  //   scope: redirectTo.value()
  // });
  auth
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      // auth.signInWithRedirect(klpProvider)
      auth.signInWithPopup(klpProvider)
    })
    .catch((e: any) => {
      console.error("Error signing in with KLP", e);
      error.set(
        {
          message: e,
          level: LogLevels.ERROR,
        } as ApplicationError,
        {id: "signInWithKLP"}
      );
    });
};
