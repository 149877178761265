'use strict'
// module.exports = validate20
// module.exports.default = validate20
export default validate20
const schema22 = {
  '$id': 'STORAGE',
  'type': 'object',
  'definitions': {
    'email': { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' },
    'phone': { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
  },
  'properties': {
    'tenantContactPersonFirstName': { 'type': 'string' },
    'tenantContactPersonLastName': { 'type': 'string' },
    'tenantPhone': { '$ref': '#/definitions/phone' },
    'tenantEmail': { '$ref': '#/definitions/email' },
    'title': { 'type': 'string' },
    'backgroundAndPurpose': { 'type': 'string' },
    'otherProvisions': { 'type': 'string' },
    'shoppingMall': { 'type': 'string' },
    'propertyAddress': { 'type': 'string' },
    'municipality': { 'type': 'string' },
    'parcelNumber': { 'type': 'string' },
    'propertyLocalNo': { 'type': 'string' },
    'propertyLevel': { 'type': 'string' },
    'propertyExclusiveArea': { 'type': 'number' },
    'propertyCommonArea': { 'type': 'number' },
    'propertyTotalArea': { 'type': 'number' },
    'conditionAndUseStorage': { 'type': 'string' },
    'rentalStart': { 'type': 'string' },
    'rentalEnd': { 'type': 'string' },
    'rentalPeriodHasOptions': { 'type': 'boolean' },
    'rentalPeriodExtensionType': { 'type': 'string' },
    'rentalPeriodExtensionYears': { 'type': 'number' },
    'storageRent': { 'type': 'number' },
    'sharedCosts': { 'type': 'number' },
    'indexAdjustmentBasicIndex': { 'type': 'string' },
    'firstTimeRegulation': { 'type': 'string' },
    'paymentProvisionType': { 'type': 'string' },
    'businessVATStatus': { 'type': 'string' },
    'businessVATPercentage': { 'type': 'number' },
    'compulsoryDerogation': { 'type': 'string' }
  },
  'required': ['tenantEmail', 'title', 'backgroundAndPurpose', 'shoppingMall', 'propertyAddress', 'municipality', 'parcelNumber', 'propertyLocalNo', 'propertyLevel', 'propertyExclusiveArea', 'propertyCommonArea', 'propertyTotalArea', 'conditionAndUseStorage', 'rentalStart', 'rentalEnd', 'storageRent', 'sharedCosts', 'indexAdjustmentBasicIndex', 'firstTimeRegulation', 'paymentProvisionType', 'businessVATStatus']
}
const schema23 = { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
const schema24 = { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' }
const pattern0 = new RegExp('^[+]?[\\d\\s()-]{7,}$', 'u')
const pattern1 = new RegExp('^\\S+@\\S+\\.\\S+$', 'u')

function validate20 (data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {/*# sourceURL="STORAGE" */
  let vErrors = null
  let errors = 0
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.tenantEmail === undefined) {
      const err0 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenantEmail' },
        message: 'must have required property \'' + 'tenantEmail' + '\''
      }
      if (vErrors === null) {vErrors = [err0]} else {vErrors.push(err0)}
      errors++
    }
    if (data.title === undefined) {
      const err1 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'title' },
        message: 'must have required property \'' + 'title' + '\''
      }
      if (vErrors === null) {vErrors = [err1]} else {vErrors.push(err1)}
      errors++
    }
    if (data.backgroundAndPurpose === undefined) {
      const err2 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'backgroundAndPurpose' },
        message: 'must have required property \'' + 'backgroundAndPurpose' + '\''
      }
      if (vErrors === null) {vErrors = [err2]} else {vErrors.push(err2)}
      errors++
    }
    if (data.shoppingMall === undefined) {
      const err3 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'shoppingMall' },
        message: 'must have required property \'' + 'shoppingMall' + '\''
      }
      if (vErrors === null) {vErrors = [err3]} else {vErrors.push(err3)}
      errors++
    }
    if (data.propertyAddress === undefined) {
      const err4 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyAddress' },
        message: 'must have required property \'' + 'propertyAddress' + '\''
      }
      if (vErrors === null) {vErrors = [err4]} else {vErrors.push(err4)}
      errors++
    }
    if (data.municipality === undefined) {
      const err5 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'municipality' },
        message: 'must have required property \'' + 'municipality' + '\''
      }
      if (vErrors === null) {vErrors = [err5]} else {vErrors.push(err5)}
      errors++
    }
    if (data.parcelNumber === undefined) {
      const err6 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'parcelNumber' },
        message: 'must have required property \'' + 'parcelNumber' + '\''
      }
      if (vErrors === null) {vErrors = [err6]} else {vErrors.push(err6)}
      errors++
    }
    if (data.propertyLocalNo === undefined) {
      const err7 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyLocalNo' },
        message: 'must have required property \'' + 'propertyLocalNo' + '\''
      }
      if (vErrors === null) {vErrors = [err7]} else {vErrors.push(err7)}
      errors++
    }
    if (data.propertyLevel === undefined) {
      const err8 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyLevel' },
        message: 'must have required property \'' + 'propertyLevel' + '\''
      }
      if (vErrors === null) {vErrors = [err8]} else {vErrors.push(err8)}
      errors++
    }
    if (data.propertyExclusiveArea === undefined) {
      const err9 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyExclusiveArea' },
        message: 'must have required property \'' + 'propertyExclusiveArea' + '\''
      }
      if (vErrors === null) {vErrors = [err9]} else {vErrors.push(err9)}
      errors++
    }
    if (data.propertyCommonArea === undefined) {
      const err10 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyCommonArea' },
        message: 'must have required property \'' + 'propertyCommonArea' + '\''
      }
      if (vErrors === null) {vErrors = [err10]} else {vErrors.push(err10)}
      errors++
    }
    if (data.propertyTotalArea === undefined) {
      const err11 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyTotalArea' },
        message: 'must have required property \'' + 'propertyTotalArea' + '\''
      }
      if (vErrors === null) {vErrors = [err11]} else {vErrors.push(err11)}
      errors++
    }
    if (data.conditionAndUseStorage === undefined) {
      const err12 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'conditionAndUseStorage' },
        message: 'must have required property \'' + 'conditionAndUseStorage' + '\''
      }
      if (vErrors === null) {vErrors = [err12]} else {vErrors.push(err12)}
      errors++
    }
    if (data.rentalStart === undefined) {
      const err13 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentalStart' },
        message: 'must have required property \'' + 'rentalStart' + '\''
      }
      if (vErrors === null) {vErrors = [err13]} else {vErrors.push(err13)}
      errors++
    }
    if (data.rentalEnd === undefined) {
      const err14 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentalEnd' },
        message: 'must have required property \'' + 'rentalEnd' + '\''
      }
      if (vErrors === null) {vErrors = [err14]} else {vErrors.push(err14)}
      errors++
    }
    if (data.storageRent === undefined) {
      const err15 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'storageRent' },
        message: 'must have required property \'' + 'storageRent' + '\''
      }
      if (vErrors === null) {vErrors = [err15]} else {vErrors.push(err15)}
      errors++
    }
    if (data.sharedCosts === undefined) {
      const err16 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'sharedCosts' },
        message: 'must have required property \'' + 'sharedCosts' + '\''
      }
      if (vErrors === null) {vErrors = [err16]} else {vErrors.push(err16)}
      errors++
    }
    if (data.indexAdjustmentBasicIndex === undefined) {
      const err17 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'indexAdjustmentBasicIndex' },
        message: 'must have required property \'' + 'indexAdjustmentBasicIndex' + '\''
      }
      if (vErrors === null) {vErrors = [err17]} else {vErrors.push(err17)}
      errors++
    }
    if (data.firstTimeRegulation === undefined) {
      const err18 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'firstTimeRegulation' },
        message: 'must have required property \'' + 'firstTimeRegulation' + '\''
      }
      if (vErrors === null) {vErrors = [err18]} else {vErrors.push(err18)}
      errors++
    }
    if (data.paymentProvisionType === undefined) {
      const err19 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'paymentProvisionType' },
        message: 'must have required property \'' + 'paymentProvisionType' + '\''
      }
      if (vErrors === null) {vErrors = [err19]} else {vErrors.push(err19)}
      errors++
    }
    if (data.businessVATStatus === undefined) {
      const err20 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'businessVATStatus' },
        message: 'must have required property \'' + 'businessVATStatus' + '\''
      }
      if (vErrors === null) {vErrors = [err20]} else {vErrors.push(err20)}
      errors++
    }
    if (data.tenantContactPersonFirstName !== undefined) {
      if (typeof data.tenantContactPersonFirstName !== 'string') {
        const err21 = {
          instancePath: instancePath + '/tenantContactPersonFirstName',
          schemaPath: '#/properties/tenantContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err21]} else {vErrors.push(err21)}
        errors++
      }
    }
    if (data.tenantContactPersonLastName !== undefined) {
      if (typeof data.tenantContactPersonLastName !== 'string') {
        const err22 = {
          instancePath: instancePath + '/tenantContactPersonLastName',
          schemaPath: '#/properties/tenantContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err22]} else {vErrors.push(err22)}
        errors++
      }
    }
    if (data.tenantPhone !== undefined) {
      let data2 = data.tenantPhone
      if (typeof data2 === 'string') {
        if (!pattern0.test(data2)) {
          const err23 = {
            instancePath: instancePath + '/tenantPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {vErrors = [err23]} else {vErrors.push(err23)}
          errors++
        }
      } else {
        const err24 = {
          instancePath: instancePath + '/tenantPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err24]} else {vErrors.push(err24)}
        errors++
      }
    }
    if (data.tenantEmail !== undefined) {
      let data3 = data.tenantEmail
      if (typeof data3 === 'string') {
        if (!pattern1.test(data3)) {
          const err25 = {
            instancePath: instancePath + '/tenantEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {vErrors = [err25]} else {vErrors.push(err25)}
          errors++
        }
      } else {
        const err26 = {
          instancePath: instancePath + '/tenantEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err26]} else {vErrors.push(err26)}
        errors++
      }
    }
    if (data.title !== undefined) {
      if (typeof data.title !== 'string') {
        const err27 = {
          instancePath: instancePath + '/title',
          schemaPath: '#/properties/title/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err27]} else {vErrors.push(err27)}
        errors++
      }
    }
    if (data.backgroundAndPurpose !== undefined) {
      if (typeof data.backgroundAndPurpose !== 'string') {
        const err28 = {
          instancePath: instancePath + '/backgroundAndPurpose',
          schemaPath: '#/properties/backgroundAndPurpose/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err28]} else {vErrors.push(err28)}
        errors++
      }
    }
    if (data.otherProvisions !== undefined) {
      if (typeof data.otherProvisions !== 'string') {
        const err29 = {
          instancePath: instancePath + '/otherProvisions',
          schemaPath: '#/properties/otherProvisions/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err29]} else {vErrors.push(err29)}
        errors++
      }
    }
    if (data.shoppingMall !== undefined) {
      if (typeof data.shoppingMall !== 'string') {
        const err30 = {
          instancePath: instancePath + '/shoppingMall',
          schemaPath: '#/properties/shoppingMall/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err30]} else {vErrors.push(err30)}
        errors++
      }
    }
    if (data.propertyAddress !== undefined) {
      if (typeof data.propertyAddress !== 'string') {
        const err31 = {
          instancePath: instancePath + '/propertyAddress',
          schemaPath: '#/properties/propertyAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err31]} else {vErrors.push(err31)}
        errors++
      }
    }
    if (data.municipality !== undefined) {
      if (typeof data.municipality !== 'string') {
        const err32 = {
          instancePath: instancePath + '/municipality',
          schemaPath: '#/properties/municipality/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err32]} else {vErrors.push(err32)}
        errors++
      }
    }
    if (data.parcelNumber !== undefined) {
      if (typeof data.parcelNumber !== 'string') {
        const err33 = {
          instancePath: instancePath + '/parcelNumber',
          schemaPath: '#/properties/parcelNumber/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err33]} else {vErrors.push(err33)}
        errors++
      }
    }
    if (data.propertyLocalNo !== undefined) {
      if (typeof data.propertyLocalNo !== 'string') {
        const err34 = {
          instancePath: instancePath + '/propertyLocalNo',
          schemaPath: '#/properties/propertyLocalNo/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err34]} else {vErrors.push(err34)}
        errors++
      }
    }
    if (data.propertyLevel !== undefined) {
      if (typeof data.propertyLevel !== 'string') {
        const err35 = {
          instancePath: instancePath + '/propertyLevel',
          schemaPath: '#/properties/propertyLevel/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err35]} else {vErrors.push(err35)}
        errors++
      }
    }
    if (data.propertyExclusiveArea !== undefined) {
      let data13 = data.propertyExclusiveArea
      if (!((typeof data13 == 'number') && (isFinite(data13)))) {
        const err36 = {
          instancePath: instancePath + '/propertyExclusiveArea',
          schemaPath: '#/properties/propertyExclusiveArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err36]} else {vErrors.push(err36)}
        errors++
      }
    }
    if (data.propertyCommonArea !== undefined) {
      let data14 = data.propertyCommonArea
      if (!((typeof data14 == 'number') && (isFinite(data14)))) {
        const err37 = {
          instancePath: instancePath + '/propertyCommonArea',
          schemaPath: '#/properties/propertyCommonArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err37]} else {vErrors.push(err37)}
        errors++
      }
    }
    if (data.propertyTotalArea !== undefined) {
      let data15 = data.propertyTotalArea
      if (!((typeof data15 == 'number') && (isFinite(data15)))) {
        const err38 = {
          instancePath: instancePath + '/propertyTotalArea',
          schemaPath: '#/properties/propertyTotalArea/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err38]} else {vErrors.push(err38)}
        errors++
      }
    }
    if (data.conditionAndUseStorage !== undefined) {
      if (typeof data.conditionAndUseStorage !== 'string') {
        const err39 = {
          instancePath: instancePath + '/conditionAndUseStorage',
          schemaPath: '#/properties/conditionAndUseStorage/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err39]} else {vErrors.push(err39)}
        errors++
      }
    }
    if (data.rentalStart !== undefined) {
      if (typeof data.rentalStart !== 'string') {
        const err40 = {
          instancePath: instancePath + '/rentalStart',
          schemaPath: '#/properties/rentalStart/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err40]} else {vErrors.push(err40)}
        errors++
      }
    }
    if (data.rentalEnd !== undefined) {
      if (typeof data.rentalEnd !== 'string') {
        const err41 = {
          instancePath: instancePath + '/rentalEnd',
          schemaPath: '#/properties/rentalEnd/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err41]} else {vErrors.push(err41)}
        errors++
      }
    }
    if (data.rentalPeriodHasOptions !== undefined) {
      if (typeof data.rentalPeriodHasOptions !== 'boolean') {
        const err42 = {
          instancePath: instancePath + '/rentalPeriodHasOptions',
          schemaPath: '#/properties/rentalPeriodHasOptions/type',
          keyword: 'type',
          params: { type: 'boolean' },
          message: 'must be boolean'
        }
        if (vErrors === null) {vErrors = [err42]} else {vErrors.push(err42)}
        errors++
      }
    }
    if (data.rentalPeriodExtensionType !== undefined) {
      if (typeof data.rentalPeriodExtensionType !== 'string') {
        const err43 = {
          instancePath: instancePath + '/rentalPeriodExtensionType',
          schemaPath: '#/properties/rentalPeriodExtensionType/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err43]} else {vErrors.push(err43)}
        errors++
      }
    }
    if (data.rentalPeriodExtensionYears !== undefined) {
      let data21 = data.rentalPeriodExtensionYears
      if (!((typeof data21 == 'number') && (isFinite(data21)))) {
        const err44 = {
          instancePath: instancePath + '/rentalPeriodExtensionYears',
          schemaPath: '#/properties/rentalPeriodExtensionYears/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err44]} else {vErrors.push(err44)}
        errors++
      }
    }
    if (data.storageRent !== undefined) {
      let data22 = data.storageRent
      if (!((typeof data22 == 'number') && (isFinite(data22)))) {
        const err45 = {
          instancePath: instancePath + '/storageRent',
          schemaPath: '#/properties/storageRent/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err45]} else {vErrors.push(err45)}
        errors++
      }
    }
    if (data.sharedCosts !== undefined) {
      let data23 = data.sharedCosts
      if (!((typeof data23 == 'number') && (isFinite(data23)))) {
        const err46 = {
          instancePath: instancePath + '/sharedCosts',
          schemaPath: '#/properties/sharedCosts/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err46]} else {vErrors.push(err46)}
        errors++
      }
    }
    if (data.indexAdjustmentBasicIndex !== undefined) {
      if (typeof data.indexAdjustmentBasicIndex !== 'string') {
        const err47 = {
          instancePath: instancePath + '/indexAdjustmentBasicIndex',
          schemaPath: '#/properties/indexAdjustmentBasicIndex/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err47]} else {vErrors.push(err47)}
        errors++
      }
    }
    if (data.firstTimeRegulation !== undefined) {
      if (typeof data.firstTimeRegulation !== 'string') {
        const err48 = {
          instancePath: instancePath + '/firstTimeRegulation',
          schemaPath: '#/properties/firstTimeRegulation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err48]} else {vErrors.push(err48)}
        errors++
      }
    }
    if (data.paymentProvisionType !== undefined) {
      if (typeof data.paymentProvisionType !== 'string') {
        const err49 = {
          instancePath: instancePath + '/paymentProvisionType',
          schemaPath: '#/properties/paymentProvisionType/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err49]} else {vErrors.push(err49)}
        errors++
      }
    }
    if (data.businessVATStatus !== undefined) {
      if (typeof data.businessVATStatus !== 'string') {
        const err50 = {
          instancePath: instancePath + '/businessVATStatus',
          schemaPath: '#/properties/businessVATStatus/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err50]} else {vErrors.push(err50)}
        errors++
      }
    }
    if (data.businessVATPercentage !== undefined) {
      let data28 = data.businessVATPercentage
      if (!((typeof data28 == 'number') && (isFinite(data28)))) {
        const err51 = {
          instancePath: instancePath + '/businessVATPercentage',
          schemaPath: '#/properties/businessVATPercentage/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err51]} else {vErrors.push(err51)}
        errors++
      }
    }
    if (data.compulsoryDerogation !== undefined) {
      if (typeof data.compulsoryDerogation !== 'string') {
        const err52 = {
          instancePath: instancePath + '/compulsoryDerogation',
          schemaPath: '#/properties/compulsoryDerogation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err52]} else {vErrors.push(err52)}
        errors++
      }
    }
  } else {
    const err53 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object'
    }
    if (vErrors === null) {vErrors = [err53]} else {vErrors.push(err53)}
    errors++
  }
  validate20.errors = vErrors
  return errors === 0
}
