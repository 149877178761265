import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const syncVersions: producer = async ({
  id = observe.contract.id,

  versions = get.contract.original.versions,

  setVersions = update.contract.original.versions[param.versionId],
  setEmptyVersions = update.contract.original.versions,
  setListener = update.listeners[arg.id].versions,
  error = update.errors[param.id],
}) => {
  if (!id) {
    return;
  }

  try {
    const listener = await db
      .collection("contracts")
      .doc(id)
      .collection("versions")
      .onSnapshot((versionSnapshot: any) => {
        versionSnapshot.forEach((version: any) => {
          const data = version.data();
          if (!data) {
            return;
          }
          setVersions.set(data, { id, versionId: version.id });
        });
        const version = versions.value();
        if (version == undefined || !version) {
          setEmptyVersions.set({});
        }
      });
    setListener.set(listener);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncVersions" }
    );
  }
};
