import { saveAs } from "file-saver";
import { formatContractName } from "src/utils/formatContractName";
import { PDFDocument } from "pdf-lib";
import { ApplicationError, ErrorLevel } from "src/types";

export const mergeAndSavePDF: producer = async ({
  mainContractPDF = observe.legacyContract.pdf.main,
  appendicesPDF = observe.legacyContract.pdf.appendices,
  removeMainContractPDF = update.legacyContract.pdf.main,
  removeAppendicesPDF = update.legacyContract.pdf.appendices,
  removeLoading = update.legacyContract.flags.loadingpdf,
  allValuesRef = get.legacyContract.values,
  appendices = observe.legacyContract.original.appendices,
  error = update.errors[param.type],
}) => {
  if (!mainContractPDF || (!appendicesPDF && appendices)) return;
  removeMainContractPDF.remove();
  removeAppendicesPDF.remove();

  try {
    const nameOfFile = formatContractName(allValuesRef.value());
    const mergedPdf = await PDFDocument.load(mainContractPDF);
    if (appendicesPDF) {
      const pdfA = await PDFDocument.load(appendicesPDF);
      const copiedPagesA = await mergedPdf.copyPages(pdfA, pdfA.getPageIndices());
      copiedPagesA.forEach((page) => mergedPdf.addPage(page));
    }

    const pdfBytes: any = await mergedPdf.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(pdfBlob, `${nameOfFile}.pdf`);
    removeLoading.remove();
  } catch (e) {
    removeLoading.remove();
    console.error("mergeAndSavePDF", e);
    error.set({ message: "error-merging-pdf", level: ErrorLevel.LOW } as ApplicationError, { type: "pdf" });
  }
};
