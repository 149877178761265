import { InputFieldLabelParams } from "./InputFieldTypes";

export const InputFieldLabel = ({
  label, required, htmlFor
}: InputFieldLabelParams) => {

  return <label htmlFor={`${htmlFor}`} className="mb-1 text-gray-700 text-xs select-none">
    <div className="flex flex-row gap-1">
      <div>{label}</div>
      {required && <div className="text-pink-600">*</div>}
    </div>
  </label>
};

export const InputFieldLabelReadOnly = ({
  label, required
}: InputFieldLabelParams) => {

  return <label className="mb-1 input-field-label-ro flex">
    <div className="mr-1">{label}</div>
    {required && <div className="text-pink-600">*</div>}
  </label>
};
