export const storageTotalArea: producer = ({
  fields = observe.contract.originalv2.fields,

  storagePropertyExclusiveArea = get.contract.originalv2.fields.storagePropertyExclusiveArea.value,
  storagePropertyCommonArea = get.contract.originalv2.fields.storagePropertyCommonArea.value,
  originalTotalArea = get.contract.originalv2.fields.storagePropertyTotalArea.value,

  storagePropertyTotalArea = update.contract.changes.fields.storagePropertyTotalArea.value,
}) => {
  if (!fields) return

  storagePropertyExclusiveArea = storagePropertyExclusiveArea.value()
  storagePropertyCommonArea = storagePropertyCommonArea.value()
  if (!storagePropertyExclusiveArea || !storagePropertyCommonArea) {
    storagePropertyTotalArea.set(null)
    return
  }

  const value = storagePropertyExclusiveArea + storagePropertyCommonArea

  if (originalTotalArea.value() === value) return
  storagePropertyTotalArea.set(value)
}
