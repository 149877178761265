/* eslint-disable max-classes-per-file */
import React from "react";
import { RefObject, MouseEventHandler, ChangeEventHandler, FocusEventHandler } from "react";
import {NumericFormat} from "react-number-format";

export type TextFieldProps = {
  path: string;
  placeholder: string;
  type: string;
  className: string;
  readOnly: boolean;
};

type TextFieldValues = {
  data?: any;
  value?: any;
  path?: { set: (x: any, y?: any) => {} };
};

enum ValueSource {
  INPUT = "INPUT",
  SYSTEM = "SYSTEM",
}

const fieldData: producer = ({
  outputPath = update[prop.outputPath],
  input = observe[prop.path].inputValue,
  system = observe[prop.path].systemValue,
  data = update[prop.path].data,
}) => {
  if (!system) {
    return;
  }
  if (!input || system.timestamp >= input.timestamp) {
    data.set({
      value: system.value ?? "",
      source: ValueSource.SYSTEM,
      timestamp: system.timestamp,
    });
  } else {
    data.set({
      value: input.value ?? "",
      source: ValueSource.INPUT,
      timestamp: input.timestamp,
    });
    outputPath.set({
      value: input.value ?? "",
      createdAt: Date.now(),
    });
  }
};

const init: producer = ({ value, updatePath = update[prop.path] }) => {
  updatePath.set({
    isHovering: {
      value: false,
      time: performance.now(),
    },
    isFocused: {
      value: false,
      time: performance.now(),
    },
    systemValue: {
      value,
      timestamp: performance.now(),
    },
  });
};

type StringInputProps = {
  data?: any;
  onChange?: ChangeEventHandler;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  placeholder: string;
  type: string;
  className: string;
  readOnly: boolean;
};

export class StringInput extends React.Component<StringInputProps> {
  private inputRef = React.createRef() as RefObject<HTMLInputElement>;
  private updatedFromSystem: number = 0;
  constructor(props: any) {
    super(props);
  }
  render() {
    if (this.props.data) {
      const el = this.inputRef.current as HTMLInputElement;
      if (
        el &&
        this.props.data.source === ValueSource.SYSTEM &&
        this.updatedFromSystem !== this.props.data.timestamp &&
        this.props.data.value !== undefined
      ) {
        el.value = this.props.data.value;
        this.updatedFromSystem = this.props.data.timestamp;
      }
    }
    return (
      <input
        className={this.props.className}
        ref={this.inputRef}
        type={this.props.type || "text"}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        readOnly={this.props.readOnly}
      />
    );
  }
}

export class ReactNumberInput extends React.Component<any> {
  private value = undefined;
  private key = undefined;
  private updatedFromSystem: number = 0;
  private isFirstUpdate = true;
  constructor(props: any) {
    super(props);
  }
  render() {
    let shouldFocus = false;
    if (this.props.data) {
      if (
        this.props.data.source === ValueSource.SYSTEM &&
        this.updatedFromSystem !== this.props.data.timestamp &&
        this.props.data.value !== undefined
      ) {
        this.value = this.props.data.value;
        this.key = this.props.data.value;
        this.updatedFromSystem = this.props.data.timestamp;
        if (!this.isFirstUpdate) {
          shouldFocus = true;
        } else if (this.props.data.value !== "") {
          this.isFirstUpdate = false;
        }
      }
    }
    return (
      <NumericFormat
        key={this.key}
        className={this.props.className}
        defaultValue={this.value}
        getInputRef={(el: any) => {
          if (shouldFocus && el) el.focus();
        }}
        placeholder={this.props.placeholder}
        onValueChange={this.props.onChange}
        readOnly={this.props.readOnly}
        thousandSeparator={"."}
        decimalSeparator={","}
        // isNumericString={true}
      />
    );
  }
}

export const TextField: view = ({
  placeholder,
  type,
  className,
  readOnly,
  value = observe[prop.path],
  data = observe[prop.path].data,
  path = update[prop.path][param.prop],
}: TextFieldProps & TextFieldValues) => {
  return (
    <StringInput
      placeholder={placeholder}
      type={type}
      data={data}
      readOnly={readOnly}
      className={className}
      onChange={(e) =>
        path.set(
          { value: (e.target as HTMLInputElement).value.trim(), timestamp: performance.now() },
          { prop: "inputValue" }
        )
      }
      onFocus={(e) => path.set({ value: true, timestamp: performance.now() }, { prop: "isFocused" })}
      onBlur={(e) => path.set({ value: false, timestamp: performance.now() }, { prop: "isFocused" })}
      onMouseEnter={(e) => path.set({ value: true, timestamp: performance.now() }, { prop: "isHovering" })}
      onMouseLeave={(e) => path.set({ value: false, timestamp: performance.now() }, { prop: "isHovering" })}
    />
  );
};

export const NumberField: view = ({
  placeholder,
  type,
  className,
  readOnly,
  value = observe[prop.path],
  data = observe[prop.path].data,
  path = update[prop.path][param.prop],
}: TextFieldProps & TextFieldValues) => {
  return (
    <ReactNumberInput
      placeholder={placeholder}
      type={type}
      data={data}
      readOnly={readOnly}
      className={className}
      onChange={(values) => {
        const { value } = values;
        path.set({ value, timestamp: performance.now() }, { prop: "inputValue" });
      }}
    />
  );
};

TextField.producers([init, fieldData]);
NumberField.producers([init, fieldData]);
