import i18n from "src/producers/languages/i18n";
import { ContractStatus } from "src/types";

export const Progress: view = ({ status = observe.contract.original.state.status }) => {
  const map = {
    [ContractStatus.DRAFT]: 0,
    [ContractStatus.NEGOTIATION]: 1,
    [ContractStatus.SIGNING]: 2,
    [ContractStatus.ACTIVE]: 3,
  };
  const level = map[status];
  status = ContractStatus.DRAFT;
  return (
    <svg width="360" height="40" viewBox="0 0 360 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="90" y1="6.5" x2="45" y2="6.5" stroke="#E12764" />
      <circle
        cx="45.3001"
        cy="6.70004"
        r="6.19986"
        transform="rotate(-180 45.3001 6.70004)"
        fill="#E12764"
        stroke="#F4F4F4"
      />

      <line x1="90" y1="6.5" x2="180" y2="6.5" stroke={level >= 1 ? "#E12764" : "#999999"} />
      <circle
        cx="134.7"
        cy="6.69986"
        r="5.19986"
        fill={level >= 1 ? "#E12764" : "#F4F4F4"}
        stroke={level >= 1 ? "#E12764" : "#999999"}
      />

      <line x1="180" y1="6.5" x2="270" y2="6.5" stroke={level >= 2 ? "#E12764" : "#999999"} />
      <circle
        cx="224.7"
        cy="6.69986"
        r="5.19986"
        fill={level >= 2 ? "#E12764" : "#F4F4F4"}
        stroke={level >= 2 ? "#E12764" : "#999999"}
      />

      <line x1="270" y1="6.5" x2="315" y2="6.5" stroke={level == 3 ? "#E12764" : "#999999"} />
      <circle
        cx="314.7"
        cy="6.69986"
        r="5.19986"
        fill={level == 3 ? "#E12764" : "#F4F4F4"}
        stroke={level >= 3 ? "#E12764" : "#999999"}
      />

      <text x="46" y="36" fill="#E12764" textAnchor="middle">
        {i18n.t("status.draft")}
      </text>
      <text x="138" y="36" fill={level >= 1 ? "#E12764" : "#999999"} textAnchor="middle">
        {i18n.t("status.negotiation")}
      </text>
      <text x="226" y="36" fill={level >= 2 ? "#E12764" : "#999999"} textAnchor="middle">
        {i18n.t("status.signing")}
      </text>
      <text x="312" y="36" fill={level >= 3 ? "#E12764" : "#999999"} textAnchor="middle">
        {i18n.t("status.active")}
      </text>
    </svg>
  );
};
