export const versions: producer = ({
  contractId = observe.legacyContract.id,
  versions = observe.legacyContract.original.versions,
  displayedVersion = observe.legacyContract.original.displayedVersion,
  values = update.legacyContract.syncQueue.values.versions,
}) => {
  if (!contractId || versions == undefined)
    return;

  if (!versions) {
    values.set({});
    return;
  }

  try {
    const toSave: any = {};

    Object.keys(versions).forEach((versionId) => {
      Object.keys(versions[versionId].variables).forEach((varName) => {
        if (parseInt(displayedVersion) < parseInt(versionId)) {
          return;
        }
        const variable = versions[versionId].variables[varName];
        if (toSave[varName]) {
          toSave[varName].valueTimeline[versionId] = {
            value: variable.value,
            createdAt: variable?.metadata?.createdAt || variable.createdAt,
            custom: variable.custom || false,
            versionId: versionId,
            type: "version",
          };
        } else {
          toSave[varName] = {
            valueTimeline: {
              [versionId]: {
                value: variable.value,
                createdAt: variable?.metadata?.createdAt || variable.createdAt,
                custom: variable.custom || false,
                versionId: versionId,
                type: "version",
              },
            },
          };
        }
      });
    });
    values.set(toSave);
  } catch (e) {
    console.error("valuesVersions", e);
  }
};
