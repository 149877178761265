import { UserRole } from "src/types";
import { Card } from "../index";
import i18n from "src/producers/languages/i18n";
import { FactoryFormField } from "../fields/formFields/FactoryFormField";

export const Intro: view = ({
  title,
  reset = update.legacyContract.triggers.resetIntro,
  triggerReset = observe.legacyContract.triggers.resetIntro,
  resetIntroTextDefault = update.legacyContract.triggers.resetDefault,
  userRole = get.user.data.role,
  termsUrl = observe.legacyContract.termsUrl,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  return (
    <Card
      key={triggerReset}
      title={title}
      actionText={isManager && i18n.t("clause_intro_reset")}
      actionFunction={
        isManager &&
        function () {
          reset.set(Date.now());
          resetIntroTextDefault.set({ varName: "introText" });
        }
      }>
      <FactoryFormField key="0" fieldName="introText" varName="introText" />
      {termsUrl && (
        <p key={"1.1"} className="font-semibold text-sm">
          <a href={termsUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {i18n.t("legacy.clause_intro_link")}
          </a>
        </p>
      )}
    </Card>
  );
};
