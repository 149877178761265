import React from "react";

import { Checkbox } from "../Checkbox";

// The security type
export const CheckboxType2: view = ({ label, checked, onClick, children }) => {
  return (
    <div className="flex flex-wrap w-full mb-4 mt-4 items-center" key="0">
      <div className="flex" key="1">
        <Checkbox key="1.0" {...{ checked, onClick }} />
        <p key={"1.1"} className="font-semibold text-sm">
          <span key="5">{label}</span>
        </p>
      </div>
      {children}
    </div>
  );
};
