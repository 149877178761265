import { MenuProps } from 'antd'
import i18n from 'src/producers/languages/i18n'

export function TenantDropdownMenuFactory(params: any): MenuProps['items'] {
  console.log('params', params)
  console.log('params?.items', params?.items)
  if (!params?.items || params.items.length === 0) {
    return [
      {
        key: '',
        label: <div className="flex-grow">{i18n.t(`general.no_results_found`)}</div>,
      },
    ]
  }

  return params.items.map((item: any) => ({
    key: item.id,
    label: (
      <div className="w-full flex flex-row" onClick={() => params?.onClick && params.onClick(item.id)}>
        <div className="flex flex-col gap-1 flex-grow">
          <div>{item.name}</div>
        </div>
      </div>
    ),
  }))
}
