import {
  InsideCard
} from "src/pages/Contract/subviews/ContractForm/ClauseCard/InsideCard";
import {
  ClauseCard
} from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { RadioButton, RadioButtonGroup } from "@c11/components";
import {
  FactoryFormField
} from "src/pages/Contract/subviews/ContractForm/fields/FactoryFormField";
import styles from "../../../styles.module.css";

export const RentalCostsClause: view = ({
  title,
  userRole = get.user.data.role,
  setPaymentProvisionFirstPayDueDate = update.contract.toSave.changes.paymentProvisionFirstPayDueDate,
  getPaymentProvisionFirstPayDueDate = observe.contract.values.paymentProvisionFirstPayDueDate.displayedValue,
  getPaymentProvisionFirstPayDueDateFromChanges = observe.contract.currentSession.changes
    .paymentProvisionFirstPayDueDate.value,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isTenant = userRole.value() === UserRole.TENANT;
  const i = getPaymentProvisionFirstPayDueDateFromChanges || getPaymentProvisionFirstPayDueDate;

  function handleClick(c: any) {
    setPaymentProvisionFirstPayDueDate.set({
      value: c + 1,
      createdAt: Date.now()
    });
  }

  return (
    <ClauseCard title={title}>
      <InsideCard key="rent" title={i18n.t("clause_rental_costs_rent_title")}>
        <div className={`${styles.sameHeightFields}`} key="0">
          <p key="p1" className="">
            {i18n.t("clause_rental_costs_rent_p1")}
          </p>
          <FactoryFormField key="0" fieldName="turnoverPercent"
                            varName="turnoverPercent"/>
        </div>
        <div className={`${styles.sameHeightFields}`} key="1">
          <p key="p2" className="">
            {i18n.t("clause_rental_costs_rent_p2")}
          </p>
          <FactoryFormField key="1" fieldName="minimumRent"
                            varName="minimumRent"/>
        </div>
        <div className="w-full flex flex-wrap justify-end" key="2">
          <p className="w-1/2" key="0"/>
          <p className="w-1/2" key="1">
            {i18n.t("clause_rental_costs_rent_p3")}
          </p>
          <FactoryFormField key="2" fieldName="MTO" varName="MTO"/>
        </div>
      </InsideCard>
      <InsideCard key="marketing"
                  title={i18n.t("clause_rental_costs_marketing_cost_title")}>
        <p key="p" className="pb-2">
          {i18n.t("clause_rental_costs_marketing_cost_p1")}
        </p>
        <FactoryFormField key="3" fieldName="marketingCosts"
                          varName="marketingCosts"/>
        <FactoryFormField key="4" fieldName="minimumMarketingCost"
                          varName="minimumMarketingCost"/>
      </InsideCard>
      <InsideCard key="shared"
                  title={i18n.t("clause_rental_costs_shared_cost_title")}>
        <p key="p" className="pb-2">
          {i18n.t("clause_rental_costs_shared_cost_p1")}
        </p>
        <FactoryFormField key="5" fieldName="sharedCosts"
                          varName="sharedCosts"/>
        <FactoryFormField key="6" fieldName="sharedCostsStorage"
                          varName="sharedCostsStorage"/>
      </InsideCard>
      <InsideCard key="index"
                  title={"4.4 " + i18n.t("clause_rental_costs_index_adjustment_title")}>
        <p key="p" className="w-full pb-2">
          {i18n.t("clause_rental_costs_index_adjustment_p1")}
        </p>
        {/* <FactoryFormField key="6" fieldName="rentAdjustment" varName="rentAdjustment" /> */}
        <FactoryFormField key="7" fieldName="firstTimeRegulation"
                          varName="firstTimeRegulation"/>
        <FactoryFormField key="8" fieldName="indexAdjustmentBasicIndex"
                          varName="indexAdjustmentBasicIndex"/>
      </InsideCard>
      <InsideCard key="provision"
                  title={i18n.t("clause_rental_costs_index_payment_provision_title")}>
        {isManager && (
          <RadioButtonGroup selectedValue={String(i)}>
            <RadioButton
              key={`11`}
              className="mb-5 mr-4"
              value={"1"}
              isDisabled={isReadOnlyContract}
              onClick={() => handleClick(0)}>
              {i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_1")}
            </RadioButton>
            <RadioButton
              key={`12`}
              className="mb-5"
              value={"2"}
              isDisabled={isReadOnlyContract}
              onClick={() => handleClick(1)}>
              {i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_2")}
            </RadioButton>
          </RadioButtonGroup>
        )}
        {isTenant && i === 1 && (
          <p>{i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_1")}</p>
        )}
        {isTenant && i === 2 && (
          <p>{i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_2")}</p>
        )}
      </InsideCard>
    </ClauseCard>
  );
};
