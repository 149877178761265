import React from "react";

import { variablesOfTypeMultipleOption, VariablesSufix, variablesWithLongText } from "src/utils/variablesInfo";
import i18n from "src/producers/languages/i18n";

export const Changes: view = ({ oldValue, newValue, currentVariable }) => {
  const valueSuffix = `${VariablesSufix[currentVariable] ? VariablesSufix[currentVariable] : ""}`;
  let oldValueText = oldValue + valueSuffix;
  let newValueText = newValue + valueSuffix;

  if (variablesOfTypeMultipleOption.includes(currentVariable)) {
    oldValueText = `${i18n.t("contract_sidebar_changes_vat_option")} ${oldValue}`;
    newValueText = `${i18n.t("contract_sidebar_changes_vat_option")} ${newValue}`;
  }
  const regularValueChange = oldValue ? (
    <p>
      {i18n.t(currentVariable) || currentVariable}
      {` ${i18n.t("contract_sidebar_changes_updated_from")} `}
      <span className="line-through font-bold">{oldValueText}</span>
      {` ${i18n.t("contract_sidebar_changes_updated_to")} `}
      <span className="text-pink-600">{newValueText}</span>
    </p>
  ) : (
    <p>
      {i18n.t(currentVariable) || currentVariable}
      {` ${i18n.t("contract_sidebar_changes_changed_to")} `}
      <span className="text-pink-600">{newValueText}</span>
    </p>
  );
  const longValueChange = (
    <p>
      {i18n.t(currentVariable) || currentVariable}
      {` ${i18n.t("contract_sidebar_changes_changed")} `}
    </p>
  );
  const regularOrLongVariable = variablesWithLongText.includes(currentVariable) ? longValueChange : regularValueChange;

  return (
    <div style={{ padding: "6px 10px" }} className="border-t klp-border1 flex w-full justify-between">
      {regularOrLongVariable}
    </div>
  );
};
