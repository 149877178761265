import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel } from "src/types";

export const syncTenantData: producer = async ({
  tenantId = observe.legacyContract.original.tenantId,
  updateTenant = update.legacyContract.tenant,
  error = update.errors[param.type],
}) => {
  if (!tenantId) {
    return;
  }
  try {
    db.collection("tenants")
      .where("id", "==", tenantId)
      .onSnapshot((snapshot) => {
        snapshot.forEach((snap) => {
          const tenant = snap.data();
          if (!tenant) return;
          if (tenant.mainContacts)
            tenant.mainContacts = Object.keys(tenant.mainContacts).map((x) => tenant.mainContacts[x]);
          updateTenant.set(tenant);
        });
      });
  } catch (e) {
    console.error("syncTenant", e);
    error.set({ message: "error-sync-tenant", level: ErrorLevel.LOW } as ApplicationError, { type: "tenant" });
  }
};
