import { db } from 'src/producers/firebase'
import {ApplicationError, ErrorLevel, ErrorType, LogLevels, UserRole} from 'src/types'
import { UpdateInputField } from 'src/pages/ContractV2/producers/synchronize/updateInputField'
import { contactFieldsToUpdate } from 'src/pages/ContractV2/producers'

const fieldsToUpdate: {
  [key: string]: string
} = {
  address: 'tenantAddress',
  invoiceAddress: 'tenantInvoiceAddress',
  name: 'tenantName',
  orgNumber: 'tenantOrgNo',
  postalAddress: 'tenantPostalAddress'
}

const updateFields = (tenant: any, contractField: any, updateFieldChanges: any) => {
  for (const key of Object.keys(tenant)) {
    if (!fieldsToUpdate[key]) continue
    const value = tenant[key] || ''
    const field = contractField.value({ field: fieldsToUpdate[key] })
    if (field?.value === value) continue
    updateFieldChanges.set({ value }, { field: fieldsToUpdate[key] })
    UpdateInputField(fieldsToUpdate[key] as string, value)
  }
  if (!tenant.mainContacts) {
    for (const field of Object.values(contactFieldsToUpdate)) {
      updateFieldChanges.set({ value: '' }, { field: field })
      UpdateInputField(field, '')
    }
  }
}

export const persistTenant: producer = async ({
  tenant = observe.contract.changes.tenant,
  isLocked = get.contract.isLocked,
  contractId = get.contract.id,
  contractField = get.contract.originalv2.fields[param.field],

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,

  updateTenantChanges = update.contract.changes.tenant,
  updateFieldChanges = update.contract.changes.fields[param.field],
  updateContractTenant = update.contract.originalv2.tenant,
  error = update.errors[param.id]
}) => {
  if (!tenant || Object.keys(tenant).length < 1) return
  if (isLocked.value()) return

  contractId = contractId.value()
  try {
    isSaving.set(true)
    const updatedAt = new Date().toISOString()
    await db
      .collection('contracts')
      .doc(contractId)
      .set({ tenant, tenantId: tenant.id, updatedAt }, { merge: true })
    updateTenantChanges.remove()
    updateContractTenant.set(tenant)
    updateFields(tenant, contractField, updateFieldChanges)
    successfulSave.set(true)
  } catch (e) {
    console.error('persistTenantChanges', e)
    failedSave.set(true)
    error.set(
      {
        message: 'saving-tenant-to-database',
        level: LogLevels.ERROR
      } as ApplicationError,
      { id: 'persistTenantChanges' }
    )
  }
  isSaving.set(false)
}
