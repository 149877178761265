import saveAs from "file-saver";
import { functions } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";
import { b64toBlob } from "src/utils/b64ToBlob";

export const downloadContract: producer = async ({
  trigger = observe.contract.triggers.downloadSignedContract,
  filePath = get.contract.originalv2.signedPdf.path.value(),
  removeTrigger = update.contract.triggers.downloadSignedContract,
  updatePdfServerLoading = update.contract.flags.loadingContractDownload,
  updateErrors = update.errors[param.id],
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  const ref = window.open()
  try {
    const previewFile = functions.httpsCallable('previewFile')
    updatePdfServerLoading.set(true)
    ref?.document.write('loading')
    const resp = await previewFile({ path: filePath })
    ref.location = resp.data
  } catch (error) {
    ref.close()
    console.error('Error on preview pdf: ', error)
    updateErrors.set(
      {
        message: 'preview-pdf-error',
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      },
      { id: 'previewPDF' }
    )
  }
  updatePdfServerLoading.remove();
};
