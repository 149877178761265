import i18n from "src/producers/languages/i18n";
import { ContractTitle } from "src/pages/Addendum/subviews/AddendumForm/ContractTitle";
import { RentalPremiseClause } from "src/pages/Contract/subviews/ContractForm/RentalPremiseClause/RentalPremiseClause";
import styles from "../../../styles.module.css";
import { ClauseCard } from "../ClauseCard/ClauseCard";
import { InsideCard } from "../ClauseCard/InsideCard";
import { PartiesClause } from "./PartiesClause";
import { RentalPeriod } from "./RentalPeriod";
import { RentalCost } from "./RentalCost";
import { RentalObject } from "./RentalObject";
import { AttachmentsList } from "./AttachmentsList";

export const ParkingContract: view = () => (
  <div className={`${styles.contractsContainer}`}>
    <div>
      <ContractTitle />
      <ClauseCard title={i18n.t("contract_page.contract_premise_clause_h")}>
        <InsideCard>
          <RentalPremiseClause />
        </InsideCard>
      </ClauseCard>
      <PartiesClause title={i18n.t("contract_page.contract_tenant_clause_h")} />
      <RentalObject />
      <RentalPeriod />
      <RentalCost />
      <AttachmentsList />
    </div>
  </div>
);
