import React from "react";

import { Card } from "../index";
import { FactoryFormField } from "../fields/formFields/FactoryFormField";

export const SpecialProvisions: view = ({ title }) => {
  return (
    <Card title={title}>
      <FactoryFormField key="0" fieldName="specialProvisions" varName="specialProvisions" />
    </Card>
  );
};
