import { ClauseType, Clause, Template } from "src/templates/types/template";
import { fields } from "./fields";

export const clauses: { [k: string]: Clause } = {
  heading: {
    name: "heading",
    title: "clause_heading_title",
    elements: {
      contractName: fields.contractName,
    },
    sortedElementsIds: ["contractName"],
    type: ClauseType.Custom,
  },
  intro: {
    name: "intro",
    title: "clause_intro_title",
    elements: {
      contractName: fields.introText,
    },
    sortedElementsIds: ["introText"],
    type: ClauseType.Custom,
  },
  parties: {
    name: "parties",
    title: "clause_parties_title",
    elements: {},
    sortedElementsIds: [],
    type: ClauseType.Custom,
  },
  rentalPeriod: {
    name: "rentalPeriod",
    title: "clause_rentalPeriod_title",
    elements: {},
    sortedElementsIds: [],
    type: ClauseType.Custom,
  },
  rentalProperty: {
    name: "rentalProperty",
    title: "clause_rentalProperty_title",
    elements: {},
    sortedElementsIds: [],
    type: ClauseType.Custom,
  },
  rentalCosts: {
    name: "rentalCosts",
    title: "clause_rentalCosts_title",
    elements: {},
    sortedElementsIds: [],
    type: ClauseType.Custom,
  },
  vat: {
    name: "vat",
    title: "clause_vat_title",
    elements: {},
    sortedElementsIds: [],
    type: ClauseType.Custom,
  },
  security: {
    name: "security",
    title: "clause_security_title",
    elements: {},
    sortedElementsIds: [],
    type: ClauseType.Custom,
  },
  compulsoryDerogation: {
    name: "compulsoryDerogation",
    title: "clause_compulsoryDerogation_title",
    elements: {},
    sortedElementsIds: [],
    type: ClauseType.Custom,
  },
  specialProvisions: {
    name: "specialProvisions",
    title: "clause_specialProvisions_title",
    elements: {},
    sortedElementsIds: [],
    type: ClauseType.Custom,
  },
  signature: {
    name: "signature",
    title: "clause_signature_title",
    elements: {},
    sortedElementsIds: [],
    type: ClauseType.Custom,
  },
};

export const template1: Template = {
  name: "KLP base contract",
  createdBy: "alexandru.filimon@code11.com",
  createdAt: Date.now(),
  isAvailable: true,
  sectionIds: ["mainContract"],
  sections: {
    mainContract: {
      name: "mainContract",
      clauses: {
        heading: clauses.heading,
        intro: clauses.intro,
        parties: clauses.parties,
        rentalProperty: clauses.rentalProperty,
        rentalPeriod: clauses.rentalPeriod,
        rentalCosts: clauses.rentalCosts,
        vat: clauses.vat,
        security: clauses.security,
        compulsoryDerogation: clauses.compulsoryDerogation,
        specialProvisions: clauses.specialProvisions,
        signature: clauses.signature,
      },
      clauseIds: [
        "heading",
        "intro",
        "parties",
        "rentalProperty",
        "rentalPeriod",
        "rentalCosts",
        "vat",
        "security",
        "compulsoryDerogation",
        "specialProvisions",
        "signature",
      ],
    },
  },
};
