import { db } from "src/producers/firebase";

export const syncComments: producer = ({
  id = observe.legacyContract.id,
  setComments = update.legacyContract.original.comments[param.commentId],
  setListener = update.listenersOld[arg.id].comments,
  syncFeed = update.legacyContract.triggers.syncFeed,
}) => {
  if (!id) {
    return;
  }
  try {
    const listener = db
      .collection("contracts")
      .doc(id)
      .collection("comments")
      .onSnapshot((commentSnapshot) => {
        commentSnapshot.forEach((comment) => {
          const data = comment.data();
          if (!data) {
            return;
          }
          setComments.set(data, { id: id, commentId: comment.id });
        });
        syncFeed.set(Date.now());
      });
    setListener.set(listener);
  } catch (e) {
    console.error("syncComments", e);
  }
};
