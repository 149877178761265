import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";

export const TenantDownloadPDFButton: view = ({
  pdfServerLoading = observe.contract.flags.loadingpdf,
  generatePDF = update.contract.triggers.generatePDF,
}) => {
  return (
    <Button
      className="uupercase"
      variant="secondary"
      onClick={() => generatePDF.set(Date.now())}
      isLoading={pdfServerLoading}>
      {!pdfServerLoading && <SVGs.DownloadIconGray key="1" className="mt-1" />}
      <span key="2" className="ml-2">
        {i18n.t("contract_navbar.button_preview_pdf_contract")}
      </span>
    </Button>
  );
};
