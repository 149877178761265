import { db } from "src/producers/firebase";
import { ErrorLevel } from "src/types";

export const updateContractNameForDraft: producer = ({
  id = observe.legacyContract.id,
  versions = observe.legacyContract.original.versions,
  error = update.errors[param.type],
}) => {
  if (!versions || !id) {
    return;
  }
  try {
    let found = false;
    Object.keys(versions)
      .sort((a: any, b: any): any => {
        if (versions[a].createdAt > versions[b].createdAt) {
          return -1;
        } else {
          return 1;
        }
      })
      .forEach((versionId) => {
        if (versions[versionId].variables.contractName?.value && !found) {
          found = true;
          db.collection("contracts").doc(id).update({
            name: versions[versionId].variables.contractName.value,
          });
        }
      });
  } catch (e) {
    console.error("updateContractNameForDraft", e);
    error.set({ message: "error-updating-contract-name", level: ErrorLevel.LOW }, { type: "contract" });
  }
};
