import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";
import { TooltipWrapper } from "src/components";
import { SVGs } from "src/assets";

export const DraftButton: view = ({
  showModal = update.modal.sendContract,
  currentTenantId = observe.legacyContract.original.tenantId,
  invalidFields = observe.legacyContract.invalidFields,
}) => {
  const hasInvalidFields = !!invalidFields && Object.keys(invalidFields).length != 0;
  const isDisabled = !currentTenantId || hasInvalidFields;
  let tooltipText;
  if (!currentTenantId) tooltipText = i18n.t("contract_navbar.button_send_contract_tooltip_no_tenantid");
  if (hasInvalidFields) tooltipText = i18n.t("contract_navbar.button_send_contract_tooltip_invalid_fields");
  return (
    <TooltipWrapper disabled={!isDisabled} text={tooltipText}>
      <Button className="uppercase" isDisabled={isDisabled} onClick={() => showModal.set(true)}>
        <>
          {isDisabled ? (
            <SVGs.PaperAirplaneGray key="1" className="mr-2" />
          ) : (
            <SVGs.PaperAirplaneWhite key="1" className="mr-2" />
          )}
          {i18n.t("contract_navbar.button_send_contract")}
        </>
      </Button>
    </TooltipWrapper>
  );
};
