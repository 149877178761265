import { db } from "src/producers/firebase";

export const syncSessions: producer = ({
  id = observe.legacyContract.id,

  sessions = get.legacyContract.original.sessions[param.sessionId],
  userRole = get.user.data.role,

  setSessions = update.legacyContract.original.sessions[param.sessionId],
  setListener = update.listenersOld[arg.id].sessions,
}) => {
  if (!id) {
    return;
  }
  try {
    const listener = db
      .collection("contracts")
      .doc(id)
      .collection("sessions")
      .onSnapshot((sessionSnapshot) => {
        sessionSnapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          if (change.type === "removed") {
            setSessions.remove({ sessionId: change.doc.id });
          } else {
            setSessions.set(data, { sessionId: change.doc.id });
          }
        });
        const role = userRole.value();
        const session = sessions.value({ sessionId: role });
        if (session === undefined || !session) {
          setSessions.set({}, { sessionId: role });
        }
      });

    setListener.set(listener);
  } catch (e) {
    console.error("syncSessions", e);
  }
};
