import { DashboardViews } from "src/types";

export const setDefaultView: producer = ({
  currentView = observe.pages.dashboard.selectedView,
  updateView = update.pages.dashboard.selectedView,
}) => {
  if (!currentView) {
    updateView.set(DashboardViews.LIST);
  }
};
