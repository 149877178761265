import i18n from "src/producers/languages/i18n";
import { ContractEventType, UserRole } from "src/types";
import { VersionBubbleManager } from "./VersionBubbleManager";
import { EventBubble } from "./EventBubble";

export const VersionBubble: view = ({ version, timestamp, userRole = get.user.data.role }) => {
  const isTenant = userRole.value() === UserRole.TENANT;

  return (
    <>
      {isTenant ? (
        <EventBubble
          timestamp={timestamp}
          title={i18n.t("activity_log.version_created")}
          event={ContractEventType.VERSION_CREATED}
        />
      ) : (
        <VersionBubbleManager
          versionNumber={version.payload?.updates?.version}
          timestamp={timestamp}
          changes={version?.payload}
        />
      )}
    </>
  );
};
