export const populateShoppingMall: producer = async ({
  trigger = observe.legacyContract.triggers.populateShoppingMall,
  removeTrigger = update.legacyContract.triggers.populateShoppingMall,
  writeValue = update.legacyContract.currentSession.changes[param.varName],
  shoppingMalls = get.legacyContract.shoppingMalls,
}) => {
  if (trigger == undefined) {
    return;
  }

  removeTrigger.remove();
  const SM = shoppingMalls.value()[trigger];

  try {
    const mapDataToFields = {
      companyName: ["landlordName"],
      organisationalNumber: ["landlordOrgNo"],
      postalAddress: ["landlordPostalAddress"],
      invoiceAddress: ["landlordInvoiceAddress"],
      accountNumber: ["landlordAccountNo"],
      name: ["shoppingMall"],
      address: ["propertyAddress", "landlordAddress"],
      municipaly: ["municipality"],
      parcelNumber: ["parcelNumber"],
      propertyManager: ["landlordEmail"],
    };

    Object.entries(mapDataToFields).forEach(([dbName, fieldsName]) => {
      fieldsName.forEach((fieldName) => {
        writeValue.set(
          {
            value: SM[dbName] || "",
            createdAt: Date.now(),
          },
          { varName: fieldName }
        );
      });
    });
  } catch (e) {
    console.error("populateShoppingMall", e);
  }
};
