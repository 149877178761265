import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import { FreeTextFieldGeneral } from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import { PlainTextFieldGeneral } from "src/pages/Contract/subviews/ContractForm/fields/PlainTextFieldGeneral";
import { UserRole } from "src/types";
import { FieldInputType } from "src/templates/types/template";
import styles from "../../../Contract/styles.module.css";

export const FreeTextGeneralClause: view = ({ varName, title, userRole = observe.user.data.role }) => {
  const isManager = userRole === UserRole.MANAGER;
  let renderFields;
  if (isManager) {
    renderFields = <FreeTextFieldGeneral varName={varName} />;
  } else {
    renderFields = (
      <PlainTextFieldGeneral varName={varName} inputType={FieldInputType.FreeText} extraClasses="w-full" />
    );
  }

  return (
    <ClauseCard title={title}>
      <div className={`${styles.contractCard}`}>{renderFields}</div>
    </ClauseCard>
  );
};
