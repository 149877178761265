export enum ContractEventType {
  CREATED = "contract.created",
  BUILDING_SELECTED = "BuildingSelected",
  CLIENT_SELECTED = "ClientSelected",
  PARTY_ADDED = "Party.added",
  PARTY_REMOVED = "Party.removed",
  PARTY_UPDATED = "Party.updated",
  OFFER_SENT = "contract.offer.sent",
  NEGOTIATION_STARTED = "NegotiationStarted",
  VERSION_CREATED = "contract.version.created",
  ATTACHMENT_ADDED = "Attachment.added",
  ATTACHMENT_EDITED = "Attachment.edited",
  ATTACHMENT_REMOVED = "Attachment.removed",
  COMMENT_ADDED = "contract.comment.added",
  SIGNING_DIGITAL_SELECTED = "signing.digital.selected",
  SIGNING_PAPER_SELECTED = "signing.paper.selected",
  MOVED_TO_SIGNING = "MovedToSigning",
  CONTRACT_PDF_ATTACHED = "contract.pdf.attached",
  SENT_FOR_SIGNING = "contract.signing.initiated",
  CONTRACT_PDF_SIGNED = "ContractPdf.signed",
  EXECUTED = "contract.executed",
  CANCELLED_SIGNING = "contract.signing.cancelled",
  GUARANTEE_UPLOADED = "contract.guarantee.attached",
  LEASE_UPLOADED = "lease.uploaded",
}

// export interface ContractEvent {
//   createdAt: number;
//   type: ContractEventType;
//   user: any;
// }
