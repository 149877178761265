import { db, storageRef } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel } from 'src/types'

export const deleteAppendix: producer = async ({
  contractId = observe.legacyContract.id,
  trigger = observe.legacyContract.triggers.deleteAppendix,
  appendices = get.legacyContract.original.appendices,
  userId = get.user.uid,
  removeTrigger = update.legacyContract.triggers.deleteAppendix,
  error = update.errors[param.type],
}) => {
  if (trigger === undefined || !contractId) {
    return
  }

  appendices = appendices.value()
  const attachment = appendices[trigger]
  const appendixRef = storageRef.child(`${contractId}/${attachment.fileId}`)
  try {
    await appendixRef.delete()
    appendices.splice(trigger, 1)
    const contractDb = db.collection('contracts').doc(contractId)
    contractDb.set({ appendices }, { merge: true }).then(() => {
      console.log('Document successfully written!')
      removeTrigger.remove()
    })
    // const command = {
    //   action: CommandActions.REMOVE_ATTACHMENTS,
    //   contractId: contractId,
    //   fileName: attachment.fileName,
    //   fileId: attachment.fileId,
    //   fileType: attachment.fileType,
    // };
    // commandPublisher(command).catch((err: any) => console.error(err))
    // await contractDb.collection("events").add({
    //   createdAt: Date.now(),
    //   type: ContractEventType.ATTACHMENT_REMOVED,
    //   user: userId.value(),
    //   fileName: attachment.fileName,
    //   fileId: attachment.fileId,
    //   fileType: attachment.fileType
    // });
  } catch (e) {
    console.error('deleteAppendix', e)
    error.set({
      message: 'error-deleting-appendix',
      level: ErrorLevel.LOW
    } as ApplicationError, {
      type: 'defaultFiles',
    })
  }
}
