import { UserRole } from "src/types";

import { RegularInputGeneral } from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import i18n from "src/producers/languages/i18n";
import { PlainTextFieldGeneral } from "src/pages/Contract/subviews/ContractForm/fields/PlainTextFieldGeneral";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import styles from "../../../Contract/styles.module.css";
import { FieldInputType } from "src/templates/types/template";

export const ContractTitle: view = ({
  userRole = observe.user.data.role,
  title = observe.contract.values.title.displayedValue,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
}) => {
  const isManager = userRole === UserRole.MANAGER;
  const isTenant = userRole === UserRole.TENANT;
  let renderFields;
  if (isManager && !isReadOnlyContract) {
    renderFields = <RegularInputGeneral varName="title" placeholder={i18n.t("contract_title")} extraClasses="w-full" />;
  } else {
    renderFields = (
      <PlainTextFieldGeneral varName="title" placeholder={i18n.t("contract_title")} inputType={FieldInputType.Text} />
    );
  }
  return (
    (isManager || (isTenant && title)) && (
      <ClauseCard title={i18n.t("contract_title")}>
        <div className={`${styles.contractCard}`}>{renderFields}</div>
      </ClauseCard>
    )
  );
};
