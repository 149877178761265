import React from "react";
import { Images } from "src/assets";

export const ShoppingMallsDropdownRow: view = ({
  SM,
  index,
  populateShoppingMall = update.legacyContract.triggers.populateShoppingMall,
}) => {
  if (!SM) return null;
  return (
    <div
      onClick={() => {
        if (index !== undefined) {
          populateShoppingMall.set(index);
        }
      }}
      className="flex justify-between items-center mx-2 my-1">
      <div className="flex">
        <img
          src={SM.buildingImageUrl || Images.logo}
          alt="shopping mall image"
          style={{ objectFit: "contain", width: 70, height: 40, marginRight: 15 }}
        />
        <div>
          <p key="1" className=" text-left font-medium">
            {SM.name && <span>{SM.name} </span>}
          </p>
          <p key="2" className="font-12 text-left text-gray-600">
            {SM.address && <span>{SM.address} </span>}
          </p>
        </div>
      </div>
    </div>
  );
};
