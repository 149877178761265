import { DateField, DateFieldReadOnly } from 'src/components_generic/DateField'
import { NumberField, NumberFieldReadOnly } from 'src/components_generic/NumberField'
import { NumberFormatterFactory } from 'src/components_generic/number-formatter'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import styles from '../styles.module.css'
import { ReactNumberInput } from 'src/components_generic/ReactNumberInput'
import { CalendarIcon } from 'src/assets/svg'

const NumberFormatter = NumberFormatterFactory()

export const ContractDates: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status,
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked

  return (
    <>
      <VisibleIf predicate={!displayReadOnlyView}>
        <ContractDatesComponent />
      </VisibleIf>
      <VisibleIf predicate={displayReadOnlyView}>
        <ContractDatesComponentReadOnly />
      </VisibleIf>
    </>
  )
}

enum ContractDatesInputFields {
  LEASE_DATE = 'leaseDate',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

const ContractDatesComponent: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  setMiscChanges = update.contract.changes.miscellaneous[param.name],
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const LeaseDate = () => fields?.[ContractDatesInputFields.LEASE_DATE]?.value
  const StartDate = () => fields?.[ContractDatesInputFields.START_DATE]?.value
  const EndDate = () => fields?.[ContractDatesInputFields.END_DATE]?.value

  return (
    <div className='pb-10'>
      <h2>{i18n.t(`contract_page.contract_dates_clause_h`)}</h2>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center justify-start gap-8">
          <CalendarIcon />
          {i18n.t('contract_page.contract_dates_clause_p')}
        </div>

        <div className={`${styles.row}`}>
          <DateField
            name={ContractDatesInputFields.LEASE_DATE}
            label={i18n.t(`contract.labels.${ContractDatesInputFields.LEASE_DATE}`)}
            value={LeaseDate()}
            required={true}
            onChange={(value) => {
              setMiscChanges.set(
                {
                  value,
                },
                {
                  name: ContractDatesInputFields.LEASE_DATE,
                }
              )
            }}
          />
          <DateField
            name={ContractDatesInputFields.START_DATE}
            label={i18n.t(`contract.labels.${ContractDatesInputFields.START_DATE}`)}
            value={StartDate()}
            required={true}
            onChange={(value) => {
              setMiscChanges.set(
                {
                  value,
                },
                {
                  name: ContractDatesInputFields.START_DATE,
                }
              )
            }}
          />
          <DateField
            name={ContractDatesInputFields.END_DATE}
            label={i18n.t(`contract.labels.${ContractDatesInputFields.END_DATE}`)}
            required={true}
            value={EndDate()}
            onChange={(value) => {
              setMiscChanges.set(
                {
                  value,
                },
                {
                  name: ContractDatesInputFields.END_DATE,
                }
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

const ContractDatesComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields,
}) => {
  return (
    <div className='pb-10'>
      <h2>{i18n.t(`contract_page.contract_dates_clause_h`)}</h2>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center justify-start gap-8">
          <CalendarIcon />
          {i18n.t('contract_page.contract_dates_clause_p')}
        </div>

        <div className={`${styles.row}`}>
          <DateFieldReadOnly
            extraClassNames="flex-grow"
            // name={ContractDatesInputFields.RENTAL_START}
            label={i18n.t(`contract.labels.${ContractDatesInputFields.LEASE_DATE}`)}
            value={fields?.[ContractDatesInputFields.LEASE_DATE]?.value}
          />
          <DateFieldReadOnly
            extraClassNames="flex-grow"
            // name={ContractDatesInputFields.RENTAL_START}
            label={i18n.t(`contract.labels.${ContractDatesInputFields.START_DATE}`)}
            value={fields?.[ContractDatesInputFields.START_DATE]?.value}
          />
          <DateFieldReadOnly
            extraClassNames="flex-grow"
            // name={ContractDatesInputFields.RENTAL_START}
            label={i18n.t(`contract.labels.${ContractDatesInputFields.END_DATE}`)}
            value={fields?.[ContractDatesInputFields.END_DATE]?.value}
          />
        </div>
      </div>
    </div>
  )
}
