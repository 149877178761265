import React from 'react'
import { ContractV2 } from './ContractV2/ContractV2'
import { Contract } from './Contract/Contract'
import { getContracts } from 'src/producers'
import { db } from 'src/producers/firebase'
import { getContractProd } from './producers/getContract'
import { router, routes } from 'src/router'
import { matchRoutes, useLocation } from 'react-router-dom'
import { ContractLegacy } from './ContractLegacy/ContractLegacy'

export const ContractWrapper: view = ({
  revision = observe.routes.contractRevision,
  pathParam = observe.routes.pathParam,
}) => {
  if (!revision) return null
  console.log('>>>Revision: ', revision)
  if (revision === 4) {
    return <ContractV2 />
  } else if (revision === 3 || revision === 2) {
    return <Contract />
  } else {
    return <ContractLegacy />
  }
}

ContractWrapper.producers([getContractProd])
