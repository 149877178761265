import { Appendix } from "./appendix"
import { Session } from "./session"
import { Version } from "./version"

export interface Contract {
  id: string
  createdAt?: number
  filterTag?: ContractFilterOption
  versions: Version[]
  comments: Comment[]
  appendices: Appendix[]
  tenantId: string
  tenant: any
  triggers: any
  templateId: string
  latestVersion: number
  contractType: ContractType
  status: ContractStatus
  defaultValues: {
    createdAt: string
    value: string
  }
  sessions: {
    manager: Session
    tenant: Session
  }
}

// Old shit
export enum AgreementType {
  RENTAL = "RENTAL",
  PARKING = "PARKING",
  ADDENDUM = "ADDENDUM",
}

// Old shit
export enum ContractType {
  DIGITAL = "DIGITAL",
  PAPER = "PAPER",
  // EXTRACT THIS TO AGREEMENT TYPE ENUM, ALONG WITH "RENTAL"
  PARKING = "PARKING",
  SHOPPING_MALL = "SHOPPING_MALL",
}

export enum ContractTypeExport {
  MALL = "mall",
  OFFICE = "office",
  PARKING_OFFICE = "office_parking",
  PARKING_MALL = "mall_parking",
  ADDENDUM_MALL = "mall_addendums",
}

export enum AddendumType {
  DOWNPAYMENT = "DOWNPAYMENT_ADDENDUM",
  TRANSPORT = "TRANSPORT_ADDENDUM",
  CANCELATION = "CANCELATION_ADDENDUM",
  RENTAL_PERIOD_PRICE = "RENTAL_PERIOD_PRICE_ADDENDUM",
  STORAGE = "STORAGE_ADDENDUM",
}

export enum ContractStatus {
  DRAFT = "draft",
  NEGOTIATION = "negotiation",
  SIGNING = "signing",
  DELETED = "deleted",
  ACTIVE = "active",
}

export enum ContractSigningMethod {
  DIGITAL = "digital",
  PAPER = "paper",
}

export enum ContractFilterOption {
  NONE = "none",
  ARCHIVED = "archived",
  LOST = "lost",
  TRASHED = "trashed",
}
