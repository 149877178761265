import React from "react";

import {
  FactoryFormField
} from "src/pages/Contract/subviews/ContractForm/fields/FactoryFormField";
import i18n from "src/producers/languages/i18n";
import { RadioButton, RadioButtonGroup, Switch } from "@c11/components";

import styles from "../../../styles.module.css";

export const ManagerPotentialOptions: view = ({
  rentalPeriodHasOptions,
  rentalPeriodEorR,
  isReadOnlyContract,
  setRentalPeriodHasOptions = update.contract.toSave.changes.rentalPeriodHasOptions,
  setRentalPeriodEorR = update.contract.toSave.changes.rentalPeriodEorR,
}) => {
  const hasPotencialOptions = rentalPeriodHasOptions === "true";
  return (
    <div className={`${styles.rentalPeriodOptionsContainer}`}>
      {/* TODO Try to put this in the template */}
      <div className={`${styles.rentalPeriodOptionsHeader}`}>
        <p key="3">{i18n.t("clause_rental_period_potential_options")}</p>
        <div className="flex">
          <span>
            {hasPotencialOptions
              ? i18n.t("clause_rental_period_potential_options_o")
              : i18n.t("clause_rental_period_potential_options_na")}
          </span>
          <Switch
            className={`secondary-switch-${hasPotencialOptions ? "checked" : "unchecked"}`}
            isChecked={hasPotencialOptions}
            isDisabled={isReadOnlyContract}
            onClick={() => {
              hasPotencialOptions
                ? setRentalPeriodHasOptions.set({
                  value: "false",
                  createdAt: Date.now()
                })
                : setRentalPeriodHasOptions.set({
                  value: "true",
                  createdAt: Date.now()
                });
            }}
          />
        </div>
      </div>
      <div className={`${styles.rentalPeriodOptions}`}>
        {rentalPeriodHasOptions === "true" && (
          <>
            <div className={`${styles.rentalRadioOptions}`}>
              <RadioButtonGroup selectedValue={rentalPeriodEorR}>
                <RadioButton
                  key={`13}`}
                  className="mb-5"
                  value={"E"}
                  isDisabled={isReadOnlyContract}
                  onClick={() =>
                    rentalPeriodEorR !== "E" ? setRentalPeriodEorR.set({
                      value: "E",
                      createdAt: Date.now()
                    }) : {}
                  }>
                  {i18n.t("clause_rental_period_potential_options_extension_u")}
                </RadioButton>
                <RadioButton
                  key={`14}`}
                  className="mb-5"
                  value={"R"}
                  isDisabled={isReadOnlyContract}
                  onClick={() =>
                    rentalPeriodEorR !== "R" ? setRentalPeriodEorR.set({
                      value: "R",
                      createdAt: Date.now()
                    }) : {}
                  }>
                  {i18n.t("clause_rental_period_potential_options_renewal_u")}
                </RadioButton>
              </RadioButtonGroup>
            </div>
            <FactoryFormField key="5" fieldName="rentalPeriodOptionsPeriod"
                              varName="rentalPeriodOptionsPeriod"/>
          </>
        )}
      </div>
    </div>
  );
};
