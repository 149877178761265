import React from "react";

import { Checkbox } from "../Checkbox";

// The potential option type
export const CheckboxType3: view = ({ label, checked, onClick }) => {
  return (
    <div className="flex flex-wrap w-full mb-4 mt-4 items-center" key="0">
      <Checkbox key="1.0" {...{ checked, onClick }} />
      <p key={"1.1"} className="font-semibold text-sm">
        <span key="5">{label}</span>
      </p>
    </div>
  );
};
