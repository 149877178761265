import { Tooltip } from "antd";
import { Button } from "@c11/components.button";

import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { ContractStatus } from "src/types";

import styles from "../../../styles.module.css"

export const UpdateFoundBar: view = ({
  showResetModal = update.modal.resetContract,
  showCreateModal = update.modal.createNewVersion,
  hasChanges = observe.contract.hasChanges,
  status = observe.contract.original.state.status,
}) => {
  if (!hasChanges || status !== ContractStatus.NEGOTIATION) return null;
  return (
    <div className={`${styles.notificationBar}`}>
      <div className="flex flex-auto items-center ">
        <SVGs.UserBanner />
        <div className="ml-3">
          <div className="font-medium">{i18n.t("contract_page.update_found_p1")}</div>
          <div className="flex items-center">
            <span className="mr-2">{i18n.t("contract_page.update_found_p2")}</span>
            <Tooltip title={i18n.t("contract_page.update_found_help_text")} placement={"bottom"}>
              <SVGs.HelpBigBlack />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <Button onClick={() => showCreateModal.set(Date.now())}>{i18n.t("button_create_new_version")}</Button>
        <Button variant="ghost" onClick={() => showResetModal.set(Date.now())}>
          {i18n.t("contract_page.reset_all_changes")}
        </Button>
      </div>
    </div>
  );
};
