export const moveElement: producer = ({
  trigger = observe.contract.triggers.dragAttachment,
  removeTrigger = update.contract.triggers.dragAttachment,
  attachmentsViewModel = get.contract.attachmentsViewModel,
  updateCurrentSession = update.contract.currentSession.attachments,
}) => {
  if (!trigger) return;
  removeTrigger.remove();

  const { sourceId, destinationId } = trigger;
  attachmentsViewModel = attachmentsViewModel.value();

  // Helper functions
  function move(array, oldIndex, newIndex) {
    if (newIndex >= array.length) {
      newIndex = array.length - 1;
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  }

  function moveElement(array, index, offset) {
    const newIndex = index + offset;

    return move(array, index, newIndex);
  }

  const sourceIndex = attachmentsViewModel.findIndex((item) => item.id === sourceId);
  const destinationIndex = attachmentsViewModel.findIndex((item) => item.id === destinationId);

  // If source/destination is unknown, do nothing.
  if (sourceId === -1 || destinationId === -1) {
    return;
  }

  const offset = destinationIndex - sourceIndex;
  const mv = moveElement(attachmentsViewModel, sourceIndex, offset);
  const newAttachmentsSessionState: any = {};
  mv.forEach((a: any, index: number) => {
    newAttachmentsSessionState[a.id] = { order: index };
  });
  updateCurrentSession.merge(newAttachmentsSessionState);
};
