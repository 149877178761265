import Big from "big.js";

export const minimumMarketingCost: producer = ({
  minimumTurnOver = observe.contract.values.MTO.displayedValue,
  marketingCosts = observe.contract.values.marketingCosts.displayedValue,
  isCustom = observe.contract.values.minimumMarketingCost.custom,
  oldValue = get.contract.values.minimumMarketingCost.displayedValue,

  minimumMarketingCost = update.contract.toSave.changes.minimumMarketingCost,
}) => {
  if (isCustom == true || minimumTurnOver === undefined || marketingCosts === undefined) {
    return;
  }
  if (minimumTurnOver === "" || marketingCosts === "") {
    minimumMarketingCost.set({
      value: 0,
      custom: false,
    });
  } else {
    oldValue = oldValue.value();
    const value = new Big(minimumTurnOver).times(marketingCosts).div(100).round().toNumber();
    if (oldValue == value) {
      return;
    }
    minimumMarketingCost.set({
      value,
      custom: false,
    });
  }
};
