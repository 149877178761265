import { Button } from "@c11/components.button";
import "./AddPartyButton.css";

import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { PeopleSilhouettesIcon } from "src/assets/svg";

export const AddPartyButton: view = ({
  addNewParty = update.modal.addEditPartyModal.type,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!isManager) return null;
  return (
    <Button variant="secondary" className="add-party-btn uppercase" key="45" onClick={() => addNewParty.set("add")}>
      <PeopleSilhouettesIcon />
      <span className="ml-2">{i18n.t("parties_page.parties_add_party")}</span>
    </Button>
  );
};
