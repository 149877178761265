import i18n from "src/producers/languages/i18n";

export const MessageBubble: view = ({ comment, timestamp, userRole = get.user.data.role }) => {
  const isMadeByYourTeam = userRole.value() === comment?.metadata?.user?.role;

  const senderName = `${i18n.t("sidebar.message_by")} ${comment?.metadata?.user?.fullName || ""}`;
  if (isMadeByYourTeam)
    return (
      <div className="justify-end flex my-3">
        <div className="px-4 py-2 bg-green-300 rounded-lg w-11/12 ">
          <p>{comment?.payload?.message}</p>
          <div className="font-12 flex justify-between text-gray-500">
            <div>{`${timestamp} - ${senderName}`}</div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="px-4 py-2 border border-gray-200 bg-white w-11/12 rounded-lg my-3">
        <p>{comment?.payload?.message}</p>
        <p className="font-12 text-gray-500 flex justify-between">{`${timestamp} ${senderName}`}</p>
      </div>
    );
};
