import Big from "big.js";

export const securityAmount: producer = ({
  sharedCosts = observe.contract.values.sharedCosts.displayedValue,
  totalArea = observe.contract.values.propertyTotalArea.displayedValue,
  marketingCosts = observe.contract.values.marketingCosts.displayedValue,
  minimumRent = observe.contract.values.minimumRent.displayedValue,
  minimumTurnover = observe.contract.values.MTO.displayedValue,
  securityAlternative = observe.contract.values.securityAlternative.displayedValue,
  isCustom = observe.contract.values.securityAmount.custom,
  shoppingMall = observe.contract.values.shoppingMall.displayedValue,

  oldValue = get.contract.values.securityAmount.displayedValue,

  securityAmount = update.contract.toSave.changes.securityAmount,
}) => {
  if (
    isCustom == true ||
    sharedCosts === undefined ||
    totalArea === undefined ||
    marketingCosts === undefined ||
    minimumRent === undefined ||
    minimumTurnover === undefined ||
    securityAlternative != 1
  ) {
    return;
  }
  if (sharedCosts === "" || totalArea === "" || marketingCosts === "" || minimumRent === "" || minimumTurnover === "") {
    securityAmount.set({
      value: 0,
      custom: false,
    });
  } else {
    oldValue = oldValue.value();
    minimumRent = new Big(minimumRent);
    sharedCosts = new Big(sharedCosts);
    totalArea = new Big(totalArea);
    marketingCosts = new Big(marketingCosts);
    minimumTurnover = new Big(minimumTurnover);

    let rate = 0;

    switch (shoppingMall) {
      case "Paléet kjøpesenter":
        rate = 282;
        break;
      case "GlasMagasinet Kjøpesenter":
        rate = 193;
        break;
      case "Byporten - Kjøpesenter":
        rate = 161;
        break;
      default:
        break;
    }

    const propertyTax = new Big(rate).times(totalArea).div(2);

    const value = minimumRent
      .div(2)
      .plus(sharedCosts.times(totalArea).div(2))
      .plus(marketingCosts.div(100).times(minimumTurnover).div(2))
      .plus(propertyTax)
      .round()
      .toNumber();
    if (oldValue == value) {
      return;
    }
    const valueWithVAT = new Big(value).times(1.25).round().toNumber();

    securityAmount.set({
      value: valueWithVAT,
      custom: false,
    });
  }
};
