import { Button } from '@c11/components.button'

import './Modal.css'
import i18n from 'src/producers/languages/i18n'
import styles from './styles.module.css'
import { Modal as ModalAntd } from 'antd'

export const Modal: view = ({
  allModals = update.modal,
  children,
  title,
  width,
  okButtonText,
  okButtonDisabled,
  onOkClick,
  cancelButtonText,
  cancelButtonHidden,
  onCancelClick,
  leftButtonText,
  leftButtonAction,
  underlayClickExits,
}) => {
  cancelButtonText = cancelButtonText || i18n.t('general.cancel')

  underlayClickExits = typeof underlayClickExits === 'undefined' ? true : underlayClickExits

  function handleOKClick() {
    if (onOkClick) onOkClick()
    allModals.remove()
  }

  function handleCancelClick() {
    if (onCancelClick) onCancelClick()
    allModals.remove()
  }

  return (
    <ModalAntd
      title={title}
      open={true}
      onCancel={() => handleCancelClick()}
      centered
      width={width || 500}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}>
      <div className={`${styles.modal}`}>
        <div className={`${styles.modalBody}`}>{children}</div>
        <footer className={`${styles.modalFooter}`}>
          <div>
            {leftButtonText && (
              <Button className="uppercase btnGhostUnderline" variant="ghost" onClick={leftButtonAction}>
                {leftButtonText}
              </Button>
            )}
          </div>
          <div className="flex flex-row gap-2">
            {!cancelButtonHidden && (
              <Button className="uppercase btnGhostUnderline" variant="ghost" onClick={handleCancelClick}>
                {cancelButtonText}
              </Button>
            )}
            {okButtonText && (
              <Button className="uppercase " variant="primary" onClick={handleOKClick} isDisabled={okButtonDisabled}>
                {okButtonText}
              </Button>
            )}
          </div>
        </footer>
      </div>
    </ModalAntd>
  )
}
