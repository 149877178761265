import Big from "big.js";

export const totalArea: producer = ({
  propertyExclusiveArea = observe.contract.values.propertyExclusiveArea.displayedValue,
  propertyCommonArea = observe.contract.values.propertyCommonArea.displayedValue,

  oldValue = get.contract.values.propertyTotalArea.displayedValue,

  propertyTotalArea = update.contract.toSave.changes.propertyTotalArea,
}) => {
  if (propertyExclusiveArea === undefined || propertyCommonArea === undefined) {
    return;
  }
  if (propertyExclusiveArea === "" || propertyCommonArea === "") {
    propertyTotalArea.set({
      value: 0,
      custom: false,
    });
  } else {
    oldValue = oldValue.value();
    const value = new Big(propertyCommonArea).plus(new Big(propertyExclusiveArea)).toNumber();
    if (oldValue == value) {
      return;
    }

    propertyTotalArea.set({
      value,
      custom: false,
    });
  }
};
