import React from "react";
import {
  MouseEventHandler,
  ChangeEventHandler,
  FocusEventHandler
} from "react";
import {NumericFormat, NumberFormatBase} from "react-number-format";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { NumberFormatterNew } from 'src/components_generic/number-formatter'

export type TextFieldProps = {
  path: string;
  placeholder: string;
  type: string;
  className: string;
  readOnly: boolean;
};

type TextFieldValues = {
  data?: any;
  value?: any;
  path?: { set: (x: any, y?: any) => {} };
};

enum ValueSource {
  INPUT = "INPUT",
  SYSTEM = "SYSTEM",
}

const fieldData: producer = ({
  custom,
  outputPath = update[prop.outputPath],
  input = observe[prop.path].inputValue,
  system = observe[prop.path].systemValue,
  data = update[prop.path].data,
}) => {
  if (!system) {
    return;
  }

  if (!input || system.timestamp >= input.timestamp) {
    data.set({
      value: system.value ?? "",
      source: ValueSource.SYSTEM,
      timestamp: system.timestamp,
    });
  } else {
    data.set({
      value: input.value ?? "",
      source: ValueSource.INPUT,
      timestamp: input.timestamp,
    });
    outputPath.set({
      value: input.value ?? "",
      createdAt: Date.now(),
      ...(custom !== undefined && { custom }),
    });
  }
};

const init: producer = ({ value, updatePath = update[prop.path] }) => {
  updatePath.set({
    isHovering: {
      value: false,
      time: performance.now(),
    },
    isFocused: {
      value: false,
      time: performance.now(),
    },
    systemValue: {
      value,
      timestamp: performance.now(),
    },
  });
};

type StringInputProps = {
  data?: any;
  onChange?: ChangeEventHandler;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  placeholder?: string;
  type?: string;
  className: string;
  readOnly: boolean;
};

export class StringInput extends React.Component<StringInputProps> {
  private element: HTMLInputElement | null = null;
  private updatedFromSystem: number = 0;
  private wasUpdatedFromSystem = false;

  constructor(props: any) {
    super(props);
  }

  setRef(element: HTMLInputElement | null) {
    this.element = element;
    this.sync();
  }

  focusTextInput() {
    if (this.element) this.element.focus();
  }

  sync() {
    if (this.props.data && this.element) {
      const el = this.element;
      if (
        el &&
        this.props.data.source === ValueSource.SYSTEM &&
        this.updatedFromSystem !== this.props.data.timestamp &&
        this.props.data.value !== undefined &&
        (!this.wasUpdatedFromSystem || (this.wasUpdatedFromSystem && el.value !== this.props.data.value)) &&
        el !== document.activeElement
      ) {
        el.value = this.props.data.value;
        this.updatedFromSystem = this.props.data.timestamp;
        if (this.props.data.value) {
          this.wasUpdatedFromSystem = true;
        }
      }
    }
  }

  render() {
    this.sync();

    return (
      <input
        className={this.props.className}
        ref={(el) => this.setRef(el)}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        // onFocus={this.props.onFocus}
        // onBlur={this.props.onBlur}
        // onMouseEnter={this.props.onMouseEnter}
        // onMouseLeave={this.props.onMouseLeave}
        readOnly={this.props.readOnly}
        // this is done to disable the autocomplete both in safari and in chrome
        type={"search"}
        autoComplete="off"
        name="notASearchField"
      />
    );
  }
}

export class ReactNumberInput extends React.Component<any> {
  private value = undefined;
  private key = undefined;
  private updatedFromSystem: number = 0;
  private isFirstUpdate = true;
  private wasUpdatedFromSystem = false;
  state = {
    value: undefined,
  };
  private element: HTMLInputElement | null = null;

  constructor(props: any, state: any) {
    super(props, state);
    this.state = {
      value: props?.data?.value,
    };
  }

  setRef(element: HTMLInputElement | null) {
    this.element = element;
    this.sync();
  }

  sync() {
    if (this.props.data && this.element) {
      const el = this.element;
      if (
        !this.wasUpdatedFromSystem &&
        el &&
        this.props &&
        this.props.data &&
        this.props.data.source === ValueSource.SYSTEM &&
        this.updatedFromSystem !== this.props.data.timestamp &&
        this.props.data.value !== undefined
      ) {
        this.setState({
          value: this.props.data.value,
        });
        this.updatedFromSystem = this.props.data.timestamp;
        if (this.props.data.value) {
          this.wasUpdatedFromSystem = true;
        }
      }
    }
  }

  render() {
    console.log("rendering", this.state?.value)
    return (
      <NumericFormat
        autoComplete="off"
        key={this.key}
        className={this.props.className}
        value={NumberFormatterNew(this.state?.value)}
        getInputRef={(el: any) => {
          this.setRef(el);
        }}
        placeholder={this.props.placeholder}
        onValueChange={(value) => {
          this.setState({
            value: value.floatValue,
          });
          this.props.onChange(value);
        }}
        readOnly={this.props.readOnly}
        thousandSeparator={"."}
        decimalSeparator={","}
        isNumericString={true}
      />
    );
  }
}

export class FreeTextInput extends React.Component<StringInputProps> {
  private element: HTMLInputElement | null = null;
  private updatedFromSystem: number = 0;
  private wasUpdatedFromSystem = false;

  constructor(props: any) {
    super(props);
  }

  state = {
    value: undefined,
    focus: false,
  };

  setRef(element: HTMLInputElement | null) {
    this.element = element;
    this.sync();
  }

  sync() {
    if (this.props.data && this.element) {
      const el = this.element;
      if (
        el &&
        this.props.data.source === ValueSource.SYSTEM &&
        this.updatedFromSystem !== this.props.data.timestamp &&
        this.props.data.value !== undefined &&
        (!this.wasUpdatedFromSystem || (this.wasUpdatedFromSystem && el.value !== this.props.data.value)) &&
        this.state.focus == false
      ) {
        this.setState({
          ...this.state,
          ...{
            value: this.props.data.value,
          },
        });

        this.updatedFromSystem = this.props.data.timestamp;
        if (this.props.data.value) {
          this.wasUpdatedFromSystem = true;
        }
      }
    }
  }

  render() {
    this.sync();
    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
      ],
      history: {
        delay: 2000,
        maxStack: 10,
        userOnly: true,
      },
    };

    const formats = [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
    ];
    return (
      <>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          ref={(el) => this.setRef(el)}
          value={this.state?.value || ""}
          onChange={(value) => {
            this.setState({
              value,
            });
            this.props.onChange(value);
          }}
          // onFocus={() =>
          //   this.setState({
          //     value: this.state.value,
          //     focus: true,
          //   })
          // }
          // onBlur={() =>
          //   this.setState({
          //     value: this.state.value,
          //     focus: false,
          //   })
          // }
          readOnly={this.props.readOnly}
          className={this.props.readOnly ? "bg-gray-200" : "bg-transparent"}
        />
        <input
          className={this.props.className}
          ref={(el) => this.setRef(el)}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          // onFocus={this.props.onFocus}
          // onBlur={this.props.onBlur}
          // onMouseEnter={this.props.onMouseEnter}
          // onMouseLeave={this.props.onMouseLeave}
          readOnly={this.props.readOnly}
          // this is done to disable the autocomplete both in safari and in chrome
          type={"search"}
          autoComplete="off"
          name="notASearchField"
        />
      </>
    );
  }
}

export const TextField: view = ({
  placeholder,
  type,
  className,
  readOnly,
  data = observe[prop.path].data,
  path = update[prop.path][param.prop],
}: TextFieldProps & TextFieldValues) => {
  return (
    <StringInput
      placeholder={placeholder}
      type={type}
      data={data}
      readOnly={readOnly}
      className={className}
      onChange={(e) =>
        path.set(
          {
            value: (e.target as HTMLInputElement).value.trim(),
            timestamp: performance.now()
          },
          { prop: "inputValue" }
        )
      }
      // onFocus={(e) => path.set({ value: true, timestamp: performance.now() }, { prop: "isFocused" })}
      // onBlur={(e) => path.set({ value: false, timestamp: performance.now() }, { prop: "isFocused" })}
      // onMouseEnter={(e) => path.set({ value: true, timestamp: performance.now() }, { prop: "isHovering" })}
      // onMouseLeave={(e) => path.set({ value: false, timestamp: performance.now() }, { prop: "isHovering" })}
    />
  );
};

export const NumberField: view = ({
  placeholder,
  type,
  className,
  readOnly,
  data = observe[prop.path].data,
  path = update[prop.path][param.prop],
}: TextFieldProps & TextFieldValues) => {
  return (
    <ReactNumberInput
      placeholder={placeholder}
      type={type}
      data={data}
      readOnly={readOnly}
      className={className}
      onChange={(values) => {
        const { value } = values;
        if (value != data.value) path.set({
          value,
          timestamp: performance.now()
        }, { prop: "inputValue" });
      }}
    />
  );
};

export const FreeTextField: view = ({
  className,
  readOnly,
  data = observe[prop.path].data,
  path = update[prop.path][param.prop],
}: any) => {
  return (
    <FreeTextInput
      data={data}
      readOnly={readOnly}
      className={className}
      onChange={(e) => {
        if (data.value !== e) path.set({
          value: e,
          timestamp: performance.now()
        }, { prop: "inputValue" });
      }}
    />
  );
};

TextField.producers([init, fieldData]);
NumberField.producers([init, fieldData]);
FreeTextField.producers([init, fieldData]);
