import i18n from 'src/producers/languages/i18n'
import { Button } from '@c11/components'
import { uploadPaperContract } from './UploadPaperContractButton.uploadPaperContract'

export const UploadPaperContractButton: view = ({
  _viewId,
  setContractToUpload = update.contract.triggers.uploadPaperContract,
  isUploading = observe.contract.triggers.uploadPaperContract,
  leaseDocument = observe.contract.originalv2.rentalContract,
  setOpenDialog = update.views[prop._viewId].openDialog,
  openDialog = observe.views[prop._viewId].openDialog,
}) => {
  return (
    <Button
      variant="primary"
      isLoading={!!isUploading}
      onClick={() => {
        !openDialog && document.getElementById('upload-signed-contract-input')?.click()
        setOpenDialog.set(true)
        setTimeout(() => setOpenDialog.set(false), 1000)
      }}>
      <input
        id="upload-signed-contract-input"
        type="file"
        onChange={(e) => {
          setContractToUpload.set({ data: e.target.files[0] })
        }}
        accept="application/pdf"
      />
      {leaseDocument ? (
        <span className="uppercase">{i18n.t('contract_page.edit_contract_pdf_button')}</span>
      ) : (
        <span className="uppercase">{i18n.t('contract_page.upload_contract_pdf_button')}</span>
      )}
    </Button>
  )
}

UploadPaperContractButton.producers([uploadPaperContract])
