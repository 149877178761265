import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel } from "src/types";

export const saveChangesToDB: producer = ({
  contractId = observe.legacyContract.id,
  currentSession = observe.legacyContract.currentSession,
  trigger = observe.legacyContract.triggers.saveChangesToDB,
  removeTrigger = update.legacyContract.triggers.saveChangesToDB,
  saveStatus = update.legacyContract.loading.saveStatus,
  userRole = get.user.data.role,
  activeSession = get.legacyContract.original.sessions[param.userType],
  error = update.errors[param.type],
}) => {
  const role = userRole.value();
  if (!trigger || !currentSession || !role || !contractId) {
    return;
  }

  try {
    const contractDb = db.collection("contracts").doc(contractId);
    activeSession = activeSession.value({ userType: role });
    if (!activeSession) {
      currentSession.createdAt = Date.now();
    }
    contractDb
      .collection("sessions")
      .doc(role)
      .set(currentSession)
      .then(() => {
        removeTrigger.remove();
        saveStatus.set("saved");
      });
  } catch (e) {
    saveStatus.set("error");
    console.error("saveChangesToDB", e);
    error.set({
      message: "error-saving-changes",
      level: ErrorLevel.BREAKING
    } as ApplicationError, { type: "changes" });
  }
};
