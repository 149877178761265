import { ContractStatus, RouteName } from 'src/types'
import { AgreementType } from 'src/types/commands/command'
import { ContractFlow } from 'src/pages/ContractV2/contract.types'
import { isContractAddendum } from 'src/utils/isContractAddendum'
export const setSummaryAsMainTab: producer = ({
  status = observe.contract.originalv2.status,
  flow = get.contract.originalv2.flow,
  revision = get.contract.originalv2.revision,
  updateActiveTab = update.routes.list[param.route].data.subview,
}) => {
  flow = flow.value()
  revision = revision.value()
  if (flow !== ContractFlow.DIGITAL) return
  if ([ContractStatus.ACTIVE, ContractStatus.SIGNING].includes(status)) {
    const route = revision > 3 ? RouteName.CONTRACT_V2 : RouteName.CONTRACT
    updateActiveTab.set('SUMMARY', { route: route })
  }
}
