import { Modal } from 'src/components'
import { SVGs } from 'src/assets'
import i18n from 'src/producers/languages/i18n'
import { UserRole } from 'src/types'
import {stopSigning} from 'src/pages/ContractV2/modals/StopSigningModal/stopSigning'

export const StopSigningModal: view = ({
  show = observe.modal.stopSigning,
  stopSigning = update.contract.triggers.stopSigning,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER
  if (!show || !isManager) return null
  return (
    <Modal
      title={i18n.t('modals.stop_signing_title')}
      image={() => <SVGs.ModalRemoveChanges/>}
      okButtonText={i18n.t('modals.stop_signing_button')}
      onOkClick={() => stopSigning.set(Date.now())}
    >
      <p>{i18n.t('modals.stop_signing_p')}</p>
    </Modal>
  )
}

StopSigningModal.producers([stopSigning]);
