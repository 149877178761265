import { Button } from "@c11/components";
import i18n from "src/producers/languages/i18n";

export const NewAddendumButton: view = ({
  createAddendum = observe.contract.triggers.createAddendum,
  openModal = update.modal.newAddendum,
  _now,
}) => (
  <Button
    className="uppercase mr-3"
    isLoading={createAddendum}
    variant="secondary"
    onClick={() => openModal.set(_now())}>
    {i18n.t("addendum.new_addendum")}
  </Button>
);
