export const synchronizeUIDataAttachments: producer = async ({
  attachments = observe.contract.originalv2.attachments,
  updateAttachmentsUIData = update.contract.ui.attachments,
}) => {
  if (!attachments) return

  if (Object.keys(attachments).length === 0) {
    updateAttachmentsUIData.set({
      asArray: [],
      count: 0,
      orderOfFirstElement: 0,
      orderOfLastElement: 0,
      orderSortedArray: [],
    })
    return
  }

  const asArray = Object.values(attachments)
  console.log(`Updated`)
  updateAttachmentsUIData.set({
    asArray,
    count: asArray.length,
    orderOfFirstElement: Math.min(...asArray.map((s) => s.order).filter((s) => !!s)),
    orderOfLastElement: Math.max(...asArray.map((s) => s.order).filter((s) => !!s)),
    orderSortedArray: asArray.sort(OrderSorter),
  })
}

type OrderedObject = {
  order: number
  [key: string]: any
}
const OrderSorter = <T extends OrderedObject>(a: T, b: T) => {
  if (a.order > b.order) return 1
  if (a.order < b.order) return -1
  return 0
}
