import moment from "moment"
import { db, storageRef } from "src/producers/firebase"
import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from "src/types"
import { v4 } from "uuid"

export const persistUserAttachment: producer = async ({
  user = get.user,
  isLocked = get.contract.isLocked,
  contractId = get.contract.id,
  attachments = get.contract.originalv2.attachments,
  updateContractAttachments = update.contract.originalv2.attachments,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  newAttachmentTrigger = observe.contract.triggers.attachments.user.new,
  newAttachment = get.contract.changes.attachments.user.new,

  addNewAttachmentTrigger = update.contract.triggers.attachments.user.new,
  updateNewAttachment = update.contract.changes.attachments.user.new,
}) => {
  if (!newAttachmentTrigger) return

  isLocked = isLocked.value()
  if (isLocked) return

  user = user.value()
  contractId = contractId.value()
  attachments = attachments.value()
  newAttachment = newAttachment.value()

  if (!user) return
  if (!contractId) return
  if (!newAttachment) return

  try {
    isSaving.set(true)

    const fileId = v4()
    const updatedAt = new Date().toISOString()
    const path = `${contractId}/${fileId}`
    let nextAttachmentIndex = 1

    if (!!attachments && Object.keys(attachments).length > 0) {
      nextAttachmentIndex = Math.max(...Object.values(attachments).map((a) => a?.order || 0)) + 1
    }

    const attachment = {
      id: fileId,
      name: newAttachment.name.split(".")[0],
      type: newAttachment.type,
      selected: false,
      isUserAttachment: true,
      path: path,
      user: user.uid,
      order: nextAttachmentIndex,
      createdAt: moment(newAttachment.lastModified).toISOString(),
    }

    const appendixRef = storageRef.child(path)
    await appendixRef.put(newAttachment, { contentType: newAttachment.type })

    await db
      .collection("contracts")
      .doc(contractId)
      .set(
        {
          attachments: { [fileId]: attachment },
          updatedAt,
        },
        { merge: true }
      )

    updateContractAttachments.merge({ [fileId]: attachment })
    successfulSave.set(true)
  } catch (e) {
    console.error("persist-user-attachment", e)
    failedSave.set(true)
    error.set(
      {
        message: "saving-attachments-to-database",
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: "persist-user-attachment" }
    )
  } finally {
    addNewAttachmentTrigger.remove()
    updateNewAttachment.remove()

    isSaving.set(false)
  }
}
