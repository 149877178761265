import { Menu, Dropdown } from "antd";
import { DropdownArrowOutline, GlobeIcon } from "src/assets/svg";
import i18n from "src/producers/languages/i18n";
import { Language } from "src/types";
import styles from "../styles.module.css"

export const LanguageDropdown: view = ({
  currentLanguage = observe.languages.current,
  triggerChange = update.languages.triggers.change,
}) => {
  if (!currentLanguage) return null;

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1" className={`${styles.languageDropdownItem}`} onClick={() => triggerChange.set(Language.ENGLISH)}>
        {i18n.t("dashboard.language_option_english")}
      </Menu.Item>
      <Menu.Item key="2"  className={`${styles.languageDropdownItem}`} onClick={() => triggerChange.set(Language.NORWEGIAN)}>
        {i18n.t("dashboard.language_option_norwegian")}
      </Menu.Item>
    </Menu>
  );
  const currentLanguageDisplayed =
    currentLanguage === Language.ENGLISH
      ? i18n.t("dashboard.language_option_english")
      : i18n.t("dashboard.language_option_norwegian");

  return (
    <Dropdown overlay={dropdownMenu} trigger={["click"]}>
      <button className={`${styles.languageDropdown}`}>
        <GlobeIcon />
        <span>{currentLanguageDisplayed}</span>
        <DropdownArrowOutline />
      </button>
    </Dropdown>
  );
};
