import "./NewUserLoginModal.css";

import { RightArrowIcon, InfoCircle } from "src/assets/svg";
import { Modal } from "src/components";
import i18n from "src/producers/languages/i18n";
import { Button } from "@c11/components.button";
import { setUserDept } from "src/pages/Dashboard/NewUserLoginModal/NewUserLoginModal.setUserDept";
import { Images } from "src/assets";
import styles from "../styles.module.css";
import { DepartmentDropdown } from "../DepartmentDropdown/DepartmentDropdown";

export const NewUserLoginModal: view = ({
  trigger = observe.modal.newUserLogin,
  modal = update.modal,
  getContracts = update.pages.dashboard.triggers.getContracts,
  klpDeptSplitFf = observe.featureFlags.dashboardKLPDeptSplit.main,
}) => {
  if (!trigger || !klpDeptSplitFf) return null;

  return (
    <Modal
      // title="Its your first login to KLP Dynamic Contracts"
      width={700}
      cancelButtonHidden="true"
      underlayClickExits={false}
      closeButtonHidden>
      <section className={`${styles.onboardingModal}`}>
        <img src={Images.building} alt="building" />
        <div className={`${styles.onboardingMessage}`}>
          <h3>{i18n.t("dashboard.modal_newUser_title")}</h3>
          <p>{i18n.t("dashboard.modal_newUser_header")}</p>
        </div>
        <div className={`${styles.deparmentDropdown}`}>
          <DepartmentDropdown
            // label={i18n.t("dashboard.department_dropdown_label")}
            onSelect={() => {
              getContracts.set(Date.now());
              modal.remove();
            }}
          />
        </div>
        <div className={`${styles.infoSection}`}>
          <InfoCircle />
          <p>{i18n.t("dashboard.modal_newUser_info_message")}</p>
        </div>
      </section>
    </Modal>
  );
};

NewUserLoginModal.producers([setUserDept]);
