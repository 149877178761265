export const createPartiesViewModel: producer = async ({
  parties = observe.contract.original.state.parties,
  uppdatePartiesViewModel = update.contract.partiesViewModel,
}) => {
  if (!parties) parties = {};

  const partiesViewModel: any = Object.values(parties);
  partiesViewModel.sort((a, b) => a.order - b.order);
  uppdatePartiesViewModel.set(partiesViewModel);
};
