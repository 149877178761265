import React from "react";

import i18n from "src/producers/languages/i18n";

export const SendMessageCard: view = ({
  getComment = observe.comment.message,
  resetComment = observe.comment.reset,
  setComment = update.comment.message,
  submitComment = update.legacyContract.triggers.submitComment,
  isSubmitted = observe.comment.submit,
}) => {
  return (
    <div
      style={{ height: "155px", bottom: "10px" }}
      className="bg-white mx-4 p-4 border klp-border1 rounded-lg absolute  right-0 left-0 ">
      <textarea
        key={resetComment}
        className="w-full resize-none"
        style={{ height: "65px" }}
        placeholder={i18n.t("sidebar_send_message_p")}
        onChange={(e: any) => setComment.set(e.target.value)}></textarea>

      <button
        className={`appearance-none mt-1 py-3 px-4 w-full  rounded font-medium outline-none ${
          getComment ? "bg-pink-600 text-white" : "text-gray-600 bg-gray-200"
        }`}
        onClick={() => !isSubmitted && submitComment.set(Date.now())}>
        <div className={`uppercase`}>{i18n.t("button_send_message")}</div>
      </button>
    </div>
  );
};
