import { db, fieldValue, storageRef } from "src/producers/firebase"
import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from "src/types"

export const persistAttachmentsDelete: producer = async ({
  isLocked = get.contract.isLocked,
  contractId = get.contract.id,
  attachments = get.contract.originalv2.attachments,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  attachmentsToRemove = observe.contract.changes.attachmentsToRemove,
  updateAttachmentsToRemove = update.contract.changes.attachmentsToRemove,
  updateContractAttachments = update.contract.originalv2.attachments[param.attachmentId],
}) => {
  if (!attachmentsToRemove) return
  if (attachmentsToRemove.length < 1) return

  isLocked = isLocked.value()
  if (isLocked) return

  contractId = contractId.value()
  attachments = attachments.value()

  if (!contractId) return
  if (!attachments) return
  if (Object.keys(attachments).length < 1) return

  try {
    isSaving.set(true)
    const updatedAt = new Date().toISOString()
    for (const attachmentId of attachmentsToRemove) {
      const attachment = attachments[attachmentId]
      if (attachment.isUserAttachment && attachment.path) {
        const appendixRef = storageRef.child(attachment.path)
        await appendixRef.delete()
      }
      await db
        .collection("contracts")
        .doc(contractId)
        .set(
          {
            attachments: {
              [attachmentId]: fieldValue.delete(),
            },
            updatedAt,
          },
          { merge: true }
        )
      updateContractAttachments.remove({ attachmentId: attachmentId })
    }
    successfulSave.set(true)
  } catch (e) {
    console.error("persist-attachment-delete", e)
    failedSave.set(true)
    error.set(
      {
        message: "saving-attachments-to-database",
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: "persist-attachment-delete" }
    )
  } finally {
    updateAttachmentsToRemove.remove()

    isSaving.set(false)
  }
}
