import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'

export const stopSigning: producer = async ({
  trigger = observe.contract.triggers.stopSigning,
  removeTrigger = update.contract.triggers.stopSigning,
  contractId = get.contract.id,
  envelopeInfo = get.contract.original.state.envelopeInfo,
  error = update.errors[param.id],
  stateTransition = update.contract.loading.stateTransition,
  rawUser = get.rawUser,
  localFunctions = get.localFunctions,
}) => {
  if (!trigger) return

  try {
    stateTransition.set(true)
    const command = {
      action: CommandActions.CANCEL_SIGNING,
      contractId: contractId.value(),
      envelopeInfo: envelopeInfo.value(),
    }
    console.log('stopSigning producer: ', command)
    await axios.post(
      getFunctionsUrl('commandPublisher', localFunctions.value()),
      command,
      {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type': 'application/json'
        }
      }
    )
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'stopSigning-contract' }
    )
  } finally {
    removeTrigger.remove()
  }
}
