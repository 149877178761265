import react from 'react'
import { ReactDatePicker } from 'react-datepicker'
import { InputFieldLabel, InputFieldLabelReadOnly } from './InputFieldLabel'
import { InputFieldParams, InputFieldReadOnlyParams } from './InputFieldTypes'
import { ComposeHtmlClassName } from './compose-html-class-name'
import { DatePicker } from 'antd'
import moment from 'moment'
import { InvalidMessage } from 'src/pages/ContractV2/components/InvalidMessage'
import dayjs from 'dayjs'

type DateFieldReadOnlyParams = InputFieldReadOnlyParams<string>
type DateFieldParameters = InputFieldParams<string> & {
  submitOnBlur?: boolean
}

const TEXT_FIELD_CLASS_NAMES = 'w-full p-3 mt-auto appearance-none shadow-sm border klp-border1 rounded-none text-gray-800 leading-tight focus:bg-green-300 focus:border-transparent focus:outline-none'
const TEXT_FIELD_RO_CLASS_NAMES = 'w-full p-3 appearance-none shadow-sm border klp-border1 text-gray-800 leading-tight focus:bg-green-300 bg-gray-200'

export const DateField = ({
  name, label, value, required, placeholder, extraClassNames, inputExtraClassNames,
  onChange, submitOnBlur
}: DateFieldParameters) => {
  let selectedDate = null
  let picker = null

  return <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)} style={{ flexBasis: 0 }}>
    <InputFieldLabel htmlFor={name} label={label} required={required}/>
    <DatePicker
      ref={picker}
      key={name}
      className={ComposeHtmlClassName(TEXT_FIELD_CLASS_NAMES, inputExtraClassNames)}
      format={'DD.MM.YYYY'}
      placeholder={placeholder}
      changeOnBlur={submitOnBlur}
      value={value ? dayjs(new Date(Date.parse(value))) : null}
      onChange={(date) => {
        if (!onChange) return
        if (!date) return
        if (!date.toISOString()) return
        onChange(new Date(date.toDate().setHours(0,0,0,0)).toISOString())
      }}
    />
    <InvalidMessage name={name}/>
  </div>
}

export const DateFieldReadOnly = ({
  label, value, required, extraClassNames, inputExtraClassNames
}: DateFieldReadOnlyParams) => {
  const GetFormattedDate = () => {
    if (!value) return ''

    return dayjs(value).format('DD.MM.YYYY')
  }

  return <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)} style={{ flexBasis: 0 }}>
    <InputFieldLabelReadOnly label={label} required={required}/>
    <div className="input-readonly">{GetFormattedDate()}</div>
  </div>
}
