import { db } from 'src/producers/firebase'
import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from 'src/types'

export const fetchTenant: producer = async ({
  // tenantId = observe.contract.originalv2.tenantId,
  tenantIDQuery = observe.contract.originalv2View.tenantIDQuery,
  updateTenant = update.contract.changes.tenant,
  error = update.errors[param.id],
  tenantsDropdownFlag = observe.featureFlags.tenantsDropdown.main,
}) => {
  if (!tenantIDQuery || tenantsDropdownFlag) return
  try {
    console.log('tenant number:', tenantIDQuery)
    const snap = await db.collection('tenants').where('number', '==', `${tenantIDQuery}`).get()
    if (snap.empty) throw ('tenant-not-found')
    for (const doc of snap.docs) {
      const tenant = doc.data()
      if (!tenant) throw ('No tenant found')
      if (tenant.mainContacts) tenant.mainContacts = Object.keys(tenant.mainContacts).map((x) => tenant.mainContacts[x])
      updateTenant.set(tenant)
    }
  } catch (e) {
    console.log('ERROR: ', e)
    error.set(
      {
        message: typeof e === 'string' ? e : 'tenant-not-found',
        level: LogLevels.WARN,
      } as ApplicationError,
      { id: 'syncTenant-contract' }
    )
  }
}
