import { Button } from '@c11/components.button'
import './AttachmentsCard.css'

import i18n from 'src/producers/languages/i18n'
import { SVGs } from 'src/assets'
import { PaperclipIcon } from 'src/assets/svg'
import { ContractPageSubview, ContractStatus, RouteName } from 'src/types'
import { ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from 'src/components'

import styles from '../../../styles.module.css'
import { VisibleIf } from 'src/components_generic/visible-if'

export const AttachmentsCard: view = ({
  setSubview = update.routes.list[RouteName.CONTRACT_V2].data.subview,

  status = observe.contract.originalv2.status,
  attachmentsUIData = observe.contract.ui.attachments
}) => {
  try {
    const isSigning = status === ContractStatus.SIGNING
    const isActive = status === ContractStatus.ACTIVE
    const isSigningOrActive = isSigning || isActive
    const selectedAttachments = attachmentsUIData?.orderSortedArray?.filter((attachment: any) => attachment.selected) || []
    return (
      <>
        <ThumbnailCard className={`${styles.contractSummaryCard} ${isSigningOrActive && styles.borderedCard}`}>
          <ThumbnailCardHeader
            className={`${styles.summaryCardsHeader}`}
            key="tch-1"
            title={i18n.t('contract_navbar.attachments')}
            separator={true}
          >
            <div className="float-right inline-block">
              <SVGs.OverviewAttachments/>
            </div>
          </ThumbnailCardHeader>
          <ThumbnailCardBody key="tcb-1">
            <div className="pb-3 overflow-auto">
              <VisibleIf predicate={selectedAttachments?.length < 1}>
                <div className="bg-gray-100 flex px-6 py-4 mt-2">
                  <div className="flex flex-auto items-center">
                    <SVGs.InfoIcon/>
                    <div className="ml-4">
                      <div className="">{i18n.t('contract.attachments.no-attachments')}.</div>
                    </div>
                  </div>
                </div>
              </VisibleIf>
              <VisibleIf predicate={selectedAttachments?.length > 0}>
                <>
                  {selectedAttachments.map(
                    (attachment, index) =>
                      <div key={`attachment-${index}`} className={`${styles.summaryList + ' ' + styles.attachments}`}>
                        <SVGs.OverviewDocument/>
                        <span className="truncate">{attachment.name}</span>
                      </div>
                  )}
                </>
              </VisibleIf>
            </div>
          </ThumbnailCardBody>
          <ThumbnailCardFooter key="tcf-1">
            <Button
              className="view-attachments-btn border-secondary second-theme-color"
              variant="secondary"
              onClick={() => {
                setSubview.set(ContractPageSubview.ATTACHMENTS)
              }}
            >
              <PaperclipIcon fill="none" stroke="currentColor"/>
              <span key="2" className="pl-2 uppercase">
              {i18n.t('summary_page.view_attachments')}
            </span>
            </Button>
          </ThumbnailCardFooter>
        </ThumbnailCard>
      </>
    )
  } catch (e) {
    console.error('AttachmentsCard', e)
  }
}
