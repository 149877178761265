import i18n from "src/producers/languages/i18n";
import { ContractStatus, ContractSigningMethod, PartyType, UserRole } from "src/types";
import { HeaderSection } from "src/pages/Contract/HeaderSection/HeaderSection";
import { createPartiesViewModel } from "src/pages/Contract/subviews/Parties/Parties.createPartiesViewModel";
import { PartyListItem } from "src/pages/Contract/subviews/Parties/PartyListItem/PartyListItem";
import { NoPartiesBar } from "src/pages/Contract/subviews/Parties/NoPartiesBar/NoPartiesBar";
import { NoPartiesCard } from "src/pages/Contract/subviews/Parties/NoPartiesCard/NoPartiesCard";
import { AddPartyButton } from "src/pages/Contract/subviews/Parties/AddPartyButton/AddPartyButton";
import { PartyModal } from "src/pages/Contract/subviews/Parties/PartyModal/PartyModal";
import { SigningMethodSection } from "src/pages/Contract/subviews/Parties/SigningMethodSection/SigningMethodSection";
import { UpdateFoundBar } from "../ContractForm/UpdateFoundBar/UpdateFoundBar";
import styles from "../../styles.module.css";

export const Parties: view = ({
  partiesViewModel = observe.contract.partiesViewModel,
  status = observe.contract.original.state.status,
  contractSigningMethod = observe.contract.original.state.signing,
  signingMethodUpdate = observe.contract.triggers.setSigningMethod,
  signingMethodUpdateTrigger = update.contract.triggers.setSigningMethod,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isDraft = status === ContractStatus.DRAFT;
  const isNegotiation = status === ContractStatus.NEGOTIATION;
  const signingMethodDisabled = status === ContractStatus.SIGNING || status === ContractStatus.ACTIVE;
  const canAddSigners = (isDraft || isNegotiation) && isManager;

  const hasEnoughParties =
    !!partiesViewModel?.find((p) => p.partyType == PartyType.TENANT) &&
    !!partiesViewModel?.find((p) => p.partyType == PartyType.LANDLORD);

  const changeSigningMethod = () => {
    signingMethodUpdateTrigger.set(
      contractSigningMethod === ContractSigningMethod.DIGITAL
        ? ContractSigningMethod.PAPER
        : ContractSigningMethod.DIGITAL
    );
  };
  return (
    <>
      <div className="flex flex-col overflow-auto pb-24 h-screen">
        <HeaderSection title={i18n.t("parties_page.parties_title")} subtitle={i18n.t("parties_page.parties_subtitle")}>
          <div className={"mr-3"}>{canAddSigners && <AddPartyButton />}</div>
        </HeaderSection>
        {isManager && <UpdateFoundBar />}
        {!hasEnoughParties && <NoPartiesBar />}
        {partiesViewModel?.length == 0 && canAddSigners && <NoPartiesCard />}
        <div className={`${styles.signersSection}`}>
         <div>
          <>
              {partiesViewModel?.map((party, index) => (
                <div key={index}>
                  <PartyListItem party={party} index={index} />
                </div>
              ))}
            </>
            <div className="mt-auto">
              <SigningMethodSection
                disabled={signingMethodDisabled}
                loading={signingMethodUpdate}
                selectedMethod={contractSigningMethod}
                onClick={changeSigningMethod}
              />
            </div>
         </div>
        </div>
      </div>
      <PartyModal />
    </>
  );
};

Parties.producers([createPartiesViewModel]);
