import { db, functions } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
import i18n from 'src/producers/languages/i18n'

export const linkTenantLegacy: producer = async ({
  trigger = observe.contract.triggers.linkTenant,
  removeTrigger = update.contract.triggers.linkTenant,
  contractId = observe.contract.id,

  sessionTenant = update.contract.currentSession.tenant,
  populateTenant = update.contract.triggers.populateTenant,
  setLoading = update.contract.flags.loadingTenant,
  error = update.errors[param.id],
  updateMainContacts = update.contract.tenant.mainContacts
}) => {
  if (!trigger || !contractId) return
  removeTrigger.remove()

  console.log('linkTenantLegacy', trigger)

  try {
    setLoading.set(performance.now())
    const tenantId = trigger

    const snap = await db.collection('tenants').where('number', '==', String(tenantId)).get()
    if (snap.empty) throw ('tenant-not-found')
    const tenant = snap.docs[0].data()
    const mainContacts = tenant?.mainContacts || {}
    const mainContactsArray = Object.keys(mainContacts).map((x) => mainContacts[x])

    if (mainContacts) updateMainContacts.set(mainContactsArray)

    sessionTenant.set(tenant)

    populateTenant.set({ ...tenant, mainContacts })
  } catch (e) {
    console.error('Error linking tenant: ', e)
    error.set(
      {
        message: 'tenant-link-failed',
        description: 'contact-support'
      } as ApplicationError,
      { id: 'linkTenant-contract' }
    )
  } finally {
    setLoading.remove()
  }
}
