import i18n from 'src/producers/languages/i18n'
import { Clause, SubClause } from 'src/components_generic/Clause'
import styles from '../../../../styles.module.css'
import { TextField, TextFieldReadOnly } from 'src/components_generic/TextField'
import { TextEditor, TextEditorReadOnly } from 'src/components'
import { AddendumFields } from './fields'
import { NumberField, NumberFieldReadOnly } from 'src/components_generic/NumberField'
import { ContractTemplate } from 'src/pages/ContractV2/contract.types'
import { VisibleIf } from 'src/components_generic/visible-if'

export const AddendumTransport: view = ({
  addendum = observe.contract.originalv2,
  isReadOnlyView = observe.random.isReadOnlyView,
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || isReadOnlyView

  if (!addendum) return <></>
  if (!addendum.template) return <></>

  if (addendum.template !== ContractTemplate.TRANSPORT) return <></>

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <AddendumTransportComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <AddendumTransportReadOnlyComponent/>
    </VisibleIf>
  </>
}

// TODO: Add button for syncing tenant and mb dropdown ?
const AddendumTransportComponent: view = ({
  addendum = observe.contract.originalv2,
  triggerChanges = update.contract.changes.fields
}) => {

  const contractTitle = addendum.fields?.[AddendumFields.CONTRACT_TITLE]?.value

  const landlordText = addendum.fields?.[AddendumFields.LANDLORD_NAME]?.value
  const tenantText = addendum.fields?.[AddendumFields.ORIGINAL_TENANT_NAME]?.value
  const backgroundAndReason = addendum.fields?.[AddendumFields.BACKGROUND_AND_PURPOSE]?.value

  const conditionsOfTransport = addendum.fields?.[AddendumFields.CONDITIONS_OF_TRANSPORT]?.value
  const derogation = addendum.fields?.[AddendumFields.DEROGATION]?.value
  const otherProvisions = addendum.fields?.[AddendumFields.OTHER_PROVISIONS]?.value

  const tenantName = addendum.fields?.[AddendumFields.TENANT_NAME]?.value
  const tenantOrganizationNo = addendum.fields?.[AddendumFields.TENANT_ORGANIZATION_NUMBER]?.value
  const tenantAddress = addendum.fields?.[AddendumFields.TENANT_ADDRESS]?.value
  const tenantPostalAddress = addendum.fields?.[AddendumFields.TENANT_POSTAL_ADDRESS]?.value
  const tenantInvoiceAddress = addendum.fields?.[AddendumFields.TENANT_INVOICE_ADDRESS]?.value

  const tenantContactFirstName = addendum.fields?.[AddendumFields.TENANT_CONTACT_PERSON_FIRST_NAME]?.value
  const tenantContactLastName = addendum.fields?.[AddendumFields.TENANT_CONTACT_PERSON_LAST_NAME]?.value
  const tenantContactPhoneNumber = addendum.fields?.[AddendumFields.TENANT_CONTACT_PERSON_PHONE_NUMBER]?.value
  const tenantContactEmail = addendum.fields?.[AddendumFields.TENANT_CONTACT_PERSON_EMAIL]?.value

  return <div className={`${styles.contract}`}>
    <div className={`${styles.innerContract} contract-centered`}>
      <Clause title={i18n.t('contract.addendums.title')}>
        <SubClause>
          <TextField
            name={AddendumFields.CONTRACT_TITLE}
            value={contractTitle}
            onChange={value => triggerChanges.merge({
              [AddendumFields.CONTRACT_TITLE]: { value }
            })}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.between')}>
        <SubClause title={i18n.t('contract.general.landlord')}>
          <div>{landlordText}</div>
        </SubClause>
        <SubClause title={i18n.t('contract.general.tenant')}>
          <div>{tenantText}</div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.background_and_reason')} index="1.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={backgroundAndReason}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.BACKGROUND_AND_PURPOSE]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.template.transport.new_tenant')} index="2.">
        <SubClause>
          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_NAME}`)}
              name={AddendumFields.TENANT_NAME}
              required={true}
              value={tenantName}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.TENANT_NAME}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.TENANT_NAME]: { value }
              })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_ORGANIZATION_NUMBER}`)}
              name={AddendumFields.TENANT_ORGANIZATION_NUMBER}
              required={true}
              value={tenantOrganizationNo}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.TENANT_ORGANIZATION_NUMBER}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.TENANT_ORGANIZATION_NUMBER]: { value }
              })}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_ADDRESS}`)}
              name={AddendumFields.TENANT_ADDRESS}
              required={true}
              value={tenantAddress}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.TENANT_ADDRESS}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.TENANT_ADDRESS]: { value }
              })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_POSTAL_ADDRESS}`)}
              name={AddendumFields.TENANT_POSTAL_ADDRESS}
              required={true}
              value={tenantPostalAddress}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.TENANT_POSTAL_ADDRESS}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.TENANT_POSTAL_ADDRESS]: { value }
              })}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_INVOICE_ADDRESS}`)}
              name={AddendumFields.TENANT_INVOICE_ADDRESS}
              required={true}
              value={tenantInvoiceAddress}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.TENANT_INVOICE_ADDRESS}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.TENANT_INVOICE_ADDRESS]: { value }
              })}
            />
          </div>
        </SubClause>
        <SubClause title={i18n.t('contract.general.tenant_contact')}>
          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_CONTACT_PERSON_FIRST_NAME}`)}
              name={AddendumFields.TENANT_CONTACT_PERSON_FIRST_NAME}
              required={true}
              value={tenantContactFirstName}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.TENANT_CONTACT_PERSON_FIRST_NAME}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.TENANT_CONTACT_PERSON_FIRST_NAME]: { value }
              })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_CONTACT_PERSON_LAST_NAME}`)}
              name={AddendumFields.TENANT_CONTACT_PERSON_LAST_NAME}
              required={true}
              value={tenantContactLastName}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.TENANT_CONTACT_PERSON_LAST_NAME}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.TENANT_CONTACT_PERSON_LAST_NAME]: { value }
              })}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_CONTACT_PERSON_PHONE_NUMBER}`)}
              name={AddendumFields.TENANT_CONTACT_PERSON_PHONE_NUMBER}
              value={tenantContactPhoneNumber}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.TENANT_CONTACT_PERSON_PHONE_NUMBER}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.TENANT_CONTACT_PERSON_PHONE_NUMBER]: { value }
              })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_CONTACT_PERSON_EMAIL}`)}
              name={AddendumFields.TENANT_CONTACT_PERSON_EMAIL}
              required={true}
              value={tenantContactEmail}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.TENANT_CONTACT_PERSON_EMAIL}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.TENANT_CONTACT_PERSON_EMAIL]: { value }
              })}
            />
          </div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.template.transport.conditions_of_transport')} index="3.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={conditionsOfTransport}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.CONDITIONS_OF_TRANSPORT]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.general.derogation')} index="4.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={derogation}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.DEROGATION]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.other_provisions')} index="5.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={otherProvisions}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.OTHER_PROVISIONS]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>
    </div>
  </div>
}

const AddendumTransportReadOnlyComponent: view = ({
  addendum = get.contract.originalv2
}) => {

  const contractTitle = addendum.value().fields?.[AddendumFields.CONTRACT_TITLE]?.value

  const landlordText = addendum.value().fields?.[AddendumFields.LANDLORD_NAME]?.value
  const tenantText = addendum.value().fields?.[AddendumFields.TENANT_NAME]?.value
  const backgroundAndReason = addendum.value().fields?.[AddendumFields.BACKGROUND_AND_PURPOSE]?.value

  const conditionsOfTransport = addendum.value().fields?.[AddendumFields.CONDITIONS_OF_TRANSPORT]?.value
  const derogation = addendum.value().fields?.[AddendumFields.DEROGATION]?.value
  const otherProvisions = addendum.value().fields?.[AddendumFields.OTHER_PROVISIONS]?.value

  const tenantName = addendum.value().fields?.[AddendumFields.TENANT_NAME]?.value
  const tenantOrganizationNo = addendum.value().fields?.[AddendumFields.TENANT_ORGANIZATION_NUMBER]?.value
  const tenantAddress = addendum.value().fields?.[AddendumFields.TENANT_ADDRESS]?.value
  const tenantPostalAddress = addendum.value().fields?.[AddendumFields.TENANT_POSTAL_ADDRESS]?.value
  const tenantInvoiceAddress = addendum.value().fields?.[AddendumFields.TENANT_INVOICE_ADDRESS]?.value

  const tenantContactFirstName = addendum.value().fields?.[AddendumFields.TENANT_CONTACT_PERSON_FIRST_NAME]?.value
  const tenantContactLastName = addendum.value().fields?.[AddendumFields.TENANT_CONTACT_PERSON_LAST_NAME]?.value
  const tenantContactPhoneNumber = addendum.value().fields?.[AddendumFields.TENANT_CONTACT_PERSON_PHONE_NUMBER]?.value
  const tenantContactEmail = addendum.value().fields?.[AddendumFields.TENANT_CONTACT_PERSON_EMAIL]?.value

  return <div className={`${styles.contract}`}>
    <div className={`${styles.innerContract} contract-centered`}>
      <Clause title={i18n.t('contract.addendums.title')}>
        <SubClause>
          <TextFieldReadOnly
            value={contractTitle}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.between')}>
        <SubClause title="Landlord">
          <div>{landlordText}</div>
        </SubClause>
        <SubClause title="Tenant">
          <div>{tenantText}</div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.background_and_reason')} index="1.">
        <SubClause>
          <TextEditor
            value={backgroundAndReason}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.template.transport.new_tenant')} index="2.">
        <SubClause>
          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_NAME}`)}
              value={tenantName}
            />

            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_ORGANIZATION_NUMBER}`)}
              value={tenantOrganizationNo}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_ADDRESS}`)}
              value={tenantAddress}
            />

            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_POSTAL_ADDRESS}`)}
              value={tenantPostalAddress}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_INVOICE_ADDRESS}`)}
              value={tenantInvoiceAddress}
            />
          </div>
        </SubClause>
        <SubClause title={i18n.t('contract.general.tenant_contact')}>
          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_CONTACT_PERSON_FIRST_NAME}`)}
              value={tenantContactFirstName}
            />

            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_CONTACT_PERSON_LAST_NAME}`)}
              value={tenantContactLastName}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_CONTACT_PERSON_PHONE_NUMBER}`)}
              value={tenantContactPhoneNumber}
            />

            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.TENANT_CONTACT_PERSON_EMAIL}`)}
              value={tenantContactEmail}
            />
          </div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.template.transport.conditions_of_transport')} index="3.">
        <SubClause>
          <TextEditorReadOnly
            value={conditionsOfTransport}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.general.derogation')} index="4.">
        <SubClause>
          <TextEditorReadOnly
            value={derogation}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.other_provisions')} index="5.">
        <SubClause>
          <TextEditor
            value={otherProvisions}
          />
        </SubClause>
      </Clause>
    </div>
  </div>
}
