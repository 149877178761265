import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";

export const NoPartiesBar: view = () => {
  return (
    <div className=" bg-pink-100 flex px-6 py-4 mt-2">
      <div className="flex flex-auto items-center ">
        <SVGs.ExclamationCircleUnfilled className="mr-2" />
        <div className="ml-3">
          <div className="">{i18n.t("parties_page.no_parties_warning")}.</div>
        </div>
      </div>
    </div>
  );
};
