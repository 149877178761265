import Big from "big.js";

export const storageTotalArea: producer = ({
  storagePropertyExclusiveArea = observe.legacyContract.values.storagePropertyExclusiveArea.displayedValue,
  storagePropertyCommonArea = observe.legacyContract.values.storagePropertyCommonArea.displayedValue,
  oldValue = get.legacyContract.values.storagePropertyTotalArea.displayedValue,

  storagePropertyTotalArea = update.legacyContract.currentSession.changes.storagePropertyTotalArea,
}) => {
  if (!storagePropertyExclusiveArea || !storagePropertyCommonArea) {
    return;
  }
  oldValue = oldValue.value();
  const value = new Big(storagePropertyCommonArea).plus(new Big(storagePropertyExclusiveArea)).toNumber();
  if (oldValue == value) {
    return;
  }
  storagePropertyTotalArea.set({
    value: value,
    createdAt: Date.now(),
    custom: false,
  });
};
