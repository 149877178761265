import { UpdatesFeed } from "./UpdatesFeed";
import { WelcomeCard } from "./WelcomeCard";
import { SendMessageCard } from "./SendMessageCard";
import { ContractStatus } from "src/types";

export const Sidebar: view = ({ status = observe.contract.original.state.status }) => {
  const isntDraft = status !== ContractStatus.DRAFT;
  return (
    <aside
      className="fixed w-1/3 right-0 bottom-0 top-0 pb-10 flex flex-col border-l border-gray-300 bg-gray-210"
      style={{ marginTop: 115 }}>
      {/* col-reverse is there for scroll to bottom */}

      <div className="flex-grow overflow-y-auto " style={{ paddingBottom: 180 }}>
        <div id="here" className="relative flex flex-col-reverse">
          {isntDraft && <UpdatesFeed />}
          <WelcomeCard />
        </div>
      </div>
      {isntDraft && <SendMessageCard />}
    </aside>
  );
};
