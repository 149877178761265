import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";
import { TooltipWrapper } from "src/components";
import { SVGs } from "src/assets";

export const ManagerNegotiationButton: view = ({
  showModal = update.modal.generateContract,
  displayedVersion = observe.legacyContract.displayedVersion,
  invalidFields = observe.legacyContract.invalidFields,
  getAcceptTerms = observe.legacyContract.values.acceptTerms.displayedValue,
}) => {
  const hasntAcceptedTerms = getAcceptTerms !== "true";
  const hasInvalidFields = !!invalidFields && Object.keys(invalidFields).length != 0;
  const isDisabled = displayedVersion || hasInvalidFields || hasntAcceptedTerms;
  let tooltipText;
  if (hasntAcceptedTerms) tooltipText = i18n.t("legacy.accept_terms_manager_unchecked");
  if (displayedVersion) {
    tooltipText = i18n.t("button_generate_contract_oldVersion_tooltip");
  }
  if (hasInvalidFields) tooltipText = i18n.t("contract_navbar.button_send_contract_tooltip_invalid_fields");

  return (
    <TooltipWrapper disabled={!isDisabled} text={tooltipText}>
      <Button className="uppercase" variant="primary" isDisabled={isDisabled} onClick={() => showModal.set(true)}>
        <SVGs.GenerateContractGearPage key="1" className="mr-2" />
        {i18n.t("button_generate_contract")}
      </Button>
    </TooltipWrapper>
  );
};
