import React from "react";

import i18n from "src/producers/languages/i18n";
import {
  FactoryFormField
} from "src/pages/Contract/subviews/ContractForm/fields/FactoryFormField";
import { RadioButton, RadioButtonGroup } from "@c11/components";

export const ManagerSecurityClause: view = ({
  value,
  setSecurityAlternative = update.contract.toSave.changes.securityAlternative,
  securityAlternativeAditionalInfo = observe.contract.values.securityAlternativeAditionalInfo.displayedValue,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
}) => {
  function handleClick(c: any) {
    setSecurityAlternative.set({ value: c + 1, createdAt: Date.now() });
  }

  return (
    <div className="flex flex-col">
      <RadioButtonGroup selectedValue={String(value)}>
        <RadioButton key={`0`} value={"1"} isDisabled={isReadOnlyContract}
                     onClick={() => handleClick(0)}>
          <p className="mr-4">{i18n.t("clause_security_alternative_1_text")}</p>
        </RadioButton>
        {value === 1 && (
          <div key="hasSecurity"
               className="ml-4 flex flex-wrap items-center mt-2 mb-5">
            <FactoryFormField
              key="0"
              disabled={value !== 1}
              fieldName="securityAlternativeAditionalInfo"
              varName="securityAlternativeAditionalInfo"
            />
            {securityAlternativeAditionalInfo === "securityAlternativeAditionalInfo_o5" && (
              <div key="00" className="w-full">
                <FactoryFormField
                  key="7"
                  fieldName="securityAlternativeAditionalInfoOther"
                  varName="securityAlternativeAditionalInfoOther"
                />
              </div>
            )}
            <p className="pr-3" key="1">
              {i18n.t("clause_security_sum_text")}
            </p>
            <div className="flex-auto" key="2">
              <FactoryFormField key="23" fieldName="securityAmount"
                                varName="securityAmount"/>
            </div>
            <p key="3" className="px-3">
              {i18n.t("clause_security_deadline")}
            </p>
            <div key="4">
              <FactoryFormField key="7" fieldName="securityDeadline"
                                varName="securityDeadline"/>
            </div>
          </div>
        )}
        <RadioButton
          key={`1`}
          className="mt-2 mb-5"
          value={"2"}
          isDisabled={isReadOnlyContract}
          onClick={() => handleClick(1)}>
          {`${i18n.t("clause_security_alternative_2_text")} `}
        </RadioButton>
      </RadioButtonGroup>
    </div>
  );
};
