import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const syncUsers: producer = async ({
  id = observe.contract.id,
  setUsers = update.users,
  error = update.errors[param.id],
}) => {
  if (!id) {
    return;
  }
  try {
    const usersSnap = await db.collection("users").get();
    const users: any = {};
    usersSnap.forEach((doc) => {
      const user = doc.data();
      if (!user) return;
      users[user.uid] = user.data;
    });
    setUsers.set(users);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncUsers" }
    );
  }
};
