export const localLandlords = [
  {
    "id": "1",
    "firstName": "Vlad",
    "lastName": "C",
    "phone": "+4790831882",
    "email": "vlad@code11.com",
    "position": "Direktør Hotell og Kjøpesenterforvaltning",
    "region": [
      "Oslo",
      "Trondheim"
    ]
  },
  {
    "id": "2",
    "firstName": "Alex",
    "lastName": "G",
    "phone": "+4790722301",
    "email": "alexandru.gaman@code11.com",
    "position": "Eiendomssjef",
    "region": [
      "Oslo"
    ]
  },
  {
    "id": "4",
    "firstName": "Gard",
    "lastName": "R",
    "phone": "+4799572117",
    "email": "gard@code11.com",
    "position": "Eiendomsforvalter",
    "region": [
      "Oslo"
    ]
  },
  {
    "id": "5",
    "firstName": "Truong",
    "lastName": "L",
    "phone": "+4790123140",
    "email": "truong.van.thanh.le@klp.no",
    "position": "Eiendomsforvalter",
    "region": [
      "Oslo"
    ]
  }
]



