import { db } from "src/producers/firebase";
import { UserRole } from "src/types";

export const tenantHasSeenContract: producer = async ({
  userRole = observe.user.data.role,
  id = observe.legacyContract.id,
  lastSeenFeature = observe.featureFlags.lastSeenFeature.main,
}) => {
  if (!lastSeenFeature) return;
  if (!id || userRole != UserRole.TENANT) return;
  try {
    await db.collection("contracts").doc(id).update({ lastSeenByTenant: new Date() });
  } catch (e) {
    console.error("tenantHasSeenContract", e);
  }
};
