import { Spinner } from "@c11/components";
import { PaperContractComponent } from "./PaperContractComponent";

import styles from "../styles.module.css";
import { ContractV2 } from "../contract.types";

export const PaperContract: view = ({
  originalContract = observe.contract.originalv2,
}) => {
    if (!originalContract) return <Spinner className={`${styles.contractSpinner}`} />;
    const contract = (originalContract.state) as ContractV2;

    return (<div className="overflow-auto pb-20 h-screen">
        <PaperContractComponent />
    </div>);
};
