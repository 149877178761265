import React from "react";

import { ContractStatus, UserRole } from "src/types";
import { DraftButton } from "./DraftButton";
import { ManagerNegotiationButton } from "./ManagerNegotiationButton";
import { TenantContractNavbarButton } from "./TenantContractNavbarButton";
import { ManagerSigningButton } from "./ManagerSigningButton";

export const ContractNavbarButton: view = ({
  status = observe.legacyContract.original.status,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isTenant = userRole.value() === UserRole.TENANT;
  const isDraft = status === ContractStatus.DRAFT;
  const isNegotiation = status === ContractStatus.NEGOTIATION;
  const isSigning = status === ContractStatus.SIGNING;

  if (isTenant) {
    return <TenantContractNavbarButton />;
  }

  if (isManager) {
    if (isDraft) return <DraftButton />;
    if (isNegotiation) return <ManagerNegotiationButton />;
    if (isSigning) return <ManagerSigningButton />;
  }
  return null;
};
