import { ContractStatus } from "src/types";

export const showReEditContractModal: producer = ({
  changes = observe.legacyContract.currentSession.changes,
  status = observe.legacyContract.original.status,
  showModal = update.modal.reEditContract,
}) => {
  const hasChanges = !!changes && Object.keys(changes).length > 0;
  const isSigning = status === ContractStatus.SIGNING;

  if (hasChanges && isSigning) showModal.set(Date.now());
};
