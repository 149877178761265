import React from "react";

import i18n from "src/producers/languages/i18n";
import { InsideCard } from "../..";
import { FactoryFormField } from "../../fields/formFields/FactoryFormField";

export const TenantSignatoryCard: view = ({
  i,
  onPlusOneClick,
  onRemoveClick,
  isLast,
  setSignatory = update.legacyContract.currentSession.changes[param.field],
}) => {
  return (
    <InsideCard key={"Tenant" + i} title={`${i18n.t("legacy.signatory_tenant_title")} #${i + 1}`}>
      <FactoryFormField key="0" varName={`signatory${i + 1}TenantFirstName`} fieldName={`signatoryTenantFirstName`} />
      <FactoryFormField key="1" varName={`signatory${i + 1}TenantLastName`} fieldName={`signatoryTenantLastName`} />
      <FactoryFormField key="2" varName={`signatory${i + 1}TenantPosition`} fieldName={`signatoryTenantPosition`} />
      <FactoryFormField key="3" varName={`signatory${i + 1}TenantPhone`} fieldName={`signatoryTenantPhone`} />
      <FactoryFormField key="4" varName={`signatory${i + 1}TenantEmail`} fieldName={`signatoryTenantEmail`} />
      <div key="b1" className="flex justify-between w-full">
        {isLast && (
          <button
            key="button1"
            className="my-4 border-b font-14 uppercase font-medium border-pink-600"
            onClick={onPlusOneClick}>
            {i18n.t("legacy.signatory_add_tenant")}
          </button>
        )}
        {/* don't show if is he first card */}
        {isLast && i !== 0 && (
          <button
            key="button2"
            className="my-4 border-b font-14 uppercase font-medium border-pink-600"
            onClick={() => {
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}TenantFirstName` });
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}TenantLastName` });
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}TenantPosition` });
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}TenantPhone` });
              setSignatory.set({ value: "", createdAt: Date.now() }, { field: `signatory${i + 1}TenantEmail` });
              onRemoveClick();
            }}>
            {i18n.t("legacy.signatory_remove")}
          </button>
        )}
      </div>
    </InsideCard>
  );
};
