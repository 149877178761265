import i18n from 'src/producers/languages/i18n'
import { Modal } from 'src/components'
import { sendSigningNotification } from './sendSigningNotification'
import { SigningNotivicationModal } from 'src/assets/svg'

export const SendSigningNotificationModal: view = ({
  trigger = observe.modal.sendSigningNotification,
  text = get.views[prop._viewId].text.value(),
  displayName = get.user.data.displayName.value(),
  setMessage = update.views[prop._viewId].text,
  sendSigningNotification = update.contract.triggers.sendSigningNotification,
}) => {
  if (!trigger) return null
  const message =
    text ||
    i18n.t('summary_page.signing_notification_modal_text', {
      name: displayName,
    })
  return (
    <Modal
      title={`${i18n.t('summary_page.signing_notification_modal_title')} ${trigger.fullName}`}
      okButtonText={i18n.t('contract_navbar.send_to_signing')}
      image={() => <SigningNotivicationModal />}
      onOkClick={() => {
        sendSigningNotification.set({
          message,
          recipientId: trigger.recipientId,
        })
      }}
      okButtonDisabled={false}>
      <textarea
        className="w-full resize-none bg-gray-200 p-4 border border-gray-500"
        defaultValue={message}
        onChange={(e: any) => setMessage.set(e.target.value)}
      />
    </Modal>
  )
}

SendSigningNotificationModal.producers([sendSigningNotification])
