import {
  ApplicationError,
  ContractStatus,
  ErrorLevel,
  ErrorType,
  UserRole
} from 'src/types'
import { CommandActions } from 'src/types/commands/command'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'

export const saveVersion: producer = async ({
  user = observe.user,
  rawUser = get.rawUser,
  localFunctions = get.localFunctions,
  trigger = observe.contract.triggers.saveVersion,
  removeTrigger = update.contract.triggers.saveVersion,
  contractId = observe.contract.id,

  recipients = get.contract.original.recipients,
  status = get.contract.original.state.status,

  resetCurrentSession = update.contract.currentSession,
  clearSessions = update.contract.triggers.clearSessions,
  error = update.errors[param.id],
  stateTransition = update.contract.loading.stateTransition,
}) => {
  status = status.value()

  if (!trigger || user.data.role !== UserRole.MANAGER || status !== ContractStatus.NEGOTIATION) {
    return
  }
  stateTransition.set(true)
  //TODO make this clearer that it's message for notification
  const payload = trigger.payload
  Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key])
  try {
    const command = {
      action: CommandActions.CREATE_VERSION,
      contractId,
      ...payload,
      recipients: recipients.value(),
    }

    await axios.post(
      getFunctionsUrl('commandPublisher', localFunctions.value()),
      command,
      {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type': 'application/json'
        }
      }
    )

    clearSessions.set(Date.now())
    resetCurrentSession.remove()

    console.log('All done')
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'saveVersion-contract' }
    )
  } finally {
    removeTrigger.remove()
  }
}
