import { VisibleIf } from "src/components_generic/visible-if";
import { HeaderSection } from "src/pages/Contract/HeaderSection/HeaderSection";
import i18n from "src/producers/languages/i18n";
import { AddAttachmentButton } from "./AddAttachmentButton";
import { AttachmentsListElement } from "./AttachmentsListElement";
import { Tooltip } from "antd";
import { SVGs } from "src/assets";

import "./AttachmentsTab.css";
import { functions } from "src/producers/firebase";
import { ErrorLevel, ErrorType } from "src/types";

export const Attachments: view = ({
  attachmentsUIData = observe.contract.ui.attachments,
  isLocked = observe.contract.isLocked,

  updateAttachmentsToRemove = update.contract.changes.attachmentsToRemove,
  updateReorderedAttachment = update.contract.changes.reorderedAttachment,
  updateAttachmentToIncludeInSigning = update.contract.changes.attachments.includeInSigning,

  updateErrors = update.errors[param.id],
}) => {

  return <>
    <HeaderSection title={i18n.t("contract_navbar.attachments")}>
      <VisibleIf predicate={!isLocked}>
        <AddAttachmentButton />
      </VisibleIf>
    </HeaderSection>

    {/* <VisibleIf predicate={!attachmentsUIData?.count}>
      <NoAttachmentsBar />
    </VisibleIf> */}

    <VisibleIf predicate={attachmentsUIData?.count > 0}>
      <div className="flex-grow flex flex-col align-center mt-8">
        <div className="attachments-list-header">
          <h5 className="attachments-tab-list-item-subtitle attachments-name">{i18n.t('contract.attachments.file-name')}</h5>
          <div className="attachments-tab-list-item-subtitle attachments-header-right">
            <h5 className="attachments-tab-list-item-subtitle">{i18n.t('contract.attachments.file-type')}</h5>
            <div className="attachments-tab-list-item-subtitle flex items-center gap-2">
              <div>{i18n.t('contract.attachments.include-in-signing')}</div>
              <Tooltip placement={"bottom"} title={i18n.t("attachments_page.card_tooltip_text")}>
                <SVGs.HelpCircleGray />
              </Tooltip>
            </div>
          </div>
        </div>
        {
          attachmentsUIData?.orderSortedArray.map((item, index) =>
            <AttachmentsListElement
              key={item.id}
              index={index + 1}
              name={item.name}
              type={item.type.replace('application/', '')}
              user={item.user}
              includeInSigning={item.selected}
              isLocked={isLocked}
              isUserAttachment={item.isUserAttachment || false}
              isFirstElement={attachmentsUIData?.count < 2 || item.order === attachmentsUIData?.orderOfFirstElement}
              isLastElement={attachmentsUIData?.count < 2 || item.order === attachmentsUIData?.orderOfLastElement}
              onDelete={() => {
                updateAttachmentsToRemove.set([item.id]);
              }}
              onIncludeInSigningToggled={() => {
                updateAttachmentToIncludeInSigning.set({
                  id: item.id,
                  selected: !item.selected
                });
              }}
              onPreview={async () => {
                const ref = window.open()
                try {
                  const previewFile = functions.httpsCallable('previewFile')
                  // updatePdfServerLoading.set(true)
                  ref?.document.write('loading')
                  const resp = await previewFile({ path: item.path, id: item.id })
                  ref.location = resp.data
                } catch (error) {
                  ref.close()
                  console.error('Error on preview pdf: ', error)
                  updateErrors.set(
                    {
                      message: 'preview-pdf-error',
                      level: ErrorLevel.WARNING,
                      type: ErrorType.SERVER,
                    },
                    { id: 'previewPDF' }
                  )
                }
                // updatePdfServerLoading.set(false)

              }}
              onReorderUpClick={() => {
                updateReorderedAttachment.set({
                  id: item.id,
                  direction: 'up'
                })
              }}
              onReorderDownClick={() => {
                updateReorderedAttachment.set({
                  id: item.id,
                  direction: 'down'
                })
              }}
            />)
        }
      </div>
    </VisibleIf>
  </>
};

const NoAttachmentsBar: view = () => {
  return (
  <div className="bg-gray-100 flex px-6 py-4 mt-2">
    <div className="flex flex-auto items-center ">
      <SVGs.InfoIcon className="mr-2" />
      <div className="ml-3">
        <div className="">{i18n.t("contract.attachments.no-attachments")}.</div>
      </div>
    </div>
  </div>
  );
};
