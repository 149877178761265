import { Button } from "@c11/components.button";
import "./AttachmentsCard.css";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { PaperclipIcon } from "src/assets/svg";
import { ContractPageSubview, ContractStatus, RouteName } from "src/types";
import { ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from "src/components";

import styles from "../../../styles.module.css";

export const AttachmentsCard: view = ({
  setSubview = update.routes.list[RouteName.CONTRACT].data.subview,
  appendices = observe.contract.original.state.attachments,
  status = observe.contract.original.state.status,
}) => {
  const isSigning = status === ContractStatus.SIGNING;
  const isActive = status === ContractStatus.ACTIVE;
  const isSigningorActive = isSigning || isActive;
  return (
    <>
      <ThumbnailCard className={`${styles.contractSummaryCard} ${isSigningorActive && styles.borderedCard}`}>
        <ThumbnailCardHeader
          className={`${styles.summaryCardsHeader}`}
          key="tch-1"
          title={i18n.t("contract_navbar.attachments")}
          separator={true}>
          <div className="float-right inline-block">
            <SVGs.OverviewAttachments />
          </div>
        </ThumbnailCardHeader>
        <ThumbnailCardBody key="tcb-1">
          <div className="pb-3 overflow-auto">
            {appendices &&
              Object.values(appendices).map((a: any, index: any) => {
                if (a.selected)
                  return (
                    <div key={index} className={`${styles.summaryList + " " + styles.attachments}`}>
                      <SVGs.OverviewDocument />
                      <span className="truncate">{a.fileName}</span>
                    </div>
                  );
              })}
          </div>
        </ThumbnailCardBody>
        <ThumbnailCardFooter key="tcf-1">
          <Button
            className="view-attachments-btn border-secondary second-theme-color"
            variant="secondary"
            onClick={() => {
              setSubview.set(ContractPageSubview.ATTACHMENTS);
            }}>
            <PaperclipIcon fill="none" stroke="currentColor" />
            <span key="2" className="pl-2 uppercase">
              {i18n.t("summary_page.view_attachments")}
            </span>
          </Button>
        </ThumbnailCardFooter>
      </ThumbnailCard>
    </>
  );
};
