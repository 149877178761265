import React from "react";

import { UserRole } from "src/types";
import { FormField } from "./FormField";
import { PlainTextField } from "./PlainTextField";

export const FactoryFormField: view = ({
  fieldName,
  varName,
  templateField = observe.contract.fields[arg.fieldName],
  value = observe.contract.values[arg.varName].displayedValue,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  disabled,
  userRole = get.user.data.role,
}) => {
  if (!templateField) return null;
  const { readOnlyForTenant, hiddenFromTenantIfEmpty } = templateField;

  const isTenant = userRole.value() === UserRole.TENANT;
  const isHidden = hiddenFromTenantIfEmpty && isTenant && !value;
  const readOnly = disabled || isReadOnlyContract;
  const displayedAsPlainText = readOnlyForTenant && isTenant;

  if (isHidden) return null;
  if (displayedAsPlainText) return <PlainTextField {...{ fieldName, varName }} />;
  return <FormField {...{ fieldName, varName, readOnly }} />;
};
