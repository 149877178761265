import { db } from 'src/producers/firebase'
import {ApplicationError, ErrorLevel, ErrorType, LogLevels, UserRole} from 'src/types'

export const persistMiscellaneous: producer = async ({
  misc = observe.contract.changes.miscellaneous,
  isLocked = get.contract.isLocked,
  contractId = get.contract.id,
  field = get.contract.originalv2.fields[param.varName],

  // timer = get.contract.utils.timers.saveChanges,
  // updateTimer = update.contract.utils.timers.saveChanges,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,

  updateMiscChanges = update.contract.changes.miscellaneous,
  updateFields = update.contract.originalv2.fields,
  error = update.errors[param.id]
}) => {
  if (!misc || Object.keys(misc).length < 1) return
  if (isLocked.value()) return
  contractId = contractId.value()
  isSaving.set(true)
  const updatedAt = new Date().toISOString()
  let fields: any = {}
  let originalFields: any = {}
  for (const varName in misc) {
    fields[varName] = misc[varName]
    originalFields[varName] = field.value({ varName })
  }
  try {
    updateFields.merge(fields)
    await db
      .collection('contracts')
      .doc(contractId)
      .set({ fields, updatedAt }, { merge: true })
    updateMiscChanges.remove()
    successfulSave.set(true)
  } catch (e) {
    console.error('persistMiscellaneousChanges', e)
    updateFields.merge(originalFields)
    failedSave.set(true)
    error.set(
      {
        message: 'saving-building-to-database',
        level: LogLevels.ERROR,
      } as ApplicationError,
      { id: 'persistBuildingChanges' }
    )
  }
  isSaving.set(false)
}
