import FormatNumber from 'format-number'

type NumberFormatterParameters = {
  separatorInteger?: string
  separatorDecimal?: string

  round?: number
}

type NumberFormatterFactory = (params?: NumberFormatterParameters) => ReturnType<typeof FormatNumber>

export const NumberFormatterFactory: NumberFormatterFactory = (params) =>
  FormatNumber({
    integerSeparator: params?.separatorInteger || '.',
    decimalsSeparator: params?.separatorDecimal || ',',
    round: params?.round || 2,
  })

export const NumberFormatterNew = (value: number, params: NumberFormatterParameters = {}) => {
  if(!value) return
  const { separatorInteger = '.', separatorDecimal = ',', round = 2 } = params
  const fractional = value % 1
  const fractionalString = fractional.toFixed(round).toString().split('.')[1]
  value = Math.floor(value)
  const newValue = value.toString()

  const decimal = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, separatorInteger)
  if (fractional === 0)
    return decimal

  return `${decimal},${fractionalString}`
}
