import { FieldInputType } from "src/templates/types/template";
import i18n from "src/producers/languages/i18n";
import { ApplicationError, ErrorLevel, Language } from "src/types";
import _numeral from "src/utils/_numeral";
import moment from "moment";
import { functions } from "src/producers/firebase";

export const getMainContractPDF: producer = async ({
  values = observe.legacyContract.values,
  trigger = observe.legacyContract.triggers.getMainContractPDF,
  removeTrigger = update.legacyContract.triggers.getMainContractPDF,
  apiGeneratePDF = get.config.api.generatePDFURL,
  templateFields = get.legacyContract.fields,
  saveMainPDF = update.legacyContract.pdf.main,
  removeLoading = update.legacyContract.flags.loadingpdf,
  error = update.errors[param.type],
}) => {
  if (!trigger) return;
  removeTrigger.remove();

  if (!values) return;
  let exportedValues: any = {};
  templateFields = templateFields.value();
  Object.keys(values).forEach((v) => {
    const value = values[v]?.displayedValue;
    exportedValues[v] = value;

    //derived values
    if (v === "paymentProvisionFirstPayDueDate") {
      exportedValues.vvv1 = ["Forskuddsvis per kvartal", "Forskuddsvis per måned"][parseInt(value) - 1];
    }
    if (v === "vatAlternative") {
      const percentageVat = values.vatPercent?.displayedValue || "";
      exportedValues.vvv2_0 = percentageVat;
      exportedValues.vvv2 = [
        "Virksomheten vil utelukkende være innenfor merverdiavgiftsområdet",
        "Virksomheten vil utelukkende være utenfor merverdiavgiftsområdet",
        `% av Leietakers virksomhet er innenfor merverdiavgiftsområdet. Målsatte tegninger og opplysninger om størrelsen på arealet som skal omfattes av Utleiers frivillige registrering er inntatt bilag 1.`,
      ][parseInt(value) - 1];
    }
    if (v === "securityAlternative") {
      const securityAlternativeAditionalInfo = values.securityAlternativeAditionalInfo?.displayedValue || "";
      const securityAlterativeText =
        securityAlternativeAditionalInfo === "securityAlternativeAditionalInfo_o5"
          ? values.securityAlternativeAditionalInfoOther?.displayedValue || ""
          : i18n.t(securityAlternativeAditionalInfo, {
              lng: Language.NORWEGIAN,
            });
      let securityAmount = values.securityAmount?.displayedValue || "";
      if (securityAmount != "") securityAmount = _numeral(securityAmount).format("0,0.[00]");

      const securityDeadline = values.securityDeadline?.displayedValue || "";
      exportedValues.vvv3 = [
        `Leietaker stiller garanti i form av <strong>${securityAlterativeText} </strong>. Garantien skal utgjøre <strong>NOK ${securityAmount}</strong> med frist for fremskaffing av sikkerhet <strong>${securityDeadline} </strong>`,
        "Leietakere skal ikke stille garanti",
      ][parseInt(value) - 1];
    }
    const rentalPeriodEorR = values.rentalPeriodEorR?.displayedValue;
    const rentalPeriodOptionsPeriod = values.rentalPeriodOptionsPeriod?.displayedValue;
    if (v === "rentalPeriodHasOptions" && value == "true" && rentalPeriodEorR && rentalPeriodOptionsPeriod) {
      exportedValues.vvv4 = i18n.t("rentalPeriodOptionsPeriod_p1_1", {
        ER:
          rentalPeriodEorR === "R"
            ? i18n.t("clause_rental_period_potential_options_renewal")
            : i18n.t("clause_rental_period_potential_options_extension"),
        lng: Language.NORWEGIAN,
      });
      exportedValues.vvv5 = i18n.t("rentalPeriodOptionsPeriod_p1_3", {
        ER:
          rentalPeriodEorR === "R"
            ? i18n.t("clause_rental_period_potential_options_renewal")
            : i18n.t("clause_rental_period_potential_options_extension"),
        T:
          rentalPeriodEorR === "R"
            ? i18n.t("clause_rental_period_potential_options_renegotiation")
            : i18n.t("clause_rental_period_potential_options_same"),
        lng: Language.NORWEGIAN,
      });
    }

    // replaced values
    if (v === "storeIndustryCode" || v === "rentalPeriodOptionsPeriod") {
      exportedValues[v] = i18n.t(value, { lng: Language.NORWEGIAN });
    }
    if (v === "hasStorageUnit" || v === "signatureOptOut") {
      exportedValues[v] = value == "true";
    }
    // format the numbers
    if (
      templateFields[v]?.inputType === FieldInputType.Number ||
      templateFields[v]?.inputType === FieldInputType.CalculationCustom
    ) {
      exportedValues[v] = _numeral(Number(value)).format("0,0.[00]");
    }
  });
  exportedValues.exportDate = moment().format("DD.MM.YYYY");
  exportedValues = {
    ...exportedValues,
    ...formatDateFields(exportedValues),
  };
  try {
    let response: any;

    const res = await fetch(apiGeneratePDF.value(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(exportedValues),
    });
    response = await res.arrayBuffer();

    saveMainPDF.set(null);
    saveMainPDF.set(response);
  } catch (e) {
    removeLoading.remove();
    console.error("generatePDF", e);
    error.set({ message: "error-generating-pdf", level: ErrorLevel.LOW } as ApplicationError, { type: "pdf" });
  }
};

function formatDateFields(fields: any) {
  const dateFields = ["rentalStart", "rentalEnd", "storeOpening"];
  const formatedFields: any = {};
  for (const field in fields) {
    if (dateFields.includes(field)) {
      formatedFields[field] = moment(fields[field]).format("DD.MM.YYYY");
    }
  }
  return formatedFields;
}
