import React from "react";

import { FactoryFormField } from "../../fields/formFields/FactoryFormField";
import i18n from "src/producers/languages/i18n";
import { CheckboxType3 } from "../../fields/formFields/CheckboxType3";

export const ManagerPotentialOptions: view = ({
  rentalPeriodHasOptions,
  rentalPeriodEorR,
  setRentalPeriodHasOptions = update.legacyContract.currentSession.changes.rentalPeriodHasOptions,
  setRentalPeriodEorR = update.legacyContract.currentSession.changes.rentalPeriodEorR,
}) => {
  return (
    <>
      {/* TODO Try to put this in the template */}
      <p className="mt-2" key="3">
        {i18n.t("clause_rental_period_potential_options")}
      </p>

      <CheckboxType3
        key="11"
        label={i18n.t("clause_rental_period_potential_options_o")}
        checked={rentalPeriodHasOptions === "true"}
        onClick={() =>
          rentalPeriodHasOptions !== "true"
            ? setRentalPeriodHasOptions.set({ value: "true", createdAt: Date.now() })
            : {}
        }
      />
      <CheckboxType3
        key="12"
        label={i18n.t("clause_rental_period_potential_options_na")}
        checked={rentalPeriodHasOptions === "false"}
        onClick={() =>
          rentalPeriodHasOptions !== "false"
            ? setRentalPeriodHasOptions.set({ value: "false", createdAt: Date.now() })
            : {}
        }
      />

      {rentalPeriodHasOptions === "true" && (
        <>
          <div className="flex w-1/2" key="4">
            <CheckboxType3
              key="13"
              label={i18n.t("clause_rental_period_potential_options_extension_u")}
              checked={rentalPeriodEorR === "E"}
              onClick={() =>
                rentalPeriodEorR !== "E" ? setRentalPeriodEorR.set({ value: "E", createdAt: Date.now() }) : {}
              }
            />
            <CheckboxType3
              key="14"
              label={i18n.t("clause_rental_period_potential_options_renewal_u")}
              checked={rentalPeriodEorR === "R"}
              onClick={() =>
                rentalPeriodEorR !== "R" ? setRentalPeriodEorR.set({ value: "R", createdAt: Date.now() }) : {}
              }
            />
          </div>
          <FactoryFormField key="5" fieldName="rentalPeriodOptionsPeriod" varName="rentalPeriodOptionsPeriod" />
        </>
      )}
    </>
  );
};
