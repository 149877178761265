import Big from 'big.js'

export const MinimumTurnOver: producer = ({
  minimumRent = observe.contract.values.minimumRent.displayedValue,
  turnoverPercent = observe.contract.values.turnoverPercent.displayedValue,
  isCustom = observe.contract.values.MTO.custom,
  oldValue = get.contract.values.MTO.displayedValue,
  minimumTurnOver = update.contract.toSave.changes.MTO,
}) => {
  if (isCustom == true || minimumRent === undefined || turnoverPercent === undefined) {
    return
  }
  if (minimumRent === '' || turnoverPercent === '') {
    minimumTurnOver.set({
      value: 0,
      custom: false,
    })
  } else {
    oldValue = oldValue.value()
    const value = new Big(minimumRent).div(turnoverPercent).times(100).round().toNumber()
    if (oldValue == value) {
      return
    }
    minimumTurnOver.set({
      value,
      custom: false,
    })
  }
}
