import moment from "moment";
import React from "react";
import {NumericFormat} from "react-number-format";

import i18n from "src/producers/languages/i18n";

export const SecurityTenant: view = ({
  checkedState,
  securityAmount = observe.legacyContract.values.securityAmount.displayedValue,
  securityDeadline = observe.legacyContract.values.securityDeadline.displayedValue,
  securityAlternativeAditionalInfo = observe.legacyContract.values.securityAlternativeAditionalInfo.displayedValue,
  securityAlternativeAditionalInfoOther = observe.legacyContract.values.securityAlternativeAditionalInfoOther
    .displayedValue,
}) => {
  return (
    <>
      {checkedState[0] && (
        <p>
          <span>{i18n.t("clause_security_alternative_1_text") + " "}</span>
          {securityAlternativeAditionalInfo === "securityAlternativeAditionalInfo_o5" ? (
            <span className="font-semibold">{i18n.t(securityAlternativeAditionalInfoOther) + ". "}</span>
          ) : (
            <span className="font-semibold">{i18n.t(securityAlternativeAditionalInfo) + ". "}</span>
          )}
          <span>{i18n.t("clause_security_sum_text") + ": "}</span>
          <span className="font-semibold">
            <NumericFormat
              key={"1"}
              defaultValue={securityAmount}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              // isNumericString={true}
            />
          </span>
          <span>{" " + i18n.t("clause_security_deadline") + ": "}</span>
          <span className="font-semibold">{securityDeadline ? moment(securityDeadline).format("DD.MM.YYYY") : ""}</span>
        </p>
      )}
      {checkedState[1] && <p>{i18n.t("clause_security_alternative_2_text")}</p>}
    </>
  );
};
