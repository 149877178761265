import React from 'react'

export const VisibleIfInvalidField: view = ({
  name, children,
  showDefaultMessage,

  invalidField = observe.contract.invalidFields[arg.name]
}) => {
  if (!invalidField) return null
  // if (!invalidField) return null

  if (!!showDefaultMessage)
    return <div>{invalidField}</div>

  return <>
    {children}
  </>
}
