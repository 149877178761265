import i18n from "src/producers/languages/i18n";
import { db } from "src/producers/firebase";
import { Language, ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const changeCurrentLanguage: producer = async ({
  trigger = observe.languages.triggers.change,
  triggerUpdate = update.languages.triggers.change,
  updateCurrentLang = update.languages.current,
  currentLanguage = get.languages.current,
  user = get.user,
  error = update.errors[param.id],
}) => {
  currentLanguage = currentLanguage.value();
  user = user.value();
  if (!trigger || !currentLanguage || !user?.uid || !user?.data) {
    return;
  }
  const userId = user.uid;
  const userLang = user.data.language;
  try {
    // if trigger language is wrong, fallback to norwegian
    const newLanguage = Object.values(Language).includes(trigger) ? trigger : Language.NORWEGIAN;

    await i18n.changeLanguage(newLanguage);
    if (newLanguage !== userLang) {
      const usersCollection = db.collection("users");
      const user = await usersCollection.doc(userId).get();
      if (user.exists) {
        usersCollection.doc(userId).set(
          {
            data: {
              language: newLanguage,
            },
          },
          { merge: true }
        );
      }
    }
    updateCurrentLang.set(newLanguage);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.APPLICATION,
      } as ApplicationError,
      { id: "changeCurrentLanguage" }
    );
  } finally {
    triggerUpdate.remove();
  }
};
