import { MenuProps } from 'antd'

type RenewalPeriodDropdownItemsParams = {
  onClick: (key: number) => void
}

export function RenewalPeriodDropdownItemsFactory(params: RenewalPeriodDropdownItemsParams): MenuProps['items'] {
  const labels: any = []

  for (let i = 0; i < 10; i++) {
    labels[i] = {
      key: i + 1,
      label: (
        <div className="flex-grow" onClick={(_) => params.onClick(i + 1)}>
          {i + 1}
        </div>
      ),
    }
  }

  return labels
}
