import styles from "../styles.module.css";
import { Checkbox } from "@c11/components";
import { Divider, Tag } from "antd";
import { SearchIcon } from "src/assets/svg";
import "src/pages/Dashboard/ClientFilter/ClientFilter.css";
import i18n from "src/producers/languages/i18n";
import { DashboardFilters } from "src/types";
import { SearchSelect } from "src/components/SearchSelect/SearchSelect";

export const ClientFilter: view = ({
  filteredTenants = observe.pages.dashboard.filteredTenants,
  currentValue = observe.pages.dashboard.filters[DashboardFilters.CLIENT],
  updateCurrentValue = update.pages.dashboard.filters[DashboardFilters.CLIENT],
  triggerGetContracts = update.pages.dashboard.triggers.getContracts,
  searchDropdownsFf = observe.featureFlags.dashboardSearchDropdowns.main,
  dashboardFiltersFlag = observe.featureFlags.dashboardFilters.main,
}) => {
  let clients: any[] = [];
  if (filteredTenants?.data) {
    clients = Object.values(filteredTenants.data);
    // bellow is needed in order to put the selected one on top of the list;
    // filtering is done here in front-end so no elegant sorting capabilities
    if (currentValue) {
      const currentValueIndex = clients.findIndex((client) => client.id === currentValue.id);
      if (currentValueIndex !== -1) {
        clients.splice(currentValueIndex, 1);
      }
      clients.splice(0, 0, currentValue);
    }
  }

  return (
    <>
      {searchDropdownsFf && (
        <div>
          <label>{i18n.t("dashboard.filter_client_default")}</label>
          <div className={`${styles.filterContainer}`}>
            <SearchSelect
              items={clients}
              placeholder={i18n.t("dashboard.filter_client_default")}
              searchOptions={{ keys: ["name"] }}
              onSearch={(ev) => console.log(`searchDropdown.onSearch: event.target = ${JSON.stringify(ev.target)}`)}
              onSelect={(ev) => {
                console.log(`searchDropdown.onSelect: event.target = ${JSON.stringify(ev.target)}`);
                const selectedLocation = ev.target.value;
                if (currentValue?.id !== selectedLocation?.id) {
                  updateCurrentValue.set(selectedLocation);
                } else {
                  updateCurrentValue.remove();
                }
                // TODO: maybe find a better way to trigger get contracts
                triggerGetContracts.set(Date.now());
              }}
            />
            <SearchIcon/>
          </div>
          {currentValue && (
            <Tag className={`${styles.selectOption}`}
              // Tag's color prop looks strange, used style and custom close icon instead
              closable
              onClose={() => {
                updateCurrentValue.remove();
                triggerGetContracts.set(Date.now());
              }}>
              {currentValue.name}
            </Tag>
          )}
        </div>
      )}
      {dashboardFiltersFlag && (
        <div className={`${styles.filter}`}>
          <div className={`${styles.filterHeader}`}>
            <h3>{i18n.t("dashboard.filter_client_default")}</h3>
            <Divider className={`${styles.divider} `} />
          </div>
          <div className={`${styles.filterContent}`}>
            {clients.map((client: any, index: number) => (
              <Checkbox
                key={`ck-${client.id}`}
                isChecked={currentValue?.id === client.id}
                onClick={() => {
                  if (currentValue?.id != client.id) {
                    updateCurrentValue.set(client);
                  } else {
                    updateCurrentValue.remove();
                  }
                  // TODO: maybe find a better way to trigger get contracts
                  triggerGetContracts.set(Date.now());
                }}>
                <span className={currentValue?.id === client.id ? "text-pink-600 font-semibold" : ""}>
                  {client.name}
                </span>
              </Checkbox>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
