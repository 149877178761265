export const currentSession: producer = ({
  role = observe.user.data.role,
  contractId = observe.legacyContract.id,
  sessions = observe.legacyContract.original.sessions[arg.role],
  setCurrentSession = update.legacyContract.currentSession,
}) => {
  if (!contractId || !sessions) {
    return;
  }
  try {
    setCurrentSession.set(sessions);
  } catch (e) {
    console.error("currentSession", e);
  }
};
