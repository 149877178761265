export const setSigningMethod: producer = async ({
  trigger = observe.contract.triggers.setSigningMethod,
  updateTrigger = update.contract.triggers.setSigningMethod,
  updateCurrentSession = update.contract.currentSession.signing,
  updateSigningMethod = update.contract.original.state.signing,
}) => {
  if (!trigger) {
    return;
  }

  try {
    //
    updateSigningMethod.set(trigger);
    updateCurrentSession.set(trigger);
  } catch (e) {
    console.error("Error setSigningMethod, ", e);
  } finally {
    setTimeout(() => {
      updateTrigger.remove(); // workaround for loading state on send to signing button
    }, 1000);
  }
};
