import moment from "moment";
import { Button } from "@c11/components.button";

import _numeral from "src/utils/_numeral";
import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { ContractPageSubview, ContractStatus, ContractType, RouteName } from "src/types";
import { ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from "src/components";

import styles from "../../../styles.module.css";
import dayjs from "dayjs";

export const ContractCard: view = ({
  setSubview = update.routes.list[RouteName.CONTRACT].data.subview,
  fields = observe.contract.original.state.fields,
  status = observe.contract.original.state.status,
  contractType = get.contract.original.contractType,
}) => {
  //TODO maybe change the hidden logic to something best practice
  contractType = contractType.value();
  const isSigning = status === ContractStatus.SIGNING;
  const isActive = status === ContractStatus.ACTIVE;
  const isSigningorActive = isSigning || isActive;
  return (
    <>
      <ThumbnailCard className={`${styles.contractSummaryCard} ${isSigningorActive && styles.borderedCard}`}>
        <ThumbnailCardHeader
          className={`${styles.summaryCardsHeader}`}
          key="tch-1"
          title={i18n.t("summary_page.contract_card_title")}
          separator={true}>
          <div className="float-right inline-block">
            <SVGs.OverviewContract />
          </div>
        </ThumbnailCardHeader>
        <ThumbnailCardBody key="tcb-1">
          <div className={`${styles.summaryList}`}>
            <span>{`${i18n.t("general.tenant")}:`}</span>
            <span className={`${styles.tenant}`}>{fields?.tenantName?.current?.value || ""}</span>
          </div>
          <div className={`${styles.summaryList}`}>
            <span>{`${i18n.t("building")}:`}</span>
            <span>{fields?.shoppingMall?.current?.value || ""}</span>
          </div>
          <div className={`${styles.summaryList}`} hidden={contractType === ContractType.PARKING}>
            <span>{`${i18n.t("clause_rental_property_property_local_no")}:`}</span>
            <span>{fields?.propertyLocalNo?.current?.value || ""}</span>
          </div>
          <div className={`${styles.summaryList}`}>
            <span>{`${i18n.t("summary_page.date")}:`}</span>
            <span>
              {fields?.rentalStart?.current?.value &&
                `${dayjs(fields?.rentalStart?.current?.value).tz('Europe/Oslo').format('DD.MM.YYYY')}${
                  fields?.rentalEnd?.current?.value &&
                  ` - ${dayjs(fields?.rentalEnd?.current?.value).tz('Europe/Oslo').format('DD.MM.YYYY')}`
                }`}
            </span>
          </div>
          <div className={`${styles.summaryList}`} hidden={contractType === ContractType.PARKING}>
            <span>{`${i18n.t("minimumRent")}:`}</span>
            <span>
              {fields?.minimumRent?.current?.value
                ? _numeral(Number(fields?.minimumRent?.current?.value)).format("0,0.[00]")
                : ""}
            </span>
          </div>
          <div className={`${styles.summaryList}`} hidden={contractType === ContractType.PARKING}>
            <span>{`${i18n.t("marketingCosts")}:`}</span>
            <span>
              {fields?.marketingCosts?.current?.value
                ? _numeral(Number(fields?.marketingCosts?.current?.value)).format("0,0.[00]")
                : ""}
            </span>
          </div>
          <div className={`${styles.summaryList}`}>
            <span>{`${i18n.t("sharedCosts")}:`}</span>
            <span>
              {fields?.sharedCosts?.current?.value
                ? _numeral(Number(fields?.sharedCosts?.current?.value)).format("0,0.[00]")
                : ""}
            </span>
          </div>
          <div className={`${styles.summaryList}`} hidden={contractType === ContractType.PARKING}>
            <span>{`${i18n.t("securityAmount")}:`}</span>
            <span>
              {fields?.securityAmount?.current?.value
                ? _numeral(Number(fields?.securityAmount?.current?.value)).format("0,0.[00]")
                : ""}
            </span>
          </div>
        </ThumbnailCardBody>
        <ThumbnailCardFooter key="tcf-1">
          <Button
            className="border-secondary second-theme-color"
            variant="secondary"
            onClick={() => {
              setSubview.set(ContractPageSubview.CONTRACT);
            }}>
            <span className="uppercase">{i18n.t("summary_page.view_contract")}</span>
          </Button>
        </ThumbnailCardFooter>
      </ThumbnailCard>
    </>
  );
};
