import { WarningBar } from 'src/components_generic/WarningBar'
import i18n from 'src/producers/languages/i18n'


export const ContractEditedByWarning: view = ({
  editor = observe.contract.editor,
  userId = get.user.uid.value(),

  becomeEditor = update.contract.changes.isEditedBy
}) => {
  console.log('ContractEditedByWarning', { editor, userId })
  if (!editor || editor.uid === userId) return null
  return (
    <WarningBar
      message={i18n.t('contract.warnings.is-edited-by', { name: editor.displayName, email: editor.email })}
      hasButton={true}
      buttonText={'contract.warnings.become-editor'}
      buttonOnClick={() => {
        becomeEditor.set(userId)
      }}
    />
  )
}
