import { ContractType } from 'src/types/contract'

interface Route {
  isMounted: boolean;
  path: string;
  auth?: boolean;
}

export enum RouteName {
  DASHBOARD = 'DASHBOARD',
  CONTRACT = 'CONTRACT',
  CONTRACT_V2 = 'CONTRACT_V2',
  LEGACY_CONTRACT = 'LEGACY_CONTRACT',
  INITIALIZATION = 'INITIALIZATION',
  ADDENDUM = 'ADDENDUM',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export const ContractRoutesMap: any = {
  [ContractType.DIGITAL]: {
    1: RouteName.LEGACY_CONTRACT,
    2: RouteName.CONTRACT,
    3: RouteName.CONTRACT,
    4: RouteName.CONTRACT_V2
  },
}

interface Dashboard extends Route {
  id: RouteName.DASHBOARD;
}

interface ContractPage extends Route {
  id: RouteName.CONTRACT;
  data: {
    [k: string]: any;
  };
}

interface AdendumPage extends Route {
  id: RouteName.ADDENDUM;
  data: {
    [k: string]: any;
  };
}

interface LegacyContractPage extends Route {
  id: RouteName.LEGACY_CONTRACT;
  data: {
    [k: string]: any;
  };
}

interface Initialization extends Route {
  id: RouteName.INITIALIZATION;
}

interface Login extends Route {
  id: RouteName.LOGIN;
}

export interface RouteList {
  [RouteName.DASHBOARD]: Dashboard;
  [RouteName.CONTRACT]: ContractPage;
  [RouteName.CONTRACT_V2]: any; // TODO: improve
  [RouteName.ADDENDUM]: AdendumPage;
  [RouteName.LEGACY_CONTRACT]: LegacyContractPage;
  [RouteName.INITIALIZATION]: Initialization;
  [RouteName.LOGIN]: Login;
}

export interface Routes {
  list: RouteList;
  ids: RouteName[];
  current: any;
  lastUrl: string;
  triggers: {
    changeRoute: number;
    reroute: number;
  };
  currentPath: string;
  pathParam: string;
  contractRevision: number;
  currentQuery: string;
  currentSubview: string;
  queryParams: {
    googleAuth: string;
    hiddenNavbar: string;
    autologin: string;
    tenantNumber: string;
  };
}
