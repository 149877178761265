import { Modal } from "src/components";
import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { goBackToNegotiation } from "src/pages/Contract/ReEditContractModal/ReEditContractModal.goBackToNegotiation";
import { showReEditContractModal } from "src/pages/Contract/ReEditContractModal/ReEditContractModal.showReEditContractModal";

export const ReEditContractModal: view = ({
  show = observe.modal.reEditContract,
  deleteAllChanges = update.contract.triggers.deleteAllChanges,
  goBackToNegotiation = update.contract.triggers.goBackToNegotiation,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!show || !isManager) return null;
  return (
    <Modal
      title={i18n.t("modals_edit_contract_title")}
      okButtonText={i18n.t("modals_edit_contract_button")}
      image={() => <SVGs.ModalEdit />}
      onOkClick={() => goBackToNegotiation.set(Date.now())}
      onCancelClick={() => deleteAllChanges.set(Date.now())}>
      <p>{i18n.t("modals_edit_contract_p1")}</p>
    </Modal>
  );
};

ReEditContractModal.producers([goBackToNegotiation, showReEditContractModal]);
