import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from 'src/types'
import posthog from 'posthog-js'
import {getFunctionsUrl} from "src/utils/getFunctionsUrl";


export const syncConfig: producer = async ({
  // isReady = update.config.isReady,
  localFunctions = get.localFunctions,
  updateLocalFunctions = update.localFunctions,
  configuration = update.config,
  error = update.errors[param.id],
}) => {
  const hostname = window.location.hostname

  try {
    if(process.env.WITH_FUNCTIONS || process.env.LOCAL) updateLocalFunctions.set(true)
    const resp = await fetch(getFunctionsUrl('serveConfig', localFunctions.value()))
    // const resp = await fetch(`http://localhost:5001/klp-contracts/europe-west2/serveConfig`)
    const config = await resp.json()
    // if(config.posthog) posthog.init(config.posthog.key, { api_host: 'klp-dev.levendekontrakter.no', ui_host: config.posthog.host })
    if(config.posthog) posthog.init(config.posthog.key, { api_host: config.posthog.host, disable_session_recording: false, enable_recording_console_log: true })
    // posthog.init('phc_qK7j6ygjCdOTMu2L9vzo5nmwqVB4b3lGEYNeAU17Ym5', { api_host: 'klp-dev.levendekontrakter.no', ui_host: 'https://us.posthog.com' })

    // posthog.init('phc_qK7j6ygjCdOTMu2L9vzo5nmwqVB4b3lGEYNeAU17Ym5', { api_host: 'https://us.posthog.com' })
    // posthog.init('phc_qK7j6ygjCdOTMu2L9vzo5nmwqVB4b3lGEYNeAU17Ym5', { api_host: 'https://us.posthog.com' })
    configuration.merge(config)
  } catch (e) {
    error.set(
      {
        message: e,
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: 'syncConfig' }
    )
  }
}
