import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { AddendumType, ContractStatus, ContractType, UserRole } from "src/types";
import { TooltipWrapper } from "src/components";

import styles from "../styles.module.css";

export const ContractName: view = ({
  shoppingMall = observe.contract.values.shoppingMall.displayedValue,
  tenantName = observe.contract.values.tenantName.displayedValue,
  status = observe.contract.original.state.status,
  contractType = observe.contract.original.contractType,
  userRole = get.user.data.role,
  addendumIsMounted = observe.routes.list.ADDENDUM.isMounted,
  addendumType = observe.contract.original.addendumType,
  title = observe.contract.values.title.displayedValue,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  const isSigning = status === ContractStatus.SIGNING;
  let agreementName = `${i18n.t("general.contract")} ${shoppingMall || ""} ${tenantName || ""}`;
  if (contractType === ContractType.PARKING) {
    agreementName = i18n.t("dashboard.agreement_option_parking");
  }
  if (addendumIsMounted) {
    switch (addendumType) {
      case AddendumType.CANCELATION:
        agreementName = i18n.t("addendum.cancelation_addendum");
        break;
      case AddendumType.DOWNPAYMENT:
        agreementName = i18n.t("addendum.downpayment_addendum");
        break;
      case AddendumType.TRANSPORT:
        agreementName = i18n.t("addendum.transport_addendum");
        break;
      case AddendumType.RENTAL_PERIOD_PRICE:
        agreementName = i18n.t("addendum.change_price_period_addendum");
        break;
      case AddendumType.STORAGE:
        agreementName = i18n.t("addendum.storage_addendum");
        break;
      default:
        agreementName = i18n.t("general.addendum");
        break;
    }
  }
  if (title) agreementName = title;
  return (
    <>
      <p className={`${styles.contractTitle}`}>{agreementName}</p>

      {isTenant && isSigning && (
        <TooltipWrapper text={i18n.t("tenant_contract_locked_icon_tooltip")}>
          <SVGs.ContractLockedPage className="ml-2" />
        </TooltipWrapper>
      )}
    </>
  );
};
