import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from 'src/types'
import { db } from '../firebase'

export const syncUserData: producer = async ({
  isAuth = observe.user.isAuth,
  uid = observe.user.uid,
  setData = update.user.data,
  setOnboarding = update.user.onboarded,
  error = update.errors[param.id],
}) => {
  if (!isAuth || !uid) return
  console.log('syncUserData', uid, isAuth)
  try {
    const doc = await db.collection('users').doc(uid).get()
    const user = doc.data()
    console.log('user', user)
    setData.set(user.data)
    setOnboarding.set(user.onboarded)
  } catch (e) {
    console.error('ERROR at syncUserData =>> ', e)
    error.set(
      {
        message: e.message || 'Error at syncUserData',
        level: LogLevels.ERROR,
      } as ApplicationError,
      { id: 'syncUserData' }
    )
  }
}
