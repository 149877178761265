import { RadioButton, RadioButtonGroup } from "@c11/components";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { InsideCard } from "../../ClauseCard/InsideCard";

export const PaymentProvision: view = ({
  userRole = observe.user.data.role,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  getPaymentProvisionFirstPayDueDate = observe.contract.values.paymentProvisionFirstPayDueDate.displayedValue,
  getPaymentProvisionFirstPayDueDateFromChanges = observe.contract.currentSession.changes
    .paymentProvisionFirstPayDueDate.value,
  setPaymentProvisionFirstPayDueDate = update.contract.toSave.changes.paymentProvisionFirstPayDueDate,
}) => {
  const isTenant = userRole === UserRole.TENANT;

  const i = getPaymentProvisionFirstPayDueDateFromChanges || getPaymentProvisionFirstPayDueDate;
  const checkedState = [i === 1, i === 2];

  function handleClick(c: any) {
    if (!checkedState[c]) setPaymentProvisionFirstPayDueDate.set({
      value: c + 1,
      createdAt: Date.now()
    });
  }

  return (
    <InsideCard key="provision"
                title={"3.4 " + i18n.t("parking_contract.rental_cost_clause_payment_provisions")}>
      <RadioButtonGroup selectedValue={String(i)}>
        <RadioButton
          key={`11`}
          className="mr-4"
          value={"1"}
          isDisabled={isReadOnlyContract || isTenant}
          onClick={() => handleClick(0)}>
          {i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_1")}
        </RadioButton>
        <RadioButton key={`12`} value={"2"}
                     isDisabled={isReadOnlyContract || isTenant}
                     onClick={() => handleClick(1)}>
          {i18n.t("clause_rental_costs_index_payment_provision_first_pay_date_alternative_2")}
        </RadioButton>
      </RadioButtonGroup>
    </InsideCard>
  );
};
