import i18n from "src/producers/languages/i18n";

import {
  DateFieldGeneral,
  DropdownInputGeneral
} from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import styles from "../../../../../styles.module.css";
import { RadioButton, RadioButtonGroup, Switch } from "@c11/components";

export const RentalPeriodManager: view = ({
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  rentalPeriodOptionsPeriod = observe.contract.values.rentalPeriodOptionsPeriod.displayedValue,
  rentalPeriodHasOptionsDV = observe.contract.values.rentalPeriodHasOptions.displayedValue,
  rentalPeriodHasOptionsFromChanges = observe.contract.currentSession.changes.rentalPeriodHasOptions.value,
  rentalPeriodEorRDV = observe.contract.values.rentalPeriodEorR.displayedValue,
  rentalPeriodEorRFromChanges = observe.contract.currentSession.changes.rentalPeriodEorR.value,
  setRentalPeriodHasOptions = update.contract.toSave.changes.rentalPeriodHasOptions,
  setRentalPeriodEorR = update.contract.toSave.changes.rentalPeriodEorR,
}) => {
  const rentalPeriodHasOptions = rentalPeriodHasOptionsFromChanges || rentalPeriodHasOptionsDV;
  const rentalPeriodEorR = rentalPeriodEorRFromChanges || rentalPeriodEorRDV;
  const hasPotencialOptions = rentalPeriodHasOptions === "true";

  return (
    <div>
      <div className={`${styles.datesRow}`}>
        <div className="flex flex-col w-64">
          <DateFieldGeneral
            varName="rentalStart"
            required
            placeholder={i18n.t("placeholders.select_date")}
            label={i18n.t("clause_rental_period_rental_start")}
          />
        </div>
        <div className="flex flex-col w-64">
          <DateFieldGeneral
            varName="rentalEnd"
            required
            placeholder={i18n.t("placeholders.select_date")}
            label={i18n.t("clause_rental_period_rental_end")}
          />
        </div>
      </div>
      <div className={`${styles.rentalPeriodOptionsContainer}`}>
        {/* TODO Try to put this in the template */}
        <div className={`${styles.rentalPeriodOptionsHeader}`}>
          <p key="3">{i18n.t("clause_rental_period_potential_options")}</p>
          <div className="flex">
            <span>
              {hasPotencialOptions
                ? i18n.t("clause_rental_period_potential_options_o")
                : i18n.t("clause_rental_period_potential_options_na")}
            </span>
            <Switch
              className={`secondary-switch-${hasPotencialOptions ? "checked" : "unchecked"}`}
              isChecked={hasPotencialOptions}
              isDisabled={isReadOnlyContract}
              onClick={() => {
                hasPotencialOptions
                  ? setRentalPeriodHasOptions.set({
                    value: "false",
                    createdAt: Date.now()
                  })
                  : setRentalPeriodHasOptions.set({
                    value: "true",
                    createdAt: Date.now()
                  });
              }}
            />
          </div>
        </div>
        {rentalPeriodHasOptions === "true" && (
          <>
            <div className={`${styles.rentalPeriodOptions}`}>
              <div className={`${styles.rentalRadioOptions}`}>
                <RadioButtonGroup selectedValue={rentalPeriodEorR}>
                  <RadioButton
                    key={`13}`}
                    className="mb-5"
                    value={"E"}
                    isDisabled={isReadOnlyContract}
                    onClick={() =>
                      rentalPeriodEorR !== "E" ? setRentalPeriodEorR.set({
                        value: "E",
                        createdAt: Date.now()
                      }) : {}
                    }>
                    {i18n.t("clause_rental_period_potential_options_extension_u")}
                  </RadioButton>
                  <RadioButton
                    key={`14}`}
                    className="mb-5"
                    value={"R"}
                    isDisabled={isReadOnlyContract}
                    onClick={() =>
                      rentalPeriodEorR !== "R" ? setRentalPeriodEorR.set({
                        value: "R",
                        createdAt: Date.now()
                      }) : {}
                    }>
                    {i18n.t("clause_rental_period_potential_options_renewal_u")}
                  </RadioButton>
                </RadioButtonGroup>
              </div>
              {" "}
              <DropdownInputGeneral
                choises={[
                  "rentalPeriodOptionsPeriod_o1",
                  "rentalPeriodOptionsPeriod_o2",
                  "rentalPeriodOptionsPeriod_o3",
                  "rentalPeriodOptionsPeriod_o4",
                ]}
                varName="rentalPeriodOptionsPeriod"
                placeholder={`${i18n.t("clause_rental_period_potential_options_period")}`}
                readOnly={isReadOnlyContract}
                extraClasses="my-2"
              />
            </div>
            <p key="p3" className="pb-2 pt-1">
              <span key="0">
                {i18n.t("rentalPeriodOptionsPeriod_p1_1", {
                  ER:
                    rentalPeriodEorR === "R"
                      ? i18n.t("clause_rental_period_potential_options_renewal")
                      : i18n.t("clause_rental_period_potential_options_extension"),
                })}
              </span>
              <strong key="1">
                {rentalPeriodOptionsPeriod &&
                  i18n.t(rentalPeriodOptionsPeriod) + i18n.t("rentalPeriodOptionsPeriod_p1_2")}
              </strong>
              <span key="2">
                {i18n.t("rentalPeriodOptionsPeriod_p1_3", {
                  ER:
                    rentalPeriodEorR === "R"
                      ? i18n.t("clause_rental_period_potential_options_renewal")
                      : i18n.t("clause_rental_period_potential_options_extension"),
                  T:
                    rentalPeriodEorR === "R"
                      ? i18n.t("clause_rental_period_potential_options_renegotiation")
                      : i18n.t("clause_rental_period_potential_options_same"),
                })}
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};
