import moment from "moment";
import { Tooltip } from "antd";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { ContractEventType, Language, UserRole } from "src/types";
import { MessageBubble } from "./MessageBubble";
import { VersionBubble } from "./VersionBubble";
import { EventBubble } from "./EventBubble";

export const UpdatesFeed: view = ({
  userRole = get.user.data.role,
  activityLog = observe.contract.activity,
  signingMethod = observe.contract.original.state.signing,
  tempActivityLog = observe.contract.tempActivity,
}) => {
  if (i18n.language === Language.NORWEGIAN) moment.locale("nb");
  else moment.locale("en");

  if (!activityLog) return null;

  const isTenant = userRole.value() === UserRole.TENANT;
  tempActivityLog = tempActivityLog || [];
  // TODO upadte the logic to add an id to the message or to process the response from server
  let log = activityLog;
  tempActivityLog.forEach((tempMessage: any) => {
    if (!activityLog.find((activityEvent: any) => activityEvent?.payload?.message == tempMessage.message))
      log.push(tempMessage);
  });

  const feedList = log.map((feedEvent: any, index: any) => {
    const timestamp = moment(feedEvent.createdAt).fromNow();
    //TODO extract the SVG's
    switch (feedEvent.type) {
      case ContractEventType.COMMENT_ADDED:
        return <MessageBubble key={index} comment={feedEvent} timestamp={timestamp} />;
      case ContractEventType.VERSION_CREATED:
        return <VersionBubble key={index} version={feedEvent} timestamp={timestamp} />;
      case ContractEventType.OFFER_SENT:
        return (
          <EventBubble
            key={index}
            title={i18n.t("activity_log.offer_sent")}
            timestamp={timestamp}
            event={ContractEventType.OFFER_SENT}
          />
        );
      case ContractEventType.SENT_FOR_SIGNING:
        return (
          <EventBubble
            key={index}
            title={
              feedEvent.payload.signing === "digital"
                ? i18n.t(`activity_log.sent_to_digital_signing`)
                : i18n.t(`activity_log.sent_to_paper_signing`)
            }
            timestamp={timestamp}
            event={ContractEventType.SENT_FOR_SIGNING}
          />
        );
      case ContractEventType.CANCELLED_SIGNING:
        return <EventBubble key={index} title={i18n.t(`activity_log.cancelled_signing`)} timestamp={timestamp} />;
      case ContractEventType.EXECUTED:
        return (
          <EventBubble
            key={index}
            title={i18n.t("activity_log.signed")}
            timestamp={timestamp}
            event={ContractEventType.EXECUTED}
          />
        );
      default:
        return null;
    }
  });

  return (
    <div style={{ flexGrow: 1 }} className="w-full flex flex-col">
      <div className="justify-between flex py-6 px-4 ">
        <h3 className="text-lg flex items-center">
          <SVGs.Message className="mr-2 mt-1" />
          {i18n.t("sidebar.activity_feed")}
        </h3>
        <div>
          <Tooltip title={i18n.t("sidebar.tooltip_messages")} placement={"bottom"}>
            <SVGs.HelpBigGray className="mt-1" />
          </Tooltip>
        </div>
      </div>
      <div style={{ paddingBottom: isTenant ? 205 : 440, padding: "0 16px 0 16px" }}>{feedList}</div>
    </div>
  );
};
