import { ErrorLevel, ApplicationError, ErrorType } from "src/types";

export const modifyExpiry: producer = async ({
  _viewId,
  expiration = observe.contract.original.envelope.expiration,
  trigger = observe.views[prop._viewId].trigger,
  removeTrigger = update.views[prop._viewId].trigger,
  contractId = get.contract.id,
  envelopeInfo = get.contract.original.state.envelopeInfo,

  error = update.errors[param.id],
  updateContracts = update.contract.triggers.updateContracts,
}) => {
  if (!trigger) return;
  contractId = contractId.value();
  envelopeInfo = envelopeInfo.value();
  // removeTrigger.remove();

  try {
    updateContracts.set({
      action: "modifyExpiry",
      contractId,
      envelopeUid: envelopeInfo.envelopeUid,
      newExpiry: trigger.toISOString(),
      loadingFor: "modifyExpiry",
    });
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "moveToSigning-contract" }
    );
  }
};
