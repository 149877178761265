import { Button } from "@c11/components.button";
import "./WelcomeCard.css";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { dismissWelcomeCard } from "src/pages/Contract/Sidebar/WelcomeCard.dismissWelcomeCard";

export const WelcomeCard: view = ({
  triggerDismiss = update.contract.triggers.dismissWelcomeCard,
  onboarded = observe.user.onboarded,
}) => {
  if (onboarded) return null;
  return (
    <div className="p-4">
      <div style={{ flexGrow: 1 }} key="card" className="bg-white border klp-border1 p-6 rounded-sm">
        <div className="flex justify-between items-start">
          <h3 key="title" className="font-bold text-xl mb-4 mr-5">
            {i18n.t("sidebar.welcome_card_title")}
          </h3>
          <Button className="dismiss-btn" variant="ghost" onClick={() => triggerDismiss.set(Date.now())}>
            <span className="text-xs">{i18n.t("sidebar.welcome_card_dismiss")}</span>
          </Button>
        </div>

        <p className="pb-4 mb-4 border-b border-gray-200">{i18n.t("sidebar.welcome_card_text_1")}</p>
        <h4 className="font-bold mt-4 mb-2 flex items-center">
          <SVGs.WelcomeEditing className="mr-2" />
          {i18n.t("sidebar.welcome_card_editing_title")}
        </h4>
        <p className="">{i18n.t("sidebar.welcome_card_editing_p1")}</p>
        <h4 className="font-bold mt-4 mb-2 flex items-center">
          <SVGs.WelcomeMessages className="mr-2" />
          {i18n.t("sidebar.welcome_card_messages_title")}
        </h4>
        <p className="">{i18n.t("sidebar.welcome_card_messages_p1")}</p>
      </div>
    </div>
  );
};

WelcomeCard.producers([dismissWelcomeCard]);
