export const process: producer = ({
  contractId = observe.legacyContract.id,
  values = observe.legacyContract.syncQueue.values,
  displayedVersion = observe.legacyContract.displayedVersion,
  setValues = update.legacyContract.values,
  antecedents = update.legacyContract.triggers.setAntecedents,
}) => {
  if (
    !contractId ||
    !values ||
    values.versions == undefined ||
    values.sessions == undefined ||
    values.defaultValues == undefined
  )
    return;

  try {
    let toSave: any = {};
    if (values.versions) {
      toSave = values.versions;
    }
    if (values.sessions) {
      Object.keys(values.sessions).forEach((varName) => {
        const variable = values.sessions[varName];
        toSave[varName] = {
          valueTimeline: Object.assign(toSave[varName]?.valueTimeline || {}, variable.valueTimeline),
        };
      });
    }
    if (values.defaultValues) {
      Object.keys(values.defaultValues).forEach((varName) => {
        const variable = values.defaultValues[varName];
        toSave[varName] = {
          valueTimeline: Object.assign(toSave[varName]?.valueTimeline || {}, variable.valueTimeline),
        };
      });
    }

    Object.keys(toSave).forEach((varName) => {
      let timeline = toSave[varName].valueTimeline;
      let sortedTimeline = Object.keys(timeline).sort((a: any, b: any): any => {
        if (parseInt(timeline[a].createdAt) > parseInt(timeline[b].createdAt)) {
          return 1;
        } else {
          return -1;
        }
      });
      let antecedent: string;
      sortedTimeline.forEach((id, index) => {
        if (index === 0) {
          antecedent = id;
          return;
        }
        timeline[id].antecedent = antecedent;
        antecedent = id;
      });
      if (sortedTimeline.length > 0) {
        toSave[varName].valueTimeline = timeline;
        if (!displayedVersion) {
          toSave[varName].displayedValueId = sortedTimeline[sortedTimeline.length - 1];
          toSave[varName].displayedValue = timeline[toSave[varName].displayedValueId].value;
          toSave[varName].custom = timeline[toSave[varName].displayedValueId].custom || false;
        } else {
          const newSortedTimeline = sortedTimeline.filter((id) => {
            return parseInt(timeline[id].versionId) <= parseInt(displayedVersion);
          });
          if (newSortedTimeline.length > 0) {
            toSave[varName].displayedValueId = newSortedTimeline[newSortedTimeline.length - 1];
            toSave[varName].displayedValue = timeline[toSave[varName].displayedValueId].value;
            toSave[varName].custom = timeline[toSave[varName].displayedValueId].custom || false;
          }
        }
      }
    });
    setValues.set(toSave);
    antecedents.set(Date.now());
  } catch (e) {
    console.error("syncValues", e);
  }
};
