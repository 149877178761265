import React, { memo, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

//This is a generalcomopnent used for drag&drop
export const DragItem = memo(({ id, onMoveItem, children }) => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const [{ isDragging }, connectDrag, connectDragPreview] = useDrag({
    type: "item",
    item: { id },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  const [, connectDrop] = useDrop({
    accept: "item",
    drop(hoveredOverItem) {
      if (hoveredOverItem.id !== id) {
        onMoveItem(hoveredOverItem.id, id);
      }
    },
  });

  connectDrag(ref1);
  connectDrop(ref2);
  connectDragPreview(ref3);

  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      connectDrag: ref1,
      connectDrop: ref2,
      connectDragPreview: ref3,
      style: { opacity: isDragging ? 0.5 : 1 },
      isDragging,
    })
  );
});
