import { db, functions } from "src/producers/firebase";
import { ContractEventType, ErrorLevel } from "src/types";
import { CommandActions } from "src/types/commands/command";

export const submitComment: producer = async ({
  contractId = observe.legacyContract.id,
  user = get.user,
  comment = get.comment.message,
  isSubmitted = get.comment.submit,
  setIsSubmitted = update.comment.submit,

  removeComment = update.comment.message,
  resetCommentComponent = update.comment.reset,
  latestVersion = get.legacyContract.original.latestVersion,
  trigger = observe.legacyContract.triggers.submitComment,
  removeTrigger = update.legacyContract.triggers.submitComment,
  error = update.errors[param.type],
}) => {
  if (!comment.value() || !trigger || !contractId || isSubmitted.value()) {
    return;
  }
  try {
    setIsSubmitted.set(true);
    removeTrigger.remove();
    user = user.value();
    const message = comment.value();
    const version = latestVersion.value();
    const contractDb = db.collection("contracts").doc(contractId);
    await contractDb.collection("comments").add({
      versionId: version,
      userId: user.uid,
      message: message,
      createdBy: user.data.role,
      createdAt: Date.now(),
    });
    // await contractDb.collection("events").add({
    //   createdAt: Date.now(),
    //   message: message,
    //   version: version,
    //   type: ContractEventType.COMMENT_ADDED,
    //   user: user.uid,
    // });
    // const commandPublisher = functions.httpsCallable("commandPublisher");
    // const command = {
    //   action: CommandActions.ADD_COMMENT,
    //   contractId: contractId,
    //   message: message,
    // };
    // commandPublisher(command).catch((err: any) => console.error(err))

    removeComment.remove();
    resetCommentComponent.set(Date.now());
    setIsSubmitted.remove();
  } catch (e) {
    console.error("submitComment", e);
    error.set({ message: "error-saving-comment", level: ErrorLevel.WARNING }, { type: "comment" });
  }
};
