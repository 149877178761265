import { notification } from 'antd'
import { db } from 'src/producers/firebase'
import i18n from 'src/producers/languages/i18n'
import {ApplicationError, ErrorLevel, ErrorType, LogLevels, UserRole} from 'src/types'

let timer: any = null

export const persistFieldChanges: producer = async ({
  fieldChanges = observe.contract.changes.fields,
  isLocked = get.contract.isLocked.value(),
  contractId = get.contract.id.value(),
  originalFields = get.contract.originalv2.fields.value(),

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,

  updateFieldChanges = update.contract.changes.fields,
  updateContractStateFields = update.contract.originalv2.fields,
  error = update.errors[param.id],
}) => {
  if (!fieldChanges || Object.keys(fieldChanges).length < 1) return
  if (!contractId) return
  if (isLocked) return
  if (timer) clearTimeout(timer)

  timer = setTimeout(async () => {
    try {
      isSaving.set(true)
      const updatedAt = new Date().toISOString()
      await db.collection('contracts').doc(contractId).set({ fields: fieldChanges, updatedAt }, { merge: true })

      updateContractStateFields.merge(fieldChanges)
      updateFieldChanges.remove()
      successfulSave.set(true)
    } catch (e) {
      console.error('ERROR!! persistFieldChanges', e)
      failedSave.set(true)
      updateContractStateFields.set(originalFields)
      error.set(
        {
          message: 'saving-changes-to-database',
          level: LogLevels.ERROR,
        } as ApplicationError,
        { id: 'persistFieldChanges' }
      )
    }
    isSaving.set(false)
  }, 1000)
  // updateTimer.set(timerId)
}
