import React from "react";

import i18n from "src/producers/languages/i18n";
import { HeadingClause } from "src/pages/Contract/subviews/ContractForm/HeadingClause/HeadingClause";
import { IntroClause } from "src/pages/Contract/subviews/ContractForm/IntroClause/IntroClause";
import { PartiesClause } from "src/pages/Contract/subviews/ContractForm/PartiesClause/PartiesClause";
import { RentalPropertyClause } from "src/pages/Contract/subviews/ContractForm/RentalPropertyClause/RentalPropertyClause";
import { RentalPeriodClause } from "src/pages/Contract/subviews/ContractForm/RentalPeriodClause/RentalPeriodClause";
import { RentalCostsClause } from "src/pages/Contract/subviews/ContractForm/RentalCostsClause/RentalCostsClause";
import { VatClause } from "src/pages/Contract/subviews/ContractForm/VatClause/VatClause";
import { SpecialProvisionsClause } from "src/pages/Contract/subviews/ContractForm/SpecialProvisionsClause/SpecialProvisionsClause";
import { SecurityClause } from "src/pages/Contract/subviews/ContractForm/SecurityClause/SecurityClause";
import { CompulsoryDerogationClause } from "src/pages/Contract/subviews/ContractForm/CompulsoryDerogationClause/CompulsoryDerogationClause";

import { Clause, ClauseType } from "src/templates/types/template";

export const ContractClauseFactory: view = ({
  clauseID,
  clause = observe.contract.template.sections.mainContract.clauses[arg.clauseID],
}: {
  clauseID: string;
  clause: Clause;
}) => {
  if (!clause) return null;

  if (clause.type === ClauseType.Custom) {
    switch (clause.name) {
      case "heading":
        return <HeadingClause key={clauseID} clause={clause} />;
      case "intro":
        return <IntroClause key="intro" title={i18n.t("clause_intro_title")} />;
      case "parties":
        return <PartiesClause key="parties" title={i18n.t("clause_parties_title")} />;
      case "rentalProperty":
        return <RentalPropertyClause key="rentalProperty" title={`2. ${i18n.t("clause_rental_property_title")}`} />;
      case "rentalPeriod":
        return <RentalPeriodClause key="rentalPeriod" title={`3. ${i18n.t("clause_rental_period_title")}`} />;
      case "rentalCosts":
        return <RentalCostsClause key="rentalCosts" title={"4. " + i18n.t("clause_rental_costs_title")} />;
      case "vat":
        return <VatClause key="vat" title={`5. ${i18n.t("clause_vat_title")}`} />;
      case "specialProvisions":
        return <SpecialProvisionsClause key="specialProvisions" title={i18n.t("clause_special_provisions_title")} />;
      case "security":
        return <SecurityClause key="security" title={`6. ${i18n.t("clause_security_title")}`} />;
      case "compulsoryDerogation":
        return (
          <CompulsoryDerogationClause key="compulsoryDerogation" title={i18n.t("clause_compulsory_derogation_title")} />
        );
      default:
        return null;
    }
  }
  return null;
};
