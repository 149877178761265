import { db } from 'src/producers/firebase'
import {ApplicationError, ContractSigningMethod, ErrorLevel, ErrorType, LogLevels} from 'src/types'

export const persistPartiesSigningMethod: producer = async ({
  contractId = get.contract.id.value(),
  isLocked = get.contract.isLocked.value(),

  contractSigningMethod = observe.contract.originalv2.signing,
  updateContractSigningMethod = update.contract.originalv2.signing,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  trigger = observe.contract.triggers.parties.updateSigningMethod,
  updateTrigger = update.contract.triggers.parties.updateSigningMethod,
}) => {
  if (!trigger) return
  if (isLocked) return
  if (!contractId) return

  let nextSigningMethod = ContractSigningMethod.PAPER
  if (contractSigningMethod === ContractSigningMethod.PAPER) {
    nextSigningMethod = ContractSigningMethod.DIGITAL
  }

  try {
    isSaving.set(true)

    const updatedAt = new Date().toISOString()
    await db.collection('contracts').doc(contractId).set(
      {
        signing: nextSigningMethod,
        updatedAt,
      },
      {
        merge: true,
      }
    )

    updateContractSigningMethod.set(nextSigningMethod)
    successfulSave.set(true)
  } catch (e) {
    console.error('persist-party-update', e)
    failedSave.set(true)
    error.set(
      {
        message: 'saving-tenant-to-database',
        level: LogLevels.WARN,
      } as ApplicationError,
      { id: 'persist-party-update' }
    )
  } finally {
    updateTrigger.remove()
    isSaving.set(false)
  }
}
