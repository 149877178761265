import { Divider } from "antd";
import "./Navbar.css";
import { BuildingIcon } from "src/assets/svg";
import i18n from "src/producers/languages/i18n";
import { Images } from "src/assets";
import { UserRole } from "src/types";
import { LanguageDropdown } from "src/pages/Dashboard/LanguageDropdown/LanguageDropdown";
import { LogOutButton } from "src/pages/Dashboard/LogOutButton/LogOutButton";
import { LeftArrowIcon } from "src/assets/svg";
import { UserMenu } from "src/pages/Dashboard/UserMenu/UserMenu";
import { TenantIdsDropdown } from "src/pages/Dashboard/TenantIdsDropdown/TenantIdsDropdown";
import { DepartmentDropdown } from "src/pages/Dashboard/DepartmentDropdown/DepartmentDropdown";
import styles from "../styles.module.css";
import { Button } from "@c11/components";
import { TestButton } from "../MigrateContracts/TestButton";

export const Navbar: view = ({
  currentLanguage = observe.languages.current, // FIXME: fix the root cause for broken translations
  getContracts = update.pages.dashboard.triggers.getContracts,
  loggedUser = observe.user,
  tenant = observe.tenant,
  myTenancyLink = observe.config.host,
}) => {
  if (!loggedUser || !currentLanguage) return null;
  const isManager = loggedUser?.data?.role === UserRole.MANAGER;
  const pageTitle = isManager ? i18n.t("dashboard.navbar_title_manager") : i18n.t("dashboard.navbar_title_tenant");

  return (
    <nav>
      <div className={`${styles.logoContainer}`}>
        <img src={Images.logo} alt="Logo" />
        <div>
          <h2>{pageTitle}</h2>
          <a href={myTenancyLink}>
            <LeftArrowIcon />
            <span>{i18n.t("dashboard.navbar_back_button")}</span>
          </a>
        </div>
      </div>

      <div className={`${styles.headerUserSection}`}>
        {!!tenant && (
          <>
            <div className="items-center hidden lg:flex">
              <TenantIdsDropdown />
            </div>
            <div className="hidden lg:block">
              <Divider type="vertical" className="vertical-line" />
            </div>
          </>
        )}
        <div className={`${styles.userInfoSection}`}>
          <p>{loggedUser?.data?.displayName}</p>
          <p>{loggedUser?.data?.email}</p>
        </div>

        <DepartmentDropdown/>

        <div className={`${styles.headerDivider}`}>
          <Divider type="vertical" />
        </div>
        <div className={`${styles.navButtons}`}>
          <LanguageDropdown />
          <LogOutButton />
        </div>
        <div className={`${styles.userMenu}`}>
          <UserMenu />
        </div>
      </div>
    </nav>
  );
};
