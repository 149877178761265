import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { AddPartyButton } from "src/pages/Contract/subviews/Parties/AddPartyButton/AddPartyButton";

export const NoPartiesCard: view = () => {
  return (
    <div className="flex flex-col items-center justify-center  w-full h-full px-0 py-10">
      <div className="flex flex-col items-center klp-border1 rounded-xl border justify-center w-64 h-64">
        <SVGs.NoParty className={"mb-2"} />
        <div className={"text-gray-600 mb-3"}> {i18n.t("parties_page.no_signers_card_p1")}</div>
        <AddPartyButton />
      </div>
    </div>
  );
};
