import { AgreementType, ContractType, ContractTypeExport } from 'src/types'

export const getContractType = (contract: any) => {
  let contractType = ''
  if (contract.contractType === ContractType.DIGITAL)
    contractType = ContractTypeExport.MALL
  if (contract.agreementType === AgreementType.ADDENDUM)
    contractType = ContractTypeExport.ADDENDUM_MALL
  if (contract.contractType === ContractType.PARKING) {
    if (contract.building.type === 'mall')
      contractType = ContractTypeExport.PARKING_MALL
    else if (contract.building.type === 'office')
      contractType = ContractTypeExport.PARKING_OFFICE
  }
  return contractType
}
