import {
  InsideCard
} from 'src/pages/Contract/subviews/ContractForm/ClauseCard/InsideCard'
import {
  ClauseCard
} from 'src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard'
import i18n from 'src/producers/languages/i18n'
import {
  FactoryFormField
} from 'src/pages/Contract/subviews/ContractForm/fields/FactoryFormField'
import { TenantInput } from './TenantInput'
import { TenantCPDropdown } from './TenantCPDropdown'
import {
  populateTenant
} from 'src/pages/Contract/subviews/ContractForm/PartiesClause/PartiesClause.populateTenant'

export const PartiesClause: view = ({ title }) => (
  <ClauseCard title={title}>
    <InsideCard key="Landlord" title={"1.1 " + i18n.t("clause_parties_landlord_title")}>
      <FactoryFormField key="0" fieldName="landlordName" varName="landlordName" />
      <FactoryFormField key="1" fieldName="landlordOrgNo" varName="landlordOrgNo" />
      <FactoryFormField key="2" fieldName="landlordAddress" varName="landlordAddress" />
      <FactoryFormField key="3" fieldName="landlordPostalAddress" varName="landlordPostalAddress" />
      <FactoryFormField key="4" fieldName="landlordInvoiceAddress" varName="landlordInvoiceAddress" />
      <FactoryFormField key="9" fieldName="landlordAccountNo" varName="landlordAccountNo" />
      <FactoryFormField key="40" fieldName="landlordPropertyManager" varName="landlordPropertyManager" />
      <FactoryFormField key="6" fieldName="landlordContactPersonFirstName" varName="landlordContactPersonFirstName" />
      <FactoryFormField key="7" fieldName="landlordContactPersonLastName" varName="landlordContactPersonLastName" />
      <FactoryFormField key="8" fieldName="landlordPhone" varName="landlordPhone" />
      <FactoryFormField key="5" fieldName="landlordEmail" varName="landlordEmail" />
    </InsideCard>
    <InsideCard key="tenant" title={"1.2 " + i18n.t("clause_parties_tenant_title")} editableByTenant className="relative">
      <TenantInput key="6" />
      {/*<LoadingTenantLink/>*/}
      <TenantCPDropdown key="8" />
      <FactoryFormField key="9" fieldName="tenantName" varName="tenantName" />
      <FactoryFormField key="10" fieldName="tenantOrgNo" varName="tenantOrgNo" />
      <FactoryFormField key="11" fieldName="tenantAddress" varName="tenantAddress" />
      <FactoryFormField key="12" fieldName="tenantPostalAddress" varName="tenantPostalAddress" />
      <FactoryFormField key="13" fieldName="tenantInvoiceAddress" varName="tenantInvoiceAddress" />
      <h4 style={{ fontFamily: "Arial", fontSize: 14 }} className="font-bold my-4 uppercase w-full">
        {i18n.t("clause_parties_tenant_contact")}
      </h4>
      <FactoryFormField key="15" fieldName="tenantContactPersonFirstName" varName="tenantContactPersonFirstName" />
      <FactoryFormField key="16" fieldName="tenantContactPersonLastName" varName="tenantContactPersonLastName" />
      <FactoryFormField key="17" fieldName="tenantPhone" varName="tenantPhone" />
      <FactoryFormField key="14" fieldName="tenantEmail" varName="tenantEmail" />
    </InsideCard>
  </ClauseCard>
);

PartiesClause.producers([populateTenant]);
