export const syncTermsUrl: producer = ({
  shoppingMalls = observe.legacyContract.shoppingMalls,
  shoppingMallName = observe.legacyContract.values.shoppingMall.displayedValue,
  setTermsUrl = update.legacyContract.termsUrl,
  host = observe.config.host,
}) => {
  if (!shoppingMallName) {
    return;
  }
  try {
    const number = shoppingMalls.filter((sm: any) => sm.name == shoppingMallName)?.[0]?.number;
    const termsUrl = `${host}bygg/${number}/documents#Kontraktsvedlegg`;
    setTermsUrl.set(termsUrl);
  } catch (e) {
    console.error("synctermsUrl", e);
  }
};
