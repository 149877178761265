import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "@c11/components";

import { setDefaultView } from "src/pages/Dashboard/ContractsSection/ContractsSection.setDefaultView";
import i18n from "src/producers/languages/i18n";
import { Contract, DashboardViews } from "src/types";
import { ContractCard } from "src/pages/Dashboard/ContractCard/ContractCard";
import { ContractListItem } from "src/pages/Dashboard/ContractListItem/ContractListItem";
import styles from "../styles.module.css";
import { router } from "../../../router";

export const ContractsSection: view = ({
  selectedView = observe.pages.dashboard.selectedView,
  contractsColl = observe.pages.dashboard.contracts,
  isGettingContracts = observe.pages.dashboard.triggers.getContracts,
  triggerGetContracts = update.pages.dashboard.triggers.getContracts,
  // setCurrentPath = update.routes.currentPath, // TODO: update with new router
  currentQuery = observe.routes.currentQuery,
  updateReroute = update.routes.triggers.reroute,
  setNewContractState = update.routes.newContractState,
}) => {
  const navigateToContract = (contract: any) => {
    // setCurrentPath.set(`/contract/${contract.id}`);
    console.log(">>>Contract: ", contract)
    // if(contract.isLegacy)
    //   router.navigate(`/contract/${contract.id}` + (currentQuery || ''));
    // else
    //   router.navigate(`/contract/${contract.id}` + (currentQuery || ''));
    router.navigate(`/contract/${contract.id}` + (currentQuery || ''));
    updateReroute.set(Date.now());
    setNewContractState.set(contract);
  };

  const initialLoading = isGettingContracts && contractsColl?.data === undefined;

  return (
    <div>
      {selectedView === DashboardViews.LIST && (
        <div className="flex justify-between">
          <div className={`${styles.dashboardListHeader}`}>
            <div className="w-3/12 flex justify-start place-items-start">{i18n.t("dashboard.building")}</div>
            <div className="w-2/12 flex justify-start">{i18n.t("dashboard.local_no")}</div>
            <div className="w-2/12 flex justify-start">{i18n.t("dashboard.start")}</div>
            <div className="w-2/12 flex justify-start">{i18n.t("dashboard.end")}</div>
            <div className="w-3/12 flex justify-end pr-20">{i18n.t("dashboard.status")}</div>
          </div>
        </div>
      )}
      {initialLoading && (
        // <div className="flex flex-row justify-end gap-20 my-2">
        //   {/* <RentalEndFilter />
        //   {user.role === UserRole.MANAGER && <ClientFilter />} */}
        //   <LocationFilter />
        //   <ViewToggle />
        // </div>
        // {isGettingContracts && (
        <div className="mx-auto mt-12">
          <Spinner color="primary" size="large" />
        </div>
      )}
      {contractsColl?.data?.length > 0 && (
        <InfiniteScroll
          className={
            selectedView === DashboardViews.GRID ? `${styles.cardViewDashboard}` : `${styles.listViewDashboard}`
          }
          dataLength={contractsColl.data.length} //This is important field to render the next data
          scrollThreshold={0.9}
          next={() => {
            console.log("fetch more data");
            triggerGetContracts.set("scroll");
          }}
          hasMore={!contractsColl.end}
          loader={
            <div className="flex justify-center mt-10">
              <Spinner color="primary" size="large" />
            </div>
          }
          endMessage={<p className="text-center font-bold">{i18n.t("dashboard.all_contracts")}</p>}>
          {contractsColl.data.map((contract: Contract) => {
            if (contract?.filterTag === "trashed") return null;
            return selectedView === DashboardViews.GRID ? (
              <ContractCard key={contract.id} contract={contract} onClick={() => navigateToContract(contract)} />
            ) : (
              <ContractListItem key={contract.id} contract={contract} onClick={() => navigateToContract(contract)} />
            );
          })}
        </InfiniteScroll>
      )}
      {!isGettingContracts && contractsColl?.data.length < 1 && <p>{i18n.t("dashboard.no_contracts_p")}</p>}
    </div>
  );
};

ContractsSection.producers([setDefaultView]);
