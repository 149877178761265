export const VariablesSufix: any = {
  minimumRent: " NOK",
  marketingCosts: "%",
  sharedCosts: " NOK",
  sharedCostsStorage: " NOK",
  minimumMarketingCost: " NOK",
  paymentProvisionFirstPaySum: " NOK",
  minimumTurnOver: " NOK",
  securityAmount: " NOK",
  storageRent: " NOK",
  turnoverPercent: "%",
  propertyCommonArea: "%",
};

export const variablesWithLongText = [
  "compulsoryDerogation",
  "specialProvisions",
  "introText",
  "propertyDescription",
  "storeConcept",
];
export const variablesOfTypeMultipleOption = [
  "vatAlternative",
  "securityAlternative",
  "paymentProvisionFirstPayDueDate",
];
export const variablesOfTypeDate = [
  "indexAdjustmentBasicIndex",
  "paymentProvisionDueDate",
  "firstTimeRegulation",
  "storeOpening",
  "rentalStart",
  "rentalEnd",
  "firstRentDate",
  "securityDeadline",
];
export const variablesOfTypeNumber = [
  "MTO",
  "minimumMarketingCost",
  "securityAmount",
  "turnoverPercent",
  "minimumRent",
  "marketingCosts",
  "sharedCosts",
  "sharedCostsStorage",
  "paymentProvisionFirstPaySum",
  "propertyExclusiveArea",
  "propertyCommonArea",
  "storagePropertyLevel",
  "storagePropertyExclusiveArea",
  "storagePropertyCommonArea",
  "storageRent",
  "vatPercent",
];

export const variablesOfTypeDropdownWithKeys = [
  "rentalPeriodOptionsPeriod",
  "storeIndustryCode",
  "securityAlternativeAditionalInfo",
];
export const variablesOfBoolean = ["rentalPeriodHasOptions", "hasStorageUnit"];
