import { db } from "src/producers/firebase";
import { ApplicationError, ContractType, ErrorLevel, ErrorType } from "src/types";

export const syncShoppingMalls: producer = async ({
  sessionReady = observe.session.firebase.isReady,
  userReady = observe.user.isReady,
  setShoppingMalls = update.domain.shoppingMalls,
  contractType = observe.contract.original.contractType,
  error = update.errors[param.id],
}) => {
  if (!sessionReady || !userReady) {
    return;
  }
  try {
    console.log("Syncing shopping malls...");
    const shoppingMallsDb = db.collection("buildings");
    const shoppingMalls = (await shoppingMallsDb.get()).docs;
    let shoppingMallObj: any = {};
    for (const shoppingMall of shoppingMalls) {
      const data = shoppingMall.data();
      if (!data) {
        continue;
      }

      if (contractType === ContractType.PARKING) {
        shoppingMallObj[shoppingMall.id] = data;
      } else if (data.type === "mall") {
        shoppingMallObj[shoppingMall.id] = data;
      }
    }

    setShoppingMalls.set(shoppingMallObj);

    console.log("Shopping malls syncing complete.");
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncShoppingMalls" }
    );
  }
};
