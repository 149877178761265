import { PartyType } from "src/types";

export const validatePartyForm: producer = async ({
  modal = observe.modal.addEditPartyModal,
  parties = get.contract.original.state.parties,
  setValidation = update.modal.addEditPartyModal.isValid,
}) => {
  if (!modal) return;
  const { partyType, recipientAction, firstName, lastName, email, phone, partyId } = modal?.data || {};

  if (
    Object.values(PartyType).includes(partyType) &&
    recipientAction !== undefined &&
    firstName?.length >= 1 &&
    lastName?.length >= 1 &&
    email?.length >= 1
  ) {
    if (modal.type === "edit") {
      const oldValues = parties.value()[partyId];
      if (
        recipientAction === oldValues.recipientAction &&
        firstName === oldValues.firstName &&
        lastName === oldValues.lastName &&
        email === oldValues.email &&
        phone === oldValues.phone
      ) {
        return;
      }
    }
    setValidation.set(true);
  }
};
