import { UserRole } from "src/types";

export const CalculationFieldAllUsers: view = ({
  label,
  value = observe[prop.readPath],
  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  return (
    <>
      {isTenant ? (
        <div style={{ padding: "10px 16px" }} className={`bg-gray-200 w-full text-right font-bold`}>
          {value !== undefined ? `${label}: ${value} m²` : ""}
        </div>
      ) : (
        <div className={"w-full flex my-2 items-center"}>
          <div className={`w-1/2 text-right font-bold pr-8`}>{`${label}:`}</div>
          <div style={{ minHeight: 38 }} className={`bg-gray-200 w-1/2 p-2 text-right font-bold`}>
            {value !== undefined ? `${value} m²` : ""}
          </div>
        </div>
      )}
    </>
  );
};
