import { Tooltip } from "antd";
import "./DragAndDropHandle.css";
import { DragDropIcon } from "src/assets/svg";

import i18n from "src/producers/languages/i18n";

export const DragAndDropHandle: view = () => {
  return (
    <div className="relative">
      <Tooltip placement={"top"} title={i18n.t("attachments_page.drag_tooltip")}>
        <div className="dnd-handle">
          <DragDropIcon/>
        </div>
      </Tooltip>
    </div>
  );
};
