import { Button, notification } from 'antd'
import i18n from 'src/producers/languages/i18n'

import './send-to-signing.notifications.css'
import { v4 } from 'uuid'

const ELEMENT_SCROLL_TOP_OFFSET = 24 //px

export const SendToSigningNotificationsProducer: producer = ({
  invalidFields = observe.contract.invalidFields,
  triggerNotifications = observe.contract.triggerInvalidFieldsNotifications,
  updateTriggerNotifications = update.contract.triggerInvalidFieldsNotifications,
}) => {
  if (!triggerNotifications) return
  if (!invalidFields) return
  if (Object.keys(invalidFields).length === 0) return

  for (const field in invalidFields) {
    let validationError = invalidFields[field]
    let message = ''
    if (validationError === 'validation_message_missing')
      message = `${i18n.t('contract.notifications.required_field_missing')}`
    else
      message = `${i18n.t('contract.notifications.invalid_field')}`

    const notificationID = v4();
    notification.error({
      key: notificationID,
      duration: 0,
      placement: 'topRight',
      closeIcon: false,
      message: message,
      description: i18n.t(`contract.labels.${field}`),
      style: {
        width: 278,
        height: 98
      },
      // description: i18n.t('contract.notifications.go_to_field'),
      // btn: <Button ghost danger style={{ marginTop: -12 }}>
      //   {i18n.t('contract.notifications.go_to_field')}
      // </Button>,
      btn: <div
        className='text-pink-600 take-me-there-button'
        onClick={() => { notification.destroy(notificationID) }}
      >
        {i18n.t('contract.notifications.go_to_field')}
      </div>,

      onClick: () => {
        const element = document.getElementById(field)
        if (!element) return

        console.log(`Found DOM element`, element)

        const boundingRect = element.getBoundingClientRect()
        if (!boundingRect || !boundingRect.top) return

        let scrollTo = window.scrollY || 0;
        scrollTo += document.body.scrollTop || 0;
        scrollTo += boundingRect.top;
        scrollTo -= ELEMENT_SCROLL_TOP_OFFSET;

        console.log(`Scrolling to`, scrollTo)

        window.scrollTo({
          top: scrollTo
        })
      }
    })
  }

  updateTriggerNotifications.set(false)
}

// const ValidationErrorList = (invalidFields) => {


// }

// type ValidationListElementProps = {
//   message: string,
//   href: string
// }

// const ValidationErrorListElement = ({
//   message,

// }: ValidationListElementProps) => {

// }
