import { ContractStatus, ContractType, RouteName } from 'src/types'
import { AgreementType } from 'src/types/commands/command'
export const setSummaryAsMainTab: producer = ({
  status = observe.contract.original.status,
  contractType = get.contract.original.contractType,
  agreementType = get.contract.original.agreementType,
  updateActiveTab = update.routes.list[param.route].data.subview
}) => {
  contractType = contractType.value()
  agreementType = agreementType.value()
  if (contractType === ContractType.PAPER || agreementType === AgreementType.ADDENDUM) return

  if (status === ContractStatus.ACTIVE || status === ContractStatus.SIGNING) {
    const route = agreementType === AgreementType.ADDENDUM ? RouteName.ADDENDUM : RouteName.CONTRACT
    updateActiveTab.set('SUMMARY', { route: route })
  }
}
