import React from 'react'
import i18n from 'i18next'

export const InvalidMessage: view = ({
  name,
  invalidField = observe.contract.invalidFields[arg.name]
}) => {
  if (!invalidField) return null
  return (
    <div key="invalidFields" className="mt-1 text-pink-600 text-xs">
      {i18n.t(invalidField)}
    </div>
  )
}
