import { DashboardViewModel } from "src/types/pages/DashboardViewModel";
import { Navbar } from "src/pages/Dashboard/Navbar/Navbar";
import { Tabs } from "src/pages/Dashboard/Tabs/Tabs";
import { ContractsSection } from "src/pages/Dashboard/ContractsSection/ContractsSection";
import { Header } from "src/pages/Dashboard/Header/Header";
import { NewAgreementModal } from "src/pages/Dashboard/NewAgreementModal/NewAgreementModal";
import { ViewToggle } from "src/pages/Dashboard/ViewToggle/ViewToggle";
import { LocationFilter } from "src/pages/Dashboard/LocationFilter/LocationFilter";
import { ClientFilter } from "src/pages/Dashboard/ClientFilter/ClientFilter";
import { NewAgreementButton } from "src/pages/Dashboard/NewAgreementButton/NewAgreementButton";
import { UserRole } from "src/types";
import { syncTenant } from "./Dashboard.syncTenant";
import { getTenantsForManager } from "./Dashboard.getTenantsForManager";
import { setFilterTag } from "./Dashboard.setFilterTag";
import { removeTag } from "./Dashboard.removeFilterTag";
import styles from "./styles.module.css";
import { userOnboarding } from "./Dashboard.userOnboarding";
import { syncFilesToS3 } from "./Dashboard.syncFilesToS3";
import { NewUserLoginModal } from "./NewUserLoginModal/NewUserLoginModal";
import { StatusDropdown } from "./StatusDropdown/StatusDropdown";
import {TestButton} from "src/pages/Dashboard/MigrateContracts/TestButton";

export const Dashboard: view<DashboardViewModel> = ({
  currentLanguage = observe.languages.current, // FIXME: fix the root cause for broken translations
  user = observe.user,
  featureFlags = observe.featureFlags,
  dashboardFiltersFlag = observe.featureFlags.dashboardFilters.main,
  searchDropdownsFf = observe.featureFlags.dashboardSearchDropdowns.main,
}) => {
  if (!currentLanguage) return null;
  const isManager = user?.data?.role === UserRole.MANAGER;
  const migrateContractsToggle = isManager  && featureFlags?.migrateContracts
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main className={`${styles.headerSection}`}>
        <div className={`${styles.dashboardWrapper}`}>
          <section className={`${styles.headerSection} `}>
            <Header />
          </section>
          <section className={`${styles.menuSection} `}>
            {isManager && (
              <div className={`${styles.newContract} `}>
                <NewAgreementButton />
              </div>
            )}

            {searchDropdownsFf && (
              <div className={`${styles.dashboardFilters} `}>
                {isManager && (
                  <ClientFilter />
                )}
                <LocationFilter />
                <StatusDropdown />
              </div>
            )}
            {!searchDropdownsFf && (
              <div className={`${styles.tabsSection} `}>
                <Tabs />
              </div>
            )}
          </section>
          <div>
            {migrateContractsToggle && (
              <TestButton />
            )}

            <ViewToggle />
          </div>
          <section className={`${styles.dashboardView} `}>
            {dashboardFiltersFlag && (
              <aside className={`${styles.filterSection} `}>
                {isManager && <ClientFilter />}
                <LocationFilter />
              </aside>
            )}
            <main className={dashboardFiltersFlag ? `w-5/6` : "w-full"}>
              <ContractsSection />
            </main>
          </section>
        </div>
      </main>
      <NewAgreementModal />
      <NewUserLoginModal />
    </>
  );
};

Dashboard.producers([setFilterTag, removeTag, syncTenant, getTenantsForManager, userOnboarding, syncFilesToS3]);
