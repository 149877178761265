import { functions } from "src/producers/firebase";
import { CommandActions } from "src/types/commands/command";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const removeParty: producer = async ({
  trigger = observe.contract.triggers.removeParty,
  updateTrigger = update.contract.triggers.removeParty,
  party = get.contract.original.state.parties[param.id],
  contractId = get.contract.id,
  error = update.errors[param.id],
}) => {
  if (!trigger) return;
  updateTrigger.remove();
  contractId = contractId.value();
  try {
    const { email, firstName, lastName, partyId, partyType, phone } = party.value({ id: trigger });
    const commandPublisher = functions.httpsCallable("eventlessCommandHandler");
    const command = {
      action: CommandActions.REMOVE_PARTY,
      contractId,
      email,
      firstName,
      lastName,
      partyId,
      phone,
      partyType,
    };
    await commandPublisher(command);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.LOW,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "removeParty-contract" }
    );
  }
};
