import React from 'react'

import { SVGs } from 'src/assets'
import i18n from 'src/producers/languages/i18n'
import { Dropdown, Menu } from 'antd'
import { PartyType } from 'src/types'

export const LandlordsDropdown: view = ({
  availableLandlords = observe.landlords,
  selectedOption = observe.modal.addEditPartyModal.selectedPartyCP,
  updateData = update.modal.addEditPartyModal.data,
  populatePartiesFormTrigger = update.contract.triggers.populatePartyForm,
}) => {
  const landlords = availableLandlords?.data;
  if (!landlords || landlords.length < 1) return null;

  const dropdownRow = (contact: any, index: any = null) => (
    <div
      onClick={() => {
        if (index !== undefined && index !== null) {
          populatePartiesFormTrigger.set({ type: PartyType.LANDLORD, option: index });
        }
      }}
      className="flex justify-between items-center px-2 py-1">
      <div>
        <p key="1" className=" text-left font-medium">
          {`${contact.firstName || ""} ${contact.lastName || ""}`}
        </p>
      </div>
    </div>
  );

  const dropdownList = (
    <Menu>
      {landlords.map((contact: any, index: any) => (
        <Menu.Item key={index}>{dropdownRow(contact, index)}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="my-2 rounded-lg bg-white p-4">
      <div className="mb-2">{i18n.t("parties_page.modal_choose_landlord_text")}</div>
      <div className="inline-block relative w-full" id="signatoryDropdown">
        <Dropdown
          overlay={dropdownList}
          trigger={["click"]}
          overlayClassName={"signatoryDropdown"}
          getPopupContainer={() => document.getElementById("signatoryDropdown") as HTMLElement}>
          <button className="bg-gray-200 p-2 flex w-full justify-between items-center border  border-gray-500 rounded-sm">
            {selectedOption != undefined ? (
              dropdownRow(landlords[selectedOption])
            ) : (
              <span className="text-pink-600">{i18n.t("parties_page.modal_select_participant")}</span>
            )}
            <SVGs.DropdownArrowOutline />
          </button>
        </Dropdown>
      </div>
    </div>
  );
};
