import { db } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'

export const syncDefaults: producer = async ({
  id = observe.contract.id,
  createdAt = observe.contract.original.createdAt,
  setDefaults = update.contract.original.defaultValues[param.varName],
  error = update.errors[param.id],
}) => {
  if (!id || !createdAt) {
    return
  }
  try {
    const snap = await db.collection('defaults').get()
    for (const doc of snap.docs) {
      let data = doc.data()
      if (!data) {
        return
      }
      data.createdAt = createdAt
      if (!data) continue
      setDefaults.merge(data, { varName: doc.id })
    }
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'syncDefaults' }
    )
  }
}
