import { Button } from "@c11/components.button";
import "./PartiesCard.css";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { ContractPageSubview, ContractStatus, RouteName } from "src/types";
import { PersonSilhouetteIcon } from "src/assets/svg";
import { ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from "src/components";

import styles from "../../../styles.module.css";

export const PartiesCard: view = ({
  setSubview = update.routes.list[RouteName.CONTRACT].data.subview,
  parties = observe.contract.original.state.parties,
  status = observe.contract.original.state.status,
}) => {
  const sortedParties = parties && Object.values(parties).sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
  const isSigning = status === ContractStatus.SIGNING;
  const isActive = status === ContractStatus.ACTIVE;
  const isSigningorActive = isSigning || isActive;
  return (
    <>
      <ThumbnailCard className={`${styles.contractSummaryCard} ${isSigningorActive && styles.borderedCard}`}>
        <ThumbnailCardHeader
          className={`${styles.summaryCardsHeader}`}
          key="tch-1"
          title={i18n.t("contract_navbar.signers")}
          separator={true}>
          <div className="float-right inline-block">
            <SVGs.OverviewParties />
          </div>
        </ThumbnailCardHeader>
        <ThumbnailCardBody key="tcb-1">
          {sortedParties &&
            sortedParties.map((p: any, index: any) => {
              const name = `${p.firstName ? p.firstName + " " : ""}${p.lastName || ""}`;
              return (
                <div key={index} className={`${styles.summaryList}`}>
                  <span>{name}</span>
                  {p.signatory && (
                    <div>
                      <SVGs.OverviewPartySign />
                    </div>
                  )}
                </div>
              );
            })}
        </ThumbnailCardBody>
        <ThumbnailCardFooter key="tcf-1">
          <Button
            className="view-signers-btn border-secondary second-theme-color"
            variant="secondary"
            onClick={() => {
              setSubview.set(ContractPageSubview.PARTIES);
            }}>
            <PersonSilhouetteIcon />
            <span key="2" className="pl-2 uppercase">
              {i18n.t("summary_page.view_signers")}
            </span>
          </Button>
        </ThumbnailCardFooter>
      </ThumbnailCard>
    </>
  );
};
