import { Switch } from "@c11/components";
import { ContractStatus, UserRole } from "src/types";
import styles from "../../../styles.module.css";

export const InsideCard: view = ({
  title,
  children,
  editableByTenant,
  hasSwitch,
  switchValue,
  onToggleSwitch,
  userRole = get.user.data.role,
  status = observe.contract.original.state.status,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const hasWhiteBackground = (editableByTenant && status == ContractStatus.NEGOTIATION) || isManager;
  return (
    <div className={`relative ${hasWhiteBackground && "bg-white"} ${styles.insideCard}`}>
      <div key="header" className="flex justify-between items-baseline">
        <h4 style={{ fontFamily: "Arial", fontSize: 14 }} className="font-bold mb-6 uppercase">
          {title}
        </h4>
        {hasSwitch && (
          <Switch
            isChecked={switchValue}
            onClick={onToggleSwitch}
            className={`${styles.headerSwitch} secondary-switch-${switchValue === "true" ? "checked" : "unchecked"}`}
          />
        )}
      </div>
      {}
      {hasSwitch ? (
        switchValue === "true" && <div className="flex flex-wrap">{children}</div>
      ) : (
        <div className="flex flex-wrap">{children}</div>
      )}
    </div>
  );
};
