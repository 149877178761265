import i18n from 'src/producers/languages/i18n'
import { Modal } from 'src/components'
import { sendSigningNotification } from './SendSigningNotificationModal.sendSigningNotification'
import { SigningNotivicationModal } from 'src/assets/svg'

export const SendSigningNotificationModal: view = ({
  trigger = observe.modal.sendSigningNotification,
  text = observe.views[prop._viewId].text,
  landlordContactPersonFirstName = observe.contract.values.landlordContactPersonFirstName.displayedValue,
  landlordContactPersonLastName = observe.contract.values.landlordContactPersonLastName.displayedValue,

  setMessage = update.views[prop._viewId].text,
  sendSigningNotification = update.contract.triggers.sendSigningNotification
}) => {
  if (!trigger) return null
  const message =
    text ||
    i18n.t('summary_page.signing_notification_modal_text', {
      name: `${landlordContactPersonFirstName && landlordContactPersonFirstName} ${
        landlordContactPersonLastName && landlordContactPersonLastName
      }`
    })
  return (
    <Modal
      title={`${i18n.t('summary_page.signing_notification_modal_title')} ${trigger.fullName}`}
      okButtonText={i18n.t('contract_navbar.send_to_signing')}
      image={() => <SigningNotivicationModal/>}
      onOkClick={() => {
        sendSigningNotification.set({
          message,
          recipientId: trigger.recipientId
        })
      }}
      okButtonDisabled={false}>
      <textarea
        className="w-full resize-none bg-gray-200 p-4 border border-gray-500"
        defaultValue={message}
        onChange={(e: any) => setMessage.set(e.target.value)}
      />
    </Modal>
  )
}

SendSigningNotificationModal.producers([sendSigningNotification])
