import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";
import { submitComment } from "src/pages/Contract/Sidebar/SendMessageCard.submitComment";

export const SendMessageCard: view = ({
  getComment = observe.comment.message,
  resetComment = observe.comment.reset,
  setComment = update.comment.message,
  submitComment = update.contract.triggers.submitComment,
  isSubmitted = observe.comment.submit,
}) => {
  return (
    <div
      style={{ height: "155px", bottom: "10px", right: "16px" }}
      className={`bg-white mx-4 p-4 border hover:shadow absolute left-0 ${!!getComment ? "shadow" : ""}`}>
      <textarea
        key={resetComment}
        className="w-full resize-none"
        style={{ height: "65px" }}
        placeholder={i18n.t("sidebar_send_message_p")}
        onChange={(e: any) => setComment.set(e.target.value)}></textarea>

      <Button
        className="uppercase w-full"
        isDisabled={!!!getComment}
        onClick={() => !isSubmitted && submitComment.set(Date.now())}>
        {i18n.t("button_send_message")}
      </Button>
    </div>
  );
};

SendMessageCard.producers([submitComment]);
