import React from "react";

import { UserRole } from "src/types";
import { Card } from "../index";
import i18n from "src/producers/languages/i18n";
import { ShoppingMallsDropdown } from "./rentalProperty/ShoppingMallsDropdown";



export const Heading: view = ({
  clause,
  userRole = get.user.data.role,
  shoppingMall = observe.legacyContract.values.shoppingMall.displayedValue,
}) => {
  if (!clause) return null;
  const isTenant = userRole.value() === UserRole.TENANT;

  return (
    <Card title={i18n.t(clause.title)} >
      {isTenant ? (
        <span key="text" className="font-bold mr-4">
          {`${i18n.t("clause_heading_text")} ${shoppingMall || ""}`}
        </span>
      ) : (
        <>
          <span key="text" className="mr-4">
            {i18n.t("clause_heading_text")}
          </span>
          <ShoppingMallsDropdown key="00" />
        </>
      )}
    </Card>
  );
};
