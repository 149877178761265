import i18n from 'src/producers/languages/i18n'
import { PeopleSilhouettesIcon } from 'src/assets/svg'
import { Button } from '@c11/components.button'

export const AddPartyButton: view = ({ partyModal = observe.modal.partyModal, updatePartyModal = update.modal.partyModal }) => {
  const IsModalOpen = () => partyModal?.isOpen

  return (
    <Button
      variant="secondary"
      className="add-party-btn uppercase"
      isDisabled={IsModalOpen()}
      onClick={() => {
        updatePartyModal.set({
          isOpen: true,
          modalType: 'add',
        })
      }}>
      <PeopleSilhouettesIcon />
      <span className="ml-2">{i18n.t('parties_page.parties_add_party')}</span>
    </Button>
  )
}
