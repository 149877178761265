'use strict'
// module.exports = validate20
// module.exports.default = validate20
export default validate20
const schema22 = {
  '$id': 'RENTAL_PERIOD_PRICE',
  'type': 'object',
  'definitions': {
    'email': { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' },
    'phone': { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
  },
  'properties': {
    'tenantContactPersonFirstName': { 'type': 'string' },
    'tenantContactPersonLastName': { 'type': 'string' },
    'tenantPhone': { '$ref': '#/definitions/phone' },
    'tenantEmail': { '$ref': '#/definitions/email' },
    'title': { 'type': 'string' },
    'backgroundAndPurpose': { 'type': 'string' },
    'otherProvisions': { 'type': 'string' },
    'hasRentalPeriodChange': { 'type': 'boolean' },
    'changeRentalPeriod': { 'type': 'string' },
    'rentalStart': { 'type': 'string' },
    'rentalEnd': { 'type': 'string' },
    'hasRentalPriceChange': { 'type': 'boolean' },
    'changeRentalPrice': { 'type': 'string' },
    'turnoverPercent': { 'type': 'number' },
    'minimumRent': { 'type': 'number' },
    'minimumTurnOver': { 'type': 'number' },
    'firstTimeRegulation': { 'type': 'string' },
    'indexAdjustmentBasicIndex': { 'type': 'string' },
    'rentalPeriodHasOptions': { 'type': 'boolean' },
    'rentalPeriodExtensionType': { 'type': 'string' },
    'rentalPeriodExtensionYears': { 'type': 'number' },
    'security': { 'type': 'string' },
    'compulsoryDerogation': { 'type': 'string' }
  },
  'required': ['tenantEmail', 'title', 'backgroundAndPurpose']
}
const schema23 = { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
const schema24 = { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' }
const pattern0 = new RegExp('^[+]?[\\d\\s()-]{7,}$', 'u')
const pattern1 = new RegExp('^\\S+@\\S+\\.\\S+$', 'u')

function validate20 (data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {/*# sourceURL="RENTAL_PERIOD_PRICE" */
  let vErrors = null
  let errors = 0
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.tenantEmail === undefined) {
      const err0 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'tenantEmail' },
        message: 'must have required property \'' + 'tenantEmail' + '\''
      }
      if (vErrors === null) {vErrors = [err0]} else {vErrors.push(err0)}
      errors++
    }
    if (data.title === undefined) {
      const err1 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'title' },
        message: 'must have required property \'' + 'title' + '\''
      }
      if (vErrors === null) {vErrors = [err1]} else {vErrors.push(err1)}
      errors++
    }
    if (data.backgroundAndPurpose === undefined) {
      const err2 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'backgroundAndPurpose' },
        message: 'must have required property \'' + 'backgroundAndPurpose' + '\''
      }
      if (vErrors === null) {vErrors = [err2]} else {vErrors.push(err2)}
      errors++
    }
    if (data.tenantContactPersonFirstName !== undefined) {
      if (typeof data.tenantContactPersonFirstName !== 'string') {
        const err3 = {
          instancePath: instancePath + '/tenantContactPersonFirstName',
          schemaPath: '#/properties/tenantContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err3]} else {vErrors.push(err3)}
        errors++
      }
    }
    if (data.tenantContactPersonLastName !== undefined) {
      if (typeof data.tenantContactPersonLastName !== 'string') {
        const err4 = {
          instancePath: instancePath + '/tenantContactPersonLastName',
          schemaPath: '#/properties/tenantContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err4]} else {vErrors.push(err4)}
        errors++
      }
    }
    if (data.tenantPhone !== undefined) {
      let data2 = data.tenantPhone
      if (typeof data2 === 'string') {
        if (!pattern0.test(data2)) {
          const err5 = {
            instancePath: instancePath + '/tenantPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {vErrors = [err5]} else {vErrors.push(err5)}
          errors++
        }
      } else {
        const err6 = {
          instancePath: instancePath + '/tenantPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err6]} else {vErrors.push(err6)}
        errors++
      }
    }
    if (data.tenantEmail !== undefined) {
      let data3 = data.tenantEmail
      if (typeof data3 === 'string') {
        if (!pattern1.test(data3)) {
          const err7 = {
            instancePath: instancePath + '/tenantEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {vErrors = [err7]} else {vErrors.push(err7)}
          errors++
        }
      } else {
        const err8 = {
          instancePath: instancePath + '/tenantEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err8]} else {vErrors.push(err8)}
        errors++
      }
    }
    if (data.title !== undefined) {
      if (typeof data.title !== 'string') {
        const err9 = {
          instancePath: instancePath + '/title',
          schemaPath: '#/properties/title/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err9]} else {vErrors.push(err9)}
        errors++
      }
    }
    if (data.backgroundAndPurpose !== undefined) {
      if (typeof data.backgroundAndPurpose !== 'string') {
        const err10 = {
          instancePath: instancePath + '/backgroundAndPurpose',
          schemaPath: '#/properties/backgroundAndPurpose/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err10]} else {vErrors.push(err10)}
        errors++
      }
    }
    if (data.otherProvisions !== undefined) {
      if (typeof data.otherProvisions !== 'string') {
        const err11 = {
          instancePath: instancePath + '/otherProvisions',
          schemaPath: '#/properties/otherProvisions/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err11]} else {vErrors.push(err11)}
        errors++
      }
    }
    if (data.hasRentalPeriodChange !== undefined) {
      if (typeof data.hasRentalPeriodChange !== 'boolean') {
        const err12 = {
          instancePath: instancePath + '/hasRentalPeriodChange',
          schemaPath: '#/properties/hasRentalPeriodChange/type',
          keyword: 'type',
          params: { type: 'boolean' },
          message: 'must be boolean'
        }
        if (vErrors === null) {vErrors = [err12]} else {vErrors.push(err12)}
        errors++
      }
    }
    if (data.changeRentalPeriod !== undefined) {
      if (typeof data.changeRentalPeriod !== 'string') {
        const err13 = {
          instancePath: instancePath + '/changeRentalPeriod',
          schemaPath: '#/properties/changeRentalPeriod/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err13]} else {vErrors.push(err13)}
        errors++
      }
    }
    if (data.rentalStart !== undefined) {
      if (typeof data.rentalStart !== 'string') {
        const err14 = {
          instancePath: instancePath + '/rentalStart',
          schemaPath: '#/properties/rentalStart/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err14]} else {vErrors.push(err14)}
        errors++
      }
    }
    if (data.rentalEnd !== undefined) {
      if (typeof data.rentalEnd !== 'string') {
        const err15 = {
          instancePath: instancePath + '/rentalEnd',
          schemaPath: '#/properties/rentalEnd/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err15]} else {vErrors.push(err15)}
        errors++
      }
    }
    if (data.hasRentalPriceChange !== undefined) {
      if (typeof data.hasRentalPriceChange !== 'boolean') {
        const err16 = {
          instancePath: instancePath + '/hasRentalPriceChange',
          schemaPath: '#/properties/hasRentalPriceChange/type',
          keyword: 'type',
          params: { type: 'boolean' },
          message: 'must be boolean'
        }
        if (vErrors === null) {vErrors = [err16]} else {vErrors.push(err16)}
        errors++
      }
    }
    if (data.changeRentalPrice !== undefined) {
      if (typeof data.changeRentalPrice !== 'string') {
        const err17 = {
          instancePath: instancePath + '/changeRentalPrice',
          schemaPath: '#/properties/changeRentalPrice/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err17]} else {vErrors.push(err17)}
        errors++
      }
    }
    if (data.turnoverPercent !== undefined) {
      let data13 = data.turnoverPercent
      if (!((typeof data13 == 'number') && (isFinite(data13)))) {
        const err18 = {
          instancePath: instancePath + '/turnoverPercent',
          schemaPath: '#/properties/turnoverPercent/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err18]} else {vErrors.push(err18)}
        errors++
      }
    }
    if (data.minimumRent !== undefined) {
      let data14 = data.minimumRent
      if (!((typeof data14 == 'number') && (isFinite(data14)))) {
        const err19 = {
          instancePath: instancePath + '/minimumRent',
          schemaPath: '#/properties/minimumRent/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err19]} else {vErrors.push(err19)}
        errors++
      }
    }
    if (data.minimumTurnOver !== undefined) {
      let data15 = data.minimumTurnOver
      if (!((typeof data15 == 'number') && (isFinite(data15)))) {
        const err20 = {
          instancePath: instancePath + '/minimumTurnOver',
          schemaPath: '#/properties/minimumTurnOver/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err20]} else {vErrors.push(err20)}
        errors++
      }
    }
    if (data.firstTimeRegulation !== undefined) {
      if (typeof data.firstTimeRegulation !== 'string') {
        const err21 = {
          instancePath: instancePath + '/firstTimeRegulation',
          schemaPath: '#/properties/firstTimeRegulation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err21]} else {vErrors.push(err21)}
        errors++
      }
    }
    if (data.indexAdjustmentBasicIndex !== undefined) {
      if (typeof data.indexAdjustmentBasicIndex !== 'string') {
        const err22 = {
          instancePath: instancePath + '/indexAdjustmentBasicIndex',
          schemaPath: '#/properties/indexAdjustmentBasicIndex/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err22]} else {vErrors.push(err22)}
        errors++
      }
    }
    if (data.rentalPeriodHasOptions !== undefined) {
      if (typeof data.rentalPeriodHasOptions !== 'boolean') {
        const err23 = {
          instancePath: instancePath + '/rentalPeriodHasOptions',
          schemaPath: '#/properties/rentalPeriodHasOptions/type',
          keyword: 'type',
          params: { type: 'boolean' },
          message: 'must be boolean'
        }
        if (vErrors === null) {vErrors = [err23]} else {vErrors.push(err23)}
        errors++
      }
    }
    if (data.rentalPeriodExtensionType !== undefined) {
      if (typeof data.rentalPeriodExtensionType !== 'string') {
        const err24 = {
          instancePath: instancePath + '/rentalPeriodExtensionType',
          schemaPath: '#/properties/rentalPeriodExtensionType/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err24]} else {vErrors.push(err24)}
        errors++
      }
    }
    if (data.rentalPeriodExtensionYears !== undefined) {
      let data20 = data.rentalPeriodExtensionYears
      if (!((typeof data20 == 'number') && (isFinite(data20)))) {
        const err25 = {
          instancePath: instancePath + '/rentalPeriodExtensionYears',
          schemaPath: '#/properties/rentalPeriodExtensionYears/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {vErrors = [err25]} else {vErrors.push(err25)}
        errors++
      }
    }
    if (data.security !== undefined) {
      if (typeof data.security !== 'string') {
        const err26 = {
          instancePath: instancePath + '/security',
          schemaPath: '#/properties/security/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err26]} else {vErrors.push(err26)}
        errors++
      }
    }
    if (data.compulsoryDerogation !== undefined) {
      if (typeof data.compulsoryDerogation !== 'string') {
        const err27 = {
          instancePath: instancePath + '/compulsoryDerogation',
          schemaPath: '#/properties/compulsoryDerogation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {vErrors = [err27]} else {vErrors.push(err27)}
        errors++
      }
    }
  } else {
    const err28 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object'
    }
    if (vErrors === null) {vErrors = [err28]} else {vErrors.push(err28)}
    errors++
  }
  validate20.errors = vErrors
  return errors === 0
}
