import { isNull, isUndefined, omitBy } from 'lodash'
import { db } from 'src/producers/firebase'
import { ApplicationError, LogLevels } from 'src/types'
import { v4 } from 'uuid'

export const persistPartyUpdate: producer = async ({
  isLocked = get.contract.isLocked.value(),
  contractId = get.contract.id.value(),

  signers = get.contract.originalv2.signers.value(),
  updateContractSigners = update.contract.originalv2.signers,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  updateParty = observe.contract.changes.parties.update,
  updateUpdateParty = update.contract.changes.parties.update,
}) => {
  if (!updateParty) return
  if (Object.keys(updateParty).length < 1) return
  if (isLocked) return
  if (!contractId) return

  let nextPartyIndex = 1
  if (!!signers && Object.values(signers).length > 0) {
    nextPartyIndex = Math.max(
      ...Object.values(signers)
        .map((s) => s.order)
        .filter((s) => !!s)
    )
    nextPartyIndex += 1
  }

  let sanitizedPartyData = omitBy(updateParty, isUndefined)
  sanitizedPartyData = omitBy(sanitizedPartyData, isNull)

  sanitizedPartyData.partyId = sanitizedPartyData.partyId || v4()
  sanitizedPartyData.order = sanitizedPartyData.order || nextPartyIndex

  try {
    isSaving.set(true)

    const updatedAt = new Date().toISOString()
    await db
      .collection('contracts')
      .doc(contractId)
      .set(
        {
          signers: {
            [sanitizedPartyData.partyId]: sanitizedPartyData,
          },
          updatedAt,
        },
        {
          merge: true,
        }
      )

    updateContractSigners.merge({
      [sanitizedPartyData.partyId]: sanitizedPartyData,
    })

    successfulSave.set(true)
  } catch (e) {
    console.error('persist-update-party', e)
    failedSave.set(true)
    error.set(
      {
        message: 'saving-tenant-to-database',
        level: LogLevels.WARN,
      } as ApplicationError,
      { id: 'persist-update-party' }
    )
  } finally {
    updateUpdateParty.remove()

    isSaving.set(false)
  }
}
