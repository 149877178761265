import { State, RouteName, ContractFilterOption } from "src/types";
import { routes } from "src/producers/routing/routes";

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const state: DeepPartial<State> = {
  config: {},
  session: {
    firebase: {
      isReady: false,
    },
  },
  listeners: {},
  routes: {
    current: {},
    currentPath: "",
    ids: [
      RouteName.DASHBOARD,
      RouteName.INITIALIZATION,
      RouteName.LOGIN,
      RouteName.CONTRACT,
      RouteName.LEGACY_CONTRACT,
      RouteName.ADDENDUM,
    ],
    list: routes,
  },
};
