import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType, UserRole } from "src/types";

export const setFilterTag: producer = async ({
  trigger = observe.triggers.setFilterFlag,
  user = get.user,
  updateTrigger = update.triggers.setFilterFlag,
  error = update.errors[param.id],
}) => {
  user = user.value();
  if (!trigger || !trigger.contractId || user.data.role != UserRole.MANAGER) {
    return;
  }
  try {
    await db
      .collection("contracts")
      .doc(String(trigger.contractId))
      .set(
        {
          filterTag: trigger.tag,
          filterTagSetBy: {
            uid: user.uid,
            name: `${user.data.displayName || ""}`,
          },
        },
        { merge: true }
      );
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "setFilterTag-addendum" }
    );
  } finally {
    updateTrigger.remove();
  }
};
