import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";

export const ManagerSigningButton: view = ({
  pdfServerLoading = observe.legacyContract.flags.loadingpdf,
  generatePDF = update.legacyContract.triggers.generatePDF,
}) => {
  return (
    <Button
      className="uppercase"
      variant="primary"
      onClick={() => generatePDF.set(Date.now())}
      isDisabled={pdfServerLoading}>
      <SVGs.DownloadContractPage key="1" className="mr-2" />
      {i18n.t("contract_navbar.button_download_contract")}
    </Button>
  );
};
