import React from "react";

import i18n from "src/producers/languages/i18n";
import { CheckboxType2 } from "../../fields/formFields/CheckboxType2";
import { FactoryFormField } from "../../fields/formFields/FactoryFormField";

export const SecurityManager: view = ({
  checkedState,
  setSecurityAlternative = update.legacyContract.currentSession.changes.securityAlternative,
  securityAlternativeAditionalInfo = observe.legacyContract.values.securityAlternativeAditionalInfo.displayedValue,
}) => {
  function handleClick(c: any) {
    if (!checkedState[c]) setSecurityAlternative.set({ value: c + 1, createdAt: Date.now() });
  }

  return (
    <>
      <CheckboxType2
        key="0"
        label={i18n.t("clause_security_alternative_1_text") + " "}
        checked={checkedState[0]}
        onClick={() => handleClick(0)}>
        {checkedState[0] && (
          <div key="hasSecurity" className="ml-4 flex flex-wrap items-center mt-2">
            <FactoryFormField
              key="0"
              disabled={checkedState[1]}
              fieldName="securityAlternativeAditionalInfo"
              varName="securityAlternativeAditionalInfo"
            />
            {securityAlternativeAditionalInfo === "securityAlternativeAditionalInfo_o5" && (
              <div key="00" className="w-full">
                <FactoryFormField
                  key="7"
                  fieldName="securityAlternativeAditionalInfoOther"
                  varName="securityAlternativeAditionalInfoOther"
                />
              </div>
            )}
            <p className="pr-3" key="1">
              {i18n.t("clause_security_sum_text")}
            </p>
            <div className="flex-auto" key="2">
              <FactoryFormField key="23" fieldName="securityAmount" varName="securityAmount" />
            </div>
            <p key="3" className="px-3">
              {i18n.t("clause_security_deadline")}
            </p>
            <div key="4">
              <FactoryFormField key="7" fieldName="securityDeadline" varName="securityDeadline" />
            </div>
          </div>
        )}
      </CheckboxType2>
      <CheckboxType2
        label={i18n.t("clause_security_alternative_2_text") + " "}
        checked={checkedState[1]}
        onClick={() => handleClick(1)}
        key="1"
      />
    </>
  );
};
