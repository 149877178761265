import { db } from "src/producers/firebase";
import { DashboardFilters, UserRole, ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const getTenantsForManager: producer = async ({
  isReady = observe.user.isReady,
  tenantNumber = observe.routes.queryParams.tenantNumber,
  trigger = observe.pages.dashboard.triggers.resyncTenantsWithContracts,
  setTenants = update.pages.dashboard.filteredTenants.data,
  setTenantsFilters = update.pages.dashboard.filteredTenants.filters[param.type],
  triggerGetContracts = update.pages.dashboard.triggers.getContracts,
  updateCurrentValue = update.pages.dashboard.filters[DashboardFilters.CLIENT],
  setTrigger = update.pages.dashboard.triggers.resyncTenantsWithContracts,
  error = update.errors[param.id],
  user = get.user,
}) => {
  user = user.value();
  if ((!isReady || user?.data?.role !== UserRole.MANAGER) && !trigger) {
    return;
  }
  try {
    const tenants: any = {};

    console.log("Syncing tenants (with contracts)...");
    const tenantsWithContractsDb = db.collection("tenantsWithContracts");

    (await tenantsWithContractsDb.get()).docs.forEach((c: any) => {
      const data = c.data();
      tenants[data.id] = data;
    });

    if (tenantNumber) {
      const tenantsDb = db.collection("tenants");
      const snapshot = await tenantsDb.where("number", "==", String(tenantNumber)).limit(1).get();
      snapshot.forEach((doc) => {
        const data = doc.data();
        if (!data) return;
        updateCurrentValue.set({ id: data.id });
        if (!tenants[data.id])
          tenants[data.id] = {
            id: data.id,
            name: data.name || data.id,
          };
      });
    }
    setTenants.set({ ...tenants });
    setTenantsFilters.set(true, { type: "withContracts" });
    triggerGetContracts.set({ trigger: Date.now() });
    console.log("Tenants syncing complete.");
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "getTenantsForManager" }
    );
  } finally {
    if (trigger) setTrigger.remove();
  }
};
