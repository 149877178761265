import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from 'src/types'
import { db, fieldValue } from 'src/producers/firebase'

let timer: any = null

export const setEditor: producer = ({
  changes = observe.contract.changes,
  isEditedBy = get.contract.originalv2.isEditedBy.value(),
  contractId = get.contract.id.value(),
  uid = get.user.uid.value(),

  error = update.errors[param.id],
}) => {
  try {
    if (!changes || Object.keys(changes).length < 1) return
    if (isEditedBy && isEditedBy !== uid) return

    if (!isEditedBy) db.collection('contracts').doc(contractId).update({ isEditedBy: uid })

    if (timer) clearTimeout(timer)

    timer = setTimeout(async () => {
      db.collection('contracts').doc(contractId).update({ isEditedBy: fieldValue.delete() })
    }, 300000)
  } catch (e) {
    error.set(
      {
        message: e,
        level: LogLevels.ERROR,
      } as ApplicationError,
      { id: 'envelope' }
    )
  }
}
