import { db, functions } from "src/producers/firebase";
import { ApplicationError, ErrorLevel } from "src/types";
import { CommandActions } from "src/types/commands/command";
//TODO refactor with async
export const linkTenant: producer = async ({
  trigger = observe.legacyContract.triggers.linkTenant,
  removeTrigger = update.legacyContract.triggers.linkTenant,
  contractId = observe.legacyContract.id,
  contractTenantId = get.legacyContract.original.tenantId,
  populateTenant = update.legacyContract.triggers.populateTenant,
  setLoading = update.legacyContract.flags.loadingTenant,
  error = update.errors[param.type],
}) => {
  if (!trigger || !contractId /*|| contractTenantId.value() == trigger*/) {
    return;
  }
  removeTrigger.remove();
  try {
    setLoading.set(performance.now());

    const syncSingleTenant = functions.httpsCallable("syncSingleTenant");
    const result = await syncSingleTenant({ tenantId: trigger });
    if (!result.data) throw new Error("Could not find tenant!");
    const { tenant, mainContacts } = result.data;

    await db.collection("contracts").doc(contractId).set({ tenantId: tenant.id }, { merge: true });

    // const commandPublisher = functions.httpsCallable("commandPublisher");
    // const command = {
    //   action: CommandActions.SELECT_TENANT,
    //   contractId: contractId,
    //   tenant: trigger,
    // };
    // commandPublisher(command).catch((err: any) => console.error(err))

    populateTenant.set({ ...tenant, mainContacts });
    setLoading.remove();
  } catch (e) {
    console.error("linkTenant", e);
    error.set({
      message: "error-link-tenant",
      level: ErrorLevel.WARNING
    } as ApplicationError, { type: "tenant" });
    setLoading.remove();
  }
};
