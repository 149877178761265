export const generatePDF: producer = async ({
  trigger = observe.legacyContract.triggers.generatePDF,
  removeTrigger = update.legacyContract.triggers.generatePDF,
  getMainContractPDF = update.legacyContract.triggers.getMainContractPDF,
  getAllAppendicesPDF = update.legacyContract.triggers.getAllAppendicesPDF,
  setLoading = update.legacyContract.flags.loadingpdf,
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  getMainContractPDF.set(performance.now());
  getAllAppendicesPDF.set(performance.now());
  setLoading.set(performance.now());
};
