import moment from "moment";
import AriaModal from "react-aria-modal";

import i18n from "src/producers/languages/i18n";
import { ContractEventType, UserRole } from "src/types";
import {
  variablesOfBoolean,
  variablesOfTypeDate,
  variablesOfTypeDropdownWithKeys,
  variablesOfTypeMultipleOption,
  variablesOfTypeNumber,
  VariablesSufix,
} from "src/utils/variablesInfo";
import { SVGs } from "src/assets";
import { Images } from "src/assets";
import { diffWords } from "diff";
import { ReceivedIcon, SentIcon, ModalCancel } from "src/assets/svg";
import _numeral from "src/utils/_numeral";
import styles from "../styles.module.css";

export const ActivityLogModal: view = ({
  show = observe.modal.activityLog,
  activityLog = observe.contract.activity,
  shoppingMall = observe.contract.values.shoppingMall.displayedValue,
  tenantName = observe.contract.values.tenantName.displayedValue,
  allModals = update.modal,
}) => {
  if (!show || !activityLog) return null;

  return (
    <AriaModal
      titleId="modal"
      underlayClickExits={true}
      verticallyCenter={true}
      onExit={() => allModals.remove()}
      dialogStyle={{ width: 1440 }}>
      <div className={`${styles.activityLogModal}`}>
        <header className={`${styles.activityLogModalHeader}`}>
          <div className={`${styles.logoContainer}`}>
            <img src={Images.logo} alt="Logo" />
          </div>
          <div className={`${styles.titleContainer}`}>
            <div>
              <span>{i18n.t("contract_navbar.activity_log")}:</span>
              <p>{`${i18n.t("general.contract")} ${shoppingMall || ""} ${tenantName || ""}`}</p>
            </div>
            <button className="border-focus">
              <SVGs.ModalCancel onClick={() => allModals.remove()} />
            </button>
          </div>
        </header>
        <div className={`${styles.activityLogModalBody}`}>
          {activityLog.map((e: any, index: any) => (
            <div key={index} className="my-6">
              <Event event={e} eventIndex={index} />
            </div>
          ))}
        </div>
      </div>
    </AriaModal>
  );
};

const Event: view = ({ event, eventIndex }) => {
  switch (event.type as ContractEventType) {
    case ContractEventType.CREATED:
      return <GreenCard text={i18n.t("activity_log.created")} userId={event.user} timestamp={event.createdAt} />;
    case ContractEventType.OFFER_SENT:
      return (
        <>
          <GreenCard text={i18n.t("activity_log.offer_sent")} userId={event.user} timestamp={event.createdAt} />
          <NewVersionEvent event={event} />
        </>
      );

    case ContractEventType.SENT_FOR_SIGNING:
      return (
        <GreenCard text={i18n.t("activity_log.sent_for_signing")} userId={event.user} timestamp={event.createdAt} />
      );
    case ContractEventType.CANCELLED_SIGNING:
      return (
        <GreenCard text={i18n.t("activity_log.cancelled_signing")} userId={event.user} timestamp={event.createdAt} />
      );
    case ContractEventType.CONTRACT_PDF_ATTACHED:
      return (
        <GreenCard
          text={i18n.t("activity_log.contract_pdf_attached")}
          userId={event.user}
          timestamp={event.createdAt}
        />
      );

    case ContractEventType.GUARANTEE_UPLOADED:
      return (
        <GreenCard text={i18n.t("activity_log.guarantee_uploaded")} userId={event.user} timestamp={event.createdAt} />
      );
    case ContractEventType.EXECUTED:
      return <GreenCard text={i18n.t("activity_log.signed")} userId={event.user} timestamp={event.createdAt} />;
    case ContractEventType.VERSION_CREATED:
      return <NewVersionEvent event={event} />;
    case ContractEventType.COMMENT_ADDED:
      return <Messages event={event} userId={event.user} timestamp={event.createdAt} eventIndex={eventIndex} />;
    default:
      return <GreenCard text={event.type} userId={event.user} timestamp={event.createdAt} />;
  }
};

const Messages: view = ({ event, eventIndex, activityLog = observe.contract.activity }) => {
  if (eventIndex > 0 && activityLog[eventIndex - 1]?.type === ContractEventType.COMMENT_ADDED) return null;

  let currentIndex = eventIndex;
  const messagesChunk = [];
  do {
    const currentMessageEvent = activityLog[currentIndex];
    const sender = currentMessageEvent?.metadata?.user;
    const currentMessageEl = (
      <div key={currentIndex} className=" p-2  pr-4 border-green-400 border-t flex items-center">
        <div>{sender?.role == UserRole.MANAGER ? <SentIcon /> : <ReceivedIcon />}</div>
        <div className="pl-3 flex-grow">{currentMessageEvent?.payload?.message || ""}</div>
        <div className="text-gray-600 flex justify-end">
          <p className="pr-5 ">{sender?.fullName || ""}</p>
          <p className=" ">{moment(currentMessageEvent.createdAt).format("DD.MM.YY HH:mm:ss")}</p>
        </div>
      </div>
    );
    messagesChunk.push(currentMessageEl);
    currentIndex++;
  } while (activityLog[currentIndex]?.type === ContractEventType.COMMENT_ADDED);

  return (
    <div className="border rounded-lg border-green-400">
      <div className="uppercase p-2" style={{ letterSpacing: "0.04em", fontSize: 11 }}>
        {i18n.t("activity_log.messages")}
      </div>

      {messagesChunk}
    </div>
  );
};

const NewVersionEvent: view = ({ event }) => {
  let changesList: any = [];
  const payload = event.payload;

  const versionNumber = payload?.updates?.version || 0;
  if (payload.fields) {
    changesList = Object.keys(payload.fields).map((varName, index) => {
      const newValue = payload.fields[varName].current?.value || "";

      const oldValue = payload.fields[varName].previous?.value || "";
      return (
        <Change
          varName={varName}
          oldValue={oldValue}
          newValue={newValue}
          index={index}
          user={event?.metadata?.user?.fullName}
          createdAt={event?.createdAt}
        />
      );
    });
  }
  if (payload?.updates?.attachments) {
    const attachments = payload?.updates?.attachments;
    Object.keys(attachments).forEach((a) => {
      if (attachments[a].toDelete)
        changesList.push(
          <p>
            {i18n.t("activity_log.attachment_removed")}: {attachments[a].fileName}
          </p>
        );
      else if (attachments[a].fileType)
        changesList.push(
          <p>
            {i18n.t("activity_log.attachment_added")}: {attachments[a].fileName}
          </p>
        );
      else if (attachments[a].selected !== undefined)
        changesList.push(
          <p>
            {attachments[a].selected
              ? i18n.t("activity_log.attachment_included")
              : i18n.t("activity_log.attachment_excluded")}
            : {a}
          </p>
        );
    });
  }
  if (payload?.updates?.parties) {
    const party = payload?.updates?.parties;
    Object.keys(party).forEach((a) => {
      if (party[a].toDelete)
        changesList.push(
          <p>
            {i18n.t("activity_log.party_removed", {
              name: `${party[a]?.firstName || ""} ${party[a]?.lastName || ""}`,
            })}
          </p>
        );
      else if (party[a]?.partyId)
        changesList.push(
          <p>
            {i18n.t("activity_log.party_added", {
              name: `${party[a]?.firstName || ""} ${party[a]?.lastName || ""}`,
              role: i18n.t(`general.${party[a].partyType}`),
            })}
          </p>
        );
      else
        changesList.push(
          <p>
            {i18n.t("activity_log.party_updated", {
              name: a,
            })}
          </p>
        );
    });
  }

  return (
    <div>
      <div className="flex mr-4 mb-6 ">
        <p className="flex-grow font-bold text-xl">{i18n.t("activity_log.version_created", { n: versionNumber })}</p>
        <p className="">{`${i18n.t("activity_log.published")}: ${moment(event?.createdAt).format(
          "DD.MM.YY HH:mm:ss"
        )}`}</p>
      </div>

      <div className="border-t pt-3">{changesList}</div>
    </div>
  );
};

const Change: view = ({
  varName,
  index,
  _viewId,
  newValue,
  oldValue,
  user,
  createdAt,
  isExpanded = observe.views[prop._viewId].expanded,
  setExpanded = update.views[prop._viewId].expanded,
}) => {
  const maxLength = 190;
  let caracterCount = 0;
  const changedBy = user;
  let formatedDiff = [];

  if (variablesOfTypeMultipleOption.includes(varName)) {
    formatedDiff.push(`${i18n.t("contract_sidebar_changes_vat_option")} `);
  }

  if (variablesOfTypeDate.includes(varName)) {
    oldValue = oldValue ? moment(oldValue).format("DD.MM.YY") : "";
    newValue = moment(newValue).format("DD.MM.YY");
  }
  if (variablesOfTypeNumber.includes(varName)) {
    oldValue = oldValue ? _numeral(Number(oldValue)).format("0,0.[00]") : "";
    newValue = _numeral(Number(newValue)).format("0,0.[00]");
  }

  if (variablesOfTypeDropdownWithKeys.includes(varName)) {
    oldValue = `${i18n.t(oldValue)}`;
    newValue = `${i18n.t(newValue)}`;
  }

  if (variablesOfBoolean.includes(varName)) {
    if (varName === "rentalPeriodHasOptions") {
      newValue =
        newValue === "true"
          ? i18n.t("activity_log.rentalPeriodHasOptions_true")
          : i18n.t("activity_log.rentalPeriodHasOptions_false");
      oldValue =
        oldValue === "true"
          ? i18n.t("activity_log.rentalPeriodHasOptions_true")
          : i18n.t("activity_log.rentalPeriodHasOptions_false");
    }
    if (varName === "hasStorageUnit") {
      newValue =
        newValue === "true" ? i18n.t("activity_log.hasStorageUnit_true") : i18n.t("activity_log.hasStorageUnit_false");
      oldValue =
        oldValue === "true" ? i18n.t("activity_log.hasStorageUnit_true") : i18n.t("activity_log.hasStorageUnit_false");
    }
  }

  // determine the difference
  // skip some variables that we want to display the changes in full
  if (variablesOfTypeDate.includes(varName) || variablesOfBoolean.includes(varName)) {
    if (oldValue) formatedDiff.push(<del className="inline text-gray-600">{oldValue}</del>);
    formatedDiff.push(<p className="inline font-bold">{newValue}</p>);
  } else {
    const difference = diffWords(String(oldValue), String(newValue));
    difference.forEach((diff) => {
      let value = diff.value || "";
      if (!isExpanded) {
        if (caracterCount >= maxLength) return;
        else if (value.length + caracterCount >= maxLength) value = value.slice(0, maxLength - caracterCount);
        caracterCount += value.length;
      }

      if (!diff.added && !diff.removed) formatedDiff.push(value);
      else if (diff.added) formatedDiff.push(<p className="inline font-bold">{value}</p>);
      else if (diff.removed) formatedDiff.push(<del className="inline text-gray-600">{value}</del>);
    });
    const valueSuffix = `${VariablesSufix[varName] ? VariablesSufix[varName] : ""}`;
    formatedDiff.push(valueSuffix);
  }
  return (
    <div key={index} className="flex justify-between mr-4">
      <p className="uppercase " style={{ flex: "0 0 300px" }}>{`[${i18n.t(varName) || varName}]`}</p>
      <div style={{ flex: "0 0 700px" }}>
        <div className="inline">
          {formatedDiff}
          {caracterCount >= maxLength && "..."}
        </div>
        {(caracterCount >= maxLength || isExpanded) && (
          <button className="text-pink-600 ml-2 inline" onClick={() => setExpanded.set(!isExpanded)}>
            {isExpanded ? i18n.t("sidebar.colapse") : i18n.t("sidebar.details")}
          </button>
        )}
      </div>
      <div className="text-gray-600 flex justify-end" style={{ flex: "0 0 300px" }}>
        <p className="pr-5 ">{changedBy || ""}</p>
        <p>{moment(createdAt).format("DD.MM.YY HH:mm:ss")}</p>
      </div>
    </div>
  );
};

const GreenCard: view = ({ text, icon, userId, timestamp, user = observe.users[prop.userId] }) => {
  const arrowSVG = (
    <svg width="15" height="14" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.87982 13.8592C9.54313 14.2091 8.98315 14.2091 8.64645 13.8592C8.32772 13.528 8.32748 13.0042 8.64592 12.6728L12.7747 8.375L0.875 8.375C0.391751 8.375 3.27103e-07 7.98325 3.0598e-07 7.5C2.84856e-07 7.01675 0.391751 6.625 0.875 6.625L12.7747 6.625L8.64314 2.33972C8.32171 2.00633 8.32148 1.47844 8.64261 1.14477C8.9814 0.792738 9.54487 0.792737 9.88366 1.14477L14.6653 6.11313C15.4105 6.88752 15.4105 8.11248 14.6653 8.88687L9.87982 13.8592Z"
        fill="#333333"
      />
    </svg>
  );

  const attachmentSVG = (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.85147 13.7599L12.9625 10.4004L13.9993 11.5202L9.85147 16.0004L6.99927 12.9196L8.03677 11.7998L9.85147 13.7599Z"
        fill="#333333"
      />
      <path
        d="M9.33048 0L13.9992 4.8L14 8C13.6111 8 12.8333 8 12.4445 8L12.4438 5.6H8.55508V1.6H1.55547V14.4H5.49081C5.49081 15.2 5.49081 15.6 5.49081 16H0.77229C0.583235 16 0.400752 15.9286 0.259419 15.7995C0.118085 15.6703 0.0277195 15.4923 0.00544412 15.2992L0 15.2064V0.7936C0 0.3952 0.300983 0.052 0.688295 0.00559998L0.77929 0H9.33048Z"
        fill="#333333"
      />
    </svg>
  );
  return (
    <div className=" rounded-lg" style={{ backgroundColor: "rgba(44, 176, 138, 0.15)" }}>
      <div className="my-2 py-3 px-4  flex items-center">
        {icon === "attachment" ? attachmentSVG : arrowSVG}
        <p className="font-bold pl-5 flex-grow" style={{ fontSize: 15 }}>
          {text}
        </p>
        <p className="pr-5 text-gray-800">{user?.displayName || ""}</p>
        <p className=" text-gray-800">{moment(timestamp).format("DD.MM.YY HH:mm:ss")}</p>
      </div>
    </div>
  );
};
