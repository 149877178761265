import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { addGuaranteeDocument } from "src/pages/Contract/Navbar/UploadGuaranteeButton.addGuaranteeDocument";

export const UploadGuaranteeButton: view = ({
  _viewId,
  saveGuaranteeDocument = update.contract.triggers.addGuaranteeDocument,
  attachments = observe.contract.original.state.attachments,
  tempAttachments = observe.contract.tempAttachments,
  deletedAppendix = observe.contract.deletedAppendix,
  setOpenDialog = update.views[prop._viewId].openDialog,
  openDialog = observe.views[prop._viewId].openDialog,
}) => {
  if (!attachments) return null;
  if (tempAttachments) {
    attachments = { ...tempAttachments, ...attachments };
  }

  if (deletedAppendix) {
    attachments = Object.entries(attachments)
      .filter((x) => !deletedAppendix[x[1].fileId])
      .reduce((acc, x) => {
        acc[x[0]] = x[1];
        return acc;
      }, {});
  }

  const guaranteeUploaded = Object.values(attachments).filter((a: any) => a.domain === "guarantee")?.length > 0;
  if (guaranteeUploaded) return null;
  return (
    <div
      className="text-pink-600 cursor-pointer mr-8 flex uppercase border border-pink-600 px-3 py-2"
      onClick={() => {
        !openDialog && document.getElementById("upload-guarantee")?.click();
        //This logic is here to prevent double clicks
        setOpenDialog.set(true);
        setTimeout(() => setOpenDialog.set(false), 1000);
      }}>
      <SVGs.UpploadAppendix key="5" />
      <input
        id="upload-guarantee"
        type="file"
        onChange={(e) => {
          saveGuaranteeDocument.set({ data: e.target.files[0] });
        }}
        accept="application/pdf, image/png, image/jpeg, image/jpg"
      />
      <div key="45" className="ml-2 cursor-pointer">
        {i18n.t("contract_navbar.upload_guarantee")}
      </div>
    </div>
  );
};

UploadGuaranteeButton.producers([addGuaranteeDocument]);
