import { UserRole, ApplicationError, ErrorLevel, ErrorType } from "src/types";
import { db } from "src/producers/firebase";

export const syncTenant: producer = async ({
  isReady = observe.user.isReady,
  user = get.user,
  triggerGetContracts = update.pages.dashboard.triggers.getContracts,
  updateTenant = update.tenant[param.id],
  setselectedTenantId = update.pages.dashbord.selectedTenantId,
  error = update.errors[param.id],
}) => {
  user = user.value();
  if (!isReady || user.data?.role !== UserRole.TENANT) {
    return;
  }
  try {
    const tenantsDb = db.collection("tenants");
    let selectedTenantId: any;
    if (user.data?.tenantId) {
      const tenantIds = user.data?.tenantId;

      const snapshot = await tenantsDb.where("id", "in", tenantIds).get();
      snapshot.forEach((doc: any) => {
        const data = doc.data();

        if (!data) return;
        if (!selectedTenantId) selectedTenantId = data.id;
        updateTenant.set(data, { id: data.id });
      });
    }
    setselectedTenantId.set(selectedTenantId);
    triggerGetContracts.set({ trigger: Date.now(), selectedTenantId });
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "syncTenant" }
    );
  }
};
