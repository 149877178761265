import { ContractSigningMethod, ContractStatus, ContractType, UserRole } from "src/types";
import { UploadSignedContractButton } from "src/pages/Contract/UploadSignedContractButton/UploadSignedContractButton";
import { AgreementType } from "src/types/commands/command";
import { SendContractButton } from "./SendContractButton";
import { SendToSigningButton } from "./SendToSigningButton";
import { TenantDownloadPDFButton } from "./TenantDownloadPDFButton";
import { StopSigningButton } from "./StopSigningButton";
import { DownloadSignedContractButton } from "./DownloadSignedContractButton";
import { SavePaperContractButton } from "./SavePaperContractButton";
import { SendAddendumButton } from "./SendAddendumButton";

export const ContractNavbarButton: view = ({
  status = observe.contract.original.state.status,
  signing = observe.contract.original.state.signing,
  contractType = observe.contract.original.state.contractType,
  agreementType = observe.contract.original.agreementType,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isTenant = userRole.value() === UserRole.TENANT;
  const isDraft = status === ContractStatus.DRAFT;
  const isNegotiation = status === ContractStatus.NEGOTIATION;
  const isSigning = status === ContractStatus.SIGNING;
  const isActive = status === ContractStatus.ACTIVE;
  const isDigitalSigning = signing === ContractSigningMethod.DIGITAL;
  const isPaperContract = contractType === ContractType.PAPER;

  if (isTenant) {
    if (isNegotiation || isSigning) return <TenantDownloadPDFButton />;
    if (isActive) return <DownloadSignedContractButton />;
  }

  if (isManager) {
    if (isDraft) {
      if (agreementType === AgreementType.ADDENDUM) return <SendAddendumButton />;
      return !isPaperContract ? <SendContractButton /> : <SavePaperContractButton />;
    }
    if (isNegotiation) return <SendToSigningButton />;
    if (isSigning)
      return (
        <div className="flex flex-row gap-4">
          <StopSigningButton />
          {!isDigitalSigning && <UploadSignedContractButton />}
        </div>
      );
    if (isActive) return <DownloadSignedContractButton />;
  }
  return null;
};
