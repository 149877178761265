import { v4 } from "uuid";
import { InputFieldLabel, InputFieldLabelReadOnly } from "./InputFieldLabel";
import { InputFieldParams, InputFieldReadOnlyParams } from "./InputFieldTypes";
import { ComposeHtmlClassName } from "./compose-html-class-name";
import { InvalidMessage } from "src/pages/ContractV2/components/InvalidMessage";

type TextFieldReadOnlyParams = InputFieldReadOnlyParams<string>
type TextFieldParameters = InputFieldParams<string>

export const TEXT_FIELD_CLASS_NAMES = "w-full p-3 mt-auto appearance-none shadow-sm border klp-border1 text-gray-800 leading-tight focus:bg-green-300 focus:border-transparent focus:outline-none";
export const TEXT_FIELD_RO_CLASS_NAMES = "w-full p-2 appearance-none shadow-sm text-gray-800 leading-tight focus:bg-green-300 bg-gray-200";

export const TextField = ({
  name, label, value, required, placeholder, extraClassNames, inputExtraClassNames,
  onChange, onFocus, onBlur
}: TextFieldParameters) => {

  return <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)} style={{ flexBasis: 0 }}>
    <InputFieldLabel htmlFor={name} label={label} required={required} />
    <input type="text" name={name} id={name}
      className={ComposeHtmlClassName(TEXT_FIELD_CLASS_NAMES, inputExtraClassNames)}
      autoComplete="off"
      placeholder={`${placeholder || ''}`}
      defaultValue={value}
      onFocus={e => onFocus && onFocus(e.target.value)}
      onBlur={e => onBlur && onBlur(e.target.value)}
      onChange={e => onChange(e.target.value)}
    />
    <InvalidMessage name={name} />
  </div>
}

export const TextFieldReadOnly = ({
  label, value, required, extraClassNames, inputExtraClassNames
}: TextFieldReadOnlyParams) => {

  return <div className={ComposeHtmlClassName(`flex flex-col flex-grow`, extraClassNames)} style={{ flexBasis: 0 }}>
    <InputFieldLabelReadOnly label={label} />
    <div className="input-readonly">
        {value}
    </div>
  </div>;
}
