import { ApplicationError, ErrorLevel, ErrorType } from "src/types";
import { auth } from "src/producers/firebase";

export const logOutUser: producer = ({
  trigger = observe.pages.dashboard.triggers.logout,
  error = update.errors[param.id],
}) => {
  if (!trigger) {
    return;
  }

  auth
    .signOut()
    .then((x: any) => {})
    .catch((e: any) => {
      error.set(
        {
          message: e,
          level: ErrorLevel.WARNING,
          type: ErrorType.SERVER,
        } as ApplicationError,
        { id: "logOutUser" }
      );
    });
};
