import { addSignedContract } from "./UploadSignedContractButton.addSignedContract";
import i18n from "src/producers/languages/i18n";
import { Button } from "@c11/components";

export const UploadSignedContractButton: view = ({
  _viewId,
  setContractToUpload = update.contract.triggers.addSignedContract,
  setOpenDialog = update.views[prop._viewId].openDialog,
  openDialog = observe.views[prop._viewId].openDialog,
}) => {
  return (
    <Button
      variant="primary"
      onClick={() => {
        !openDialog && document.getElementById("upload-signed-contract-input")?.click();
        setOpenDialog.set(true);
        setTimeout(() => setOpenDialog.set(false), 1000);
      }}>
      <input
        id="upload-signed-contract-input"
        type="file"
        onChange={(e) => {
          setContractToUpload.set({ data: e.target.files[0] });
        }}
        accept="application/pdf"
      />
      <span className="uppercase">{i18n.t("contract_navbar.button_upload_sign_doc")}</span>
    </Button>
  );
};

UploadSignedContractButton.producers([addSignedContract]);
