import { Menu, Dropdown } from "antd";
import { UnlockedIcon, UserProfileBicolorIcon } from "src/assets/svg";

import i18n from "src/producers/languages/i18n";
import { TenantIdsDropdown } from "src/pages/Dashboard/TenantIdsDropdown/TenantIdsDropdown";
import { LanguageDropdown } from "src/pages/Dashboard/LanguageDropdown/LanguageDropdown";

export const UserMenu: view = ({
  triggerLogout = update.pages.dashboard.triggers.logout,
  loggedUser = observe.user,
}) => {
  const dropdownMenu = (
    <Menu>
      <Menu.Item className="mx-2 my-2" key="1" onClick={() => {}}>
        <TenantIdsDropdown />
      </Menu.Item>
      <Menu.Item className="mx-2 my-2" key="2" onClick={() => {}}>
        <LanguageDropdown />
      </Menu.Item>
      <Menu.Item className="mx-2 my-2" key="3" onClick={() => {}}>
        <div className="flex flex-row items-center" onClick={() => triggerLogout.set(Date.now())}>
          <UnlockedIcon className="ml-1" />
          <span className="ml-2">{i18n.t("dashboard.logout_button")}</span>
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={dropdownMenu} trigger={["click"]}>
      <button type="button" className="mr-6 flex flex-col items-center">
        <UserProfileBicolorIcon className="text-gray-800" height="38" width="38" />
        <p className="font-semibold">{loggedUser?.data?.email}</p>
      </button>
    </Dropdown>
  );
};
