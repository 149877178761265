import React from "react";
import "./index.css";
import App from "./App";
import {EventNames} from "@c11/engine.types";
import {debug} from "@c11/engine.patterns";
import {engine, producers, pathFn} from "@c11/engine.runtime";
import {connectToDashboard} from "@c11/engine.dashboard";
import {render} from "@c11/engine.react";
import {state} from "./state";
import * as producersList from "./producers";
import "./global";

if (process.env.NODE_ENV === "development") {
  const dashboard = connectToDashboard();
  const receiveUpdates: producer = ({updatePath = update}) => {
    dashboard.on((event) => {
      event.data.text().then((x) => {
        const op = JSON.parse(x);
        const result = updatePath(pathFn(...op.path));
        result.set(op.value);
      });
    });
  };

  const app = engine({
    // onEvents: {
    //   [EventNames.STATE_UPDATED]: dashboard.send,
    //   [EventNames.PATCH_APPLIED]: dashboard.send,
    // },
    state,
    use: [render(<App/>, "#root", {debug: false}), producers([debug, producersList, receiveUpdates])],
  });
  app.start();
} else {
  const app = engine({
    state,
    use: [render(<App/>, "#root", {debug: false}), producers([debug, producersList])],
  });
  app.start();
}
