import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";
import { stopSigning } from "src/pages/Contract/Navbar/StopSigningButton.stopSigning";

export const StopSigningButton: view = ({ showModal = update.modal.stopSigning }) => {
  return (
    <Button variant="secondary" className="uppercase font-semibold" onClick={() => showModal.set(true)}>
      <span className="ml-2" key="57">
        {i18n.t("contract_navbar.button_stop_signing")}
      </span>
    </Button>
  );
};

StopSigningButton.producers([stopSigning]);
