import Big from "big.js";

export const MinimumTurnOver: producer = ({
  minimumRent = observe.legacyContract.values.minimumRent.displayedValue,
  turnoverPercent = observe.legacyContract.values.turnoverPercent.displayedValue,
  trigger = observe.legacyContract.custom.MTO,

  oldValue = get.legacyContract.values.MTO.displayedValue,

  minimumTurnOver = update.legacyContract.currentSession.changes.MTO,
}) => {
  if (trigger || !minimumRent || !turnoverPercent) {
    return;
  }
  oldValue = oldValue.value();
  const value = new Big(minimumRent).div(turnoverPercent).times(100).round().toNumber();
  if (oldValue == value) {
    return;
  }
  minimumTurnOver.set({
    value: value,
    createdAt: Date.now(),
    custom: false,
  });
};
