import i18n from 'src/producers/languages/i18n'
import { v4 } from 'uuid'
import { Clause, SubClause } from 'src/components_generic/Clause'
import styles from '../../../../styles.module.css'
import { TextField, TextFieldReadOnly } from 'src/components_generic/TextField'
import { TextEditor, TextEditorReadOnly } from 'src/components'
import { DateField, DateFieldReadOnly } from 'src/components_generic/DateField'
import {
  AutocalculatedFieldWithOverride,
  AutocalculatedFieldWithOverrideReadOnly,
  NumberFieldReadOnly,
} from 'src/components_generic/NumberField'
import { ReactNumberInput } from 'src/components_generic/ReactNumberInput'
import { VisibleIf } from 'src/components_generic/visible-if'
import { RadioButton, RadioButtonGroup } from '@c11/components'
import { Dropdown, MenuProps } from 'antd'
import { DropdownField } from 'src/components_generic/DropdownField'
import { ContractTemplate } from 'src/pages/ContractV2/contract.types'
import { AddendumFields } from './fields'
import { RentalPeriodExtensionType, RentalPeriodShoppingMallInputFields } from '../shoppingMall/clauses/RentalPeriod'
import { RenewalPeriodDropdownItemsFactory } from '../shoppingMall/dropdowns/RenewalPeriodDropdown'
import { VisibleIfInvalidField } from 'src/pages/ContractV2/components/VisibleIfInvalidField'

export const AddendumChangeInRentalPriceDate: view = ({
  addendum = observe.contract.originalv2,
  isReadOnlyView = observe.random.isReadOnlyView,
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status,
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || isReadOnlyView

  if (!addendum) return <></>
  if (!addendum?.template) return <></>

  if (addendum?.template !== ContractTemplate.RENTAL_PERIOD_PRICE) return <></>

  return (
    <>
      <VisibleIf predicate={!displayReadOnlyView}>
        <AddendumChangeInRentalPriceDateComponent />
      </VisibleIf>
      <VisibleIf predicate={displayReadOnlyView}>
        <AddendumChangeInRentalPriceDateComponentReadOnly />
      </VisibleIf>
    </>
  )
}

const AddendumChangeInRentalPriceDateComponent: view = ({
  fields = observe.contract.originalv2.fields,
  indices = observe.contract.originalv2.indices,
  // contractTitle = get.contract.originalv2[AddendumFields.CONTRACT_TITLE].value(),
  triggerChanges = update.contract.changes.fields,
  miscChanges = update.contract.changes.miscellaneous[param.field].value,
  updateIsCustom = update.contract.changes.miscellaneous[param.field].isCustom,
}) => {
  if (!fields) return <></>
  const firstName = fields?.[AddendumFields.FIRST_NAME]?.value
  const lastName = fields?.[AddendumFields.LAST_NAME]?.value
  const phone = fields?.[AddendumFields.PHONE]?.value
  const email = fields?.[AddendumFields.EMAIL]?.value

  const contractTitle = fields?.[AddendumFields.CONTRACT_TITLE]?.value

  const landlordText = fields?.landlordName?.value
  const tenantText = fields?.tenantName?.value

  const backgroundAndReason = fields?.[AddendumFields.BACKGROUND_AND_PURPOSE]?.value

  const changeOfRentalPeriod = fields?.[AddendumFields.CHANGE_OF_RENTAL_PERIOD]?.value
  // const changeOfRentalPrice = fields?.[AddendumFields.CHANGE_OF_RENTAL_PRICE]?.value

  const rentalPeriodDescription = fields?.[AddendumFields.RENTAL_PERIOD_DESCRIPTION]?.value
  const rentalStartDate = fields?.[AddendumFields.RENTAL_START]?.value
  const rentalEndDate = fields?.[AddendumFields.RENTAL_END]?.value
  const rentalPaymentStart = fields?.[AddendumFields.RENTAL_PAYMENT_START]?.value

  const hasPotentialOptions = fields?.[AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS]?.value

  const rentalPriceDescription = fields?.[AddendumFields.RENTAL_PRICE_DESCRIPTION]?.value
  const turnoverRent = fields?.[AddendumFields.TURNOVER_RENT]?.value
  const minimumRent = fields?.[AddendumFields.MINIMUM_RENT]?.value
  const minimumTurnover = fields?.[AddendumFields.MINIMUM_TURNOVER]?.value
  const minimumTurnoverIsCustom = fields?.[AddendumFields.MINIMUM_TURNOVER]?.isCustom

  const firstTimeRegulation = fields?.[AddendumFields.FIRST_TIME_REGULATION]?.value
  const basicIndex = fields?.[AddendumFields.BASIC_INDEX]?.value

  const extensionType = fields?.[AddendumFields.RENTAL_EXTENSION_TYPE]?.value
  const extensionNumberOfYears = fields?.[AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS]?.value

  const marketingCost = fields?.[AddendumFields.MARKETING_COST_DESCRIPTION]?.value
  const hasMarketingCost = fields?.[AddendumFields.HAS_MARKETING_COST]?.value

  const securityText = fields?.[AddendumFields.SECURITY]?.value
  const derogationText = fields?.[AddendumFields.DEROGATION]?.value
  const otherProvisionText = fields?.[AddendumFields.OTHER_PROVISIONS]?.value

  return (
    <div className={`${styles.contract}`}>
      <div className={`${styles.innerContract} contract-centered`}>
        <Clause title={i18n.t(`contract.general.tenant`)}>
          <SubClause>
            <div className={`${styles.row}`}>
              <TextField
                label={i18n.t(`contract.labels.${AddendumFields.FIRST_NAME}`)}
                name={AddendumFields.FIRST_NAME}
                value={firstName}
                // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
                onChange={(value) =>
                  triggerChanges.merge({
                    [AddendumFields.FIRST_NAME]: { value },
                  })
                }
              />

              <TextField
                label={i18n.t(`contract.labels.${AddendumFields.LAST_NAME}`)}
                name={AddendumFields.LAST_NAME}
                value={lastName}
                // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
                onChange={(value) =>
                  triggerChanges.merge({
                    [AddendumFields.LAST_NAME]: { value },
                  })
                }
              />
            </div>

            <div className={`${styles.row}`}>
              <TextField
                label={i18n.t(`contract.labels.${AddendumFields.PHONE}`)}
                name={AddendumFields.PHONE}
                value={phone}
                // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
                onChange={(value) =>
                  triggerChanges.merge({
                    [AddendumFields.PHONE]: { value },
                  })
                }
              />

              <TextField
                label={i18n.t(`contract.labels.${AddendumFields.EMAIL}`)}
                name={AddendumFields.EMAIL}
                required={true}
                value={email}
                // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
                onChange={(value) =>
                  triggerChanges.merge({
                    [AddendumFields.EMAIL]: { value },
                  })
                }
              />
            </div>
          </SubClause>
        </Clause>

        <Clause title={i18n.t(`contract.addendums.title`)}>
          <SubClause>
            <TextField
              name={AddendumFields.CONTRACT_TITLE}
              value={contractTitle}
              onChange={(value) =>
                triggerChanges.merge({
                  [AddendumFields.CONTRACT_TITLE]: { value },
                })
              }
            />
          </SubClause>
        </Clause>

        <Clause title={i18n.t('contract.addendums.between')}>
          <SubClause title={i18n.t('contract.general.landlord')}>
            <div>{landlordText}</div>
          </SubClause>
          <SubClause title={i18n.t('contract.general.tenant')}>
            <div>{tenantText}</div>
          </SubClause>
        </Clause>

        {/* <ClausesList toggles={{changeOfRentalPeriod, hasPotentialOptions}}/> */}

        <Clause
          title={i18n.t(`contract.addendums.background_and_reason`)}
          index={`${indices[AddendumFields.BACKGROUND_AND_PURPOSE]}.`}>
          <SubClause>
            <TextEditor
              readOnly={false}
              value={backgroundAndReason}
              onChange={(e: any) => {
                triggerChanges.merge({
                  [AddendumFields.BACKGROUND_AND_PURPOSE]: { value: e },
                })
              }}
            />
          </SubClause>
        </Clause>

        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.rental_period`)}
          index={`${indices[AddendumFields.RENTAL_PERIOD_DESCRIPTION]}.`}
          isClauseToggled={!!changeOfRentalPeriod}
          onClauseToggled={() => {
            miscChanges.set(!changeOfRentalPeriod, { field: AddendumFields.CHANGE_OF_RENTAL_PERIOD })
          }}>
          <VisibleIf predicate={!!changeOfRentalPeriod}>
            <SubClause>
              <TextEditor
                readOnly={false}
                value={rentalPeriodDescription}
                disabled={!changeOfRentalPeriod}
                onChange={(e: any) => {
                  triggerChanges.merge({
                    [AddendumFields.RENTAL_PERIOD_DESCRIPTION]: { value: e },
                  })
                }}
              />
            </SubClause>

            <SubClause>
              <div className={`${styles.row}`}>
                <DateField
                  extraClassNames="flex-grow"
                  label={i18n.t(`contract.labels.${AddendumFields.RENTAL_START}`)}
                  name={AddendumFields.RENTAL_START}
                  value={rentalStartDate}
                  onChange={(value) => {
                    miscChanges.set(value, { field: AddendumFields.RENTAL_START })
                  }}
                />
                <DateField
                  extraClassNames="flex-grow"
                  label={i18n.t(`contract.labels.${AddendumFields.RENTAL_END}`)}
                  name={AddendumFields.RENTAL_END}
                  value={rentalEndDate}
                  onChange={(value) => {
                    miscChanges.set(value, { field: AddendumFields.RENTAL_END })
                  }}
                />
                <DateField
                  extraClassNames="flex-grow"
                  label={i18n.t(`contract.labels.${AddendumFields.RENTAL_PAYMENT_START}`)}
                  name={AddendumFields.RENTAL_PAYMENT_START}
                  value={rentalPaymentStart}
                  onChange={(value) => {
                    miscChanges.set(value, { field: AddendumFields.RENTAL_PAYMENT_START })
                  }}
                />
              </div>
            </SubClause>
          </VisibleIf>
        </Clause>

        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.rental_price`)}
          index={`${indices[AddendumFields.RENTAL_PRICE_DESCRIPTION]}.`}>
          <SubClause>
            <TextEditor
              readOnly={false}
              value={rentalPriceDescription}
              onChange={(e: any) => {
                triggerChanges.merge({
                  [AddendumFields.RENTAL_PRICE_DESCRIPTION]: { value: e },
                })
              }}
            />
          </SubClause>
          <SubClause>
            <div className={`${styles.row}`}>
              <ReactNumberInput
                label={i18n.t(`contract.labels.${AddendumFields.TURNOVER_RENT}`)}
                name={AddendumFields.TURNOVER_RENT}
                required={true}
                value={turnoverRent}
                // placeholder={i18n.t(`contract.placeholders.${RentalCostsInputFields.TURNOVER_RENT}`)}
                onChange={(value) => {
                  triggerChanges.merge({
                    [AddendumFields.TURNOVER_RENT]: { value },
                  })
                }}
              />
              <ReactNumberInput
                label={i18n.t(`contract.labels.${AddendumFields.MINIMUM_RENT}`)}
                name={AddendumFields.MINIMUM_RENT}
                required={true}
                value={minimumRent}
                // placeholder={i18n.t(`contract.placeholders.${RentalCostsInputFields.MINIMUM_RENT}`)}
                onChange={(value) => {
                  triggerChanges.merge({
                    [AddendumFields.MINIMUM_RENT]: { value },
                  })
                }}
              />
            </div>

            <VisibleIf predicate={!minimumTurnover && !minimumTurnoverIsCustom}>
              <div className={`${styles.row} mt-3 font-bold text-right`}>
                <div>{i18n.t(`contract.labels.${AddendumFields.MINIMUM_TURNOVER}`)}</div>
              </div>
            </VisibleIf>
            <VisibleIf predicate={!!minimumTurnover || minimumTurnoverIsCustom}>
              <div className={`${styles.row} flex flex-row items-center justify-center gap-8 mt-3`}>
                <div className="text-right font-bold w-1/2 ">
                  <div>{i18n.t(`contract.labels.${AddendumFields.MINIMUM_TURNOVER}`)}</div>
                </div>
                <AutocalculatedFieldWithOverride
                  name=""
                  inputExtraClassNames="text-right font-bold"
                  value={minimumTurnover}
                  isCustom={minimumTurnoverIsCustom}
                  onChange={(value: any) => {
                    triggerChanges.merge({
                      [AddendumFields.MINIMUM_TURNOVER]: { value },
                    })
                  }}
                  onSetCustomChanged={(value: any) => {
                    updateIsCustom.set(!minimumTurnoverIsCustom, { field: AddendumFields.MINIMUM_TURNOVER })
                  }}
                />
              </div>
            </VisibleIf>
          </SubClause>
          <SubClause
            title={i18n.t(`contract.addendums.template.rental_period_price.index_adjustment`)}
            index={`${indices[AddendumFields.RENTAL_PRICE_DESCRIPTION]}.1`}>
            <div className={`${styles.row}`}>
              <DateField
                extraClassNames="flex-grow w-1/2"
                label={i18n.t(`contract.labels.${AddendumFields.FIRST_TIME_REGULATION}`)}
                name={AddendumFields.FIRST_TIME_REGULATION}
                value={firstTimeRegulation}
                onChange={(value) => {
                  miscChanges.set(value, { field: AddendumFields.FIRST_TIME_REGULATION })
                }}
              />
              <DateField
                extraClassNames="flex-grow w-1/2"
                label={i18n.t(`contract.labels.${AddendumFields.BASIC_INDEX}`)}
                name={AddendumFields.BASIC_INDEX}
                value={basicIndex}
                onChange={(value) => {
                  miscChanges.set(value, { field: AddendumFields.BASIC_INDEX })
                }}
              />
            </div>
          </SubClause>
        </Clause>

        <Clause
          title={i18n.t(`contract.addendums.template.marketing_cost`)}
          index={`${indices[AddendumFields.MARKETING_COST_DESCRIPTION]}.`}
          isClauseToggled={!!hasMarketingCost}
          onClauseToggled={() => {
            miscChanges.set(!hasMarketingCost, { field: AddendumFields.HAS_MARKETING_COST })
          }}>
          <VisibleIf predicate={!!hasMarketingCost}>
            <SubClause>
              <TextEditor
                readOnly={false}
                value={marketingCost}
                disabled={!hasMarketingCost}
                onChange={(e: any) => {
                  triggerChanges.merge({
                    [AddendumFields.MARKETING_COST_DESCRIPTION]: { value: e },
                  })
                }}
              />
            </SubClause>
          </VisibleIf>
        </Clause>

        {/*<<<<<<< HEAD*/}
        {/*    <Clause title="Potential options" index="2."*/}
        {/*      isClauseToggled={hasPotentialOptions}*/}
        {/*      onClauseToggled={() => {*/}
        {/*        triggerChanges.merge({*/}
        {/*          [AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS]: { value: !(hasPotentialOptions) }*/}
        {/*        })*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <SubClause>*/}
        {/*        <div className="flex items-center gap-8" >*/}
        {/*          <RadioButtonGroup selectedValue={extensionType} className="flex flex-grow gap-8">*/}
        {/*            <RadioButton*/}
        {/*              key={v4()}*/}
        {/*              value={RentalPeriodExtensionType.EXTENSION}*/}
        {/*              isDisabled={false}*/}
        {/*              onClick={() => {*/}
        {/*                triggerChanges.merge({*/}
        {/*                  [AddendumFields.RENTAL_EXTENSION_TYPE]: RentalPeriodExtensionType.EXTENSION*/}
        {/*=======*/}

        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.potential_options`)}
          index={`${indices[AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS]}.`}
          isClauseToggled={!!hasPotentialOptions}
          onClauseToggled={() => {
            miscChanges.set(!hasPotentialOptions, { field: AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS })
          }}>
          <VisibleIf predicate={!!hasPotentialOptions}>
            <SubClause>
              <div className={`${styles.row}`}>
                <RadioButtonGroup selectedValue={extensionType} className="flex flex-grow gap-8 mt-8">
                  <RadioButton
                    key={v4()}
                    value={RentalPeriodExtensionType.EXTENSION}
                    isDisabled={false}
                    onClick={() => {
                      miscChanges.set(RentalPeriodExtensionType.EXTENSION, {
                        field: RentalPeriodShoppingMallInputFields.EXTENSION_TYPE,
                      })
                    }}>
                    {i18n.t(`contract.fields.${AddendumFields.RENTAL_EXTENSION_TYPE}.${RentalPeriodExtensionType.EXTENSION}`)}
                  </RadioButton>
                  <RadioButton
                    key={v4()}
                    value={RentalPeriodExtensionType.RENEWAL}
                    isDisabled={false}
                    onClick={() => {
                      miscChanges.set(RentalPeriodExtensionType.RENEWAL, {
                        field: RentalPeriodShoppingMallInputFields.EXTENSION_TYPE,
                      })
                    }}>
                    {i18n.t(`contract.fields.${AddendumFields.RENTAL_EXTENSION_TYPE}.${RentalPeriodExtensionType.RENEWAL}`)}
                  </RadioButton>

                  <VisibleIfInvalidField name={RentalPeriodShoppingMallInputFields.EXTENSION_TYPE}>
                    <div className="text-pink-600 text-xs mt-1">{i18n.t('errors.rental_period_extension_type_not_selected')}</div>
                  </VisibleIfInvalidField>
                </RadioButtonGroup>

                <Dropdown
                  menu={{
                    items: RenewalPeriodDropdownItemsFactory({
                      onClick: (key) => {
                        miscChanges.set(key, { field: AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS })
                      },
                    }),
                  }}
                  placement="bottom"
                  trigger={['click']}>
                  <div className="flex-grow">
                    <DropdownField
                      label={i18n.t(`contract.labels.${AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS}`)}
                      value={extensionNumberOfYears}
                    />
                  </div>
                </Dropdown>
              </div>
              <VisibleIf predicate={extensionType === RentalPeriodExtensionType.EXTENSION}>
                <p>
                  {i18n.t('contract.general.rental_period_extension_description', {
                    years: extensionNumberOfYears,
                  })}
                </p>
              </VisibleIf>
              <VisibleIf predicate={extensionType === RentalPeriodExtensionType.RENEWAL}>
                <p>
                  {i18n.t('contract.general.rental_period_renewal_description', {
                    years: extensionNumberOfYears,
                  })}
                </p>
              </VisibleIf>
            </SubClause>
          </VisibleIf>
        </Clause>

        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.security`)}
          index={`${indices[AddendumFields.SECURITY]}.`}>
          <SubClause>
            <TextEditor
              readOnly={false}
              value={securityText}
              onChange={(e: any) => {
                triggerChanges.merge({
                  [AddendumFields.SECURITY]: { value: e },
                })
              }}
            />
          </SubClause>
        </Clause>

        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.derogation`)}
          index={`${indices[AddendumFields.DEROGATION]}.`}>
          <SubClause>
            <TextEditor
              readOnly={false}
              value={derogationText}
              onChange={(e: any) => {
                triggerChanges.merge({
                  [AddendumFields.DEROGATION]: { value: e },
                })
              }}
            />
          </SubClause>
        </Clause>

        <Clause title={i18n.t(`contract.addendums.other_provisions`)} index={`${indices[AddendumFields.OTHER_PROVISIONS]}.`}>
          <SubClause>
            <TextEditor
              readOnly={false}
              value={otherProvisionText}
              onChange={(e: any) => {
                triggerChanges.merge({
                  [AddendumFields.OTHER_PROVISIONS]: { value: e },
                })
              }}
            />
          </SubClause>
        </Clause>
      </div>
    </div>
  )
}

const AddendumChangeInRentalPriceDateComponentReadOnly: view = ({
  fields = get.contract.originalv2.fields.value(),
  indices = get.contract.originalv2.indices.value(),
  addendum = get.contract.originalv2.value(),
}) => {
  const firstName = fields?.[AddendumFields.FIRST_NAME]?.value
  const lastName = fields?.[AddendumFields.LAST_NAME]?.value
  const phone = fields?.[AddendumFields.PHONE]?.value
  const email = fields?.[AddendumFields.EMAIL]?.value

  const contractTitle = fields?.[AddendumFields.CONTRACT_TITLE]?.value

  const landlordText = fields?.landlordName?.value
  const tenantText = fields?.tenantName?.value

  const backgroundAndReason = fields?.[AddendumFields.BACKGROUND_AND_PURPOSE]?.value

  const changeOfRentalPeriod = fields?.[AddendumFields.CHANGE_OF_RENTAL_PERIOD]?.value
  const rentalPeriodDescription = fields?.[AddendumFields.RENTAL_PERIOD_DESCRIPTION]?.value
  const rentalStartDate = fields?.[AddendumFields.RENTAL_START]?.value
  const rentalEndDate = fields?.[AddendumFields.RENTAL_END]?.value
  const rentalPaymentStart = fields?.[AddendumFields.RENTAL_PAYMENT_START]?.value

  const changeOfRentalPrice = fields?.[AddendumFields.CHANGE_OF_RENTAL_PRICE]?.value
  const rentalPriceDescription = fields?.[AddendumFields.RENTAL_PRICE_DESCRIPTION]?.value
  const turnoverRent = fields?.[AddendumFields.TURNOVER_RENT]?.value
  const minimumRent = fields?.[AddendumFields.MINIMUM_RENT]?.value
  const minimumTurnover = fields?.[AddendumFields.MINIMUM_TURNOVER]?.value
  const minimumTurnoverIsCustom = fields?.[AddendumFields.MINIMUM_TURNOVER]?.isCustom
  const firstTimeRegulation = fields?.[AddendumFields.FIRST_TIME_REGULATION]?.value
  const basicIndex = fields?.[AddendumFields.BASIC_INDEX]?.value
  const extensionType = fields?.[AddendumFields.RENTAL_EXTENSION_TYPE]?.value
  const hasPotentialOptions = fields?.[AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS]?.value
  const extensionNumberOfYears = fields?.[AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS]?.value

  const securityText = fields?.[AddendumFields.SECURITY]?.value
  const derogationText = fields?.[AddendumFields.DEROGATION]?.value
  const otherProvisionText = fields?.[AddendumFields.OTHER_PROVISIONS]?.value

  const marketingCost = fields?.[AddendumFields.MARKETING_COST_DESCRIPTION]?.value
  const hasMarketingCost = fields?.[AddendumFields.HAS_MARKETING_COST]?.value
  const marketingCostDescription = fields?.[AddendumFields.MARKETING_COST_DESCRIPTION]?.value

  return (
    <div className={`${styles.contract}`}>
      <div className={`${styles.innerContract} contract-centered`}>
        <Clause title={i18n.t(`contract.general.tenant`)}>
          <SubClause>
            <div className={`${styles.row}`}>
              <TextFieldReadOnly label={i18n.t(`contract.labels.${AddendumFields.FIRST_NAME}`)} value={firstName} />
              <TextFieldReadOnly label={i18n.t(`contract.labels.${AddendumFields.LAST_NAME}`)} value={lastName} />
            </div>
            <div className={`${styles.row}`}>
              <TextFieldReadOnly label={i18n.t(`contract.labels.${AddendumFields.PHONE}`)} value={phone} />
              <TextFieldReadOnly label={i18n.t(`contract.labels.${AddendumFields.EMAIL}`)} value={email} />
            </div>
          </SubClause>
        </Clause>
        <Clause title={i18n.t(`contract.addendums.title`)}>
          <SubClause>
            <TextFieldReadOnly value={contractTitle} />
          </SubClause>
        </Clause>
        <Clause title={i18n.t(`contract.addendums.between`)}>
          <SubClause title="Landlord">
            <div>{landlordText}</div>
          </SubClause>
          <SubClause title="Tenant">
            <div>{tenantText}</div>
          </SubClause>
        </Clause>
        <Clause
          title={i18n.t(`contract.addendums.background_and_reason`)}
          index={`${indices[AddendumFields.BACKGROUND_AND_PURPOSE]}.`}>
          <SubClause>
            <TextEditorReadOnly value={backgroundAndReason} />
          </SubClause>
        </Clause>
        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.rental_period`)}
          index={`${indices[AddendumFields.RENTAL_PERIOD_DESCRIPTION]}.`}
          isClauseToggled={changeOfRentalPeriod?.isActive}
          isToggleDisabled={true}
          onClauseToggled={() => {}}>
          <VisibleIf predicate={!!changeOfRentalPeriod}>
            <SubClause>
              <TextEditorReadOnly value={rentalPeriodDescription} />
            </SubClause>
            <SubClause>
              <div className={`${styles.row}`}>
                <DateFieldReadOnly
                  extraClassNames="flex-grow"
                  label={i18n.t(`contract.labels.${AddendumFields.RENTAL_START}`)}
                  value={rentalStartDate}
                />
                <DateFieldReadOnly
                  extraClassNames="flex-grow"
                  label={i18n.t(`contract.labels.${AddendumFields.RENTAL_END}`)}
                  value={rentalEndDate}
                />
                <DateFieldReadOnly
                  extraClassNames="flex-grow"
                  label={i18n.t(`contract.labels.${AddendumFields.RENTAL_PAYMENT_START}`)}
                  value={rentalPaymentStart}
                />
              </div>
            </SubClause>
          </VisibleIf>
        </Clause>
        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.rental_price`)}
          index={`${indices[AddendumFields.RENTAL_PRICE_DESCRIPTION]}.`}>
          <SubClause>
            <TextEditorReadOnly value={rentalPriceDescription} />
          </SubClause>
          <SubClause>
            <div className={`${styles.row}`}>
              <NumberFieldReadOnly label={i18n.t(`contract.labels.${AddendumFields.TURNOVER_RENT}`)} value={turnoverRent} />
              <NumberFieldReadOnly label={i18n.t(`contract.labels.${AddendumFields.MINIMUM_RENT}`)} value={minimumRent} />
            </div>

            <VisibleIf predicate={!!minimumTurnover}>
              <div className={`${styles.row} flex flex-row items-center justify-center gap-8 mt-3`}>
                <div className="text-right font-bold w-1/2 ">
                  <div>{i18n.t(`contract.labels.${AddendumFields.MINIMUM_TURNOVER}`)}</div>
                </div>
                <AutocalculatedFieldWithOverrideReadOnly
                  name=""
                  inputExtraClassNames="text-right font-bold"
                  value={
                    minimumTurnoverIsCustom
                      ? minimumTurnover
                      : // NumberFormatter(fields.minimumTurnOver?.value)
                        100
                  }
                  isCustom={minimumTurnoverIsCustom}
                />
              </div>
            </VisibleIf>
          </SubClause>
          <SubClause
            title={i18n.t(`contract.addendums.template.rental_period_price.index_adjustment`)}
            index={`${indices[AddendumFields.RENTAL_PRICE_DESCRIPTION]}.1`}>
            <div className={`${styles.row}`}>
              <DateFieldReadOnly
                extraClassNames="flex-grow w-1/2"
                label={i18n.t(`contract.labels.${AddendumFields.FIRST_TIME_REGULATION}`)}
                value={firstTimeRegulation}
              />
              <DateFieldReadOnly
                extraClassNames="flex-grow w-1/2"
                label={i18n.t(`contract.labels.${AddendumFields.BASIC_INDEX}`)}
                value={basicIndex}
              />
            </div>
          </SubClause>
        </Clause>

        <Clause
          title={i18n.t(`contract.addendums.template.marketing_cost`)}
          index={`${indices[AddendumFields.MARKETING_COST_DESCRIPTION]}.`}
          isClauseToggled={hasMarketingCost}
          isToggleDisabled={true}
          onClauseToggled={() => {}}>
          <VisibleIf predicate={!!hasPotentialOptions}>
            <SubClause>
              <TextEditorReadOnly readOnly={false} value={marketingCost} />
            </SubClause>
          </VisibleIf>
        </Clause>

        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.potential_options`)}
          index={`${indices[AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS]}.`}
          isClauseToggled={hasPotentialOptions}
          isToggleDisabled={true}
          onClauseToggled={() => {}}>
          <VisibleIf predicate={!!hasPotentialOptions}>
            <SubClause>
              <div className="flex items-center gap-8">
                <RadioButtonGroup selectedValue={extensionType} className="flex flex-grow gap-8">
                  <RadioButton key={v4()} value={RentalPeriodExtensionType.EXTENSION} isDisabled={true}>
                    {i18n.t(`contract.fields.${AddendumFields.RENTAL_EXTENSION_TYPE}.${RentalPeriodExtensionType.EXTENSION}`)}
                  </RadioButton>
                  <RadioButton key={v4()} value={RentalPeriodExtensionType.RENEWAL} isDisabled={true}>
                    {i18n.t(`contract.fields.${AddendumFields.RENTAL_EXTENSION_TYPE}.${RentalPeriodExtensionType.RENEWAL}`)}
                  </RadioButton>
                </RadioButtonGroup>
                <TextFieldReadOnly
                  label={i18n.t(`contract.labels.${AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS}`)}
                  value={extensionNumberOfYears}
                />
              </div>
            </SubClause>
          </VisibleIf>
        </Clause>

        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.security`)}
          index={`${indices[AddendumFields.SECURITY]}.`}>
          <SubClause>
            <TextEditorReadOnly value={securityText} />
          </SubClause>
        </Clause>

        <Clause
          title={i18n.t(`contract.addendums.template.rental_period_price.derogation`)}
          index={`${indices[AddendumFields.DEROGATION]}.`}>
          <SubClause>
            <TextEditorReadOnly value={derogationText} />
          </SubClause>
        </Clause>

        <Clause title={i18n.t(`contract.addendums.other_provisions`)} index={`${indices[AddendumFields.OTHER_PROVISIONS]}.`}>
          <SubClause>
            <TextEditorReadOnly readOnly={false} value={otherProvisionText} />
          </SubClause>
        </Clause>
      </div>
    </div>
  )
}
