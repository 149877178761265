import "src/pages/Dashboard/Tabs/Tabs.css";
import { Divider } from "antd";

import { setDefaultTab } from "src/pages/Dashboard/Tabs/Tabs.setDefaultTab";
import i18n from "src/producers/languages/i18n";
import { UserRole, DashboardTabs } from "src/types";
import styles from "../styles.module.css"

export const Tabs: view = ({
  loggedUser = observe.user,
  selectedTab = observe.pages.dashboard.selectedTab,
  selectTab = update.pages.dashboard.selectedTab,
  triggerGetContracts = update.pages.dashboard.triggers.getContracts,
  currentLanguage = observe.languages.current, // FIXME: fix the root cause for broken translations
}) => {
  if (!currentLanguage) return null;
  const isManager = loggedUser?.data?.role === UserRole.MANAGER;

  const renderTab = (tabName: DashboardTabs) => (
    <div
      className={`cursor-pointer text-base relative mx-2 ${
        selectedTab === tabName ? "selected-tab" : "text-gray-800 hover:text-pink-500"
      }`}
      // TODO: maybe find a better way to trigger get contracts
      onClick={() => {
        triggerGetContracts.set(Date.now());
        selectTab.set(tabName);
      }}>
      <div>{i18n.t(`dashboard.tab_${tabName}`)}</div>
    </div>
  );

  return (
    <div>
      <div className={`${styles.tabsRow} `}>
        {renderTab(DashboardTabs.ALL_CONTRACTS)}
        {isManager && renderTab(DashboardTabs.DRAFTS)}
        {renderTab(DashboardTabs.OFFERS)}
        {renderTab(DashboardTabs.SIGNING)}
        {renderTab(DashboardTabs.ACTIVE)}
        {renderTab(DashboardTabs.ARCHIVED)}
        {isManager && renderTab(DashboardTabs.LOST)}
        {isManager && renderTab(DashboardTabs.TRASHED)}
      </div>
      <Divider className={`${styles.tabsDivider}`} />
    </div>
  );
};

Tabs.producers([setDefaultTab]);
