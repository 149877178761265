import i18n from "src/producers/languages/i18n";
import { ContractStatus } from "src/types";
import { Tooltip } from "antd";
import { SVGs } from "src/assets";

export const StatusPill: view = ({
  status = observe.contract.originalv2.status,
  signingMethod = observe.contract.originalv2.signing,
}) => {
  const isDraft = status === ContractStatus.DRAFT;
  const isInSigning = status === ContractStatus.SIGNING;
  const isActive = status === ContractStatus.ACTIVE;
  if (isDraft) {
    return (
      <div className="flex ml-6 text-gray-600 uppercase rounded-full bg-gray-200 p-1 px-3 items-center">
        <span className="mr-2">{i18n.t("contract_status_pill.draft")} </span>
        <Tooltip title={i18n.t("contract_status_pill.draft")} placement={"bottom"}>
          <SVGs.HelpCircleLight />
        </Tooltip>
      </div>
    );
  }
  if (isInSigning)
    return (
      <div className="flex ml-6 text-white uppercase rounded-full bg-pink-600 p-1 px-3 items-center">
        <span className="mr-2">{i18n.t(`contract_status_pill.${signingMethod}_signing`)} </span>
        <Tooltip title={i18n.t(`contract_status_pill.${signingMethod}_signing`)} placement={"bottom"}>
          <SVGs.HelpCircle />
        </Tooltip>
      </div>
    );
  if (isActive)
    return (
      <div className="flex ml-6 text-white uppercase rounded-full bg-green-600 p-1 px-3 items-center">
        <span className="mr-2">{i18n.t("contract_status_pill.active")} </span>
        <Tooltip title={i18n.t("contract_status_pill.active")} placement={"bottom"}>
          <SVGs.HelpCircle />
        </Tooltip>
      </div>
    );

  return null;
};
