export const populateContactPerson: producer = async ({
  trigger = observe.contract.triggers.populateContactPerson,
  removeTrigger = update.contract.triggers.populateContactPerson,
  selectedCP = get.contract.temp.selectedCP,
  tenant = get.contract.tenant,
  writeValue = update.contract.toSave.changes[param.varName],
}) => {
  tenant = tenant.value();
  if (!trigger || !tenant) return;
  removeTrigger.remove();
  tenant.mainContacts = Object.keys(tenant.mainContacts).map((x) => tenant.mainContacts[x]);
  console.log("ceva");
  const CP = tenant.mainContacts[selectedCP.value()];

  writeValue.set(
    {
      value: CP?.firstName || "",
      createdAt: Date.now(),
    },
    { varName: "tenantContactPersonFirstName" }
  );
  writeValue.set(
    {
      value: CP?.lastName || "",
      createdAt: Date.now(),
    },
    { varName: "tenantContactPersonLastName" }
  );
  writeValue.set(
    {
      value: CP?.phone || "",
      createdAt: Date.now(),
    },
    { varName: "tenantPhone" }
  );
  writeValue.set(
    {
      value: CP?.email || "",
      createdAt: Date.now(),
    },
    { varName: "tenantEmail" }
  );
};
