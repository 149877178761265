import i18n from "src/producers/languages/i18n";
import { ContractFilterOption, ContractStatus, UserRole } from "src/types";
import { HeaderSection } from "../../HeaderSection/HeaderSection";
import { setFilterTag } from "./Addendum.setFilterTag";
import { DeleteAdeendumModal } from "./DeleteAdeendumModal";
import { ExistingAddendumCard } from "./ExistingAddendumCard";
import { NewAddendumButton } from "./NewAddendumButton";

export const Addendums: view = ({
  status = observe.contract.original.status,
  addendums = observe.contract.addendums,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isActive = status === ContractStatus.ACTIVE;
  const canCreateNewAddendum = isActive && isManager;
  //
  return (
    <div className="overflow-auto pb-20 h-screen ">
      <HeaderSection title={i18n.t("general.addendums")}>{canCreateNewAddendum && <NewAddendumButton />}</HeaderSection>
      <div className="mx-12 mt-8 mb-10">
        <div className="flex flex-wrap justify-center">
          {addendums &&
            Object.values(addendums).map((addendum: any) => {
              if (!addendum.id) return null;
              const status = addendum.state?.status || addendum.status;
              if (!isManager && (status === ContractStatus.DRAFT || addendum.filterTag === ContractFilterOption.LOST))
                return null;
              return <ExistingAddendumCard key={addendum.id} addendum={addendum} />;
            })}
        </div>
      </div>
      <DeleteAdeendumModal />
    </div>
  );
};

Addendums.producers([setFilterTag]);
