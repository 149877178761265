import { Button } from '@c11/components'
import './SendContractDataButton.css'

import { DownloadIcon } from 'src/assets/svg'
import i18n from 'src/producers/languages/i18n'
import { ContractStatus, UserRole } from 'src/types'
import axios from 'axios'
import { getFunctionsUrl } from 'src/utils/getFunctionsUrl'

export const SendContractDataButton: view = ({
  isLoading = observe.contract.flags.sendDataLoading,
  isActive = get.featureFlags.sendContractData,
  status = get.contract.original.status,
  config = get.config,
  updateLoading = update.contract.flags.sendDataLoading,
  updateContracts = update.contract.triggers.updateContracts,
  id = get.contract.id,
  role = get.user.data.role,
  rawUser = get.rawUser,
  localFunctions = get.config.localFunctions,
}) => {
  id = id.value();
  role = role.value();
  if (role !== UserRole.MANAGER) return;
  // if (role !== UserRole.MANAGER || !isActive.value() || status.value() !== ContractStatus.ACTIVE) return;

  async function sendContractData () {
    try {
      updateLoading.set(true);
      // updateContracts.set({contractId: id, action: 'sendContractData', loadingFor: 'sendDataLoading'})
      let config = {
        headers: {
          'Authorization': 'Bearer ' + await (rawUser.value()).getIdToken(true),
          'Content-Type' : 'application/json',
        }
      };
      const params = { contractId: id }
      console.log("Sending data: ", params)
      await axios.post(
        getFunctionsUrl('sendContractData', localFunctions.value()),
        params,
        config
      )
    } catch (err) {
      console.error("Error on sending data: ", err);
    }
    updateLoading.set(false);
  }

  return (
    <div>
      <Button
        variant="secondary"
        className="preview-contract-btn flex uppercase gap-2 "
        onClick={async () => {
          await sendContractData();
        }}
        isLoading={isLoading}
      >
        <DownloadIcon/>
        <span>{i18n.t("contract_navbar.send_contract_data")}</span>
      </Button>
    </div>
  );
};
