import React from "react";

import { ContractStatus, UserRole } from "src/types";
import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";

export const Progress: view = ({ status = observe.legacyContract.original.status, userRole = get.user.data.role }) => {
  if (userRole.value() === UserRole.TENANT) return null;
  const isDraft = status === ContractStatus.DRAFT;
  const isNegotiation = status === ContractStatus.NEGOTIATION;
  const isSigning = status === ContractStatus.SIGNING;
  return (
    <div className="flex mr-8 text-gray-500 uppercase">
      {isDraft && <SVGs.DraftIcon className="mr-2" />}
      <p className={isDraft ? "text-gray-800" : ""}>1. {i18n.t("status.draft")}</p>
      <HorizontalLine />
      {isNegotiation && <SVGs.NegotiationIcon className="mr-2" />}
      <p className={isNegotiation ? "text-gray-800" : ""}>2. {i18n.t("status.negotiation")}</p>
      <HorizontalLine />
      {/* TODO fix this SVG icon */}
      {isSigning && (
        <svg className="mr-2" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.7792 18.1645L15.4493 10.8137L16.1702 11.535L20.1172 8.69797L19.3106 7.57387L16.3174 9.7252L11.2803 4.6842L13.4072 1.71254L12.2829 0.906738L9.47329 4.83297L10.1958 5.55608L2.84869 9.22664L2.82794 9.63005C2.65785 12.9629 2.33046 16.6695 0.590818 18.4669L0.117188 18.9561L2.06633 20.9067L2.55552 20.4158C4.27684 18.6866 7.85707 18.3658 11.3768 18.1856L11.7792 18.1645ZM4.16137 17.8842L7.41214 14.6309C7.67903 14.632 7.94627 14.5309 8.15024 14.3271C8.55507 13.922 8.55473 13.2646 8.15024 12.8591C7.74506 12.4543 7.08855 12.454 6.68337 12.8595C6.4545 13.0885 6.35978 13.3982 6.38951 13.6975L3.03157 17.058C3.81738 15.0354 4.05627 12.4969 4.18799 10.1044L11.2271 6.58816L14.4184 9.78194L10.9025 16.8241C8.50149 16.9563 6.09669 17.1808 4.16137 17.8842Z"
            fill="#E12764"
          />
        </svg>
      )}
      <p className={isSigning ? "text-gray-800" : ""}>3. {i18n.t("status.signing")}</p>
    </div>
  );
};

const HorizontalLine: view = () => {
  return (
    <div
      className="bg-gray-500 mx-2"
      style={{
        width: "8px",
        height: "2px",
        marginTop: "10px",
      }}
    />
  );
};
