import i18n from 'src/producers/languages/i18n'
import {
  ApplicationError,
  ContractType,
  ErrorLevel,
  ErrorType
} from 'src/types'
// import { ContractFlow } from "src/pages/ContractV2/contract.types";
const emailRegex = /^\S+@\S+\.\S+$/
const phoneRegex = /^[+]?[\d\s()-]{7,}$/

export const realtimeValidation: producer = ({
  fields = observe.contract.originalv2.fields,
  // state = get.contract.originalv2,

  updateInvalidFields = update.contract.invalidFields[param.varName],
  removeInvalidField = update.contract.invalidFields[param.varName],
  error = update.errors[param.id],
}) => {
  if (!fields) return

  try {
    // if (state.flow === ContractFlow.PAPER) {
    //   updateInvalidFields.set(i18n.t(`validation_message_contractPdf`), { varName: 'contractPdf' })
    // } else {
    //   removeInvalidField.remove({ varName: 'contractPdf' })
    // }
    for (const varName in fields) {
      const value = fields[varName]?.value
      switch (varName) {
        case 'landlordEmail':
        case 'tenantEmail':
          removeInvalidField.remove({ varName: varName })
          if (!value || value.length < 1) continue
          const isValidEmail = emailRegex.test(value)
          if (!isValidEmail) {
            updateInvalidFields.set(i18n.t(`validation_message_email`), { varName: varName })
          }
          break
        case 'landlordPhone':
        case 'tenantPhone':
          removeInvalidField.remove({ varName: varName })
          if (!value || value.length < 1) continue
          const isValidPhone = phoneRegex.test(value)
          if (!isValidPhone) {
            updateInvalidFields.set(i18n.t(`validation_message_phone`), { varName: varName })
          }
          break
        case 'rentalEnd':
          removeInvalidField.remove({ varName: varName })
          if (!value || value.length < 1) continue
          const rentalStart = fields.rentalStart?.value
          if (rentalStart && rentalStart > value) {
            updateInvalidFields.set(i18n.t(`validation_message_endDate`), { varName: varName })
          }
          break
        case 'endDate':
          removeInvalidField.remove({ varName: varName })
          if (!value || value.length < 1) continue
          const startDate = fields.startDate?.value
          if (startDate && startDate > value) {
            updateInvalidFields.set(i18n.t(`validation_message_endDate`), { varName: varName })
          }
          break
        case 'leaseDate':
          removeInvalidField.remove({ varName: varName })
          if (!value || value.length < 1) continue
          if(new Date(value) > new Date()) {
            updateInvalidFields.set(i18n.t(`validation_message_leaseDate`), { varName: varName })
          }
      }
    }
  } catch (e) {
    console.error('Error in valueValidation', e)
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.APPLICATION,
      } as ApplicationError,
      { id: 'valueValidation-contract' }
    )
  }
}
