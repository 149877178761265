import { Button } from "@c11/components.button";

import i18n from "src/producers/languages/i18n";

export const StopSigningButton: view = ({
  isLoading = observe.contract.loading.stopSigning,
  showModal = update.modal.stopSigning
}) => {
  return (
    <Button
      variant="secondary"
      className="uppercase font-semibold"
      isLoading={isLoading}
      onClick={() => showModal.set(true)}
    >
      <span className="ml-2" key="57">
        {i18n.t("contract_navbar.button_stop_signing")}
      </span>
    </Button>
  );
};

