import React from "react";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";

export const ChangesNotification: view = ({ showModal = update.modal.resetContract }) => {
  return (
    <div className="flex items-center my-2">
      <SVGs.ExclamationCircleFilled />
      <span className="pl-2">{i18n.t("legacy.sidebar_versions_updates_found")}</span>
      <span className="text-gray-500 pl-2">|</span>
      <span className="text-pink-600 pl-2 cursor-pointer" onClick={() => showModal.set(Date.now())}>
        {i18n.t("contract_page.reset_all_changes")}
      </span>
    </div>
  );
};
