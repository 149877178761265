import "./TextEditor.css";
import { InputFieldLabelReadOnly } from "./InputFieldLabel";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const TextEditor: view = ({
  textEditors = update.contract.textEditors[param.field],
  value, name, onChange, readOnly
}) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link"],
    ],
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"];
  return (
    <ReactQuill
      id={name}
      // ref={(el) => textEditors.set(el, { name })}
      theme="snow"
      modules={modules}
      formats={formats}
      defaultValue={value}
      onChange={onChange}
      readOnly={readOnly}
      className={"bg-transparent"}
    />
  );
};

export const TextEditorReadOnly: view = ({ value, readOnly }) => {
  return (
    <ReactQuill
      theme="snow"
      defaultValue={value}
      readOnly={readOnly}
      className={"texteditor-readonly"}
    />
  );
}
