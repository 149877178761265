import React from "react";

import { Card } from "../index";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";
import { CheckboxType1 } from "../fields/formFields/CheckboxType1";
import { FactoryFormField } from "../fields/formFields/FactoryFormField";

export const Vat: view = ({
  title,
  setVariable = update.legacyContract.currentSession.changes.vatAlternative,
  getVatAlternative = observe.legacyContract.values.vatAlternative.displayedValue,
  getVatAlternativeFromChanges = observe.legacyContract.currentSession.changes.vatAlternative.value,
  userRole = get.user.data.role,
}) => {
  const i = getVatAlternativeFromChanges || getVatAlternative;
  const isChecked = [i === 1, i === 2, i === 3];
  const isTenant = userRole.value() === UserRole.TENANT;

  function handleClick(c: any) {
    setVariable.set({ value: c + 1, createdAt: Date.now() });
  }

  return (
    <Card title={title} required={isTenant} editableByTenant>
      <CheckboxType1
        key="1"
        label={i18n.t("clause_vat_alternative_1")}
        checked={isChecked[0]}
        onClick={() => handleClick(0)}>
        {i18n.t("clause_vat_alternative_1_text")}
      </CheckboxType1>
      <CheckboxType1
        key="2"
        label={i18n.t("clause_vat_alternative_2")}
        checked={isChecked[1]}
        onClick={() => handleClick(1)}>
        {i18n.t("clause_vat_alternative_2_text")}
      </CheckboxType1>
      <CheckboxType1
        key="3"
        label={i18n.t("clause_vat_alternative_3")}
        checked={isChecked[2]}
        onClick={() => handleClick(2)}>
        <FactoryFormField key="1" disabled={!isChecked[2]} fieldName="vatPercent" varName="vatPercent" />
        <span key="2">{" " + i18n.t("clause_vat_alternative_3_text")}</span>
      </CheckboxType1>
    </Card>
  );
};
