const mallRates = {
  'Paléet kjøpesenter': 282,
  'GlasMagasinet Kjøpesenter': 193,
  'Glasmagasinet Kjøpesenter': 193,
  'Byporten - Kjøpesenter': 161,
  default: 0
}

export const securityAmount: producer = ({
  fields = observe.contract.originalv2.fields,

  sharedCosts = get.contract.originalv2.fields.sharedCosts.value,
  propertyTotalArea = get.contract.originalv2.fields.propertyTotalArea.value,
  marketingCosts = get.contract.originalv2.fields.marketingCosts.value,
  minimumRent = get.contract.originalv2.fields.minimumRent.value,
  minimumTurnOver = get.contract.originalv2.fields.minimumTurnOver.value,
  shoppingMall = get.contract.originalv2.fields.shoppingMall.value,
  securityIsProvided = get.contract.originalv2.fields.securityIsProvided.value,

  originalSecurityAmount = get.contract.originalv2.fields.securityAmount.value,
  isCustom = get.contract.originalv2.fields.securityAmount.isCustom,
  securityAmount = update.contract.changes.fields.securityAmount.value
}) => {
  securityIsProvided = securityIsProvided.value()
  originalSecurityAmount = originalSecurityAmount.value()
  if (!fields || !securityIsProvided || isCustom.value()) return

  sharedCosts = Number(sharedCosts.value())
  propertyTotalArea = Number(propertyTotalArea.value())
  marketingCosts = Number(marketingCosts.value())
  minimumRent = Number(minimumRent.value())
  minimumTurnOver = Number(minimumTurnOver.value())
  shoppingMall = shoppingMall.value()

  if (
    !sharedCosts ||
    !propertyTotalArea ||
    !marketingCosts ||
    !minimumRent ||
    !minimumTurnOver ||
    !shoppingMall ||
    !marketingCosts
  )
    return

  const rate = mallRates[shoppingMall] || mallRates['default']
  const propertyTax = parseFloat(((rate * propertyTotalArea) / 2).toFixed(2))
  const value = (minimumRent / 2 + (sharedCosts * propertyTotalArea) / 2 + ((marketingCosts / 100) * minimumTurnOver) / 2 + propertyTax) * 1.25

  if (originalSecurityAmount === value) return
  securityAmount.set(value)
}
