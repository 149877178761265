import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from 'src/types'
import { db } from 'src/producers/firebase'

export const setUserDepartment: producer = async ({
  trigger = observe.triggers.selectUserDepartment,
  triggerUpdate = update.triggers.selectUserDepartment,
  isAuth = observe.user.isAuth,
  isReady = observe.user.isReady,
  user = get.user,
  setDepartMentId = update.user.data.departmentId,
  getContracts = update.pages.dashboard.triggers.getContracts,
  error = update.errors[param.id],
}) => {
  if (!trigger || !isAuth || !isReady) {
    return
  }
  user = user.value()
  try {
    console.log('setUserDepartment: ', trigger, user.data.departmentId)
    if (trigger !== user.data.departmentId) {
      const usersCollection = db.collection('users')
      // const userDoc = await usersCollection.doc(user.uid).get()
      // if (userDoc.exists) {
      usersCollection.doc(user.uid).set({ data: { departmentId: trigger } }, { merge: true })
      // }
      setDepartMentId.set(trigger)
      // workaround to make sure dashboard is always in sync with the selected department
      // its ok now that you only cand change dept from dashboard where we also display the contracts
      getContracts.set(Date.now())
    }
  } catch (e) {
    error.set(
      {
        message: e,
        level: LogLevels.WARN,
      } as ApplicationError,
      { id: 'setUserDepartment' }
    )
  } finally {
    triggerUpdate.remove()
  }
}
