import React from "react";
import { Menu, Dropdown } from "antd";

import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { ContractStatus, UserRole } from "src/types";
import { populateContactPerson } from "src/pages/Contract/subviews/ContractForm/PartiesClause/TenantCPDropdown.populateContactPerson";
import { TenantCPDropdownRow } from "./TenantCPDropdownRow";

export const TenantCPDropdown: view = ({
  selectedTenant = observe.contract.tenant,
  selectedCP = observe.contract.temp.selectedCP,

  status = observe.contract.original.state.status,
  userRole = get.user.data.role,
}) => {
  const isTenant = userRole.value() === UserRole.TENANT;
  const mainContacts = selectedTenant?.mainContacts;
  if (status !== ContractStatus.DRAFT || isTenant || !mainContacts || mainContacts.length <= 1) return null;

  const tenantsListDisplay = (
    <Menu>
      {mainContacts.map((CP: any, index: any) => (
        <Menu.Item key={index}>
          <TenantCPDropdownRow CP={CP} index={index} />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="inline-block relative w-full  my-2" id="tenantCPDropdown">
      <Dropdown
        overlay={tenantsListDisplay}
        trigger={["click"]}
        overlayClassName={"tenantCPDropdown"}
        getPopupContainer={() => document.getElementById("tenantCPDropdown") as HTMLElement}>
        <button
          type="button"
          className="bg-white kpy-10 kpx-14 flex w-full justify-between items-center border klp-border1">
          {selectedCP !== undefined ? (
            <TenantCPDropdownRow CP={mainContacts[selectedCP]} />
          ) : (
            <span key="1">{i18n.t("clause_parties_tenant_choose_a_cp")}</span>
          )}
          {<SVGs.DropdownArrow />}
        </button>
      </Dropdown>
    </div>
  );
};

TenantCPDropdown.producers([populateContactPerson]);
