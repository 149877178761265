import { RadioButton, RadioButtonGroup } from "@c11/components";
import React from "react";
import {
  ClauseCard
} from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import {
  InsideCard
} from "src/pages/Contract/subviews/ContractForm/ClauseCard/InsideCard";
import {
  CalculationFieldManager
} from "src/pages/Contract/subviews/ContractForm/fields/CalculationFieldManager";

import {
  DateFieldGeneral,
  FreeTextFieldGeneral,
  NumberInputGeneral,
  DropdownInputGeneral,
} from "src/pages/Contract/subviews/ContractForm/fields/FormField";
import i18n from "src/producers/languages/i18n";
import styles from "../../styles.module.css";
import { FreeTextGeneralClause } from "./FreeTextGeneralClause";

export const RentalPeriodAndPriceAddendum: view = ({
  hasRentalPeriodChange = observe.contract.values.hasRentalPeriodChange.displayedValue,
  hasRentalPriceChange = observe.contract.values.hasRentalPriceChange.displayedValue,
  rentalPeriodHasOptions = observe.contract.values.rentalPeriodHasOptions.displayedValue,
  rentalPeriodEorRDV = observe.contract.values.rentalPeriodEorR.displayedValue,
  rentalPeriodEorRFromChanges = observe.contract.currentSession.changes.rentalPeriodEorR.value,
  rentalPeriodOptionsPeriod = observe.contract.values.rentalPeriodOptionsPeriod.displayedValue,
  setRentalChangeOption = update.contract.toSave.changes[param.option],
  setRentalPeriodEorR = update.contract.toSave.changes.rentalPeriodEorR,
}) => {
  const periodClauseDisabled = !hasRentalPeriodChange || hasRentalPeriodChange === "false";
  const priceClauseDisabled = !hasRentalPriceChange || hasRentalPriceChange === "false";
  const potentialOptsClauseDisabled = !rentalPeriodHasOptions || rentalPeriodHasOptions === "false";
  const rentalPeriodEorR = rentalPeriodEorRFromChanges || rentalPeriodEorRDV;

  function toggleSwitch(option: string) {
    let value;
    if (option === "hasRentalPeriodChange") {
      value = hasRentalPeriodChange === "true" ? "false" : "true";
    }
    if (option === "hasRentalPriceChange") {
      value = hasRentalPriceChange === "true" ? "false" : "true";
    }
    if (option === "rentalPeriodHasOptions") {
      value = rentalPeriodHasOptions === "true" ? "false" : "true";
    }
    setRentalChangeOption.set({ value, createdAt: Date.now() }, { option });
  }

  // custom logic to display the clause number dynamically
  function dynamicClauseNo(currentNo, skip = null) {
    let res = currentNo;
    if (hasRentalPeriodChange === "true" && skip !== "period") {
      res += 1.0;
    }
    if (hasRentalPriceChange === "true" && skip !== "price") {
      res += 1.0;
    }
    if (rentalPeriodHasOptions === "true" && skip !== "options") {
      res += 1.0;
    }
    if (res >= 4 && skip && skip !== "options") {
      res -= 1.0;
    }
    if (res >= 3 && skip === "period") {
      res -= 1.0;
    }
    return Number(res.toFixed(1));
  }

  return (
    <div>
      <FreeTextGeneralClause varName="backgroundAndPurpose"
                             title={`1. ${i18n.t("clause_background_reason_title")}`}/>
      <ClauseCard
        title={`${dynamicClauseNo(2, "period")}. ${i18n.t("clause_change_rental_period_title")}`}
        hasSwitch
        switchValue={hasRentalPeriodChange}
        onToggleSwitch={() => toggleSwitch("hasRentalPeriodChange")}>
        <InsideCard>
          <FreeTextFieldGeneral varName="changeRentalPeriod"
                                readOnly={periodClauseDisabled}/>
          <div className={`${styles.datesRow}`}>
            <div className="flex flex-col w-64">
              <DateFieldGeneral
                varName="rentalStart"
                placeholder={i18n.t("placeholders.select_date")}
                required
                readOnly={periodClauseDisabled}
                label={i18n.t("clause_rental_period_rental_start")}
              />
            </div>
            <div className="flex flex-col w-64">
              <DateFieldGeneral
                varName="rentalEnd"
                placeholder={i18n.t("placeholders.select_date")}
                required
                readOnly={periodClauseDisabled}
                label={i18n.t("clause_rental_period_rental_end")}
              />
            </div>
          </div>
        </InsideCard>
      </ClauseCard>
      <ClauseCard
        title={`${dynamicClauseNo(2, "price")}. ${i18n.t("clause_change_rental_price_title")}`}
        hasSwitch
        switchValue={hasRentalPriceChange}
        onToggleSwitch={() => toggleSwitch("hasRentalPriceChange")}>
        <InsideCard>
          <FreeTextFieldGeneral varName="changeRentalPrice"
                                readOnly={priceClauseDisabled}/>
          <div className={`${styles.turnoverRow}`}>
            <p className="w-1/2">{i18n.t("clause_rental_costs_rent_p1")}</p>
            <NumberInputGeneral
              placeholder={i18n.t("clause_rental_costs_turnover_rent_p")}
              varName="turnoverPercent"
              label={i18n.t("clause_rental_costs_turnover_rent")}
              readOnly={priceClauseDisabled}
            />
          </div>
          <div className={`${styles.turnoverRow}`}>
            <p className="">{i18n.t("clause_rental_costs_rent_p2")}</p>
            <NumberInputGeneral
              placeholder={i18n.t("clause_rental_costs_placeholder_minimum_rent")}
              varName="minimumRent"
              label={i18n.t("clause_rental_costs_minimum_rent")}
              readOnly={priceClauseDisabled}
            />
          </div>
          <div className={`${styles.turnoverRow}`}>
            <p>{i18n.t("clause_rental_costs_rent_p3")}</p>

            <CalculationFieldManager {...{
              varName: "MTO",
              fieldName: "MTO",
              readOnly: priceClauseDisabled
            }} />
          </div>
          <h4 className="font-bold my-4 uppercase w-full">
            {`${dynamicClauseNo(2.1, "price")}. ${i18n.t("clause_rental_costs_index_adjustment_title")}`}
          </h4>
          <div>
            <p key="p" className="w-full pb-2">
              {i18n.t("clause_rental_costs_index_adjustment_p1")}
            </p>
          </div>
          <div className={`${styles.datesRow}`}>
            <div className="flex flex-col w-64">
              <span>
                <span className="text-pink-600">*</span>
                {i18n.t("clause_rental_costs_index_adjustment_first_time_regulation")}
              </span>
              <DateFieldGeneral
                varName="firstTimeRegulation"
                placeholder={i18n.t("placeholders.select_date")}
                readOnly={priceClauseDisabled}
              />
            </div>

            <div className="flex flex-col w-64">
              <span>
                <span className="text-pink-600">*</span>
                {i18n.t("clause_rental_costs_index_adjustment_basic_index")}
              </span>
              <DateFieldGeneral
                varName="indexAdjustmentBasicIndex"
                placeholder={i18n.t("placeholders.select_date")}
                readOnly={priceClauseDisabled}
              />
            </div>
          </div>
          {" "}
        </InsideCard>
      </ClauseCard>
      <ClauseCard
        title={`${dynamicClauseNo(2, "options")}. ${i18n.t("clause_rental_period_potential_options")}`}
        hasSwitch
        switchValue={rentalPeriodHasOptions}
        onToggleSwitch={() => toggleSwitch("rentalPeriodHasOptions")}>
        <InsideCard>
          <div className={`${styles.rentalRadioOptions}`}>
            <RadioButtonGroup selectedValue={rentalPeriodEorR}>
              <RadioButton
                key={`13}`}
                className="mb-5"
                value={"E"}
                isDisabled={potentialOptsClauseDisabled}
                onClick={() =>
                  rentalPeriodEorR !== "E" ? setRentalPeriodEorR.set({
                    value: "E",
                    createdAt: Date.now()
                  }) : {}
                }>
                {i18n.t("clause_rental_period_potential_options_extension_u")}
              </RadioButton>
              <RadioButton
                key={`14}`}
                className="mb-5"
                value={"R"}
                isDisabled={potentialOptsClauseDisabled}
                onClick={() =>
                  rentalPeriodEorR !== "R" ? setRentalPeriodEorR.set({
                    value: "R",
                    createdAt: Date.now()
                  }) : {}
                }>
                {i18n.t("clause_rental_period_potential_options_renewal_u")}
              </RadioButton>
            </RadioButtonGroup>
            <DropdownInputGeneral
              choises={[
                "rentalPeriodOptionsPeriod_o1",
                "rentalPeriodOptionsPeriod_o2",
                "rentalPeriodOptionsPeriod_o3",
                "rentalPeriodOptionsPeriod_o4",
              ]}
              varName="rentalPeriodOptionsPeriod"
              placeholder={`${i18n.t("clause_rental_period_potential_options_period")}`}
              readOnly={potentialOptsClauseDisabled || !rentalPeriodEorR}
              extraClasses="my-2"
              required
            />
          </div>
          <p key="p3" className="pb-2 pt-1">
            <span key="0">
              {i18n.t("rentalPeriodOptionsPeriod_p1_1", {
                ER:
                  rentalPeriodEorR === "R"
                    ? i18n.t("clause_rental_period_potential_options_renewal")
                    : i18n.t("clause_rental_period_potential_options_extension"),
              })}
            </span>
            <strong key="1">
              {rentalPeriodOptionsPeriod &&
                i18n.t(rentalPeriodOptionsPeriod) + i18n.t("rentalPeriodOptionsPeriod_p1_2")}
            </strong>
            <span key="2">
              {i18n.t("rentalPeriodOptionsPeriod_p1_3", {
                ER:
                  rentalPeriodEorR === "R"
                    ? i18n.t("clause_rental_period_potential_options_renewal")
                    : i18n.t("clause_rental_period_potential_options_extension"),
                T:
                  rentalPeriodEorR === "R"
                    ? i18n.t("clause_rental_period_potential_options_renegotiation")
                    : i18n.t("clause_rental_period_potential_options_same"),
              })}
            </span>
          </p>
        </InsideCard>
      </ClauseCard>
      <FreeTextGeneralClause varName="security"
                             title={`${dynamicClauseNo(2)}. ${i18n.t("clause_security_title")}`}/>
      <FreeTextGeneralClause
        varName="cumpulsoryDerogation"
        title={`${dynamicClauseNo(3)}. ${i18n.t("clause_adoption_derogation_title")}`}
      />
      <FreeTextGeneralClause
        varName="otherProvisions"
        title={`${dynamicClauseNo(4)}. ${i18n.t("clause_other_provisions_title")}`}
      />
    </div>
  );
};
