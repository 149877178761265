import { UserRole } from "src/types";
import i18n from "src/producers/languages/i18n";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import styles from "../../../../../../Contract/styles.module.css";
import { RentalCostManager } from "./RentalCostManager";
import { RentalCostTenant } from "./RentalCostTenant";

export const RentalCost: view = ({ userRole = observe.user.data.role }) => {
  const isManager = userRole === UserRole.MANAGER;
  return (
    <ClauseCard title={`4. ${i18n.t("storage_addendum.rental_cost_title")}`}>
      <div className={`${styles.contractCard}`}>{isManager ? <RentalCostManager /> : <RentalCostTenant />}</div>
    </ClauseCard>
  );
};
