import { ContractStatus, UserRole, Language } from "src/types";
import moment from "moment";
import i18n from "src/producers/languages/i18n";

require("moment/locale/nb");

export const syncUpdatesFeed: producer = ({
  versions = observe.legacyContract.original.versions,
  comments = observe.legacyContract.original.comments,
  allValues = observe.legacyContract.values,
  trigger = observe.legacyContract.triggers.syncFeed,

  status = get.legacyContract.original.status,
  movedToSigningAt = get.legacyContract.original.movedToSigningAt,
  displayedVersion = observe.legacyContract.displayedVersion,

  setFeed = update.legacyContract.updatesFeed,
  removeTrigger = update.legacyContract.triggers.syncFeed,
}) => {
  if (i18n.language === Language.NORWEGIAN) moment.locale("nb");
  else moment.locale("en");

  if (!trigger || (!versions && !comments) || !allValues) {
    return;
  }
  removeTrigger.remove();
  let updatesFeed: any = [];
  try {
    if (versions) {
      Object.keys(versions).forEach((versionId, index, array) => {
        const isLastVersion = array.length - 1 === index;
        const version = versions[versionId];
        let feed: any = {
          type: "version",
          versionId: parseInt(versionId),
          createdBy: UserRole.MANAGER,
          createdAt: version.createdAt,
          isLastVersion,
        };
        if (String(versionId) !== "1") {
          Object.keys(version.variables).forEach((varName) => {
            const variable = version.variables[varName];
            feed.changes = {
              ...feed.changes,
              ...{
                [varName]: {
                  value: variable.value,
                  varName: varName,
                  createdBy: UserRole.MANAGER,
                  antecedent: variable.antecedent || "",
                },
              },
            };
          });
        } else {
          feed.type = "contractSent";
        }
        updatesFeed.push(feed);
      });
    }
    if (comments) {
      Object.keys(comments).forEach((commentId) => {
        const comment = comments[commentId];
        const feed = {
          type: "comment",
          ...comment,
        };
        updatesFeed.push(feed);
      });
    }

    // add a aditional bubble when the contract moves to signing
    movedToSigningAt = movedToSigningAt.value();
    if (status.value() === ContractStatus.SIGNING && movedToSigningAt && !displayedVersion) {
      updatesFeed.push({
        type: "sign",
        createdAt: movedToSigningAt,
      });
    }

    //filter older version
    if (displayedVersion) {
      updatesFeed = updatesFeed.filter((feed: any) => feed.versionId <= parseInt(displayedVersion));
    }

    updatesFeed.sort((a: any, b: any): any => (parseInt(a.createdAt) < parseInt(b.createdAt) ? -1 : 1));

    //format the timestamp with moment
    updatesFeed = updatesFeed.map((feed: any) => {
      return { ...feed, createdAt: moment(feed.createdAt).fromNow() };
    });

    setFeed.set(updatesFeed);
  } catch (e) {
    console.error("syncUpdatesFeed", e);
  }
};
