import React from "react";

import { Modal } from "src/components";
import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";

export const DeleteAppendixModal: view = ({
  appendixToDelete = observe.modal.deleteAppendixModal,
  triggerDeleteAppendix = update.legacyContract.triggers.deleteAppendix,
  appendices = observe.legacyContract.original.appendices,
}) => {
  if (appendixToDelete === undefined || !appendices) return null;
  return (
    <Modal
      title={i18n.t("modals_appendix_delete_title")}
      okButtonText={i18n.t("modals_appendix_delete_button")}
      image={() => <SVGs.ModalRemoveChanges />}
      onOkClick={() => triggerDeleteAppendix.set(appendixToDelete)}>
      <p>
        {i18n.t("modals_appendix_delete_p1")}{" "}
        <span key="1" className="font-medium">
          {appendices[appendixToDelete].fileName}
        </span>
        <span key="2"> ?</span>
      </p>
    </Modal>
  );
};
