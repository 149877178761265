import { MenuProps } from "antd";
import i18n from "src/producers/languages/i18n";

type BuildingDropdownMenuParams = {
  onClick: (key: string) => void;
  items: Array<{
    key: string;
    name: string;
    departmentname: string;
    address: string;
    buildingImageUrl: string;
  }>;
};

export function BuildingDropdownMenuFactory(params: BuildingDropdownMenuParams): MenuProps['items'] {
  if (!params?.items || params.items.length === 0) {
    return [{
      key: '',
      label: (<div className="flex-grow">{i18n.t(`general.no_results_found`)}</div>)
    }]
  }

  return params.items.map(item => ({
    key: item.key,
    label: (<div className="w-full flex flex-row" onClick={() => params?.onClick && params.onClick(item.key)}>
      <div className="mr-4">
        <img src={item.buildingImageUrl} alt="" className="rental-premise-image" />
      </div>
      <div className="flex flex-col gap-1 flex-grow">
        <div>{item.name} - {item?.departmentname}</div>
        <div>{item.address}</div>
      </div>
    </div>)
  }))
}
