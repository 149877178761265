import _numeral from 'src/utils/_numeral'
import i18n from 'src/producers/languages/i18n'
import { SigningSummaryVerified } from 'src/assets/svg'
import { ThumbnailCard, ThumbnailCardBody, ThumbnailCardHeader, ThumbnailCardHeaderV2 } from 'src/components'

import styles from '../../../styles.module.css'
import { ContractStatus } from 'src/types'
import { ActiveSignerListItem, InactiveSignerListItemSigned, InactiveSignerListItemUnsigned } from './SignerListItem'
import { ActivePDFListItem, InactivePDFListItem } from './PdfListItem'
import { ExpiryDate } from './ExpiryDate'
import { SendSigningNotificationModal } from 'src/pages/ContractV2/modals/SendSigningNotificationModal/SendSigningNotificationModal'

export const SigningSummaryCard: view = ({
  document = observe.contract.originalv2.envelope.documents[0],
  envelopeModifiedDate = get.contract.originalv2.envelope.modified,
  contractType = get.contract.originalv2.contractType,
  status = observe.contract.originalv2.state.status,
}) => {
  const recipients = document?.recipients
  if (!recipients) return null
  let lastSignedRecipientIndex: any = null
  let isRejected: boolean = false
  const isActive = status === ContractStatus.ACTIVE
  recipients.forEach(({signature, completed}: any, i: number) => {
    if (signature?.status === 'rejected') isRejected = true
    if (completed) lastSignedRecipientIndex = i
  })
  contractType = contractType.value()
  return (
    <>
      <ThumbnailCard className={`${styles.summaryCardSigning}`}>
        <ThumbnailCardHeaderV2
          className={`${styles.signingCardsHeaderTitle}`}
          key="tch-1"
          title={i18n.t('summary_page.signing_card_title')}
          subtitle={i18n.t('summary_page.signing_card_subtitle')}>
          <div className="float-right inline-block">
            <div className="flex items-center">
              <ExpiryDate />
              <SigningSummaryVerified />
            </div>
          </div>
        </ThumbnailCardHeaderV2>
        <ThumbnailCardBody key="tcb-1" className={`${styles.summaryCardSigningBody}`}>
          {recipients.map((recipient, i) => {
            if (recipient.completed) {
              return <InactiveSignerListItemSigned recipient={recipient} key={i} />
            }
            if (lastSignedRecipientIndex === i - 1 || (i === 0 && lastSignedRecipientIndex === null)) {
              const lastSignDate = recipients?.[lastSignedRecipientIndex]?.signature?.date
              const date = lastSignDate || envelopeModifiedDate.value()
              return <ActiveSignerListItem recipient={recipient} index={i} key={i} date={date} />
            } else {
              return <InactiveSignerListItemUnsigned recipient={recipient} index={i} key={i} />
            }
          })}
          {isActive ? <ActivePDFListItem /> : <InactivePDFListItem isRejected={isRejected}/>}
        </ThumbnailCardBody>
      </ThumbnailCard>
      <SendSigningNotificationModal />
    </>
  )
}
