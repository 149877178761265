import { Button } from "@c11/components.button";
import "./PartiesCard.css";

import i18n from "src/producers/languages/i18n";
import { SVGs } from "src/assets";
import { ContractPageSubview, ContractStatus, RouteName } from "src/types";
import { PersonSilhouetteIcon } from "src/assets/svg";
import { ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from "src/components";

import styles from "../../../styles.module.css";
import { VisibleIf } from "src/components_generic/visible-if";

export const PartiesCard: view = ({
  setSubview = update.routes.list[RouteName.CONTRACT_V2].data.subview,

  status = observe.contract.originalv2.status,
  signersUIData = observe.contract.ui.signers,
}) => {
  const isSigning = status === ContractStatus.SIGNING;
  const isActive = status === ContractStatus.ACTIVE;
  const isSigningorActive = isSigning || isActive;

  return (
    <>
      <ThumbnailCard className={`${styles.contractSummaryCard} ${isSigningorActive && styles.borderedCard}`}>
        <ThumbnailCardHeader
          className={`${styles.summaryCardsHeader}`}
          key="tch-1"
          title={i18n.t("contract_navbar.signers")}
          separator={true}>
          <div className="float-right inline-block">
            <SVGs.OverviewParties />
          </div>
        </ThumbnailCardHeader>
        <ThumbnailCardBody key="tcb-1">
          <VisibleIf predicate={!signersUIData?.count}>
          <div className="bg-gray-100 flex px-6 py-4 mt-2">
            <div className="flex flex-auto items-center ">
              <SVGs.ExclamationCircleUnfilled className="mr-2" />
              <div className="ml-3">
                <div className="">{i18n.t("parties_page.no_signers_card_p1")}.</div>
              </div>
            </div>
          </div>
          </VisibleIf>
          <VisibleIf predicate={signersUIData?.count > 0}>
          {
            signersUIData?.orderSortedArray.map((signer, index) => <div key={`signer-${index}`} className={`${styles.summaryList}`}>
              <span>{`${signer.firstName} ${signer.lastName}`}</span>
              <VisibleIf predicate={signer.recipientAction === "sign"}>
                <div>
                  <SVGs.OverviewPartySign />
                </div>
              </VisibleIf>
            </div>)
          }
          </VisibleIf>
        </ThumbnailCardBody>
        <ThumbnailCardFooter key="tcf-1">
          <Button
            className="view-signers-btn border-secondary second-theme-color"
            variant="secondary"
            onClick={() => {
              setSubview.set(ContractPageSubview.PARTIES);
            }}>
            <PersonSilhouetteIcon />
            <span key="2" className="pl-2 uppercase">
              {i18n.t("summary_page.view_signers")}
            </span>
          </Button>
        </ThumbnailCardFooter>
      </ThumbnailCard>
    </>
  );
};
