import { fields } from "src/templates/KLP-digital-v2/fields";
import i18n from "src/producers/languages/i18n";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";

export const syncFields: producer = ({
  id = observe.contract.id,
  setTemplate = update.contract.fields,
  error = update.errors[param.id],
}) => {
  if (!id) {
    return;
  }
  try {
    const f = JSON.parse(JSON.stringify(fields));
    Object.keys(f).forEach((fieldID) => {
      const field = f[fieldID];
      if (field.label) f[fieldID].label = i18n.t(field.label);
      if (field.placeholder) f[fieldID].placeholder = i18n.t(field.placeholder);
    });
    setTemplate.set(f);
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.APPLICATION,
      } as ApplicationError,
      { id: "syncFields" }
    );
  }
};
