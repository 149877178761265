import React from "react";

import { Checkbox } from "../Checkbox";

// The VAT type
export const CheckboxType1: view = ({ label, checked, onClick, children }) => {
  return (
    <div className="flex w-full pb-4 pt-4 border-t klp-border1 items-center">
      <div key="1" className="w-4/12 flex items-center">
        <Checkbox key="1.0" {...{ checked, onClick }} />
        <p key="1.1" className="font-semibold text-sm">
          {label}
        </p>
      </div>
      <div key="2" className="w-8/12 pr-6">
        {children}
      </div>
    </div>
  );
};
