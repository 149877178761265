import {db} from "src/producers/firebase";
import {
  ApplicationError,
  ContractType,
  ErrorLevel,
  ErrorType
} from "src/types";
import { localLandlords } from "src/assets/localLandlords";

export const syncLandlords: producer = async ({
  sessionReady = observe.session.firebase.isReady,
  userReady = observe.user.isReady,
  updateLandlords = update.landlords,
  error = update.errors[param.id],
}) => {
  if (!sessionReady || !userReady) return;
  try {
    // const syncLandlords = functions.httpsCallable("syncLandlords");
    // const res = await syncLandlords({ contractType: contractOriginal?.contractType });
    let landlords: any = [];
    if (process.env.NODE_ENV !== 'development') {
      const landlordsSnap = (await db.collection("landlords").get()).docs;
      for (const landlord of landlordsSnap) {
        const data = landlord.data();
        if (!data) continue;
        landlords.push(data);
      }
    } else {
      landlords = localLandlords
    }
    updateLandlords.set({data: landlords});
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      {id: "syncLandlords"}
    );
  }
};
