import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'
import { db } from 'src/producers/firebase'

const gatherTenants = async () => {
  const tenants: any = {}
  const simpleQuery = await db.collection('tenants').get()

  for (let i = 0; i < simpleQuery.docs.length; i++) {
    const tenant = simpleQuery.docs[i].data()
    if (tenant.mainContacts) tenant.mainContacts = Object.keys(tenant.mainContacts).map((x) => tenant.mainContacts[x])
    tenants[tenant.id] = tenant
  }

  return tenants
}

export const syncTenants: producer = async ({
  sessionReady = observe.session.firebase.isReady,
  userReady = observe.user.isReady,
  setTenants = update.tenants.all,
  error = update.errors[param.id],
  setSyncIsReady = update.tenants.syncIsReady,
  featureFlag = observe.featureFlags.tenantsDropdown,
}) => {
  return
  if (!sessionReady || !userReady || !featureFlag?.main) return
  try {
    setSyncIsReady.set(false)

    const tenants = await gatherTenants()

    setTenants.set(tenants)

    setSyncIsReady.set(true)
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'syncTenants' }
    )
  }
}
