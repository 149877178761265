import { RouteList, RouteName } from 'src/types'

//todo this is RouteList but there's an issue with RouteName enum and parsing through routes, cause they are strings and not enum
export const routes: any = {
  [RouteName.LOGIN]: {
    id: RouteName.LOGIN,
    isMounted: false,
    auth: false,
    // redirectTo: ,
    path: '^/login$',
  },
  [RouteName.INITIALIZATION]: {
    id: RouteName.INITIALIZATION,
    isMounted: true,
    auth: false,
    path: '^/init$',
  },
  [RouteName.DASHBOARD]: {
    id: RouteName.DASHBOARD,
    isMounted: false,
    auth: true,
    path: '^/',
  },
  [RouteName.CONTRACT]: {
    id: RouteName.CONTRACT,
    isMounted: false,
    auth: true,
    path: '^/contract/[^/]+$',
    data: {},
  },
  [RouteName.CONTRACT_V2]: {
    id: RouteName.CONTRACT_V2,
    isMounted: false,
    auth: true,
    path: '^/contract/[^/]+$',
    data: {},
  },
  [RouteName.ADDENDUM]: {
    id: RouteName.ADDENDUM,
    isMounted: false,
    auth: true,
    path: '^/addendum/[^/]+$',
    data: {},
  },
  [RouteName.LEGACY_CONTRACT]: {
    id: RouteName.LEGACY_CONTRACT,
    isMounted: false,
    auth: true,
    path: '^/contract/[^/]+$',
    data: {},
  },
}
