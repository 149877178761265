import { db } from 'src/producers/firebase'
import { ApplicationError, ErrorLevel, ErrorType } from 'src/types'

export const syncDefaultValues: producer = async ({
  isReady = observe.session.isReady,
  setDefaults = update.defaultValues,
  error = update.errors[param.id],
}) => {
  if (!isReady) return
  try {
    let defaultValues = require('src/assets/defaultValues.json')
    const templates = Object.keys(defaultValues)
    for (const template of templates) {
      const templateData = defaultValues[template]
      for (const key of Object.keys(templateData)) {
        defaultValues[template][key] = { value: templateData[key] }
      }
    }
    setDefaults.set(defaultValues)
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'syncDefaults' }
    )
  }
}
