import i18n from "src/producers/languages/i18n";
import { ClauseCard } from "src/pages/Contract/subviews/ContractForm/ClauseCard/ClauseCard";
import styles from "../../../../styles.module.css";

export const AttachmentsList: view = ({ attachments = observe.contract.original.state.attachments }) => {
  const attachmentsInOrder =
    attachments &&
    Object.values(attachments)
      .sort((a, b) => a.order - b.order)
      .filter((a: any) => a.selected === true);

  return (
    <ClauseCard title={`4. ${i18n.t("clause_intro_title")}`}>
      <div className={`${styles.contractCard}`}>
        <ul className="list-disc">
          {attachmentsInOrder?.map((a) => (
            <li className="mb-1" key={a.fileName}>
              {a.fileName}
            </li>
          ))}
        </ul>
      </div>
    </ClauseCard>
  );
};
