export interface User {
  id: string;
  displayName: string;
  email: string;
  role: UserRole;
  language: string;
}

export enum UserRole {
  MANAGER = "manager",
  TENANT = "tenant",
}

export enum PartyType {
  TENANT = "tenant",
  LANDLORD = "landlord",
}

export const authOptions = {
  "google.com": "google",
  "oidc.klp-dev": "klp",
  "oidc.klp-prod": "klp",
};
