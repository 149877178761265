import i18n from 'src/producers/languages/i18n'
import {
  ApplicationError,
  ContractType,
  ErrorLevel,
  ErrorType
} from 'src/types'
const emailRegex = /^\S+@\S+\.\S+$/
const phoneRegex = /^[+]?[\d\s()-]{7,}$/

export const valueValidation: producer = ({
  values = observe.contract.values,
  contractPdf = get.contract.original.state.contractPdf,
  templateFields = get.contract.fields,
  state = get.contract.original.state,
  updateInvalidFields = update.contract.invalidFields[param.varName],
  removeAllInvalidFields = update.contract.invalidFields,
  removeInvalidField = update.contract.invalidFields[param.varName],
  error = update.errors[param.id],
}) => {
  templateFields = templateFields.value()
  if (!values || !templateFields) return

  state = state.value()

  try {
    if (
      state.contractType === ContractType.PAPER &&
      !state?.contractPdf?.signed?.path
    ) {
      updateInvalidFields.set(i18n.t(`validation_message_contractPdf`), { varName: 'contractPdf' })
    } else {
      removeInvalidField.remove({ varName: 'contractPdf' })
    }

    for (const varName in values) {
      const value = values[varName]?.displayedValue
      switch (varName) {
        case 'landlordEmail':
        case 'tenantEmail':
          removeInvalidField.remove({ varName: varName })
          if (!value || value.length < 1) continue
          const isValidEmail = emailRegex.test(value)
          if (!isValidEmail) {
            updateInvalidFields.set(i18n.t(`validation_message_email`), { varName: varName })
          }
          break
        case 'landlordPhone':
        case 'tenantPhone':
          removeInvalidField.remove({ varName: varName })
          if (!value || value.length < 1) continue
          const isValidPhone = phoneRegex.test(value)
          if (!isValidPhone) {
            updateInvalidFields.set(i18n.t(`validation_message_phone`), { varName: varName })
          }
          break
        case 'rentalEnd':
          removeInvalidField.remove({ varName: varName })
          if (!value || value.length < 1) continue
          const rentalStart = values.rentalStart?.displayedValue
          if (rentalStart && rentalStart > value) {
            updateInvalidFields.set(i18n.t(`validation_message_rentalEnd`), { varName: varName })
          }
          break
      }
    }
  } catch (e) {
    console.error('Error in valueValidation', e)
    error.set(
      {
        message: e,
        level: ErrorLevel.WARNING,
        type: ErrorType.APPLICATION,
      } as ApplicationError,
      { id: 'valueValidation-contract' }
    )
  }
}
