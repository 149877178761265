import React from "react";

import { Card } from "../../index";
import { SignatureCheckbox } from "./SignatureCheckbox";
import { Signatories } from "./Signatories";
import { AcceptCheckbox } from "./AcceptCheckbox";

export const Signature: view = ({ title }) => {
  return (
    <Card title={title} editableByTenant>
      <AcceptCheckbox key="0" />
      <SignatureCheckbox key="1" />
      <Signatories key="2" />
    </Card>
  );
};
