import { db } from "src/producers/firebase"
import {ApplicationError, ErrorLevel, ErrorType, LogLevels} from "src/types"

export const persistPartyOrder: producer = async ({
  contractId = get.contract.id,
  isLocked = get.contract.isLocked,

  signers = get.contract.originalv2.signers,
  updateContractSigners = update.contract.originalv2.signers,

  isSaving = update.contract.saveStatus.isSaving,
  successfulSave = update.contract.saveStatus.successfulSave,
  failedSave = update.contract.saveStatus.failedSave,
  error = update.errors[param.id],

  reorderedParty = observe.contract.changes.parties.reordered,
  updateReorderedParty = update.contract.changes.parties.reordered,
}) => {
  if (!reorderedParty) return
  if (!reorderedParty.partyId) return
  if (!reorderedParty.direction) return

  isLocked = isLocked.value()
  if (isLocked) return

  contractId = contractId.value()
  if (!contractId) return

  signers = signers.value()
  if (!signers) return
  if (Object.keys(signers).length < 2) return

  const target = Object.values(signers).find((signer) => signer.partyId === reorderedParty.partyId)
  if (!target) return

  let swapWithOrder = -1
  if (reorderedParty.direction === "up") {
    swapWithOrder = Math.max(
      ...Object.values(signers)
        .map((s) => s.order)
        .filter((s) => !!s)
        .filter((s) => s < target.order)
    )
  }
  if (reorderedParty.direction === "down") {
    swapWithOrder = Math.min(
      ...Object.values(signers)
        .map((s) => s.order)
        .filter((s) => !!s)
        .filter((s) => s > target.order)
    )
  }

  if (swapWithOrder === -1) return

  const swapWith = Object.values(signers).find((signer) => {
    return signer.order === swapWithOrder
  })
  if (!swapWith) return

  try {
    console.log(`Updating edited party`, reorderedParty)
    isSaving.set(true)
    const updatedAt = new Date().toISOString()

    await db
      .collection("contracts")
      .doc(contractId)
      .set(
        {
          signers: {
            [target.partyId]: {
              ...target,
              order: swapWith.order,
            },
            [swapWith.partyId]: {
              ...swapWith,
              order: target.order,
            },
          },
          updatedAt,
        },
        {
          merge: true,
        }
      )

    updateContractSigners.merge({
      [target.partyId]: {
        ...target,
        order: swapWith.order,
      },
      [swapWith.partyId]: {
        ...swapWith,
        order: target.order,
      },
    })
    successfulSave.set(true)
  } catch (e) {
    console.error("persist-party-reorder", e)
    failedSave.set(true)
    error.set(
      {
        message: "saving-tenant-to-database",
        level: LogLevels.WARN,
      } as ApplicationError,
      { id: "persist-party-reorder" }
    )
  } finally {
    updateReorderedParty.remove()

    isSaving.set(false)
  }
}
