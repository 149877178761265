import { Button } from "@c11/components.button";
import "./AddAttachmentButton.css";

import { PaperclipIcon } from "src/assets/svg";
import i18n from "src/producers/languages/i18n";
import { addAttachment } from "src/pages/Contract/subviews/Attachments/AddAttachmentButton/AddAttachmentButton.addAttachment";

export const AddAttachmentButton: view = ({
  _viewId,
  setAppendixToSave = update.contract.triggers.saveAttachment,
  setOpenDialog = update.views[prop._viewId].openDialog,
  openDialog = observe.views[prop._viewId].openDialog,
}) => (
  <Button
    variant="secondary"
    className="upload-attachment-btn flex uppercase gap-2 mr-3"
    onClick={() => {
      !openDialog && document.getElementById("upload-attachment-input")?.click();
      //This logic is here to prevent double clicks
      setOpenDialog.set(true);
      setTimeout(() => setOpenDialog.set(false), 1000);
    }}>
    <PaperclipIcon fill="none" stroke="currentColor" />
    <input
      id="upload-attachment-input"
      type="file"
      onChange={(e) => {
        console.log("changing appendinx");
        setAppendixToSave.set({ data: e.target.files[0] });
      }}
      accept="application/pdf, image/png, image/jpeg, image/jpg"
    />
    <span>{i18n.t("attachments_page.button_attachments_upload")}</span>
  </Button>
);

AddAttachmentButton.producers([addAttachment]);
