import React from "react";

import { Modal } from "src/components";
import { SVGs } from "src/assets";
import i18n from "src/producers/languages/i18n";
import { UserRole } from "src/types";

export const SendContractModal: view = ({
  show = observe.modal.sendContract,
  triggerVersion = update.legacyContract.triggers.triggerVersion,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  if (!show || !isManager) return null;
  return (
    <Modal
      title={i18n.t("modals.send_contract_title")}
      okButtonText={i18n.t("contract_navbar.button_send_contract")}
      image={() => <SVGs.ModalPlane />}
      onOkClick={() => triggerVersion.set({ callback: "sendContract" })}>
      <p>{i18n.t("modals.send_contract_p1")}</p>
    </Modal>
  );
};
