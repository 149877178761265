import React from "react";

import { VersionDropdownRow } from "./VersionDropdownRow";
import { SVGs } from "src/assets";
import { Menu, Dropdown } from "antd";

export const VersionsDropdown: view = ({
  versions = observe.contract.original.versions,
  setDisplayedVersion = update.contract.displayedVersion,
  displayedVersion = observe.contract.displayedVersion,
  hasChanges = observe.contract.hasChanges,
}) => {
  if (!versions || Object.keys(versions).length === 0) {
    return null;
  }

  let versionIdsArray = Object.keys(versions);
  versionIdsArray.sort((a, b) => (versions[a]["createdAt"] < versions[b]["createdAt"] ? 1 : -1));

  const hasMoreThenOneVersion = versionIdsArray.length > 1;

  const versionsListDisplay = (
    <Menu>
      {versionIdsArray.map((version: any, index: any) => {
        const isLatest = index === 0;
        return (
          <Menu.Item key={index}>
            <VersionDropdownRow
              version={version}
              createdAt={versions[version]["createdAt"]}
              onClick={() => (isLatest ? setDisplayedVersion.remove() : setDisplayedVersion.set(version))}
              latest={isLatest}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const currentVersion = displayedVersion || versionIdsArray[0];

  return (
    <div
      className={`self-stretch ${hasChanges && "bg-pink-200"} relative  border-l border-gray-300`}
      id="versionsDropdown">
      <Dropdown
        overlay={versionsListDisplay}
        trigger={["click"]}
        overlayClassName={"versionsDropdown"}
        getPopupContainer={() => document.getElementById("versionsDropdown") as HTMLElement}
        disabled={!hasMoreThenOneVersion}>
        <button className=" px-4 py-4 flex  justify-between items-center ">
          <VersionDropdownRow
            className="mr-1"
            version={currentVersion}
            latest={currentVersion && currentVersion === versionIdsArray[0]}
            header
          />
          <div className="flex items-center">
            {hasMoreThenOneVersion && <SVGs.VersionDropdownArrowLine className="ml-6" />}
          </div>
        </button>
      </Dropdown>
    </div>
  );
};
