'use strict'
// module.exports = validate20
// module.exports.general = validate20
export default validate20
const schema22 = {
  '$id': 'parking',
  'type': 'object',
  'definitions': {
    'email': {
      'type': 'string',
      'pattern': '^\\S+@\\S+\\.\\S+$'
    }, 'phone': { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
  },
  'properties': {
    'VATExempted': { 'type': 'boolean' },
    'building': { 'type': 'string' },
    'compulsoryDerogation': { 'type': 'string' },
    'firstTimeRegulation': { 'type': 'number' },
    'indexAdjustmentBasicIndex': { 'type': 'number' },
    'introText': { 'type': 'string' },
    'landlordAccountNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'landlordAddress': { 'type': 'string' },
    'landlordContactPersonFirstName': { 'type': 'string' },
    'landlordContactPersonLastName': { 'type': 'string' },
    'landlordEmail': { '$ref': '#/definitions/email' },
    'landlordInvoiceAddress': { 'type': 'string' },
    'landlordName': { 'type': 'string' },
    'landlordOrgNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'landlordPhone': { '$ref': '#/definitions/phone' },
    'landlordPostalAddress': { 'type': 'string' },
    'landlordPropertyManager': { 'type': 'string' },
    'municipality': { 'type': 'string' },
    'noticePeriod': { 'type': 'number' },
    'numberOfParkingSpaces': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'parcelNumber': { 'type': 'string' },
    'parkingType': { 'type': 'string' },
    'paymentProvisionFirstPayDueDate': { 'type': 'number' },
    'propertyAddress': { 'type': 'string' },
    'rentAdjustment': { 'type': 'string' },
    'rentPerParkingSpace': { 'type': 'string' },
    'rentalCostDescription': { 'type': 'string' },
    'rentalEnd': { 'type': 'number' },
    'rentalObject': { 'type': 'string' },
    'rentalStart': { 'type': 'number' },
    'shoppingMall': { 'type': 'string' },
    'tenantAddress': { 'type': 'string' },
    'tenantContactPersonFirstName': { 'type': 'string' },
    'tenantContactPersonLastName': { 'type': 'string' },
    'tenantEmail': { '$ref': '#/definitions/email' },
    'tenantInvoiceAddress': { 'type': 'string' },
    'tenantName': { 'type': 'string' },
    'tenantOrgNo': { 'anyOf': [{ 'type': 'number' }, { 'type': 'string' }] },
    'tenantPhone': { '$ref': '#/definitions/phone' },
    'tenantPostalAddress': { 'type': 'string' },
    'title': { 'type': 'string' }
  },
  'required': ['VATExempted', 'firstTimeRegulation', 'indexAdjustmentBasicIndex', 'landlordAccountNo', 'landlordAddress', 'landlordContactPersonFirstName', 'landlordContactPersonLastName', 'landlordEmail', 'landlordInvoiceAddress', 'landlordName', 'landlordOrgNo', 'landlordPostalAddress', 'landlordPropertyManager', 'municipality', 'noticePeriod', 'numberOfParkingSpaces', 'parcelNumber', 'parkingType', 'propertyAddress', 'rentPerParkingSpace', 'rentalCostDescription', 'rentalObject', 'rentalStart', 'title']
}
const schema23 = { 'type': 'string', 'pattern': '^\\S+@\\S+\\.\\S+$' }
const schema24 = { 'type': 'string', 'pattern': '^[+]?[\\d\\s()-]{7,}$' }
const pattern0 = new RegExp('^\\S+@\\S+\\.\\S+$', 'u')
const pattern1 = new RegExp('^[+]?[\\d\\s()-]{7,}$', 'u')

function validate20(data, {
  instancePath = '',
  parentData,
  parentDataProperty,
  rootData = data
} = {}) {/*# sourceURL="parking" */
  let vErrors = null
  let errors = 0
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.VATExempted === undefined) {
      const err0 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'VATExempted' },
        message: 'must have required property \'' + 'VATExempted' + '\''
      }
      if (vErrors === null) {
        vErrors = [err0]
      } else {
        vErrors.push(err0)
      }
      errors++
    }
    if (data.firstTimeRegulation === undefined) {
      const err1 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'firstTimeRegulation' },
        message: 'must have required property \'' + 'firstTimeRegulation' + '\''
      }
      if (vErrors === null) {
        vErrors = [err1]
      } else {
        vErrors.push(err1)
      }
      errors++
    }
    if (data.indexAdjustmentBasicIndex === undefined) {
      const err2 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'indexAdjustmentBasicIndex' },
        message: 'must have required property \'' + 'indexAdjustmentBasicIndex' + '\''
      }
      if (vErrors === null) {
        vErrors = [err2]
      } else {
        vErrors.push(err2)
      }
      errors++
    }
    if (data.landlordAccountNo === undefined) {
      const err3 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordAccountNo' },
        message: 'must have required property \'' + 'landlordAccountNo' + '\''
      }
      if (vErrors === null) {
        vErrors = [err3]
      } else {
        vErrors.push(err3)
      }
      errors++
    }
    if (data.landlordAddress === undefined) {
      const err4 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordAddress' },
        message: 'must have required property \'' + 'landlordAddress' + '\''
      }
      if (vErrors === null) {
        vErrors = [err4]
      } else {
        vErrors.push(err4)
      }
      errors++
    }
    if (data.landlordContactPersonFirstName === undefined) {
      const err5 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordContactPersonFirstName' },
        message: 'must have required property \'' + 'landlordContactPersonFirstName' + '\''
      }
      if (vErrors === null) {
        vErrors = [err5]
      } else {
        vErrors.push(err5)
      }
      errors++
    }
    if (data.landlordContactPersonLastName === undefined) {
      const err6 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordContactPersonLastName' },
        message: 'must have required property \'' + 'landlordContactPersonLastName' + '\''
      }
      if (vErrors === null) {
        vErrors = [err6]
      } else {
        vErrors.push(err6)
      }
      errors++
    }
    if (data.landlordEmail === undefined) {
      const err7 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordEmail' },
        message: 'must have required property \'' + 'landlordEmail' + '\''
      }
      if (vErrors === null) {
        vErrors = [err7]
      } else {
        vErrors.push(err7)
      }
      errors++
    }
    if (data.landlordInvoiceAddress === undefined) {
      const err8 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordInvoiceAddress' },
        message: 'must have required property \'' + 'landlordInvoiceAddress' + '\''
      }
      if (vErrors === null) {
        vErrors = [err8]
      } else {
        vErrors.push(err8)
      }
      errors++
    }
    if (data.landlordName === undefined) {
      const err9 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordName' },
        message: 'must have required property \'' + 'landlordName' + '\''
      }
      if (vErrors === null) {
        vErrors = [err9]
      } else {
        vErrors.push(err9)
      }
      errors++
    }
    if (data.landlordOrgNo === undefined) {
      const err10 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordOrgNo' },
        message: 'must have required property \'' + 'landlordOrgNo' + '\''
      }
      if (vErrors === null) {
        vErrors = [err10]
      } else {
        vErrors.push(err10)
      }
      errors++
    }
    if (data.landlordPostalAddress === undefined) {
      const err11 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordPostalAddress' },
        message: 'must have required property \'' + 'landlordPostalAddress' + '\''
      }
      if (vErrors === null) {
        vErrors = [err11]
      } else {
        vErrors.push(err11)
      }
      errors++
    }
    if (data.landlordPropertyManager === undefined) {
      const err12 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'landlordPropertyManager' },
        message: 'must have required property \'' + 'landlordPropertyManager' + '\''
      }
      if (vErrors === null) {
        vErrors = [err12]
      } else {
        vErrors.push(err12)
      }
      errors++
    }
    if (data.municipality === undefined) {
      const err13 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'municipality' },
        message: 'must have required property \'' + 'municipality' + '\''
      }
      if (vErrors === null) {
        vErrors = [err13]
      } else {
        vErrors.push(err13)
      }
      errors++
    }
    if (data.noticePeriod === undefined) {
      const err14 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'noticePeriod' },
        message: 'must have required property \'' + 'noticePeriod' + '\''
      }
      if (vErrors === null) {
        vErrors = [err14]
      } else {
        vErrors.push(err14)
      }
      errors++
    }
    if (data.numberOfParkingSpaces === undefined) {
      const err15 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'numberOfParkingSpaces' },
        message: 'must have required property \'' + 'numberOfParkingSpaces' + '\''
      }
      if (vErrors === null) {
        vErrors = [err15]
      } else {
        vErrors.push(err15)
      }
      errors++
    }
    if (data.parcelNumber === undefined) {
      const err16 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'parcelNumber' },
        message: 'must have required property \'' + 'parcelNumber' + '\''
      }
      if (vErrors === null) {
        vErrors = [err16]
      } else {
        vErrors.push(err16)
      }
      errors++
    }
    if (data.parkingType === undefined) {
      const err17 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'parkingType' },
        message: 'must have required property \'' + 'parkingType' + '\''
      }
      if (vErrors === null) {
        vErrors = [err17]
      } else {
        vErrors.push(err17)
      }
      errors++
    }
    if (data.propertyAddress === undefined) {
      const err18 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'propertyAddress' },
        message: 'must have required property \'' + 'propertyAddress' + '\''
      }
      if (vErrors === null) {
        vErrors = [err18]
      } else {
        vErrors.push(err18)
      }
      errors++
    }
    if (data.rentPerParkingSpace === undefined) {
      const err19 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentPerParkingSpace' },
        message: 'must have required property \'' + 'rentPerParkingSpace' + '\''
      }
      if (vErrors === null) {
        vErrors = [err19]
      } else {
        vErrors.push(err19)
      }
      errors++
    }
    if (data.rentalCostDescription === undefined) {
      const err20 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentalCostDescription' },
        message: 'must have required property \'' + 'rentalCostDescription' + '\''
      }
      if (vErrors === null) {
        vErrors = [err20]
      } else {
        vErrors.push(err20)
      }
      errors++
    }
    if (data.rentalObject === undefined) {
      const err21 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentalObject' },
        message: 'must have required property \'' + 'rentalObject' + '\''
      }
      if (vErrors === null) {
        vErrors = [err21]
      } else {
        vErrors.push(err21)
      }
      errors++
    }
    if (data.rentalStart === undefined) {
      const err22 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'rentalStart' },
        message: 'must have required property \'' + 'rentalStart' + '\''
      }
      if (vErrors === null) {
        vErrors = [err22]
      } else {
        vErrors.push(err22)
      }
      errors++
    }
    if (data.title === undefined) {
      const err23 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'title' },
        message: 'must have required property \'' + 'title' + '\''
      }
      if (vErrors === null) {
        vErrors = [err23]
      } else {
        vErrors.push(err23)
      }
      errors++
    }
    if (data.VATExempted !== undefined) {
      if (typeof data.VATExempted !== 'boolean') {
        const err24 = {
          instancePath: instancePath + '/VATExempted',
          schemaPath: '#/properties/VATExempted/type',
          keyword: 'type',
          params: { type: 'boolean' },
          message: 'must be boolean'
        }
        if (vErrors === null) {
          vErrors = [err24]
        } else {
          vErrors.push(err24)
        }
        errors++
      }
    }
    if (data.building !== undefined) {
      if (typeof data.building !== 'string') {
        const err25 = {
          instancePath: instancePath + '/building',
          schemaPath: '#/properties/building/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err25]
        } else {
          vErrors.push(err25)
        }
        errors++
      }
    }
    if (data.compulsoryDerogation !== undefined) {
      if (typeof data.compulsoryDerogation !== 'string') {
        const err26 = {
          instancePath: instancePath + '/compulsoryDerogation',
          schemaPath: '#/properties/compulsoryDerogation/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err26]
        } else {
          vErrors.push(err26)
        }
        errors++
      }
    }
    if (data.firstTimeRegulation !== undefined) {
      let data3 = data.firstTimeRegulation
      if (!((typeof data3 == 'number') && (isFinite(data3)))) {
        const err27 = {
          instancePath: instancePath + '/firstTimeRegulation',
          schemaPath: '#/properties/firstTimeRegulation/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err27]
        } else {
          vErrors.push(err27)
        }
        errors++
      }
    }
    if (data.indexAdjustmentBasicIndex !== undefined) {
      let data4 = data.indexAdjustmentBasicIndex
      if (!((typeof data4 == 'number') && (isFinite(data4)))) {
        const err28 = {
          instancePath: instancePath + '/indexAdjustmentBasicIndex',
          schemaPath: '#/properties/indexAdjustmentBasicIndex/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err28]
        } else {
          vErrors.push(err28)
        }
        errors++
      }
    }
    if (data.introText !== undefined) {
      if (typeof data.introText !== 'string') {
        const err29 = {
          instancePath: instancePath + '/introText',
          schemaPath: '#/properties/introText/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err29]
        } else {
          vErrors.push(err29)
        }
        errors++
      }
    }
    if (data.landlordAccountNo !== undefined) {
      let data6 = data.landlordAccountNo
      const _errs14 = errors
      let valid1 = false
      const _errs15 = errors
      if (!((typeof data6 == 'number') && (isFinite(data6)))) {
        const err30 = {
          instancePath: instancePath + '/landlordAccountNo',
          schemaPath: '#/properties/landlordAccountNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err30]
        } else {
          vErrors.push(err30)
        }
        errors++
      }
      var _valid0 = _errs15 === errors
      valid1 = valid1 || _valid0
      if (!valid1) {
        const _errs17 = errors
        if (typeof data6 !== 'string') {
          const err31 = {
            instancePath: instancePath + '/landlordAccountNo',
            schemaPath: '#/properties/landlordAccountNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err31]
          } else {
            vErrors.push(err31)
          }
          errors++
        }
        var _valid0 = _errs17 === errors
        valid1 = valid1 || _valid0
      }
      if (!valid1) {
        const err32 = {
          instancePath: instancePath + '/landlordAccountNo',
          schemaPath: '#/properties/landlordAccountNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err32]
        } else {
          vErrors.push(err32)
        }
        errors++
      } else {
        errors = _errs14
        if (vErrors !== null) {
          if (_errs14) {
            vErrors.length = _errs14
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.landlordAddress !== undefined) {
      if (typeof data.landlordAddress !== 'string') {
        const err33 = {
          instancePath: instancePath + '/landlordAddress',
          schemaPath: '#/properties/landlordAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err33]
        } else {
          vErrors.push(err33)
        }
        errors++
      }
    }
    if (data.landlordContactPersonFirstName !== undefined) {
      if (typeof data.landlordContactPersonFirstName !== 'string') {
        const err34 = {
          instancePath: instancePath + '/landlordContactPersonFirstName',
          schemaPath: '#/properties/landlordContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err34]
        } else {
          vErrors.push(err34)
        }
        errors++
      }
    }
    if (data.landlordContactPersonLastName !== undefined) {
      if (typeof data.landlordContactPersonLastName !== 'string') {
        const err35 = {
          instancePath: instancePath + '/landlordContactPersonLastName',
          schemaPath: '#/properties/landlordContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err35]
        } else {
          vErrors.push(err35)
        }
        errors++
      }
    }
    if (data.landlordEmail !== undefined) {
      let data10 = data.landlordEmail
      if (typeof data10 === 'string') {
        if (!pattern0.test(data10)) {
          const err36 = {
            instancePath: instancePath + '/landlordEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {
            vErrors = [err36]
          } else {
            vErrors.push(err36)
          }
          errors++
        }
      } else {
        const err37 = {
          instancePath: instancePath + '/landlordEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err37]
        } else {
          vErrors.push(err37)
        }
        errors++
      }
    }
    if (data.landlordInvoiceAddress !== undefined) {
      if (typeof data.landlordInvoiceAddress !== 'string') {
        const err38 = {
          instancePath: instancePath + '/landlordInvoiceAddress',
          schemaPath: '#/properties/landlordInvoiceAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err38]
        } else {
          vErrors.push(err38)
        }
        errors++
      }
    }
    if (data.landlordName !== undefined) {
      if (typeof data.landlordName !== 'string') {
        const err39 = {
          instancePath: instancePath + '/landlordName',
          schemaPath: '#/properties/landlordName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err39]
        } else {
          vErrors.push(err39)
        }
        errors++
      }
    }
    if (data.landlordOrgNo !== undefined) {
      let data13 = data.landlordOrgNo
      const _errs33 = errors
      let valid3 = false
      const _errs34 = errors
      if (!((typeof data13 == 'number') && (isFinite(data13)))) {
        const err40 = {
          instancePath: instancePath + '/landlordOrgNo',
          schemaPath: '#/properties/landlordOrgNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err40]
        } else {
          vErrors.push(err40)
        }
        errors++
      }
      var _valid1 = _errs34 === errors
      valid3 = valid3 || _valid1
      if (!valid3) {
        const _errs36 = errors
        if (typeof data13 !== 'string') {
          const err41 = {
            instancePath: instancePath + '/landlordOrgNo',
            schemaPath: '#/properties/landlordOrgNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err41]
          } else {
            vErrors.push(err41)
          }
          errors++
        }
        var _valid1 = _errs36 === errors
        valid3 = valid3 || _valid1
      }
      if (!valid3) {
        const err42 = {
          instancePath: instancePath + '/landlordOrgNo',
          schemaPath: '#/properties/landlordOrgNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err42]
        } else {
          vErrors.push(err42)
        }
        errors++
      } else {
        errors = _errs33
        if (vErrors !== null) {
          if (_errs33) {
            vErrors.length = _errs33
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.landlordPhone !== undefined) {
      let data14 = data.landlordPhone
      if (typeof data14 === 'string') {
        if (!pattern1.test(data14)) {
          const err43 = {
            instancePath: instancePath + '/landlordPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {
            vErrors = [err43]
          } else {
            vErrors.push(err43)
          }
          errors++
        }
      } else {
        const err44 = {
          instancePath: instancePath + '/landlordPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err44]
        } else {
          vErrors.push(err44)
        }
        errors++
      }
    }
    if (data.landlordPostalAddress !== undefined) {
      if (typeof data.landlordPostalAddress !== 'string') {
        const err45 = {
          instancePath: instancePath + '/landlordPostalAddress',
          schemaPath: '#/properties/landlordPostalAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err45]
        } else {
          vErrors.push(err45)
        }
        errors++
      }
    }
    if (data.landlordPropertyManager !== undefined) {
      if (typeof data.landlordPropertyManager !== 'string') {
        const err46 = {
          instancePath: instancePath + '/landlordPropertyManager',
          schemaPath: '#/properties/landlordPropertyManager/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err46]
        } else {
          vErrors.push(err46)
        }
        errors++
      }
    }
    if (data.municipality !== undefined) {
      if (typeof data.municipality !== 'string') {
        const err47 = {
          instancePath: instancePath + '/municipality',
          schemaPath: '#/properties/municipality/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err47]
        } else {
          vErrors.push(err47)
        }
        errors++
      }
    }
    if (data.noticePeriod !== undefined) {
      let data18 = data.noticePeriod
      if (!((typeof data18 == 'number') && (isFinite(data18)))) {
        const err48 = {
          instancePath: instancePath + '/noticePeriod',
          schemaPath: '#/properties/noticePeriod/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err48]
        } else {
          vErrors.push(err48)
        }
        errors++
      }
    }
    if (data.numberOfParkingSpaces !== undefined) {
      let data19 = data.numberOfParkingSpaces
      const _errs50 = errors
      let valid5 = false
      const _errs51 = errors
      if (!((typeof data19 == 'number') && (isFinite(data19)))) {
        const err49 = {
          instancePath: instancePath + '/numberOfParkingSpaces',
          schemaPath: '#/properties/numberOfParkingSpaces/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err49]
        } else {
          vErrors.push(err49)
        }
        errors++
      }
      var _valid2 = _errs51 === errors
      valid5 = valid5 || _valid2
      if (!valid5) {
        const _errs53 = errors
        if (typeof data19 !== 'string') {
          const err50 = {
            instancePath: instancePath + '/numberOfParkingSpaces',
            schemaPath: '#/properties/numberOfParkingSpaces/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err50]
          } else {
            vErrors.push(err50)
          }
          errors++
        }
        var _valid2 = _errs53 === errors
        valid5 = valid5 || _valid2
      }
      if (!valid5) {
        const err51 = {
          instancePath: instancePath + '/numberOfParkingSpaces',
          schemaPath: '#/properties/numberOfParkingSpaces/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err51]
        } else {
          vErrors.push(err51)
        }
        errors++
      } else {
        errors = _errs50
        if (vErrors !== null) {
          if (_errs50) {
            vErrors.length = _errs50
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.parcelNumber !== undefined) {
      if (typeof data.parcelNumber !== 'string') {
        const err52 = {
          instancePath: instancePath + '/parcelNumber',
          schemaPath: '#/properties/parcelNumber/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err52]
        } else {
          vErrors.push(err52)
        }
        errors++
      }
    }
    if (data.parkingType !== undefined) {
      if (typeof data.parkingType !== 'string') {
        const err53 = {
          instancePath: instancePath + '/parkingType',
          schemaPath: '#/properties/parkingType/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err53]
        } else {
          vErrors.push(err53)
        }
        errors++
      }
    }
    if (data.paymentProvisionFirstPayDueDate !== undefined) {
      let data22 = data.paymentProvisionFirstPayDueDate
      if (!((typeof data22 == 'number') && (isFinite(data22)))) {
        const err54 = {
          instancePath: instancePath + '/paymentProvisionFirstPayDueDate',
          schemaPath: '#/properties/paymentProvisionFirstPayDueDate/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err54]
        } else {
          vErrors.push(err54)
        }
        errors++
      }
    }
    if (data.propertyAddress !== undefined) {
      if (typeof data.propertyAddress !== 'string') {
        const err55 = {
          instancePath: instancePath + '/propertyAddress',
          schemaPath: '#/properties/propertyAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err55]
        } else {
          vErrors.push(err55)
        }
        errors++
      }
    }
    if (data.rentAdjustment !== undefined) {
      if (typeof data.rentAdjustment !== 'string') {
        const err56 = {
          instancePath: instancePath + '/rentAdjustment',
          schemaPath: '#/properties/rentAdjustment/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err56]
        } else {
          vErrors.push(err56)
        }
        errors++
      }
    }
    if (data.rentPerParkingSpace !== undefined) {
      if (typeof data.rentPerParkingSpace !== 'string') {
        const err57 = {
          instancePath: instancePath + '/rentPerParkingSpace',
          schemaPath: '#/properties/rentPerParkingSpace/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err57]
        } else {
          vErrors.push(err57)
        }
        errors++
      }
    }
    if (data.rentalCostDescription !== undefined) {
      if (typeof data.rentalCostDescription !== 'string') {
        const err58 = {
          instancePath: instancePath + '/rentalCostDescription',
          schemaPath: '#/properties/rentalCostDescription/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err58]
        } else {
          vErrors.push(err58)
        }
        errors++
      }
    }
    if (data.rentalEnd !== undefined) {
      let data27 = data.rentalEnd
      if (!((typeof data27 == 'number') && (isFinite(data27)))) {
        const err59 = {
          instancePath: instancePath + '/rentalEnd',
          schemaPath: '#/properties/rentalEnd/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err59]
        } else {
          vErrors.push(err59)
        }
        errors++
      }
    }
    if (data.rentalObject !== undefined) {
      if (typeof data.rentalObject !== 'string') {
        const err60 = {
          instancePath: instancePath + '/rentalObject',
          schemaPath: '#/properties/rentalObject/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err60]
        } else {
          vErrors.push(err60)
        }
        errors++
      }
    }
    if (data.rentalStart !== undefined) {
      let data29 = data.rentalStart
      if (!((typeof data29 == 'number') && (isFinite(data29)))) {
        const err61 = {
          instancePath: instancePath + '/rentalStart',
          schemaPath: '#/properties/rentalStart/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err61]
        } else {
          vErrors.push(err61)
        }
        errors++
      }
    }
    if (data.shoppingMall !== undefined) {
      if (typeof data.shoppingMall !== 'string') {
        const err62 = {
          instancePath: instancePath + '/shoppingMall',
          schemaPath: '#/properties/shoppingMall/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err62]
        } else {
          vErrors.push(err62)
        }
        errors++
      }
    }
    if (data.tenantAddress !== undefined) {
      if (typeof data.tenantAddress !== 'string') {
        const err63 = {
          instancePath: instancePath + '/tenantAddress',
          schemaPath: '#/properties/tenantAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err63]
        } else {
          vErrors.push(err63)
        }
        errors++
      }
    }
    if (data.tenantContactPersonFirstName !== undefined) {
      if (typeof data.tenantContactPersonFirstName !== 'string') {
        const err64 = {
          instancePath: instancePath + '/tenantContactPersonFirstName',
          schemaPath: '#/properties/tenantContactPersonFirstName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err64]
        } else {
          vErrors.push(err64)
        }
        errors++
      }
    }
    if (data.tenantContactPersonLastName !== undefined) {
      if (typeof data.tenantContactPersonLastName !== 'string') {
        const err65 = {
          instancePath: instancePath + '/tenantContactPersonLastName',
          schemaPath: '#/properties/tenantContactPersonLastName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err65]
        } else {
          vErrors.push(err65)
        }
        errors++
      }
    }
    if (data.tenantEmail !== undefined) {
      let data34 = data.tenantEmail
      if (typeof data34 === 'string') {
        if (!pattern0.test(data34)) {
          const err66 = {
            instancePath: instancePath + '/tenantEmail',
            schemaPath: '#/definitions/email/pattern',
            keyword: 'pattern',
            params: { pattern: '^\\S+@\\S+\\.\\S+$' },
            message: 'must match pattern "' + '^\\S+@\\S+\\.\\S+$' + '"'
          }
          if (vErrors === null) {
            vErrors = [err66]
          } else {
            vErrors.push(err66)
          }
          errors++
        }
      } else {
        const err67 = {
          instancePath: instancePath + '/tenantEmail',
          schemaPath: '#/definitions/email/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err67]
        } else {
          vErrors.push(err67)
        }
        errors++
      }
    }
    if (data.tenantInvoiceAddress !== undefined) {
      if (typeof data.tenantInvoiceAddress !== 'string') {
        const err68 = {
          instancePath: instancePath + '/tenantInvoiceAddress',
          schemaPath: '#/properties/tenantInvoiceAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err68]
        } else {
          vErrors.push(err68)
        }
        errors++
      }
    }
    if (data.tenantName !== undefined) {
      if (typeof data.tenantName !== 'string') {
        const err69 = {
          instancePath: instancePath + '/tenantName',
          schemaPath: '#/properties/tenantName/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err69]
        } else {
          vErrors.push(err69)
        }
        errors++
      }
    }
    if (data.tenantOrgNo !== undefined) {
      let data37 = data.tenantOrgNo
      const _errs91 = errors
      let valid7 = false
      const _errs92 = errors
      if (!((typeof data37 == 'number') && (isFinite(data37)))) {
        const err70 = {
          instancePath: instancePath + '/tenantOrgNo',
          schemaPath: '#/properties/tenantOrgNo/anyOf/0/type',
          keyword: 'type',
          params: { type: 'number' },
          message: 'must be number'
        }
        if (vErrors === null) {
          vErrors = [err70]
        } else {
          vErrors.push(err70)
        }
        errors++
      }
      var _valid3 = _errs92 === errors
      valid7 = valid7 || _valid3
      if (!valid7) {
        const _errs94 = errors
        if (typeof data37 !== 'string') {
          const err71 = {
            instancePath: instancePath + '/tenantOrgNo',
            schemaPath: '#/properties/tenantOrgNo/anyOf/1/type',
            keyword: 'type',
            params: { type: 'string' },
            message: 'must be string'
          }
          if (vErrors === null) {
            vErrors = [err71]
          } else {
            vErrors.push(err71)
          }
          errors++
        }
        var _valid3 = _errs94 === errors
        valid7 = valid7 || _valid3
      }
      if (!valid7) {
        const err72 = {
          instancePath: instancePath + '/tenantOrgNo',
          schemaPath: '#/properties/tenantOrgNo/anyOf',
          keyword: 'anyOf',
          params: {},
          message: 'must match a schema in anyOf'
        }
        if (vErrors === null) {
          vErrors = [err72]
        } else {
          vErrors.push(err72)
        }
        errors++
      } else {
        errors = _errs91
        if (vErrors !== null) {
          if (_errs91) {
            vErrors.length = _errs91
          } else {
            vErrors = null
          }
        }
      }
    }
    if (data.tenantPhone !== undefined) {
      let data38 = data.tenantPhone
      if (typeof data38 === 'string') {
        if (!pattern1.test(data38)) {
          const err73 = {
            instancePath: instancePath + '/tenantPhone',
            schemaPath: '#/definitions/phone/pattern',
            keyword: 'pattern',
            params: { pattern: '^[+]?[\\d\\s()-]{7,}$' },
            message: 'must match pattern "' + '^[+]?[\\d\\s()-]{7,}$' + '"'
          }
          if (vErrors === null) {
            vErrors = [err73]
          } else {
            vErrors.push(err73)
          }
          errors++
        }
      } else {
        const err74 = {
          instancePath: instancePath + '/tenantPhone',
          schemaPath: '#/definitions/phone/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err74]
        } else {
          vErrors.push(err74)
        }
        errors++
      }
    }
    if (data.tenantPostalAddress !== undefined) {
      if (typeof data.tenantPostalAddress !== 'string') {
        const err75 = {
          instancePath: instancePath + '/tenantPostalAddress',
          schemaPath: '#/properties/tenantPostalAddress/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err75]
        } else {
          vErrors.push(err75)
        }
        errors++
      }
    }
    if (data.title !== undefined) {
      if (typeof data.title !== 'string') {
        const err76 = {
          instancePath: instancePath + '/title',
          schemaPath: '#/properties/title/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string'
        }
        if (vErrors === null) {
          vErrors = [err76]
        } else {
          vErrors.push(err76)
        }
        errors++
      }
    }
  } else {
    const err77 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object'
    }
    if (vErrors === null) {
      vErrors = [err77]
    } else {
      vErrors.push(err77)
    }
    errors++
  }
  validate20.errors = vErrors
  return errors === 0
}
