import i18n from 'src/producers/languages/i18n'
import { getContractType } from 'src/utils/getContractType'
import { validateForm } from './validateForm'

export const validateData = (
  fields: any,
  template: string,
  updateInvalidFields: any,
  removeAllInvalidFields: any,
) => {
  // if (contractType.includes('parking')) contractType = 'parking'
  removeAllInvalidFields.remove()
  let valuesToValidate: any = {}
  for (const key in fields) {
    const field = fields[key].value
    if (typeof field !== 'boolean' && (!field || field === '')) continue
    valuesToValidate[key] = field
  }
  const { valid, errors } = validateForm(valuesToValidate, template)

  if (!valid) {
    for (const varName in errors) {
      updateInvalidFields.set(errors[varName], { varName: varName })
    }
  }

  return valid
}
