import { AiOutlineConsoleSql } from "react-icons/ai";
import { db } from "src/producers/firebase";

export const saveDefaultEmails: producer = async ({
  contractId = get.contract.id,
  recipients = get.contract.original.recipients,
  landlordEmail = get.contract.values.landlordEmail.displayedValue,
  tenantEmail = get.contract.values.tenantEmail.displayedValue,
  createVersionIsOpen = observe.modal.createNewVersion,
  sendContractIsOpen = observe.modal.sendContract,
}) => {
  try {
    if (!sendContractIsOpen && !createVersionIsOpen) return;

    if (recipients.value() && Object.keys(recipients.value()).length !== 0) {
      return;
    }

    landlordEmail = landlordEmail.value();
    tenantEmail = tenantEmail.value();

    if (!landlordEmail || !tenantEmail) return;

    recipients = {
      ...(landlordEmail && { landlords: [landlordEmail] }),
      ...(tenantEmail && { tenants: [tenantEmail] }),
    };

    const contractDb = db.collection("contracts").doc(contractId.value());
    await contractDb.update({ recipients: recipients });
    // .catch((err: any) => console.error(err));
  } catch (err: any) {
    console.error("Error: ", err);
  }
};
