import { ContractSigningMethod, ContractStatus, ContractType, UserRole } from 'src/types'
// import { UploadSignedContractButton } from "src/pages/Contract/UploadSignedContractButton/UploadSignedContractButton";
import { AgreementType } from 'src/types/commands/command'
import { SendContractButton } from './SendContractButton'
import { SendToSigningButton } from './SendToSigningButton'
import { TenantDownloadPDFButton } from './TenantDownloadPDFButton'
import { StopSigningButton } from './StopSigningButton'
import { DownloadSignedContractButton } from './DownloadSignedContractButton'
import { SavePaperContractButton } from './SavePaperContractButton'
import { SendAddendumButton } from './SendAddendumButton'
import { UploadSignedContractButton } from 'src/pages/ContractV2/components/Navbar/UploadSignedContractButton'

export const ContractNavbarButton: view = ({
  status = observe.contract.originalv2.status,
  signing = observe.contract.originalv2.signing,
  contractType = observe.contract.originalv2.contractType,
  // agreementType = observe.contract.originalv2.agreementType,
  userRole = get.user.data.role,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER
  const isTenant = userRole.value() === UserRole.TENANT
  const isDraft = status === ContractStatus.DRAFT
  // const isNegotiation = status === ContractStatus.NEGOTIATION;
  const isSigning = status === ContractStatus.SIGNING
  const isActive = status === ContractStatus.ACTIVE
  const isDigitalSigning = signing === ContractSigningMethod.DIGITAL
  const isPaperContract = contractType === ContractType.PAPER

  if (isTenant) {
    if (isActive) return <DownloadSignedContractButton/>
    return <TenantDownloadPDFButton/>
  }

  if (isManager) {
    if (isDraft && isPaperContract) return <SavePaperContractButton />
    if (isSigning)
      return (
        <div className="flex flex-row gap-4">
          <StopSigningButton/>
          {!isDigitalSigning && <UploadSignedContractButton/>}
        </div>
      )
    if (isActive) return <DownloadSignedContractButton/>
    return <SendToSigningButton/>
  }
  return null
}
