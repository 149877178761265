import { db } from "src/producers/firebase";
import { ApplicationError, ErrorLevel } from "src/types";

export const dismissWelcomeCard: producer = ({
  trigger = observe.legacyContract.triggers.dismissWelcomeCard,
  removeTrigger = update.legacyContract.triggers.dismissWelcomeCard,
  userUid = get.user.uid,
  error = update.errors[param.type],
}) => {
  if (!trigger) return;
  removeTrigger.remove();
  try {
    db.collection("users").doc(userUid.value()).update({ onboarded: true });
  } catch (e) {
    console.error("dismissWelcomeCard", e);
    error.set({ message: "error-dismissing-welcome-card", level: ErrorLevel.LOW } as ApplicationError, {
      type: "updatesFeed",
    });
  }
};
