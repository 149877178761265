export const reorderParties: producer = async ({
  trigger = observe.contract.triggers.reorderParty,
  updateTrigger = update.contract.triggers.reorderParty,
  updateCurrentSession = update.contract.currentSession.parties,
  partiesViewModel = get.contract.partiesViewModel,
}) => {
  if (!trigger) return;
  const { index, by } = trigger;
  if (index === undefined || by === undefined) return;
  updateTrigger.remove();
  partiesViewModel = partiesViewModel.value();
  let change: any = {};
  partiesViewModel.forEach((p: any, index: any) => {
    change[p.partyId] = { order: index };
  });
  if (by === 1) {
    const current = partiesViewModel[index];
    const next = partiesViewModel[index + 1];
    change[current.partyId] = { order: index + 1 };
    change[next.partyId] = { order: index };
  }
  if (by === -1) {
    const current = partiesViewModel[index];
    const previous = partiesViewModel[index - 1];
    change[current.partyId] = { order: index - 1 };
    change[previous.partyId] = { order: index };
  }
  updateCurrentSession.merge(change);
};
