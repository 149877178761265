import i18n from "src/producers/languages/i18n";
import { ContractEventType } from "src/types";

export const EventBubble: view = ({
  timestamp,
  title,
  children,
  _viewId,
  isExpanded = observe.views[prop._viewId].expanded,
  setExpanded = update.views[prop._viewId].expanded,
  event,
}) => {
  //TODO refactor this
  const svg = () => {
    switch (event) {
      case ContractEventType.VERSION_CREATED:
        return (
          <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.31644 4C4.31644 3.72386 4.54029 3.5 4.81644 3.5H11.166C11.2987 3.5 11.426 3.55278 11.5198 3.64672L14.1703 6.30125C14.2639 6.39499 14.3164 6.52205 14.3164 6.65453V15C14.3164 15.2761 14.0926 15.5 13.8164 15.5H4.81644C4.54029 15.5 4.31644 15.2761 4.31644 15V4Z"
              stroke="#828282"
            />
            <path
              d="M14.8164 6.98186L11.821 6.99545C11.2669 6.99796 10.8164 6.54951 10.8164 5.99546V3"
              stroke="#828282"
            />
            <rect x="6.81644" y="11" width="1" height="5" transform="rotate(-90 6.81644 11)" fill="#828282" />
            <rect x="8.81644" y="8" width="1" height="5" fill="#828282" />
            <path
              d="M1.39515 11C1.97388 11 1.97388 10.4174 1.97388 10.4174L1.97387 1.16542H8.23768C8.23768 1.16542 8.81641 1.16542 8.81641 0.582583C8.81641 0.261003 8.55738 0 8.23768 0H1.39514C1.07544 0 0.816406 0.261003 0.816406 0.582583L0.816414 10.4174C0.816414 10.739 1.07545 11 1.39515 11Z"
              fill="#828282"
            />
          </svg>
        );
      case ContractEventType.OFFER_SENT:
        return (
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.83474 15.2577L2.18618 9.40529C2.23953 9.15625 2.45291 8.96058 2.71964 8.925L10.277 8.14231C10.4904 8.12452 10.4904 7.80433 10.277 7.76875L2.71964 7.03942C2.45291 7.02163 2.23953 6.82596 2.18618 6.57692L0.83474 0.74229C0.710265 0.244212 1.24373 -0.164924 1.70606 0.066327L16.483 7.46635C16.9275 7.6976 16.9275 8.33798 16.483 8.56923L1.70606 15.9337C1.24373 16.1649 0.710265 15.7558 0.83474 15.2577Z"
              fill="#BDBDBD"
            />
          </svg>
        );
      case ContractEventType.SENT_FOR_SIGNING:
        return (
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.83474 15.2577L2.18618 9.40529C2.23953 9.15625 2.45291 8.96058 2.71964 8.925L10.277 8.14231C10.4904 8.12452 10.4904 7.80433 10.277 7.76875L2.71964 7.03942C2.45291 7.02163 2.23953 6.82596 2.18618 6.57692L0.83474 0.74229C0.710265 0.244212 1.24373 -0.164924 1.70606 0.066327L16.483 7.46635C16.9275 7.6976 16.9275 8.33798 16.483 8.56923L1.70606 15.9337C1.24373 16.1649 0.710265 15.7558 0.83474 15.2577Z"
              fill="#34C4A4"
            />
          </svg>
        );
      case ContractEventType.EXECUTED:
        return (
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.2813 10.0281L14.7883 0.957883H16.8164L11.1829 12.5015H8.92878L6.49947 6.0282H8.87291L10.2813 10.0281ZM2.50651 13.352H10.6754L9.94298 15.0421H0.816406V14.4787V13.7464V3.77473L2.50651 2.33825L4.14028 0.957886H10.6754H11.4077H12.0838V1.91561V4.3381V4.61978L10.3937 7.67092V2.64799H4.75999V4.50711V4.90147H4.08394H3.8586H2.50651V13.352Z"
              fill="#34C4A4"
            />
          </svg>
        );
      default:
        return null;
    }
  };
  return (
    <div className="p-2 my-3 mx-auto w-full rounded border">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {svg()}
          <span className="ml-2 text-gray-600 ">{title}.</span>
          {children && (
            <button className="text-pink-600 ml-2" onClick={() => setExpanded.set(!isExpanded)}>
              {isExpanded ? i18n.t("sidebar.colapse") : i18n.t("sidebar.details")}
            </button>
          )}
        </div>
        <span className="font-12 text-gray-500 ">{timestamp}</span>
      </div>
      {children && isExpanded && (
        <div className=" mt-2 rounded">
          <div style={{ padding: "6px 10px" }} className="flex justify-between">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};
