import i18n from "src/producers/languages/i18n";
import { InsideCard } from "../../ClauseCard/InsideCard";
import { DateFieldGeneral, FreeTextFieldGeneral, NumberInputGeneral } from "../../fields/FormField";
import { CommonCost } from "./CommonCost";
import { PaymentProvision } from "./PaymentProvision";
import { VATExempted } from "./VATExempted";

export const RentalCostManager: view = () => (
  <div>
    <InsideCard key="3.1" title={"3.1 " + i18n.t("parking_contract.rental_cost_clause_first_subclause")}>
      <div className="w-full">
        <FreeTextFieldGeneral varName="rentalCostDescription" />
      </div>
      <NumberInputGeneral
        varName="rentPerParkingSpace"
        placeholder={i18n.t("placeholders.add_amount")}
        extraClasses="pr-8 w-1/2 self-end"
        label={i18n.t("parking_contract.parking_space_l")}
      />
      <NumberInputGeneral
        varName="rentPerCharger"
        placeholder={i18n.t("placeholders.add_amount")}
        extraClasses="pr-8 w-1/2 self-end"
        label={i18n.t("parking_contract.rentPerCharger")}
      />
    </InsideCard>
    <CommonCost />
    <InsideCard key="3.3" title={"3.3 " + i18n.t("parking_contract.rental_cost_clause_intex")}>
      <div className="w-full">
        <p className="mb-4 w-full">
          {
            "Leien reguleres hver 1. januar, i samsvar med eventuelle endringer i Statistisk Sentralbyrås konsumprisindeks, eller, hvis denne blir opphevet, annen tilsvarende offentlig indeks."
          }
        </p>
        <div className="flex mb-2">
          <div className="flex flex-col w-1/2 mr-8">
            <span>
              <span className="text-pink-600">*</span>
              {i18n.t("clause_rental_costs_index_adjustment_first_time_regulation")}
            </span>
            <DateFieldGeneral
              varName="firstTimeRegulation"
              placeholder={i18n.t("placeholders.select_date")}
              extraClasses="pr-8"
            />
          </div>
          <div className="flex flex-col w-1/2">
            <span>
              <span className="text-pink-600">*</span>
              {i18n.t("clause_rental_costs_index_adjustment_basic_index")}
            </span>
            <DateFieldGeneral
              varName="indexAdjustmentBasicIndex"
              placeholder={i18n.t("placeholders.select_date")}
              extraClasses="pr-8"
            />
          </div>
        </div>
      </div>
    </InsideCard>

    <PaymentProvision />
    <VATExempted />
  </div>
);
