import { Button } from "@c11/components.button";
import "./DownloadSignedContractButton.css";

import i18n from "src/producers/languages/i18n";
import { DownloadIcon } from "src/assets/svg";
import { downloadContract } from "src/pages/Contract/Navbar/DownloadSignedContractButton.downloadContract";

export const DownloadSignedContractButton: view = ({
  loadingContractDownload = observe.contract.flags.loadingContractDownload,
  downloadSignedContract = update.contract.triggers.downloadSignedContract,
}) => {
  return (
      <Button
        className="download-signed-contract-btn flex uppercase gap-2 mr-3"
        variant="secondary"
        onClick={() => downloadSignedContract.set(Date.now())}
        isLoading={loadingContractDownload}>
        <span>{i18n.t("contract_navbar.button_download_signed_contract")}</span>
        <DownloadIcon />
      </Button>
  );
};

DownloadSignedContractButton.producers([downloadContract]);
