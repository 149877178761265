import React from "react";

export const InvalidMessage: view = ({ varName, invalidFields = observe.legacyContract.invalidFields[arg.varName] }) => {
  if (!invalidFields) return null;

  return (
    <div key="invalidFields" className="text-pink-600 text-xs">
      {invalidFields}
    </div>
  );
};
