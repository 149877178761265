import { Button } from '@c11/components.button'

import { Upload } from 'antd'
import { Modal } from 'src/components'
import { Input } from '../Input'
import i18n from 'src/producers/languages/i18n'
import { SVGs } from 'src/assets'

// TODO: Put the file reference on the state
//  instead of keeping it locally

export let appendixToSave: any

export function resetAppendixToSave() {
  appendixToSave = undefined
}

export const AddAppendixModal: view = ({
  addAppendixModal = observe.modal.addAppendixModal,
  appendixDisplayName = observe.legacyContract.triggers.saveAppendix.appendixDisplayName,
  setAddAppendixModal = update.modal.addAppendixModal,
  triggerSaveAppendix = update.legacyContract.triggers.saveAppendix.save,
  setAppendixDisplayName = update.legacyContract.triggers.saveAppendix.appendixDisplayName
}) => {
  if (!addAppendixModal) return null

  function handleCancelClick() {
    resetAppendixToSave()
  }

  function handleOKClick() {
    if (appendixToSave) {
      triggerSaveAppendix.set(Date.now())
    }
  }

  const hasValidName = appendixDisplayName?.length > 0 && appendixDisplayName?.length < 81

  const uploadConfig = {
    accept: 'image/*,.pdf',
    beforeUpload: (file: any) => {
      setAddAppendixModal.set(Date.now()) //I don't remember why this is here
      appendixToSave = file
      return false
    },
    onRemove: (file: any) => {
      resetAppendixToSave()
      return true
    },
    fileList: appendixToSave ? [appendixToSave] : []
  }
  return (
    <Modal
      title={i18n.t('modals_appendix_add')}
      okButtonText={i18n.t('modals_appendix_add')}
      image={() => <SVGs.ModalEdit/>}
      onOkClick={handleOKClick}
      onCancelClick={handleCancelClick}
      okButtonDisabled={!hasValidName}>
      <div className="mb-2">
        <Upload key="1" {...uploadConfig}>
          <Button className="uppercase" variant="primary">
            {i18n.t('modals_appendix_add_button')}
          </Button>
        </Upload>
        <Input
          label={i18n.t('modals_appendix_add_label')}
          extraClasses="w-full"
          key="input"
          placeholder="ex. image 1"
          inputType="text"
          onChange={(e: any) => setAppendixDisplayName.set(e.target.value)}
          defaultValue={appendixDisplayName}
        />
      </div>
    </Modal>
  )
}
