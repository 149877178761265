import React from "react";

import { Progress } from "./Progress";
import { SVGs } from "src/assets";
import { ContractNavbarButton } from "./ContractNavbarButton";
import { ContractName } from "./ContractName";
import { router } from "src/router";

export const ContractNavbar: view = () => {
  return (
    <nav
      style={{ padding: "18px 24px" }}
      className="bg-white font-medium fixed w-full z-30 top-0 flex items-center justify-between">
      <div key="left" className="flex items-center">
        <BackButton />
        <ContractName />
      </div>
      <div key="right" className="flex items-center">
        <Progress />
        <ContractNavbarButton />
      </div>
    </nav>
  );
};

const BackButton: view = ({ 
  // setCurrentPath = update.routes.currentPath, 
  currentQuery = observe.routes.currentQuery, 
  updateReroute = update.routes.triggers.reroute,
  clearAll = update.legacyContract
}) => {
  return (
    <button
      className="relative focus:outline-none border-focus"
      onClick={() => {
        // setCurrentPath.set("/");
        router.navigate("/" + (currentQuery || ""));
        updateReroute.set(Date.now());
        clearAll.remove();
      }}>
      <SVGs.BackArrow className="mr-16" />
      <VerticalLine />
    </button>
  );
};

const VerticalLine: view = () => {
  return (
    <div
      className="bg-gray-500 absolute"
      style={{
        width: "1.5px",
        height: "48px",
        right: "32px",
        top: "-15px",
      }}></div>
  );
};
