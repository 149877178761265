export enum CommandActions {
  CREATE_DIGITAL_CONTRACT = "CreateDigitalContract",
  CREATE_PAPER_CONTRACT = "CreatePaperContract",
  CREATE_PARKING_CONTRACT = "CreateParkingContract",
  CREATE_ADDENDUM = "CreateAddendum",
  SELECT_BUILDING = "SelectBuilding",
  ADD_PARTY = "AddParty",
  REMOVE_PARTY = "RemoveParty",
  UPDATE_PARTY = "UpdateParty",
  ADD_ATTACHMENT = "AddAttachment",
  REMOVE_ATTACHMENT = "RemoveAttachment",
  UPLOAD_GUARANTEE = "UploadGuarantee",
  MOVE_TO_NEGOTIATION = "MoveToNegotiation",
  CREATE_VERSION = "CreateVersion",
  ADD_COMMENT = "AddComment",
  CANCEL_SIGNING = "CancelSigning",
  UPLOAD_PAPER_CONTRACT = "UploadPaperContract",
  SAVE_PAPER_CONTRACT = "SavePaperContract",
  INITIALIZE_SIGNING = "InitializeSigning",
  EXECUTE_CONTRACT = "ExecuteContract",
}

export interface Command {
  action: CommandActions;
  contractId?: string;
  agreementType?: AgreementType;

  [x: string]: any;
}

export enum AgreementType {
  CONTRACT = "CONTRACT",
  ADDENDUM = "ADDENDUM",
}
