import moment from "moment"; // eslint-disable-line

export const formatContractName = (values: any) => {
  if (!values) {
    return "_ _ Leieavtale _-_ _";
  }
  const shoppingMall = values.shoppingMall?.displayedValue || "";
  const propertyLocalNo = values.propertyLocalNo?.displayedValue || "";
  const rentalStart = values.rentalStart?.displayedValue
    ? moment(values.rentalStart?.displayedValue).utc().format("DD.MM.YYYY")
    : "";
  const rentalEnd = values.rentalEnd?.displayedValue
    ? moment(values.rentalEnd?.displayedValue).utc().format("DD.MM.YYYY")
    : "";
  const tenantName = values.tenantName?.displayedValue || "";

  return `${shoppingMall} ${propertyLocalNo} Leieavtale ${rentalStart}-${rentalEnd} ${tenantName}`;
};
