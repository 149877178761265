import { VisibleIf } from "src/components_generic/visible-if";
import i18n from "src/producers/languages/i18n";
import { ContractFilterOption, ContractStatus, UserRole } from "src/types";
import { HeaderSection } from "../../components/HeaderSection/HeaderSection";
import { AddendumListElement } from "./AddendumListElement";
import { NewAddendumButton } from "./NewAddendumButton";
import { notification } from 'antd'
import { router } from "src/router";

export const Addendums: view = ({
  userRole = get.user.data.role,
  status = observe.contract.originalv2.status,
  addendumsUIData = observe.contract.ui.addendums,
  isLocked = get.contract.isLocked.value,

  // setCurrentPath = update.routes.currentPath,
  currentQuery = observe.routes.currentQuery,
  updateReroute = update.routes.triggers.reroute,
  removeTrigger = update.contract.triggers.deleteAddendum,
  toggleLostTrigger = update.contract.triggers.toggleAddendumLost,
}) => {
  const isManager = userRole.value() === UserRole.MANAGER;
  const isActive = status === ContractStatus.ACTIVE;
  const canCreateNewAddendum = isActive && isManager;

  return (<>
    <HeaderSection title={i18n.t("general.addendums")}>
      <NewAddendumButton />
    </HeaderSection>

    <VisibleIf predicate={addendumsUIData?.asArray.length > 0}>
    <div className="flex-grow flex flex-col align-center mt-8">

      <div className="flex-grow flex flex-row mx-auto" style={{ width: '100%', maxWidth: '1024px', maxHeight: '24px' }}>
        <VisibleIf predicate={!isLocked}>
          <div className="flex-grow">Actions</div>
        </VisibleIf>
      </div>

      {
        addendumsUIData?.asArray?.map((item, index) => <AddendumListElement
          key={item.id}
          index={index + 1}
          name={item.title}
          status={item.status}
          isLost={!!item.isLost}
          onEdit={() => {
            console.log(`selected!`, item.id)
            setTimeout(() => {
              // setCurrentPath.set(`/contract/${item.id}`)
              router.navigate(`/contract/${item.id}` + (currentQuery || ''))
              updateReroute.set(Date.now())
            }, 100)
          }}
          onDelete={() => {
            console.log(`deleted!`);
            removeTrigger.set({
              id: item.id
            })
          }}
          onMarkAsLostToggled={() => {
            console.log(`Marked as lost`)
            toggleLostTrigger.set({
              id: item.id
            })
          }}
          onCopyLink={async () => {
            const url = `${window.location.origin}${window.location.pathname}`;
            await window.navigator.clipboard.writeText(url);

            notification.info({
              message: 'Link copied successfully'
            })
          }}
        />)
      }
    </div>
    </VisibleIf>
  </>);
};

// Addendums.producers([setFilterTag]);
