import { ContractFilterOption, Version } from "src/types"

export enum ContractFlow {
  DIGITAL = "DIGITAL",
  PAPER = "PAPER",
}

export enum ContractTemplate {
  SHOPPING_MALL = "SHOPPING_MALL",
  PARKING = "PARKING",
  DOWNPAYMENT = "DOWNPAYMENT",
  TRANSPORT = "TRANSPORT",
  CANCELLATION = "CANCELLATION",
  RENTAL_PERIOD_PRICE = "RENTAL_PERIOD_PRICE",
  STORAGE = "STORAGE",
  PAPER = "PAPER",
}

export const AddendumTemplates = [
  ContractTemplate.DOWNPAYMENT,
  ContractTemplate.TRANSPORT,
  ContractTemplate.CANCELLATION,
  ContractTemplate.RENTAL_PERIOD_PRICE,
  ContractTemplate.STORAGE,
]

export enum ContractStatus {
  DRAFT = "draft",
  SIGNING = "signing",
  DELETED = "deleted",
  ACTIVE = "active",
}

// export type ContractFieldData<T> = {
//   value: T
//   overwritten?: boolean
// }

export type ContractFieldMetadata = {
  updatedBy: string
  updatedAt: number
}

// export type ContractField<T = any> = {
//   data: ContractFieldData<T>
//   metadata: ContractFieldMetadata
// }

export type ContractField<T = any> = {
  value: T
  overwritten?: boolean
  metadata: ContractFieldMetadata
}

export type ContractTenant = {
  _updated: number
  address?: string
  createdAt: string | number
  id: number
  invoiceAddress?: string
  name: string
  number: string | number
  phone?: string | number
  postalAddress?: string
  updatedAt?: string | number
  mainContacts?: {
    [x: string]: {
      email: string
      firstName: string
      lastName: string
      phone?: string | number
      id: number
    }
  }
}

export type ContractBuilding = {
  _id: string
  _updated: number
  accountNumber: string
  address: string
  buildingImageUrl: string
  city: string
  companyName: string
  id: string
  invoiceAddress: string
  municipality: string
  name: string
  number: string
  operationManager: string
  organisationalNumber: string
  parcelNumber: string
  postalAddress: string
  propertyManager: string
  zipCode: string
  files?: {
    [x: string]: {
      _id: string | number
      _updated: string | number
      created: string | number
      id: string | number
      name: string
      path: string
      group?: string
      type: string
    }
  }
}

export type ContractAttachment = {
  id: string
  name: string
  type: string
  createdAt: string
  selected: boolean
  user: string
  order: number
  path: string
  isUserAttachment?: boolean
  toDelete?: boolean
}

export type ContractSigner = {
  email: string
  firstName: string
  lastName: string
  order: number
  partyId: string
  partyType: string
  phone: string
  position: string
  recipientAction: string
}

export type ContractAddendum = {
  id: string
  order: number
  [property: string]: any
}

export type ContractV2 = {
  id: string
  templateId: string

  flow: ContractFlow
  template: ContractTemplate
  status: ContractStatus
  filterTag?: ContractFilterOption

  fields: {
    [key: string]: ContractField
  }

  indices: any

  tenant: ContractTenant
  building: ContractBuilding
  attachments: {
    [key: string]: ContractAttachment
  }
  signers: {
    [id: string]: ContractSigner
  }
  addendums: {
    [id: string]: ContractAddendum
  }

  defaultValues: {
    [key: string]: any // We could infer this, but it's too much work
  }

  revision: number

  metadata: {
    createdAt: number
    createdBy: string
    updatedAt: number
    updatedBy: string

    isEditedBy: string
  }
}

const a = {
  second: {},
  third: {},
  fourth: {},
  first: {},
}
