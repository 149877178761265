import i18n from "src/producers/languages/i18n";
import { ContractStatus } from "src/types";

export const Progress: view = ({ status = observe.contract.originalv2.status }) => {
  const map = {
    [ContractStatus.DRAFT]: 0,
    // [ContractStatus.NEGOTIATION]: 1,
    [ContractStatus.SIGNING]: 1,
    [ContractStatus.ACTIVE]: 2,
  };
  const level = map[status];
  status = ContractStatus.DRAFT;
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="230" height="40" viewBox="0 0 211.68 39.9">
      <line x1="58" y1="6.5" x2="13" y2="6.5" stroke="#E12764"/>
      <circle cx="13.3" cy="6.7" r="6.2"  fill="#E12764"
        stroke="#F4F4F4"/>
      <line x1="58" y1="6.5" x2="148" y2="6.5" stroke={level >= 1 ? "#E12764" : "#999999"}/>
      <circle cx="102.7" cy="6.7" r="5.2" fill={level >= 1 ? "#E12764" : "#F4F4F4" }
        stroke={level >= 1 ? "#E12764" : "#999999"}/>
      <line x1="148" y1="6.5" x2="193" y2="6.5"  stroke={level == 2 ? "#E12764" : "#999999"}/>
      <circle cx="192.7" cy="6.7" r="5.2"  fill={level == 2 ? "#E12764" : "#F4F4F4"}
        stroke={level >= 2 ? "#E12764" : "#999999"}/>
      <text transform="translate(0 36)" fill="#E12764" >
        <tspan x="0" y="0">{i18n.t("status.draft")}</tspan>
      </text>
      <text transform="translate(84 36)" fill={level >= 1 ? "#E12764" : "#999999"}>
        <tspan x="0" y="0">{i18n.t("status.signing")}</tspan>
      </text>
      <text transform="translate(179 36)" fill={level >= 2 ? "#E12764" : "#999999"}>
        <tspan x="0" y="0">{i18n.t("status.active")}</tspan>
      </text>
      </svg>

  );
};
