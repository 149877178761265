import { Spinner } from "@c11/components";
import { Divider } from "antd";
import styles from "./styles.module.css";

interface ListItemCardProps {
  index: number;
  onClick: () => void;
  children: any;
  className: string;
  isLoading: boolean;
}

interface ListItemCardHeaderProps {
  title: string;
  children: any;
  className: string;
  align: "start" | "center" | "end";
  separator: boolean;
}

interface ListItemCardBodyProps {
  children: any;
  className: any;
  align: "start" | "center" | "end";
}

interface ListItemCardFooterProps {
  children: any;
  className: any;
  align: "start" | "center" | "end";
}

export const ListItemCard: view<ListItemCardProps> = ({
  index,
  onClick,
  children,
  className,
  isLoading,
}: ListItemCardProps) => {
  return (
    <div className={`${className} ${!!onClick ? "cursor-pointer" : ""} ${styles.listCard}`} onClick={onClick}>
      {index && (
        <div className="flex flex-row items-center">
          <span className="font-semibold">{`${index}. `}</span>
          <Divider type="vertical" className="h-8 border mx-4" />
        </div>
      )}
      {isLoading ? <Spinner color="primary" size="medium" /> : children}
    </div>
  );
};

export const ListItemCardHeader: view<ListItemCardHeaderProps> = ({
  title,
  children,
  className,
  align,
}: ListItemCardHeaderProps) => {
  if (!align) align = "start";

  return (
    <div className={`list-item-card-header ${className}`}>
      <div className={`${styles.cardHeaderContent}`} >
        {title && <h2>{title}</h2>}
        {children}
      </div>
    </div>
  );
};

export const ListItemCardBody: view<ListItemCardBodyProps> = ({
  children,
  className,
  align,
}: ListItemCardBodyProps) => {
  if (!align) align = "end";

  return <div className={` place-content-${align} ${className} ${styles.listCardBody}`}>{children}</div>;
};

export const ListItemCardFooter: view<ListItemCardFooterProps> = ({
  children,
  className,
  align,
}: ListItemCardFooterProps) => {
  if (!align) align = "end";

  return (
    <div
      className={`list-item-card-footer flex flex-row items-center self-center justify-${align} content-${align} ${className}`}
      style={{ width: "7%" }}>
      {children}
    </div>
  );
};
