import React from "react";

import "./Checkbox.css";

export const Checkbox: view = ({
  checked,
  onClick,
  isReadOnlyContract = observe.contract.isReadOnlyContract,
  small,
}) => {
  return (
    <label className="container-checkbox">
      <input type="checkbox" checked={!!checked} onChange={isReadOnlyContract ? () => {} : onClick} />
      <span className={small ? "checkmark-small" : "checkmark-large"}></span>
    </label>
  );
};
