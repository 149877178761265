import { storageRef, functions } from "src/producers/firebase";
import { ApplicationError, ErrorLevel, ErrorType } from "src/types";
import { CommandActions } from "src/types/commands/command";

export const deleteAppendix: producer = async ({
  _now,
  contractId = observe.contract.id,
  trigger = observe.contract.triggers.deleteAppendix,
  updateAppendix = update.contract.original.state.attachments[param.id],
  updateTrigger = update.contract.triggers.deleteAppendix,
  updateDeletedAppendix = update.contract.deletedAppendix[param.id],
  attachments = get.contract.original.state.attachments,
  error = update.errors[param.id],
}) => {
  if (trigger === undefined || !contractId) {
    return;
  }
  updateTrigger.remove();
  const attachment = attachments.value()?.[trigger];
  console.log("attachment", attachment);
  const appendixRef = storageRef.child(attachment.path);
  updateAppendix.remove({ id: attachment.fileId });
  updateDeletedAppendix.set(_now(), { id: attachment.fileId });
  try {
    // await appendixRef.delete();
    const commandPublisher = functions.httpsCallable("eventlessCommandHandler");
    const command = {
      action: CommandActions.REMOVE_ATTACHMENT,
      contractId,
      attachmentId: attachment.id,
      fileName: attachment.fileName,
      fileId: attachment.fileId,
      fileType: attachment.fileType,
    };
    await commandPublisher(command);
    console.log("file deleted");
  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.LOW,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: "deleteAppendix-contract" }
    );
    updateDeletedAppendix.remove({ id: attachment.fileId });
  }
};
