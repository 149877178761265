import { PartyType } from 'src/types'

export const populatePartyForm: producer = async ({
  trigger = observe.contract.triggers.populatePartyForm,
  removeTrigger = update.contract.triggers.populatePartyForm,
  writeValue = update.modal.addEditPartyModal.data[param.field],
  setSelectedOption = update.modal.addEditPartyModal.selectedPartyCP,
  availableLandlords = observe.landlords,
  contractTenant = observe.contract.tenant,
}) => {
  if (!trigger?.type || trigger?.option === undefined) return

  const party =
    trigger.type === PartyType.TENANT
      ? contractTenant.mainContacts[trigger.option]
      : availableLandlords.data[trigger.option]

  writeValue.set(party?.firstName || '', { field: 'firstName' })
  writeValue.set(party?.lastName || '', { field: 'lastName' })
  writeValue.set(party?.phone || '', { field: 'phone' })
  writeValue.set(party?.email || '', { field: 'email' })
  writeValue.set(party?.position || '', { field: 'position' })

  setSelectedOption.set(trigger.option)
  removeTrigger.remove()
}
